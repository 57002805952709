import { SchemaValidation } from 'utilities/validationCheck'

export const newPasswordSchema = SchemaValidation.object().shape({
  newPassword: SchemaValidation.string()
    .minNoTrim(8, 'Panjang minimal password adalah 8 karakter')
    .required('Password harus diisi'),
  confirmation: SchemaValidation.string()
    .required('Konfirmasi password harus diisi')
    .test('Konfirmasi password tidak sesuai', function (value, context) {
      return context.parent.newPassword === value
    }),
})
