import { BaseApi } from '../base.api'
import { ILocationApi } from 'domains/location/contracts/api'
import { LocationModel, GetLocationParams } from 'domains/location/models'

export default class LocationApi extends BaseApi implements ILocationApi {
  constructor() {
    super('/v2')
  }

  public async getAreas(
    queryParams?: GetLocationParams
  ): Promise<LocationModel[]> {
    return this.makeForCamelCase('GET', '/areas', queryParams)
  }

  public async getArea(id: string): Promise<LocationModel | undefined> {
    return this.makeForCamelCase('GET', `/areas/${id}`)
  }
}
