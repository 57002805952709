import { camelCase, snakeCase } from 'change-case'

export function toQueryString(queryParams: any): string {
  queryParams = toSnakeCase(queryParams)
  let querystring = ''
  if (queryParams) {
    querystring = `?${Object.keys(queryParams)
      .map((key, i) => `&${key}=${queryParams[key]}`)
      .join('')}`
  }
  return querystring
}

export function toCamelCase<T extends any>(obj: any): T {
  if (
    typeof obj != 'object' ||
    typeof obj === 'undefined' ||
    obj === null ||
    obj === ''
  ) {
    return obj
  }
  if (Array.isArray(obj)) {
    const arr = obj
    return arr.map((item: any) => toCamelCase(item)) as any
  } else {
    const newObj: any = {}
    Object.keys(obj).forEach(key => {
      const newKey = camelCase(key)
      const newVal = toCamelCase(obj[key])
      newObj[newKey] = newVal
    })
    return newObj
  }
}

export function toSnakeCase<T extends any>(obj: any): T {
  if (
    typeof obj != 'object' ||
    typeof obj === 'undefined' ||
    obj === null ||
    obj === ''
  ) {
    return obj
  }
  if (Array.isArray(obj)) {
    const arr = obj
    return arr.map((item: any) => toSnakeCase(item)) as any
  } else {
    const newObj: any = {}
    Object.keys(obj).forEach(key => {
      const newKey = snakeCase(key)
      const newVal = obj[key] ? toSnakeCase(obj[key]) : obj[key]
      newObj[newKey] = newVal
    })
    return newObj
  }
}
