import { BaseReportingService } from '.'
import { ReportingFilter } from '../models'
import { toQueryISOString } from 'utilities/converter/date'

export class ServiceTypeReportingService
  extends BaseReportingService
  implements IServiceTypeReportingService
{
  public getServiceTypeListReporting = async (filter?: ReportingFilter) => {
    const queryParams = {
      outletId: filter.selectedOutletId || '',
      fromDate: filter.dateRange
        ? toQueryISOString(filter.dateRange.from, true)
        : '',
      toDate: filter.dateRange
        ? toQueryISOString(filter.dateRange.to, true)
        : '',
      viewIn: filter.showType,
    }
    this.setLoading('SET_ORDER_TYPE_LIST_REPORTING', true)
    const serviceTypeReports = await this.api.reporting.getServiceTypeReports(
      queryParams
    )
    this.reportingStorage.setServiceTypeListReporting(serviceTypeReports)
    this.setLoading('SET_ORDER_TYPE_LIST_REPORTING', false)
  }
}

export interface IServiceTypeReportingService {
  getServiceTypeListReporting: (filter?: ReportingFilter) => Promise<void>
}
