import React, { useState } from 'react'
import { ModalProps, Modal, DefaultModalService } from 'views/components/Modal'
import {
  Wrapper,
  Text,
  Break,
  Panel,
  Sided,
  Button,
  Field,
  Form,
  FormAction,
  VSided,
  FeatureFlag,
} from 'views/components'
import './style.css'
import { DiscountFormSchema, DiscountFields } from './discountFormSchema'
import { discountService, commonService } from 'injectors'
import { Discount } from 'domains/management/discounts/models'
import { DeleteDiscountModal } from '../DeleteDiscountModal'
import { trimDuplicateSpace } from 'utilities/strings'
import { featureCheck } from 'config'
import { firebaseFunc } from 'utilities/firebase'
import { capitalizeFirstLetter } from 'utilities/strings/capitalizeFirstLetter'
import { camelCase } from 'change-case'
import { useLingui } from '@lingui/react'
import { t, Trans } from '@lingui/macro'

interface AddDiscountProps extends ModalProps {
  discount?: Discount
}

const transparentButtonStyle = {
  backgroundColor: '#001E3A',
  border: '1px solid #d8d8d8',
  width: '40px',
  height: '40px',
  borderRadius: '5px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'white',
  fontWeight: 'bold',
  cursor: 'pointer',
}

const inactiveButtonStyle = {
  backgroundColor: 'white',
  border: '1px solid #d8d8d8',
  color: '#333333',
  width: '40px',
  height: '40px',
  borderRadius: '5px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
}

const AddDiscount: React.FC<AddDiscountProps> = props => {
  const { i18n } = useLingui()
  if (props.discount) {
    firebaseFunc.logEvent('edit_discount_start')
  } else {
    firebaseFunc.logEvent('create_discount_start')
  }
  const [discountType, setDiscountType] = useState<string>(
    props.discount && props.discount.percentage > 0 ? 'percentage' : 'amount'
  )

  const initialValues: DiscountFields = props.discount
    ? {
        name: props.discount.name,
        percentage:
          props.discount.amount > 0
            ? props.discount.amount
            : props.discount.percentage,
        maxAmount: props.discount.maxAmount,
      }
    : {
        name: '',
        percentage: 0,
        maxAmount: 0,
      }

  const handleSubmit = async (actions: FormAction<DiscountFields>) => {
    try {
      let discount = {} as Discount
      const values = actions.getValues()
      if (discountType === 'amount') {
        discount = {
          ...props.discount,
          ...values,
          amount: values.percentage,
          percentage: 0,
          name: trimDuplicateSpace(values.name),
          maxAmount: 0,
        }
      }
      if (discountType === 'percentage') {
        discount = {
          ...props.discount,
          ...values,
          percentage: values.percentage,
          amount: 0,
          name: trimDuplicateSpace(values.name),
          maxAmount: values.maxAmount,
        }
      }
      if (discount.id) {
        firebaseFunc.logEvent('edit_discount_complete')
        await discountService.updateDiscount(discount, discount.id)
        commonService.setAlert({
          message: i18n._(t`Diskon berhasil diubah`),
          type: 'success',
        })
      } else {
        firebaseFunc.logEvent('create_discount_complete')
        await discountService.createDiscount(discount)
        commonService.setAlert({
          message: i18n._(t`Diskon berhasil disimpan`),
          type: 'success',
        })
      }
      props.close('')
    } catch (error) {
      if (error.code === 'validation_error') {
        error.fields.forEach((x: { message: string; name: string }) => {
          actions.setFieldError(
            camelCase(x.name),
            capitalizeFirstLetter(x.message)
          )
        })
      }
      if (error.code === 'already_exists') {
        actions.setFieldError('name', capitalizeFirstLetter(error.message))
      }
    }
  }

  const handleDeleteDiscount = async () => {
    try {
      const isDeleted = await DefaultModalService.open(DeleteDiscountModal, {
        discount: props.discount,
      })
      if (isDeleted) {
        await discountService.deleteDiscount(props.discount.id)
        commonService.setAlert({
          message: i18n._(t`Diskon berhasil di hapus`),
          type: 'netral',
        })
        props.dismiss()
      }
    } catch (error) {
      commonService.setAlert({
        message: i18n._(t`Diskon gagal di hapus`),
        type: 'error',
      })
    }
  }

  const textTitle = props.discount
    ? i18n._(t`Ubah Diskon`)
    : i18n._(t`Diskon Baru`)

  return (
    <Modal dismiss={props.dismiss} type='full' canDismissOutside={false}>
      <Form.Form
        height='100%'
        initialValues={initialValues}
        validationSchema={DiscountFormSchema}
        onSubmit={handleSubmit}
        render={(actions: FormAction<DiscountFields>) => (
          <VSided.VSided>
            <VSided.Remaining>
              <Wrapper width='480px' isCenter>
                <Break height={24} />
                <Text.Heading h={4} align='left' noMargin>
                  {textTitle}
                </Text.Heading>
                <Break height={24} />
                <Panel.Card padding={16}>
                  <Field.Text
                    placeholder={i18n._(t`Contoh: DISKONTAHUNBARU2020`)}
                    name='name'
                    label={i18n._(t`Nama Diskon`)}
                  />
                  <Trans>
                    <Text.Paragraph size={14} color='black'>
                      Nilai Diskon
                    </Text.Paragraph>
                  </Trans>
                  <Sided.Sided justify='top' gutter={8}>
                    <Sided.Remaining>
                      <Field.Number
                        placeholder={i18n._(t`Contoh: 10%`)}
                        name='percentage'
                        decimal={discountType === 'amount' ? false : true}
                      />
                    </Sided.Remaining>
                    <Sided.Fixed minWidth={80}>
                      <Sided.Sided gutter={0}>
                        <Sided.Remaining>
                          <Wrapper
                            style={
                              discountType === 'amount'
                                ? transparentButtonStyle
                                : inactiveButtonStyle
                            }
                            onClick={() => setDiscountType('amount')}
                          >
                            Rp
                          </Wrapper>
                        </Sided.Remaining>
                        <Sided.Remaining>
                          <Wrapper
                            style={
                              discountType === 'percentage'
                                ? transparentButtonStyle
                                : inactiveButtonStyle
                            }
                            onClick={() => setDiscountType('percentage')}
                          >
                            %
                          </Wrapper>
                        </Sided.Remaining>
                      </Sided.Sided>
                    </Sided.Fixed>
                  </Sided.Sided>
                  <FeatureFlag
                    featureCheck={featureCheck.management.maxDiscount}
                  >
                    {discountType === 'percentage' ? (
                      <div style={{ marginTop: '16px' }}>
                        <Field.Number
                          label={i18n._(t`Jumlah Maksimum Diskon`)}
                          placeholder={i18n._(t`Contoh: Rp 5.000`)}
                          name='maxAmount'
                          decimal={false}
                          secondaryLabel={i18n._(t`Opsional`)}
                        />
                      </div>
                    ) : null}
                  </FeatureFlag>
                </Panel.Card>
              </Wrapper>
              <Break height={24} />
              {props.discount && (
                <Trans>
                  <Text.Heading
                    h={6}
                    color='red'
                    onClick={() => handleDeleteDiscount()}
                    align='center'
                  >
                    Hapus Diskon
                  </Text.Heading>
                </Trans>
              )}
            </VSided.Remaining>
            <VSided.Fixed>
              <Wrapper
                padding='16px'
                backgroundColor='#ffffff'
                shadow='0 -2px 4px 0 rgba(0, 0, 0, 0.2)'
              >
                <Wrapper width='480px' isCenter>
                  <Sided.Sided justify='center' gutter={16}>
                    <Sided.Remaining>
                      <Button
                        theme='netral'
                        label={i18n._(t`Batal`)}
                        size='large'
                        fullW
                        onClick={() => props.close({})}
                      />
                    </Sided.Remaining>
                    <Sided.Remaining>
                      <Button
                        disabled={!actions.isValid()}
                        type='submit'
                        theme='primary'
                        label={i18n._(t`Simpan`)}
                        size='large'
                        fullW
                      />
                    </Sided.Remaining>
                  </Sided.Sided>
                </Wrapper>
              </Wrapper>
            </VSided.Fixed>
          </VSided.VSided>
        )}
      />
    </Modal>
  )
}

export { AddDiscount }
