import { BaseApi } from 'api/base.api'
import { IPaymentMethodKYCApi } from 'domains/management/paymentMethodKYC/contracts/api'
import {
  BusinessType,
  OutletOwnershipStatus,
  Bank,
  PaymentMethodKYCForm,
  FinalizePaymentMethodKYCForm,
  PaymentOption,
} from 'domains/management/paymentMethodKYC/models'

export default class PaymentMethodKYCApi
  extends BaseApi
  implements IPaymentMethodKYCApi
{
  constructor() {
    super('')
  }

  public async getBusinessTypes(): Promise<BusinessType[]> {
    return this.makeForCamelCaseForList('GET', '/v2/kyc-data/business-types')
  }

  public async getOutletOwnershipStatuses(): Promise<OutletOwnershipStatus[]> {
    return this.makeForCamelCaseForList(
      'GET',
      '/v2/kyc-data/ownership-statuses'
    )
  }

  public async getBanks(): Promise<Bank[]> {
    return this.makeForCamelCaseForList('GET', '/v2/kyc-data/banks')
  }

  public async getCashlezPaymentMethod(
    outletId: string
  ): Promise<PaymentOption[]> {
    return this.makeForCamelCaseForList(
      'GET',
      `/v2/outlets/${outletId}/cashlez-payment-options`
    )
  }

  public async getShopeePaymentMethod(
    outletId: string
  ): Promise<PaymentOption[]> {
    return this.makeForCamelCaseForList(
      'GET',
      `/v2/outlets/${outletId}/shopee-payment-options`
    )
  }

  public async getPaymentMethodKYCForm(
    outletId: string,
    integratorId: string
  ): Promise<PaymentMethodKYCForm> {
    return this.makeForCamelCase(
      'GET',
      `/v3/outlets/${outletId}/kyc-form/${integratorId}`
    )
  }

  public async checkPaymentMethodKYCForm(
    outletId: string,
    integratorId: string
  ): Promise<FinalizePaymentMethodKYCForm> {
    return this.makeForCamelCase(
      'GET',
      `/v3/outlets/${outletId}/kyc-form/${integratorId}/check`
    )
  }

  public async upsertPaymentMethodKYCForm(
    paymentMethodKYCForm: PaymentMethodKYCForm,
    integratorId: string
  ): Promise<PaymentMethodKYCForm> {
    return this.makeForCamelCase(
      'POST',
      `/v3/outlets/${paymentMethodKYCForm.outletId}/kyc-form/${integratorId}/upsert`,
      paymentMethodKYCForm
    )
  }

  public async finalizePaymentMethodKYCForm(
    outletId: string,
    integratorId: string,
    finalizePaymentMethodKYCForm: FinalizePaymentMethodKYCForm,
    headers: object = {}
  ): Promise<PaymentMethodKYCForm> {
    return this.makeForCamelCase(
      'POST',
      `/v3/outlets/${outletId}/kyc-form/${integratorId}/finalize`,
      finalizePaymentMethodKYCForm,
      {},
      headers
    )
  }

  public async activatePaymentMethodOption(
    outletId: string,
    paymentOptionId: string,
    integratorId: string
  ): Promise<void> {
    return this.makeForCamelCase(
      'POST',
      `/v3/outlets/${outletId}/payment-options/${paymentOptionId}/${integratorId}`
    )
  }
}
