import { BaseApi } from 'api/base.api'
import {
  BatchInventoryStockAdjustmentPayloadModel,
  GetInventoryStockAdjustmenHistoryQueryParams,
  InventoryStockAdjustmenHistoryModel,
} from 'domains/inventory/models'

export default class InventoryApi extends BaseApi {
  constructor() {
    super('')
  }

  public batchInventoryStockAdjustment(payload: {
    stocks: BatchInventoryStockAdjustmentPayloadModel[]
  }) {
    return this.make('POST', '/product/v4/products/batch-update-stock', payload)
  }

  public async getInventoryStockHistories(
    queryParams: GetInventoryStockAdjustmenHistoryQueryParams
  ): Promise<InventoryStockAdjustmenHistoryModel[]> {
    return (
      (await this.makeForList(
        'GET',
        '/catalog/v4/stocks/stock-histories',
        queryParams
      )) as any
    ).data
  }
}
