import { ServiceType, CreateServiceTypeRequest } from '../models'
import { toDict, mapDict } from 'utilities/converter/list'
import { Dict } from 'utilities/type'
import { ICommonStorage } from 'domains/commons/contracts/storage'
import { IServiceTypeStorage } from '../contracts/storage'
import { BaseService } from 'domains/commons/services'
import { Api } from 'domains/contracts/api'
import { commonService } from 'injectors'

export class ServiceTypeService
  extends BaseService
  implements IServiceTypeService
{
  serviceTypeStorage: IServiceTypeStorage

  constructor(
    commonStorage: ICommonStorage,
    api: Api,
    serviceTypeStorage: IServiceTypeStorage
  ) {
    super(commonStorage, api)
    this.serviceTypeStorage = serviceTypeStorage
  }

  public getServiceTypes = async (): Promise<ServiceType[]> => {
    const data = await this.api.management.serviceType.getServiceTypes()
    const filterServiceTypeStates = (serviceTypes: any) =>
      serviceTypes.data.map((res: any) => ({
        id: res.id,
        name: res.name,
        withServiceCharge: res.withServiceCharge,
      }))
    this.serviceTypeStorage.setServiceTypes(
      toDict(filterServiceTypeStates(data), (p: ServiceType) => p.id)
    )
    return data
  }

  public setAsPriority = async (id: string) => {
    const { management } = this.getState()
    const serviceTypeDict: Dict<ServiceType> = mapDict<ServiceType>(
      management.serviceType.serviceTypes,
      (v: ServiceType) => ({
        ...v,
        isPriority: v.id == id,
      })
    )

    this.serviceTypeStorage.setServiceTypes(serviceTypeDict)
  }

  public createServiceType = async (request: CreateServiceTypeRequest) => {
    try {
      await this.api.management.serviceType.createServiceType(request)
      this.getServiceTypes()
    } catch (error) {
      throw error
    }
  }

  public updateServiceType = async (request: ServiceType) => {
    try {
      await this.api.management.serviceType.updateServiceType(request)
      this.getServiceTypes()
      commonService.setAlert({
        message: 'Tipe pesanan berhasil diubah',
        type: 'success',
      })
    } catch (error) {
      throw error
    }
  }

  public deleteServiceType = async (id = '') => {
    try {
      const queryParams = id && `/${id}`
      await this.api.management.serviceType.deleteServiceType(queryParams)
      this.getServiceTypes()
      commonService.setAlert({
        message: 'Tipe pesanan berhasil dihapus',
        type: 'netral',
      })
    } catch (error) {
      if (error.code === 'service_type_used_by_contactless') {
        commonService.setAlert({
          message:
            'Tidak dapat menghapus tipe pesanan karena masih digunakan pada Toko Online',
          type: 'error',
        })
      } else {
        commonService.setAlert({
          message: error.message,
          type: 'error',
        })
      }
    }
  }
}
export interface IServiceTypeService {
  getServiceTypes: () => Promise<ServiceType[]>
  setAsPriority: (id: string) => Promise<void>
  createServiceType: (request: CreateServiceTypeRequest) => void
  updateServiceType: (request: ServiceType) => void
  deleteServiceType: (id: string) => void
}
