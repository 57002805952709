import authRoute from './auth'
import contactlessRoute from './contactless'
import helperRoute from './helper'
import { ingredientsRoute } from './ingredients'
import managementRoute from './management'
import reportingRoute from './reporting'
import settingsRoute from './settings'
import subscribeRoute from './subscribe'
import { inventoryRoute } from './inventory'
import paylaterRoute from './paylater'

const ROUTE = {
  MAINPAGE: '/',
  ONBOARD: '/onboarding',
  FLEXPAY_DETAIL: '/flexpay/info',
  ...paylaterRoute,
  ...authRoute,
  ...reportingRoute,
  ...managementRoute,
  ...settingsRoute,
  ...subscribeRoute,
  ...helperRoute,
  ...contactlessRoute,
  ...ingredientsRoute,
  ...inventoryRoute,
}

export default ROUTE
