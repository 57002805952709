import moment from 'moment'

export const toDateString = (date: moment.MomentInput, format: string, locale?: string) => {
  return moment(date).locale(locale ?? 'en').format(format)
}

export const toISOString = (date: Date, keepOffset?: boolean): string => {
  return moment(date).toISOString(keepOffset)
}

export const toUTCISOString = (date: Date): string => {
  return moment(date).toISOString()
}

export const toQueryISOString = (date: Date, keepOffset?: boolean): string => {
  return encodeURIComponent(toISOString(date, keepOffset))
}
