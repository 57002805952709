import { ReportingFilter } from '../models'
import { toQueryISOString } from 'utilities/converter/date'
import { BaseReportingService } from '.'

export class SummaryReportingService
  extends BaseReportingService
  implements ISummaryReportingService
{
  public async getSummaryReporting(
    filter?: ReportingFilter,
    headers: object = {}
  ) {
    try {
      const queryParams = {
        outletId: filter.selectedOutletId || '',
        fromDate: filter.dateRange
          ? toQueryISOString(filter.dateRange.from, true)
          : '',
        toDate: filter.dateRange
          ? toQueryISOString(filter.dateRange.to, true)
          : '',
        viewIn: filter.showType,
      }
      const summaryReports = await this.api.reporting.getSummaries(
        queryParams,
        headers
      )
      this.reportingStorage.setSummaryReporting(summaryReports)
    } catch (error) {
      throw error
    }
  }

  public async getDailySummaryReporting(
    filter?: ReportingFilter,
    headers: object = {}
  ) {
    try {
      const queryParams = {
        outletId: filter.selectedOutletId || '',
        fromDate: filter.dateRange
          ? toQueryISOString(filter.dateRange.from, true)
          : '',
        toDate: filter.dateRange
          ? toQueryISOString(filter.dateRange.to, true)
          : '',
        viewIn: 'daily',
      }
      const dailyReport = await this.api.reporting.getSummaries(queryParams)
      this.reportingStorage.setDailySummaryReporting(dailyReport)
    } catch (error) {
      throw error
    }
  }

  public async getHourlySummaryReporting(
    filter?: ReportingFilter,
    headers: object = {}
  ) {
    try {
      const queryParams = {
        outletId: filter.selectedOutletId || '',
        fromDate: filter.dateRange
          ? toQueryISOString(filter.dateRange.from, true)
          : '',
        toDate: filter.dateRange
          ? toQueryISOString(filter.dateRange.to, true)
          : '',
        viewIn: 'daily',
      }
      const dailyReport = await this.api.reporting.getSummariesHourly(
        queryParams
      )
      this.reportingStorage.setHourlySummaryReporting(dailyReport)
    } catch (error) {
      throw error
    }
  }
}

export interface ISummaryReportingService {
  getSummaryReporting(filter?: ReportingFilter, headers?: object): Promise<void>
  getDailySummaryReporting(filter?: ReportingFilter): Promise<void>
  getHourlySummaryReporting(filter?: ReportingFilter): Promise<void>
}
