import classNames from 'classnames'
import React, { useState, useEffect, ReactNode } from 'react'
import './style.css'

import { getScreenType } from 'utilities/screen/getScreentType'
import { Icon, Text, Form, Sided, Image } from 'views/components'
import { Dropdown, DropdownAction } from 'views/components/Dropdown'
import { RenderingFieldContext } from 'views/components/Form'
import Wrapper from 'views/components/Wrapper'

import InputBase from '../base'

export interface DropdownItem<T = string> {
  value: T
  label: string
  shownValue?: string
  isDisabled?: boolean
  asHeader?: boolean
  indent?: number
  group?: string
  secondaryLabel?: string
  disabledLabel?: string
  imageUrl?: string
}

export interface DropdownSearchable {
  enabled: boolean
  notFoundMessage?: string
  customNotFound?: JSX.Element
}

interface Props<T> {
  mapSubItem?: (x: any) => any
  label?: string
  placeholder?: string
  list: DropdownItem<T>[]
  name: string
  onChange?: (value: T) => any
  value?: T
  isDisabled?: boolean
  prefixName?: string
  className?: string
  mobileView?: boolean
  renderItem?: (item: DropdownItem<T>) => JSX.Element
  extraItem?: () => JSX.Element
  stringValue?: string
  multilevel?: boolean
  searchable?: DropdownSearchable
  emptyMessage?: string
  optionHeight?: string
}

const DropdownField = <T extends any>(props: Props<T>) => {
  const valueState = useState<T>(props.value)
  const screenType = getScreenType(window.screen.width, window.screen.height)
  const [selectedGroup, setSelectedGroup] = useState<string>()
  const [groupHandle, setGroupHandle] = useState<boolean>(false)
  const [itemKeyword, setItemKeyword] = useState<string>('')
  const [typedKeyword, setTypedKeyword] = useState<string>('')
  const [itemList, setItemList] = useState<DropdownItem<T>[]>([])
  const [isDropdownTextFieldBlur, setIsDropdownTextFieldBlur] =
    useState<boolean>(true)
  const [isDropdownContentBlur, setIsDropdownContentBlur] =
    useState<boolean>(true)
  const [isSelectedItemLoaded, setIsSelectedItemLoaded] =
    useState<boolean>(false)

  useEffect(() => {
    if (props.value) {
      handlePropsOnChange(props.value)
    }
  }, [])

  useEffect(() => {
    setItemList(
      props.list.filter(item =>
        item.label.toLowerCase().includes(typedKeyword.toLowerCase())
      )
    )
  }, [props.list, typedKeyword])

  const handlePropsOnChange = (value?: T) => {
    if (props.onChange) {
      props.onChange(value)
    }
  }

  const getSelectedItem = (): DropdownItem<T> | undefined => {
    return itemList.find(item => item.value === valueState[0])
  }

  const selectedItem = getSelectedItem()

  useEffect(() => {
    if (
      selectedItem &&
      selectedItem.label &&
      !itemKeyword &&
      !isSelectedItemLoaded
    ) {
      setItemKeyword(selectedItem.label)
      setIsSelectedItemLoaded(true)
    }
  }, [selectedItem])

  useEffect(() => {
    if (isDropdownTextFieldBlur && isDropdownContentBlur) {
      if (!selectedItem) {
        setTypedKeyword('')
      }
      if (selectedItem && selectedItem.label && !itemKeyword) {
        setItemKeyword(selectedItem.label)
      }
    }
  }, [isDropdownTextFieldBlur, isDropdownContentBlur, selectedItem])

  const updateSelection = (value: T) => {
    valueState[1](value)
  }

  const renderDropdownAndOverlay = (
    fieldContext: RenderingFieldContext,
    dropdownAction: DropdownAction
  ): [ReactNode, ReactNode] => {
    const { value, onChange } = fieldContext
    const { setVisibility, isVisible, toggle } = dropdownAction

    if (value !== undefined && value !== valueState[0]) {
      updateSelection(value)
      handlePropsOnChange(value)
    }

    const onSelect = (item: DropdownItem<T>) => {
      const newItem = {
        ...item,
      }
      onChange(newItem.value)
      updateSelection(item.value)
      handlePropsOnChange(item.value)
      if (props.searchable) {
        setItemKeyword(item.label)
      }
      setVisibility(false)
    }

    let renderItem = (
      item: DropdownItem<T>,
      fontSize?: 10 | 12 | 14 | 16 | 18 | 20 | 24 | 32 | 40
    ) => {
      let isSelected = valueState[0] === item.value
      if (props.stringValue) {
        isSelected = props.stringValue === String(item.value)
      }

      if (item.secondaryLabel && item.secondaryLabel.length > 0) {
        if (typeof item.secondaryLabel === 'string') {
          return (
            <React.Fragment>
              <Text.Span
                dangerousContent={item.label}
                size={fontSize || 14}
              ></Text.Span>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Text.Span color='green' weight='bold'>
                {item.secondaryLabel}
              </Text.Span>
              {isSelected && <Icon.Checklist />}
            </React.Fragment>
          )
        } else {
          return item.secondaryLabel
        }
      } else if (item.disabledLabel && item.disabledLabel.length > 0) {
        return (
          <React.Fragment>
            <Sided.Sided style={{ width: '100%' }}>
              <Sided.Remaining>
                <Text.Span
                  dangerousContent={item.label}
                  size={fontSize || 14}
                ></Text.Span>
              </Sided.Remaining>
              <Sided.Fixed align='right'>
                <Text.Paragraph color='gray' size={12} noWrap noMargin>
                  {item.disabledLabel}
                </Text.Paragraph>
              </Sided.Fixed>
            </Sided.Sided>
            {isSelected && <Icon.Checklist />}
          </React.Fragment>
        )
      } else if (item.imageUrl && item.imageUrl.length > 0) {
        return (
          <React.Fragment>
            <Sided.Sided style={{ width: '100%' }} gutter={16}>
              <Sided.Fixed align='right'>
                <Image src={item.imageUrl} width='60px' height='100%' />
              </Sided.Fixed>
              <Sided.Remaining>
                <Text.Span
                  dangerousContent={item.label}
                  size={fontSize || 14}
                ></Text.Span>
              </Sided.Remaining>
            </Sided.Sided>
            {isSelected && <Icon.Checklist />}
          </React.Fragment>
        )
      } else {
        return (
          <React.Fragment>
            <Text.Span
              dangerousContent={item.label}
              size={fontSize || 14}
            ></Text.Span>
            {isSelected && <Icon.Checklist />}
          </React.Fragment>
        )
      }
    }
    if (props.renderItem) {
      renderItem = props.renderItem
    }

    const clickOptHeader = (item: DropdownItem<T>) => {
      !item.isDisabled && !item.asHeader && onSelect(item)
      setSelectedGroup(item.group)
      if (item.group === selectedGroup) {
        setGroupHandle(!groupHandle)
      } else {
        setGroupHandle(true)
      }
    }

    const renderMultilevelList = (
      fontSize?: 10 | 12 | 14 | 16 | 18 | 20 | 24 | 32 | 40
    ) =>
      itemList.map((item, i) => {
        let isSelected = valueState[0] === item.value
        if (props.stringValue) {
          isSelected = props.stringValue === String(item.value)
        }
        if (item !== undefined && item.label !== undefined) {
          return (
            <>
              {item.asHeader && (
                <li
                  className={classNames(
                    { disabled: item.isDisabled && !isSelected },
                    { 'as-header': item.asHeader },
                    { selected: isSelected },
                    item.indent ? 'item-indent-' + item.indent : ''
                  )}
                  onClick={() => clickOptHeader(item)}
                  key={i}
                >
                  {renderItem(item, fontSize)}
                </li>
              )}
              {item.group === selectedGroup && !item.asHeader && groupHandle && (
                <li
                  className={classNames(
                    { disabled: item.isDisabled && !isSelected },
                    { 'as-header': item.asHeader },
                    { selected: isSelected },
                    item.indent ? 'item-indent-' + item.indent : ''
                  )}
                  onClick={() => clickOptHeader(item)}
                  key={i}
                >
                  {renderItem(item, fontSize)}
                </li>
              )}
            </>
          )
        }
        return <li key='empty' />
      })

    const renderList = (
      fontSize?: 10 | 12 | 14 | 16 | 18 | 20 | 24 | 32 | 40
    ) =>
      itemList.map((item, i) => {
        let isSelected = valueState[0] === item.value
        if (props.stringValue) {
          isSelected = props.stringValue === String(item.value)
        }
        if (item !== undefined && item.label !== undefined) {
          return (
            <li
              className={classNames(
                { disabled: item.isDisabled && !isSelected },
                { 'as-header': item.asHeader },
                { selected: isSelected },
                item.indent ? 'item-indent-' + item.indent : ''
              )}
              onClick={() =>
                !item.isDisabled && !item.asHeader && onSelect(item)
              }
              key={i}
            >
              {renderItem(item, fontSize)}
            </li>
          )
        }
        return <li key='empty' />
      })

    const handleToggle = () => {
      if (props.isDisabled) {
        return
      }
      toggle()
    }

    const renderFieldValue = () => {
      if (props.searchable?.enabled) {
        const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
          if (itemKeyword) {
            setItemKeyword('')
          }
          if (!isVisible) {
            setVisibility(true)
          }
          setTypedKeyword(e.target.value)
        }

        return (
          <Sided.Sided gutter={0}>
            {selectedItem?.imageUrl && (
              <Sided.Fixed style={{ padding: '0 0 0 8px' }}>
                <Image src={selectedItem.imageUrl} width='60px' height='100%' />
              </Sided.Fixed>
            )}
            <Sided.Remaining>
              <input
                type='text'
                name='keyword'
                placeholder={props.placeholder}
                onChange={handleOnChange}
                value={itemKeyword ? itemKeyword : typedKeyword}
                onFocus={() => setIsDropdownTextFieldBlur(false)}
                onBlur={() => setIsDropdownTextFieldBlur(true)}
                autoComplete='off'
              />
            </Sided.Remaining>
          </Sided.Sided>
        )
      }
      if (props.stringValue) {
        return <Text.Span dangerousContent={props.stringValue}></Text.Span>
      } else if (
        selectedItem &&
        selectedItem.label &&
        selectedItem.secondaryLabel === undefined
      ) {
        return <Text.Span dangerousContent={selectedItem.label}></Text.Span>
      } else if (
        selectedItem &&
        selectedItem.label &&
        selectedItem.secondaryLabel !== undefined
      ) {
        return (
          <>
            <Text.Span dangerousContent={selectedItem.label}></Text.Span>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <Text.Span color='green' weight='bold'>
              {selectedItem.secondaryLabel}
            </Text.Span>
          </>
        )
      } else {
        return <Text.Paragraph color='gray'>{props.placeholder}</Text.Paragraph>
      }
    }

    const inputBoxContent = (
      <Sided.Sided className='input-dropdown-box-content'>
        <Sided.Remaining>
          <Wrapper
            className='inner'
            onClick={handleToggle}
            style={{
              position: 'relative',
              padding: props.searchable?.enabled ? '0' : '0 8px',
            }}
          >
            {renderFieldValue()}
            <Wrapper
              onClick={handleToggle}
              style={{
                width: '25px',
                height: '35px',
                position: 'absolute',
                backgroundColor: 'transparent',
                right: '-25px',
                top: '0',
                zIndex: 999,
              }}
            />
          </Wrapper>
        </Sided.Remaining>
        <Sided.Fixed>
          <Icon.Caretdown color='black' onClick={handleToggle} />
        </Sided.Fixed>
      </Sided.Sided>
    )

    const desktopOverlayContent = (
      <ul
        className={`post-input-select-option ${
          isVisible ? 'visible' : 'hidden'
        }`}
        onMouseEnter={() => setIsDropdownContentBlur(false)}
        onMouseLeave={() => setIsDropdownContentBlur(true)}
        style={{ height: props.optionHeight }}
      >
        {itemList && props.multilevel ? renderMultilevelList() : renderList()}
        {itemList.length === 0 && !typedKeyword && (
          <li className='disabled'>
            <Text.Paragraph color='gray' size={12} align='center' noMargin>
              {props.emptyMessage || `Tidak ada ${props.label}`}
            </Text.Paragraph>
          </li>
        )}
        {props.searchable?.enabled &&
          typedKeyword &&
          itemList.length === 0 &&
          (props.searchable.customNotFound ? (
            props.searchable.customNotFound
          ) : (
            <li style={{ textAlign: 'center' }}>
              <Text.Span weight='bold'>
                {props.searchable?.notFoundMessage}
              </Text.Span>
            </li>
          ))}
        {props.extraItem && props.extraItem()}
      </ul>
    )

    const mobileOverlayContent = !!itemList.length && (
      <Wrapper
        className={`post-input-select-option-mobile post-input-select-option ${
          isVisible ? 'visible' : 'hidden'
        }`}
        position='fixed'
      >
        <Wrapper padding='24px'>
          <Sided.Sided justify='center' gutter={16}>
            <Sided.Fixed>
              <Icon.ArrowLeft size={24} color='black' onClick={toggle} />
            </Sided.Fixed>
            <Sided.Remaining>
              <Text.Span size={24}>{props.placeholder}</Text.Span>
            </Sided.Remaining>
          </Sided.Sided>
        </Wrapper>
        <ul>{itemList && renderList(18)}</ul>
      </Wrapper>
    )

    return [
      inputBoxContent,
      props.mobileView && screenType === 'MOBILE'
        ? mobileOverlayContent
        : desktopOverlayContent,
    ]
  }

  const renderInner = (fieldContext: RenderingFieldContext) => (
    <InputBase
      label={props.label}
      isDisabled={props.isDisabled}
      className={props.className}
      error={fieldContext.field.isDirty && fieldContext.field.error}
    >
      <Dropdown
        className='post-input-select'
        onClose={() => fieldContext.onBlur()}
        alwaysRenderOverlay={true}
        isDisabled={props.isDisabled}
        render={dropdownAction =>
          renderDropdownAndOverlay(fieldContext, dropdownAction)
        }
      ></Dropdown>
    </InputBase>
  )

  return (
    <Form.Field
      mapSubItem={props.mapSubItem}
      name={props.name}
      prefixName={props.prefixName}
      render={renderInner}
    />
  )
}

export default DropdownField
