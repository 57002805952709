import React, { useState, useEffect, useRef, useCallback } from 'react'
import InputBase from '../base'
import { Icon, Text, Form, Sided } from 'views/components'
import './style.css'
import classNames from 'classnames'
import { RenderingFieldContext } from 'views/components/Form'
import { Dropdown, DropdownAction } from 'views/components/Dropdown'
import { DropdownItem } from '../Dropdown'
import { highlight, removeHighlight } from 'utilities/strings/highlighter'
import Wrapper from 'views/components/Wrapper'
import { getScreenType } from 'utilities/screen/getScreentType'
import Loader from 'views/components/Loader'
import Scroll from 'views/components/InfiniteScrollWrapper'
import { convertAreasToDropdownItems } from 'views/modules/Home/selector'
import { LocationModel } from 'domains/location/models'
import { locationService } from 'injectors'

interface Props<T> {
  label?: string
  secondaryLabel?: string
  placeholder?: string
  searchFunc: (keyword: string) => Promise<DropdownItem<T>[]>
  name: string
  onChange?: (value: T) => any
  className?: string
  initValue?: any
  resolveInitValueFunc?: (initValue: T) => Promise<DropdownItem<T>>
  mobileView?: boolean
  note?: string
  showUpside?: boolean
}

export default <T extends any>(props: Props<T>) => {
  const [isLoading, setLoading] = useState<boolean>(false)
  const [onTouch, setOnTouch] = useState<boolean>(false)
  const [selectedItem, setSelectedItem] = useState<DropdownItem<T>>(
    props.initValue
  )
  const [list, setList] = useState([])
  const [keyword, setKeyword] = useState<string>('')
  const screenType = getScreenType(window.screen.width, window.screen.height)

  useCallback((flag: boolean) => setLoading(flag), [])

  const loadInitialValue = async () => {
    if (props.initValue && props.resolveInitValueFunc) {
      const item = await props.resolveInitValueFunc(props.initValue)
      if (item) {
        setSelectedItem(item)
      }
    }
  }

  const transformLocation = (keyword: string, locations: LocationModel[]) => {
    return convertAreasToDropdownItems(locations).map(x => ({
      ...x,
      label: highlight(x.label, keyword.toUpperCase(), y => `<b>${y}</b>`),
    }))
  }

  useEffect(() => {
    loadInitialValue()
  }, [props.initValue])

  const domRef = useRef()

  const renderDropdownAndOverlay = (
    fieldContext: RenderingFieldContext,
    dropdownAction: DropdownAction
  ): [React.ReactNode, React.ReactNode] => {
    const { value, onChange } = fieldContext
    const { setVisibility, isVisible } = dropdownAction

    if (value && selectedItem && value != selectedItem.value) {
      updateSelection(value)
      handlePropsOnChange(value)
    }

    const loadList = async (keyword: string) => {
      setKeyword(keyword)
      setLoading(true)
      try {
        const l = await props.searchFunc(keyword)
        setList(l)
      } catch {}
      setLoading(false)
    }

    const updateList = async () => {
      if (list.length > 5) {
        try {
          const locations = await locationService.loadMore()
          const newArr = list.concat(transformLocation(keyword, locations))
          setList(newArr)
        } catch {}
      }
    }

    const onSelect = (item: DropdownItem<T>) => {
      onChange(item.value)
      setKeyword(item.label)
      updateSelection(item)
      handlePropsOnChange(item.value)
      setVisibility(false)
    }

    const renderList = () =>
      list.map((item, i) => {
        const isSelected = selectedItem && selectedItem.value === item.value
        if (item.value !== undefined && item.label !== undefined) {
          return (
            <li
              className={classNames(
                { disabled: item.isDisabled },
                { selected: selectedItem === item }
              )}
              onClick={() => !item.isDisabled && onSelect(item)}
              key={i}
            >
              <Text.Span dangerousContent={item.label}></Text.Span>
              {isSelected && <Icon.Checklist />}
            </li>
          )
        }
        return null
      })

    const showSearch = () => {
      setVisibility(true)
      if (!domRef || !domRef.current) {
        return
      }
      const currentNode = domRef.current as any
      currentNode.focus()
    }

    const isShowingSelection = selectedItem != undefined && !isVisible

    const canShowDropdown = list && list.length > 0 && isVisible

    const renderPlaceHolder = () => (
      <span style={{ color: '#e0e0e0' }}>{props.placeholder}</span>
    )

    const renderSelectedItem = () => (
      <Text.Span
        dangerousContent={removeHighlight(
          selectedItem.shownValue
            ? selectedItem.shownValue
            : selectedItem.label || ''
        )}
      />
    )

    const isRenderPlaceHolder = () =>
      props.placeholder && !selectedItem && !onTouch

    const inputBoxContent = (
      <div
        className={`inner ${isShowingSelection && 'display-mode'}`}
        onClick={() => showSearch()}
      >
        {isRenderPlaceHolder()
          ? renderPlaceHolder()
          : selectedItem && renderSelectedItem()}
      </div>
    )

    const desktopOverlayContent = () => (
      <>
        {props.showUpside && !canShowDropdown && (
          <div style={{ height: '200px' }}></div>
        )}
        <ul
          className={`post-input-select-option ${
            isVisible ? 'visible' : 'hidden'
          }`}
          style={props.showUpside && { position: 'relative', top: '-305px' }}
        >
          {!props.showUpside && (
            <div className='search-input'>
              <InputBase>
                <input
                  ref={domRef}
                  placeholder={props.placeholder}
                  value={removeHighlight(keyword)}
                  onChange={evt => loadList(evt.target.value)}
                  onBlur={() => setOnTouch(false)}
                  type='string'
                />
              </InputBase>
            </div>
          )}
          {canShowDropdown && !isLoading ? (
            <Scroll className='sub-district-list' onBottom={() => updateList()}>
              {renderList()}
            </Scroll>
          ) : (
            props.note && (
              <li className='note'>
                <Text.Span>{props.note}</Text.Span>
              </li>
            )
          )}
          {props.showUpside && (
            <div className='search-input'>
              <InputBase>
                <input
                  ref={domRef}
                  placeholder={props.placeholder}
                  value={removeHighlight(keyword)}
                  onChange={evt => loadList(evt.target.value)}
                  onBlur={() => setOnTouch(false)}
                  type='string'
                />
              </InputBase>
            </div>
          )}
        </ul>
      </>
    )

    const mobileOverlayContent = (
      <Wrapper
        position='fixed'
        className={`post-autocomplete-mobile post-input-select-option ${
          isVisible ? 'visible' : 'hidden'
        }`}
      >
        <Wrapper padding='24px'>
          <Sided.Sided justify='center' gutter={16}>
            <Sided.Fixed>
              <Icon.ArrowLeft
                size={24}
                color='black'
                onClick={() => setVisibility(false)}
              />
            </Sided.Fixed>
            <Sided.Remaining>
              <Text.Span size={24}>{props.placeholder}</Text.Span>
            </Sided.Remaining>
          </Sided.Sided>
        </Wrapper>
        <Wrapper className='keyword-input'>
          <input
            placeholder={props.placeholder}
            onChange={evt => loadList(evt.target.value)}
            type='string'
          />
          <Icon.Search />
        </Wrapper>
        {canShowDropdown && (isLoading ? <Loader /> : <ul>{renderList()}</ul>)}
      </Wrapper>
    )

    return [
      inputBoxContent,
      props.mobileView && screenType === 'MOBILE'
        ? mobileOverlayContent
        : desktopOverlayContent(),
    ]
  }

  const handlePropsOnChange = (value?: T) => {
    if (props.onChange) {
      props.onChange(value)
    }
  }

  const updateSelection = (value: DropdownItem<T>) => {
    setSelectedItem(value)
  }

  const renderInner = (fieldContext: RenderingFieldContext) => (
    <InputBase
      label={props.label}
      secondaryLabel={props.secondaryLabel}
      className={props.className}
      suffix={<Icon.Caretdown />}
      error={fieldContext.field.isDirty && fieldContext.field.error}
    >
      <Dropdown
        className='post-input-select autocomplete'
        onClose={() => fieldContext.onBlur()}
        alwaysRenderOverlay={true}
        render={dropdownAction =>
          renderDropdownAndOverlay(fieldContext, dropdownAction)
        }
      ></Dropdown>
    </InputBase>
  )
  return <Form.Field name={props.name} render={renderInner} />
}
