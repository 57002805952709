import React, { useState, useContext, useEffect, useRef } from 'react'
import './style.scss'
import { Wrapper, Grid, Text, VSided } from 'views/components'
import { BaseParentProps } from '../props'
import classNames from 'classnames'
import Context from './context'

interface TabInfo {
  identifier: string
  name: string
}

interface ITabsProps extends BaseParentProps {
  className?: string
  defaultActiveKey?: string
  onChangeTab?: (identifier: string) => any
  type?: 'full' | 'fixed'
}

export const Tabs: React.FC<ITabsProps> = props => {
  const tabsRef = useRef<TabInfo[]>([])
  const [currentTabKey, setCurrentTabKey] = useState<string | undefined>(
    undefined
  )

  const registerTab = (identifier: string, name: string) => {
    tabsRef.current = [
      ...tabsRef.current,
      {
        identifier,
        name,
      },
    ]
    if (identifier == props.defaultActiveKey) {
      setTabKey(identifier)
    }
  }

  const setTabKey = (identifier: string) => {
    setCurrentTabKey(identifier)
    if (props.onChangeTab) {
      props.onChangeTab(identifier)
    }
  }

  const renderHeader = () => {
    if (tabsRef.current.length == 1) {
      return null
    }
    return (
      <VSided.Fixed>
        <Grid.Row capacity={tabsRef.current.length}>
          {tabsRef.current.map(t => (
            <Grid.Column>
              <Wrapper
                className={classNames(
                  'tabs-option',
                  t.identifier === currentTabKey ? 'active' : ''
                )}
                onClick={() => setTabKey(t.identifier)}
              >
                <Text.Heading
                  color={t.identifier === currentTabKey ? 'black' : 'semiGray'}
                  h={6}
                  noMargin
                  align='center'
                >
                  {t.name}
                </Text.Heading>
              </Wrapper>
            </Grid.Column>
          ))}
        </Grid.Row>
      </VSided.Fixed>
    )
  }

  return (
    <Wrapper
      className={classNames(
        'post-tabs',
        props.className || '',
        props.type || 'full'
      )}
    >
      <VSided.VSided gutter={0}>
        {renderHeader()}
        <VSided.Remaining className='tab-content'>
          <Context.Provider
            value={{
              currentTabKey,
              registerTab,
            }}
          >
            {props.children}
          </Context.Provider>
        </VSided.Remaining>
      </VSided.VSided>
    </Wrapper>
  )
}

export interface TabPaneProps extends BaseParentProps {
  name: string
  identifier: string
}

export const TabPane: React.FC<TabPaneProps> = props => {
  const { currentTabKey, registerTab } = useContext(Context)

  useEffect(() => {
    registerTab(props.identifier, props.name)
  }, [])

  const isCurrentTab = currentTabKey == props.identifier

  return (
    <Wrapper
      className={classNames('inner-content', isCurrentTab ? 'shown' : 'hidden')}
      height='100%'
    >
      {props.children}
    </Wrapper>
  )
}
