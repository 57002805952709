import { BaseService } from 'domains/commons/services'
import { OutletModel, OutletRequest } from '../models'
import { ICommonStorage } from 'domains/commons/contracts/storage'
import { Api } from 'domains/contracts/api'
import { IOutletStorage } from '../contracts/storage'
import { toDict } from 'utilities/converter/list'

const SET_MUlTI_OUTLET = 'SET_MUlTI_OUTLET'

export class OutletService extends BaseService implements IOutletService {
  outletStorage: IOutletStorage

  constructor(
    commonStorage: ICommonStorage,
    api: Api,
    outletStorage: IOutletStorage
  ) {
    super(commonStorage, api)
    this.outletStorage = outletStorage
  }

  public getOutlets = async (): Promise<OutletModel[]> => {
    try {
      this.setLoading(SET_MUlTI_OUTLET, true)
      const data = await this.api.management.outlet.getOutlets()
      this.outletStorage.setOutlets(toDict(data, (p: OutletModel) => p.id))
      this.setLoading(SET_MUlTI_OUTLET, false)
      return data
    } catch (error) {
      throw error
    }
  }

  public createOutlet = async (request: OutletRequest) => {
    try {
      const outletResponse = await this.api.management.outlet.createOutlet(
        request
      )
      const { outlets } = this.getState().management.outlet
      const newOutlets = {
        [outletResponse.id]: outletResponse,
        ...outlets,
      }
      this.outletStorage.setOutlets(newOutlets)
      return outletResponse
    } catch (error) {
      throw error
    }
  }

  public updateOutlet = async (request: OutletRequest, outletId: string) => {
    try {
      const outletResponse = await this.api.management.outlet.updateOutlet(
        request,
        outletId
      )
      const { outlets } = this.getState().management.outlet
      const newOutlets = outlets
      newOutlets[outletResponse.id] = outletResponse
      this.outletStorage.setOutlets(newOutlets)
    } catch (error) {
      throw error
    }
  }

  public deleteOutlet = async (outletId: string) => {
    try {
      await this.api.management.outlet.deleteOutlet(outletId)
      const { outlets } = this.getState().management.outlet
      delete outlets[outletId]
    } catch (error) {
      throw error
    }
  }
}

export interface IOutletService {
  getOutlets: () => Promise<OutletModel[]>
  createOutlet: (request: OutletRequest) => Promise<OutletModel>
  updateOutlet: (request: OutletRequest, outletId: string) => Promise<void>
  deleteOutlet: (outletId: string) => Promise<void>
}
