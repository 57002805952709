import { history } from 'storages'
import $ from 'jquery'

const adjustScreen = () => {
  const path = history.location.pathname
  if (path.startsWith('/auth')) {
    adjustNaturalMobileView()
  } else {
    adjustDashboardViewForMobile()
  }
}
const adjustDashboardViewForMobile = () => {
  const win = $(window)
  const w = win.width()
  const h = win.height()

  if (w > 1200) {
    setDefaultScreen()
    return
  }

  const scaler = 1200 / w
  const transforms = []
  transforms.push(`scale(${1 / scaler})`)
  $('#inner').css('transform', transforms.join(' '))
  $('#inner').css('width', scaler * w + 'px')
  $('#inner').css('height', scaler * h + 'px')
  $('body').removeClass('vertical-mobile-mode')
}

const setDefaultScreen = () => {
  $('body').removeClass('vertical-mobile-mode')
  $('#inner').css('transform', '')
  $('#inner').css('width', '100%')
  $('#inner').css('height', '100%')
}

const adjustNaturalMobileView = () => {
  const win = $(window)
  const w = win.width()
  const h = win.height()

  if (w > 520) {
    setDefaultScreen()
    return
  }

  $('body').addClass('vertical-mobile-mode')

  const scaler = 520 / w
  const transforms = []
  transforms.push(`scale(${1 / scaler})`)
  $('#inner').css('transform', transforms.join(' '))
  $('#inner').css('width', scaler * w + 'px')
  $('#inner').css('height', scaler * h + 'px')
}

export const activateScreenChecker = () => {
  $(document).ready(function () {
    history.listen(x => {
      adjustScreen()
    })

    const win = $(window)
    win.resize(adjustScreen)
    window.setTimeout(adjustScreen, 100)
  })
}
