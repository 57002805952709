import React, { useRef } from 'react'
import './style.css'
import classNames from 'classnames'
import { BaseParentProps, BaseProps } from '../props'

interface Props extends BaseParentProps, BaseProps {
  onBottom?: () => any
  className?: string
}

const Scroll: React.FC<Props> = props => {
  const myDivElement = useRef(null)

  const style: React.CSSProperties = {
    ...props.style,
  }

  const className = classNames('infinite-scroll-wrapper', props.className || '')

  const handleOnScroll = (evt: React.UIEvent<HTMLDivElement>) => {
    if (
      evt.currentTarget.scrollHeight <=
      Math.ceil(evt.currentTarget.scrollTop + evt.currentTarget.clientHeight)
    ) {
      if (props.onBottom) {
        props.onBottom()
      }
    }
  }

  if (myDivElement.current && props.onBottom) {
    if (
      myDivElement.current.clientHeight === myDivElement.current.scrollHeight
    ) {
      props.onBottom()
    }
  }

  return (
    <div
      ref={myDivElement}
      className={className}
      style={style}
      onScroll={handleOnScroll}
    >
      {props.children}
    </div>
  )
}

export default Scroll
