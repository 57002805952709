import * as React from 'react'
import { BaseProps, BaseParentProps } from '../props'
import classNames from 'classnames'
import './style.scss'
import { Text } from 'views/components'
import { Loading } from '../Loading'
import {
  TableConfig,
  ColumnConfig,
  SegmentedData,
  ItemWithSegment,
  convertToTableConfig as convertTC,
  toListAsRowsWithKeysAsSegmentedColumns as tlr,
  toListAsSegmentedColumnsWithKeysAsRows as tls,
} from 'utilities/table'
import { Dict } from 'utilities/type'

export type RSegmentedData<X, A, B> = SegmentedData<X, React.ReactNode, A, B>
interface RowProps extends BaseProps, BaseParentProps {
  onClick?: () => any
}

const Row: React.FC<RowProps> = (props: RowProps) => {
  const rowStyle = classNames(props.className || '', 'post-table-tr', {
    'cursor-pointer': props.onClick,
  })
  return (
    <tr className={rowStyle} onClick={props.onClick}>
      {props.children}
    </tr>
  )
}

export interface ColumnProps extends BaseProps, BaseParentProps {
  colSpan?: number
  width?: string
  align?: 'left' | 'center' | 'right'
  sticky?: boolean
  verticalAlign?: 'top' | 'middle' | 'bottom'
}

const Column: React.FC<ColumnProps> = (props: ColumnProps) => {
  const columnStyle = classNames(props.className || '', 'post-table-td', {
    'post-table-td-sticky': props.sticky,
  })
  const style: React.CSSProperties = {
    width: props.width,
    textAlign: props.align || 'left',
  }
  return (
    <td style={style} colSpan={props.colSpan} className={columnStyle}>
      {props.children}
    </td>
  )
}
export interface RTableConfig<T> extends TableConfig<T, React.ReactNode> {
  rowClass?: string
}

export interface RColumnConfig<T> extends ColumnConfig<T, React.ReactNode> {
  title?: string | JSX.Element
  className?: string
  isHidden?: boolean
  sticky?: boolean
  align?: 'left' | 'center' | 'right'
  width?: string
  verticalAlign?: 'top' | 'middle' | 'bottom'
}

interface SimpleTableProps extends BaseProps, BaseParentProps {
  type?: 'bordered' | 'striped' | 'hovered' | 'borderBottom'
  size?: 'default' | 'fat'
}
interface TableProps<T> extends SimpleTableProps {
  tableConfig?: RTableConfig<T>
  list: T[]
  isLoading?: boolean
}

export function getDerivedColumnProps<T>(col: RColumnConfig<T>): ColumnProps {
  return {
    sticky: col.sticky,
    className: col.className,
    align: col.align,
    width: col.width,
    verticalAlign: col.verticalAlign,
  }
}

export function toListAsSegmentedColumnsWithKeysAsRows<T, S = any>(
  stickyColumns: RColumnConfig<string>[],
  list: T[],
  toKeyValues: (item: T) => Dict<S>,
  columnConfigFunc: (
    itemWithSegment: ItemWithSegment<T, S>
  ) => RColumnConfig<string>[],
  sortingKeysFunc: (
    a: string,
    b: string,
    availableSegments: Dict<S[]>
  ) => number = (a, b) => 0
): RSegmentedData<string, T, S> {
  return tls(
    stickyColumns,
    list,
    toKeyValues,
    columnConfigFunc,
    sortingKeysFunc,
    convertToReactTableConfig
  )
}

export function toListAsRowsWithKeysAsSegmentedColumns<T, S = any>(
  stickyColumns: RColumnConfig<ItemWithSegment<T, S>>[],
  list: T[],
  toKeyValues: (item: T) => Dict<S>,
  columnConfigFunc: (key: string) => RColumnConfig<ItemWithSegment<T, S>>[],
  sortingKeysFunc: (
    a: string,
    b: string,
    availableSegments: Dict<S[]>
  ) => number = (a, b) => 0
): RSegmentedData<ItemWithSegment<T, S>, T, S> {
  return tlr(
    stickyColumns,
    list,
    toKeyValues,
    columnConfigFunc,
    sortingKeysFunc,
    convertToReactTableConfig
  )
}

function colsToRow<T>(
  cols: React.ReactNode[],
  config: RTableConfig<T>
): React.ReactNode {
  return <Row className={config.rowClass || ''}>{cols}</Row>
}

export function convertToReactTableConfig<T>(configs: RColumnConfig<T>[]) {
  const newConfigs: RColumnConfig<T>[] = configs
    .filter(x => !x.isHidden)
    .map(c => ({
      ...c,
      body: (item: T, index: number, conf?: RColumnConfig<T>) =>
        makeCell(c.body(item, index), getDerivedColumnProps(c)),
      headers: c.title
        ? () => [makeTitleCellHeader(c.title, getDerivedColumnProps(c))]
        : c.headers,
    }))

  return convertTC<T, React.ReactNode, React.ReactNode>(
    newConfigs,
    (cols: React.ReactNode[], tableConfig: RTableConfig<T>): React.ReactNode =>
      colsToRow<T>(cols, tableConfig)
  )
}

const SimpleTable = (props: SimpleTableProps) => {
  return (
    <div className='post-table' style={{ ...props.style }}>
      <table
        className={classNames(
          props.className || '',
          'post-table-type-' + (props.type || 'striped'),
          'post-table-size-' + (props.size || 'default')
        )}
      >
        {props.children}
      </table>
    </div>
  )
}

export default class Table<T> extends React.Component<TableProps<any>> {
  public static Row = Row
  public static Simple = SimpleTable
  public static Column = Column

  renderLoading = () => {
    return <Loading />
  }

  renderBody() {
    if (!this.props.list || this.props.list.length === 0) {
      return null
    }
    return (
      <tbody>
        {this.props.list.map((item: T, index: number) =>
          this.props.tableConfig.itemToRowFunc(item, index)
        )}
      </tbody>
    )
  }

  renderHeader() {
    return <thead>{this.props.tableConfig.headerFunc(this.props.list)}</thead>
  }

  renderFooter() {
    return <tfoot>{this.props.tableConfig.footerFunc(this.props.list)}</tfoot>
  }

  renderList() {
    let innerChildren: React.ReactElement<any> | null = null
    if (this.props.children) {
      innerChildren = this.props.children as any
    }

    return (
      <React.Fragment>
        {this.renderBody()}
        {innerChildren}
        {this.renderFooter()}
      </React.Fragment>
    )
  }

  render() {
    return (
      <SimpleTable
        className={this.props.className || ''}
        size={this.props.size}
        type={this.props.type}
        style={{ ...this.props.style }}
      >
        {this.renderHeader()}
        {this.props.isLoading ? this.renderLoading() : this.renderList()}
      </SimpleTable>
    )
  }
}

export function makeCell(content: React.ReactNode, props: ColumnProps = {}) {
  return (
    <Table.Column
      sticky={props.sticky}
      colSpan={props.colSpan}
      className={props.className || ''}
      align={props.align}
      width={props.width}
      verticalAlign={props.verticalAlign}
    >
      {content}
    </Table.Column>
  )
}

export function makeTitleCellHeader(
  title: string | JSX.Element | number | null,
  props: ColumnProps = {}
) {
  let content: any = title
  if (title == null) {
    content = <span>&nbsp;</span>
  }
  return makeCell(<Text.Heading h={6}>{content}</Text.Heading>, props)
}
