import { EnvironmentConfig } from 'config/def'
import defaultConfig from 'config/default'

const config: EnvironmentConfig = {
  isTracking: false,
  featureFlags: {
    ...defaultConfig.featureFlags,
  },
}
export default config
