import {
  ProductModel,
  CreateVariationRequest,
  CreateComboProductRequest,
  MassProduct,
  MassUploadRequest,
  MassUpdateRequest,
  CreateSingleProductRequest,
  ProductStockHistory,
  ProductModelForMassUpload,
} from '../models'
import { toDict, toList } from 'utilities/converter/list'
import ROUTE from 'constants/routes'
import {
  ProductListQueryString,
  ProductStockHistoryListQueryString,
} from '../states'
import { IProductStorage } from '../contracts/storage'
import { ICommonStorage } from 'domains/commons/contracts/storage'
import { BaseService } from 'domains/commons/services'
import { Api } from 'domains/contracts/api'
import { History } from 'history'

import { convertExcelBinaryTo2DArray } from 'utilities/csv/fileTo2DArray'
import { ExtractedFileData } from 'utilities/file/upload'
import UUID from 'uuidjs'

const SET_PRODUCTS = 'SET_PRODUCTS'
const SET_PRODUCT_STOCK_HISTORIES = 'SET_PRODUCT_STOCK_HISTORIES'

export class ProductService extends BaseService implements IProductService {
  productStorage: IProductStorage
  history: History

  constructor(
    commonStorage: ICommonStorage,
    api: Api,
    productStorage: IProductStorage,
    history: History
  ) {
    super(commonStorage, api)
    this.productStorage = productStorage
    this.history = history
  }

  public checkMassProductCategory = (
    name: string,
    massProducts: MassProduct[]
  ) => {
    const { categories } = this.getState().management.category
    const categoryList = toList(categories)
    for (let i = 0; i < categoryList.length; i++) {
      if (categoryList[i].name.toLowerCase() === name.toLowerCase()) {
        return categoryList[i].id
      }
    }
    const newCategories = massProducts.map(p => ({
      id: p.categoryId,
      name: p.categoryName,
    }))
    return newCategories.find(p => p.name === name)?.id || UUID.generate()
  }

  public uploadMassProducts = async (
    file: ExtractedFileData
  ): Promise<ProductModelForMassUpload> => {
    const jsonData = convertExcelBinaryTo2DArray(file.binary)
    const massProducts: MassProduct[] = []
    jsonData.list.forEach((x: any) => {
      massProducts.push({
        productId: x['Id Produk'] || '',
        productName: x['Nama Produk'] || '',
        categoryName: x['Kategori'] !== undefined ? x['Kategori'] : undefined,
        categoryId: x['Kategori']
          ? this.checkMassProductCategory(x['Kategori'], massProducts)
          : undefined,
        price: parseInt(x['Harga Jual']) > 0 ? x['Harga Jual'] : undefined,
        unitCost: parseInt(x['Harga Modal']) > 0 ? x['Harga Modal'] : undefined,
        sku: String(x['SKU']) !== 'undefined' ? String(x['SKU']) : undefined,
        barcode:
          String(x['Barcode']) !== 'undefined'
            ? String(x['Barcode'])
            : undefined,
        trackStock:
          typeof x['Kelola Stok (Iya / Tidak)'] === 'string' &&
          x['Kelola Stok (Iya / Tidak)'].toLowerCase() === 'iya'
            ? true
            : false,
        stockAdjustment:
          parseInt(x['Stok Masuk']) > 0
            ? {
                type: 'addition',
                quantity: x['Stok Masuk'],
                note: '',
              }
            : parseInt(x['Stok Saat Ini']) > 0
            ? {
                type: 'recalculation',
                quantity: x['Stok Saat Ini'],
                note: '',
              }
            : {
                type: 'addition',
                quantity: 0,
                note: '',
              },
        minStock:
          parseInt(x['Stok Minimum']) > 0 ? x['Stok Minimum'] : undefined,
        description: x['Deskripsi'] || '',
        weight: parseInt(x['Berat (gram)']) > 0 ? x['Berat (gram)'] : undefined,
        dimension: {
          length:
            parseInt(x['Panjang (cm)']) > 0 ? x['Panjang (cm)'] : undefined,
          width: parseInt(x['Lebar (cm)']) > 0 ? x['Lebar (cm)'] : undefined,
          height: parseInt(x['Tinggi (cm)']) > 0 ? x['Tinggi (cm)'] : undefined,
        },
      } as MassProduct)
    })

    return {
      massProducts,
      fileInfo: file,
    }
  }

  public getProducts = async (
    queryParams: ProductListQueryString = {}
  ): Promise<ProductModel[]> => {
    this.setLoading(SET_PRODUCTS, true)
    const products = await this.api.management.product.getProducts(queryParams)
    this.productStorage.setProducts(toDict(products, (p: ProductModel) => p.id))
    this.setLoading(SET_PRODUCTS, false)
    const lastProductId = this.getLastProductId(products)
    this.productStorage.setProductListRequest({
      ...queryParams,
      startingAfter: lastProductId || queryParams.startingAfter,
    } as ProductListQueryString)
    this.productStorage.setProductListReachBottom(products.length == 0)

    return products
  }

  public getProductStockHistories = async (
    productId: string,
    queryParams: ProductStockHistoryListQueryString = {}
  ): Promise<ProductStockHistory[]> => {
    this.setLoading(SET_PRODUCT_STOCK_HISTORIES, true)
    queryParams.limit = queryParams.limit || 10
    const histories =
      await this.api.management.product.getProductStockHistories(
        productId,
        queryParams
      )
    this.setLoading(SET_PRODUCTS, false)
    return histories
  }

  private getLastProductId = (products: ProductModel[]): string | undefined => {
    return products.length > 0 ? products[products.length - 1].id : undefined
  }

  public loadMore = async (): Promise<ProductModel[]> => {
    const productListManager =
      this.getState().management.product.productListManager
    if (productListManager.hasReachedBottom) {
      return Promise.resolve([])
    }
    if (productListManager.isLoadingMoreProduct) {
      return Promise.resolve([])
    }
    this.productStorage.setLoadingMoreProduct(true)
    const queryParams = productListManager.request
    const products = await this.api.management.product.getProducts(queryParams)
    this.productStorage.pushProducts(
      toDict(products, (p: ProductModel) => p.id)
    )
    const lastProductId = this.getLastProductId(products)
    this.productStorage.setLoadingMoreProduct(false)
    this.productStorage.setProductListRequest({
      ...queryParams,
      startingAfter: lastProductId || queryParams.startingAfter,
    } as ProductListQueryString)
    this.productStorage.setProductListReachBottom(products.length == 0)

    return products
  }

  public getProduct = async (productId: string): Promise<ProductModel> => {
    this.setLoading('SET_PRODUCT', true)
    const product = await this.api.management.product.getProduct(productId)
    this.productStorage.setProducts({
      [product.id]: product,
    })
    this.setLoading('SET_PRODUCT', false)

    return product
  }

  public getProductByBarcode = async (
    barcode: string
  ): Promise<ProductModel> => {
    this.setLoading('SET_PRODUCT', true)
    const product = await this.api.management.product.getProductByBarcode(
      barcode
    )
    this.productStorage.setProducts({ [product.id]: product })
    this.setLoading('SET_PRODUCT', false)

    return product
  }

  public getFavoriteProduct = async (id: string): Promise<ProductModel> => {
    this.setLoading('SET_PRODUCT', true)
    const favoriteProduct =
      await this.api.management.product.getFavoriteProduct(id)
    this.productStorage.setProducts({ [favoriteProduct.id]: favoriteProduct })
    this.setLoading('SET_PRODUCT', false)

    return favoriteProduct
  }

  public getComboProducts = async () => {
    this.setLoading('SET_COMBO_PRODUCT', true)
    const comboProducts = await this.api.management.product.getComboProducts()
    this.productStorage.setProducts(
      toDict(comboProducts, (p: ProductModel) => p.id)
    )
    this.setLoading('SET_COMBO_PRODUCT', false)

    return comboProducts
  }

  public createSingleProduct = async (
    request: CreateSingleProductRequest,
    headers: object = {}
  ) => {
    this.setLoading('CREATE_PRODUCT', true)
    try {
      const productResponse =
        await this.api.management.product.createSingleProduct(request, headers)
      this.productStorage.unshiftProducts({
        [productResponse.id]: productResponse,
      })
    } catch (error) {
      throw error
    }
    this.setLoading('CREATE_PRODUCT', false)
  }

  public createVariationProduct = async (
    request: CreateVariationRequest
  ): Promise<ProductModel> => {
    this.setLoading('SET_VARIATION_PRODUCT', true)
    const variationProduct =
      await this.api.management.product.createVariationProduct(request)
    this.productStorage.setVariationProducts({
      [variationProduct.id]: variationProduct,
    })
    this.setLoading('SET_VARIATION_PRODUCT', false)

    return variationProduct
  }

  public createComboProduct = async (request: CreateComboProductRequest) => {
    try {
      await this.api.management.product.createComboProduct(request)
    } catch (error) {
      throw error
    }
  }

  public updateSingleProduct = async (
    request: CreateSingleProductRequest,
    id: string
  ) => {
    try {
      this.setLoading('SET_PRODUCT', true)
      const data = await this.api.management.product.updateSingleProduct(
        request,
        id
      )
      const { management } = this.getState()
      const { product } = management
      const newProducts = product.products
      newProducts[data.id] = data
      this.productStorage.setProducts(newProducts)
      this.setLoading('SET_PRODUCT', false)
      this.history.push(ROUTE.MANAGEMENT_PRODUCT_LIST)
    } catch (error) {
      throw error
    }
  }

  public deleteProduct = async (id = '') => {
    try {
      const queryParams = id && `/${id}`
      await this.api.management.product.deleteProduct(queryParams)
      const { management } = this.getState()
      const { product } = management
      const newProducts = product.products
      delete newProducts[id]
      this.productStorage.setProducts(newProducts)
    } catch (error) {
      throw error
    }
  }

  public uploadMass = async (request: MassUploadRequest) => {
    try {
      this.setLoading('UPLOAD_MASS', true)
      await this.api.management.product.uploadMass(request)
      this.getProducts({ limit: 10 })
      this.setLoading('UPLOAD_MASS', false)
    } catch (error) {
      throw error
    }
  }

  public updateMass = async (request: MassUpdateRequest) => {
    try {
      this.setLoading('UPDATE_MASS', true)
      await this.api.management.product.updateMass(request)
      this.getProducts({ limit: 10 })
      this.setLoading('UPDATE_MASS', false)
    } catch (error) {
      throw error
    }
  }
}

export interface IProductService {
  getProductStockHistories: (
    productId: string,
    queryParams: ProductStockHistoryListQueryString
  ) => Promise<ProductStockHistory[]>
  uploadMassProducts: (
    file: ExtractedFileData
  ) => Promise<ProductModelForMassUpload>
  getProducts: (queryParams: ProductListQueryString) => Promise<ProductModel[]>
  getProduct: (productId: string) => Promise<ProductModel>
  loadMore: () => Promise<ProductModel[]>
  getProductByBarcode: (barcode: string) => Promise<ProductModel>
  getFavoriteProduct: (id: string) => Promise<ProductModel>
  getComboProducts: () => Promise<ProductModel[]>
  createSingleProduct: (
    request: CreateSingleProductRequest,
    headers?: object
  ) => void
  createVariationProduct: (
    request: CreateVariationRequest
  ) => Promise<ProductModel>
  createComboProduct: (request: CreateComboProductRequest) => void
  updateSingleProduct: (request: CreateSingleProductRequest, id: string) => void
  deleteProduct: (id?: string) => void
  uploadMass: (request: MassUploadRequest) => Promise<void>
  updateMass: (request: MassUpdateRequest) => Promise<void>
  checkMassProductCategory: (
    name: string,
    massProducts: MassProduct[]
  ) => string
}
