import React, { useState, useEffect } from 'react'
import { history } from 'storages'
import { Menu } from 'antd'
import { Text, Icon, Sided } from '..'
import {
  RouteDescription,
  getSubmenus,
  hasCurrentPath,
  findFirst,
} from 'domains/layouts/services/route.item'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import { layoutService } from 'injectors'
import selector from './selector'
import { flatten } from 'utilities/type'
import { Break } from '../Common'
import netcore from 'utilities/netcore'

const { Item } = Menu

const SidebarChild: React.FC<{}> = props => {
  const { selectedRoute } = useSelector(selector)

  const [expandedPath, setExpandedPath] = useState<string | null>(
    selectedRoute ? selectedRoute.path : null
  )

  useEffect(() => {
    if (!selectedRoute || !selectedRoute.children) {
      return
    }
    selectedRoute.children
      .filter(x => !!x.children)
      .forEach(r => {
        if (history.location.pathname == r.path) {
          return
        }
        if (hasCurrentPath(r, history.location.pathname)) {
          setExpandedPath(r.path)
        }
      })
  }, [selectedRoute])

  const renderMenu = (
    route: RouteDescription,
    isMenuExpanded: boolean,
    indent = 0
  ) => {
    if (getSubmenus(route).length === 0) {
      return null
    }

    const menuItems = route.children.map((r: RouteDescription) => {
      const style = { marginLeft: indent }

      const isExpanded = expandedPath && hasCurrentPath(r, expandedPath)
      const submenus = getSubmenus(r)
      const isAsParentMenu = submenus.length > 0
      const className = classNames(
        hasCurrentPath(r, history.location.pathname) ? 'selected' : ''
      )

      const netcoreDispatchEvent = () => {
        if (r.key === 'subscriptionHistory') {
          const name = 'subscription_history'
          netcore.dispatchEvent(name)
        }
      }

      const navigate = () => {
        netcoreDispatchEvent()
        if (isAsParentMenu) {
          changeSubmenuVisibility()
        } else {
          history.push(r.path)
        }
      }

      const changeSubmenuVisibility = () => {
        if (isExpanded) {
          setExpandedPath(null)
        } else {
          const selectedMenu = findFirst(r)
          setExpandedPath(r.path)
          history.push(selectedMenu.path)
        }
      }

      const chevron = isExpanded ? (
        <Icon.ChevronUp className={'submenu-indicator'} />
      ) : (
        <Icon.ChevronDown className={'submenu-indicator'} />
      )

      const submenu = (
        <Item style={style} className='expandable' key={r.key + '.submenu'}>
          {renderMenu(r, isExpanded, indent + 16)}
        </Item>
      )
      const arr = [
        <Item
          style={style}
          className={className}
          key={r.key}
          onClick={() => navigate()}
        >
          <Text.Span size={14} align='left'>
            {r.sidebarMenu.label}
          </Text.Span>
          {isAsParentMenu && chevron}
        </Item>,
      ]

      if (isAsParentMenu) {
        arr.push(submenu)
      }
      return arr
    })

    const className = classNames(!isMenuExpanded ? 'shrink' : '')
    return <Menu className={className}>{flatten(menuItems)}</Menu>
  }

  if (!selectedRoute) {
    return null
  }

  return (
    <div className='sidebar v1 child-side'>
      <div className='post-sidebar'>
        <div className='post-sidebar-child'>
          <Sided.Sided
            className='post-sidebar-child-headtext'
            justifyContent='center'
            gutter={16}
            onClick={() => layoutService.setMainMenuView()}
          >
            <Sided.Fixed>
              <Icon.ArrowLeft color='white' />
            </Sided.Fixed>
            <Sided.Remaining>
              <Text.Heading h={5} align='left' color='white'>
                {selectedRoute.sidebarMenu.label}
              </Text.Heading>
            </Sided.Remaining>
          </Sided.Sided>
          <Break height={16} />
          <div className='post-sidebar-child-menu'>
            {renderMenu(selectedRoute, true)}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SidebarChild
