import React from 'react'
import { ModalProps, Modal, DefaultModalService } from 'views/components/Modal'
import {
  Form,
  FormAction,
  VSided,
  Wrapper,
  Sided,
  Button,
  Break,
  Text,
  Panel,
  Divider,
  Field,
} from 'views/components'
import { useSelector } from 'react-redux'
import selector from './selector'
import { resetDataFormsSchema } from './resetDataFormSchema'
import { commonService, userService } from 'injectors'
import ResetDataSuccessModal from './ResetDataSuccessModal'
import { ConfirmationResetDataModal } from './ConfirmationResetData'

type ResetDataSettingProps = ModalProps

interface ResetDataForm {
  resetDataType: string
  password: string
}

const ResetDataSetting: React.FC<ResetDataSettingProps> = props => {
  const { resetDataDropdownItemList } = useSelector(selector)

  const submit = async (action: FormAction<ResetDataForm>) => {
    try {
      const { password, resetDataType } = action.getValues()
      const confirmed = await DefaultModalService.open(
        ConfirmationResetDataModal,
        { resetDataType: resetDataType }
      )
      if (confirmed) {
        await userService.checkPassword({ password })
        if (resetDataType === 'management') {
          await userService.resetManagements()
        } else {
          await userService.resetTransactions()
        }
        await DefaultModalService.open(ResetDataSuccessModal, { resetDataType })
        props.close('')
      }
    } catch (error) {
      if (error.code === 'invalid_password') {
        action.setFieldError('password', 'Password yang Anda masukan salah')
      } else {
        commonService.setAlert({
          type: 'error',
          message: error.message,
        })
      }
    }
  }

  return (
    <Modal
      dismiss={() => props.dismiss()}
      type='full'
      canDismissOutside={false}
    >
      <Form.Form
        height='100%'
        initialValues={
          { resetDataType: 'transaction', password: '' } as ResetDataForm
        }
        validationSchema={resetDataFormsSchema}
        onSubmit={submit}
        render={(actions: FormAction<ResetDataForm>) => (
          <VSided.VSided>
            <VSided.Remaining>
              <Wrapper width='470px' isCenter>
                <Break height={32} />
                <Text.Heading h={3}>Reset Data</Text.Heading>
                <Break height={24} />
                <Panel.Card padding={16}>
                  <Text.Paragraph weight='bold' size={14}>
                    Reset Data
                  </Text.Paragraph>
                  <Divider />
                  <Break height={14} />
                  <Wrapper
                    padding='16px'
                    backgroundColor='#F8E7E6'
                    style={{
                      border: '1px solid #AC2C24',
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <img
                      src='/assets/Img/ic_caution_triangle_solid.png'
                      width='33px'
                      height='33px'
                      style={{ marginRight: '10px' }}
                    />
                    <Text.Paragraph size={14}>
                      <b>PERINGATAN!</b> Data yang telah berhasil di reset tidak
                      dapat dikembalikan lagi seperti semula.
                    </Text.Paragraph>
                  </Wrapper>
                  <Break height={14} />
                  <Field.Dropdown
                    name='resetDataType'
                    list={resetDataDropdownItemList}
                    label='Reset Data'
                    placeholder='Pilih Reset Data'
                    value={actions.getValues().resetDataType}
                  />
                  <Field.Password
                    name='password'
                    label='Password'
                    placeholder='Minimal 8 karakter'
                  />
                </Panel.Card>
              </Wrapper>
            </VSided.Remaining>
            <VSided.Fixed>
              <Wrapper
                shadow='0 -2px 4px 0 rgba(0, 0, 0, 0.2)'
                width='100%'
                padding='16px'
                backgroundColor='white'
              >
                <Wrapper width='480px' isCenter>
                  <Sided.Sided justify='center' gutter={16}>
                    <Sided.Remaining>
                      <Button
                        size='large'
                        theme='netral'
                        type='button'
                        label='Batal'
                        fullW={true}
                        onClick={() => props.dismiss()}
                      />
                    </Sided.Remaining>
                    <Sided.Remaining>
                      <Button
                        size='large'
                        theme='danger'
                        disabled={!actions.isValid()}
                        type='submit'
                        label='Reset Data'
                        fullW={true}
                      />
                    </Sided.Remaining>
                  </Sided.Sided>
                </Wrapper>
              </Wrapper>
            </VSided.Fixed>
          </VSided.VSided>
        )}
      />
    </Modal>
  )
}

export default ResetDataSetting
