import { Dispatch, SetStateAction } from 'react'

export interface Collection<T> {
  data: Array<T>
}

export class NullCollection<T> implements Collection<T> {
  data: Array<T> = []
}

export interface ObjCollection<T> {
  obj: T
}

export type Dict<T> = Record<string, T>

export type Tree<T> = T & {
  children?: Tree<T>[]
}

export interface KeyedValue<T> {
  key: string
  value: T
}

export interface NameAndValue<T> {
  name: string
  value: T
}

export type Group<T> = Dict<T[]>

export function toKeyedValue<T>(dict: Dict<T>): KeyedValue<T>[] {
  return Object.keys(dict).map(key => ({
    key,
    value: dict[key],
  }))
}

export function flatten<T>(arr: T[][], separator: T[] = []): T[] {
  return arr.reduce((x: T[], curr: T[]) => [...x, ...separator, ...curr], [])
}

export type ReactState<S> = [S, Dispatch<SetStateAction<S>>]
