import React from 'react'
import ReactModal from 'react-modal'
import { Wrapper, Sided, Icon, Image, Text, Break } from 'views/components'

interface SuccessModalProps {
  isOpenModal: boolean
  closeModal: () => void
}

const SuccessModal = (props: SuccessModalProps) => {
  return (
    <ReactModal
      isOpen={props.isOpenModal}
      style={{
        overlay: {
          zIndex: 99,
          backgroundColor: 'rgba(46, 63, 80, 0.8)',
          border: 'none',
          display: 'flex',
          placeContent: 'center',
          alignItems: 'center',
          padding: '1rem 2rem',
        },
        content: {
          inset: 0,
          backgroundColor: 'white',
          border: 'none',
          position: 'unset',
          height: '375px',
          width: '360px',
          overflow: 'unset',
          padding: '12px 16px',
        },
      }}
    >
      <Wrapper width='100%' isCenter align='center'>
        <Sided.Sided justifyContent='right'>
          <Icon.Cross
            color='black'
            size={16}
            onClick={() => props.closeModal()}
          />
        </Sided.Sided>
        <Break height={20} />
        <Image
          src='/assets/Img/register-success.svg'
          width='240px'
          height='180px'
        />
        <Break height={40} />
        <Text.Heading h={5}>Selamat!</Text.Heading>
        <Text.Span size={16}>Mulai terima pembayaran e-wallet!</Text.Span>
      </Wrapper>
    </ReactModal>
  )
}

export default SuccessModal
