import { Tree } from 'utilities/type'
import { mapTree, filterTreeList } from 'utilities/converter/list'
import { RouteItemV2 } from 'route'

export type MiddleWareFunction = () => Promise<boolean | string>

interface _Route<C> extends _RouteDescription {
  component?: C
}

interface SidebarMenu {
  label: string
  icon?: JSX.Element
}

interface _RouteDescription {
  isFullScreen?: boolean
  key: string
  sidebarMenu?: SidebarMenu
  path?: string
  middleware?: Array<MiddleWareFunction>
  isEnabled: boolean
  isNew?: boolean
}

export type RouteItem<C = any> = Tree<_Route<C>>

export type RouteDescription = Tree<_RouteDescription>

export function flattenRoutes(
  routes: RouteItem[],
  parent: RouteItem = null
): RouteItem[] {
  let parsedRoutes: RouteItem[] = []
  routes.forEach((route: RouteItem) => {
    const r = { ...route }
    if (parent) {
      r.middleware = [...parent.middleware, ...r.middleware]
    }
    parsedRoutes.push(r)
    if (route.children) {
      parsedRoutes = [...parsedRoutes, ...flattenRoutes(route.children, parent)]
    }
  })
  return parsedRoutes
}

export function toRouteDescription(i: RouteItem): RouteDescription {
  return mapTree(i, x => {
    const newX = {
      ...x,
    }
    delete newX.component
    return newX
  })
}

export function filterOnlyEnabled(routes: RouteItem[]): RouteItem[] {
  return filterTreeList(routes, x => x.isEnabled)
}

export function hasCurrentPath(r: RouteDescription, path: string): boolean {
  if (r.path == path) {
    return true
  }
  if (r.children) {
    return r.children
      .map(x => hasCurrentPath(x, path))
      .reduce((a, b) => a || b, false)
  }
  return false
}

export function hasCurrentPathV2(r: RouteItemV2, path: string): boolean {
  if (r.path == path) {
    return true
  }
  if (r.children) {
    return r.children
      .map(x => hasCurrentPathV2(x, path))
      .reduce((a, b) => a || b, false)
  }
  return false
}

export function getSubmenus(route: RouteDescription): RouteDescription[] {
  return route.children || []
}

export function findFirst(
  r: RouteDescription | RouteItemV2
): RouteDescription | RouteItemV2 {
  if (!r.children || r.children.length == 0) {
    return r
  }
  return findFirst(r.children[0])
}
