import { getLocalStorage, clearLocalStorage } from 'utilities/localStorage'
import { CONFIG_LOCALSTORAGE } from 'constants/localStorage'
import ROUTE from 'constants/routes'

export async function Authenticated(): Promise<boolean | string> {
  if (getLocalStorage(CONFIG_LOCALSTORAGE.POST_TOKEN)) {
    return true
  }
  clearLocalStorage()
  return ROUTE.LOGIN
}
