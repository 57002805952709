export type ItemAssessor<T> = (item: T) => boolean

export type Executor<T> = (f: ItemAssessor<T>) => boolean

function _allOf<T>(...items: T[]): Executor<T> {
  return (f: ItemAssessor<T>) => {
    return items
      .map(i => {
        return f(i)
      })
      .reduce((x, y) => x && y, true)
  }
}

function _anyOf<T>(...items: T[]): Executor<T> {
  return (f: ItemAssessor<T>) => {
    return items
      .map(i => {
        return f(i)
      })
      .reduce((x, y) => x || y, false)
  }
}
function _or<T>(...ors: Executor<T>[]): Executor<T> {
  return (f: ItemAssessor<T>) => {
    return ors
      .map(a => a(f))
      .map((x, i) => {
        return x
      })
      .reduce((x, y) => x || y, false)
  }
}

function _and<T>(...ands: Executor<T>[]): Executor<T> {
  return (f: ItemAssessor<T>) => {
    return ands
      .map(a => a(f))
      .map((x, i) => {
        return x
      })
      .reduce((x, y) => x && y, true)
  }
}
function _not<T>(a: Executor<T>): Executor<T> {
  return (f: ItemAssessor<T>) => {
    const output = a(f)
    return !output
  }
}

function _validFor<T>(e: Executor<T>, f: ItemAssessor<T>): boolean {
  return e(f)
}

function createOperator<T>(
  prev: Executor<T>,
  operator: (...xxx: Executor<T>[]) => Executor<T>
): _BinaryOperator<T> {
  return new _BinaryOperator<T>(prev, operator)
}

function createOperand<T>(prev: Executor<T>) {
  return new _Operand<T>(prev)
}

export interface BinaryOperator<T> {
  not: _BinaryOperator<T>
  anyOf(...items: T[]): Operand<T>
  allOf(...items: T[]): Operand<T>
}

class _BinaryOperator<T> implements BinaryOperator<T> {
  prev: Executor<T>
  operator: (left: Executor<T>, right: Executor<T>) => Executor<T>
  constructor(
    prev: Executor<T>,
    operator: (left: Executor<T>, right: Executor<T>) => Executor<T>
  ) {
    this.prev = prev
    this.operator = operator
  }

  get not(): _BinaryOperator<T> {
    return createOperator(this.prev, (a, b) => this.operator(a, _not(b)))
  }
  anyOf(...items: T[]): Operand<T> {
    return createOperand(this.operator(this.prev, _anyOf(...items)))
  }
  allOf(...items: T[]): Operand<T> {
    return createOperand(this.operator(this.prev, _allOf(...items)))
  }
}

export interface Operand<T> {
  and: BinaryOperator<T>
  or: BinaryOperator<T>
  validFor(i: ItemAssessor<T>): boolean
}

class _Operand<T> implements Operand<T> {
  prev: Executor<T>

  constructor(prev: Executor<T>) {
    this.prev = prev
  }

  get and(): BinaryOperator<T> {
    return createOperator(this.prev, _and)
  }

  get or(): BinaryOperator<T> {
    return createOperator(this.prev, _or)
  }

  validFor(i: ItemAssessor<T>): boolean {
    return _validFor(this.prev, i)
  }
}

export function not<T>(): BinaryOperator<T> {
  return createOperator(
    () => true,
    (first, second) => _not(second)
  )
}
export function anyOf<T>(...items: T[]): Operand<T> {
  return createOperand(_anyOf(...items))
}
export function allOf<T>(...items: T[]): Operand<T> {
  return createOperand(_allOf(...items))
}
export function is<T>(): BinaryOperator<T> {
  return createOperator(
    () => true,
    (first, second) => second
  )
}
