import { FunctionComponent } from 'react'
import { Dict } from 'utilities/type'
import { DefaultModalService } from '.'
// import { history } from 'storages'

export interface ModalConfig {
  path: string
  defaultParams?: any
  backPath?: string
}

const modalConfigDict: Dict<ModalConfig> = {}
const modalRouteDict: Dict<{
  func: () => any
  config: ModalConfig
}> = {}

export function registerModalConfig(
  fc: FunctionComponent<any>,
  config: ModalConfig
) {
  modalConfigDict[fc.name] = config
  modalRouteDict[config.path] = {
    config,
    func: () => DefaultModalService.open(fc, config.defaultParams),
  }
}

export function getModalConfig(
  fc: FunctionComponent<any>
): ModalConfig | undefined {
  return modalConfigDict[fc.name]
}

export function tryOpenModalAtInitialLoading(path: string) {
  const modalRoute = modalRouteDict[path]
  if (modalRoute) {
    // history.push(modalRoute.config.backPath)
    modalRoute.func()
  }
}
