import { BaseApi } from 'api/base.api'
import { IEmployeeApi } from 'domains/management/employee/contracts/api'
import {
  Employee,
  StaffRequestBody,
  Staff,
  Cashier,
  SetStaffPassword,
  LoginStaffResponse,
} from 'domains/management/employee/models'
import { EmployeeQueryParam } from 'domains/management/employee/states'
import { LoginRequest } from 'domains/auth/login/models'

export default class EmployeeApi extends BaseApi implements IEmployeeApi {
  constructor() {
    super('/v3')
  }

  public async getEmployees(
    queryParams?: EmployeeQueryParam
  ): Promise<Employee[]> {
    return await this.makeForCamelCaseForList('GET', '/employees', queryParams)
  }

  public async getStaff(id: string): Promise<Staff> {
    return await this.makeForCamelCase('GET', `/employees/staff/${id}`)
  }

  public async createStaff(staff: StaffRequestBody): Promise<Staff> {
    return await this.makeForCamelCase('POST', `/employees/staff`, staff)
  }

  public async updateStaff(
    staff: StaffRequestBody,
    id: string
  ): Promise<Staff> {
    return await this.makeForCamelCase('POST', `/employees/staff/${id}`, staff)
  }

  public async getCashier(id: string): Promise<Cashier> {
    return await this.makeForCamelCase('GET', `/employees/cashier/${id}`)
  }

  public async createCashier(cashier: Cashier): Promise<Cashier> {
    return await this.makeForCamelCase('POST', `/employees/cashier`, cashier)
  }

  public async updateCashier(cashier: Cashier, id: string): Promise<Cashier> {
    return await this.makeForCamelCase(
      'POST',
      `/employees/cashier/${id}`,
      cashier
    )
  }

  public async deleteEmployee(id: string): Promise<void> {
    return await this.makeForCamelCase('DELETE', `/employees/${id}`)
  }

  public async sendCreatePasswordEmail(staffId: string): Promise<void> {
    return await this.makeForCamelCase(
      'POST',
      `/employees/staff/${staffId}/set-password-email`
    )
  }

  public async setStaffPassword(
    setStaffPassword: SetStaffPassword
  ): Promise<void> {
    return await this.makeForCamelCase(
      'POST',
      `/employees/staff/set-password`,
      setStaffPassword
    )
  }

  public async loginStaff(
    loginRequest: LoginRequest
  ): Promise<LoginStaffResponse> {
    return await this.makeForCamelCase(
      'POST',
      `/employees/staff/login`,
      loginRequest
    )
  }

  public async logOutEmployee(header: {}, id: string): Promise<void> {
    return await this.makeForCamelCase(
      'POST',
      `/employees/${id}/logout`,
      {},
      header
    )
  }

  public async validateEmployeeRegisterToken(id: string): Promise<void> {
    return await this.makeForCamelCase(
      'GET',
      `/employees/register/${id}/validate`
    )
  }
}
