import { AxiosError } from 'axios'
import { commonService, layoutService } from 'injectors'

export const errorInterceptor = (error: AxiosError): Promise<Error> => {
  const { response } = error
  if (!error.response && error.message === 'Network Error') {
    commonService.setAlert({
      message: 'Koneksi internet tidak stabil',
      type: 'error',
    })
  }
  if (response?.status === 401) {
    layoutService.clearAccess()
  }
  return Promise.reject(response ? response.data : error)
}
