import './style.css'
import { useSelector } from 'react-redux'
import React, {
  CSSProperties,
  FunctionComponent,
  useEffect,
  useState,
} from 'react'
import classNames from 'classnames'
import { ICommonService } from 'domains/commons/services'
import { commonService } from 'injectors'
import selector from './selector'
import { defer, Deferred } from 'utilities/defer'
import { history } from 'storages'
import {
  ModalConfig,
  getModalConfig,
  tryOpenModalAtInitialLoading,
} from './config'

export const ModalContainer: React.FC<{}> = () => {
  const { controllers } = useSelector(selector)

  useEffect(() => {
    const pathName = history.location.pathname
    tryOpenModalAtInitialLoading(pathName)
  }, [])

  return (
    <div className='modal-container'>{controllers.map(c => c.component)}</div>
  )
}

export interface ModalProps<TOut = any> {
  close: (returnedValue: TOut) => any
  dismiss: () => any
}

export class ModalController<Props = any, TOut = any> implements ModalProps {
  deferred: Deferred<TOut>
  isClosed = false
  close(item: TOut) {
    this.deferred.resolve(item)
    this.isClosed = true
    this.goBack()
  }
  async dismiss() {
    await this.dismissWithoutRouting()
    this.goBack()
  }

  goBack() {
    if (
      this.config &&
      this.config.path &&
      history.location.pathname == this.config.path
    ) {
      if (this.config.backPath) {
        history.push(this.config.backPath)
      } else {
        history.goBack()
      }
    }
  }

  dismissWithoutRouting() {
    this.deferred.reject()
    this.isClosed = true
  }

  component: React.ReactElement<Props & ModalProps<TOut>>
  id: string
  config?: ModalConfig

  constructor(
    id: string,
    fc: FunctionComponent<Props & ModalProps<TOut>>,
    props: Props,
    config?: ModalConfig
  ) {
    this.id = id
    this.deferred = defer()
    this.config = config
    this.component = React.createElement(fc, {
      ...props,
      close: (x: TOut) => this.close(x),
      dismiss: () => this.dismiss(),
    })
    if (config && config.path) {
      // history.push(config.path)
      window.addEventListener('popstate', ev => {
        if (!this.isClosed) {
          this.dismiss()
        }
      })
    }
  }
}

export class ModalService {
  commonService: ICommonService
  constructor(commonService: ICommonService) {
    this.commonService = commonService
  }

  async open<Props, TOut>(
    fc: FunctionComponent<Props & ModalProps<TOut>>,
    props: Props
  ): Promise<TOut> {
    const id = new Date().getTime().toString()
    const config = getModalConfig(fc)
    const modalController = new ModalController<Props, TOut>(
      id,
      fc,
      props,
      config
    )
    modalController.deferred.promise.finally(() => {
      this.remove(modalController.id)
    })
    this.commonService.pushModal(modalController)
    return modalController.deferred.promise
  }

  remove(id: string) {
    this.commonService.removeModal(id)
  }
}

export interface Props {
  dismiss: () => any
  children: any[] | any
  type: 'full' | 'blank'
  width?: string
  className?: string
  canDismissOutside?: boolean
  style?: CSSProperties
}

export const Modal: React.FC<Props> = (props: Props) => {
  const style: CSSProperties = {
    ...props.style,
  }
  if (props.width) {
    style.width = props.width
  }

  const className = classNames(
    'modal-panel',
    props.className || '',
    props.type ? 'modal-panel-' + props.type : ''
  )

  let onClickOutside = () => {
    props.dismiss()
  }
  if (props.canDismissOutside == false) {
    onClickOutside = () => {}
  }

  const [finishStatus, setfinishStatus] = useState(false)

  const onBackButtonEvent = (e: any) => {
    e.preventDefault()
    if (!finishStatus) {
      setfinishStatus(true)
      props.dismiss()
    }
  }

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname)
    window.addEventListener('popstate', onBackButtonEvent)
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent)
    }
  }, [])

  return (
    <div
      className='modal-background'
      onClick={onClickOutside}
      style={{ zIndex: 9999 }}
    >
      <div className={className} style={style}>
        <React.Fragment>{props.children}</React.Fragment>
      </div>
    </div>
  )
}

export const DefaultModalService = new ModalService(commonService)
