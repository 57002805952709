import React, { useRef } from 'react'
import {
  Form,
  Text,
  Break,
  Button,
  Field,
  FormAction,
  Divider,
  Wrapper,
  Sided,
  Panel,
  VSided,
} from 'views/components'
import { ModalProps, Modal, DefaultModalService } from 'views/components/Modal'

import { LocationModel } from 'domains/location/models'
import { Debouncer } from 'utilities/debounce'
import { DropdownItem } from 'views/components/Field/Dropdown'
import { locationService, customerService, commonService } from 'injectors'
import { highlight } from 'utilities/strings/highlighter'
import { CustomerRequest, Customer } from 'domains/management/customer/models'
import { customerFormSchema } from './customerFormSchema'
import DeleteConfirmationModal from '../../Modal/deleteConfirmation'
import { trimDuplicateSpace } from 'utilities/strings'
import { convertAreasToDropdownItems } from 'views/modules/Home/selector'
export interface CustomerFormProps extends ModalProps {
  mode: string
  customerInfo?: Customer
}
const CustomerForm: React.FC<CustomerFormProps> = (
  props: CustomerFormProps
) => {
  const { mode, customerInfo } = props
  const id = customerInfo && customerInfo.id
  const name = customerInfo && customerInfo.name
  const areaId = customerInfo && customerInfo.areaId
  const phone = customerInfo && customerInfo.phone
  const email = customerInfo && customerInfo.email
  const address = customerInfo && customerInfo.address

  const addNewCustomer = async (customerRequestBody: CustomerRequest) => {
    try {
      await customerService.createCustomer(customerRequestBody)
      commonService.setAlert({
        type: 'success',
        message: 'Data pelanggan baru berhasil ditambahkan',
      })
      props.dismiss()
    } catch (error) {
      commonService.setAlert({
        type: 'error',
        message: 'Data pelanggan baru gagal ditambahkan',
      })
    }
  }

  const updateCustomer = async (
    customerRequestBody: CustomerRequest,
    id: string
  ) => {
    try {
      await customerService.updateCustomer(customerRequestBody, id)
      commonService.setAlert({
        type: 'success',
        message: 'Data pelanggan berhasil diubah',
      })
      props.dismiss()
    } catch (error) {
      commonService.setAlert({
        type: 'error',
        message: 'Data pelanggan gagal diubah',
      })
    }
  }

  const deleteCustomer = async (id: string) => {
    try {
      await customerService.deleteCustomer(id)
      props.dismiss()
      commonService.setAlert({
        type: 'netral',
        message: 'Data pelanggan berhasil dihapus',
      })
    } catch (error) {
      commonService.setAlert({
        type: 'error',
        message: 'Data pelanggan gagal dihapus',
      })
    }
  }

  const openDeleteConfirmationModal = async () => {
    const confirmed = await DefaultModalService.open(DeleteConfirmationModal, {
      title: 'Hapus Pelanggan?',
      body: 'Informasi pelanggan yang sudah dihapus tidak dapat dikembalikan lagi.',
    })
    if (confirmed) {
      deleteCustomer(id)
    }
  }

  const renderDeleteCustomerButton = () =>
    mode === 'update' && (
      <Text.Heading
        h={6}
        color='red'
        onClick={() => openDeleteConfirmationModal()}
        align='center'
      >
        Hapus Pelanggan
      </Text.Heading>
    )

  const submit = async (actions: FormAction<CustomerRequest>) => {
    const customerRequestBody = actions.getValues()
    const trimmedRequest = {
      ...customerRequestBody,
      name: trimDuplicateSpace(customerRequestBody.name),
    }
    mode === 'update'
      ? updateCustomer(trimmedRequest, id)
      : addNewCustomer(trimmedRequest)
  }

  const loadedLocations = useRef<LocationModel[]>([])

  const debouncer = new Debouncer()

  const handleAreaSearch = async (
    keyword: string
  ): Promise<DropdownItem<string>[]> => {
    if (keyword.length < 3) {
      return []
    }
    return debouncer.debounce(async () => {
      const queryParams = {
        query: keyword,
      }
      const areas = await locationService.getAreas(queryParams)
      loadedLocations.current = areas
      return convertAreasToDropdownItems(areas).map(x => ({
        ...x,
        label: highlight(x.label, keyword.toUpperCase(), y => `<b>${y}</b>`),
      }))
    }, 500)
  }

  const resolveInitValue = async (areaId: string): Promise<DropdownItem> => {
    const location = await locationService.getArea(areaId)
    if (!location) {
      return undefined
    }
    return convertAreasToDropdownItems([location])[0]
  }

  const renderFormFields = (actions: FormAction<CustomerRequest>) => (
    <VSided.VSided gutter={24}>
      <VSided.Remaining>
        <Wrapper width='480px' isCenter>
          <Break height={20} />
          <Text.Heading h={4}>
            {mode === 'update' ? 'Ubah Pelanggan' : 'Tambah Pelanggan Baru'}
          </Text.Heading>
          <Break height={20} />
          <Panel.Card padding={16}>
            <React.Fragment>
              <Text.Heading h={6}>Detail Pelanggan</Text.Heading>
              <Divider />
              <Break height={10} />
              <Field.Text label='Nama' name='name' placeholder='Contoh: Joni' />
              <Break height={10} />
              <Field.Text
                label='Nomor Kontak'
                name='phone'
                placeholder='Contoh: 08123456789'
              />
              <Break height={10} />
              <Field.Text
                label='Email'
                name='email'
                placeholder='Contoh: joni@gmail.com'
              />
              <Break height={10} />
              <Field.LocationAutoComplete
                label='Kelurahan'
                name='areaId'
                searchFunc={handleAreaSearch}
                placeholder='Jawa Barat, Kota Bandung'
                initValue={areaId}
                resolveInitValueFunc={resolveInitValue}
              />
              <Break height={10} />
              <Field.Text
                label='Alamat'
                name='address'
                placeholder='Contoh: Jl. Bukit Pakar Utara No. 10. Dago Pakar'
              />
            </React.Fragment>
          </Panel.Card>
          <Break height={16} />
          {renderDeleteCustomerButton()}
        </Wrapper>
      </VSided.Remaining>
      <VSided.Fixed>
        <Wrapper
          shadow='0 -2px 4px 0 rgba(0, 0, 0, 0.2)'
          padding='16px'
          backgroundColor='white'
        >
          <Wrapper width='480px' isCenter>
            <Sided.Sided justify='center' gutter={16}>
              <Sided.Remaining>
                <Button
                  onClick={() => props.dismiss()}
                  size='large'
                  theme='netral'
                  type='button'
                  label='Batal'
                  fullW={true}
                />
              </Sided.Remaining>
              <Sided.Remaining>
                <Button
                  size='large'
                  theme='primary'
                  type='submit'
                  disabled={!actions.isValid()}
                  label='Simpan'
                  fullW={true}
                />
              </Sided.Remaining>
            </Sided.Sided>
          </Wrapper>
        </Wrapper>
      </VSided.Fixed>
    </VSided.VSided>
  )
  return (
    <Modal
      dismiss={() => props.dismiss()}
      type='full'
      canDismissOutside={false}
    >
      <Form.Form
        height='100%'
        initialValues={
          {
            name: name,
            phone: phone,
            email: email,
            areaId: areaId,
            address: address,
          } as CustomerRequest
        }
        validationSchema={customerFormSchema}
        onSubmit={submit}
        render={(actions: FormAction<CustomerRequest>) => {
          return renderFormFields(actions)
        }}
      />
    </Modal>
  )
}

export default CustomerForm
