import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useSWRConfig } from 'swr'

import './style.scss'

import { BusinessAccount } from 'domains/users/models'
import { userService, commonService } from 'injectors'
import { trimDuplicateSpace } from 'utilities/strings'
import {
  Text,
  Break,
  Wrapper,
  Button,
  Field,
  Form,
  FormAction,
  VSided,
  Sided,
  Panel,
  Divider,
} from 'views/components'
import { DefaultModalService, Modal, ModalProps } from 'views/components/Modal'

import selector from '../selector'
import { accountSettingsSchema } from './accountSettingsSchema'
import PasswordConfirmationModal from './PasswordConfirmation'

interface AccountSettingProps extends ModalProps {
  isLoading: boolean
}

const AccountSetting: React.FC<AccountSettingProps> = props => {
  const { businessAccountInfo, userInfo } = useSelector(selector)
  const { i18n } = useLingui()
  const { mutate } = useSWRConfig()

  useEffect(() => {
    userService.getUser()
  }, [])

  const accountInfoIsChanged = (actions: FormAction<BusinessAccount>) => {
    const { businessName, email, phone } = actions.getValues()
    if (
      businessName !== businessAccountInfo.businessName ||
      email !== businessAccountInfo.email ||
      phone !== businessAccountInfo.phone
    ) {
      return false
    } else {
      return true
    }
  }

  const submitSetting = async (actions: FormAction<BusinessAccount>) => {
    const { businessName, email, phone } = actions.getValues()
    if (
      businessName !== businessAccountInfo.businessName ||
      email !== businessAccountInfo.email ||
      phone !== businessAccountInfo.phone
    ) {
      try {
        const passwordChecked = await DefaultModalService.open(
          PasswordConfirmationModal,
          {}
        )
        const businessNameBody = {
          ...userInfo,
          businessName: trimDuplicateSpace(businessName),
        }
        if (passwordChecked) {
          if (businessName !== businessAccountInfo.businessName) {
            await userService.updateUser(businessNameBody)
            commonService.setAlert({
              message: i18n._(t`Akun Bisnis berhasil diubah`),
              type: 'success',
            })
          }
          if (email !== businessAccountInfo.email) {
            await userService.changeEmail(email)
            commonService.setAlert({
              message: i18n._(t`Akun Bisnis berhasil diubah`), // TODO: DRY
              type: 'success',
            })
          }
        }
        if (phone !== businessAccountInfo.phone) {
          await userService.changePhone(phone)
          commonService.setAlert({
            message: i18n._(t`Akun Bisnis berhasil diubah`), // TODO: DRY
            type: 'success',
          })
        }
        mutate('profile')
        await userService.getUser()
        props.close('')
      } catch (error) {
        commonService.setAlert({
          message: error.message,
          type: 'error',
        })
      }
    }
  }

  const isBusinessInfoNull = () => {
    if (
      Object.values(businessAccountInfo).find(x => x === undefined || x === '')
    ) {
      return true
    } else {
      return false
    }
  }

  const renderFormBusinessInfo = () => {
    if (isBusinessInfoNull() === false) {
      return formBusiness()
    } else {
      return null
    }
  }

  const formBusiness = () => (
    <Wrapper>
      <Text.Paragraph align='center' size={14}>
        Anda dapat mengubah nomor handphone dan email untuk <br /> mendapatkan
        notifikasi melalui email.
      </Text.Paragraph>
      <Break height={40} />
      <Panel.Card padding={16}>
        <Wrapper className='form-control'>
          <Text.Heading h={6}>Detail Akun</Text.Heading>
          <Divider />
          <Field.Text
            label={i18n._(t`Nomor Handphone Akun`)}
            name='phone'
            placeholder={i18n._(t`081236789700`)}
          />
          <Field.Text
            label={i18n._(t`Email`)}
            name='email'
            placeholder={i18n._(t`ayamgeprekjono@gmail.com`)}
          />
        </Wrapper>
        {/* <Button
          disabled={accountInfoIsChanged(action)}
          size="large"
          theme="primary"
          type="submit"
          label="Simpan"
          fullW={true}
        /> */}
      </Panel.Card>
    </Wrapper>
  )

  return (
    <Modal
      dismiss={() => props.dismiss()}
      type='full'
      canDismissOutside={false}
    >
      <Form.Form
        height='100%'
        initialValues={
          {
            ...businessAccountInfo,
          } as BusinessAccount
        }
        validationSchema={accountSettingsSchema}
        onSubmit={submitSetting}
        render={(action: FormAction<BusinessAccount>) => (
          <VSided.VSided>
            <VSided.Remaining>
              <Wrapper
                width='470px'
                isCenter
                className='post-settings-Business'
              >
                <Break height={32} />
                <Text.Heading h={3}>Atur Akun</Text.Heading>
                <Break height={24} />
                {renderFormBusinessInfo()}
              </Wrapper>
            </VSided.Remaining>
            <VSided.Fixed>
              <Wrapper
                shadow='0 -2px 4px 0 rgba(0, 0, 0, 0.2)'
                width='100%'
                padding='16px'
                backgroundColor='white'
              >
                <Wrapper width='480px' isCenter>
                  <Sided.Sided justify='center' gutter={16}>
                    <Sided.Remaining>
                      <Button
                        size='large'
                        theme='netral'
                        type='button'
                        label={i18n._(t`Batal`)}
                        fullW={true}
                        onClick={() => props.dismiss()}
                      />
                    </Sided.Remaining>
                    <Sided.Remaining>
                      <Button
                        size='large'
                        theme='primary'
                        disabled={accountInfoIsChanged(action)}
                        type='submit'
                        label={i18n._(t`Simpan`)}
                        fullW={true}
                      />
                    </Sided.Remaining>
                  </Sided.Sided>
                </Wrapper>
              </Wrapper>
            </VSided.Fixed>
          </VSided.VSided>
        )}
      />
    </Modal>
  )
}

export default AccountSetting
