import loadable from '@loadable/component'
import React from 'react'

import ErrorBoundary from '../ErrorBoundary'
import Loader from '../Loader'
import Wrapper from '../Wrapper'

interface AsyncPageProps {
  page: string
  fallback?: JSX.Element
}

const Component = loadable(
  (props: AsyncPageProps) => import(`../../modules/${props.page}`)
)

const AsyncPage: React.FC<AsyncPageProps> = props => {
  return (
    <ErrorBoundary>
      <Component
        page={props.page}
        fallback={
          props.fallback || (
            <Wrapper height='100%' width='100%' position='relative'>
              <Loader />
            </Wrapper>
          )
        }
      />
    </ErrorBoundary>
  )
}

export default AsyncPage
