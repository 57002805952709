import { featureCheck, isAnyOfTheseFeaturesEnabled } from 'config'
import ROUTE from 'constants/routes'
import { RouteItemV2 } from 'route'

const getUnauthorizedRoutes = (): RouteItemV2[] => {
  const routes: RouteItemV2[] = [
    {
      path: ROUTE.CREATE_NEW_EMPLOYEE,
      component: 'Management/Employee/ChangePassword',
      exact: true,
      isEnabled: true,
    },
    {
      path: ROUTE.CREATE_STAFF_PASSWORD_SUCCESS,
      component: 'Management/Employee/ChangeStaffPasswordSuccess',
      exact: true,
      isEnabled: true,
    },
    {
      path: ROUTE.REGISTER_SUCCESS,
      component: isAnyOfTheseFeaturesEnabled(featureCheck.home.registerRevamp)
        ? 'Registration/RegisterSuccessV2'
        : 'Registration/RegisterSuccess',
      exact: true,
      isEnabled: true,
    },
    {
      path: ROUTE.LOGIN,
      component: 'Login',
      userAccess: 'unauthorized',
      exact: true,
      isEnabled: true,
    },
    {
      path: ROUTE.REGISTER_TOKEN,
      component: 'Registration/RegisterToken',
      userAccess: 'unauthorized',
      exact: true,
      isEnabled: true,
    },
    {
      path: ROUTE.REGISTER_OTP,
      component: 'Registration/RegisterOTP',
      userAccess: 'unauthorized',
      exact: true,
      isEnabled: true,
    },
    {
      path: ROUTE.FORGOT_PASSWORD,
      component: 'Auth/ResetPassword/ResetPasswordToken',
      userAccess: 'unauthorized',
      exact: true,
      isEnabled: true,
    },
    {
      path: ROUTE.FORGOT_PASSWORD_OTP,
      component: 'Auth/ResetPassword/ResetPasswordOTP',
      userAccess: 'unauthorized',
      exact: true,
      isEnabled: true,
    },
    {
      path: ROUTE.CREATE_NEW_PASSWORD,
      component: 'Auth/ResetPassword/NewPasswordForm',
      userAccess: 'unauthorized',
      exact: true,
      isEnabled: true,
    },
  ]

  return routes
    .filter(route => route.isEnabled)
    .map(route =>
      route.children
        ? {
            ...route,
            children: route.children.filter(child => child.isEnabled),
          }
        : route
    )
}

export default getUnauthorizedRoutes
