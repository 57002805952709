import React, { useRef, useEffect } from 'react'
import { Text, Flex, Button, Icon } from 'views/components'
import './style.scss'
import { extractFile, triggerFileUpload } from 'utilities/file/upload'
import { t, Trans } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { commonService } from 'injectors'
import classNames from 'classnames'

interface Props {
  initValue: string
  onChange: (values: string) => any
  imageUrl: string
  className?: string
}

const UploadImage = (props: Props) => {
  const { onChange, initValue, imageUrl } = props
  const { i18n } = useLingui()

  const renderUploadPhotoButton = () => (
    <div
      style={{
        position: 'absolute',
        left: '40%',
        top: '40%',
      }}
    >
      <Icon.Add />
    </div>
  )

  const handleUploadImage = async () => {
    const imageInfo = await triggerFileUpload(undefined, {
      acceptedFile: 'image/png, image/jpg, image/jpeg',
    })

    if (imageInfo.file.size > 10000000) {
      commonService.setAlert({
        type: 'error',
        message: 'Ukuran file foto tidak boleh lebih dari 10mb',
      })
      return
    }
    if (
      imageInfo.file.type !== 'image/png' &&
      imageInfo.file.type !== 'image/jpg' &&
      imageInfo.file.type !== 'image/jpeg'
    ) {
      commonService.setAlert({
        type: 'error',
        message: 'Format foto harus .png, .jpg, atau .jpeg',
      })
      return
    }

    onChange(imageInfo.base64)
  }

  const handleFileDrop = async (e: DragEvent) => {
    const files = e.dataTransfer.files

    if (!files || files.length != 1) {
      return
    }

    if (
      files[0].type !== 'image/png' &&
      files[0].type !== 'image/jpg' &&
      files[0].type !== 'image/jpeg'
    ) {
      commonService.setAlert({
        type: 'error',
        message: 'Format foto harus .png, .jpg, atau .jpeg',
      })
      return
    }
    if (files[0].size > 10000000) {
      commonService.setAlert({
        type: 'error',
        message: 'Ukuran file foto tidak boleh lebih dari 10mb',
      })
      return
    }

    const imageInfo = await extractFile(files[0])
    onChange(imageInfo.base64)
  }

  const dropAreaRef = useRef<HTMLDivElement>()
  useEffect(() => {
    if (!dropAreaRef || !dropAreaRef.current) {
      return
    }
    dropAreaRef.current.addEventListener('drop', handleFileDrop, false)
  }, [])

  const deleteImage = () => {
    onChange('image_removed')
  }

  return (
    <div
      className={classNames('product-photo-container', props.className || '')}
      ref={dropAreaRef}
    >
      <Flex.Row
        className='product-photo-row'
        flexDirection='row'
        style={{ marginTop: '8px' }}
      >
        <Flex.Column flex='2'>
          <div
            style={{
              width: '120px',
              height: '120px',
              border: 'dashed 2px #e6e6e6',
              position: 'relative',
            }}
          >
            <div>
              {imageUrl && imageUrl !== 'image_removed' ? (
                <img
                  src={imageUrl}
                  alt='Product Image'
                  style={{
                    width: '120px',
                    height: '120px',
                    objectFit: 'cover',
                  }}
                />
              ) : initValue && initValue !== 'image_removed' ? (
                <img
                  src={initValue}
                  alt='Product Image'
                  style={{
                    width: '120px',
                    height: '120px',
                    objectFit: 'cover',
                  }}
                />
              ) : (
                renderUploadPhotoButton()
              )}
              {(imageUrl || (initValue && initValue !== 'image_removed')) && (
                <Button
                  theme='transparent'
                  className='delete-photo'
                  onClick={() => deleteImage()}
                >
                  <Icon.Cross></Icon.Cross>
                </Button>
              )}
            </div>
          </div>
        </Flex.Column>
        <Flex.Column flex='10'>
          <div
            style={{
              paddingLeft: '8px',
              textAlign: 'left',
            }}
          >
            <Trans>
              <Text.Paragraph>
                Unggah foto atau tarik produk kesini
              </Text.Paragraph>
            </Trans>
            <div className='select-photo-button'>
              <Button
                theme='netral'
                label={i18n._(t`Pilih Foto`)}
                size='large'
                onClick={handleUploadImage}
              ></Button>
            </div>
          </div>
        </Flex.Column>
      </Flex.Row>
    </div>
  )
}

export default UploadImage
