import React, { useState } from 'react'
import InputBase from '../base'
import { Icon } from 'views/components'
import Form, { RenderingFieldContext } from '../../Form'

interface Props {
  label?: string
  placeholder?: string
  name?: string
  isDisabled?: boolean
  autoComplete?: boolean
}

const InputPassword = (props: Props) => {
  const [isShown, setIsShown] = useState(false)
  const [fieldType, setFieldType] = useState('password')

  const renderEyeIcon = () => {
    return (
      <div onClick={() => handleShowPassword(isShown)}>
        {isShown ? <Icon.EyeShow /> : <Icon.EyeHidden />}
      </div>
    )
  }

  const handleShowPassword = (isShown: boolean) => {
    setIsShown(!isShown)
    if (!isShown === true) {
      setFieldType('text')
    } else {
      setFieldType('password')
    }
  }

  return (
    <Form.Field
      name={props.name}
      render={({ field, value, onChange, onBlur }: RenderingFieldContext) => (
        <div style={{ position: 'relative' }}>
          <InputBase
            suffix={renderEyeIcon()}
            isDisabled={props.isDisabled}
            label={props.label}
            error={field.isDirty && field.error}
          >
            <input
              autoComplete={!props.autoComplete ? 'new-password' : 'on'}
              name={props.name}
              type={fieldType}
              disabled={props.isDisabled}
              value={value}
              placeholder={props.placeholder}
              onBlur={onBlur}
              onChange={evt => onChange(evt.target.value)}
              data-testid='field-password'
              step={0.01}
            />
          </InputBase>
        </div>
      )}
    />
  )
}

export default InputPassword
