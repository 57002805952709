import React from 'react'
import selector from './selector'
import { useSelector } from 'react-redux'
import SidebarV2 from './SidebarV2'
import SidebarV3 from './SidebarV3'
import classNames from 'classnames'
import { featureCheck, isAnyOfTheseFeaturesEnabled } from 'config'

const Sidebar: React.FC<{}> = (props: {}) => {
  const { isChildVisible } = useSelector(selector)
  const isSidebarV2 = isAnyOfTheseFeaturesEnabled(featureCheck.home.sidebarV2)
  const isSidebarV3 = isAnyOfTheseFeaturesEnabled(featureCheck.home.sidebarV3)
  const className = classNames(
    'sidebar-wrap',
    isChildVisible && 'child-mode',
    { v2: isSidebarV2 },
    { v3: isSidebarV3 }
  )

  return (
    <div className={className}>
      {isSidebarV2 && <SidebarV2 />}
      {isSidebarV3 && <SidebarV3 />}
    </div>
  )
}

export default Sidebar
