import React from 'react'
import './style.css'
import classNames from 'classnames'
import { OfficialTextColor } from '../const'
interface Props {
  color?: OfficialTextColor
  size?: 16 | 18 | 20 | 24 | 32 | 40 | 50
  onClick?: () => any
  className?: string
  style?: React.CSSProperties
  float?: 'left' | 'right'
}

function defineIcon(className: string) {
  return (props: Props) => {
    return (
      <div
        style={{
          width: props.size,
          height: props.size,
          cursor: props.onClick ? 'pointer' : 'default',
          ...props.style,
        }}
        className={classNames(
          'post-icon',
          className,
          props.className || '',
          'post-icon-float-' + (props.float || 'default'),
          props.color
        )}
        onClick={props.onClick}
      ></div>
    )
  }
}

export default {
  Logo: defineIcon('main-logo'),
  Home: defineIcon('home'),
  Expense: defineIcon('kelola-pengeluaran'),
  ChevronRight: defineIcon('chevron-right'),
  ChevronLeft: defineIcon('chevron-left'),
  ChevronUp: defineIcon('chevron-up'),
  ChevronDown: defineIcon('chevron-down'),
  EmptyList: defineIcon('empty'),
  Transaction: defineIcon('transaction'),
  GridMenu: defineIcon('grid-menu'),
  ManagementForProduct: defineIcon('management-product'),
  ManagementForShop: defineIcon('management-shop'),
  Info: defineIcon('info'),
  InfoFilled: defineIcon('info-filled'),
  Information: defineIcon('information'),
  CSCall: defineIcon('cs-call'),
  Settings: defineIcon('settings'),
  Report: defineIcon('report'),
  ArrowLeft: defineIcon('arrows-left'),
  Loader: defineIcon('spinner'),
  Calendar: defineIcon('calendar'),
  Caretdown: defineIcon('caretdown'),
  SortCaretup: defineIcon('sort-caretup'),
  SortCaretdown: defineIcon('sort-caretdown'),
  RotateLeft: defineIcon('rotate-left'),
  RotateRight: defineIcon('rotate-right'),
  Help: defineIcon('help'),
  Rectangle: defineIcon('rectangle'),
  Caution: defineIcon('caution'),
  CautionSolid: defineIcon('caution-solid'),
  Logout: defineIcon('logout'),
  Search: defineIcon('search'),
  ProductPlaceholder: defineIcon('product-placeholder'),
  Add: defineIcon('add'),
  Multifinance: defineIcon('multifinance'),
  Session: defineIcon('session'),
  Reload: defineIcon('reload'),
  Question: defineIcon('question'),
  Shop: defineIcon('shop'),
  Lock: defineIcon('lock'),
  SmallLock: defineIcon('small-lock'),
  BusinessProfile: defineIcon('businessProfile'),
  Star: defineIcon('star'),
  UserSession: defineIcon('user-session'),
  timeClock: defineIcon('time-clock'),
  Download: defineIcon('download'),
  logoBCA: defineIcon('bank-logo-bca'),
  ShowPass: defineIcon('password-show'),
  ShowHide: defineIcon('password-hide'),
  Cross: defineIcon('cross'),
  Trash: defineIcon('trash'),
  Close: defineIcon('close'),
  Close24: defineIcon('close-24'),
  Checklist: defineIcon('checklist'),
  CheckCircled: defineIcon('check-circled'),
  Bank: defineIcon('bank'),
  Minimarket: defineIcon('market'),
  BCA: defineIcon('bank-bca'),
  BRI: defineIcon('bank-bri'),
  BNI: defineIcon('bank-bni'),
  DANAMON: defineIcon('bank-danamon'),
  MANDIRI: defineIcon('bank-mandiri'),
  KEBHANA: defineIcon('bank-kebhana'),
  ALTO: defineIcon('bank-alto'),
  PRIMA: defineIcon('bank-prima'),
  PERMATA: defineIcon('bank-permata'),
  CIMB: defineIcon('bank-cimb'),
  ATMBERSAMA: defineIcon('bank-atmbersama'),
  ALFAMIDI: defineIcon('market-alfamidi'),
  ALFAMART: defineIcon('market-alfamart'),
  LAWSON: defineIcon('market-lawson'),
  DANDAN: defineIcon('market-dandan'),
  OnboardDownload: defineIcon('step-download'),
  OnboardManagement: defineIcon('step-management'),
  OnboardPost: defineIcon('step-post'),
  playVideo: defineIcon('play-video'),
  EyeHidden: defineIcon('eye-hidden'),
  EyeShow: defineIcon('eye-show'),
  SuccessSign: defineIcon('success-sign'),
  BadgePremium: defineIcon('badge-premium'),
  BadgeLite: defineIcon('badge-lite'),
  BadgeTrial: defineIcon('badge-trial'),
  HelpInfo: defineIcon('help-info'),
  HelperImage: defineIcon('help-image'),
  Phone: defineIcon('phone'),
  Mail: defineIcon('mail'),
  ActivityListing: defineIcon('activity-list'),
  Papper: defineIcon('papper'),
  Gopay: defineIcon('gopay'),
  MEGA: defineIcon('mega'),
  Cashlez: defineIcon('cashlez'),
  LinkAja: defineIcon('linkaja'),
  Dana: defineIcon('dana'),
  Ovo: defineIcon('ovo'),
  EmptyEmployee: defineIcon('empty-employee'),
  EmptyDevice: defineIcon('empty-device'),
  EmptyCustomer: defineIcon('empty-customer'),
  File: defineIcon('file'),
  LockFeature: defineIcon('lock-feature'),
  SmallLoader: defineIcon('small-loader'),
  Minus: defineIcon('minus'),
  QRPayment: defineIcon('qr-payment'),
  Contact: defineIcon('contact'),
  ShoppingBag: defineIcon('shopping-bag'),
  Copy: defineIcon('copy'),
  Coupon: defineIcon('coupon'),
  List: defineIcon('list'),
  Payment: defineIcon('payment'),
  EMoney: defineIcon('emoney'),
  PaymentCard: defineIcon('payment-card'),
  Outlet: defineIcon('outlet'),
  User: defineIcon('user'),
  Share: defineIcon('share'),
  Burger: defineIcon('burger'),
  Whatsapp: defineIcon('whatsapp'),
  Facebook: defineIcon('facebook'),
  Twitter: defineIcon('twitter'),
  Error: defineIcon('error'),
  Ingredient: defineIcon('ingredient'),
  CautionCircle: defineIcon('caution-circle'),
  OnBoardStep1: defineIcon('onboard-step-1'),
  OnBoardStep2: defineIcon('onboard-step-2'),
  OnBoardStep3: defineIcon('onboard-step-3'),
  More: defineIcon('more'),
  TriangleDown: defineIcon('triangle-down'),
  TriangleRight: defineIcon('triangle-right'),
  Inventory: defineIcon('inventory'),
  SettingFlat: defineIcon('setting-flat'),
  TrashFlat: defineIcon('trash-flat'),
  AddOval: defineIcon('add-oval'),
  EditItem: defineIcon('edit-item'),
  Chat: defineIcon('chat'),
  HelpOutline: defineIcon('help-outline'),
  CautionTriangle: defineIcon('caution-triangel'),
}
