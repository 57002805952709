import * as React from 'react'
import classNames from 'classnames'
import { BaseProps } from '../props'
import { Text } from 'views/components'
import './style.scss'
import { TimerCountdown, CountdownCounter } from 'utilities/date'
import { withLeadingZero } from 'utilities/converter/modeller'

interface TimeUnitProps extends BaseProps {
  value: number
  unit: string
}

const TimeUnit = (props?: TimeUnitProps) => {
  const time: string = withLeadingZero(props.value, 2)
  return (
    <React.Fragment>
      <div className='post-countdown-second'>
        <Text.Heading h={6} align='center' noMargin color='black'>
          {time[0]}
        </Text.Heading>
        <Text.Heading h={6} align='center' noMargin color='black'>
          {time[1]}
        </Text.Heading>
        <Text.Paragraph size={12} color='brownGray' align='center' noMargin>
          {props.unit}
        </Text.Paragraph>
      </div>
    </React.Fragment>
  )
}

interface TimeProps extends BaseProps {
  children?: React.ReactNode
  endTime: Date
}

const Time: React.FC<TimeProps> = (props: TimeProps) => {
  const className = classNames(props.className || '', 'post-countdown-style')

  const [timeLeft, setTimeLeft] = React.useState<TimerCountdown | null>(null)

  const countdownCounter = new CountdownCounter(t => setTimeLeft(t))

  React.useEffect(() => {
    countdownCounter.start(props.endTime)
  }, [])

  return (
    <div className={className}>
      <React.Fragment>
        <TimeUnit unit='Jam' value={timeLeft ? timeLeft.hours : 0} />
        <Text.Span
          align='center'
          color='black'
          style={{ position: 'relative', top: '-25px' }}
          size={24}
        >
          :
        </Text.Span>
        <TimeUnit unit='Menit' value={timeLeft ? timeLeft.minutes : 0} />
        <Text.Span
          align='center'
          color='black'
          style={{ position: 'relative', top: '-25px' }}
          size={24}
        >
          :
        </Text.Span>
        <TimeUnit unit='Detik' value={timeLeft ? timeLeft.seconds : 0} />
      </React.Fragment>
    </div>
  )
}

export default {
  Time,
}
