import { DropdownItem } from 'views/components/Field/Dropdown'

interface ResetDataSelector {
  resetDataDropdownItemList: DropdownItem[]
}

const resetDataDropdownItemList: DropdownItem[] = [
  {
    value: 'transaction',
    label: 'Reset Data Transaksi',
  },
  {
    value: 'management',
    label: 'Reset Data Manajemen',
  },
]

export default function (): ResetDataSelector {
  return {
    resetDataDropdownItemList,
  }
}
