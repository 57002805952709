import { featureCheck, getAppConfig, isAnyOfTheseFeaturesEnabled } from 'config'

const AF = (window as any).AF

const isNotLocalMode = getAppConfig().mode !== 'local'
const isAppsflyerActive =
  isNotLocalMode && isAnyOfTheseFeaturesEnabled(featureCheck.home.appsflyer)

const dispatchEvent = (
  eventName: string,
  value?: string,
  eventRevenue?: number,
  eventRevenueCurrency?: string,
  eventType?: string
): void => {
  if (isAppsflyerActive) {
    AF('pba', 'event', {
      eventType: eventType ?? 'EVENT',
      eventName: eventName,
      eventValue: value,
      eventRevenue: eventRevenue,
      eventRevenueCurrency: eventRevenueCurrency,
    })
  }
}

const appsflyer = {
  dispatchEvent,
}

export default appsflyer
