import { BaseApi } from 'api/base.api'
import { IReceiptApi } from 'domains/management/receipt/contracts/api'
import { ReceiptModel } from 'domains/management/receipt/models'

export default class ReceiptApi extends BaseApi implements IReceiptApi {
  constructor() {
    super('/v2/settings/receipt')
  }

  public async getReceipt(): Promise<ReceiptModel> {
    return await this.makeForCamelCase('GET', '')
  }

  public async updateReceipt(requestBody: ReceiptModel): Promise<ReceiptModel> {
    return await this.makeForCamelCase('POST', '', requestBody)
  }
}
