import React, { useState, useEffect } from 'react'
import { ModalProps, Modal } from 'views/components/Modal'
import {
  Panel,
  Text,
  Icon,
  Break,
  Button,
  Field,
  FormAction,
  Form,
  Wrapper,
  Sided,
} from 'views/components'
import { CountdownCounter, TimerCountdown } from 'utilities/date'

import { resetPasswordService, registerService, commonService } from 'injectors'
import moment from 'moment'
import { withLeadingZero } from 'utilities/converter/modeller'
import { resetPasswordOTPSchema } from 'views/modules/Auth/ResetPassword/ResetPasswordOTP/resetPasswordOTPSchema'

interface Props extends ModalProps<string> {
  resetPasswordTokenId: string
  phone: string
}

export interface OTPRequest {
  otp: string
}

const OTPConfirmationModal: React.FC<Props> = props => {
  const handleCheckOTP = async (action: FormAction<OTPRequest>) => {
    try {
      const otpRequest: OTPRequest = {
        otp: `${action.getValues().otp}`,
      }
      await resetPasswordService.checkResetPasswordToken(
        otpRequest,
        props.resetPasswordTokenId
      )
      props.close(`${action.getValues().otp}`)
    } catch (error) {
      action.setFieldError('otp', 'Kode OTP anda salah')
    }
  }

  const startTimer = () => {
    const t = moment().add(120, 'second').toDate()
    countdownCounter.start(t)
  }

  const renderSendOTPLink = () => (
    <Text.Heading
      className='cursor-pointer'
      h={6}
      align='center'
      noMargin
      color='blue'
      onClick={() => resendOTP()}
    >
      Kirim Ulang Kode OTP
    </Text.Heading>
  )

  const renderCountdown = () => (
    <Text.Heading
      className='cursor-pointer'
      h={6}
      align='center'
      noMargin
      color='semiGray'
    >
      Menunggu ({withLeadingZero(timeLeft.minutes, 2)}:
      {withLeadingZero(timeLeft.seconds, 2)})
    </Text.Heading>
  )

  const resendOTP = async () => {
    try {
      await registerService.resendOTP(props.resetPasswordTokenId)
      startTimer()
    } catch (error) {
      commonService.setAlert({
        message: 'Gagal mengirimkan kode OTP, silahkan coba lagi',
        type: 'error',
      })
    }
  }

  const [timeLeft, setTimeLeft] = useState<TimerCountdown | null>(null)

  const countdownCounter = new CountdownCounter(t => {
    setTimeLeft(t)
  })

  useEffect(() => {
    startTimer()
  }, [])

  const OTPForm = () => (
    <Form.Form
      height='100%'
      initialValues={
        {
          otp: undefined,
        } as OTPRequest
      }
      validationSchema={resetPasswordOTPSchema}
      render={(action: FormAction<OTPRequest>) => {
        return (
          <React.Fragment>
            <Field.Text numberOnly label='Kode OTP' name='otp' />
            <Break height={16} />
            <Wrapper padding='5px'>
              <Button
                size='large'
                theme='primary'
                type='button'
                label='Lanjut'
                fullW={true}
                disabled={action.getValues().otp ? false : true}
                onClick={() => handleCheckOTP(action)}
              />
            </Wrapper>
            <Break height={10} />
            {timeLeft ? renderCountdown() : renderSendOTPLink()}
          </React.Fragment>
        )
      }}
    />
  )

  return (
    <Modal
      width={'470px'}
      dismiss={props.dismiss}
      type='blank'
      canDismissOutside={false}
    >
      <Panel.Panel>
        <Panel.Header>
          <Sided.Sided justifyContent='center' justify='center'>
            <Sided.Remaining>
              <Text.Heading h={5} color='black'>
                {' '}
                Konfirmasi OTP
              </Text.Heading>
            </Sided.Remaining>
            <Sided.Fixed>
              <Icon.Close
                size={24}
                style={{ cursor: 'pointer' }}
                onClick={() => props.dismiss()}
              />
            </Sided.Fixed>
          </Sided.Sided>
        </Panel.Header>
        <Panel.Content>
          <Text.Paragraph size={14} color='brownGray' align='left'>
            Masukkan kode OTP yang dikirimkan ke{' '}
            <Text.Span color='black'>{props.phone}</Text.Span>. Ingat jangan
            memberi tahu kode OTP kepada siapapun!
          </Text.Paragraph>
          <Break height={16} />
          {OTPForm()}
        </Panel.Content>
      </Panel.Panel>
    </Modal>
  )
}

export default OTPConfirmationModal
