import * as React from 'react'
import { Text } from '..'
import classNames from 'classnames'
import { BaseProps } from '../props'
import './style.scss'

interface Props extends BaseProps {
  fullName: string
  fontSize?: number
}

const ProfileInitial = (props: Props) => {
  const className = classNames(props.className || '', 'post-profile-initial')
  const splittedName = props.fullName.split(' ')
  let initial = ''
  if (splittedName.length > 1) {
    initial = `${splittedName[0].substr(0, 1)}${splittedName[
      splittedName.length - 1
    ].substr(0, 1)}`
  } else {
    initial = `${splittedName[0].substr(0, 1)}${splittedName[0].substr(1, 1)}`
  }
  return (
    <div
      className={classNames(className)}
      style={props.style}
      data-testid='profile-initial'
    >
      <Text.Heading
        h={3}
        color='brownGray'
        weight='bold'
        style={{ fontSize: props?.fontSize }}
      >
        {initial.toUpperCase()}
      </Text.Heading>
    </div>
  )
}

export default ProfileInitial
