import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { featureCheck } from 'config'
import { CONFIG_LOCALSTORAGE } from 'constants/localStorage'
import { REGEX } from 'constants/regex'
import {
  CreateSingleProductRequest,
  CreateSingleProductRequestSchema,
  ProductModel,
} from 'domains/management/products/models'
import { ImageUploadResponse } from 'domains/media'
import {
  categoryService,
  serviceTypeService,
  productService,
  mediaService,
  commonService,
} from 'injectors'
import { getLocalStorage } from 'utilities/localStorage'
import netcore from 'utilities/netcore'
import { trimDuplicateSpace } from 'utilities/strings'
import {
  Form,
  Text,
  Break,
  Button,
  FormAction,
  Wrapper,
  Sided,
  Tabs,
  TabPane,
  VSided,
  FeatureFlag,
} from 'views/components'
import { ModalProps, Modal, DefaultModalService } from 'views/components/Modal'

import DeleteProductModal from '../DeleteProductModal'
import selector, { ProductViewModel } from '../selector'
import Context from './context'
import { productFormSchema } from './productFormSchema'
import ProductInfoForm from './ProductInfoForm'
import StockForm from './StockForm'
import { useLingui } from '@lingui/react'
import { Trans, t } from '@lingui/macro'
import useDebouncedCallback from 'use-debounce/lib/useDebouncedCallback'

interface ProductFormModalProperties extends ModalProps {
  product?: ProductModel
  autoFillingProductName?: string
}

const ProductFormModal: React.FC<ProductFormModalProperties> = properties => {
  const {
    id,
    name,
    categoryId,
    description,
    singleData,
    pictureUrl,
    outlets,
    applyOnNewOutlet,
  } = properties.product ? properties.product : ({} as ProductModel)
  const { autoFillingProductName } = properties
  const { outletList }: ProductViewModel = useSelector(selector)
  const employeeId = getLocalStorage(CONFIG_LOCALSTORAGE.STAFF_ID)
  const { i18n } = useLingui()
  const submit = async (
    actions: FormAction<CreateSingleProductRequestSchema>
  ) => {
    const isValid = actions.validate()
    if (!isValid) {
      return
    }
    const formValues = actions.getValues()
    let values: CreateSingleProductRequest = {
      ...formValues,
      dimension: {
        length: formValues.length,
        width: formValues.width,
        height: formValues.height,
      },
    }
    try {
      if (
        values.pictureUrl &&
        !REGEX.URL_LINK.test(values.pictureUrl) &&
        values.pictureUrl.length > 0 &&
        values.pictureUrl !== 'image_removed'
      ) {
        const productImageUrl: ImageUploadResponse =
          await mediaService.uploadImageToServer(values.pictureUrl)
        values.pictureUrl = productImageUrl.url
      }
      if (pictureUrl && !values.pictureUrl) {
        values.pictureUrl = pictureUrl
      }
      if (values.pictureUrl === 'image_removed') {
        values.pictureUrl = undefined
      }
      values = {
        ...values,
        name: trimDuplicateSpace(values.name),
        description: trimDuplicateSpace(values.description),
      }

      if (!values.trackStock) {
        values.stock = undefined
        // values.minStock = undefined
        values.stockAdjustment = undefined
      }

      const requestBody = {
        ...values,
      }
      if (requestBody.stockAdjustment && !requestBody.stockAdjustment.type) {
        requestBody.stockAdjustment = undefined
      }

      if (properties.product && properties.product.id) {
        await productService.updateSingleProduct(requestBody, id)
        if (values.trackStock) {
          netcore.dispatchEvent('inventory_complete', {
            source: 'edit_product',
            action: values.stockAdjustment.type,
          })
        }
        commonService.setAlert({
          type: 'success',
          message: i18n._(t`Produk berhasil diubah`),
        })
      } else {
        await productService.createSingleProduct(requestBody, {
          'Content-Type': 'application/json',
          'POST-Platform': 'dashboard',
        })
        netcore.dispatchEvent('create_product_complete', {
          source: 'management',
        })
        if (values.trackStock) {
          netcore.dispatchEvent('inventory_complete', {
            source: 'create_product',
            action: values.stockAdjustment.type,
          })
        }
        commonService.setAlert({
          type: 'success',
          message: i18n._(t`Produk berhasil dibuat`),
        })
      }
      properties.close(true)
    } catch (error) {
      if (error.code === 'empty_outlet') {
        actions.setFieldError('outletIds', i18n._(t`Outlet tidak boleh kosong`))
      } else {
        commonService.setAlert({
          type: 'error',
          message: error.message,
        })
      }
    }
  }

  const debouncedDelayMs = 1000
  const debouncedSubmit = useDebouncedCallback(submit, debouncedDelayMs)

  const deleteProduct = async () => {
    try {
      const confirmed = await DefaultModalService.open(DeleteProductModal, {
        name: name,
      })
      if (confirmed) {
        await productService.deleteProduct(id)
        commonService.setAlert({
          type: 'netral',
          message: i18n._(t`Produk berhasil dihapus`),
        })
        properties.close('')
      }
    } catch {
      commonService.setAlert({
        type: 'error',
        message: i18n._(t`Produk gagal dihapus`),
      })
    }
  }

  const base64ImageUrlState = useState<string>('')

  useEffect(() => {
    categoryService.getCategories()
    serviceTypeService.getServiceTypes()
  }, [])

  const handleOnChangeTab = function (id: string) {
    if (id === '1') {
      netcore.dispatchEvent('create_product_info')
    }
    if (id === '2') {
      netcore.dispatchEvent('create_product_inventory')
    }
  }

  return (
    <Modal dismiss={properties.dismiss} type='full' canDismissOutside={false}>
      <Form.Form
        height='100%'
        initialValues={{
          name: autoFillingProductName || name,
          description: description,
          pictureUrl: pictureUrl,
          categoryId: categoryId,
          active: true,
          price: singleData ? singleData.price : undefined,
          pricing: singleData ? [...singleData.pricing] : [],
          unitCost: singleData ? singleData.unitCost : undefined,
          trackStock: singleData ? singleData.trackStock : false,
          minStock: singleData ? singleData.minStock : 0,
          barcode: singleData && singleData.barcode,
          applyOnNewOutlet:
            applyOnNewOutlet !== undefined ? applyOnNewOutlet : true,
          outletIds: outlets
            ? outlets.map(x => x.id)
            : outletList.map(x => x.id),
          sku: singleData && singleData.sku,
          stockAdjustment: {
            type: undefined,
            quantity: 1,
            note: undefined,
          },
          outlets: [],
          weight:
            singleData && singleData.weight > 0 ? singleData.weight : undefined,
          length:
            singleData && singleData.dimension?.length > 0
              ? singleData.dimension.length
              : undefined,
          width:
            singleData && singleData.dimension?.width > 0
              ? singleData.dimension.width
              : undefined,
          height:
            singleData && singleData.dimension?.height > 0
              ? singleData.dimension.height
              : undefined,
        }}
        validationSchema={productFormSchema}
        onSubmit={debouncedSubmit}
        render={(actions: FormAction<CreateSingleProductRequestSchema>) => {
          return (
            <VSided.VSided gutter={24}>
              <VSided.Remaining>
                <Break height={24} />
                <Wrapper width='480px' isCenter verticalGap={24}>
                  <Text.Heading h={4} noMargin>
                    {' '}
                    {properties.product && properties.product.id
                      ? i18n._(t`Ubah Produk`)
                      : i18n._(t`Tambah Produk`)}
                  </Text.Heading>
                  <Context.Provider
                    value={{
                      productId: properties.product
                        ? properties.product.id
                        : undefined,
                      base64ImageUrl: base64ImageUrlState[0],
                      setBase64ImageUrl: base64ImageUrlState[1],
                      actionsProps: actions,
                      singleData,
                    }}
                  >
                    <Tabs defaultActiveKey='1' onChangeTab={handleOnChangeTab}>
                      <TabPane
                        name={i18n._(t`Informasi Produk`)}
                        identifier='1'
                      >
                        <ProductInfoForm />
                      </TabPane>
                      <FeatureFlag
                        featureCheck={featureCheck.management.stockTracking}
                      >
                        <TabPane
                          name={i18n._(t`Manajemen Stok`)}
                          identifier='2'
                        >
                          <StockForm />
                        </TabPane>
                      </FeatureFlag>
                    </Tabs>
                  </Context.Provider>
                </Wrapper>
                {properties.product && !employeeId && (
                  <Wrapper>
                    <Break height={40} />
                    <Trans>
                      <Text.Paragraph
                        size={14}
                        color='red'
                        align='center'
                        onClick={deleteProduct}
                      >
                        Hapus Produk
                      </Text.Paragraph>
                    </Trans>
                  </Wrapper>
                )}
                <Wrapper height='100px'></Wrapper>
              </VSided.Remaining>
              <VSided.Fixed minHeight={78}>
                <Wrapper
                  shadow='0 -2px 4px 0 rgba(0, 0, 0, 0.2)'
                  width='100%'
                  padding='16px'
                  backgroundColor='white'
                >
                  <Wrapper width='480px' isCenter>
                    <Sided.Sided justify='center' gutter={16}>
                      <Sided.Remaining>
                        <Button
                          onClick={() => properties.dismiss()}
                          size='large'
                          theme='netral'
                          type='button'
                          label={i18n._(t`Batal`)}
                          fullW={true}
                        />
                      </Sided.Remaining>
                      <Sided.Remaining>
                        <Button
                          disabled={!actions.isValid()}
                          size='large'
                          theme='primary'
                          type='submit'
                          label={i18n._(t`Simpan`)}
                          fullW={true}
                        />
                      </Sided.Remaining>
                    </Sided.Sided>
                  </Wrapper>
                </Wrapper>
              </VSided.Fixed>
            </VSided.VSided>
          )
        }}
      />
    </Modal>
  )
}

export default ProductFormModal
