import { BaseApi } from 'api/base.api'
import { IChargesApi } from 'domains/management/charges/contracts/api'
import {
  ChargesDetails,
  ChargesRequestBody,
} from 'domains/management/charges/models'

export default class ChargesApi extends BaseApi implements IChargesApi {
  constructor() {
    super('/v3/settings/charge')
  }

  public async getCharges(): Promise<ChargesDetails> {
    return await this.makeForCamelCase('GET', '')
  }

  public async updateCharges(
    request: ChargesRequestBody
  ): Promise<ChargesDetails> {
    return await this.makeForCamelCase('POST', '', request)
  }
}
