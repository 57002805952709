export function disableDragAndDrop() {
  window.addEventListener(
    'dragover',
    e => {
      e.preventDefault()
    },
    false
  )
  window.addEventListener(
    'drop',
    e => {
      e.preventDefault()
    },
    false
  )
}
