import React from 'react'
import classNames from 'classnames'
import './style.css'
import { Text } from 'views/components'

interface Props {
  onClick?: () => any
  status?: 'lite' | 'trial' | 'premium'
  isAlmostExpired?: boolean
}

const BadgeStatus = (props: Props) => {
  const className = classNames(
    'badge-status',
    'badge-' + (props.status || 'lite')
  )

  return (
    <div
      onClick={props.onClick}
      style={{ cursor: 'pointer' }}
      className={className}
    >
      {props.isAlmostExpired && <span className='notification-dot'></span>}
      <Text.Heading h={6} noMargin>
        {props.status === 'lite' ? 'Upgrade ke Premium' : props.status}
      </Text.Heading>
    </div>
  )
}

export default BadgeStatus
export { BadgeStatus }
