import { BaseApi } from 'api/base.api'
import { IDeviceApi } from 'domains/management/device/contracts/api'
import { DeviceModel, DeviceBody } from 'domains/management/device/models'

export default class DeviceApi extends BaseApi implements IDeviceApi {
  constructor() {
    super('/v2')
  }

  public async getDevices(): Promise<DeviceModel[]> {
    return await this.makeForCamelCaseForList('GET', '/devices')
  }

  public async getDevice(deviceId: string): Promise<DeviceModel> {
    return await this.makeForCamelCase('GET', `/devices/${deviceId}`)
  }

  public async createDevice(device: DeviceBody): Promise<DeviceModel> {
    return await this.makeForCamelCase('POST', '/devices', device)
  }

  public async updateDevice(
    deviceId: string,
    device: DeviceBody
  ): Promise<DeviceModel> {
    return await this.makeForCamelCase('POST', `/devices/${deviceId}`, device)
  }

  public async deleteDevice(deviceId: string): Promise<void> {
    return await this.make('DELETE', `/devices/${deviceId}`)
  }
}
