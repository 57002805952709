import { convertBase64ToBlob } from '../file/b64toBlob'
export * from './previewImageInNewTab'
export * from './resizeImage'
export * from './getCroppedImage'

export function convertBase64ImageToBlob(base64: string): Blob {
  const block = base64.split(';')
  // Get the content type of the image
  const contentType = block[0].split(':')[1] // In this case "image/gif"
  // get the real base64 content of the file
  const realData = block[1].split(',')[1] // In this case "R0lGODlhPQBEAPeoAJosM...."

  return convertBase64ToBlob(realData, contentType)
}
