import { AppState } from 'domains/contracts/appState'
import { Category } from 'domains/management/category/models'
import { toList } from 'utilities/converter/list'
import { ProductV2Model } from 'domains/management/products/models'

export interface CategoryViewModel {
  categories: Category[]
  isLoading: boolean
  products: ProductV2Model[]
  qrisBannerVisible: boolean
}

export default (state: AppState): CategoryViewModel => ({
  categories: toList(state.management.category.categories),
  isLoading: state.common.loading['SET_CATEGORY_PRODUCT'],
  products: toList(state.management.product.productsV2),
  qrisBannerVisible: state.common.qrisBannerVisible,
})
