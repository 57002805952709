import * as React from 'react'
import classNames from 'classnames'
import { BaseProps, BaseParentProps } from '../props'
import './style.scss'
import { AlignmentClass } from '../const'

interface FixProps extends BaseParentProps, BaseProps {
  minWidth?: number
  width?: number
  align?: 'left' | 'center' | 'right'
  verticalAlign?: 'center' | 'flex-start' | 'flex-end'
  style?: React.CSSProperties
}

const Fixed = (props: FixProps) => {
  const className = classNames(
    props.className || '',
    'post-sided-fix',
    'post-sided-fix-text-align-' + (props.align || 'left')
  )
  const style: React.CSSProperties = {
    minWidth: props.minWidth,
    width: props.width,
    alignSelf: props.verticalAlign,
    ...props.style,
  }
  return (
    <div style={style} className={classNames(className)}>
      <React.Fragment>{props.children}</React.Fragment>
    </div>
  )
}

interface RemainingProps extends BaseParentProps, BaseProps {
  align?: 'center' | 'left' | 'right'
  verticalAlign?: 'center' | 'flex-start' | 'flex-end'
}

const Remaining = (props: RemainingProps) => {
  const basicClassNames = classNames(
    props.className || '',
    'post-sided-remaining',
    'post-sided-remaining-text-align-' + (props.align || 'left')
  )
  const style: React.CSSProperties = {
    textAlign: props.align,
    alignSelf: props.verticalAlign,
    ...props.style,
  }
  return (
    <section style={style} className={basicClassNames}>
      <React.Fragment>{props.children}</React.Fragment>
    </section>
  )
}

interface SidedProps extends BaseParentProps, BaseProps {
  gutter?: 0 | 4 | 8 | 12 | 16 | 20 | 24 | 32 | 40
  justify?: 'center' | 'bottom' | 'top' | 'normal'
  justifyContent?: AlignmentClass
  onClick?: () => any
  style?: React.CSSProperties
}

const Sided: React.FC<SidedProps> = (props: SidedProps) => {
  const className = classNames(
    props.className || '',
    'post-sided',
    'post-sided-gutter-' + (props.gutter != undefined ? props.gutter : 8),
    'post-sided-justify-' + (props.justify || 'normal'),
    'post-sided-justify-content-' + (props.justifyContent || 'left')
  )
  return (
    <div className={className} onClick={props.onClick} style={props.style}>
      <React.Fragment>{props.children}</React.Fragment>
    </div>
  )
}

export default {
  Sided,
  Fixed,
  Remaining,
}
