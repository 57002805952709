export async function resizeImage(
  base64: string,
  width: number,
  height: number
): Promise<string> {
  const imageProperty = await getImageDimension(base64)
  const originalProportion: number = imageProperty.height / imageProperty.width
  const expectedProportion: number = height / width
  let scaling = 1
  let offsetX = 0
  let offsetY = 0
  if (originalProportion > expectedProportion) {
    scaling = width / imageProperty.width
  } else {
    scaling = height / imageProperty.height
  }
  if (scaling > 1) {
    width = width / scaling
    height = height / scaling
    scaling = 1
  }
  const newWidth = imageProperty.width * scaling
  const newHeight = imageProperty.height * scaling
  if (newWidth > width) {
    offsetX = (newWidth - width) / 2
  }
  if (newHeight > height) {
    offsetY = (newHeight - height) / 2
  }
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')
  // set its dimension to target size
  canvas.width = width
  canvas.height = height
  // draw source image into the off-screen canvas:
  ctx.drawImage(imageProperty.image, -offsetX, -offsetY, newWidth, newHeight)
  // encode image to data-uri with base64 version of compressed image
  return canvas.toDataURL()
}

export interface ImageProperty {
  image: HTMLImageElement
  width: number
  height: number
}

export function getImageDimension(imageUrl: string): Promise<ImageProperty> {
  return new Promise<ImageProperty>(res => {
    const image: HTMLImageElement = new Image()
    image.onload = function () {
      res({
        image,
        width: image.width,
        height: image.height,
      })
    }
    image.src = imageUrl
  })
}
