import { BaseService } from 'domains/commons/services'
import { sendEmail } from 'utilities/mailer'
import { User } from 'domains/users/models'

export interface IHelpdeskService {
  sendEmail(): Promise<void>
  emailAddress: string
  phone: string
}

export class HelpdeskService extends BaseService implements IHelpdeskService {
  customerServiceEmail = 'cs@post.app'
  customerServicePhone = '+62 21-5071-1700'

  async sendEmail(): Promise<void> {
    const user: User = await this.api.users.getUser()
    sendEmail({
      to: this.customerServiceEmail,
      subject: `Bantuan%20-%20${user.ownerName}%20-%20`,
      body: `Nama%20=%20${user.ownerName}
            %0D%0AEmail%20=%20${user.email}
            %0D%0ANomor%20HP%20=%20${user.phone}
            %0D%0ANama%20Toko%20=%20${user.businessName}
            %0D%0A%0D%0ATulis%20pertanyaan%20disini...`,
    })
  }
  get emailAddress(): string {
    return this.customerServiceEmail
  }
  get phone(): string {
    return this.customerServicePhone
  }
}
