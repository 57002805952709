import { IDiningStorage } from 'domains/contactless/contracts/storage'
import { BaseStorage } from 'storages/base'
import { Dict } from 'utilities/type'
import { OutletSetting } from 'domains/contactless/models'
import { SET_OUTLET_SETTINGS } from './actions'

export class DiningStorage extends BaseStorage implements IDiningStorage {
  public setOutletSetting(outletSetting: Dict<OutletSetting>): void {
    this.dispatch(SET_OUTLET_SETTINGS, outletSetting)
  }
}
