import {
  ResetPasswordTokenRequest,
  CheckResetPasswordOTPRequest,
  ResetPasswordOTPRequest,
} from '../models'
import { BaseService } from 'domains/commons/services'

export class ResetPasswordService extends BaseService {
  public createResetPasswordToken = async (
    request: ResetPasswordTokenRequest
  ) => {
    try {
      const resetPasswordResponse =
        await this.api.auth.createResetPasswordToken(request)
      const { id } = resetPasswordResponse
      return id
    } catch (error) {
      throw error
    }
  }

  public checkResetPasswordToken = async (
    request: CheckResetPasswordOTPRequest,
    resetPasswordTokenId: string
  ) => {
    try {
      await this.api.auth.checkResetPasswordToken(request, resetPasswordTokenId)
    } catch (error) {
      throw error
    }
  }

  public resetPassword = async (request: ResetPasswordOTPRequest) => {
    try {
      await this.api.auth.resetPassword(request)
    } catch (error) {
      throw error
    }
  }
}

export interface IResetPasswordService {
  createResetPasswordToken: (
    request: ResetPasswordTokenRequest
  ) => Promise<string>
  checkResetPasswordToken: (
    request: CheckResetPasswordOTPRequest,
    resetPasswordTokenId: string
  ) => void
  resetPassword: (request: ResetPasswordOTPRequest) => void
}
