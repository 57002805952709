import { BaseStorage } from 'storages/base'
import { Dict } from 'utilities/type'
import { IPaymentMethodStorage } from 'domains/management/paymentMethod/contracts/storage'
import { SET_PAYMENT_METHODS, SET_QRIS_PAYMENT_METHODS } from './actions'
import {
  PaymentMethodModel,
  QRISPaymentMethod,
} from 'domains/management/paymentMethod/models'

export class PaymentMethodStorage
  extends BaseStorage
  implements IPaymentMethodStorage
{
  setPaymentMethods(paymentMethods: Dict<PaymentMethodModel>): void {
    this.dispatch(SET_PAYMENT_METHODS, paymentMethods)
  }
  setQRISPaymentMethods(QRISPaymentMethods: QRISPaymentMethod): void {
    this.dispatch(SET_QRIS_PAYMENT_METHODS, QRISPaymentMethods)
  }
}
