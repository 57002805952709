import { Reducer, StateActions } from 'utilities/redux/reducer'
import { Dict } from 'utilities/type'
import { ExpenseState } from 'domains/management/expense/states'
import { ExpenseCategory, Expense } from 'domains/management/expense/models'
import {
  SET_EXPENSE,
  APPEND_EXPENSE,
  SET_EXPENSE_CATEGORIES,
  APPEND_EXPENSE_CATEGORIES,
  SET_SHOW_EXPENSE_DETAILS,
  SET_EXPENSE_DETAILS,
  SET_EXPENSE_FILTER_CATEGORY_NAME,
} from './actions'
import { ExpenseSummary } from 'domains/reporting/models'

export class ExpenseReducer extends Reducer<ExpenseState> {
  constructor() {
    super({
      expenses: {},
      categories: {},
      showExpenseDetails: false,
      expenseDetails: {},
      categoryName: '',
    })
  }

  setExpenses = (
    state: ExpenseState,
    expenses: Dict<ExpenseSummary>
  ): ExpenseState => {
    return {
      ...state,
      expenses: expenses,
    }
  }

  appendExpenses = (
    state: ExpenseState,
    expenses: Dict<ExpenseSummary>
  ): ExpenseState => {
    return {
      ...state,
      expenses: {
        ...expenses,
        ...state.expenses,
      },
    }
  }

  setExpenseCategories = (
    state: ExpenseState,
    categories: Dict<ExpenseCategory>
  ): ExpenseState => {
    return {
      ...state,
      categories: categories,
    }
  }

  appendExpenseCategories = (
    state: ExpenseState,
    categories: Dict<ExpenseCategory>
  ): ExpenseState => {
    return {
      ...state,
      categories: {
        ...categories,
        ...state.categories,
      },
    }
  }

  setShowExpenseDetails = (
    state: ExpenseState,
    showExpenseDetails: boolean
  ): ExpenseState => {
    return {
      ...state,
      showExpenseDetails: showExpenseDetails,
    }
  }

  setExpenseDetails = (
    state: ExpenseState,
    expenseDetails: Dict<Expense>
  ): ExpenseState => {
    return {
      ...state,
      expenseDetails: expenseDetails,
    }
  }

  setExpenseFilterCategoryName = (
    state: ExpenseState,
    categoryName: string
  ): ExpenseState => {
    return {
      ...state,
      categoryName: categoryName,
    }
  }

  get actions(): StateActions<ExpenseState> {
    return {
      [SET_EXPENSE]: this.setExpenses,
      [APPEND_EXPENSE]: this.appendExpenses,
      [SET_EXPENSE_CATEGORIES]: this.setExpenseCategories,
      [APPEND_EXPENSE_CATEGORIES]: this.appendExpenseCategories,
      [SET_SHOW_EXPENSE_DETAILS]: this.setShowExpenseDetails,
      [SET_EXPENSE_DETAILS]: this.setExpenseDetails,
      [SET_EXPENSE_FILTER_CATEGORY_NAME]: this.setExpenseFilterCategoryName,
    }
  }
}
