import React from 'react'
import classNames from 'classnames'
import './style.css'

interface Props {
  children?: React.ReactNode
  style?: React.CSSProperties
  onClick?: any
}

const FloatingBox = (props: Props) => {
  const className = classNames('post-floating-box')
  const style: React.CSSProperties = { ...props.style }

  return (
    <div className={className} onClick={props.onClick} style={style}>
      {props.children}
    </div>
  )
}

export default FloatingBox
