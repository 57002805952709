import { BaseApi } from 'api/base.api'
import {
  ProductModel,
  CreateSingleProductRequest,
  CreateVariationRequest,
  CreateComboProductRequest,
  MassUploadRequest,
  MassUpdateRequest,
  ProductStockHistory,
} from 'domains/management/products/models'
import { IProductApi } from 'domains/management/products/contracts/api'
import {
  ProductListQueryString,
  ProductStockHistoryListQueryString,
} from 'domains/management/products/states'

export default class ProductApi extends BaseApi implements IProductApi {
  constructor() {
    super('/v2/products')
  }

  public async getProducts(
    queryParams?: ProductListQueryString
  ): Promise<ProductModel[]> {
    return await this.makeForCamelCaseForList<ProductModel>(
      'GET',
      '',
      queryParams
    )
  }
  public async getProductStockHistories(
    productId: string,
    queryParams?: ProductStockHistoryListQueryString
  ): Promise<ProductStockHistory[]> {
    return await this.makeForCamelCaseForList<ProductStockHistory>(
      'GET',
      `/${productId}/stock-histories`,
      queryParams
    )
  }

  public async getProduct(id?: string): Promise<ProductModel> {
    return await this.makeForCamelCase<ProductModel>('GET', `/${id}`)
  }

  public async getProductByBarcode(barcode: string): Promise<ProductModel> {
    return await this.makeForCamelCase<ProductModel>(
      'GET',
      `/${barcode}/barcode`
    )
  }

  public async getFavoriteProduct(id: string): Promise<ProductModel> {
    return await this.makeForCamelCase<ProductModel>('GET', `/${id}/favorite`)
  }

  public async getComboProducts(): Promise<ProductModel[]> {
    return await this.makeForCamelCaseForList<ProductModel>(
      'GET',
      '/comboProducts'
    )
  }

  public async createSingleProduct(
    query: CreateSingleProductRequest,
    headers: object = {}
  ): Promise<ProductModel> {
    return await this.makeForCamelCase('POST', '/single', query, {}, headers)
  }

  public async createVariationProduct(
    query: CreateVariationRequest
  ): Promise<ProductModel> {
    return await this.makeForCamelCase('POST', '/variation', query)
  }

  public async createComboProduct(
    query: CreateComboProductRequest
  ): Promise<ProductModel> {
    return await this.makeForCamelCase('POST', '/comboProducts', query)
  }

  public async updateSingleProduct(
    query: CreateSingleProductRequest,
    id: string
  ): Promise<ProductModel> {
    return await this.makeForCamelCase('POST', `/${id}/single`, query)
  }

  public async updateVariationProduct(
    query: CreateVariationRequest,
    id: string
  ): Promise<ProductModel> {
    return await this.makeForCamelCase('POST', `/${id}/variation`, query)
  }

  public async updateComboProduct(
    query: CreateComboProductRequest,
    id: string
  ): Promise<ProductModel> {
    return await this.makeForCamelCase('POST', `/${id}/comboProducts`, query)
  }

  public async deleteProduct(id?: string): Promise<void> {
    return await this.make('DELETE', id)
  }

  public async uploadMass(request?: MassUploadRequest): Promise<void> {
    return await this.makeForCamelCase('POST', '/single/batch-insert', request)
  }

  public async updateMass(request?: MassUpdateRequest): Promise<void> {
    return await this.makeForCamelCase('POST', '/single/batch-update', request)
  }
}
