import { TableConfig, ColumnConfig, convertToTableConfig } from './converter'
import { FuncArgs, XlsxGenerator } from 'utilities/xlsxGenerator'
import {
  SegmentedData,
  ItemWithSegment,
  toListAsRowsWithKeysAsSegmentedColumns,
  toListAsSegmentedColumnsWithKeysAsRows,
} from './toSegmentedData'
import { Dict } from 'utilities/type'
import Xlsx from 'xlsx'

export type VCol = string | number | boolean | undefined
export type VRow = VCol[]

export function generateVirtualTable<T>(
  list: T[],
  tableConfig: TableConfig<T, VRow>
): VRow[] {
  const headers: VRow[] = tableConfig.headerFunc(list)
  const body: VRow[] = list.map((item: T, index: number) =>
    tableConfig.itemToRowFunc(item, index)
  )
  const footer: VRow[] = tableConfig.footerFunc(list)
  return [...headers, ...body, ...footer]
}

export function toVirtualListAsRowsWithKeysAsSegmentedColumns<T, S = any>(
  stickyColumns: VColumnConfig<ItemWithSegment<T, S>>[],
  list: T[],
  toKeyValues: (item: T) => Dict<S>,
  columnConfigFunc: (key: string) => VColumnConfig<ItemWithSegment<T, S>>[],
  sortingKeysFunc: (
    a: string,
    b: string,
    availableSegments: Dict<S[]>
  ) => number = (a, b) => 0
): VSegmentedData<ItemWithSegment<T, S>, T, S> {
  return toListAsRowsWithKeysAsSegmentedColumns(
    stickyColumns,
    list,
    toKeyValues,
    columnConfigFunc,
    sortingKeysFunc,
    (configs: VColumnConfig<ItemWithSegment<T, S>>[]) =>
      convertToTableConfig(configs, x => x)
  )
}

export function toVirtualListAsSegmentedColumnsWithKeysAsRows<T, S = any>(
  stickyColumns: VColumnConfig<string>[],
  list: T[],
  toKeyValues: (item: T) => Dict<S>,
  columnConfigFunc: (item: ItemWithSegment<T, S>) => VColumnConfig<string>[],
  sortingKeysFunc: (
    a: string,
    b: string,
    availableSegments: Dict<S[]>
  ) => number = (a, b) => 0
): VSegmentedData<string, T, S> {
  return toListAsSegmentedColumnsWithKeysAsRows(
    stickyColumns,
    list,
    toKeyValues,
    columnConfigFunc,
    sortingKeysFunc,
    (configs: VColumnConfig<string>[]) => convertToTableConfig(configs, x => x)
  )
}

export function generateExcelSheetTable<T>(
  list: T[],
  tableConfig: TableConfig<T, VRow>,
  fileName: string,
  sheetName: string,
  additionalTableHeader?: any[],
  additionalUnderTableHeader?: any[],
  customSettingFunc?: FuncArgs<[Xlsx.WorkSheet]>
) {
  const virtualTable = generateVirtualTable(list, tableConfig) as any[][]
  if (additionalUnderTableHeader) {
    virtualTable.splice(1, 0, additionalUnderTableHeader)
  }

  const arr = [...(additionalTableHeader || []), ...virtualTable]
  return new XlsxGenerator()
    .withSheet(sheetName, arr, customSettingFunc)
    .saveAs(fileName)
}

export function generateExcelSheetFromCells(
  arr: any[][],
  fileName: string,
  sheetName: string
) {
  return new XlsxGenerator().withSheet(sheetName, arr).saveAs(fileName)
}

export type VColumnConfig<T> = ColumnConfig<T, VCol>
export type VTableConfig<T> = TableConfig<T, VRow>

export type VSegmentedData<X, A, B> = SegmentedData<X, VRow, A, B>

export function convertToVTableConfig<T>(
  configs: VColumnConfig<T>[]
): VTableConfig<T> {
  return convertToTableConfig(configs, x => x)
}
