import { BaseService } from 'domains/commons/services'
import { ReceiptModel } from '../models'

export class ReceiptService extends BaseService implements IReceiptService {
  public getReceipt = async () => {
    try {
      return await this.api.management.receipt.getReceipt()
    } catch (error) {
      throw error
    }
  }

  public updateReceipt = async (requestBody: ReceiptModel) => {
    try {
      return await this.api.management.receipt.updateReceipt(requestBody)
    } catch (error) {
      throw error
    }
  }
}

export interface IReceiptService {
  getReceipt: () => Promise<ReceiptModel>
  updateReceipt: (requestBody: ReceiptModel) => Promise<ReceiptModel>
}
