import { convertBase64ImageToBlob } from 'utilities/image'
import { BaseService } from 'domains/commons/services'

export class MediaService extends BaseService implements IMediaService {
  async uploadImageToServer(
    base64Picture: string
  ): Promise<ImageUploadResponse> {
    try {
      const blob = convertBase64ImageToBlob(base64Picture)
      if (!blob) {
        throw new Error()
      }
      return await this.api.media.uploadImageFromBlob(blob, 'file')
    } catch (error) {
      throw error
    }
  }
}
export interface ImageUploadResponse {
  url: string
}

export interface IMediaService {
  uploadImageToServer(base64Picture: string): Promise<ImageUploadResponse>
}
