import React from 'react'
import Wrapper from '../Wrapper'
import './style.css'
import classNames from 'classnames'
import { useSelector } from 'react-redux'
import { AppState } from 'domains/contracts/appState'

const OrientationBlockScreen = () => {
  const user = useSelector((x: AppState) => x.user)
  const isAuthenticated = user && user.id
  const className = classNames(
    'post-orientation-block-screen',
    isAuthenticated ? 'responsive' : ''
  )
  return (
    <Wrapper className={className}>
      <Wrapper className='phone'></Wrapper>
      <Wrapper className='message'>Putar layar Anda!</Wrapper>
    </Wrapper>
  )
}

export default OrientationBlockScreen
