import React from 'react'
import { useTable, useSortBy, Column } from 'react-table'
import classNames from 'classnames'
import { BaseProps } from '../props'
import { Text, Icon } from 'views/components'
import './style.css'

export type ReactColumn<D extends object = {}> = Column<D> & {
  headerAlign?: string
  align?: string
  footerColspan?: number
}

interface TableProps extends BaseProps {
  columns: any
  data: any[]
  type?: 'bordered' | 'striped' | 'hovered' | 'borderBottom'
  size?: 'default' | 'fat'
  withIndex: boolean
  withFooter: boolean
  segmentedHeader?: any[]
  segmentedHeaderColspan?: number
  segmentedColspan?: number
}

const ReactTable = (props: TableProps) => {
  const { columns, data } = props
  const isSegmented = props.segmentedHeader?.length > 0
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    footerGroups,
  } = useTable(
    {
      columns,
      data,
    },
    useSortBy
  )

  const renderTable = () => (
    <div className='post-table' style={{ ...props.style }}>
      <table
        {...getTableProps()}
        className={classNames(
          props.className || '',
          'post-table-type-' + (props.type || 'striped'),
          'post-table-size-' + (props.size || 'default')
        )}
      >
        <thead>
          {headerGroups.map((headerGroup: any) => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              className='post-table-tr'
            >
              {props.withIndex && <th>No</th>}
              {headerGroup.headers.map((column: any) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  style={{
                    cursor: !column.disableSortBy ? 'pointer' : 'unset',
                  }}
                >
                  <div style={{ float: column.headerAlign || 'unset' }}>
                    <Text.Heading h={6} style={{ alignSelf: 'center' }}>
                      {column.render('Header')}
                    </Text.Heading>
                    {!column.disableSortBy && (
                      <div style={{ alignSelf: 'center' }}>
                        {column.isSorted && !column.isSortedDesc ? (
                          <Icon.SortCaretup
                            color='blue'
                            className='sort-icon'
                          />
                        ) : (
                          <Icon.SortCaretup className='sort-icon' />
                        )}
                        {column.isSorted && column.isSortedDesc ? (
                          <Icon.SortCaretdown
                            color='blue'
                            className='sort-icon'
                          />
                        ) : (
                          <Icon.SortCaretdown className='sort-icon' />
                        )}
                      </div>
                    )}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row: any, i: number) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()} className='post-table-tr'>
                {props.withIndex && (
                  <td>
                    <Text.Paragraph size={14} color='black'>
                      {i + 1}
                    </Text.Paragraph>
                  </td>
                )}
                {row.cells.map((cell: any) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      className='post-table-td'
                      style={{ textAlign: cell.column.align }}
                    >
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
        {props.withFooter && (
          <tfoot>
            {footerGroups.map((group: any) => (
              <tr {...group.getFooterGroupProps()} className='post-table-tr'>
                {group.headers.map((column: any) => (
                  <td
                    {...column.getFooterProps()}
                    colSpan={column.footerColspan ? column.footerColspan : 1}
                  >
                    {column.render('Footer')}
                  </td>
                ))}
              </tr>
            ))}
          </tfoot>
        )}
      </table>
    </div>
  )

  const renderSegmentedTable = () => (
    <div className='post-table' style={{ ...props.style }}>
      <table
        {...getTableProps()}
        className={classNames(
          props.className || '',
          'post-table-type-' + (props.type || 'striped'),
          'post-table-size-' + (props.size || 'default')
        )}
      >
        <thead>
          <tr className='post-table-tr'>
            <td
              colSpan={props.segmentedColspan}
              className='sticky-1 post-table-td post-table-td-sticky'
              style={{ height: '40px' }}
            >
              <Text.Heading h={6} style={{ alignSelf: 'left' }}>
                {' '}
              </Text.Heading>
            </td>
            {props.segmentedHeader.map((item: any, index: number) => (
              <td
                colSpan={props.segmentedHeaderColspan}
                className='post-table-td'
                key={index}
              >
                <Text.Heading
                  h={6}
                  align='center'
                  style={{ alignSelf: 'center' }}
                >
                  {item}
                </Text.Heading>
              </td>
            ))}
          </tr>
          {headerGroups.map((headerGroup: any) => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              className='post-table-tr'
            >
              {props.withIndex && (
                <th
                  className={classNames(
                    'post-table-td',
                    isSegmented && 'sticky-1 post-table-td-sticky'
                  )}
                >
                  No
                </th>
              )}
              {headerGroup.headers.map((column: any) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  style={{
                    cursor: !column.disableSortBy ? 'pointer' : 'unset',
                  }}
                  className={classNames(
                    'post-table-td',
                    column.columnClass ? column.columnClass : ''
                  )}
                >
                  <div style={{ float: column.headerAlign || 'unset' }}>
                    <Text.Heading
                      h={6}
                      style={{ alignSelf: 'center', top: 'unset' }}
                    >
                      {column.render('Header')}
                    </Text.Heading>
                    {!column.disableSortBy && (
                      <div style={{ alignSelf: 'center' }}>
                        {column.isSorted && !column.isSortedDesc ? (
                          <Icon.SortCaretup
                            color='blue'
                            className='sort-icon'
                          />
                        ) : (
                          <Icon.SortCaretup className='sort-icon' />
                        )}
                        {column.isSorted && column.isSortedDesc ? (
                          <Icon.SortCaretdown
                            color='blue'
                            className='sort-icon'
                          />
                        ) : (
                          <Icon.SortCaretdown className='sort-icon' />
                        )}
                      </div>
                    )}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row: any, i: number) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()} className='post-table-tr'>
                {props.withIndex && (
                  <td
                    className={classNames(
                      'post-table-td',
                      isSegmented && 'sticky-1 post-table-td-sticky'
                    )}
                  >
                    <Text.Paragraph size={14} color='black'>
                      {i + 1}
                    </Text.Paragraph>
                  </td>
                )}
                {row.cells.map((cell: any) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      className={classNames(
                        'post-table-td',
                        cell.column.columnClass ? cell.column.columnClass : ''
                      )}
                      style={{ textAlign: cell.column.align }}
                    >
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
        {props.withFooter && (
          <tfoot>
            {footerGroups.map((group: any) => (
              <tr {...group.getFooterGroupProps()} className='post-table-tr'>
                <td
                  className='post-table-td sticky-1 post-table-td-sticky'
                  colSpan={props.segmentedColspan}
                  style={{ zIndex: 9 }}
                >
                  <Text.Heading h={6} align='left'>
                    Total
                  </Text.Heading>
                </td>
                {group.headers.map((column: any) => (
                  <td
                    {...column.getFooterProps()}
                    colSpan={column.footerColspan ? column.footerColspan : 1}
                    className={classNames(
                      'post-table-td',
                      column.columnClass ? column.columnClass : ''
                    )}
                  >
                    {column.render('Footer')}
                  </td>
                ))}
              </tr>
            ))}
          </tfoot>
        )}
      </table>
    </div>
  )

  return isSegmented ? renderSegmentedTable() : renderTable()
}

export default ReactTable
