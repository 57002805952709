import { SchemaValidation } from 'utilities/validationCheck'
import { i18n } from '@lingui/core'
import { t } from '@lingui/macro'

export interface DiscountFields {
  name: string
  percentage: number
  maxAmount: number
}

export const DiscountFormSchema =
  SchemaValidation.object<DiscountFields>().shape({
    name: SchemaValidation.string()
      .min(3, i18n._(t`Nama Diskon tidak boleh kurang dari 3 karakter`))
      .required(i18n._(t`Nama Diskon tidak boleh kosong`)),
    percentage: SchemaValidation.number()
      .required(i18n._(t`Nilai Diskon tidak boleh kosong`))
      .min(0.01, i18n._(t`Nilai Diskon harus lebih dari 0`)),
  })
