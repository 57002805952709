import { BaseApi } from 'api/base.api'
import { ICustomerApi } from 'domains/management/customer/contracts/api'
import {
  Customer,
  CustomerRequest,
  CustomerQueryParams,
  MassCustomer,
} from 'domains/management/customer/models'

export default class CustomerApi extends BaseApi implements ICustomerApi {
  constructor() {
    super('/v2/customers')
  }

  public async getCustomers(
    queryParams: CustomerQueryParams
  ): Promise<Customer[]> {
    return await this.makeForCamelCaseForList<Customer>('GET', '', queryParams)
  }

  public async createCustomer(requestBody: CustomerRequest): Promise<Customer> {
    return await this.makeForCamelCase('POST', '', requestBody)
  }

  public async updateCustomer(
    requestBody: CustomerRequest,
    customerId: string
  ): Promise<Customer> {
    return await this.makeForCamelCase('POST', `/${customerId}`, requestBody)
  }

  public async deleteCustomer(customerId: string): Promise<Customer> {
    return await this.makeForCamelCase('DELETE', `/${customerId}`)
  }

  public async bulkImportCustomers(customers: MassCustomer[]): Promise<void> {
    return await this.make('POST', '/bulk-import', { customers })
  }
}
