const settingsRoute = {
  MAIN_SETTINGS: '/pengaturan',
  ACCOUNT_SETTINGS: '/pengaturan/akun',
  ACCOUNT_BUSINESS_SETTINGS: '/pengaturan/akun/akun-bisnis',
  SUBSCRIPTION_HISTORY_SETTINGS: '/pengaturan/riwayat-berlangganan',
  INFORMATION_SETTINGS: '/pengaturan/informasi',
  BUSINESS_PROFILE: '/pengaturan/profil-bisnis',
  PASSWORD_SETTINGS: '/pengaturan/atur-password',
  RESET_DATA_SETTINGS: '/pengaturan/reset-data',
  RESET_DATA_MANAGEMENT_SETTINGS: '/pengaturan/reset-data/management',
  RESET_DATA_TRANSACTIONS: '/pengaturan/reset-data/transaksi',
}

export default settingsRoute
