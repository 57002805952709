import React from 'react'

import { featureCheck, isAnyOfTheseFeaturesEnabled } from 'config'
import ROUTE from 'constants/routes'
import { RouteItemV2 } from 'route'
import { Icon } from 'views/components'
import { firebaseFunc } from 'utilities/firebase'

const getAuthorizedRoutes = (): RouteItemV2[] => {
  const routes: RouteItemV2[] = [
    {
      path: ROUTE.MAINPAGE,
      exact: true,
      component: 'Home',
      isEnabled: true,
      sidebarMenu: {
        label: 'Beranda',
        icon: <Icon.Home />,
      },
    },
    {
      path: ROUTE.KELOLA_PENGELUARAN,
      exact: true,
      component: 'Reporting/Expense',
      isEnabled: true,
      sidebarMenu: {
        label: 'Kelola Pengeluaran',
        icon: <Icon.Expense />,
      },
    },
    {
      sidebarMenu: {
        label: 'Laporan',
        icon: <Icon.Report />,
      },
      path: ROUTE.MAIN_REPORTING,
      exact: true,
      isEnabled: isAnyOfTheseFeaturesEnabled(featureCheck.reporting.any),
      component: 'Reporting/Summary',
      children: [
        {
          sidebarMenu: {
            label: 'Ringkasan',
          },
          exact: true,
          path: ROUTE.SUMMARY_REPORTING,
          isEnabled: isAnyOfTheseFeaturesEnabled(
            featureCheck.reporting.summary
          ),
          component: 'Reporting/Summary',
        },
        {
          sidebarMenu: {
            label: 'Penjualan Produk',
          },
          exact: true,
          path: ROUTE.PRODUCT_SALES_REPORTING,
          isEnabled: isAnyOfTheseFeaturesEnabled(
            featureCheck.reporting.productSale
          ),
          component: 'Reporting/ProductSale',
        },
        {
          sidebarMenu: {
            label: 'Pembatalan',
          },
          exact: true,
          path: ROUTE.CANCELLATION_REPORTING,
          isEnabled: isAnyOfTheseFeaturesEnabled(
            featureCheck.reporting.cancellation
          ),
          component: 'Reporting/Cancellation',
        },
        {
          sidebarMenu: {
            label: 'Diskon',
          },

          exact: true,
          path: ROUTE.DISCOUNT_REPORTING,
          isEnabled: isAnyOfTheseFeaturesEnabled(
            featureCheck.reporting.discount
          ),
          component: 'Reporting/Discount',
        },
        {
          sidebarMenu: {
            label: 'Shift & Kas',
          },
          exact: true,
          path: ROUTE.SHIFT_REPORTING,
          isEnabled: isAnyOfTheseFeaturesEnabled(featureCheck.reporting.shift),
          component: 'Reporting/Shift',
        },
        {
          sidebarMenu: {
            label: 'Tipe Pesanan',
          },
          exact: true,
          path: ROUTE.ORDER_TYPE_REPORTING,
          isEnabled: isAnyOfTheseFeaturesEnabled(
            featureCheck.reporting.serviceType
          ),
          component: 'Reporting/ServiceType',
        },
        {
          sidebarMenu: {
            label: 'Pembayaran',
          },
          exact: true,
          path: ROUTE.PAYMENT_METHOD_REPORTING,
          isEnabled: isAnyOfTheseFeaturesEnabled(
            featureCheck.reporting.paymentMethod
          ),
          component: 'Reporting/PaymentMethod',
        },
        {
          sidebarMenu: {
            label: 'Pencairan Dana',
          },
          exact: true,
          path: ROUTE.DISBURSEMENT_REPORTING,
          isEnabled: isAnyOfTheseFeaturesEnabled(
            featureCheck.reporting.disbursement
          ),
          component: 'Reporting/Disbursement',
        },
        {
          sidebarMenu: {
            label: 'Karyawan',
          },
          exact: true,
          path: ROUTE.EMPLOYEE_REPORTING,
          isEnabled: isAnyOfTheseFeaturesEnabled(
            featureCheck.reporting.employee
          ),
          component: 'Reporting/Employee',
        },
      ],
    },
    {
      sidebarMenu: {
        label: isAnyOfTheseFeaturesEnabled(featureCheck.home.sidebarV2)
          ? 'Kelola Produk'
          : 'Produk',
        icon: <Icon.ManagementForProduct />,
      },
      component: 'Management/Product',
      exact: true,
      path: ROUTE.MAIN_MANAGEMENT,
      isEnabled: isAnyOfTheseFeaturesEnabled(featureCheck.management.any),
      parentKey: 'product',
      children: [
        {
          sidebarMenu: {
            label: 'Daftar Produk',
          },
          exact: true,
          path: ROUTE.MANAGEMENT_PRODUCT_LIST,
          component: 'Management/Product',
          isEnabled: isAnyOfTheseFeaturesEnabled(
            featureCheck.management.product
          ),
        },
        {
          sidebarMenu: {
            label: 'Daftar Produk',
          },
          exact: true,
          path: ROUTE.MANAGEMENT_PRODUCT_LIST,
          component: 'Management/ProductV2',
          isEnabled: isAnyOfTheseFeaturesEnabled(
            featureCheck.management.productVariant
          ),
        },
        {
          sidebarMenu: {
            label: 'Kategori',
          },
          exact: true,
          path: ROUTE.MANAGEMENT_PRODUCT_CATEGORY,
          component: 'Management/Category/List',
          isEnabled: isAnyOfTheseFeaturesEnabled(
            featureCheck.management.category
          ),
        },
      ],
    },
    {
      sidebarMenu: {
        label: 'Inventaris',
        icon: <Icon.Inventory />,
      },
      exact: true,
      isEnabled: isAnyOfTheseFeaturesEnabled(featureCheck.management.inventory),
      parentKey: 'product',
      path: ROUTE.INVENTORY_MAIN,
      component: 'Inventory/Summary',
      children: [
        {
          sidebarMenu: {
            label: 'Ringkasan',
          },
          exact: true,
          path: ROUTE.INVENTORY_REPORT,
          component: 'Inventory/Summary',
          isEnabled: true,
        },
        {
          sidebarMenu: {
            label: 'Penyesuaian Stok',
          },
          exact: true,
          path: ROUTE.INVENTORY_STOCK_ADJUSTMENT,
          component: 'Inventory/StockAdjustment',
          isEnabled: isAnyOfTheseFeaturesEnabled(
            featureCheck.management.bulkStockAdjustment
          ),
        },
      ],
    },
    {
      sidebarMenu: {
        label: 'Diskon',
        icon: <Icon.Coupon />,
      },
      exact: true,
      path: ROUTE.MANAGEMENT_PAYMENT_DISCOUNT,
      component: 'Management/Discount',
      isEnabled: isAnyOfTheseFeaturesEnabled(featureCheck.management.discount),
      parentKey: 'product',
    },
    {
      sidebarMenu: {
        label: 'Tipe Pesanan',
        icon: <Icon.List />,
      },
      exact: true,
      path: ROUTE.MANAGEMENT_PRODUCT_ORDER_TYPE,
      component: 'Management/ServiceType/List',
      isEnabled: isAnyOfTheseFeaturesEnabled(
        featureCheck.management.serviceType
      ),
      parentKey: 'product',
    },
    {
      sidebarMenu: {
        label: 'Bahan Baku',
        icon: <Icon.Ingredient />,
      },
      exact: true,
      path: ROUTE.INGREDIENT_MAIN,
      component: 'Ingredients/Ingredients',
      isEnabled: isAnyOfTheseFeaturesEnabled(featureCheck.ingredients.any),
      parentKey: 'product',
      category: 'fnb',
      onClick: () => firebaseFunc.logEvent('ingredient_menu_open'),
      children: [
        {
          sidebarMenu: {
            label: 'Ringkasan Pemakaian',
          },
          exact: true,
          path: ROUTE.SUMMARY,
          component: 'Ingredients/Summary',
          isEnabled: isAnyOfTheseFeaturesEnabled(
            featureCheck.ingredients.ingredientSummaryReport
          ),
          category: 'fnb',
          onClick: () => firebaseFunc.logEvent('ingredient_summary_click'),
        },
        {
          sidebarMenu: {
            label: 'Daftar Bahan Baku',
          },
          exact: true,
          path: ROUTE.INGREDIENT_LIST,
          component: 'Ingredients/Ingredients',
          isEnabled: isAnyOfTheseFeaturesEnabled(
            featureCheck.ingredients.ingredient
          ),
          category: 'fnb',
          onClick: () => firebaseFunc.logEvent('ingredient_list_click'),
        },
        {
          sidebarMenu: {
            label: 'Kategori Bahan Baku',
          },
          exact: true,
          path: ROUTE.INGREDIENT_CATEGORY,
          component: 'Ingredients/IngredientCategory',
          isEnabled: isAnyOfTheseFeaturesEnabled(
            featureCheck.ingredients.ingredientCategory
          ),
          category: 'fnb',
          onClick: () => firebaseFunc.logEvent('ingredient_category_click'),
        },
        {
          sidebarMenu: {
            label: 'Resep',
          },
          exact: true,
          path: ROUTE.RECIPES,
          component: 'Ingredients/Recipes',
          isEnabled: isAnyOfTheseFeaturesEnabled(
            featureCheck.ingredients.recipe
          ),
          category: 'fnb',
          onClick: () => firebaseFunc.logEvent('ingredient_recipe_click'),
        },
      ],
    },
    {
      sidebarMenu: {
        label: 'Pelanggan',
        icon: <Icon.Contact />,
      },
      exact: true,
      path: ROUTE.MANAGEMENT_CUSTOMER,
      component: 'Management/Customer',
      isEnabled: isAnyOfTheseFeaturesEnabled(
        featureCheck.management.outerCustomer
      ),
    },
    {
      sidebarMenu: {
        label: 'Pembayaran',
        icon: <Icon.Payment />,
      },
      exact: true,
      path: ROUTE.MANAGEMENT_PAYMENT,
      component: 'Management/MenuPayment',
      isEnabled: isAnyOfTheseFeaturesEnabled(
        featureCheck.management.tax,
        featureCheck.management.paymentMethod
      ),
      parentKey: 'manageOutlet',
      children: [
        {
          sidebarMenu: {
            label: 'Metode Pembayaran',
          },
          exact: true,
          path: ROUTE.MANAGEMENT_PAYMENT_METHOD,
          component: 'Management/PaymentMethod',
          isEnabled: isAnyOfTheseFeaturesEnabled(
            featureCheck.management.paymentMethod
          ),
        },
        {
          sidebarMenu: {
            label: 'Pajak',
          },

          exact: true,
          path: ROUTE.MANAGEMENT_PAYMENT_TAX_SERVICE_CHARGE,
          component: 'Management/TaxServiceCharge',
          isEnabled: isAnyOfTheseFeaturesEnabled(featureCheck.management.tax),
        },
      ],
    },
    {
      sidebarMenu: {
        label: 'Outlet',
        icon: <Icon.Outlet />,
      },
      exact: true,
      path: ROUTE.MANAGEMENT_OUTLET,
      component: 'Management/MenuOutlet',
      isEnabled: isAnyOfTheseFeaturesEnabled(
        featureCheck.management.employee,
        featureCheck.management.device,
        featureCheck.management.shift,
        featureCheck.management.multiOutlet,
        featureCheck.management.customer,
        featureCheck.management.receipt,
        featureCheck.management.attendance
      ),
      parentKey: 'manageOutlet',
      children: [
        {
          sidebarMenu: {
            label: 'Karyawan',
          },
          exact: true,
          path: ROUTE.MANAGEMENT_OUTLET_EMPLOYEE,
          component: 'Management/Employee',
          isEnabled: isAnyOfTheseFeaturesEnabled(
            featureCheck.management.employee
          ),
        },
        {
          sidebarMenu: {
            label: 'Kode Akses Kasir',
          },
          exact: true,
          path: ROUTE.MANAGEMENT_OUTLET_DEVICE,
          component: 'Management/Device',
          isEnabled: isAnyOfTheseFeaturesEnabled(
            featureCheck.management.device
          ),
        },
        {
          sidebarMenu: {
            label: 'Shift',
          },
          exact: true,
          path: ROUTE.MANAGEMENT_OUTLET_SHIFT,
          component: 'Management/Shift',
          isEnabled: isAnyOfTheseFeaturesEnabled(featureCheck.management.shift),
        },
        {
          sidebarMenu: {
            label: 'Cabang (Outlet)',
          },
          exact: true,
          path: ROUTE.MANAGEMENT_OUTLET_MULTIOUTLET,
          component: 'Management/MultiOutlet',
          isEnabled: isAnyOfTheseFeaturesEnabled(
            featureCheck.management.multiOutlet
          ),
        },
        {
          sidebarMenu: {
            label: 'Pelanggan',
          },
          exact: true,
          path: ROUTE.MANAGEMENT_CUSTOMER,
          component: 'Management/Customer',
          isEnabled:
            isAnyOfTheseFeaturesEnabled(featureCheck.management.customer) &&
            isAnyOfTheseFeaturesEnabled(featureCheck.home.sidebarV2),
          parentKey: 'manageOutlet',
        },
        {
          sidebarMenu: {
            label: 'Struk',
          },
          exact: true,
          path: ROUTE.MANAGEMENT_OUTLET_RECEIPT,
          component: 'Management/Receipt',
          isEnabled: isAnyOfTheseFeaturesEnabled(
            featureCheck.management.receipt
          ),
        },
        {
          sidebarMenu: {
            label: 'Atur Peran Staff',
          },
          path: ROUTE.MANAGEMENT_ROLE_MANAGEMENT,
          component: 'Management/RoleManagement',
          isEnabled: isAnyOfTheseFeaturesEnabled(
            featureCheck.management.roleManagement
          ),
        },
        {
          sidebarMenu: {
            label: 'Kehadiran',
          },
          exact: true,
          path: ROUTE.MANAGEMENT_OUTLET_ATTENDANCE,
          component: 'Management/Attendance',
          isEnabled: isAnyOfTheseFeaturesEnabled(
            featureCheck.management.attendance
          ),
        },
      ],
    },
    {
      sidebarMenu: {
        label: 'Pelanggan',
        icon: <Icon.Contact />,
      },
      exact: true,
      path: ROUTE.MANAGEMENT_CUSTOMER,
      component: 'Management/Customer',
      isEnabled:
        isAnyOfTheseFeaturesEnabled(featureCheck.management.customer) &&
        isAnyOfTheseFeaturesEnabled(featureCheck.home.sidebarV3),
      parentKey: 'manageOutlet',
    },
    {
      sidebarMenu: {
        label: 'Toko Online',
        icon: isAnyOfTheseFeaturesEnabled(featureCheck.home.sidebarV2) ? (
          <Icon.QRPayment />
        ) : (
          <Icon.ShoppingBag />
        ),
      },
      component: isAnyOfTheseFeaturesEnabled(
        featureCheck.contactless.onlineDelivery
      )
        ? 'ContactlessV2'
        : 'Contactless',
      path: ROUTE.MAIN_CONTACTLESS,
      isEnabled: isAnyOfTheseFeaturesEnabled(
        featureCheck.contactless.contactlessDining
      ),
      parentKey: 'manageOutlet',
      isNew: isAnyOfTheseFeaturesEnabled(featureCheck.home.sidebarV2),
    },
    {
      component: 'Settings',
      exact: true,
      path: ROUTE.MAIN_SETTINGS,
      isEnabled: isAnyOfTheseFeaturesEnabled(featureCheck.setting.any),
      children: [
        {
          sidebarMenu: {
            label: 'Akun',
          },
          exact: true,
          path: ROUTE.ACCOUNT_SETTINGS,
          component: 'Settings',
          isEnabled: isAnyOfTheseFeaturesEnabled(featureCheck.setting.account),
        },
        {
          sidebarMenu: {
            label: 'Informasi Bisnis',
          },
          exact: true,
          path: ROUTE.BUSINESS_PROFILE,
          component: 'Settings/BusinessProfile',
          isEnabled: isAnyOfTheseFeaturesEnabled(
            featureCheck.setting.businessProfile
          ),
        },
        {
          sidebarMenu: {
            label: 'Riwayat Berlangganan',
          },
          exact: true,
          path: ROUTE.SUBSCRIPTION_HISTORY_SETTINGS,
          component: 'Settings/SubscriptionHistory',
          isEnabled: isAnyOfTheseFeaturesEnabled(
            featureCheck.setting.subscriptionHistory
          ),
        },
      ],
    },
    {
      exact: true,
      path: ROUTE.INFORMATION_SETTINGS,
      component: 'Settings/Information',
      isEnabled: isAnyOfTheseFeaturesEnabled(
        featureCheck.setting.generalInformation
      ),
    },
    {
      exact: true,
      path: ROUTE.RESET_DATA_MANAGEMENT_SETTINGS,
      component: 'Settings/ResetData/ResetDataManagement',
      isEnabled: isAnyOfTheseFeaturesEnabled(featureCheck.setting.resetData),
    },
    {
      exact: true,
      path: ROUTE.RESET_DATA_TRANSACTIONS,
      component: 'Settings/ResetData/ResetDataTransaction',
      isEnabled: isAnyOfTheseFeaturesEnabled(featureCheck.setting.resetData),
    },
    {
      exact: true,
      path: ROUTE.MAIN_HELPER,
      component: 'HelpDesk',
      isEnabled: isAnyOfTheseFeaturesEnabled(
        featureCheck.miscellaneous.helpdesk
      ),
    },
    {
      exact: true,
      path: ROUTE.FLEXPAY_DETAIL,
      component: 'FlexPayDetail',
      isEnabled: true,
    },
  ]

  return routes
    .filter(route => route.isEnabled)
    .map(route =>
      route.children
        ? {
            ...route,
            children: route.children.filter(child => child.isEnabled),
          }
        : route
    )
}

export default getAuthorizedRoutes
