import { BaseReportingService } from '.'
import { DiscountReport, DiscountReportQueryString } from '../models'
import { toUTCISOString } from 'utilities/converter/date'

export class DiscountReportingService
  extends BaseReportingService
  implements IDiscountReportingService
{
  public getDiscountListReporting = async (
    filter?: DiscountReportQueryString
  ): Promise<DiscountReport[]> => {
    const queryParams = {
      outletId: filter.selectedOutletId || '',
      fromDate: filter.dateRange ? toUTCISOString(filter.dateRange.from) : '',
      toDate: filter.dateRange ? toUTCISOString(filter.dateRange.to) : '',
    }
    const discountReports = await this.api.reporting.getDiscountReports(
      queryParams
    )
    this.reportingStorage.setDiscountReporting(discountReports)
    return discountReports
  }
}

export interface IDiscountReportingService {
  getDiscountListReporting: (
    queryParams?: DiscountReportQueryString
  ) => Promise<DiscountReport[]>
}
