import React, { useState } from 'react'
import InputBase from '../base'
import { BaseProps } from 'views/components/props'
import Form, { RenderingFieldContext } from '../../Form'
import { featureCheck, isAnyOfTheseFeaturesEnabled } from 'config'

interface Props extends BaseProps {
  label?: string
  mapSubItem?: (x: any) => any
  prefixName?: string
  placeholder?: string
  name?: string
  maxLength?: number
  isDisabled?: boolean
  prefix?: React.ReactNode
  suffix?: React.ReactNode
  height?: number
  width?: number
  onChange?: (value: string) => any
  showCounter?: boolean
  style?: React.CSSProperties
  noMargin?: boolean
}

const InputTextArea = (props: Props) => {
  const [word, setWord] = useState('')

  const handleOnChange = (value: string) => {
    if (props.onChange) {
      props.onChange(value)
    }
  }

  const isReceiptV3 = isAnyOfTheseFeaturesEnabled(
    featureCheck.management.receiptV3
  )

  const counterStyle = isReceiptV3
    ? ({
        position: 'absolute',
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        right: '10px',
        bottom: '-23px',
        fontSize: '12px',
      } as React.CSSProperties)
    : ({
        position: 'absolute',
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        top: '70px',
        right: '10px',
      } as React.CSSProperties)

  return (
    <Form.Field
      name={props.name}
      mapSubItem={props.mapSubItem}
      prefixName={props.prefixName}
      render={({
        field,
        value,
        onChange,
        onBlur,
      }: RenderingFieldContext<any>) => (
        <InputBase
          isDisabled={props.isDisabled}
          label={props.label}
          error={field.isDirty && field.error}
          suffix={props.suffix}
          prefix={props.prefix}
          noMargin={props.noMargin}
        >
          <>
            <textarea
              rows={props.height}
              cols={props.width}
              name={props.name}
              disabled={props.isDisabled}
              placeholder={props.placeholder}
              maxLength={props.maxLength}
              value={value}
              onBlur={onBlur}
              onChange={evt => {
                onChange(evt.target.value)
                handleOnChange(evt.target.value)
                setWord(evt.target.value)
              }}
              style={{ resize: 'none', ...props.style }}
            />
            {props.showCounter && props.maxLength && (
              <div style={{ ...counterStyle }}>
                {word
                  ? word.length + ' / ' + props.maxLength
                  : value && value.length + ' / ' + props.maxLength}
              </div>
            )}
          </>
        </InputBase>
      )}
    />
  )
}

export default InputTextArea
