import {
  MassProduct,
  ProductModelForMassUpload,
  ProductV2Model,
  ProductListV2QueryParams,
  MassUpdateRequest,
  MassUploadRequest,
  MassProductV2,
  ProductModelForMassUploadV2,
} from '../models'
import { toDict, toList } from 'utilities/converter/list'
import { IProductStorage } from '../contracts/storage'
import { ICommonStorage } from 'domains/commons/contracts/storage'
import { BaseService } from 'domains/commons/services'
import { Api } from 'domains/contracts/api'
import { History } from 'history'

import { convertExcelBinaryTo2DArray } from 'utilities/csv/fileTo2DArray'
import { ExtractedFileData } from 'utilities/file/upload'
import UUID from 'uuidjs'
import { SET_PRODUCTS_V2 } from 'storages/management/products/actions'

export class ProductServiceV2 extends BaseService {
  productStorage: IProductStorage
  history: History

  constructor(
    commonStorage: ICommonStorage,
    api: Api,
    productStorage: IProductStorage,
    history: History
  ) {
    super(commonStorage, api)
    this.productStorage = productStorage
    this.history = history
  }

  public checkMassProductCategory = (
    name: string,
    massProducts: MassProduct[]
  ) => {
    const { categories } = this.getState().management.category
    const categoryList = toList(categories)
    const category = categoryList.find(
      c => c.name.toLowerCase() === name.toLowerCase()
    )
    if (category) {
      return category.id
    } else {
      const newCategories = massProducts.map(p => ({
        id: p.categoryId,
        name: p.categoryName,
      }))
      return newCategories.find(p => p.name === name)?.id || UUID.generate()
    }
  }

  public checkMassProductV2Category = (
    name: string,
    massProducts: MassProductV2[]
  ) => {
    if (!name) return ''
    const { categories } = this.getState().management.category
    const categoryList = toList(categories)
    const category = categoryList.find(
      c => c.name.toLowerCase() === name.toLowerCase()
    )
    if (category) {
      return category.id
    } else {
      const newCategories = massProducts.map(p => ({
        id: p.categoryId,
        name: p.categoryName,
      }))
      return newCategories.find(p => p.name === name)?.id || UUID.generate()
    }
  }

  public extractMassProduct = async (
    file: ExtractedFileData
  ): Promise<ProductModelForMassUpload> => {
    const jsonData = convertExcelBinaryTo2DArray(file.binary)
    const massProducts: MassProduct[] = []
    jsonData.list.forEach((x: any) => {
      massProducts.push({
        productName: x['Nama Produk'] || '',
        productId: x['Id Produk'] || '',
        categoryName: x['Kategori'] !== undefined ? x['Kategori'] : undefined,
        categoryId: x['Kategori']
          ? this.checkMassProductCategory(x['Kategori'], massProducts)
          : undefined,
        price: parseInt(x['Harga Jual']) > 0 ? x['Harga Jual'] : undefined,
        unitCost: parseInt(x['Harga Modal']) > 0 ? x['Harga Modal'] : undefined,
        sku: String(x['SKU']) !== 'undefined' ? String(x['SKU']) : undefined,
        barcode:
          String(x['Barcode']) !== 'undefined'
            ? String(x['Barcode'])
            : undefined,
        trackStock: x['Kelola Stok (Iya / Tidak)'],
        stockAdjustment:
          parseInt(x['Stok Masuk']) > 0
            ? {
                type: 'addition',
                quantity: x['Stok Masuk'],
                note: '',
              }
            : parseInt(x['Stok Saat Ini']) > 0
            ? {
                type: 'recalculation',
                quantity: x['Stok Saat Ini'],
                note: '',
              }
            : {
                type: 'addition',
                quantity: 0,
                note: '',
              },
        minStock:
          parseInt(x['Stok Minimum']) > 0 ? x['Stok Minimum'] : undefined,
        description: x['Deskripsi'] || '',
        weight: parseInt(x['Berat (gram)']) > 0 ? x['Berat (gram)'] : undefined,
        dimension: {
          length:
            parseInt(x['Panjang (cm)']) > 0 ? x['Panjang (cm)'] : undefined,
          width: parseInt(x['Lebar (cm)']) > 0 ? x['Lebar (cm)'] : undefined,
          height: parseInt(x['Tinggi (cm)']) > 0 ? x['Tinggi (cm)'] : undefined,
        },
      } as MassProduct)
    })

    return {
      massProducts,
      fileInfo: file,
    }
  }

  public extractMassProductVariant = async (
    file: ExtractedFileData,
    type?: string
  ): Promise<ProductModelForMassUploadV2> => {
    const jsonData = convertExcelBinaryTo2DArray(file.binary)
    const massProducts: MassProductV2[] = []
    if (type === 'edit') {
      jsonData.list = jsonData.list.slice(1)
    } else {
      jsonData.list = jsonData.list.slice(4)
    }
    jsonData.list.forEach((x: any) => {
      massProducts.push({
        productName: x['Nama Produk*']
          ? String(x['Nama Produk*']).replace(/\s+/g, ' ')
          : '',
        variantName: x['Nama Varian']
          ? String(x['Nama Varian']).replace(/\s+/g, ' ')
          : '',
        productId: x['ID Produk'] || '',
        crossedVariantOptionId: x['ID Crossed Variant Option'] || '',
        variantOptionId: x['ID Variant Option'] || '',
        categoryName:
          x['Kategori'] !== undefined
            ? String(x['Kategori']).replace(/\s+/g, ' ')
            : undefined,
        categoryId: x['Kategori']
          ? this.checkMassProductV2Category(
              String(x['Kategori']).replace(/\s+/g, ' '),
              massProducts
            )
          : undefined,
        price: x['Harga Jual*'],
        unitCost: x['Harga Modal'],
        sku:
          String(x['SKU']) !== 'undefined'
            ? String(x['SKU'])?.replace(/\s+/g, ' ')
            : undefined,
        barcode:
          String(x['Barcode']) !== 'undefined'
            ? String(x['Barcode'])?.replace(/\s+/g, ' ')
            : undefined,
        trackStock: x['Kelola Stok (Ya/Tidak)'] || 'Tidak',
        stockAdjustment:
          type !== 'edit'
            ? {
                type: 'addition',
                quantity: x['Stok Saat Ini'],
                note: '',
              }
            : {
                type: 'recalculation',
                quantity: x['Stok Saat Ini'],
                note: '',
              },
        minStock: x['Stok Minimum'],
        description: x['Deskripsi'] || '',
        weight: parseInt(x['Berat (gram)']) > 0 ? x['Berat (gram)'] : undefined,
        dimension: {
          length:
            parseInt(x['Panjang (cm)']) > 0 ? x['Panjang (cm)'] : undefined,
          width: parseInt(x['Lebar (cm)']) > 0 ? x['Lebar (cm)'] : undefined,
          height: parseInt(x['Tinggi (cm)']) > 0 ? x['Tinggi (cm)'] : undefined,
        },
      } as MassProductV2)
    })

    return {
      massProducts,
      fileInfo: file,
    }
  }

  public uploadMass = async (request: MassUploadRequest) => {
    try {
      this.setLoading('UPLOAD_MASS', true)
      await this.api.management.product.uploadMass(request)
      this.setLoading('UPLOAD_MASS', false)
    } catch (error) {
      throw error
    }
  }

  public updateMass = async (request: MassUpdateRequest) => {
    try {
      this.setLoading('UPDATE_MASS', true)
      await this.api.management.productV2.bulkUpdateProductSingle(request)
      this.setLoading('UPDATE_MASS', false)
    } catch (error) {
      throw error
    }
  }

  private getLastProductId = (
    products: ProductV2Model[]
  ): string | undefined => {
    return products.length > 0 ? products[products.length - 1].id : undefined
  }

  public getProducts = async (
    queryParams: ProductListV2QueryParams = {}
  ): Promise<ProductV2Model[]> => {
    this.setLoading(SET_PRODUCTS_V2, true)
    const products = await this.api.management.productV2.getProducts(
      queryParams
    )
    this.productStorage.setProductsV2(
      toDict(products, (p: ProductV2Model) => p.id)
    )
    this.setLoading(SET_PRODUCTS_V2, false)
    const lastProductId = this.getLastProductId(products)
    this.productStorage.setProductListRequestV2({
      ...queryParams,
      starting_after: lastProductId || queryParams.starting_after,
    } as ProductListV2QueryParams)
    this.productStorage.setProductListReachBottomV2(
      queryParams.limit ? products.length < queryParams.limit : true
    )

    return products
  }

  public loadMore = async (
    queryParams: ProductListV2QueryParams = {}
  ): Promise<ProductV2Model[]> => {
    const productListManager =
      this.getState().management.product.productV2ListManager
    if (productListManager.hasReachedBottom) {
      return Promise.resolve([])
    }
    if (productListManager.isLoadingMoreProduct) {
      return Promise.resolve([])
    }
    this.productStorage.setLoadingMoreProductV2(true)
    queryParams = {
      ...queryParams,
      ...productListManager.request,
    }
    const products = await this.api.management.productV2.getProducts(
      queryParams
    )
    this.productStorage.pushProductsV2(
      toDict(products, (p: ProductV2Model) => p.id)
    )
    const lastProductId = this.getLastProductId(products)
    this.productStorage.setLoadingMoreProductV2(false)
    this.productStorage.setProductListRequestV2({
      ...queryParams,
      starting_after: lastProductId || queryParams.starting_after,
    } as ProductListV2QueryParams)
    this.productStorage.setProductListReachBottomV2(
      queryParams.limit ? products.length < queryParams.limit : true
    )

    return products
  }
}
