import { Reducer, StateActions } from 'utilities/redux/reducer'
import { SubscriptionState } from 'domains/subscriptions/states'
import {
  SubscriptionAlert,
  SubscriptionPackagePricing,
  SubscriptionPackage,
  SubscriptionMethod,
  Subscription,
} from 'domains/subscriptions/model'
import { toDict } from 'utilities/converter/list'
import { Dict } from 'utilities/type'
import {
  SET_SUBSCRIPTION_ALERT,
  SET_AVAILABLE_PACKAGE,
  SET_SUBSCRIPTION_PACKAGE,
  SET_SUBSCRIPTION_METHOD,
  SET_SUBSCRIPTIONS,
  SET_SUBSCRIPTION,
  REMOVE_SUBSCRIPTION,
} from './actions'

export class SubscriptionReducer extends Reducer<SubscriptionState> {
  constructor() {
    super({
      subscriptionAlert: {} as SubscriptionAlert,
      availablePackages: {} as Dict<SubscriptionPackagePricing>,
      subscriptionPackage: [],
      subscriptionMethod: {} as Dict<SubscriptionMethod>,
      subscriptions: {},
    })
  }

  public setSubscriptionAlert(
    state: SubscriptionState,
    alert: SubscriptionAlert
  ): SubscriptionState {
    return {
      ...state,
      subscriptionAlert: alert,
    }
  }

  public setAvailablePackage(
    state: SubscriptionState,
    availablePackages: SubscriptionPackagePricing[]
  ): SubscriptionState {
    return {
      ...state,
      availablePackages: toDict(availablePackages, x => x.id),
    }
  }

  public setSubscriptionPackage(
    state: SubscriptionState,
    subscriptionPackage: SubscriptionPackage[]
  ): SubscriptionState {
    return {
      ...state,
      subscriptionPackage: subscriptionPackage,
    }
  }

  public setSubscriptionMethod(
    state: SubscriptionState,
    subscriptionMethod: SubscriptionMethod[]
  ): SubscriptionState {
    return {
      ...state,
      subscriptionMethod: toDict(subscriptionMethod, x => x.id),
    }
  }

  public setSubscriptions(
    state: SubscriptionState,
    subscriptions: Subscription[]
  ): SubscriptionState {
    return {
      ...state,
      subscriptions: toDict(subscriptions, x => x.id),
    }
  }

  public setSubscription(
    state: SubscriptionState,
    subscriptions: Subscription
  ): SubscriptionState {
    return {
      ...state,
      subscriptions: {
        ...state.subscriptions,
        [subscriptions.id]: subscriptions,
      },
    }
  }

  public removeSubscription(
    state: SubscriptionState,
    id: string
  ): SubscriptionState {
    const subscriptions = { ...state.subscriptions }
    delete subscriptions[id]
    return {
      ...state,
      subscriptions,
    }
  }

  get actions(): StateActions<SubscriptionState> {
    return {
      [SET_SUBSCRIPTION_ALERT]: this.setSubscriptionAlert,
      [SET_AVAILABLE_PACKAGE]: this.setAvailablePackage,
      [SET_SUBSCRIPTION_PACKAGE]: this.setSubscriptionPackage,
      [SET_SUBSCRIPTION_METHOD]: this.setSubscriptionMethod,
      [SET_SUBSCRIPTIONS]: this.setSubscriptions,
      [SET_SUBSCRIPTION]: this.setSubscription,
      [REMOVE_SUBSCRIPTION]: this.removeSubscription,
    }
  }
}
