import {
  SET_CUSTOMER,
  SET_CUSTOMER_QUERY_PARAMS,
  SET_CUSTOMER_REACHED_BOTTOM,
  SET_LOADING_MORE_CUSTOMER,
  PUSH_CUSTOMER,
  UNSHIFT_CUSTOMER,
} from './actions'
import { StateActions, Reducer } from 'utilities/redux/reducer'
import { CustomerState } from 'domains/management/customer/states'
import { Dict } from 'utilities/type'
import {
  Customer,
  CustomerQueryParams,
} from 'domains/management/customer/models'

export class CustomerReducer extends Reducer<CustomerState> {
  constructor() {
    super({
      customers: {},
      customerListManager: {
        isLoadingMoreCustomer: false,
        request: {},
        hasReachedBottom: false,
      },
    })
  }

  setCustomer = (
    state: CustomerState,
    customers: Dict<Customer>
  ): CustomerState => {
    return {
      ...state,
      customers: customers,
    }
  }

  setCustomerListReachBottom = (
    state: CustomerState,
    hasReachedBottom: boolean
  ): CustomerState => {
    return {
      ...state,
      customerListManager: {
        ...state.customerListManager,
        hasReachedBottom,
      },
    }
  }

  setCustomerListRequest = (
    state: CustomerState,
    request: CustomerQueryParams
  ): CustomerState => {
    return {
      ...state,
      customerListManager: {
        ...state.customerListManager,
        request,
      },
    }
  }

  setLoadingMoreCustomer = (
    state: CustomerState,
    isLoadingMoreCustomer: boolean
  ): CustomerState => {
    return {
      ...state,
      customerListManager: {
        ...state.customerListManager,
        isLoadingMoreCustomer: isLoadingMoreCustomer,
      },
    }
  }

  pushCustomer = (
    state: CustomerState,
    customers: Dict<Customer>
  ): CustomerState => {
    return {
      ...state,
      customers: {
        ...state.customers,
        ...customers,
      },
    }
  }

  unshiftCustomer = (
    state: CustomerState,
    customers: Dict<Customer>
  ): CustomerState => {
    return {
      ...state,
      customers: {
        ...customers,
        ...state.customers,
      },
    }
  }

  get actions(): StateActions<CustomerState> {
    return {
      [SET_CUSTOMER]: this.setCustomer,
      [PUSH_CUSTOMER]: this.pushCustomer,
      [UNSHIFT_CUSTOMER]: this.unshiftCustomer,
      [SET_CUSTOMER_QUERY_PARAMS]: this.setCustomerListRequest,
      [SET_CUSTOMER_REACHED_BOTTOM]: this.setCustomerListReachBottom,
      [SET_LOADING_MORE_CUSTOMER]: this.setLoadingMoreCustomer,
    }
  }
}
