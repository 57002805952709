import React from 'react'
import { ModalProps, Modal } from 'views/components/Modal'
import {
  Panel,
  Sided,
  Text,
  Icon,
  Collapse,
  CollapsePanel,
  VSteps,
} from 'views/components'
import './styleVirtualstep.scss'
import { SubscriptionPaymentInformation } from 'domains/subscriptions/model'

interface Props extends ModalProps {
  payment: SubscriptionPaymentInformation
}

export const StepPaymentVirtualAccount: React.FC<Props> = props => {
  const { payment } = props

  const renderStepContentPayment = () =>
    payment.paymentInstructions &&
    payment.paymentInstructions.map((dt, index) => (
      <Collapse
        accordion
        expandIconPosition='right'
        expandIcon={() => <Icon.ChevronDown />}
      >
        <CollapsePanel header={dt.name} key={index}>
          <VSteps.Steps>
            {dt.instructions.map((step, idx) => (
              <VSteps.List step={idx + 1}>
                <Text.Paragraph
                  size={14}
                  dangerousContent={step}
                ></Text.Paragraph>
              </VSteps.List>
            ))}
          </VSteps.Steps>
        </CollapsePanel>
      </Collapse>
    ))

  return (
    <Modal
      dismiss={props.dismiss}
      type='blank'
      width='448px'
      canDismissOutside={false}
      style={{
        maxHeight: '100vh',
        overflowY: 'auto',
      }}
    >
      <Panel.Panel>
        <Panel.Header>
          <Sided.Sided>
            <Sided.Remaining>
              <Text.Heading h={5} noMargin>
                Cara Pembayaran via Virtual Account
              </Text.Heading>
            </Sided.Remaining>
            <Sided.Fixed>
              <Icon.Close24 onClick={() => props.close({})} color='black' />
            </Sided.Fixed>
          </Sided.Sided>
        </Panel.Header>
        <Panel.Content>{renderStepContentPayment()}</Panel.Content>
      </Panel.Panel>
    </Modal>
  )
}
