import { BaseService } from 'domains/commons/services'
import { IShiftSettingStorage } from '../contracts/storage'
import { ICommonStorage } from 'domains/commons/contracts/storage'
import { Api } from 'domains/contracts/api'
import { ShiftSetting } from '../models'

const SHIFT_SETTING = 'SHIFT_SETTING'

export class ShiftSettingService
  extends BaseService
  implements IShiftSettingService
{
  shiftStorage: IShiftSettingStorage

  constructor(
    commonStorage: ICommonStorage,
    api: Api,
    shiftStorage: IShiftSettingStorage
  ) {
    super(commonStorage, api)
    this.shiftStorage = shiftStorage
  }

  public loadSetting = async (): Promise<ShiftSetting> => {
    try {
      this.commonStorage.setLoading(SHIFT_SETTING, true)
      const setting = await this.api.management.shift.getShiftSetting()
      this.shiftStorage.setShiftSetting(setting)
      this.commonStorage.setLoading(SHIFT_SETTING, false)
      return setting
    } catch (error) {
      throw error
    }
  }

  public updateSetting = async (shiftSetting: ShiftSetting) => {
    try {
      this.commonStorage.setLoading(SHIFT_SETTING, true)
      const setting = await this.api.management.shift.setShiftSetting(
        shiftSetting
      )
      this.shiftStorage.setShiftSetting(setting)
      this.commonStorage.setLoading(SHIFT_SETTING, false)
    } catch (error) {
      throw error
    }
  }
}

export interface IShiftSettingService {
  loadSetting: () => Promise<ShiftSetting>
  updateSetting: (shiftSetting: ShiftSetting) => Promise<void>
}
