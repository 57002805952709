import { MerchantSetting, OutletSetting } from '../models'
import { BaseService } from 'domains/commons/services'
import { ICommonStorage } from 'domains/commons/contracts/storage'
import { Api } from 'domains/contracts/api'
import { IDiningStorage } from 'domains/contactless/contracts/storage'
import { toDict } from 'utilities/converter/list'

export class DiningService extends BaseService implements IDiningService {
  diningStorage: IDiningStorage

  constructor(
    commonStorage: ICommonStorage,
    api: Api,
    diningStorage: IDiningStorage
  ) {
    super(commonStorage, api)
    this.diningStorage = diningStorage
  }

  public getMerchantSetting = async () => {
    try {
      return await this.api.contactless.dining.getMerchantSetting()
    } catch (error) {
      throw error
    }
  }

  public createMerchantSetting = async (merchantRequest: MerchantSetting) => {
    try {
      return await this.api.contactless.dining.createMerchantSetting(
        merchantRequest
      )
    } catch (error) {
      throw error
    }
  }

  public updateMerchantSetting = async (
    merchantRequest: MerchantSetting,
    urlSlug: string
  ) => {
    try {
      return await this.api.contactless.dining.updateMerchantSetting(
        merchantRequest,
        urlSlug
      )
    } catch (error) {
      throw error
    }
  }

  public checkMerchantSettingSlug = async (urlSlug: string) => {
    try {
      await this.api.contactless.dining.checkMerchantSettingSlug(urlSlug)
    } catch (error) {
      throw error
    }
  }

  public getOutletSetting = async () => {
    try {
      const outletSettingList =
        await this.api.contactless.dining.getOutletSetting()
      this.diningStorage.setOutletSetting(
        toDict(outletSettingList || [], o => o.id)
      )
      return outletSettingList
    } catch (error) {
      throw error
    }
  }

  public updateOutletSettingById = async (requestBody: OutletSetting) => {
    try {
      const res = await this.api.contactless.dining.updateOutletSetting(
        requestBody.outletId,
        requestBody
      )
      const { contactless } = this.getState()
      const { dining } = contactless
      const outletSetting = dining.outletSetting
      outletSetting[res.id] = res
      this.diningStorage.setOutletSetting(outletSetting)
      return res
    } catch (error) {
      throw error
    }
  }
}

export interface IDiningService {
  getMerchantSetting: () => Promise<MerchantSetting>
  createMerchantSetting: (
    merchantRequest: MerchantSetting
  ) => Promise<MerchantSetting>
  updateMerchantSetting: (
    merchantRequest: MerchantSetting,
    urlSlug: string
  ) => Promise<MerchantSetting>
  checkMerchantSettingSlug: (slug: string) => Promise<any>
  getOutletSetting: () => Promise<OutletSetting[]>
  updateOutletSettingById: (
    requestBody: OutletSetting
  ) => Promise<OutletSetting>
}
