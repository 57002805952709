import React from 'react'
import './style.css'
import { Text, Sided } from 'views/components'
import classNames from 'classnames'

interface OnboardingHorizontalStepsProps {
  stepNames: string[]
  currentStep: number
  showLabel?: boolean
  isIcon?: boolean
  onClick?: (step: number) => any
}

const OHSteps: React.FC<OnboardingHorizontalStepsProps> = props => {
  const sequentialStepRules = (index: number) =>
    index + 1 < props.currentStep ? index + 1 : props.currentStep

  const circleClassName = classNames('circle', props.isIcon ? 'cheklist' : '')

  const renderEachStep = (stepName: string, index: number) => {
    const showLabel = props.showLabel === undefined ? true : props.showLabel
    const className = classNames(
      'step',
      index < props.currentStep ? 'fulfilled' : ''
    )

    return (
      <Sided.Remaining className={className}>
        {index > 0 && <div className='line'></div>}
        <div
          className={circleClassName}
          onClick={() =>
            props.onClick ? props.onClick(sequentialStepRules(index)) : {}
          }
        >
          <div className='onboarding-checkmark'></div>
        </div>
        {showLabel && (
          <Text.Heading
            h={4}
            align='center'
            noMargin
            color={index < props.currentStep ? 'black' : 'gray'}
          >
            {stepName}
          </Text.Heading>
        )}
        {props.currentStep !== 0 && index === props.currentStep && (
          <div className='progress-line'></div>
        )}
      </Sided.Remaining>
    )
  }
  return (
    <Sided.Sided gutter={0} className='onboarding-horizontal-steps'>
      {props.stepNames.map(renderEachStep)}
    </Sided.Sided>
  )
}

export default OHSteps
