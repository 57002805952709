import { Reducer, StateActions } from 'utilities/redux/reducer'
import { DiningState } from 'domains/contactless/states'
import { Dict } from 'utilities/type'
import { OutletSetting } from 'domains/contactless/models'
import { SET_OUTLET_SETTINGS } from './actions'

export class DiningReducer extends Reducer<DiningState> {
  constructor() {
    super({
      outletSetting: {},
    })
  }

  setOutletSetting = (
    state: DiningState,
    outletSetting: Dict<OutletSetting>
  ): DiningState => {
    return {
      ...state,
      outletSetting: outletSetting,
    }
  }

  get actions(): StateActions<DiningState> {
    return {
      [SET_OUTLET_SETTINGS]: this.setOutletSetting,
    }
  }
}
