import { BaseApi } from 'api/base.api'
import { IDiningApi } from 'domains/contactless/contracts/api'
import { MerchantSetting, OutletSetting } from 'domains/contactless/models'

export default class CatalogSettingApi extends BaseApi implements IDiningApi {
  constructor() {
    super('/contactless')
  }

  public async getMerchantSetting(): Promise<MerchantSetting> {
    return await this.makeForCamelCase('GET', '/v1/settings/merchants')
  }

  public async createMerchantSetting(
    merchantSetting: MerchantSetting
  ): Promise<MerchantSetting> {
    return await this.makeForCamelCase(
      'POST',
      '/v1/settings/merchants',
      merchantSetting
    )
  }

  public async checkMerchantSettingSlug(
    urlSlug: string
  ): Promise<MerchantSetting> {
    return await this.makeForCamelCase(
      'POST',
      `/v1/settings/merchants/slug/validate`,
      {
        urlSlug,
      }
    )
  }

  public async updateMerchantSetting(
    merchantSetting: MerchantSetting,
    urlSlug: string
  ): Promise<MerchantSetting> {
    return await this.makeForCamelCase(
      'POST',
      `/v1/settings/merchants/${urlSlug}`,
      {
        ...merchantSetting,
      }
    )
  }

  public async getOutletSetting(): Promise<OutletSetting[]> {
    return await this.makeForCamelCaseForList('GET', '/v1/settings/outlets')
  }

  public async updateOutletSetting(
    id: string,
    requestBody: OutletSetting
  ): Promise<OutletSetting> {
    return await this.makeForCamelCase(
      'POST',
      `/v1/settings/outlets/${id}`,
      requestBody
    )
  }
}
