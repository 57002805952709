import React from 'react'
import classNames from 'classnames'
import './style.css'

interface ImageProps {
  src: string
  alt?: string
  height: string
  width: string
  align?: 'left' | 'right' | 'center'
  mode?: 'contain' | 'cover'
  className?: string
  onClick?: () => any
  style?: React.CSSProperties
}

const Image: React.FC<ImageProps> = props => {
  const className = classNames('post-image', props.className)

  const style: React.CSSProperties = {
    position: 'relative',
    ...props.style,
    height: props.height,
    display: 'inline-block',
    width: props.width,
    backgroundImage: `url(${props.src})`,
    lineHeight: props.height,
    backgroundSize: props.mode,
    textAlign: props.align || 'left',
    cursor: props.onClick ? 'pointer' : 'default',
  }

  const onClick = () => {
    if (props.onClick) {
      props.onClick()
    }
  }

  return (
    <div className={className} style={style} onClick={onClick}>
      {!props.src && props.alt}
    </div>
  )
}

export default Image
