import {
  BusinessType,
  OutletOwnershipStatus,
  Bank,
  PaymentMethodKYCForm,
  FinalizePaymentMethodKYCForm,
  PaymentOption,
} from '../models'
import { BaseService } from 'domains/commons/services'

export interface IPaymentMethodKYCService {
  getBusinessTypes: () => Promise<BusinessType[]>
  getOutletOwnershipStatuses: () => Promise<OutletOwnershipStatus[]>
  getBanks: () => Promise<Bank[]>
  getCashlezPaymentMethodKYCForm: (
    outletId: string
  ) => Promise<PaymentMethodKYCForm>
  getCashlezPaymentMethods: (outletId: string) => Promise<PaymentOption[]>
  getShopeePaymentMethods: (outletId: string) => Promise<PaymentOption[]>
  checkCashlezPaymentMethodKYCForm: (
    outletId: string
  ) => Promise<FinalizePaymentMethodKYCForm>
  upsertCashlezPaymentMethodKYCForm: (
    paymentMethodKYCForm: PaymentMethodKYCForm
  ) => Promise<PaymentMethodKYCForm>
  finalizeCashlezPaymentMethodKYCForm: (
    outletId: string,
    headers: object
  ) => Promise<PaymentMethodKYCForm>
  activateCashlezPaymentMethodOption: (
    outletId: string,
    paymentOptionId: string
  ) => Promise<void>
  getShopeePaymentMethodKYCForm: (
    outletId: string
  ) => Promise<PaymentMethodKYCForm>
  checkShopeePaymentMethodKYCForm: (
    outletId: string
  ) => Promise<FinalizePaymentMethodKYCForm>
  upsertShopeePaymentMethodKYCForm: (
    paymentMethodKYCForm: PaymentMethodKYCForm
  ) => Promise<PaymentMethodKYCForm>
  finalizeShopeePaymentMethodKYCForm: (
    outletId: string,
    headers: object
  ) => Promise<PaymentMethodKYCForm>
  activateShopeePaymentMethodOption: (
    outletId: string,
    paymentOptionId: string
  ) => Promise<void>
}

export class PaymentMethodKYCService
  extends BaseService
  implements IPaymentMethodKYCService
{
  // paymentMethodStorage: IPaymentMethodStorage;

  // constructor(commonStorage: ICommonStorage, api: Api){
  //   super(commonStorage, api)
  // this.paymentMethodStorage = discountStorage
  // }

  public async getBusinessTypes(): Promise<BusinessType[]> {
    try {
      return await this.api.management.paymentMethodKYC.getBusinessTypes()
    } catch (error) {
      throw error
    }
  }

  public async getOutletOwnershipStatuses(): Promise<OutletOwnershipStatus[]> {
    try {
      return await this.api.management.paymentMethodKYC.getOutletOwnershipStatuses()
    } catch (error) {
      throw error
    }
  }

  public async getBanks(): Promise<Bank[]> {
    try {
      return await this.api.management.paymentMethodKYC.getBanks()
    } catch (error) {
      throw error
    }
  }

  public async getCashlezPaymentMethods(
    outletId: string
  ): Promise<PaymentOption[]> {
    this.commonStorage.setLoading('GET_CASHLEZ_PAYMENT_METHOD', true)
    try {
      const cashlezPaymentMethodsResponse =
        await this.api.management.paymentMethodKYC.getCashlezPaymentMethod(
          outletId
        )
      this.commonStorage.setLoading('GET_CASHLEZ_PAYMENT_METHOD', false)
      return cashlezPaymentMethodsResponse
    } catch (error) {
      this.commonStorage.setLoading('GET_CASHLEZ_PAYMENT_METHOD', false)
      throw error
    }
  }

  public async getShopeePaymentMethods(
    outletId: string
  ): Promise<PaymentOption[]> {
    this.commonStorage.setLoading('GET_SHOPEE_PAYMENT_METHOD', true)
    try {
      const shopeePaymentMethodsResponse =
        await this.api.management.paymentMethodKYC.getShopeePaymentMethod(
          outletId
        )
      this.commonStorage.setLoading('GET_SHOPEE_PAYMENT_METHOD', false)
      return shopeePaymentMethodsResponse
    } catch (error) {
      this.commonStorage.setLoading('GET_SHOPEE_PAYMENT_METHOD', false)
      throw error
    }
  }

  public async getCashlezPaymentMethodKYCForm(
    outletId: string
  ): Promise<PaymentMethodKYCForm> {
    this.commonStorage.setLoading('GET_CASHLEZ_PAYMENT_METHOD_KYC_FORM', true)
    try {
      const cashlezPaymentMethodKYCFormResponse =
        await this.api.management.paymentMethodKYC.getPaymentMethodKYCForm(
          outletId,
          'cashlez'
        )
      this.commonStorage.setLoading(
        'GET_CASHLEZ_PAYMENT_METHOD_KYC_FORM',
        false
      )
      return cashlezPaymentMethodKYCFormResponse
    } catch (error) {
      this.commonStorage.setLoading(
        'GET_CASHLEZ_PAYMENT_METHOD_KYC_FORM',
        false
      )
      throw error
    }
  }

  public async checkCashlezPaymentMethodKYCForm(
    outletId: string
  ): Promise<FinalizePaymentMethodKYCForm> {
    this.commonStorage.setLoading('CHECK_CASHLEZ_PAYMENT_METHOD_KYC', true)
    try {
      const checkCashlezPaymentMethodKYCFormResponse =
        await this.api.management.paymentMethodKYC.checkPaymentMethodKYCForm(
          outletId,
          'cashlez'
        )
      this.commonStorage.setLoading('CHECK_CASHLEZ_PAYMENT_METHOD_KYC', false)
      return checkCashlezPaymentMethodKYCFormResponse
    } catch (error) {
      this.commonStorage.setLoading('CHECK_CASHLEZ_PAYMENT_METHOD_KYC', false)
      throw error
    }
  }

  public async upsertCashlezPaymentMethodKYCForm(
    paymentMethodKYCForm: PaymentMethodKYCForm
  ): Promise<PaymentMethodKYCForm> {
    try {
      return await this.api.management.paymentMethodKYC.upsertPaymentMethodKYCForm(
        paymentMethodKYCForm,
        'cashlez'
      )
    } catch (error) {
      throw error
    }
  }

  public async finalizeCashlezPaymentMethodKYCForm(
    outletId: string,
    headers: object = {}
  ): Promise<PaymentMethodKYCForm> {
    try {
      return await this.api.management.paymentMethodKYC.finalizePaymentMethodKYCForm(
        outletId,
        'cashlez',
        { isFinalized: true },
        headers
      )
    } catch (error) {
      throw error
    }
  }

  public async activateCashlezPaymentMethodOption(
    outletId: string,
    paymentOptionId: string
  ): Promise<void> {
    try {
      return await this.api.management.paymentMethodKYC.activatePaymentMethodOption(
        outletId,
        paymentOptionId,
        'cashlez'
      )
    } catch (error) {
      throw error
    }
  }

  public async getShopeePaymentMethodKYCForm(
    outletId: string
  ): Promise<PaymentMethodKYCForm> {
    this.commonStorage.setLoading('GET_SHOPEE_PAYMENT_METHOD_KYC_FORM', true)
    try {
      const shopeePaymentMethodKYCFormResponse =
        await this.api.management.paymentMethodKYC.getPaymentMethodKYCForm(
          outletId,
          'shopee'
        )
      this.commonStorage.setLoading('GET_SHOPEE_PAYMENT_METHOD_KYC_FORM', false)
      return shopeePaymentMethodKYCFormResponse
    } catch (error) {
      this.commonStorage.setLoading('GET_SHOPEE_PAYMENT_METHOD_KYC_FORM', false)
      throw error
    }
  }

  public async checkShopeePaymentMethodKYCForm(
    outletId: string
  ): Promise<FinalizePaymentMethodKYCForm> {
    this.commonStorage.setLoading('CHECK_SHOPEE_PAYMENT_METHOD_KYC', true)
    try {
      const checkShopeePaymentMethodKYCFormResponse =
        await this.api.management.paymentMethodKYC.checkPaymentMethodKYCForm(
          outletId,
          'shopee'
        )
      this.commonStorage.setLoading('CHECK_SHOPEE_PAYMENT_METHOD_KYC', false)
      return checkShopeePaymentMethodKYCFormResponse
    } catch (error) {
      this.commonStorage.setLoading('CHECK_SHOPEE_PAYMENT_METHOD_KYC', false)
      throw error
    }
  }

  public async upsertShopeePaymentMethodKYCForm(
    paymentMethodKYCForm: PaymentMethodKYCForm
  ): Promise<PaymentMethodKYCForm> {
    try {
      return await this.api.management.paymentMethodKYC.upsertPaymentMethodKYCForm(
        paymentMethodKYCForm,
        'shopee'
      )
    } catch (error) {
      throw error
    }
  }

  public async finalizeShopeePaymentMethodKYCForm(
    outletId: string,
    headers: object = {}
  ): Promise<PaymentMethodKYCForm> {
    try {
      return await this.api.management.paymentMethodKYC.finalizePaymentMethodKYCForm(
        outletId,
        'shopee',
        { isFinalized: true },
        headers
      )
    } catch (error) {
      throw error
    }
  }

  public async activateShopeePaymentMethodOption(
    outletId: string,
    paymentOptionId: string
  ): Promise<void> {
    try {
      return await this.api.management.paymentMethodKYC.activatePaymentMethodOption(
        outletId,
        paymentOptionId,
        'shopee'
      )
    } catch (error) {
      throw error
    }
  }
}
