import { SingleReducer } from 'utilities/redux/reducer'
import { combineReducers } from 'redux'
import { ContactlessState } from 'domains/contactless/state'
import { DiningReducer } from './dining/reducer'

export class ContactlessReducer {
  build(): SingleReducer<ContactlessState> {
    return combineReducers<ContactlessState>({
      dining: new DiningReducer().build(),
    })
  }
}
