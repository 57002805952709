import {
  Staff,
  StaffRequestBody,
  Cashier,
  Employee,
  SetStaffPassword,
} from '../models'
import { BaseService } from 'domains/commons/services'
import { IEmployeeStorage } from '../contracts/storage'
import { ICommonStorage } from 'domains/commons/contracts/storage'
import { Api } from 'domains/contracts/api'
import { BusinessCategory } from 'domains/users/models'
import { Group } from 'utilities/type'
import { toDict } from 'utilities/converter/list'
import { getLocalStorage } from 'utilities/localStorage'
import { EmployeeQueryParam } from '../states'
import { history } from 'storages'
import ROUTE from 'constants/routes'
import { CONFIG_LOCALSTORAGE } from 'constants/localStorage'

const SET_EMPLOYEES = 'SET_EMPLOYEES'

export class EmployeeService extends BaseService implements IEmployeeService {
  employeeStorage: IEmployeeStorage

  constructor(
    commonStorage: ICommonStorage,
    api: Api,
    employeeStorage: IEmployeeStorage
  ) {
    super(commonStorage, api)
    this.employeeStorage = employeeStorage
  }

  private getLastId = (employees: Employee[]): string | undefined => {
    return employees.length > 0 ? employees[employees.length - 1].id : undefined
  }

  public loadEmployees = async (queryParams: EmployeeQueryParam = {}) => {
    try {
      this.setLoading(SET_EMPLOYEES, true)
      queryParams.limit = queryParams.limit || 10
      const employees = await this.api.management.employee.getEmployees(
        queryParams
      )
      this.employeeStorage.setEmployees(
        toDict(employees, employee => employee.id)
      )
      this.setLoading(SET_EMPLOYEES, false)
      const lastProductId = this.getLastId(employees)
      this.employeeStorage.setEmployeeListRequest({
        ...queryParams,
        startingAfter: lastProductId || queryParams.startingAfter,
      } as EmployeeQueryParam)
      this.employeeStorage.setEmployeeListReachBottom(employees.length === 0)

      return employees
    } catch (error) {
      throw error
    }
  }

  private setIsFnbBusinessCategory = (
    businessCategories: Group<BusinessCategory>
  ) => {
    const fnbBusinessCategories =
      businessCategories && businessCategories['Makanan & Minuman']
    const businessCategoryId = getLocalStorage(
      CONFIG_LOCALSTORAGE.STAFF_BUSINESS_CATEGORY_ID
    )
    this.employeeStorage.setIsFnbBusinessCategory(
      !!fnbBusinessCategories.find(
        category => category.id === businessCategoryId
      )
    )
  }

  public loadMore = async (): Promise<Employee[]> => {
    try {
      const employeeListManager =
        this.getState().management.employee.employeeListManager
      if (employeeListManager.hasReachedBottom) {
        return Promise.resolve([])
      }
      if (employeeListManager.isLoadingMoreEmployee) {
        return Promise.resolve([])
      }
      this.employeeStorage.setLoadingMoreEmployee(true)
      const queryParams = employeeListManager.request
      const employees = await this.api.management.employee.getEmployees(
        queryParams
      )
      this.employeeStorage.appendEmployees(
        toDict(employees, (p: Employee) => p.id)
      )
      const lastProductId = this.getLastId(employees)
      this.employeeStorage.setLoadingMoreEmployee(false)
      this.employeeStorage.setEmployeeListRequest({
        ...queryParams,
        startingAfter: lastProductId || queryParams.startingAfter,
      } as EmployeeQueryParam)
      this.employeeStorage.setEmployeeListReachBottom(employees.length === 0)

      return employees
    } catch (error) {
      throw error
    }
  }

  public getStaff = async (id: string) => {
    try {
      const staffResponse = await this.api.management.employee.getStaff(id)
      this.employeeStorage.setCurrentStaff(staffResponse)
      return staffResponse
    } catch (error) {
      throw error
    }
  }

  public createStaff = async (staff: StaffRequestBody) => {
    try {
      const staffResponse = await this.api.management.employee.createStaff(
        staff
      )
      this.sendCreatePasswordEmail(staffResponse.id)
      return staffResponse
    } catch (error) {
      throw error
    }
  }

  public updateStaff = async (staff: StaffRequestBody, id: string) => {
    try {
      return await this.api.management.employee.updateStaff(staff, id)
    } catch (error) {
      throw error
    }
  }

  public getCashier = async (id: string) => {
    try {
      const cashierResponse = await this.api.management.employee.getCashier(id)
      this.employeeStorage.setCurrentCashier(cashierResponse)
      return cashierResponse
    } catch (error) {
      throw error
    }
  }

  public createCashier = async (cashier: Cashier) => {
    try {
      return await this.api.management.employee.createCashier(cashier)
    } catch (error) {
      throw error
    }
  }

  public updateCashier = async (cashier: Cashier, id: string) => {
    try {
      return await this.api.management.employee.updateCashier(cashier, id)
    } catch (error) {
      throw error
    }
  }

  public deleteEmployee = async (employeeId: string) => {
    try {
      await this.api.management.employee.deleteEmployee(employeeId)
      const { employees } = this.getState().management.employee
      delete employees[employeeId]
      this.employeeStorage.setEmployees(employees)
    } catch (error) {
      throw error
    }
  }

  public sendCreatePasswordEmail = async (staffId: string) => {
    try {
      await this.api.management.employee.sendCreatePasswordEmail(staffId)
    } catch (error) {
      throw error
    }
  }

  public setStaffPassword = async (setStaffPassword: SetStaffPassword) => {
    try {
      await this.api.management.employee.setStaffPassword(setStaffPassword)
    } catch (error) {
      throw error
    }
  }

  public logOutEmployee = async (header: {}, id: string) => {
    try {
      await this.api.management.employee.logOutEmployee(header, id)
    } catch (error) {
      throw error
    }
  }

  public validateEmployeeRegisterToken = async (id: string) => {
    try {
      await this.api.management.employee.validateEmployeeRegisterToken(id)
    } catch (error) {
      history.push(ROUTE.LOGIN)
    }
  }

  public getBusinessCategories = async () => {
    try {
      const businessCategories = await this.api.users.getBusinessCategories()
      this.setIsFnbBusinessCategory(businessCategories)
    } catch (error) {
      throw error
    }
  }
}

export interface IEmployeeService {
  loadEmployees: (
    employeeListRequest?: EmployeeQueryParam
  ) => Promise<Employee[]>
  loadMore: () => Promise<Employee[]>
  getStaff: (id: string) => Promise<Staff>
  createStaff: (staff: StaffRequestBody) => Promise<Staff>
  updateStaff: (staff: StaffRequestBody, id: string) => Promise<Staff>
  getCashier: (id: string) => Promise<Cashier>
  createCashier: (cashier: Cashier) => Promise<Cashier>
  updateCashier: (cashier: Cashier, id: string) => Promise<Cashier>
  deleteEmployee: (id: string) => Promise<void>
  sendCreatePasswordEmail: (staffId: string) => Promise<void>
  setStaffPassword: (setStaffPassword: SetStaffPassword) => Promise<void>
  logOutEmployee: (header: {}, id: string) => Promise<void>
  validateEmployeeRegisterToken: (id: string) => Promise<void>
  getBusinessCategories: () => Promise<void>
}
