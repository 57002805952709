import React, { useState, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import selector from '../selector'
import {
  Wrapper,
  Sided,
  Button,
  Break,
  Panel,
  Text,
  Icon,
  VSided,
} from 'views/components'
import { ModalProps, Modal } from 'views/components/Modal'
import {
  MassProduct,
  MassProductUpdate,
} from 'domains/management/products/models'
import { productService, commonService } from 'injectors'
import './style.css'
import { t, Trans } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { trimDuplicateSpace } from 'utilities/strings'
import Loader from 'views/components/Loader'
import MassImportReactTable from './table'
import { REGEX } from 'constants/regex'
import UUID from 'uuidjs'

const checkRowValidity = (item: MassProduct) => {
  const errorFields: string[] = []
  if (!item.productName) {
    errorFields.push('productName')
  }
  if (item.price === undefined || item.price <= 0) {
    errorFields.push('price')
  }
  if (item.unitCost > item.price) {
    errorFields.push('unitCost')
  }
  if (item.minStock < 0) {
    errorFields.push('minStock')
  }
  if (item.stockAdjustment.quantity < 0) {
    errorFields.push('stockAdjustment.quantity')
  }
  if (item.trackStock === undefined) {
    errorFields.push('trackStock')
  }
  if (item.description.length > 160) {
    errorFields.push('description')
  }
  if (REGEX.DECIMAL_ONLY.test(JSON.stringify(item.weight))) {
    errorFields.push('weight')
  }
  if (item.dimension) {
    if (REGEX.DECIMAL_ONLY.test(JSON.stringify(item.dimension.height))) {
      errorFields.push('dimension.height')
    }
    if (REGEX.DECIMAL_ONLY.test(JSON.stringify(item.dimension.length))) {
      errorFields.push('dimension.length')
    }
    if (REGEX.DECIMAL_ONLY.test(JSON.stringify(item.dimension.width))) {
      errorFields.push('dimension.width')
    }
    if (
      !!item.dimension.height ||
      !!item.dimension.length ||
      !!item.dimension.width
    ) {
      if (!item.dimension.height) {
        errorFields.push('dimension.height')
      }
      if (!item.dimension.length) {
        errorFields.push('dimension.length')
      }
      if (!item.dimension.width) {
        errorFields.push('dimension.width')
      }
    }
  }
  if (item.barcode && !REGEX.NUMBER.test(item.barcode)) {
    errorFields.push('barcode')
  }
  return errorFields
}

interface MassImportTableModalProps extends ModalProps {
  massProducts: MassProduct[]
  type: string
}

interface Category {
  id: string
  name: string
}

interface Category {
  id: string
  name: string
}

const MassImportTable: React.FC<MassImportTableModalProps> = props => {
  const [isReady, setIsReady] = useState(false)
  const [isError, setIsError] = useState(false)
  const { i18n } = useLingui()

  const [data, setData] = useState(() => props.massProducts)
  const [newCategory, setNewCategory] = useState<Category[]>([] as Category[])
  const isCreate = props.type === 'create'
  const { products, user } = useSelector(selector)

  useEffect(() => {
    checkIfThereIsValidationError(data)
    for (let i = 0; i < data.length; i++) {
      if (data[i].categoryId === null && data[i].categoryName) {
        if (newCategory.length === 0) {
          setNewCategory([
            {
              id: UUID.generate(),
              name: data[i].categoryName,
            },
          ])
          continue
        }
        if (newCategory.length > 0) {
          const isCategoryExist = newCategory.some(
            category => category.name === data[i].categoryName
          )
          if (!isCategoryExist) {
            setNewCategory([
              ...newCategory,
              {
                id: UUID.generate(),
                name: data[i].categoryName,
              },
            ])
          }
        }
      }
    }
  }, [data])

  const checkIfThereIsValidationError = (products: MassProduct[]) => {
    setIsReady(false)
    const validationsResults = products.filter(
      row => checkRowValidity(row).length > 0
    )
    setIsError(validationsResults.length > 0)
    setIsReady(true)
  }

  // const storeNewCategory = (products: MassProduct[]) => {
  //   for (let i = 0; i < products.length; i++) {
  //     if (products[i].categoryId === null && products[i].categoryName) {
  //       if (i === 0) {
  //         newCategory.push({
  //           id: UUID.generate(),
  //           name: products[i].categoryName,
  //         })
  //         continue
  //       }
  //       if (newCategory.length > 0) {
  //         const isCategoryExist = newCategory.some(
  //           category => category.name === products[i].categoryName
  //         )
  //         if (!isCategoryExist) {
  //           newCategory.push({
  //             id: UUID.generate(),
  //             name: products[i].categoryName,
  //           })
  //         }
  //       }
  //     }
  //   }
  // }

  const handleUploadMassProduct = async () => {
    setIsReady(false)
    if (isCreate) {
      const filteredMassProducts: MassProduct[] = data.map(
        ({
          productName,
          categoryId,
          categoryName,
          price,
          unitCost,
          sku,
          barcode,
          trackStock,
          stockAdjustment,
          minStock,
          dimension,
          description,
          weight,
        }) => {
          if (categoryId === null) {
            newCategory.forEach(category => {
              if (category.name === categoryName) {
                categoryId = category.id
              }
            })
          }

          return {
            productName: trimDuplicateSpace(productName),
            categoryId,
            categoryName,
            price,
            unitCost,
            sku,
            barcode,
            trackStock,
            stockAdjustment,
            minStock,
            dimension,
            description,
            weight,
          }
        }
      )
      try {
        await productService.uploadMass({
          data: filteredMassProducts,
        })
        commonService.setAlert({
          type: 'success',
          message: i18n._(t`Upload produk berhasil!`),
        })
        setIsReady(true)
        props.close(true)
      } catch (error) {
        commonService.setAlert({
          type: 'error',
          message: error.message,
        })
        setIsReady(true)
      }
    } else {
      const filteredMassProducts: MassProductUpdate[] = data.map(
        ({
          productName,
          categoryId,
          categoryName,
          price,
          unitCost,
          sku,
          barcode,
          trackStock,
          stockAdjustment,
          minStock,
          dimension,
          description,
          weight,
          productId,
        }) => {
          const productData = products.find(item => item.id === productId)
          if (categoryId === null) {
            newCategory.forEach(category => {
              if (category.name === categoryName) {
                categoryId = category.id
              }
            })
          }

          return {
            productName: trimDuplicateSpace(productName),
            categoryId,
            categoryName,
            price,
            unitCost,
            sku,
            barcode,
            trackStock,
            stockAdjustment,
            minStock,
            dimension,
            description,
            weight,
            userID: user.id,
            productId: productId,
            active: productData?.active,
            applyOnNewOutlet: productData?.applyOnNewOutlet,
            comboOnly: productData?.singleData.comboOnly,
            favorite: productData?.favorite,
            outletIds: productData?.outlets.map(item => item.id),
            pictureUrl: productData?.pictureUrl,
            pricing: productData?.singleData.pricing,
          }
        }
      )
      try {
        await productService.updateMass({
          data: filteredMassProducts,
        })
        commonService.setAlert({
          type: 'success',
          message: i18n._(t`Sukses mengubah ${data.length} produk`),
        })
        setIsReady(true)
        props.close(true)
      } catch (error) {
        commonService.setAlert({
          type: 'error',
          message: error.message,
        })
        setIsReady(true)
      }
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Nama Produk',
        accessor: 'productName',
      },
      {
        Header: 'Kategori',
        accessor: 'categoryName',
      },
      {
        Header: 'Harga Jual',
        accessor: 'price',
      },
      {
        Header: 'Harga Modal (COGS)',
        accessor: 'unitCost',
      },
      {
        Header: 'SKU',
        accessor: 'sku',
      },
      {
        Header: 'Barcode',
        accessor: 'barcode',
      },
      {
        Header: 'Deskripsi',
        accessor: 'description',
      },
      {
        Header: 'Kelola Stok',
        id: 'trackStock',
        accessor: (d: MassProduct) => (d.trackStock ? 'Iya' : 'Tidak'),
      },
      {
        Header: 'Stok Saat Ini',
        accessor: 'stockAdjustment.quantity',
      },
      {
        Header: 'Stok Minimum',
        accessor: 'minStock',
      },
      {
        Header: 'Berat (gram)',
        accessor: 'weight',
      },
      {
        Header: 'Panjang (cm)',
        accessor: 'dimension.length',
      },
      {
        Header: 'Lebar (cm)',
        accessor: 'dimension.width',
      },
      {
        Header: 'Tinggi (cm)',
        accessor: 'dimension.height',
      },
    ],
    []
  )

  const [skipPageReset, setSkipPageReset] = React.useState(false)

  const updateMyData = (rowIndex: number, columnId: string, value: any) => {
    if (data[rowIndex][columnId] !== value) {
      setSkipPageReset(true)
      setData(old => {
        const newMassProducts: MassProduct[] = []
        old.forEach((row, index) => {
          if (index === rowIndex) {
            const newData = {
              ...old[rowIndex],
            }
            if (columnId.includes('stockAdjustment')) {
              newData.stockAdjustment = {
                ...newData.stockAdjustment,
                [columnId.split('.')[1]]: value,
              }
            } else if (columnId.includes('dimension')) {
              newData.dimension = {
                ...newData.dimension,
                [columnId.split('.')[1]]: value,
              }
            } else {
              newData[columnId] = value
            }
            newMassProducts.push({
              productId: row.productId,
              productName: newData.productName,
              categoryName:
                newData.categoryName !== undefined
                  ? newData.categoryName
                  : undefined,
              categoryId: newData.categoryName
                ? productService.checkMassProductCategory(
                    newData.categoryName,
                    newMassProducts
                  )
                : undefined,
              price: newData.price !== undefined ? +newData.price : undefined,
              unitCost: newData.unitCost !== undefined ? +newData.unitCost : 0,
              sku:
                String(newData.sku) !== 'undefined'
                  ? String(newData.sku)
                  : undefined,
              barcode:
                String(newData.barcode) !== 'undefined'
                  ? String(newData.barcode)
                  : undefined,
              trackStock:
                typeof newData.trackStock === 'string'
                  ? newData.trackStock.toLowerCase() === 'iya'
                    ? true
                    : false
                  : newData.trackStock,
              stockAdjustment:
                newData.stockAdjustment !== undefined
                  ? {
                      type: newData.stockAdjustment.type,
                      quantity: +newData.stockAdjustment.quantity,
                      note: '',
                    }
                  : {
                      type: 'addition',
                      quantity: 0,
                      note: '',
                    },
              minStock: newData.minStock !== undefined ? +newData.minStock : 0,
              description: newData.description || '',
              weight: +newData.weight,
              dimension: {
                length: +newData.dimension.length,
                width: +newData.dimension.width,
                height: +newData.dimension.height,
              },
            })
          } else {
            newMassProducts.push(row)
          }
        })
        return newMassProducts
      })
    }
  }

  // const resetData = () => setData(originalData)

  const renderHeader = () => (
    <Wrapper width='100%' isCenter style={{ backgroundColor: '#f7f9fa' }}>
      <Break height={20} />
      {isError && (
        <Wrapper align='center'>
          <Trans>
            <Text.Span align='center' className='error-msg-wrapper'>
              <Icon.CautionSolid className='error-msg' /> Terjadi kesalahan.
              Silahkan periksa kembali informasi produk yang Anda masukkan
            </Text.Span>
          </Trans>
        </Wrapper>
      )}
      <Break height={32} />
      <Trans>
        <Text.Heading h={3} align='center' noMargin color='black'>
          Periksa Data Produk
        </Text.Heading>
      </Trans>
      <Break height={10} />
    </Wrapper>
  )

  const renderContent = () =>
    isReady ? (
      <Wrapper backgroundColor='#f7f9fa' padding='0 24px' height='100%'>
        <Break height={20} />
        <Panel.Card style={{ maxHeight: 'calc(100% - 50px)' }}>
          <MassImportReactTable
            columns={columns}
            data={data}
            updateMyData={updateMyData}
            skipPageReset={skipPageReset}
            isError={isError}
            checkRowValidity={checkRowValidity}
          />
          {/* <button onClick={resetData}>Reset Data</button> */}
        </Panel.Card>
      </Wrapper>
    ) : (
      <Loader />
    )

  const renderConfirmationButton = () => (
    <Wrapper
      width='100%'
      padding='16px 0px'
      backgroundColor='white'
      shadow='0 -2px 4px 0 rgba(0, 0, 0, 0.2)'
    >
      <Wrapper width='480px' isCenter>
        <Sided.Sided gutter={16} justifyContent='center'>
          <Sided.Remaining>
            <Button
              theme='netral'
              fullW
              label={i18n._(t`Kembali`)}
              onClick={() => props.dismiss()}
            />
          </Sided.Remaining>
          <Sided.Remaining>
            <Button
              theme='primary'
              fullW
              disabled={isError}
              label={i18n._(t`Import`)}
              onClick={() => handleUploadMassProduct()}
            />
          </Sided.Remaining>
        </Sided.Sided>
      </Wrapper>
    </Wrapper>
  )

  return (
    <>
      <Modal dismiss={props.dismiss} type='full' canDismissOutside={false}>
        <Wrapper style={{ height: '100%' }}>
          <VSided.VSided>
            <VSided.Fixed>{renderHeader()}</VSided.Fixed>
            <VSided.Remaining>{renderContent()}</VSided.Remaining>
            <VSided.Fixed>{renderConfirmationButton()}</VSided.Fixed>
          </VSided.VSided>
        </Wrapper>
      </Modal>
    </>
  )
}

export default MassImportTable
