import React from 'react'
import { Modal, ModalProps } from 'views/components/Modal'
import {
  Panel,
  Sided,
  Text,
  Icon,
  Form,
  Field,
  Break,
  Button,
  FormAction,
} from 'views/components'
import { categoryService, commonService } from 'injectors'
import { CreateCategoryRequest } from 'domains/management/category/models'
import { categoryFormSchema } from 'views/modules/Management/Category/Form/categoryFormSchema'
import { useLingui } from '@lingui/react'
import { t, Trans } from '@lingui/macro'

type AddProductCategoryExtraModalProps = ModalProps

const AddProductCategoryExtraModal: React.FC<AddProductCategoryExtraModalProps> =
  props => {
    const { i18n } = useLingui()

    const submit = async (actions: FormAction<CreateCategoryRequest>) => {
      try {
        const category = await categoryService.createSingleCategory(
          actions.getValues()
        )
        commonService.setAlert({
          type: 'success',
          message: i18n._(t`Kategori baru berhasil disimpan`),
        })
        props.close(category.id)
      } catch (error) {
        commonService.setAlert({
          type: 'error',
          message: error.message,
        })
      }
    }

    return (
      <Modal
        dismiss={props.dismiss}
        type='blank'
        canDismissOutside={false}
        width='470px'
      >
        <Panel.Panel>
          <Panel.Header>
            <Sided.Sided justifyContent='center' justify='center'>
              <Sided.Remaining>
                <Trans>
                  <Text.Paragraph size={14} color='black' weight='bold'>
                    Buat Kategori
                  </Text.Paragraph>
                </Trans>
              </Sided.Remaining>
              <Sided.Fixed>
                <Icon.Close
                  style={{ cursor: 'pointer' }}
                  onClick={() => props.close(false)}
                  color='black'
                />
              </Sided.Fixed>
            </Sided.Sided>
          </Panel.Header>
          <Panel.Content>
            <Form.Form
              initialValues={{
                name: undefined,
              }}
              validationSchema={categoryFormSchema}
              onSubmit={submit}
              render={(actions: FormAction<CreateCategoryRequest>) => {
                return (
                  <React.Fragment>
                    <Field.Text
                      name='name'
                      label={i18n._(t`Nama Kategori`)}
                      placeholder={i18n._(t`Nama Kategori`)}
                    />
                    <Break height={32} />
                    <Sided.Sided gutter={16} justifyContent='center'>
                      <Sided.Remaining>
                        <Button
                          size='large'
                          theme='netral'
                          type='button'
                          label={i18n._(t`Batal`)}
                          fullW={true}
                          onClick={props.dismiss}
                        />
                      </Sided.Remaining>
                      <Sided.Remaining>
                        <Button
                          size='large'
                          theme='primary'
                          type='submit'
                          label={i18n._(t`Tambah`)}
                          fullW={true}
                        />
                      </Sided.Remaining>
                    </Sided.Sided>
                  </React.Fragment>
                )
              }}
            />
          </Panel.Content>
        </Panel.Panel>
      </Modal>
    )
  }

export default AddProductCategoryExtraModal
