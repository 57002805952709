import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { featureCheck } from 'config'
import {
  Subscription,
  SubscriptionPackagePricing,
  SubscriptionPaymentInformation,
  SubscriptionVoucher,
} from 'domains/subscriptions/model'
import { commonService, subscriptionService } from 'injectors'
import { toCurrency } from 'utilities/converter/currency'
import { toGrid } from 'utilities/converter/list'
import { firebaseFunc } from 'utilities/firebase'
import netcore from 'utilities/netcore'
import { capitalizeFirstLetter } from 'utilities/strings/capitalizeFirstLetter'
import { getLocalStorage } from 'utilities/localStorage'
import { CONFIG_LOCALSTORAGE } from 'constants/localStorage'
import {
  Wrapper,
  VSided,
  Sided,
  Icon,
  Text,
  Break,
  Field,
  Button,
  Panel,
  Badge,
  Countdown,
  FeatureFlag,
} from 'views/components'
import { BadgeStatus } from 'views/components/Common'
import { DefaultModalService, Modal, ModalProps } from 'views/components/Modal'

import {
  isPremiumSelector,
  isTrialSelector,
} from '../globalSelector/subscriptionTypeCheck'
import { StepPaymentVirtualAccount } from '../Settings/SubscriptionHistory/ModalSubscribe/stepPaymentvirtualAccount'
import { getSubscriptionBadge } from '../Settings/SubscriptionHistory/selector'
import InvoicePdf from './InvoicePdf'
import { SubsPaymentMethod } from './Modals/SubsPaymentMethod'
import selector from './selector'
import { cdnImageUrl } from 'constants/cdnUrl/imageUrl'

type Props = ModalProps

const SubscriptionComponent: React.FC<Props> = props => {
  const {
    premiumPackage,
    subscriptionList,
    availablePackages,
    availablePackagesDropdownList,
    subscriptionAlert,
    currentSubscription,
  } = useSelector(selector)

  const [selectedPackage, setSelectedPackage] = useState(
    {} as SubscriptionPackagePricing
  )
  const [selectedPackageId, setSelectedPackageId] = useState('')
  const [appliedVoucher, setAppliedVoucher] = useState(
    {} as SubscriptionVoucher
  )
  const [saveNowVoucher, setSaveNowVoucher] = useState(
    {} as SubscriptionVoucher
  )
  const [code, setCode] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [saveNowVoucherName, setSaveNowVoucherName] = useState('')
  const [saveNowVoucherAmount, setSaveNowVoucherAmount] = useState(0)
  const [triggerCount, setTriggerCount] = useState(0)
  const [showVoucherField, setShowVoucherField] = useState(false)
  const [singleMonthPrice, setSingleMonthPrice] = useState(0)
  const [monthCount, setMonthCount] = useState(1)
  const [isVoucherShow, setVoucherShow] = useState(false)
  const [specificPlanId, setSpecificPlanId] = useState('')
  const [specificPlanPrice, setSpecificPlanPrice] = useState(0)

  const postType = getLocalStorage(CONFIG_LOCALSTORAGE.POST_TYPE)

  useEffect(() => {
    firebaseFunc.logEvent('subscription_badge', {
      source: 'Subscription Page',
      user_type: subscriptionAlert.subscriptionType,
      user: postType,
    })
    subscriptionService.getSubcriptionPackage()
    handleFetchSubscriptions()
    fetchVoucherCouponFromFirebase()
  }, [triggerCount])

  const premiumFeatures: string[] = premiumPackage
    ? premiumPackage.features
    : []

  const isPremium = useSelector(isPremiumSelector)
  const isTrial = useSelector(isTrialSelector)

  let badge: any = null

  if (isTrial || isPremium) {
    badge = <BadgeStatus status='premium' />
  }

  const setAllStateToNull = () => {
    setCode('')
    setAppliedVoucher({} as SubscriptionVoucher)
    setErrorMessage('')
  }

  const fetchVoucherCouponFromFirebase = async () => {
    const couponValueName: firebase.remoteConfig.Value =
      await firebaseFunc.getRemoteConfigValue('voucher_coupon_name')
    const couponValueAmount: firebase.remoteConfig.Value =
      await firebaseFunc.getRemoteConfigValue('voucher_coupon_amount')
    const singleMonthPrice: firebase.remoteConfig.Value =
      await firebaseFunc.getRemoteConfigValue('single_month_price')
    const showVoucher: firebase.remoteConfig.Value =
      await firebaseFunc.getRemoteConfigValue('show_voucher')
    const specificPlanId: firebase.remoteConfig.Value =
      await firebaseFunc.getRemoteConfigValue('specific_plan_id')
    const specificPlanPrice: firebase.remoteConfig.Value =
      await firebaseFunc.getRemoteConfigValue('specific_plan_price')
    if (specificPlanId && specificPlanPrice) {
      setSpecificPlanId(specificPlanId.asString())
      setSpecificPlanPrice(specificPlanPrice.asNumber())
    }
    if (couponValueName && couponValueAmount) {
      setSaveNowVoucher({
        code: couponValueName.asString(),
        amount: couponValueAmount.asNumber(),
        percentage: 0,
      })
      setSaveNowVoucherName(couponValueName.asString())
      setSaveNowVoucherAmount(couponValueAmount.asNumber())
      setSingleMonthPrice(singleMonthPrice.asNumber())
      setVoucherShow(showVoucher.asBoolean())
      netcore.dispatchEvent('subscription_savenow_show')
    }
  }

  const renderHeaderSubscribe = () => (
    <Wrapper padding='20px' shadow='0px 3px 12px -5px rgba(0,0,0,0.96)'>
      <Sided.Sided justifyContent='center'>
        <Sided.Fixed minWidth={30}>
          <Icon.ChevronLeft onClick={() => props.close({})} />
        </Sided.Fixed>
        <Sided.Remaining>
          <img
            src={cdnImageUrl + 'post-logo/post-dark.png'}
            width='111px'
            height='56px'
            alt='logo post'
          />
        </Sided.Remaining>
      </Sided.Sided>
    </Wrapper>
  )

  const renderFeatures = () => {
    return (
      <Wrapper padding='8px' minHeight='302px'>
        <Break height={10} />
        {premiumFeatures &&
          toGrid(premiumFeatures, 1).map((row, index) => (
            <React.Fragment key={index}>
              <Sided.Sided gutter={24} justify='center'>
                {row.map((col, i) => (
                  <Sided.Remaining key={i}>
                    <Sided.Sided gutter={16}>
                      <Sided.Fixed>
                        <Wrapper padding='3px'>
                          <Icon.Checklist color='green' />
                        </Wrapper>
                      </Sided.Fixed>
                      <Sided.Remaining>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            height: '100%',
                          }}
                        >
                          <span style={{ fontSize: '12px' }}>{col}</span>
                        </div>
                      </Sided.Remaining>
                    </Sided.Sided>
                  </Sided.Remaining>
                ))}
              </Sided.Sided>
              <Break height={6} />
            </React.Fragment>
          ))}
      </Wrapper>
    )
  }

  const featuresBox = () => {
    return (
      <Wrapper
        style={{
          backgroundColor: '#fff',
          padding: '35px 25px',
          border: '1px solid #e0e0e0',
          borderRadius: '4px',
        }}
      >
        <Text.Span color='black' size={16} weight='bold'>
          Majukan bisnis kamu dengan fitur lengkap POST.
        </Text.Span>
        {renderFeatures()}
        <Text.Heading color='black' h={4}>
          Upgrade ke Premium Sekarang!
        </Text.Heading>
      </Wrapper>
    )
  }

  const handleCreateSubscription = async () => {
    netcore.dispatchEvent('subscription_continue')
    const subscriptionRequest = {
      packagePricingId: selectedPackageId,
      voucherCode: appliedVoucher.code,
    }

    if (totalPrice() === 0) {
      try {
        await subscriptionService.applySubscription(subscriptionRequest)
        setAllStateToNull()
        handleFetchSubscriptions()
        subscriptionService.checkSubscriptionAlert()
        commonService.setAlert({
          type: 'success',
          message: 'Subscription telah berhasil!',
        })
      } catch (error) {
        if (error.code === 'subscription_payment_pending_exist') {
          commonService.setAlert({
            type: 'error',
            message: 'Terdapat subscription yang belum dibayar',
          })
        }
        if (error.code === 'nicepay_registration_failed') {
          commonService.setAlert({
            type: 'error',
            message: 'Gagal membuat pembayaran, silahkan dicoba lagi',
          })
        }
      }
      setAllStateToNull()
    } else {
      const paymentMethod = await DefaultModalService.open(SubsPaymentMethod, {
        selectedPackage: selectedPackage,
        voucher: appliedVoucher,
        totalPrice: totalPrice(),
        singleMonthPrice: singleMonthPrice,
      })
      if (paymentMethod !== 'cancelled') {
        try {
          await subscriptionService.applySubscription(
            Object.assign(subscriptionRequest, paymentMethod)
          )
          setAllStateToNull()
          handleFetchSubscriptions()
          subscriptionService.checkSubscriptionAlert()
        } catch (error) {
          if (error.code === 'subscription_payment_pending_exist') {
            commonService.setAlert({
              type: 'error',
              message: 'Terdapat subscription yang belum dibayar',
            })
          }
          if (error.code === 'validation_error') {
            commonService.setAlert({
              type: 'error',
              message: 'Metode pembayaran harus dipilih',
            })
          }
          if (error.code === 'nicepay_registration_failed') {
            commonService.setAlert({
              type: 'error',
              message: 'Gagal membuat pembayaran, silahkan dicoba lagi',
            })
          }
        }
        setAllStateToNull()
      }
    }
  }

  const checkMonthCount = (packageId: string) => {
    switch (packageId) {
      case 'premium_1m':
        return 1
      case 'premium_3m':
        return 3
      case 'premium_6m':
        return 6
      case 'premium_12m':
        return 12
      default:
        return 1
    }
  }

  const handleSetSelectedPackage = async (packageId: string) => {
    const selectedPackage = availablePackages.find(x => x.id === packageId)
    setSelectedPackageId(packageId)
    setSelectedPackage(selectedPackage)
    setMonthCount(checkMonthCount(packageId))
    setErrorMessage('')
    if (appliedVoucher && appliedVoucher.code) {
      try {
        const voucher = await subscriptionService.checkVoucher(
          appliedVoucher.code,
          {
            packagePricingId: packageId,
          }
        )
        setAppliedVoucher(voucher)
        setErrorMessage('')
      } catch (error) {
        if (error.message) {
          setErrorMessage(error.message)
        } else {
          setErrorMessage(
            'Kode kupon tidak berlaku untuk paket ini / kode kupon tidak ditemukan'
          )
        }
      }
    }
  }

  const handleInputVoucher = (voucherCode: string) => {
    setCode(voucherCode.toUpperCase())
    setErrorMessage('')
  }

  const checkVoucher = async (code: string) => {
    try {
      const voucher = await subscriptionService.checkVoucher(code, {
        packagePricingId: selectedPackageId,
      })
      netcore.dispatchEvent('subscription_voucher_complete', {
        voucher_code: code,
        success: 'yes',
        user_type: subscriptionAlert.subscriptionType,
      })
      setAppliedVoucher(voucher)
      setErrorMessage('')
    } catch (error) {
      netcore.dispatchEvent('subscription_voucher_complete', {
        voucher_code: code,
        success: 'no',
        user_type: subscriptionAlert.subscriptionType,
      })
      setErrorMessage(error.message)
    }
  }

  const removeVoucher = function () {
    setAppliedVoucher({} as SubscriptionVoucher)
    setErrorMessage('')
    setCode('')
    setShowVoucherField(true)
  }

  const renderAppliedVoucher = () => {
    if (errorMessage && errorMessage.length > 0) {
      return (
        <>
          <Wrapper
            style={{
              backgroundColor: '#eed5d3',
              border: '1px solid #ac2c24',
              borderRadius: '2px',
              padding: '10px',
            }}
          >
            <Sided.Sided>
              <Sided.Remaining>
                <Text.Span color='black' size={12} weight='bold'>
                  {appliedVoucher.code}
                </Text.Span>
              </Sided.Remaining>
              <Sided.Remaining align='right'>
                <Icon.Close24 onClick={() => removeVoucher()} color='black' />
              </Sided.Remaining>
            </Sided.Sided>
          </Wrapper>
          {errorMessage && (
            <Text.Span size={12} color='red'>
              {errorMessage}
            </Text.Span>
          )}
        </>
      )
    } else {
      return (
        <Wrapper
          style={{
            backgroundColor: 'rgba(59, 142, 243, 0.1)',
            border: '1px solid #3b8ef3',
            borderRadius: '2px',
            padding: '10px',
          }}
        >
          <Sided.Sided>
            <Sided.Remaining>
              <Text.Span color='black' size={12} weight='bold'>
                {appliedVoucher.code}
              </Text.Span>
            </Sided.Remaining>
            <Sided.Remaining align='right'>
              <Sided.Sided>
                <Sided.Remaining align='right'>
                  <Text.Span color='red' size={12} weight='bold'>
                    -{toCurrency('Rp', appliedVoucher.amount)}
                  </Text.Span>
                </Sided.Remaining>
                <Sided.Fixed align='right' minWidth={25}>
                  <Icon.Close24 onClick={() => removeVoucher()} color='black' />
                </Sided.Fixed>
              </Sided.Sided>
            </Sided.Remaining>
          </Sided.Sided>
        </Wrapper>
      )
    }
  }

  const totalPrice = () => {
    if (selectedPackage.price > 0) {
      if (appliedVoucher && appliedVoucher.amount > 0) {
        return selectedPackage.price - appliedVoucher.amount
      } else {
        return selectedPackage.price
      }
    } else {
      return 0
    }
  }

  const handleCouponValue = async () => {
    netcore.dispatchEvent('subscription_savenow_click')
    const couponValueName: firebase.remoteConfig.Value =
      await firebaseFunc.getRemoteConfigValue('voucher_coupon_name')
    const couponValueAmount: firebase.remoteConfig.Value =
      await firebaseFunc.getRemoteConfigValue('voucher_coupon_amount')
    if (couponValueName && couponValueAmount) {
      setAppliedVoucher({
        code: couponValueName.asString(),
        amount: couponValueAmount.asNumber(),
        percentage: 0,
      })
    }
    try {
      const voucher = await subscriptionService.checkVoucher(
        couponValueName.asString(),
        {
          packagePricingId: selectedPackageId,
        }
      )
      setAppliedVoucher(voucher)
      setErrorMessage('')
    } catch (error) {
      if (error.message) {
        setErrorMessage(capitalizeFirstLetter(error.message))
      } else {
        setErrorMessage(
          'Kode kupon tidak berlaku untuk paket ini / kode kupon tidak ditemukan'
        )
      }
      setAppliedVoucher({
        code: couponValueName.asString(),
        amount: 0,
        percentage: 0,
      })
    }
  }

  const haveAnotherCoupon = () => {
    netcore.dispatchEvent('subscription_other_coupon')
    setShowVoucherField(true)
  }

  const checkIsSpecificPlan = () => {
    if (selectedPackageId === specificPlanId) {
      return true
    } else {
      return false
    }
  }

  const packageBox = () => {
    return (
      <Wrapper
        style={{
          backgroundColor: '#fff',
          padding: '35px 25px',
          border: '1px solid #e0e0e0',
          borderRadius: '4px',
        }}
      >
        <Text.Span color='black' size={16} weight='bold'>
          Pilih Paket
        </Text.Span>
        <Break height={12} />
        {availablePackagesDropdownList.length > 0 && (
          <Field.Dropdown
            name='packageId'
            placeholder='Pilih Paket'
            list={availablePackagesDropdownList}
            onChange={value => handleSetSelectedPackage(value)}
            value={
              availablePackagesDropdownList.find(
                x => x.value === 'premium_12m'
              ) &&
              availablePackagesDropdownList.find(x => x.value === 'premium_12m')
                .value
            }
          />
        )}
        <Break height={6} />
        <Text.Span color='black' size={16} weight='bold'>
          Outlet
        </Text.Span>
        <Break height={10} />
        <Sided.Sided>
          <Sided.Remaining>
            <Text.Span color='black' size={12}>
              Semua Outlet
            </Text.Span>
          </Sided.Remaining>
          <Sided.Remaining align='right'>
            {singleMonthPrice > 0 && checkIsSpecificPlan() === false && (
              <>
                <Text.Span
                  color='red'
                  size={12}
                  style={{ textDecoration: 'line-through' }}
                >
                  {toCurrency('Rp ', singleMonthPrice)}
                </Text.Span>
                &nbsp;&nbsp;&nbsp;
              </>
            )}
            {checkIsSpecificPlan() === true && (
              <>
                <Text.Span
                  color='red'
                  size={12}
                  style={{ textDecoration: 'line-through' }}
                >
                  {toCurrency('Rp ', specificPlanPrice)}
                </Text.Span>
                &nbsp;&nbsp;&nbsp;
              </>
            )}
            <Text.Span color='black' size={14} weight='bold'>
              {toCurrency('Rp', selectedPackage.price / monthCount)}/bulan
            </Text.Span>
          </Sided.Remaining>
        </Sided.Sided>
        <Break height={18} />
        {appliedVoucher && appliedVoucher.code ? (
          renderAppliedVoucher()
        ) : saveNowVoucher && !showVoucherField && isVoucherShow ? (
          <Sided.Sided justifyContent='center'>
            <Sided.Fixed minWidth={300}>
              <Wrapper
                style={{
                  backgroundColor: '#001e3a',
                  padding: '10px',
                  borderRadius: '4px',
                }}
              >
                <Sided.Sided justifyContent='center'>
                  <Sided.Fixed minWidth={180}>
                    <Text.Span color='white' size={10}>
                      Extra hemat {toCurrency('Rp', saveNowVoucherAmount)}
                      <br />
                      dengan kode kupon {saveNowVoucherName}
                    </Text.Span>
                  </Sided.Fixed>
                  <Sided.Remaining
                    align='right'
                    style={{
                      borderLeft: '3px solid #fff',
                      paddingLeft: '7.5px',
                      paddingRight: '7.5px',
                    }}
                  >
                    <Text.Span
                      color='white'
                      size={12}
                      weight='bold'
                      style={{ lineHeight: '40px', cursor: 'pointer' }}
                      onClick={() => handleCouponValue()}
                    >
                      SAVE NOW
                    </Text.Span>
                  </Sided.Remaining>
                </Sided.Sided>
              </Wrapper>
            </Sided.Fixed>
            <Sided.Remaining align='right'>
              <Text.Paragraph
                size={12}
                align='right'
                color='blue'
                style={{ lineHeight: '55px', cursor: 'pointer' }}
                onClick={() => haveAnotherCoupon()}
              >
                Punya kode kupon?
              </Text.Paragraph>
            </Sided.Remaining>
          </Sided.Sided>
        ) : (
          <>
            <Text.Span color='black' size={16} weight='bold'>
              Kode Kupon
            </Text.Span>
            <Break height={10} />
            <Sided.Sided justifyContent='center'>
              <Sided.Fixed minWidth={300}>
                <Field.Text
                  name='couponName'
                  placeholder='Nama Kupon'
                  onChange={value => handleInputVoucher(value)}
                  uppercase={true}
                />
                {errorMessage && (
                  <Text.Span
                    size={12}
                    color='red'
                    style={{ position: 'relative', top: '-15px' }}
                  >
                    {errorMessage}
                  </Text.Span>
                )}
              </Sided.Fixed>
              <Sided.Remaining>
                <Button
                  fullW
                  theme='secondaryOutline'
                  label='Gunakan'
                  disabled={code.length === 0}
                  onClick={() => checkVoucher(code)}
                />
              </Sided.Remaining>
            </Sided.Sided>
          </>
        )}
        <Break height={14} />
        <Wrapper
          style={{
            backgroundColor: '#f2f4f5',
            borderRadius: '0px 0px 4px 4px',
            padding: '10px',
          }}
        >
          <Sided.Sided>
            <Sided.Remaining>
              <Text.Span color='black' size={12} weight='bold'>
                Total
              </Text.Span>
            </Sided.Remaining>
            <Sided.Remaining align='right'>
              {singleMonthPrice > 0 &&
                selectedPackageId &&
                checkIsSpecificPlan() === false && (
                  <>
                    <Text.Span
                      color='red'
                      size={12}
                      style={{ textDecoration: 'line-through' }}
                    >
                      {toCurrency('Rp', singleMonthPrice * monthCount)}
                    </Text.Span>
                    &nbsp;&nbsp;&nbsp;
                  </>
                )}
              {singleMonthPrice === 0 &&
                appliedVoucher.code &&
                appliedVoucher.code.length > 0 &&
                checkIsSpecificPlan() === false && (
                  <>
                    <Text.Span
                      color='red'
                      size={12}
                      style={{ textDecoration: 'line-through' }}
                    >
                      {toCurrency('Rp', selectedPackage.price)}
                    </Text.Span>
                    &nbsp;&nbsp;&nbsp;
                  </>
                )}
              {selectedPackageId && checkIsSpecificPlan() === true && (
                <>
                  <Text.Span
                    color='red'
                    size={12}
                    style={{ textDecoration: 'line-through' }}
                  >
                    {toCurrency('Rp', specificPlanPrice * monthCount)}
                  </Text.Span>
                  &nbsp;&nbsp;&nbsp;
                </>
              )}
              <Text.Span color='black' size={14} weight='bold'>
                {toCurrency(
                  'Rp',
                  totalPrice() < 0 ? 0 : Math.abs(totalPrice())
                )}
              </Text.Span>
            </Sided.Remaining>
          </Sided.Sided>
          {singleMonthPrice === 0 &&
            appliedVoucher.code &&
            appliedVoucher.code.length > 0 &&
            checkIsSpecificPlan() === false && (
              <Wrapper align='right'>
                <Text.Span color='green' size={12} weight='bold'>
                  Hemat{' '}
                  {Math.trunc(
                    Math.ceil(
                      (appliedVoucher.amount / selectedPackage.price) * 100 >
                        100
                        ? 100
                        : (appliedVoucher.amount / selectedPackage.price) * 100
                    )
                  )}
                  %
                </Text.Span>
              </Wrapper>
            )}
          {singleMonthPrice > 0 &&
            selectedPackageId &&
            checkIsSpecificPlan() === false && (
              <Wrapper align='right'>
                <Text.Span color='green' size={12} weight='bold'>
                  Hemat{' '}
                  {Math.trunc(
                    Math.ceil(
                      ((singleMonthPrice * monthCount -
                        (totalPrice() < 0 ? 0 : Math.abs(totalPrice()))) /
                        (singleMonthPrice * monthCount)) *
                        100
                    )
                  )}
                  %
                </Text.Span>
              </Wrapper>
            )}
          {selectedPackageId && checkIsSpecificPlan() === true && (
            <Wrapper align='right'>
              <Text.Span color='green' size={12} weight='bold'>
                Hemat{' '}
                {Math.trunc(
                  Math.ceil(
                    ((specificPlanPrice * monthCount -
                      (totalPrice() < 0 ? 0 : Math.abs(totalPrice()))) /
                      (specificPlanPrice * monthCount)) *
                      100
                  )
                )}
                %
              </Text.Span>
            </Wrapper>
          )}
        </Wrapper>
        <Break height={14} />
        <Button
          fullW
          theme='primary'
          label='Langganan Sekarang'
          disabled={errorMessage.length > 0 || selectedPackageId.length < 1}
          onClick={() => handleCreateSubscription()}
        />
      </Wrapper>
    )
  }

  const handleFetchSubscriptions = async () => {
    const subscriptions = await subscriptionService.getSubscriptions()
    const unpaidSubs = subscriptions.find(
      x => x.status.toUpperCase() === 'WAITING_PAYMENT'
    )
    if (unpaidSubs) {
      await subscriptionService.getSubscriptionDetail(unpaidSubs.id)
    }
  }

  const renderSingleSubscription = (subscription: Subscription) => {
    const payment =
      subscription.payment || ({} as SubscriptionPaymentInformation)
    const badge = getSubscriptionBadge(subscription)

    return (
      <React.Fragment key={subscription.id}>
        <Panel.Card padding={16}>
          <Sided.Sided justify='center'>
            <Sided.Fixed minWidth={150}>
              {subscription.startDate && (
                <Text.Paragraph size={14} color='semiGray'>
                  {moment(subscription.payment?.createdAt).format(
                    'DD MMM YYYY HH:mm'
                  )}
                </Text.Paragraph>
              )}
              <Text.Paragraph size={14} color='brownGray' className='bottom-p'>
                {payment.invoiceCode}
              </Text.Paragraph>
            </Sided.Fixed>
            <Sided.Remaining>
              <Text.Heading h={6}>
                Paket {subscription.packagePricing.label}
              </Text.Heading>
              {subscription.startDate && (
                <Text.Paragraph size={14} className='bottom-p'>
                  Masa Berlaku:{' '}
                  {moment(subscription.startDate).format('DD MMM YYYY')} -{' '}
                  {moment(subscription.endDate).format('DD MMM YYYY')}
                </Text.Paragraph>
              )}
            </Sided.Remaining>
            <Sided.Remaining>
              {payment.paymentAmount && payment.paymentAmount !== 0 ? (
                <Text.Heading h={6}>
                  {toCurrency(
                    'Rp',
                    payment.paymentAmount < 0 ? 0 : payment.paymentAmount
                  )}
                </Text.Heading>
              ) : (
                <Text.Heading h={6}>Rp 0</Text.Heading>
              )}
            </Sided.Remaining>
            <Sided.Fixed minWidth={105} align='center'>
              <Badge labelColor={badge.color} text={badge.status} fullW />
            </Sided.Fixed>
            <FeatureFlag
              featureCheck={featureCheck.setting.subscriptionInvoice}
            >
              <Sided.Fixed minWidth={105} align='right' verticalAlign='center'>
                {(subscription.status === 'active' ||
                  subscription.status === 'ended') && (
                  <InvoicePdf subscription={subscription} />
                )}
              </Sided.Fixed>
            </FeatureFlag>
          </Sided.Sided>
        </Panel.Card>
        <Break height={16} />
      </React.Fragment>
    )
  }

  const renderSubscriptionHistory = () => (
    <Panel.Panel>
      <Panel.Header>
        <Text.Heading h={5}>Daftar Riwayat Berlangganan</Text.Heading>
      </Panel.Header>
      <Panel.Content className='post-subscribe-history-listings'>
        {subscriptionList.map(renderSingleSubscription)}
      </Panel.Content>
    </Panel.Panel>
  )

  const subscriptionHistoryBox = () => {
    return (
      <>
        <Text.Heading color='black' h={4}>
          Riwayat Berlangganan
        </Text.Heading>
        <Break height={16} />
        {renderSubscriptionHistory()}
      </>
    )
  }

  const copyToClipboard = () => {
    const copyText = document.querySelector('#paymentAccountNum')
    const textArea = document.createElement('textarea')
    textArea.value = copyText.textContent
    document.body.append(textArea)
    textArea.select()
    document.execCommand('Copy')
    textArea.remove()
  }

  const handleCancelSubscription = async (id: string) => {
    await subscriptionService.cancelSubscription(currentSubscription.id)
    netcore.dispatchEvent('subscription_cancel_start', {
      source: 'detail_after_subscription',
      plan_chosen: currentSubscription.packagePricing.id,
      voucher_code: currentSubscription.payment.voucherCode,
      payment_method: currentSubscription.payment.paymentChannel.id,
      user_type: subscriptionAlert.subscriptionType,
    })
    setTriggerCount(triggerCount + 1)
    setAllStateToNull()
  }

  const subscriptionPaymentReceipt = () => {
    return (
      <Wrapper
        style={{
          padding: '15px',
          border: '1px solid #e0e0e0',
          borderRadius: '4px',
          backgroundColor: '#fff',
        }}
      >
        <Text.Heading color='black' h={6}>
          Selesaikan Pembayaran
        </Text.Heading>
        <Text.Span size={14}>
          Sebelum{' '}
          {moment(
            currentSubscription && currentSubscription.payment.paymentExpiredAt
          )
            .locale('id')
            .format('LLL')}{' '}
          WIB
        </Text.Span>
        <Break height={20} />
        <Wrapper width='200px' margin='0px auto'>
          <Countdown.Time
            endTime={moment(
              currentSubscription &&
                currentSubscription.payment.paymentExpiredAt
            ).toDate()}
          />
        </Wrapper>
        <Break height={12} />
        <Panel.Panel>
          <Panel.Content padding={16}>
            <Break height={16} />
            <Sided.Sided>
              <Sided.Remaining>
                <Text.Heading color='black' h={6}>
                  Pembayaran ke Virtual Account
                </Text.Heading>
              </Sided.Remaining>
              <Sided.Remaining></Sided.Remaining>
            </Sided.Sided>
            <Break height={6} />
            <Sided.Sided>
              <Sided.Remaining>
                <Text.Span color='black' size={12}>
                  Bank
                </Text.Span>
              </Sided.Remaining>
              <Sided.Remaining align='right'>
                <Text.Span color='black' size={12}>
                  <img
                    src={
                      currentSubscription &&
                      currentSubscription.payment.paymentChannel.imageUrl
                    }
                    alt='logo_bank'
                    style={{ width: '80px' }}
                  />
                </Text.Span>
              </Sided.Remaining>
            </Sided.Sided>
            <Break height={6} />
            <Sided.Sided>
              <Sided.Remaining>
                <Text.Span color='black' size={12}>
                  Nomor Virtual Account
                </Text.Span>
              </Sided.Remaining>
              <Sided.Remaining align='right'>
                <Sided.Sided>
                  <Sided.Remaining align='right'>
                    <Text.Heading h={6}>
                      <span id='paymentAccountNum'>
                        {currentSubscription &&
                          currentSubscription.payment.paymentAccountNo}
                      </span>
                    </Text.Heading>
                  </Sided.Remaining>
                  <Sided.Fixed
                    minWidth={30}
                    align='right'
                    verticalAlign='center'
                  >
                    <Text.Paragraph
                      size={12}
                      noMargin
                      color='blue'
                      onClick={() => copyToClipboard()}
                    >
                      Salin
                    </Text.Paragraph>
                  </Sided.Fixed>
                </Sided.Sided>
              </Sided.Remaining>
            </Sided.Sided>
            <Break height={6} />
            <Sided.Sided>
              <Sided.Remaining>
                <Text.Span color='black' size={12}>
                  Cara Bayar Virtual Account
                </Text.Span>
              </Sided.Remaining>
              <Sided.Remaining align='right'>
                <Text.Paragraph
                  size={12}
                  align='right'
                  color='blue'
                  onClick={() =>
                    DefaultModalService.open(StepPaymentVirtualAccount, {
                      payment: currentSubscription.payment,
                    })
                  }
                >
                  Lihat Detail
                </Text.Paragraph>
              </Sided.Remaining>
            </Sided.Sided>
          </Panel.Content>
        </Panel.Panel>
        <Break height={12} />
        <Panel.Panel>
          <Panel.Content padding={16}>
            <Sided.Sided>
              <Sided.Remaining>
                <Text.Span color='black' size={12}>
                  {currentSubscription &&
                    currentSubscription.payment.invoiceCode}
                </Text.Span>
              </Sided.Remaining>
              <Sided.Remaining></Sided.Remaining>
            </Sided.Sided>
            <Break height={6} />
            <Sided.Sided>
              <Sided.Remaining>
                <Text.Span color='black' size={12}>
                  {currentSubscription &&
                    currentSubscription.packagePricing.label}
                </Text.Span>
              </Sided.Remaining>
              <Sided.Remaining align='right'>
                <Text.Span color='black' size={12}>
                  {toCurrency(
                    'Rp',
                    currentSubscription &&
                      currentSubscription.packagePricing.price
                  )}
                </Text.Span>
              </Sided.Remaining>
            </Sided.Sided>
            <Break height={6} />
            {currentSubscription &&
              currentSubscription.payment.voucherCode &&
              currentSubscription.payment.voucherCode.length > 0 && (
                <Sided.Sided>
                  <Sided.Remaining>
                    <Text.Span color='black' size={12}>
                      {currentSubscription.payment.voucherCode}
                    </Text.Span>
                  </Sided.Remaining>
                  <Sided.Remaining align='right'>
                    <Text.Span color='red' size={12}>
                      -{' '}
                      {toCurrency(
                        'Rp',
                        currentSubscription.payment.voucherAmount
                      )}
                    </Text.Span>
                  </Sided.Remaining>
                </Sided.Sided>
              )}
          </Panel.Content>
          <Panel.Footer bgColor='gray' padding={16}>
            <Sided.Sided>
              <Sided.Remaining>
                <Text.Heading h={6}>Total Tagihan</Text.Heading>
              </Sided.Remaining>
              <Sided.Remaining>
                <Text.Heading h={6} align='right'>
                  {toCurrency(
                    'Rp',
                    currentSubscription &&
                      currentSubscription.payment.paymentAmount
                  )}
                </Text.Heading>
              </Sided.Remaining>
            </Sided.Sided>
          </Panel.Footer>
        </Panel.Panel>
        <Break height={12} />
        <Text.Heading
          h={6}
          color='red'
          onClick={() =>
            handleCancelSubscription(
              currentSubscription && currentSubscription.id
            )
          }
          align='center'
        >
          Batalkan Transaksi
        </Text.Heading>
      </Wrapper>
    )
  }

  const subscriptionStatusBox = () => {
    return (
      <>
        <Sided.Sided>
          <Sided.Remaining>
            <Sided.Sided>
              <Sided.Fixed minWidth={200}>
                <Text.Heading color='black' h={4}>
                  Status Berlangganan
                </Text.Heading>
              </Sided.Fixed>
              <Sided.Remaining align='left'>{badge}</Sided.Remaining>
            </Sided.Sided>
            <Break height={16} />
            <Text.Span color='semiGray' size={12}>
              Paket berlangganan&nbsp;:&nbsp;
              {capitalizeFirstLetter(
                subscriptionAlert && subscriptionAlert.subscriptionType
              )}
            </Text.Span>
            <Break height={6} />
            <Text.Span color='semiGray' size={12}>
              Aktif sampai&nbsp;:&nbsp;
              {subscriptionAlert && subscriptionAlert.endDate !== null
                ? moment(subscriptionAlert && subscriptionAlert.endDate).format(
                    'DD MMMM YYYY'
                  )
                : 'Tanpa Batas'}
            </Text.Span>
            <Break height={40} />
            <Break height={20} />
          </Sided.Remaining>
          <Sided.Remaining align='center'>
            {currentSubscription && subscriptionPaymentReceipt()}
          </Sided.Remaining>
        </Sided.Sided>
      </>
    )
  }

  const renderSubsStatusBox = () => {
    if (
      (isTrial === false && isPremium === true && currentSubscription) ||
      (isTrial === true && isPremium === true) ||
      (isTrial === false && isPremium === false)
    ) {
      return (
        <>
          {subscriptionStatusBox()}
          <Break height={24} />
          <hr style={{ border: '1px solid #e0e0e0' }} />
        </>
      )
    }
    return null
  }

  return (
    <Modal dismiss={props.dismiss} type='full' canDismissOutside={false}>
      <VSided.VSided gutter={32}>
        <VSided.Fixed>{renderHeaderSubscribe()}</VSided.Fixed>
        <VSided.Remaining>
          <Wrapper width='1000px' isCenter>
            <Text.Heading color='black' h={4}>
              Langganan
            </Text.Heading>
            <Break height={24} />

            {!currentSubscription && (
              <>
                <Sided.Sided justifyContent='center'>
                  {(!isTrial === false && isPremium) ||
                  (isTrial === false && isPremium === false) ? (
                    <Sided.Fixed minWidth={500}>{featuresBox()}</Sided.Fixed>
                  ) : (
                    <Sided.Fixed minWidth={500}>
                      {subscriptionStatusBox()}
                    </Sided.Fixed>
                  )}
                  <Sided.Remaining>{packageBox()}</Sided.Remaining>
                </Sided.Sided>
                <Break height={24} />
                <hr style={{ border: '1px solid #e0e0e0' }} />
              </>
            )}
            <Break height={24} />
            {renderSubsStatusBox()}
            <Break height={24} />
            {subscriptionHistoryBox()}
            <Break height={24} />
          </Wrapper>
        </VSided.Remaining>
      </VSided.VSided>
    </Modal>
  )
}

export { SubscriptionComponent }
