import { BaseApi } from 'api/base.api'
import {
  ServiceType,
  CreateServiceTypeRequest,
} from 'domains/management/serviceType/models'
import { IServiceTypeApi } from 'domains/management/serviceType/contracts/api'

export default class ServiceTypeApi extends BaseApi implements IServiceTypeApi {
  constructor() {
    super('/v2/service-types')
  }

  public async getServiceTypes(): Promise<ServiceType[]> {
    return await this.makeForCamelCase('GET', '')
  }

  public async createServiceType(
    query: CreateServiceTypeRequest
  ): Promise<ServiceType> {
    return await this.makeForCamelCase('POST', '', query)
  }

  public async updateServiceType(body: ServiceType): Promise<ServiceType> {
    return await this.makeForCamelCase('POST', `/${body.id}`, body)
  }

  public async deleteServiceType(id: string) {
    return await this.makeForCamelCase('DELETE', id)
  }
}
