import $ from 'jquery'

export interface ExtractedFileData {
  file: File
  base64: string
  binary: string
}

export async function extractFile(file: File): Promise<ExtractedFileData> {
  const base64 = await readFileAsBase64(file)
  const binary = await readFileAsArrayBuffer(file)
  return {
    file,
    base64,
    binary,
  }
}

export function readFileAsBase64(file: File): Promise<string> {
  return new Promise<string>((res, reject) => {
    const reader = new FileReader()
    reader.onload = (e: any) => {
      if (!e.target) {
        return
      }
      res(e.target.result)
    }
    reader.onerror = error => {
      reject(error)
    }
    reader.readAsDataURL(file)
  })
}

export function readFileAsArrayBuffer(file: File): Promise<string> {
  return new Promise<string>((res, reject) => {
    const reader = new FileReader()
    reader.onload = (e: any) => {
      if (!e.target) {
        return
      }
      const bytes = new Uint8Array(e.target.result)
      const length = bytes.byteLength
      let binary = ''
      for (let i = 0; i < length; i++) {
        binary += String.fromCharCode(bytes[i])
      }
      res(binary)
    }
    reader.onerror = error => {
      reject(error)
    }
    reader.readAsArrayBuffer(file)
  })
}

export default {
  extractFile,
  readFileAsBase64,
  readFileAsArrayBuffer,
}

export async function triggerFileUpload(
  xls?: any,
  options?: any
): Promise<ExtractedFileData> {
  if (xls) {
    return extractFile(xls)
  }
  let uploadFileButton = $(`<input id="sf-input-file" type="file" />`)
  if (options) {
    uploadFileButton = $(
      `<input id="sf-input-file" type="file" accept="${
        options.acceptedFile || ''
      }" />`
    )
  }
  return new Promise<ExtractedFileData>((res, reject) => {
    uploadFileButton.on('change', async function (evt: JQueryEventObject) {
      const target = evt.target as HTMLInputElement
      const file: File | undefined = target.files ? target.files[0] : undefined
      if (!file) {
        reject()
        return
      }
      res(extractFile(file))
    })
    uploadFileButton.click()
  })
}
