import { AppState } from 'domains/contracts/appState'
import { UserState } from 'domains/users/states'
import { SubscriptionAlert } from 'domains/subscriptions/model'
import {
  RouteDescription,
  toRouteDescription,
} from 'domains/layouts/services/route.item'
import { EmployeeState } from 'domains/management/employee/states'
import { RouteItemV2 } from 'route'

export interface SidebarViewModel {
  user: UserState
  subscriptionAlert: SubscriptionAlert
  selectedRoute?: RouteDescription
  selectedRouteV2?: RouteItemV2
  isChildVisible: boolean
  isChildVisibleV2: boolean
  employee: EmployeeState
}

export default (state: AppState): SidebarViewModel => {
  const subscriptionAlert = state.subscriptions.subscriptionAlert
  const currentRoute = state.layout.sidebarState.currentRoute
  const currentRouteV2 = state.layout.sidebarState.currentRouteV2
  const isChildVisible = !!state.layout.sidebarState.currentRoute
  const isChildVisibleV2 = !!state.layout.sidebarState.currentRouteV2
  const employee = state.management.employee

  return {
    isChildVisible,
    isChildVisibleV2,
    selectedRoute: currentRoute ? toRouteDescription(currentRoute) : null,
    selectedRouteV2: currentRouteV2 ? currentRouteV2 : null,
    user: state.user,
    subscriptionAlert,
    employee,
  }
}
