import { CONFIG_LOCALSTORAGE } from 'constants/localStorage'

export const getLocalStorage = (name: string) => localStorage.getItem(name)

export const setLocalStorage = (name: string, value: string) => {
  localStorage.setItem(name, value)
}

export const clearLocalStorage = async () => {
  // await localStorage.clear()
  await localStorage.removeItem('_postDashboardAccessType')
  await localStorage.removeItem(CONFIG_LOCALSTORAGE.POST_TOKEN)
  await localStorage.removeItem(CONFIG_LOCALSTORAGE.POST_TYPE)
  await localStorage.removeItem(CONFIG_LOCALSTORAGE.STAFF_ID)
  await localStorage.removeItem(CONFIG_LOCALSTORAGE.STAFF_OUTLET_ID)
  await localStorage.removeItem(CONFIG_LOCALSTORAGE.STAFF_BUSINESS_CATEGORY_ID)
  await localStorage.removeItem(CONFIG_LOCALSTORAGE.STAFF_OUTLET_NAME)
  await localStorage.removeItem(CONFIG_LOCALSTORAGE.STAFF_PERMISSION_LIST)
  await localStorage.removeItem(CONFIG_LOCALSTORAGE.IS_ONBOARDING_STEP2_CLOSED)
  await localStorage.removeItem(
    CONFIG_LOCALSTORAGE.IS_BUSINESS_INFO_NOTIF_CLOSED
  )
  await localStorage.removeItem(CONFIG_LOCALSTORAGE.USER_CREATED_DATE)
  await localStorage.removeItem(
    CONFIG_LOCALSTORAGE.IS_USER_FILLED_BUSINESS_INFO
  )
  await localStorage.removeItem(CONFIG_LOCALSTORAGE.IS_SKIP_EASYORDER_ONBOARD)
  // await localStorage.removeItem("_postIsOnboardingSkipped");
}
