import classNames from 'classnames'
import React, { CSSProperties } from 'react'

import './index.scss'

interface FazzLoaderProps {
  position?: 'relative' | 'absolute'
  style?: React.CSSProperties
  className?: string
  withBackground?: boolean
  size?: 16 | 24
}

const Loader: React.FC<FazzLoaderProps> = props => {
  const className = classNames(
    'post-loader-ring',
    props.position || 'absolute',
    'size-' + props.size,
    props.className
  )

  const style: CSSProperties = {
    backgroundColor: props.withBackground ? '#FFFFFF' : 'unset',
    borderRadius: props.withBackground ? '4px' : 'unset',
    ...props.style,
  }

  return (
    <div className={className} style={style}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  )
}

export default Loader
