import { AppsflyerRequest } from 'domains/appsflyer/models'
import {
  SubscriptionPackagePricing,
  SubscriptionVoucher,
} from 'domains/subscriptions/model'
import { appsflyerService, subscriptionService, userService } from 'injectors'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import appsflyer from 'utilities/appflyers'
import { toCurrency } from 'utilities/converter/currency'
import netcore from 'utilities/netcore'
import { Panel, Sided, Text, Button, Break, Wrapper } from 'views/components'
import { ModalProps, Modal } from 'views/components/Modal'
import selector, { translateAvailablePackage } from '../../selector'
import './style.scss'

interface Props extends ModalProps<any> {
  selectedPackage: SubscriptionPackagePricing
  voucher: SubscriptionVoucher
  totalPrice: number
  singleMonthPrice: number
}

export const SubsPaymentMethod: React.FC<Props> = props => {
  useEffect(() => {
    userService.getUser()
    subscriptionService.getSubscriptionMethod()
  }, [])

  const { subscriptionMethod, subscriptionAlert, userInfo } =
    useSelector(selector)
  const { selectedPackage, voucher, totalPrice } = props

  const [selectedPayment, setSelectedPayment] = useState('')
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState('')
  const [selectedChannelId, setSelectedChannelId] = useState('')

  const handleBankSelection = (status: string, paymentMethodId: string) => {
    setSelectedPaymentMethodId(paymentMethodId)
    setSelectedChannelId(status)
    setSelectedPayment(status)
  }

  const appsflyerTrackEvent = (request: AppsflyerRequest) => {
    appsflyerService.trackEvent(request)
  }

  const renderGirdBankListings = (
    status: string,
    bankImage: string,
    subscriptionMethodId: string
  ) => (
    <Wrapper
      padding='7px'
      shadow='0px 2px 4px rgba(0, 0, 0, 0.2)'
      isCorner
      onClick={() => handleBankSelection(status, subscriptionMethodId)}
      isSelected={status === selectedPayment}
    >
      <img src={bankImage} alt='logo_bank' style={{ width: '80px' }} />
    </Wrapper>
  )

  const GridbankListing = () => (
    <React.Fragment>
      {subscriptionMethod[0] &&
        subscriptionMethod[0].paymentChannels.map(item => (
          <>
            {renderGirdBankListings(
              item.id,
              item.imageUrl,
              subscriptionMethod[0].id
            )}
          </>
        ))}
    </React.Fragment>
  )

  const renderVADetail = () => {
    return (
      <Panel.Panel>
        <Panel.Content padding={16}>
          <Text.Heading h={5}>Virtual Account</Text.Heading>
          <Break height={16} />
          <Text.Span color='semiGray' size={14}>
            Konfirmasi otomatis 24 jam
          </Text.Span>
          <Break height={16} />
          <Wrapper className='grid-col-listings'>{GridbankListing()}</Wrapper>
        </Panel.Content>
      </Panel.Panel>
    )
  }

  const renderSubscriptionDetail = () => {
    return (
      <Panel.Panel>
        <Panel.Content padding={16}>
          <Text.Heading h={5}>Detail Berlangganan</Text.Heading>
          <Break height={16} />
          <Sided.Sided>
            <Sided.Remaining>
              <Text.Span color='black' size={14}>
                {translateAvailablePackage(selectedPackage)}
              </Text.Span>
            </Sided.Remaining>
            <Sided.Remaining align='right'>
              {props.singleMonthPrice > 0 && (
                <>
                  <Text.Span
                    color='red'
                    size={12}
                    weight='bold'
                    style={{ textDecoration: 'line-through' }}
                  >
                    {toCurrency('Rp ', props.singleMonthPrice)}
                  </Text.Span>
                  &nbsp;&nbsp;&nbsp;
                </>
              )}
              <Text.Span color='black' size={14}>
                {toCurrency('Rp', selectedPackage.price)}
              </Text.Span>
            </Sided.Remaining>
          </Sided.Sided>
          <Break height={6} />
          {voucher.code && voucher.code.length > 0 && (
            <Sided.Sided>
              <Sided.Remaining>
                <Text.Span color='black' size={14}>
                  {voucher.code}
                </Text.Span>
              </Sided.Remaining>
              <Sided.Remaining align='right'>
                <Text.Span color='red' size={14}>
                  - {toCurrency('Rp', voucher.amount)}
                </Text.Span>
              </Sided.Remaining>
            </Sided.Sided>
          )}
        </Panel.Content>
        <Panel.Footer bgColor='gray' padding={16}>
          <Sided.Sided>
            <Sided.Remaining>
              <Text.Heading h={5}>Total</Text.Heading>
            </Sided.Remaining>
            <Sided.Remaining>
              <Text.Heading h={5} align='right'>
                {totalPrice < 0 ? 0 : toCurrency('Rp', totalPrice)}
              </Text.Heading>
            </Sided.Remaining>
          </Sided.Sided>
        </Panel.Footer>
      </Panel.Panel>
    )
  }

  const handleChoosePaymentMethod = () => {
    netcore.dispatchEvent('subscription_complete', {
      voucher_code: voucher.code,
      plan_chosen: selectedPackage.id,
      payment_method: selectedPaymentMethodId,
      user_type: subscriptionAlert.subscriptionType,
      paid_amount: totalPrice,
    })
    appsflyer.dispatchEvent(
      'subscription_paid',
      `{"time_of_subscription": "${selectedPackage.id}"}`,
      totalPrice,
      'IDR'
    )
    appsflyerTrackEvent({
      deviceType: 'Web',
      action: 'PAY_PREMIUM_PKG',
      appsflyerData: JSON.stringify({
        package_name: selectedPackage.label,
        package_id: selectedPackage.id,
        package_duration: selectedPackage.duration,
        package_price: selectedPackage.price,
      }),
      appsflyerChannel: '',
      appsflyerId: '',
      username: userInfo.email,
    })
    const values = {
      paymentChannelId: selectedChannelId,
      paymentMethodId: selectedPaymentMethodId,
    }
    props.close(values)
  }

  return (
    <Modal
      dismiss={props.dismiss}
      canDismissOutside={false}
      type='blank'
      width='600px'
    >
      <Panel.Panel>
        <Panel.Content>
          <Wrapper width='500px' isCenter>
            <Break height={16} />
            <Text.Heading color='black' h={5}>
              Pilih Metode Pembayaran
            </Text.Heading>
            <hr style={{ border: '1px solid #e0e0e0' }} />
            <Break height={16} />
            {renderVADetail()}
            <Break height={16} />
            {renderSubscriptionDetail()}
            <Break height={16} />
            <Sided.Sided>
              <Sided.Remaining align='right'>
                <Button
                  fullW
                  type='button'
                  theme='secondaryOutline'
                  size='large'
                  label='BATAL'
                  onClick={() => props.close('cancelled')}
                />
              </Sided.Remaining>
              <Sided.Remaining>
                <Button
                  fullW
                  type='submit'
                  theme='primary'
                  size='large'
                  label='BAYAR'
                  disabled={!selectedPaymentMethodId}
                  onClick={() => handleChoosePaymentMethod()}
                />
              </Sided.Remaining>
            </Sided.Sided>
          </Wrapper>
        </Panel.Content>
      </Panel.Panel>
    </Modal>
  )
}
