import { EnvironmentConfig } from './def'
import { getCurrentEnvironment } from './env'

export function getAppConfig(): AppConfig {
  const detail = getCurrentEnvironment()
  return {
    apiUrl: process.env.REACT_APP_API_URL,
    detail,
    publicPath: process.env.REACT_APP_PUBLIC_PATH,
    mode: process.env.REACT_APP_MODE,
    sentryDsn: process.env.REACT_APP_SENTRY_DSN,
    crispWebsiteId: process.env.REACT_APP_CRISP_WEBSITE_ID,
  }
}

export interface AppConfig {
  apiUrl: string
  detail: EnvironmentConfig
  publicPath: string
  mode: string
  sentryDsn: string
  crispWebsiteId: string
}

export * from './func'
