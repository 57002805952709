import React, { useState, useEffect } from 'react'
import { Redirect, Route, RouteProps, Switch } from 'react-router'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { history } from 'storages'
import { CONFIG_LOCALSTORAGE } from 'constants/localStorage'
import ROUTE from 'constants/routes'
import { setLocalStorage, getLocalStorage } from 'utilities/localStorage'
import { firebaseFunc } from 'utilities/firebase'
import { toList } from 'utilities/converter/list'
import { AppState } from 'domains/contracts/appState'
import { User } from 'domains/users/models'
import { ProductV2Model } from 'domains/management/products/models'
import { OutletModel } from 'domains/management/outlets/models'
import { QRISPaymentMethod } from 'domains/management/paymentMethod/models'
import RegisterQRISModal from 'views/components/RegisterQRISModal'
import AsyncPage from 'views/components/AsyncPageV2'
import AuthorizedLayout from 'views/layouts/Authorized'
import UnauthorizedLayout from 'views/layouts/Unauthorized'
import getAuthorizedRoutes from './authorizedRoute'
import getUnauthorizedRoutes from './unauthorizedRoute'

import {
  store,
  api,
  commonService,
  paymentMethodService,
  employeeService,
} from 'injectors'
import { isAnyOfTheseFeaturesEnabled, featureCheck } from 'config'

interface SideBarMenu {
  label: string
  icon?: React.ReactElement
}

export interface RouteItemV2 {
  path: string
  exact?: boolean
  component: string
  isEnabled: boolean
  sidebarMenu?: SideBarMenu
  userAccess?: string
  children?: RouteItemV2[]
  isNew?: boolean
  parentKey?: string
  category?: string
  onClick?: () => any
}

export interface RouteConfig {
  path: string
  key: string
  exact?: boolean
  component: (props?: any) => React.ReactElement
  routes?: RouteConfig[]
  userAccess?: string
  parentKey?: string
  category?: string
}

const toRouteConfig = (routeItem: RouteItemV2, key: string): RouteConfig => {
  return {
    ...routeItem,
    key,
    component: () => <AsyncPage page={routeItem.component} />,
  }
}

const generateRouteConfigs = (
  routeItems: RouteItemV2[],
  key: string
): RouteConfig[] => {
  const routeConfigs: RouteConfig[] = []
  routeItems.forEach(route => {
    if (route.children) {
      route.children.forEach(routeChild =>
        routeConfigs.push(toRouteConfig(routeChild, key))
      )
    } else {
      routeConfigs.push(toRouteConfig(route, key))
    }
  })

  return routeConfigs
}

export const authorizedRoutes = generateRouteConfigs(
  getAuthorizedRoutes(),
  'AUTHORIZED'
)
export const unauthorizedRoutes = generateRouteConfigs(
  getUnauthorizedRoutes(),
  'PUBLIC'
)

interface RouteConfigComponent extends RouteProps {
  routes: RouteConfig[]
}

const ROUTE_CONFIG: RouteConfig[] = [
  {
    path: '/auth',
    key: 'ROOT',
    component: (props: RouteConfigComponent): any => {
      const currentRoute = unauthorizedRoutes.filter(
        r => r.path === history.location.pathname
      )[0]
      if (
        currentRoute &&
        currentRoute.userAccess &&
        currentRoute.userAccess === 'unauthorized' &&
        getLocalStorage(CONFIG_LOCALSTORAGE.POST_TOKEN)
      ) {
        return <Redirect to={'/'} />
      }
      return (
        <UnauthorizedLayout>
          <RenderRoutes {...props} />
        </UnauthorizedLayout>
      )
    },
    routes: unauthorizedRoutes,
  },
  {
    path: '/',
    key: 'APP',
    component: (props: RouteConfigComponent) => {
      if (!localStorage.getItem(CONFIG_LOCALSTORAGE.POST_TOKEN)) {
        return (
          <Redirect
            to={`/auth/login?redirect_url=${props.location.pathname}`}
          />
        )
      }
      return (
        <AuthorizedLayout>
          <RenderRoutes {...props} />
          {isAnyOfTheseFeaturesEnabled(
            featureCheck.management.qrisPaymentMethod
          ) && <RenderQRISModal />}
        </AuthorizedLayout>
      )
    },
    routes: authorizedRoutes,
  },
]

export default ROUTE_CONFIG

const RouteWithSubRoutes = (routeConfig: RouteConfig) => {
  return (
    <Route
      path={routeConfig.path}
      exact={routeConfig.exact}
      render={props => (
        <routeConfig.component {...props} routes={routeConfig.routes} />
      )}
    />
  )
}

interface RenderRoutesProps {
  routes: RouteConfig[]
}

export const RenderRoutes = ({ routes }: RenderRoutesProps) => {
  const { user, management } = store.getState()
  const isFnbBusinessCategory =
    user.isFnbBusinessCategory || management.employee.isFnbBusinessCategory
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    getBusinessCategory()
  }, [])

  const getBusinessCategory = async () => {
    try {
      await employeeService.getBusinessCategories()
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      console.error(error)
    }
  }

  if (!isFnbBusinessCategory) {
    routes = routes.filter(route => route.category !== 'fnb')
  }

  return (
    <>
      {!isLoading && (
        <Switch>
          {routes.map((route: RouteConfig) => {
            return <RouteWithSubRoutes key={route.key} {...route} />
          })}
          <Route
            render={() => (
              <h1 className='text-center py-44'>Page Not Found!</h1>
            )}
          />
        </Switch>
      )}
    </>
  )
}

const RenderQRISModal = () => {
  const user = useSelector<AppState, User>(state => state.user)
  const outletList = useSelector<AppState, OutletModel[]>(state =>
    toList(state.management.outlet.outlets)
  )
  const QRISPaymentMethods = useSelector<AppState, QRISPaymentMethod[]>(
    state => state.management.paymentMethod.QRISPaymentMethods
  )
  const QRISPopupLastShown = getLocalStorage(
    CONFIG_LOCALSTORAGE.QRIS_POPUP_LAST_SHOWN + '_' + user.id
  )
  const isQRISPopupShow = getLocalStorage(
    CONFIG_LOCALSTORAGE.IS_QRIS_POPUP_SHOW + '_' + user.id
  )
  const isOwner =
    getLocalStorage(CONFIG_LOCALSTORAGE.POST_TYPE)?.toLowerCase() === 'owner'
  const [products, setProducts] = useState<ProductV2Model[]>([])

  const getProducts = async () => {
    const productResponse = await api.management.productV2.getProducts({
      limit: 1,
    })
    setProducts(productResponse)
  }

  useEffect(() => {
    getProducts()
  }, [])

  useEffect(() => {
    if (isOwner && user.id) {
      const isNewUser = moment().diff(moment(user.createdAt), 'days') < 7
      const isNewUserHasProducts = isNewUser && products.length > 0

      if (!isNewUser || isNewUserHasProducts) {
        if (!QRISPopupLastShown && isQRISPopupShow === null) {
          showQRISModal()
        } else {
          const lastShownDate = moment(QRISPopupLastShown)
          const dayDiff = moment().diff(lastShownDate, 'days')

          if (dayDiff >= 7 || isQRISPopupShow === 'true') {
            showQRISModal()
          }
        }
      }
    }
  }, [QRISPopupLastShown, user, products])

  const showQRISModal = async () => {
    const isQRISRegistered = await getQRISRegister()
    if (!isQRISRegistered) {
      setLocalStorage(
        CONFIG_LOCALSTORAGE.QRIS_POPUP_LAST_SHOWN + '_' + user.id,
        moment().toString()
      )
      setLocalStorage(
        CONFIG_LOCALSTORAGE.IS_QRIS_POPUP_SHOW + '_' + user.id,
        'true'
      )
      firebaseFunc.logEvent('qris_open')
      commonService.setQrisModal({
        visible: true,
        onClose: handleCloseRegisterModal,
        onSuccess: handleRegisterSuccess,
      })
    }
  }

  const getQRISRegister = async () => {
    let isQRISRegistered = true
    if (QRISPaymentMethods.length === 0) {
      const promises = outletList.map(async outlet => {
        const qris = await paymentMethodService.getQris(outlet.id)
        if (qris.isRegistered === false) {
          isQRISRegistered = false
          return
        }
      })
      await Promise.all(promises)
      commonService.setQrisBannerVisible(!isQRISRegistered)
      return isQRISRegistered
    } else {
      QRISPaymentMethods.forEach(qris => {
        if (qris.isRegistered === false) {
          isQRISRegistered = false
          return
        }
      })
      commonService.setQrisBannerVisible(!isQRISRegistered)
      return isQRISRegistered
    }
  }

  const handleCloseRegisterModal = () => {
    setLocalStorage(
      CONFIG_LOCALSTORAGE.IS_QRIS_POPUP_SHOW + '_' + user.id,
      'false'
    )
  }

  const handleRegisterSuccess = () => {
    const path = window.location.pathname
    if (path === ROUTE.MAINPAGE || path === ROUTE.MANAGEMENT_PAYMENT_METHOD) {
      window.location.reload()
    }
  }

  return <RegisterQRISModal />
}

// export const displayRouteMenu = (routeConfigs: RouteConfig[]) => {
//   const singleRoute = (routeConfig: RouteConfig) => {
//     return (
//       <li key={routeConfig.key}>
//         <Link to={routeConfig.path}>
//           {routeConfig.key} ({routeConfig.path})
//         </Link>
//       </li>
//     );
//   }

//   return (
//     <ul>
//       {routeConfigs.map((routeConfig: RouteConfig) => {
//         if (routeConfig.routes) {
//           return (
//             <React.Fragment key={routeConfig.key}>
//               {singleRoute(routeConfig)}
//               {displayRouteMenu(routeConfig.routes)}
//             </React.Fragment>
//           );
//         }
//         return singleRoute(routeConfig);
//       })}
//     </ul>
//   );
// }
