import { Deferred, defer } from 'utilities/defer'

export class Debouncer {
  intervalCode: number
  constructor() {
    this.intervalCode = new Date().getTime()
  }

  debounce<T>(callback: () => Promise<T>, wait: number): Promise<T> {
    clearTimeout(this.intervalCode)

    const deferred: Deferred<T> = defer()

    this.intervalCode = setTimeout(async () => {
      const returnedValue: T = await callback()
      deferred.resolve(returnedValue)
    }, wait)
    return deferred.promise
  }
}
