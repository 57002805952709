import { ISubscriptionApi } from 'domains/subscriptions/contracts/api'
import {
  SubscriptionAlert,
  SubscriptionPackage,
  SubscriptionMethod,
  Subscription,
  SubscriptionRequest,
  SubscriptionVoucher,
  VoucherQueryParam,
  SubscriptionInvoice,
} from 'domains/subscriptions/model'

import { BaseApi } from '../base.api'

export default class SubscriptionApi
  extends BaseApi
  implements ISubscriptionApi
{
  constructor() {
    super('/v2')
  }

  public async getSubscriptionPackages(): Promise<SubscriptionPackage[]> {
    return await this.makeForCamelCaseForList('GET', '/subscription-packages')
  }

  public async checkSubscriptionAlert(): Promise<SubscriptionAlert> {
    return await this.makeForCamelCase('GET', '/subscriptions/check')
  }

  public async getSubscriptionMethod(): Promise<SubscriptionMethod[]> {
    return await this.makeForCamelCaseForList(
      'GET',
      '/subscription-payment-methods'
    )
  }

  public async getVoucher(
    code: string,
    packagePricingId: VoucherQueryParam
  ): Promise<SubscriptionVoucher> {
    return await this.makeForCamelCase(
      'GET',
      `/vouchers/${code}`,
      packagePricingId
    )
  }

  public async applySubscription(
    requestBody: SubscriptionRequest
  ): Promise<Subscription> {
    return await this.makeForCamelCase('POST', '/subscriptions', requestBody)
  }

  public async cancelSubscription(id: string) {
    return await this.makeForCamelCase('POST', `/subscriptions/${id}/cancel`)
  }

  public async getSubscriptionDetail(id: string): Promise<Subscription> {
    return await this.makeForCamelCase('GET', `/subscriptions/${id}`)
  }

  public async getSubscriptions(): Promise<Subscription[]> {
    return await this.makeForCamelCaseForList('GET', `/subscriptions`)
  }

  public async getSubscriptionInvoice(
    subscriptionId: string
  ): Promise<SubscriptionInvoice> {
    return await this.makeForCamelCase(
      'GET',
      `/subscriptions/${subscriptionId}/invoice`
    )
  }
}
