import React, { useEffect, useState } from 'react'
import {
  Break,
  Checkbox,
  Icon,
  Scroll,
  Sided,
  Text,
  Wrapper,
} from 'views/components'
import { Tooltip, Button as ButtonAnt } from 'antd'
import { Trans } from '@lingui/macro'
import { useTable, useGlobalFilter, Column, Row } from 'react-table'
import { MassProduct } from 'domains/management/products/models'
import { REGEX } from 'constants/regex'

interface MassImportReactTableProps {
  columns: Column<MassProduct>[]
  data: MassProduct[]
  updateMyData: (rowIndex: number, columnId: string, value: any) => void
  skipPageReset: boolean
  isError: boolean
  checkRowValidity: (row: MassProduct) => string[]
}

interface EditableCellProps {
  value: any
  row: Row<MassProduct>
  column: Column<MassProduct>
  updateMyData: (rowIndex: number, columnId: string, value: any) => void
}

const EditableCell = ({
  value: initialValue,
  row: { index },
  column: { id },
  updateMyData,
}: EditableCellProps) => {
  const [value, setValue] = React.useState(initialValue)

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
  }

  const onBlur = () => {
    updateMyData(index, id, value)
  }

  React.useEffect(() => {
    setValue(initialValue || '')
  }, [initialValue])

  const fieldType: {
    [key: string]: any
    name: string
    categoryName: string
    price: string
    unitCost: string
    sku: string
    barcode: string
    description: string
    trackStock: string
    'stockAdjustment.quantity': string
    minStock: string
    weight: string
    'dimension.length': string
    'dimension.width': string
    'dimension.height': string
  } = {
    name: 'string',
    categoryName: 'string',
    price: 'number',
    unitCost: 'number',
    sku: 'string',
    barcode: 'string',
    description: 'string',
    trackStock: 'string',
    'stockAdjustment.quantity': 'number',
    minStock: 'number',
    weight: 'number',
    'dimension.length': 'number',
    'dimension.width': 'number',
    'dimension.height': 'number',
  }

  return (
    <input
      style={{ border: 'none', padding: '8px 16px', outline: 'none' }}
      value={value}
      type={fieldType[id]}
      onChange={onChange}
      onBlur={onBlur}
      onKeyPress={e => {
        if (fieldType[id] === 'number' && !REGEX.NUMBER.test(e.key)) {
          e.preventDefault()
        }
      }}
    />
  )
}

const defaultColumn = {
  Cell: EditableCell,
}

const MassImportReactTable = ({
  columns,
  data,
  updateMyData,
  skipPageReset,
  isError,
  checkRowValidity,
}: MassImportReactTableProps) => {
  const [filter, setFilter] = useState('')

  const globalFilterCustom = (
    rows: Row<MassProduct>[],
    columnIds: string[],
    filterValue: string
  ) => {
    return rows.filter(row => {
      return filterValue === 'errorOnly'
        ? checkRowValidity(row.original).length > 0
        : true
    })
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    setGlobalFilter,
    state,
  } = useTable<MassProduct>(
    {
      columns,
      data,
      defaultColumn,
      autoResetPage: !skipPageReset,
      updateMyData,
      globalFilter: globalFilterCustom,
      initialState: {
        globalFilter: filter,
      },
    },
    useGlobalFilter
  )

  useEffect(() => {
    if (isError) {
      setGlobalFilter(filter)
    } else {
      setFilter('')
      setGlobalFilter('')
    }
  }, [filter, isError])

  const handleOnFilter = (value: boolean) => {
    setFilter(value ? 'errorOnly' : '')
  }

  const errMessage = (item: MassProduct) => (
    <Wrapper>
      <Trans>
        <Text.Heading h={6} color='white'>
          Kesalahan Data
        </Text.Heading>
      </Trans>
      <ul style={{ paddingLeft: '20px' }}>
        {item && item.unitCost > item.price && (
          <Trans>
            <li>Harga Modal (COGS) tidak boleh lebih besar dari Harga Jual</li>
          </Trans>
        )}
        {item && !item.price && (
          <Trans>
            <li>Harga Jual tidak boleh kosong</li>
          </Trans>
        )}
        {item && item.price < 0 && (
          <Trans>
            <li>Harga Jual tidak boleh minus</li>
          </Trans>
        )}
        {item && !item.name && (
          <Trans>
            <li>Nama Produk tidak boleh kosong</li>
          </Trans>
        )}
        {item && item.minStock < 0 && (
          <Trans>
            <li>Minimal Stok tidak boleh minus</li>
          </Trans>
        )}
        {item && item.stockAdjustment.quantity < 0 && (
          <Trans>
            <li>Stok masuk tidak boleh minus</li>
          </Trans>
        )}
        {item && item.trackStock === undefined && (
          <Trans>
            <li>Kelola Stok tidak valid</li>
          </Trans>
        )}
        {item && item.description.length > 160 && (
          <Trans>
            <li>Deskripsi maksimal 160 karakter</li>
          </Trans>
        )}
        {item && item.barcode && !REGEX.NUMBER.test(item.barcode) && (
          <Trans>
            <li>Barcode harus berupa angka</li>
          </Trans>
        )}
        {item && REGEX.DECIMAL_ONLY.test(JSON.stringify(item.weight)) && (
          <Trans>
            <li>Berat tidak boleh desimal</li>
          </Trans>
        )}
        {item && item.dimension && (
          <>
            {item.dimension.height !== undefined &&
              REGEX.DECIMAL_ONLY.test(
                JSON.stringify(item.dimension.height)
              ) && (
                <Trans>
                  <li>Tinggi tidak boleh desimal</li>
                </Trans>
              )}
            {item.dimension.width !== undefined &&
              REGEX.DECIMAL_ONLY.test(JSON.stringify(item.dimension.width)) && (
                <Trans>
                  <li>Lebar tidak boleh desimal</li>
                </Trans>
              )}
            {item.dimension.length !== undefined &&
              REGEX.DECIMAL_ONLY.test(
                JSON.stringify(item.dimension.length)
              ) && (
                <Trans>
                  <li>Panjang tidak boleh desimal</li>
                </Trans>
              )}
            {(item.dimension.height !== undefined ||
              item.dimension.length !== undefined ||
              item.dimension.width !== undefined) && (
              <>
                {(item.dimension.height === undefined ||
                  item.dimension.length === undefined ||
                  item.dimension.width === undefined) && (
                  <Trans>
                    <li>Dimensi harus diisi semua</li>
                  </Trans>
                )}
              </>
            )}
          </>
        )}
        {checkRowValidity(item).length === 0 && (
          <Trans>
            <span>Product ini Valid&nbsp;&nbsp;&nbsp;</span>
          </Trans>
        )}
      </ul>
    </Wrapper>
  )

  return (
    <Scroll style={{ maxHeight: '100%', maxWidth: '100%' }}>
      <Wrapper className='post-table'>
        {isError && (
          <>
            <Break height={12} />
            <Sided.Sided style={{ marginLeft: '50px' }}>
              <Sided.Fixed>
                <Checkbox
                  checked={(filter || state.globalFilter) === 'errorOnly'}
                  onChange={handleOnFilter}
                />
              </Sided.Fixed>
              <Sided.Remaining>
                <Text.Paragraph noMargin weight='bold'>
                  Hanya tampilkan data yang error
                </Text.Paragraph>
              </Sided.Remaining>
            </Sided.Sided>
            <Break height={12} />
          </>
        )}
        <table
          {...getTableProps()}
          className='uploadmass-table post-table-type-bordered post-table-size-default'
        >
          <thead>
            {headerGroups.map(headerGroup => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                className='post-table-tr'
              >
                {isError && (
                  <td
                    className='post-table-td'
                    style={{ borderRight: 'none' }}
                  ></td>
                )}
                <td className='post-table-td'>
                  <Text.Paragraph weight='bold' noMargin>
                    No.
                  </Text.Paragraph>
                </td>
                {headerGroup.headers.map(column => (
                  <td {...column.getHeaderProps()} className='post-table-td'>
                    <Text.Paragraph weight='bold' noMargin>
                      {column.render('Header')}
                    </Text.Paragraph>
                  </td>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row)
              const errorFields = checkRowValidity(row.original)
              return (
                <tr {...row.getRowProps()} className='post-table-tr'>
                  {isError && (
                    <td
                      className='post-table-td'
                      style={{ paddingRight: 0, borderRight: 'none' }}
                    >
                      {errorFields.length === 0 ? (
                        <Wrapper align='center'>
                          {/* <Icon.Rectangle /> */}
                        </Wrapper>
                      ) : (
                        <Tooltip
                          placement='right'
                          title={errMessage(row.original)}
                          className='placement-tooltip'
                        >
                          <ButtonAnt
                            style={{
                              border: 'none',
                              padding: 0,
                              height: 'unset',
                              display: 'grid',
                            }}
                          >
                            <Icon.Caution size={18} style={{ margin: 0 }} />
                          </ButtonAnt>
                        </Tooltip>
                      )}
                    </td>
                  )}
                  <td className='post-table-td'>
                    <Text.Paragraph noMargin>{i + 1}</Text.Paragraph>
                  </td>
                  {row.cells.map(cell => {
                    const isCellError = errorFields.includes(cell.column.id)
                    return (
                      <td
                        {...cell.getCellProps()}
                        className='post-table-td'
                        style={{
                          padding: 0,
                          color: isCellError ? 'red' : '#333',
                        }}
                      >
                        {cell.render('Cell')}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </Wrapper>
    </Scroll>
  )
}

export default MassImportReactTable
