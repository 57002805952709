import { ISummaryReportingService } from './summary'
import { IProductSaleReportingService } from './productSale'
import { IServiceTypeReportingService } from './serviceType'
import { ICancellationReportingService } from './cancellation'
import { IDiscountReportingService } from './discount'
import { IShiftReportingService } from './shift'
import { IPaymentMethodReportService } from './paymentMethod'
import { IReportingFilterService } from './filter'
import { BaseService } from 'domains/commons/services'
import { IReportingStorage } from '../contracts/storage'
import { ICommonStorage } from 'domains/commons/contracts/storage'
import { Api } from 'domains/contracts/api'
import { IExpenseReportingService } from './expense'
import { ISalesFactReportingService } from './salesFact'
import { IEmployeeReportingService } from './employee'
import { IOrderReportingService } from './order'

export interface IReportingService {
  summary: ISummaryReportingService
  expense: IExpenseReportingService
  productSale: IProductSaleReportingService
  employee: IEmployeeReportingService
  serviceType: IServiceTypeReportingService
  cancellation: ICancellationReportingService
  discount: IDiscountReportingService
  shift: IShiftReportingService
  paymentMethod: IPaymentMethodReportService
  filter: IReportingFilterService
  salesFact: ISalesFactReportingService
  order: IOrderReportingService
}

export class BaseReportingService extends BaseService {
  reportingStorage: IReportingStorage

  constructor(
    commonStorage: ICommonStorage,
    api: Api,
    reportingStorage: IReportingStorage
  ) {
    super(commonStorage, api)
    this.reportingStorage = reportingStorage
  }
}
