import {
  RouteDescription,
  findFirst,
  hasCurrentPath,
  toRouteDescription,
  hasCurrentPathV2,
} from 'domains/layouts/services/route.item'
import { clearLocalStorage, getLocalStorage } from 'utilities/localStorage'
import ROUTE from 'constants/routes'
import { BaseService } from 'domains/commons/services'
import { ILayoutStorage } from '../contracts/storage'
import { ICommonStorage } from 'domains/commons/contracts/storage'
import { IUserStorage } from 'domains/users/contracts/storage'
import { Api } from 'domains/contracts/api'
import { History } from 'history'
import { generateDashboardRoutes } from 'routes/dashboard'
import { authService, employeeService } from 'injectors'
import { CONFIG_LOCALSTORAGE } from 'constants/localStorage'
import { RouteItemV2 } from 'route'
import getAuthorizedRoutes from 'route/authorizedRoute'

export class LayoutService extends BaseService implements ILayoutService {
  layoutStorage: ILayoutStorage
  userStorage: IUserStorage
  history: History

  constructor(
    commonStorage: ICommonStorage,
    api: Api,
    subscriptionStorage: ILayoutStorage,
    userStorage: IUserStorage,
    history: History
  ) {
    super(commonStorage, api)
    this.layoutStorage = subscriptionStorage
    this.userStorage = userStorage
    this.history = history
  }

  async getMenu() {
    const routes = await generateDashboardRoutes()
    return routes.map(toRouteDescription).filter(x => !!x.sidebarMenu)
  }

  async getMenuV2() {
    const routes = getAuthorizedRoutes()
    return routes.filter(x => !!x.sidebarMenu)
  }

  public setSubmenuView = (route: RouteDescription) => {
    this.layoutStorage.setSubmenuView(route)
    const currentRoute = this.getState().layout.sidebarState.currentRoute
    const isHavingCurrentPath =
      currentRoute &&
      hasCurrentPath(currentRoute, this.history.location.pathname)
    if (currentRoute == null || !isHavingCurrentPath) {
      const subRoute = findFirst(route)
      this.history.push(subRoute.path)
    }
  }

  public setSubmenuViewV2 = (route: RouteItemV2) => {
    this.layoutStorage.setSubmenuViewV2(route)
    const currentRoute = this.getState().layout.sidebarState.currentRouteV2
    const isHavingCurrentPath =
      currentRoute &&
      hasCurrentPathV2(currentRoute, this.history.location.pathname)
    if (currentRoute == null || !isHavingCurrentPath) {
      const subRoute = findFirst(route)
      this.history.push(subRoute.path)
    }
  }

  public setMainMenuView = async () => {
    this.layoutStorage.setMainMenuView()
    const menu = await this.getMenu()
    this.history.push(menu[0].path)
  }

  public checkActiveChild = async () => {
    const menu = await this.getMenu()
    menu
      .filter(x => !!x.children)
      .forEach((routeItem: RouteDescription) => {
        if (hasCurrentPath(routeItem, this.history.location.pathname)) {
          this.layoutStorage.setSubmenuView(routeItem)
          return
        }
      })
  }

  public checkActiveChildV2 = async () => {
    const menu = await this.getMenuV2()
    menu
      .filter(x => !!x.children)
      .forEach((routeItem: RouteItemV2) => {
        if (hasCurrentPathV2(routeItem, this.history.location.pathname)) {
          this.layoutStorage.setSubmenuViewV2(routeItem)
          return
        }
      })
  }

  public logout = async () => {
    const accessToken = getLocalStorage(CONFIG_LOCALSTORAGE.POST_TOKEN)
    const authHeader = {
      Authorization: 'Bearer' + accessToken,
    }
    await authService.logOutOwner(authHeader)
    clearLocalStorage()
    this.userStorage.logoutUser()
    this.history.push(ROUTE.LOGIN)
  }

  public clearAccess = () => {
    clearLocalStorage()
    this.userStorage.logoutUser()
    this.history.push(ROUTE.LOGIN)
  }

  public logOutEmployee = async () => {
    const accessToken = getLocalStorage(CONFIG_LOCALSTORAGE.POST_TOKEN)
    const employeeId = getLocalStorage(CONFIG_LOCALSTORAGE.STAFF_ID)
    const authHeader = {
      Authorization: 'Bearer' + accessToken,
    }
    await employeeService.logOutEmployee(authHeader, employeeId)
    clearLocalStorage()
    this.userStorage.logoutUser()
    this.history.push(ROUTE.LOGIN)
  }
}

export interface ILayoutService {
  setSubmenuView: (value: RouteDescription) => void
  setSubmenuViewV2: (value: RouteItemV2) => void
  setMainMenuView: () => void
  checkActiveChild: () => void
  checkActiveChildV2: () => void
  logout: () => Promise<void>
  clearAccess: () => void
  logOutEmployee: () => Promise<void>
}
