import React, { useState, useEffect } from 'react'
import { TimerCountdown, CountdownCounter } from 'utilities/date'
import { withLeadingZero } from 'utilities/converter/modeller'
interface CountdownTimerProps {
  endTime: Date
}

const CountdownTimer: React.FC<CountdownTimerProps> = props => {
  const [timeLeft, setTimeLeft] = useState<TimerCountdown | null>(null)

  const countdownCounter = new CountdownCounter(t => {
    setTimeLeft(t)
  })

  useEffect(() => {
    countdownCounter.start(props.endTime)
  }, [])

  let timerLabel = ''

  if (timeLeft) {
    timerLabel = [
      withLeadingZero(timeLeft.days, 2),
      withLeadingZero(timeLeft.hours, 2),
      withLeadingZero(timeLeft.minutes, 2),
      withLeadingZero(timeLeft.seconds, 2),
    ]
      .filter(x => x != '00')
      .join(':')
  }

  return <span>{timerLabel}</span>
}

export default CountdownTimer
