import { BaseApi } from 'api/base.api'
import {
  ProductListV2QueryParams,
  ProductV2StockHistoryQueryParams,
  ProductV2StockHistory,
  ProductV2Model,
  ProductV2PayloadModel,
  BulkCreateRequest,
  BulkUpdateRequest,
  MassUploadRequest,
  MassUpdateRequest,
} from 'domains/management/products/models'

interface ProductV2ListResponse {
  data: ProductV2Model[]
}

interface ProductStockHistoryResponse {
  data: ProductV2StockHistory[]
}

export default class ProductApiV2 extends BaseApi {
  constructor() {
    super('/product/v4/products')
  }

  public async getProducts(
    queryParams?: ProductListV2QueryParams
  ): Promise<ProductV2Model[]> {
    return (await this.make<ProductV2ListResponse>('GET', '', queryParams)).data
  }

  public async createProduct(
    productData: ProductV2PayloadModel,
    headers?: any
  ) {
    return await this.make('POST', '', productData, {}, headers)
  }

  public async updateProduct(productData: ProductV2PayloadModel) {
    return await this.make('PUT', `/${productData.id}`, productData)
  }

  public async deleteProduct(productId: string) {
    return await this.make('DELETE', `/${productId}`)
  }

  public async getProductStockHistory(
    productId: string,
    queryParams: ProductV2StockHistoryQueryParams
  ) {
    return (
      await this.make<ProductStockHistoryResponse>(
        'GET',
        `/${productId}/stock-histories`,
        queryParams
      )
    ).data
  }

  public async bulkCreateProductSingle(
    request?: MassUploadRequest
  ): Promise<void> {
    return await this.makeForCamelCase('POST', '/batch-insert-single', request)
  }

  public async bulkUpdateProductSingle(
    request?: MassUpdateRequest
  ): Promise<void> {
    return await this.makeForCamelCase('POST', '/batch-update-single', request)
  }

  public async bulkCreateProduct(request?: BulkCreateRequest): Promise<void> {
    return await this.makeForCamelCase('POST', '/batch-insert', request)
  }

  public async bulkUpdateProduct(request?: BulkUpdateRequest): Promise<void> {
    return await this.makeForCamelCase('POST', '/batch-update', request)
  }
}
