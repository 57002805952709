const PREFIX = 'EMPLOYEE'

export const SET_EMPLOYEES = PREFIX + '.SET_EMPLOYEES'
export const APPEND_EMPLOYEES = PREFIX + '.APPEND_EMPLOYEES'
export const SET_EMPLOYEE_LIST_MANAGER = PREFIX + '.SET_EMPLOYEE_LIST_MANAGER'
export const LOAD_MORE_EMPLOYEES = PREFIX + '.LOAD_MORE_EMPLOYEES'
export const SET_CURRENT_STAFF = PREFIX + '.SET_CURRENT_STAFF'
export const SET_CURRENT_CASHIER = PREFIX + '.SET_CURRENT_CASHIER'
export const SET_EMPLOYEE_LIST_REQUEST = PREFIX + '.SET_EMPLOYEE_LIST_REQUEST'
export const SET_EMPLOYEE_LIST_REACH_BOTTOM: string =
  PREFIX + '.SET_EMPLOYEE_LIST_REACH_BOTTOM'
export const SET_LOADING_MORE_EMPLOYEE: string =
  PREFIX + '.SET_LOADING_MORE_EMPLOYEE'
export const SET_IS_FNB_BUSINESS_CATEGORY =
  PREFIX + '.SET_IS_FNB_BUSINESS_CATEGORY'
