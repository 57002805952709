import { Reducer, StateActions } from 'utilities/redux/reducer'
import { OutletModel } from 'domains/management/outlets/models'
import { Dict } from 'utilities/type'
import { SET_MUlTI_OUTLET } from './actions'
import { OutletState } from 'domains/management/outlets/states'

export class OutletReducer extends Reducer<OutletState> {
  constructor() {
    super({
      outlets: {},
    })
  }

  setOutlets = (
    state: OutletState,
    outlets: Dict<OutletModel>
  ): OutletState => {
    return {
      ...state,
      outlets: outlets,
    }
  }

  get actions(): StateActions<OutletState> {
    return {
      [SET_MUlTI_OUTLET]: this.setOutlets,
    }
  }
}
