import {
  ProductSaleReport,
  SummaryReport,
  DiscountReport,
  ShiftReport,
  PaymentMethodReport,
  CancellationReport,
  ServiceTypeReportData,
  SummaryHourlyReport,
  EmployeeReport,
} from 'domains/reporting/models'
import {
  SET_EMPLOYEE_REPORTING,
  SET_SUMMARY_REPORTING,
  SET_DAILY_SUMMARY_REPORTING,
  SET_PRODUCT_SALES_REPORTING,
  SET_ORDER_TYPE_LIST_REPORTING,
  SET_CANCELLATION_REPORTING,
  SET_OUTLET_ID_REPORT_FILTER,
  SET_DATE_RANGE_REPORT_FILTER,
  SET_PAYMENT_METHOD_LIST_REPORT,
  SET_DISCOUNT_REPORTING,
  SET_SHIFT_REPORTING,
  SET_SHOW_TYPE_REPORT_FILTER,
  SET_SALE_TYPE_REPORT_FILTER,
  SET_EXPENSE_TYPE_REPORT_FILTER,
  RESET_REPORTING_FILTER,
  SET_HOURLY_SUMMARY_REPORTING,
} from './actions'
import { DateRange } from 'domains/commons/models'
import { BaseStorage } from 'storages/base'
import { IReportingStorage } from 'domains/reporting/contracts/storage'

export class ReportingStorage extends BaseStorage implements IReportingStorage {
  setSummaryReporting(summaryReports: SummaryReport[]): void {
    this.dispatch(SET_SUMMARY_REPORTING, summaryReports)
  }
  setDailySummaryReporting(summaryReports: SummaryReport[]): void {
    this.dispatch(SET_DAILY_SUMMARY_REPORTING, summaryReports)
  }
  setHourlySummaryReporting(summaryHourlyReports: SummaryHourlyReport[]): void {
    this.dispatch(SET_HOURLY_SUMMARY_REPORTING, summaryHourlyReports)
  }
  setProductSalesReporting(productSales: ProductSaleReport[]): void {
    this.dispatch(SET_PRODUCT_SALES_REPORTING, productSales)
  }
  setEmployeeReporting(employeeReports: EmployeeReport[]): void {
    this.dispatch(SET_EMPLOYEE_REPORTING, employeeReports)
  }
  setCancellationReporting(cancellations: CancellationReport[]): void {
    this.dispatch(SET_CANCELLATION_REPORTING, cancellations)
  }
  setServiceTypeListReporting(serviceTypes: ServiceTypeReportData[]): void {
    this.dispatch(SET_ORDER_TYPE_LIST_REPORTING, serviceTypes)
  }
  setDiscountReporting(discounts: DiscountReport[]): void {
    this.dispatch(SET_DISCOUNT_REPORTING, discounts)
  }
  setShiftReporting(shifts: ShiftReport[]): void {
    this.dispatch(SET_SHIFT_REPORTING, shifts)
  }
  setPaymentMethodListReport(paymentMethod: PaymentMethodReport[]): void {
    this.dispatch(SET_PAYMENT_METHOD_LIST_REPORT, paymentMethod)
  }
  setOutletIdReportFilter(selectedOutletId?: string): void {
    this.dispatch(SET_OUTLET_ID_REPORT_FILTER, selectedOutletId)
  }
  setDateRangeReportFilter(dateRange: DateRange): void {
    this.dispatch(SET_DATE_RANGE_REPORT_FILTER, dateRange)
  }
  setShowTypeReportFilter(
    showType: 'summary' | 'daily' | 'weekly' | 'monthly'
  ): void {
    this.dispatch(SET_SHOW_TYPE_REPORT_FILTER, showType)
  }
  setSaleTypeReportFilter(saleType: string): void {
    this.dispatch(SET_SALE_TYPE_REPORT_FILTER, saleType)
  }
  setExpenseTypeReportFilter(expenseType: string): void {
    this.dispatch(SET_EXPENSE_TYPE_REPORT_FILTER, expenseType)
  }
  resetFilter(): void {
    this.dispatch(RESET_REPORTING_FILTER)
  }
}
