import React from 'react'
import Modal from 'react-modal'
import { Panel, Text, Break, Button, Sided } from 'views/components'
import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'

export interface ConfirmationModalProps {
  showModal: boolean
  title: string
  content: string
  submitTheme: 'primary' | 'danger'
  onSubmit: () => Promise<void>
  close: () => void
}

export const ConfirmationModal = (props: ConfirmationModalProps) => {
  const { showModal, title, content, submitTheme, onSubmit, close } = props
  const { i18n } = useLingui()

  const submit = () => {
    onSubmit()
    close()
  }

  return (
    <Modal
      isOpen={showModal}
      onRequestClose={close}
      contentLabel='Konfirmasi'
      ariaHideApp={false}
      style={{
        overlay: {
          zIndex: 1002,
          backgroundColor: 'rgba(51,51,51,0.8)',
          display: 'flex',
          placeContent: 'center',
          alignItems: 'center',
          padding: '1rem 2rem',
        },
        content: {
          position: 'unset',
          height: '100%',
          maxHeight: '193px',
          width: '100%',
          maxWidth: '400px',
          padding: 0,
        },
      }}
    >
      <Panel.Panel>
        <Panel.Header>
          <Sided.Sided>
            <Sided.Remaining>
              <Text.Paragraph size={14} color='black' weight='bold'>
                {title}
              </Text.Paragraph>
            </Sided.Remaining>
          </Sided.Sided>
        </Panel.Header>
        <Panel.Content>
          <Text.Paragraph size={14} align='left'>
            {content}
          </Text.Paragraph>
          <Break height={32} />
          <Sided.Sided justify='center' justifyContent='right' gutter={16}>
            <Button
              size='medium'
              theme='netral'
              type='button'
              label={i18n._(t`Batal`)}
              onClick={close}
              style={{ width: '97px' }}
            />
            <Button
              size='medium'
              theme={submitTheme}
              type='submit'
              label={
                submitTheme === 'primary'
                  ? i18n._(t`Gunakan`)
                  : i18n._(t`Hapus`)
              }
              onClick={submit}
              style={{ width: '105px' }}
            />
          </Sided.Sided>
        </Panel.Content>
      </Panel.Panel>
    </Modal>
  )
}
