import React, { useEffect } from 'react'
import { Modal, ModalProps } from 'views/components/Modal'
import {
  Panel,
  Break,
  Wrapper,
  Text,
  Field,
  Button,
  Form,
  FormAction,
} from 'views/components'
import {
  commonService,
  locationService,
  productService,
  userService,
} from 'injectors'
import ROUTE from 'constants/routes'
import { useSelector } from 'react-redux'
import selector, { convertAreasToDropdownItems } from '../../../selector'
import { getLocalStorage, setLocalStorage } from 'utilities/localStorage'
import { CONFIG_LOCALSTORAGE } from 'constants/localStorage'
import { history } from 'storages'
import { Debouncer } from 'utilities/debounce'
import { DropdownItem } from 'views/components/Field/Dropdown'
import { highlight } from 'utilities/strings/highlighter'
import { User } from 'domains/users/models'
import { OnboardingBusinessInfoSchema } from './formSchema'
import moment from 'moment'
import { firebaseFunc } from 'utilities/firebase'
import netcore from 'utilities/netcore'
import { BusinessProfileForm } from 'views/modules/Settings/BusinessProfile/businessProfileFormSchema'

type OnboardingBusinessInfoModalProps = ModalProps

export interface OnboardingModalAction
  extends OnboardingBusinessInfoModalProps {
  next: () => void
}

const OnboardingBusinessInfo: React.FC<OnboardingBusinessInfoModalProps> =
  props => {
    const isOnBoardingStep2Closed = getLocalStorage(
      CONFIG_LOCALSTORAGE.IS_ONBOARDING_STEP2_CLOSED
    )

    useEffect(() => {
      userService.getUser()
      productService.getProducts({ limit: 10 })
      userService.getBusinessCategories()
      trackAnalytics()
    }, [])

    const { products, businessCategories, userInfo } = useSelector(selector)

    if (products.length !== 0 && isOnBoardingStep2Closed === 'true') {
      props.dismiss()
      history.push(ROUTE.MAINPAGE)
    }

    const trackAnalytics = () => {
      firebaseFunc.logEvent('business_information_start')
      netcore.dispatchEvent('business_information_start', {
        source: 'welcome screen',
      })
    }

    const debouncer = new Debouncer()
    const handleAreaSearch = async (
      keyword: string
    ): Promise<DropdownItem<string>[]> => {
      if (keyword.length < 3) {
        return []
      }
      return debouncer.debounce(async () => {
        const queryParams = {
          query: keyword,
        }
        const areas = await locationService.getAreas(queryParams)
        return convertAreasToDropdownItems(areas).map(x => ({
          ...x,
          label: highlight(x.label, keyword.toUpperCase(), y => `<b>${y}</b>`),
        }))
      }, 500)
    }

    const handleSkipOnboarding = () => {
      firebaseFunc.logEvent('profiling_skip')
      netcore.dispatchEvent('profiling_skip', {
        days:
          7 - moment(userInfo.createdAt).add(7, 'days').diff(moment(), 'days'),
      })
      setLocalStorage(
        userInfo.id + CONFIG_LOCALSTORAGE.IS_FILL_BUSINESS_INFO,
        String(moment(userInfo.createdAt).add(7, 'days').diff(moment(), 'days'))
      )
      setLocalStorage(
        userInfo.id + CONFIG_LOCALSTORAGE.CURRENT_DAY,
        String(moment(userInfo.createdAt).add(7, 'days').diff(moment(), 'days'))
      )
      props.close(true)
    }

    const getBusinessCategoryName = (id: string) => {
      const currentBusinessCategory = businessCategories.find(function (x) {
        return x.value === id
      })
      return currentBusinessCategory?.label
    }

    const submit = async (actions: FormAction<User>) => {
      try {
        const formValues = actions.getValues()
        const newBusinessProfile: BusinessProfileForm = {
          ...formValues,
          businessCategory: getBusinessCategoryName(
            actions.getValues().businessCategoryId
          ),
        }

        await userService.updateUser(newBusinessProfile)
        firebaseFunc.logEvent('profiling_complete')
        netcore.dispatchEvent('profiling_complete')
        netcore.dispatchEvent('business_information_complete', {
          source: 'welcome screen',
          days:
            7 -
            moment(userInfo.createdAt).add(7, 'days').diff(moment(), 'days'),
        })
        commonService.setAlert({
          message: 'Informasi Bisnis berhasil diubah!',
          type: 'success',
        })
        setLocalStorage(
          userInfo.id + CONFIG_LOCALSTORAGE.IS_FILL_BUSINESS_INFO,
          'false'
        )
        props.close(true)
      } catch (error) {
        commonService.setAlert({
          type: 'success',
          message: error.message,
        })
      }
    }

    const renderContent = () => {
      return (
        <Wrapper verticalGap={40}>
          <Wrapper>
            <Text.Heading h={2} align='center'>
              Informasi Bisnis
            </Text.Heading>
            <Text.Paragraph size={18} align='center'>
              Lengkapi data berikut untuk memudahkan kami dalam membantu bisnis
              Kamu
            </Text.Paragraph>
          </Wrapper>
          <Form.Form
            initialValues={{
              ...userInfo,
              businessCategory: '',
              areaId: '',
            }}
            validationSchema={OnboardingBusinessInfoSchema}
            onSubmit={submit}
            render={(actions: FormAction<User>) => (
              <Wrapper
                width='520px'
                isCenter
                isCorner
                border='solid 1px #e0e0e0'
                padding='40px'
                verticalGap={40}
              >
                <Wrapper>
                  <Field.Dropdown
                    label='Kategori Bisnis'
                    name='businessCategoryId'
                    placeholder='Pilih Kategori Bisnis'
                    list={businessCategories}
                    multilevel={true}
                  />
                  <Field.LocationAutoComplete
                    label='Lokasi Bisnis (Kelurahan)'
                    name='areaId'
                    searchFunc={handleAreaSearch}
                    placeholder='Pilih Lokasi Bisnis'
                    mobileView={true}
                  />
                </Wrapper>
                <Wrapper>
                  <Button
                    disabled={!actions.isValid()}
                    type='submit'
                    size='large'
                    theme='primary'
                    label='Simpan'
                    fullW
                  />
                </Wrapper>
              </Wrapper>
            )}
          />
          {moment(moment()).isBefore(
            moment(userInfo.createdAt).add(7, 'days')
          ) && (
            <Wrapper align='center'>
              <Text.Span
                style={{ cursor: 'pointer' }}
                color='blue'
                onClick={() => handleSkipOnboarding()}
              >
                Klik disini untuk melewati langkah ini
              </Text.Span>
            </Wrapper>
          )}
        </Wrapper>
      )
    }

    return (
      <Modal
        dismiss={props.dismiss}
        type='blank'
        width='1024px'
        canDismissOutside={false}
      >
        <Panel.Panel>
          <Panel.Content>
            <Break height={32} />
            <Wrapper height='500px' position='relative'>
              {renderContent()}
            </Wrapper>
            <Break height={32} />
          </Panel.Content>
        </Panel.Panel>
      </Modal>
    )
  }

export default OnboardingBusinessInfo
