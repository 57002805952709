import React from 'react'
import { Collapse as AntCollapse } from 'antd'
import { CollapsePanelProps, CollapseProps } from 'antd/lib/collapse'
import './style.scss'

type _CollapseProps = CollapseProps

export const Collapse: React.FC<_CollapseProps> = props => {
  return (
    <div className='post-collapsible'>
      <AntCollapse {...props}>{props.children}</AntCollapse>
    </div>
  )
}

export const CollapsePanel: React.FC<CollapsePanelProps> = props => {
  return <AntCollapse.Panel {...props}>{props.children}</AntCollapse.Panel>
}
