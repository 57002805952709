import React from 'react'
import { Wrapper, VSided, Sided, Text, Break } from 'views/components'
import { commonService } from 'injectors'
import { cdnImageUrl } from 'constants/cdnUrl/imageUrl'

interface QRISBannerComponentProps {
  size?: 'small' | 'default'
  onCloseModal?: () => void
  onSuccessModal?: () => void
}

const QRISBannerComponent = ({
  size,
  onCloseModal,
  onSuccessModal,
}: QRISBannerComponentProps) => {
  return (
    <Wrapper
      width='100%'
      backgroundColor='#1161BF'
      padding={size === 'small' ? '0' : '24px'}
      style={{
        backgroundImage: `url("${cdnImageUrl}images/qris-banner-bg.png")`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPositionX: 'right',
        borderRadius: '4px',
      }}
      onClick={() => {
        commonService.setQrisModal({
          visible: true,
          onClose: onCloseModal,
          onSuccess: onSuccessModal,
        })
      }}
    >
      {size === 'small' ? (
        <VSided.VSided>
          <VSided.Remaining>
            <Wrapper padding='20px 8px 8px' height='60px'>
              <Text.Paragraph size={12} color='white' noMargin>
                Terima semua metode
                <br /> pembayaran lewat{' '}
                <b>
                  QRIS <i>POST.</i>
                </b>
              </Text.Paragraph>
            </Wrapper>
          </VSided.Remaining>
          <VSided.Fixed>
            <Wrapper
              backgroundColor='#1557A7'
              padding='8px'
              style={{ borderRadius: '0 0 4px 4px' }}
            >
              <Text.Paragraph size={10} color='white' align='center' noMargin>
                Tersedia untuk <b>Lite & Premium users</b> tanpa daftar!
              </Text.Paragraph>
            </Wrapper>
          </VSided.Fixed>
        </VSided.VSided>
      ) : (
        <Sided.Sided>
          <Sided.Remaining>
            <Text.Paragraph size={16} color='white' noMargin>
              Terima semua metode pembayaran lewat{' '}
              <b>
                QRIS <i>POST.</i>
              </b>
            </Text.Paragraph>
            <Break height={6} />
            <Text.Paragraph size={12} color='white' noMargin>
              Tersedia untuk <b>Lite & Premium users</b> tanpa daftar!
            </Text.Paragraph>
          </Sided.Remaining>
        </Sided.Sided>
      )}
    </Wrapper>
  )
}

export default QRISBannerComponent
