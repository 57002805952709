import { OutletGeolocation } from 'domains/management/outlets/models'
import { locationService } from 'injectors'
import React, { useState } from 'react'
import { Debouncer } from 'utilities/debounce'
import { highlight } from 'utilities/strings/highlighter'

import {
  Break,
  Button,
  Panel,
  Sided,
  Text,
  Wrapper,
  Field,
  Form,
  FormAction,
} from 'views/components'
import { DropdownItem } from 'views/components/Field/Dropdown'
import GoogleMaps from 'views/components/GoogleMaps'
import { Modal, ModalProps } from 'views/components/Modal'
import { convertAreasToDropdownItems } from 'views/modules/Home/selector'

export interface EditAddressProps extends ModalProps {
  latitude?: number
  longitude?: number
  areaId?: string
  mapAddress?: string
}

const EditAddressModal: React.FC<EditAddressProps> = props => {
  const [postalCode, setPostalCode] = useState(null)

  const submit = async (actions: FormAction<OutletGeolocation>) => {
    props.close(actions.getValues())
  }

  const handleAreaSearch = async (
    keyword: string
  ): Promise<DropdownItem<string>[]> => {
    if (keyword.length < 3) {
      return []
    }
    const debouncer = new Debouncer()
    return debouncer.debounce(async () => {
      const queryParams = {
        query: keyword,
      }
      const areas = await locationService.getAreas(queryParams)
      return convertAreasToDropdownItems(areas).map(x => ({
        ...x,
        label: highlight(x.label, keyword.toUpperCase(), y => `<b>${y}</b>`),
      }))
    }, 500)
  }

  const resolveInitValue = async (areaId: string): Promise<DropdownItem> => {
    const location = await locationService.getArea(areaId)
    if (!location) {
      return undefined
    }
    return convertAreasToDropdownItems([location])[0]
  }

  const handleOnChangeAreaId = async (areaId: string) => {
    try {
      const response = await locationService.getArea(areaId)
      setPostalCode(response.postalCode)
    } catch (error) {
      console.error(error)
    }
  }

  const renderFormContent = (actions: FormAction<OutletGeolocation>) => {
    return (
      <>
        <Wrapper style={{ marginBottom: '320px' }}>
          <GoogleMaps
            actions={actions}
            initLatitude={props.latitude}
            initLongitude={props.longitude}
          />
        </Wrapper>
        <Field.TextArea
          maxLength={160}
          label='Alamat Lengkap'
          name='mapAddress'
          placeholder='Contoh: Nama jalan, nomor rumah, blok'
        />
        <Field.LocationAutoComplete
          label='Kelurahan'
          name='areaId'
          searchFunc={handleAreaSearch}
          initValue={props.areaId}
          placeholder='Tulis Kelurahan'
          resolveInitValueFunc={resolveInitValue}
          onChange={val => handleOnChangeAreaId(val)}
          showUpside={true}
        />
        {postalCode && (
          <>
            <Text.Span>Kode Pos</Text.Span>{' '}
            <Text.Span weight='bold'>{postalCode}</Text.Span>
          </>
        )}
      </>
    )
  }

  const Footer = () => {
    return (
      <Wrapper
        width='100%'
        padding='16px'
        backgroundColor='white'
        style={{ position: 'relative' }}
      >
        <Sided.Sided>
          <Sided.Remaining>
            <Button
              size='large'
              theme='netral'
              label='Batal'
              fullW={true}
              onClick={() => {
                props.dismiss()
                console.log('test')
              }}
            />
          </Sided.Remaining>
          <Sided.Remaining>
            <Button
              size='large'
              theme='primary'
              type='submit'
              label='Lanjut'
              fullW={true}
            />
          </Sided.Remaining>
        </Sided.Sided>
      </Wrapper>
    )
  }

  return (
    <Form.Form
      height='100%'
      initialValues={
        {
          latitude: props.latitude ? props.latitude : undefined,
          longitude: props.longitude ? props.longitude : undefined,
          areaId: props.areaId ? props.areaId : undefined,
          mapAddress: props.mapAddress ? props.mapAddress : undefined,
        } as OutletGeolocation
      }
      validationSchema={null}
      onSubmit={submit}
      render={(actions: FormAction<OutletGeolocation>) => {
        return (
          <Modal
            width={'600px'}
            dismiss={props.dismiss}
            type='blank'
            canDismissOutside={false}
          >
            <Panel.Panel>
              <Panel.Header>
                <Text.Heading h={5}>Alamat</Text.Heading>
              </Panel.Header>
              <Panel.Content>
                <Break height={20} />
                {renderFormContent(actions)}
                <Break height={20} />
              </Panel.Content>
            </Panel.Panel>
            <Footer />
          </Modal>
        )
      }}
    />
  )
}

export default EditAddressModal
