import React from 'react'
import classNames from 'classnames'
import './style.css'

interface Props {
  height?: 6 | 8 | 10 | 12 | 14 | 16 | 18 | 20 | 24 | 32 | 40 | 120
}

const Break = (props: Props) => {
  const className = classNames(
    'post-break',
    'post-break-' + (props.height || 0)
  )
  return <div className={className} />
}

export default Break
export { Break }
