import { AppState } from 'domains/contracts/appState'
import {
  SubscriptionPackagePricing,
  SubscriptionPackage,
  SubscriptionMethod,
  SubscriptionAlert,
  Subscription,
} from 'domains/subscriptions/model'
import { User } from 'domains/users/models'
import { toList } from 'utilities/converter/list'
import { DropdownItem } from 'views/components/Field/Dropdown'

export interface SubscriptionsViewModel {
  availablePackages: SubscriptionPackagePricing[]
  availablePackagesDropdownList: AvailablePackagesDropdown[]
  subscriptionPackages: SubscriptionPackage[]
  premiumPackage: SubscriptionPackage
  subscriptionMethod: SubscriptionMethod[]
  subscriptionAlert: SubscriptionAlert
  currentSubscription?: Subscription
  subscriptionList: Subscription[]
  oneMonthPricing: number
  userInfo: User
}

export interface AvailablePackagesDropdown extends DropdownItem {
  price: number
  pricing: SubscriptionPackagePricing
}

export const translateAvailablePackage = (item: SubscriptionPackagePricing) => {
  switch (item.id) {
    case 'premium_1m':
      return 'POST 1 Bulan'
    case 'premium_3m':
      return 'POST 3 Bulan'
    case 'premium_6m':
      return 'POST 6 Bulan'
    case 'premium_12m':
      return 'POST 12 Bulan'
    default:
      return ''
  }
}

export const availablePackagesForDropdown = (
  availablePackages: SubscriptionPackagePricing[]
): AvailablePackagesDropdown[] => {
  return availablePackages
    ? availablePackages.map(item => ({
        value: item.id,
        label: translateAvailablePackage(item),
        price: item.price,
        pricing: item,
        secondaryLabel: item.id === 'premium_12m' ? 'Lebih hemat!' : '',
      }))
    : []
}

export default (state: AppState): SubscriptionsViewModel => {
  const oneMonthSubscriptionPackage = toList(
    state.subscriptions.availablePackages
  ).filter(x => x.id == 'premium_1m')[0]
  return {
    userInfo: state.user,
    availablePackages: toList(state.subscriptions.availablePackages).sort(
      (a, b) => a.duration - b.duration
    ),
    availablePackagesDropdownList: availablePackagesForDropdown(
      toList(state.subscriptions.availablePackages)
    ),
    subscriptionPackages: state.subscriptions.subscriptionPackage,
    premiumPackage: state.subscriptions.subscriptionPackage.filter(
      x => x.name == 'Premium'
    )[0],
    oneMonthPricing: oneMonthSubscriptionPackage
      ? oneMonthSubscriptionPackage.price
      : 0,
    subscriptionMethod: toList(state.subscriptions.subscriptionMethod),
    subscriptionAlert: state.subscriptions.subscriptionAlert,
    subscriptionList: toList(state.subscriptions.subscriptions),
    currentSubscription: toList(state.subscriptions.subscriptions).filter(
      x => x.status.toUpperCase() === 'WAITING_PAYMENT'
    )[0],
  }
}
