/* eslint-disable import/no-duplicates */
import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/analytics'
import 'firebase/database'
import 'firebase/remote-config'
import 'firebase/performance'
import { getAppConfig } from 'config'

export interface FirebaseFunctions {
  initializeFirebase: () => void
  logEvent: (eventName: string, eventParams?: { [key: string]: any }) => void
  fetchVoucherPopupData: () => any
  getRemoteConfigValue: (id: string) => Promise<any>
}

const checkIsTracking = (): boolean => {
  const appConfigdetail = getAppConfig().detail
  return appConfigdetail.isTracking && !!process.env.REACT_APP_FIREBASE_API_KEY
}

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: 'post-web-46a8b.firebaseapp.com',
  databaseURL: 'https://post-web-46a8b.firebaseio.com',
  projectId: 'post-web-46a8b',
  storageBucket: 'post-web-46a8b.appspot.com',
  messagingSenderId: '837117142790',
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: 'G-Z84R15WV0K',
}

const initializeFirebase = () => {
  if (checkIsTracking()) {
    // Initialize Firebase App
    firebase.initializeApp(firebaseConfig)
    // Initialize Firebase Analytic
    firebase.analytics()
    // Initialize Firebase Database
    firebase.database()
    // Initialize Firebase Performance
    firebase.performance()

    console.log('Firebase initiated')
  }
}

const fetchVoucherPopupData = () => {
  if (checkIsTracking()) {
    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig)
    }
    return firebase.database().ref('voucher_popup_config')
  }
  return {} as any
}

const logEvent = async (
  eventName: string,
  eventParams?: { [key: string]: any }
) => {
  if (checkIsTracking()) {
    firebase.analytics().logEvent(eventName, eventParams)
  }
}

const getRemoteConfigValue = async (id: string) => {
  if (checkIsTracking()) {
    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig)
    }
    const remoteConfig = firebase.remoteConfig()
    remoteConfig.settings = {
      fetchTimeoutMillis: 60000,
      minimumFetchIntervalMillis: 1,
    }
    await remoteConfig.fetchAndActivate()
    const value = remoteConfig.getValue(id)
    if (value) {
      return value
    } else {
      return null
    }
  }
  return null
}

export const firebaseFunc: FirebaseFunctions = {
  initializeFirebase,
  logEvent,
  fetchVoucherPopupData,
  getRemoteConfigValue,
}
