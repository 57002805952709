import { Reducer, StateActions } from 'utilities/redux/reducer'
import {
  ProductState,
  ProductListQueryString,
} from 'domains/management/products/states'
import {
  SET_PRODUCTS,
  SET_COMBO_PRODUCTS,
  SET_VARIATION_PRODUCTS,
  SET_PRODUCT_LIST_REQUEST,
  PUSH_PRODUCTS,
  SET_PRODUCT_LIST_REACH_BOTTOM,
  SET_LOADING_MORE_PRODUCT,
  UNSHIFT_PRODUCTS,
  SET_PRODUCTS_V2,
  PUSH_PRODUCTS_V2,
  SET_PRODUCT_LIST_REQUEST_V2,
  SET_PRODUCT_LIST_REACH_BOTTOM_V2,
  SET_LOADING_MORE_PRODUCT_V2,
} from './actions'
import { Dict } from 'utilities/type'
import {
  ProductListV2QueryParams,
  ProductModel,
  ProductV2Model,
} from 'domains/management/products/models'

export class ProductReducer extends Reducer<ProductState> {
  constructor() {
    super({
      products: {},
      productsV2: {},
      comboProducts: {},
      variationProducts: {},
      selectedOutletId: '',
      stockStatus: '',
      productListManager: {
        isLoadingMoreProduct: false,
        request: {},
        hasReachedBottom: false,
      },
      productV2ListManager: {
        isLoadingMoreProduct: false,
        request: {},
        hasReachedBottom: false,
      },
      productKeyword: '',
    })
  }

  setProducts = (
    state: ProductState,
    products: Dict<ProductModel>
  ): ProductState => {
    return {
      ...state,
      products: products,
    }
  }

  pushProducts = (
    state: ProductState,
    products: Dict<ProductModel>
  ): ProductState => {
    return {
      ...state,
      products: {
        ...state.products,
        ...products,
      },
    }
  }

  setProductsV2 = (
    state: ProductState,
    productsV2: Dict<ProductV2Model>
  ): ProductState => {
    return {
      ...state,
      productsV2,
    }
  }

  pushProductsV2 = (
    state: ProductState,
    productsV2: Dict<ProductV2Model>
  ): ProductState => {
    return {
      ...state,
      productsV2: {
        ...state.productsV2,
        ...productsV2,
      },
    }
  }

  unshiftProduct = (
    state: ProductState,
    products: Dict<ProductModel>
  ): ProductState => {
    return {
      ...state,
      products: {
        ...products,
        ...state.products,
      },
    }
  }

  setComboProduct = (
    state: ProductState,
    comboProducts: Dict<ProductModel>
  ): ProductState => {
    return {
      ...state,
      comboProducts: comboProducts,
    }
  }

  setVariationProduct = (
    state: ProductState,
    variationProductDict: Dict<ProductModel>
  ): ProductState => {
    return {
      ...state,
      variationProducts: variationProductDict,
    }
  }

  setProductListReachBottom = (
    state: ProductState,
    hasReachedBottom: boolean
  ): ProductState => {
    return {
      ...state,
      productListManager: {
        ...state.productListManager,
        hasReachedBottom,
      },
    }
  }

  setLoadingMoreProduct = (
    state: ProductState,
    isLoadingMoreProduct: boolean
  ): ProductState => {
    return {
      ...state,
      productListManager: {
        ...state.productListManager,
        isLoadingMoreProduct,
      },
    }
  }

  setProductListRequest = (
    state: ProductState,
    request: ProductListQueryString
  ): ProductState => {
    return {
      ...state,
      productListManager: {
        ...state.productListManager,
        request,
      },
    }
  }

  setProductListReachBottomV2 = (
    state: ProductState,
    hasReachedBottom: boolean
  ): ProductState => {
    return {
      ...state,
      productV2ListManager: {
        ...state.productV2ListManager,
        hasReachedBottom,
      },
    }
  }

  setLoadingMoreProductV2 = (
    state: ProductState,
    isLoadingMoreProduct: boolean
  ): ProductState => {
    return {
      ...state,
      productV2ListManager: {
        ...state.productV2ListManager,
        isLoadingMoreProduct,
      },
    }
  }

  setProductListRequestV2 = (
    state: ProductState,
    request: ProductListV2QueryParams
  ): ProductState => {
    return {
      ...state,
      productV2ListManager: {
        ...state.productV2ListManager,
        request,
      },
    }
  }

  get actions(): StateActions<ProductState> {
    return {
      [SET_PRODUCTS]: this.setProducts,
      [PUSH_PRODUCTS]: this.pushProducts,
      [SET_PRODUCTS_V2]: this.setProductsV2,
      [PUSH_PRODUCTS_V2]: this.pushProductsV2,
      [UNSHIFT_PRODUCTS]: this.unshiftProduct,
      [SET_PRODUCT_LIST_REACH_BOTTOM]: this.setProductListReachBottom,
      [SET_LOADING_MORE_PRODUCT]: this.setLoadingMoreProduct,
      [SET_PRODUCT_LIST_REQUEST]: this.setProductListRequest,
      [SET_PRODUCT_LIST_REACH_BOTTOM_V2]: this.setProductListReachBottomV2,
      [SET_LOADING_MORE_PRODUCT_V2]: this.setLoadingMoreProductV2,
      [SET_PRODUCT_LIST_REQUEST_V2]: this.setProductListRequestV2,
      [SET_COMBO_PRODUCTS]: this.setComboProduct,
      [SET_VARIATION_PRODUCTS]: this.setVariationProduct,
    }
  }
}
