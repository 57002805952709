import { DropdownItem } from 'views/components/Field/Dropdown'
import { ReportingFilter } from 'domains/reporting/models'
import { forEachDict, toList } from 'utilities/converter/list'
import { AppState } from 'domains/contracts/appState'
import { BusinessCategory, User } from 'domains/users/models'
import { Dict, Group } from 'utilities/type'
import { CardData } from 'domains/home/states'
import { SubscriptionAlert } from 'domains/subscriptions/model'
import { OutletModel } from 'domains/management/outlets/models'
import { ProductModel } from 'domains/management/products/models'
import { DateRange } from 'domains/commons/models'
import { LocationModel } from 'domains/location/models'

interface HomeViewModel {
  cardVisibility: Dict<boolean>
  outletDropdownList: DropdownItem[]
  cardData: CardData
  filter: ReportingFilter
  user: User
  subsAlert: SubscriptionAlert
  products: ProductModel[]
  calendarType: string
  homeDateRange: DateRange
  liteUserOutletDropdownList: DropdownItem[]
  areas: DropdownItem[]
  businessCategories: DropdownItem[]
  userInfo: User
  subscriptionAlert: SubscriptionAlert
  qrisBannerVisible: boolean
}

const outletForDropdown = (outlets: OutletModel[]): DropdownItem[] => {
  const dropdownItems: DropdownItem[] = outlets
    ? outlets.map((item, i) => ({
        value: item.id,
        label: item.name,
      }))
    : []

  if (dropdownItems.length > 0) {
    dropdownItems.unshift({
      label: 'Semua Outlet',
      value: '',
    })
  }

  return dropdownItems
}

const outletLiteUserForDropdown = (outlets: OutletModel[]): DropdownItem[] => {
  return outlets
    ? outlets.map((item, i) => ({
        value: item.id,
        label: item.name,
      }))
    : []
}

export const businessCategoryForDropdown = (
  businessCategories: Group<BusinessCategory>
): DropdownItem[] => {
  if (!businessCategories) {
    return []
  }

  let dropdownItems: DropdownItem[] = []

  forEachDict(businessCategories, (key, list) => {
    dropdownItems.push({
      label: key,
      value: null,
      asHeader: true,
      group: list.some(list => list.group === key) && key,
    })
    const newList = list.map((item, i) => ({
      value: item.id,
      label: item.name,
      indent: 1,
      group: item.group,
    }))
    dropdownItems = [...dropdownItems, ...newList]
  })

  return dropdownItems
}

export const convertAreasToDropdownItems = (
  areas: LocationModel[]
): DropdownItem[] => {
  return areas
    ? areas.map((item, i) => ({
        value: item.id,
        label: convertAreaToString(item),
        shownValue: item.village,
      }))
    : []
}

export function convertAreaToString(area: LocationModel): string {
  return `${area.village}, ${area.district}, ${area.regency}, ${area.province}`
}

export default (state: AppState): HomeViewModel => ({
  cardVisibility: state.home.cardVisibility,
  liteUserOutletDropdownList: outletLiteUserForDropdown(
    toList(state.management.outlet.outlets)
  ),
  cardData: state.home.cardData,
  user: state.user,
  outletDropdownList: outletForDropdown(
    toList(state.management.outlet.outlets)
  ),
  filter: state.home.filter,
  homeDateRange: state.home.filter.dateRange,
  subsAlert: state.subscriptions.subscriptionAlert,
  products: toList(state.management.product.products),
  calendarType: state.home.filter.calendarType,
  areas: convertAreasToDropdownItems(toList(state.location.areas)),
  businessCategories: businessCategoryForDropdown(
    state.user.businessCategories
  ),
  userInfo: state.user,
  subscriptionAlert: state.subscriptions.subscriptionAlert,
  qrisBannerVisible: state.common.qrisBannerVisible,
})
