import React from 'react'
import classNames from 'classnames'
import './style.css'
import { BadgeColor } from 'views/components/const'

interface Props {
  labelColor?: BadgeColor
  text?: string
  fullW?: boolean
  onClick?: () => any
  style?: React.CSSProperties
}

const Badge = (props: Props) => {
  const className = classNames(
    'post-badge-' + (props.labelColor || 'darkGray'),
    props.fullW ? 'post-badge-full' : ''
  )

  const style = {
    ...props.style,
    cursor: 'pointer',
  }

  return (
    <div onClick={props.onClick} style={style} className={className}>
      {props.text}
    </div>
  )
}

export default Badge
export { Badge }
