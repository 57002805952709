import {
  applyMiddleware,
  combineReducers,
  createStore,
  Middleware,
  ReducersMapObject,
  Store,
  AnyAction,
  compose,
} from 'redux'
import { AppState } from 'domains/contracts/appState'

import { composeWithDevTools } from 'redux-devtools-extension'
import { ReportingReducer } from './reporting/reducer'
import { LayoutReducer } from './layouts/reducer'
import { UserReducer } from './users/reducer'
import { SubscriptionReducer } from './subscriptions/reducer'
import { RegisterReducer } from './register/reducer'
import { ManagementReducer } from './management/reducer'
import { HomeReducers } from './home/reducer'
import { CommonReducer } from './common/reducer'
import { LOGOUT_USER } from './users/actions'
import { LocationReducer } from './location/reducer'
import { ContactlessReducer } from './contactless/reducer'
import { sentryReduxEnhancer } from 'utilities/sentry'

const logger: Middleware = () => next => action => {
  if (process.env.NODE_ENV !== 'production') {
    console.log(action)
  }
  return next(action)
}

export function configureStore(): Store<AppState> {
  let middleware = applyMiddleware(logger)

  if (process.env.NODE_ENV !== 'production') {
    middleware = composeWithDevTools(middleware)
  }

  const reducers: ReducersMapObject<AppState, any> = {
    home: new HomeReducers().build(),
    common: new CommonReducer().build(),
    reporting: new ReportingReducer().build(),
    layout: new LayoutReducer().build(),
    user: new UserReducer().build(),
    subscriptions: new SubscriptionReducer().build(),
    register: new RegisterReducer().build(),
    management: new ManagementReducer().build(),
    location: new LocationReducer().build(),
    contactless: new ContactlessReducer().build(),
  }

  const appReducer = combineReducers<AppState>(reducers)

  const rootReducer = (state: AppState, action: AnyAction) => {
    if (action.type === LOGOUT_USER) {
      state = undefined
    }
    return appReducer(state, action)
  }

  return createStore(rootReducer, compose(middleware, sentryReduxEnhancer))
}

export const AppStore = configureStore()
