export interface TableConfig<T, Row> {
  itemToRowFunc: (item: T, index: number) => Row
  headerFunc: (list: T[]) => Row[]
  footerFunc: (list: T[]) => Row[]
}

export interface ColumnConfig<T, Col> {
  headers?: (list: T[], conf?: ColumnConfig<T, Col>) => Col[]
  body?: (item: T, index: number, conf?: ColumnConfig<T, Col>) => Col
  footers?: (list: T[], conf?: ColumnConfig<T, Col>) => Col[]
}

export function convertToTableConfig<T, Row, Col>(
  configs: ColumnConfig<T, Col>[],
  colsToRowFunc: (c: Col[], conf: TableConfig<T, Row>) => Row
): TableConfig<T, Row> {
  const tConf: TableConfig<T, Row> = {
    headerFunc: (list: T[]): Row[] => {
      const rows: Col[][] = []
      configs.forEach((conf: ColumnConfig<T, Col>) => {
        const h: Col[] = conf.headers ? conf.headers(list, conf) : []
        h.forEach((col: Col, idx: number) => {
          if (!rows[idx]) {
            rows[idx] = []
          }
          rows[idx].push(col)
        })
      })
      return rows.map(cols => colsToRowFunc(cols, tConf))
    },
    itemToRowFunc: (item: T, index: number): Row => {
      const cols: Col[] = configs
        .filter(col => col.body)
        .map((conf: ColumnConfig<T, Col>) => {
          return conf.body(item, index, conf)
        })
      return colsToRowFunc(cols, tConf)
    },
    footerFunc: (list: T[]): Row[] => {
      const rows: Col[][] = []
      configs.forEach((conf: ColumnConfig<T, Col>) => {
        const h: Col[] = conf.footers ? conf.footers(list, conf) : []
        h.forEach((col: Col, idx: number) => {
          if (!rows[idx]) {
            rows[idx] = []
          }
          rows[idx].push(col)
        })
      })
      return rows.map(cols => colsToRowFunc(cols, tConf))
    },
  }

  return tConf
}
