import { BaseApi } from 'api/base.api'
import {
  GetIngredientQueryParams,
  IngredientModel,
  IngredientStockHistoryModel,
  GetIngredientStockHistoryQueryParams,
} from 'domains/ingredients/models/ingredient'

export default class IngredientApi extends BaseApi {
  constructor() {
    super('/v3')
  }

  public getIngredients(
    queryParams?: GetIngredientQueryParams
  ): Promise<IngredientModel[]> {
    return this.makeForList('GET', '/ingredients', queryParams)
  }

  public createIngredient(
    ingredient: IngredientModel
  ): Promise<IngredientModel> {
    return this.make('POST', '/ingredients', ingredient)
  }

  public updateIngredient(
    ingredient: IngredientModel
  ): Promise<IngredientModel> {
    return this.make('POST', `/ingredients/${ingredient.id}`, ingredient)
  }

  public deleteIngredient(ingredientId: string): Promise<void> {
    return this.make('DELETE', `/ingredients/${ingredientId}`)
  }

  public getIngredientStockHistory(
    ingredientId: string,
    queryParams?: GetIngredientStockHistoryQueryParams
  ): Promise<IngredientStockHistoryModel[]> {
    return this.makeForList(
      'GET',
      `/ingredients/${ingredientId}/stock-history`,
      queryParams
    )
  }
}
