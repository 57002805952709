import { Switch } from 'react-router-dom'
import React from 'react'
import { hot } from 'react-hot-loader/root'
import { routeFactory } from 'views/route.factory'
import { RouteItem } from 'domains/layouts/services/route.item'

interface Props {
  routes: RouteItem[]
}
const SwitchRoute: React.FC<Props> = (props: Props) => (
  <Switch>{routeFactory(props.routes)}</Switch>
)

export default hot(SwitchRoute)
