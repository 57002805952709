import React, { createRef, ReactNode, RefObject } from 'react'
import InputBase from '../base'
import Form, { RenderingFieldContext } from '../../Form'
import { REGEX } from 'constants/regex'
import { Text } from 'views/components'

interface Props {
  prefixName?: string
  mapSubItem?: (x: any) => any
  label?: string
  placeholder?: string
  name?: string
  isDisabled?: boolean
  value?: number
  onChange?: (val: number | undefined) => any
  onBlur?: (e: any) => void
  decimal?: boolean
  secondaryLabel?: string
  hideErrorMessage?: boolean
  className?: string
  prefix?: ReactNode
  suffix?: ReactNode
  unit?: string
}

const InputNumber = (props: Props) => {
  const inputRef: RefObject<HTMLInputElement> = createRef()

  const handleOnChange = (val: number | undefined) => {
    if (props.onChange) {
      props.onChange(val)
    }
  }

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (!props.decimal && !REGEX.NUMBER.test(e.key)) {
      e.preventDefault()
    }
    if (props.decimal && !REGEX.DECIMAL_ALLOW.test(e.key)) {
      e.preventDefault()
    }
  }

  const onWheel = () => {
    inputRef.current.blur()
  }

  return (
    <Form.Field
      prefixName={props.prefixName}
      name={props.name}
      mapSubItem={props.mapSubItem}
      render={({ field, value, onChange, onBlur }: RenderingFieldContext) => (
        <InputBase
          label={props.label}
          isDisabled={props.isDisabled}
          error={field.isDirty && field.error}
          secondaryLabel={props.secondaryLabel}
          hideErrorMessage={props.hideErrorMessage}
          className={props.className}
          prefix={props.prefix}
          suffix={props.suffix}
        >
          <>
            <input
              ref={inputRef}
              disabled={props.isDisabled}
              name={props.name}
              type='number'
              placeholder={props.placeholder}
              value={props.value || value}
              onBlur={props.onBlur}
              onKeyPress={handleKeyPress}
              onWheel={onWheel}
              onChange={evt => {
                let inputValue = evt.target.value
                if (
                  props.decimal &&
                  inputValue &&
                  !REGEX.DECIMAL_NUMBER.test(inputValue)
                ) {
                  inputValue = value
                }
                let val = parseFloat(inputValue)
                if (isNaN(val)) {
                  val = undefined
                }
                onChange(val)
                handleOnChange(val)
              }}
              data-testid='field-number'
              style={{
                width: props.unit ? '50%' : '100%',
              }}
            />
            {props.unit && (
              <Text.Span
                color='semiGray'
                style={{
                  position: 'absolute',
                  top: '8px',
                  right: '12px',
                }}
              >
                {props.unit}
              </Text.Span>
            )}
          </>
        </InputBase>
      )}
    />
  )
}

export default InputNumber
