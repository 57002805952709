import React from 'react'

import { isAnyOfTheseFeaturesEnabled, featureCheck } from 'config'
import ROUTE from 'constants/routes/index'
import {
  RouteItem,
  filterOnlyEnabled,
} from 'domains/layouts/services/route.item'
import { Authenticated } from 'middleware/authenticate'
import { Icon } from 'views/components'

export function generateDashboardRoutes(): RouteItem[] {
  const array: RouteItem[] = [
    {
      key: 'home',
      sidebarMenu: {
        label: 'Beranda',
        icon: <Icon.Home />,
      },
      path: ROUTE.MAINPAGE,
      component: 'views/modules/Home',
      middleware: [Authenticated],
      isEnabled: true,
    },
    {
      key: 'reporting',
      sidebarMenu: {
        label: 'Laporan',
        icon: <Icon.Report />,
      },
      middleware: [Authenticated],
      path: ROUTE.MAIN_REPORTING,
      isEnabled: isAnyOfTheseFeaturesEnabled(featureCheck.reporting.any),
      children: [
        {
          key: 'summary',
          sidebarMenu: {
            label: 'Ringkasan',
          },
          path: ROUTE.SUMMARY_REPORTING,
          isEnabled: isAnyOfTheseFeaturesEnabled(
            featureCheck.reporting.summary
          ),
          component: 'views/modules/Reporting/Summary',
        },
        {
          key: 'product_sales',
          sidebarMenu: {
            label: 'Penjualan Produk',
          },
          path: ROUTE.PRODUCT_SALES_REPORTING,
          isEnabled: isAnyOfTheseFeaturesEnabled(
            featureCheck.reporting.productSale
          ),
          component: 'views/modules/Reporting/ProductSale',
        },
        {
          key: 'cancellation',
          sidebarMenu: {
            label: 'Pembatalan',
          },
          path: ROUTE.CANCELLATION_REPORTING,
          isEnabled: isAnyOfTheseFeaturesEnabled(
            featureCheck.reporting.cancellation
          ),
          component: 'views/modules/Reporting/Cancellation',
        },
        {
          key: 'discount',
          sidebarMenu: {
            label: 'Diskon',
          },

          path: ROUTE.DISCOUNT_REPORTING,
          isEnabled: isAnyOfTheseFeaturesEnabled(
            featureCheck.reporting.discount
          ),
          component: 'views/modules/Reporting/Discount',
        },
        {
          key: 'shift',
          sidebarMenu: {
            label: 'Shift',
          },
          path: ROUTE.SHIFT_REPORTING,
          isEnabled: isAnyOfTheseFeaturesEnabled(featureCheck.reporting.shift),
          component: 'views/modules/Reporting/Shift',
        },
        {
          key: 'serviceType',
          sidebarMenu: {
            label: 'Tipe Pesanan',
          },
          path: ROUTE.ORDER_TYPE_REPORTING,
          isEnabled: isAnyOfTheseFeaturesEnabled(
            featureCheck.reporting.serviceType
          ),
          component: 'views/modules/Reporting/ServiceType',
        },
        {
          key: 'paymentMethod',
          sidebarMenu: {
            label: 'Pembayaran',
          },
          path: ROUTE.PAYMENT_METHOD_REPORTING,
          isEnabled: isAnyOfTheseFeaturesEnabled(
            featureCheck.reporting.paymentMethod
          ),
          component: 'views/modules/Reporting/PaymentMethod',
        },
        {
          key: 'expenseReport',
          sidebarMenu: {
            label: 'Pengeluaran',
          },
          path: ROUTE.OUTPUT_REPORTING,
          isEnabled: isAnyOfTheseFeaturesEnabled(
            featureCheck.reporting.expense
          ),
          component: 'views/modules/Reporting/Expense',
        },
        {
          key: 'employeeReport',
          sidebarMenu: {
            label: 'Karyawan',
          },
          path: ROUTE.EMPLOYEE_REPORTING,
          isEnabled: isAnyOfTheseFeaturesEnabled(
            featureCheck.reporting.employee
          ),
          component: 'views/modules/Reporting/Employee',
        },
      ],
    },
    {
      key: 'management-product',
      sidebarMenu: {
        label: 'Produk',
        icon: <Icon.ManagementForProduct />,
      },

      middleware: [Authenticated],
      path: ROUTE.MAIN_MANAGEMENT,
      isEnabled: isAnyOfTheseFeaturesEnabled(featureCheck.management.any),
      children: [
        {
          key: 'productList',
          sidebarMenu: {
            label: 'Daftar Produk',
          },
          path: ROUTE.MANAGEMENT_PRODUCT_LIST,
          component: 'views/modules/Management/Product',
          isEnabled: isAnyOfTheseFeaturesEnabled(
            featureCheck.management.product
          ),
        },
        {
          key: 'category',
          sidebarMenu: {
            label: 'Kategori',
          },
          path: ROUTE.MANAGEMENT_PRODUCT_CATEGORY,
          component: 'views/modules/Management/Category/List',
          isEnabled: isAnyOfTheseFeaturesEnabled(
            featureCheck.management.category
          ),
        },
        {
          key: 'serviceType',
          sidebarMenu: {
            label: 'Tipe Pesanan',
          },
          path: ROUTE.MANAGEMENT_PRODUCT_ORDER_TYPE,
          component: 'views/modules/Management/ServiceType/List',
          isEnabled: isAnyOfTheseFeaturesEnabled(
            featureCheck.management.serviceType
          ),
        },
        {
          key: 'discount',
          sidebarMenu: {
            label: 'Diskon',
          },
          path: ROUTE.MANAGEMENT_PAYMENT_DISCOUNT,
          component: 'views/modules/Management/Discount',
          middleware: [Authenticated],
          isEnabled: isAnyOfTheseFeaturesEnabled(
            featureCheck.management.discount
          ),
        },
      ],
    },
    {
      key: 'customer',
      sidebarMenu: {
        label: 'Kelola Pelanggan',
        icon: <Icon.ManagementForShop />,
      },
      path: ROUTE.MANAGEMENT_CUSTOMER,
      component: 'views/modules/Management/Customer',
      isEnabled: isAnyOfTheseFeaturesEnabled(
        featureCheck.management.outerCustomer
      ),
    },
    {
      key: 'management-shop',
      sidebarMenu: {
        label: 'Kelola Toko',
        icon: <Icon.ManagementForShop />,
      },

      middleware: [Authenticated],
      path: ROUTE.MAIN_MANAGEMENT,
      isEnabled: isAnyOfTheseFeaturesEnabled(
        featureCheck.management.outerCustomer
      )
        ? false
        : isAnyOfTheseFeaturesEnabled(featureCheck.management.any),
      children: [
        {
          key: 'payment',
          sidebarMenu: {
            label: 'Pembayaran',
          },
          path: ROUTE.MANAGEMENT_PAYMENT,
          component: 'views/modules/Management/MenuPayment',
          isEnabled: isAnyOfTheseFeaturesEnabled(
            featureCheck.management.tax,
            featureCheck.management.paymentMethod
          ),
          children: [
            {
              key: 'paymentMethod',
              sidebarMenu: {
                label: 'Metode Pembayaran',
              },
              path: ROUTE.MANAGEMENT_PAYMENT_METHOD,
              component: 'views/modules/Management/PaymentMethod',
              middleware: [Authenticated],
              isEnabled: isAnyOfTheseFeaturesEnabled(
                featureCheck.management.paymentMethod
              ),
            },
            {
              key: 'taxService',
              sidebarMenu: {
                label: 'Pajak',
              },

              path: ROUTE.MANAGEMENT_PAYMENT_TAX_SERVICE_CHARGE,
              component: 'views/modules/Management/TaxServiceCharge',
              middleware: [Authenticated],
              isEnabled: isAnyOfTheseFeaturesEnabled(
                featureCheck.management.tax
              ),
            },
          ],
        },
        {
          key: 'outlet',
          sidebarMenu: {
            label: 'Outlet',
          },
          path: ROUTE.MANAGEMENT_OUTLET,
          component: 'views/modules/Management/MenuOutlet',
          isEnabled: isAnyOfTheseFeaturesEnabled(
            featureCheck.management.employee,
            featureCheck.management.device,
            featureCheck.management.shift,
            featureCheck.management.multiOutlet,
            featureCheck.management.customer,
            featureCheck.management.receipt,
            featureCheck.management.attendance
          ),
          children: [
            {
              key: 'employee',
              sidebarMenu: {
                label: 'Karyawan',
              },
              path: ROUTE.MANAGEMENT_OUTLET_EMPLOYEE,
              component: 'views/modules/Management/Employee',
              isEnabled: isAnyOfTheseFeaturesEnabled(
                featureCheck.management.employee
              ),
            },
            {
              key: 'device',
              sidebarMenu: {
                label: 'Kode Akses Kasir',
              },
              path: ROUTE.MANAGEMENT_OUTLET_DEVICE,
              component: 'views/modules/Management/Device',
              isEnabled: isAnyOfTheseFeaturesEnabled(
                featureCheck.management.device
              ),
            },
            {
              key: 'shift',
              sidebarMenu: {
                label: 'Shift',
              },
              path: ROUTE.MANAGEMENT_OUTLET_SHIFT,
              component: 'views/modules/Management/Shift',
              isEnabled: isAnyOfTheseFeaturesEnabled(
                featureCheck.management.shift
              ),
            },
            {
              key: 'multiOutlet',
              sidebarMenu: {
                label: 'Cabang (Outlet)',
              },
              path: ROUTE.MANAGEMENT_OUTLET_MULTIOUTLET,
              component: 'views/modules/Management/MultiOutlet',
              isEnabled: isAnyOfTheseFeaturesEnabled(
                featureCheck.management.multiOutlet
              ),
            },
            {
              key: 'customer',
              sidebarMenu: {
                label: 'Pelanggan',
              },
              path: ROUTE.MANAGEMENT_CUSTOMER,
              component: 'views/modules/Management/Customer',
              isEnabled: isAnyOfTheseFeaturesEnabled(
                featureCheck.management.customer
              ),
            },
            {
              key: 'receipt',
              sidebarMenu: {
                label: 'Struk',
              },
              path: ROUTE.MANAGEMENT_OUTLET_RECEIPT,
              component: 'views/modules/Management/Receipt',
              isEnabled: isAnyOfTheseFeaturesEnabled(
                featureCheck.management.receipt
              ),
            },

            {
              key: 'attendance',
              sidebarMenu: {
                label: 'Kehadiran',
              },
              path: ROUTE.MANAGEMENT_OUTLET_ATTENDANCE,
              component: 'views/modules/Management/Attendance',
              isEnabled: isAnyOfTheseFeaturesEnabled(
                featureCheck.management.attendance
              ),
            },
          ],
        },
      ],
    },
    {
      key: 'contactless',
      sidebarMenu: {
        label: 'Contactless',
        icon: <Icon.QRPayment />,
      },
      path: ROUTE.MAIN_CONTACTLESS,
      middleware: [Authenticated],
      isEnabled: isAnyOfTheseFeaturesEnabled(featureCheck.contactless.any),
      isNew: true,
      children: [
        {
          key: 'contactless-dining',
          sidebarMenu: {
            label: 'Contactless Dining',
          },
          path: ROUTE.CONTACTLESS_DINING,
          component: 'views/modules/Contactless/Dining',
          middleware: [Authenticated],
          isEnabled: isAnyOfTheseFeaturesEnabled(
            featureCheck.contactless.contactlessDining
          ),
        },
      ],
    },
    {
      key: 'setting',
      sidebarMenu: {
        label: 'Pengaturan Akun',
        icon: <Icon.Settings />,
      },
      path: ROUTE.MAIN_SETTINGS,
      middleware: [Authenticated],
      isEnabled: isAnyOfTheseFeaturesEnabled(featureCheck.setting.any),
      children: [
        {
          key: 'account',
          sidebarMenu: {
            label: 'Akun',
          },
          path: ROUTE.ACCOUNT_SETTINGS,
          component: 'views/modules/Settings',
          middleware: [Authenticated],
          isEnabled: isAnyOfTheseFeaturesEnabled(featureCheck.setting.account),
        },
        {
          key: 'businessInformation',
          sidebarMenu: {
            label: 'Informasi Bisnis',
          },
          path: ROUTE.BUSINESS_PROFILE,
          component: 'views/modules/Settings/BusinessProfile',
          middleware: [Authenticated],
          isEnabled: isAnyOfTheseFeaturesEnabled(
            featureCheck.setting.businessProfile
          ),
        },
        {
          key: 'subscriptionHistory',
          sidebarMenu: {
            label: 'Riwayat Berlangganan',
          },
          path: ROUTE.SUBSCRIPTION_HISTORY_SETTINGS,
          component: 'views/modules/Settings/SubscriptionHistory',
          middleware: [Authenticated],
          isEnabled: isAnyOfTheseFeaturesEnabled(
            featureCheck.setting.subscriptionHistory
          ),
        },
      ],
    },
    {
      key: 'Information',
      path: ROUTE.INFORMATION_SETTINGS,
      component: 'views/modules/Settings/Information',
      middleware: [Authenticated],
      isEnabled: isAnyOfTheseFeaturesEnabled(
        featureCheck.setting.generalInformation
      ),
    },
    {
      key: 'ResetDataManagement',
      path: ROUTE.RESET_DATA_MANAGEMENT_SETTINGS,
      component: 'views/modules/Settings/ResetData/ResetDataManagement',
      middleware: [Authenticated],
      isEnabled: isAnyOfTheseFeaturesEnabled(featureCheck.setting.resetData),
    },
    {
      key: 'ResetDataManagement',
      path: ROUTE.RESET_DATA_TRANSACTIONS,
      component: 'views/modules/Settings/ResetData/ResetDataTransaction',
      middleware: [Authenticated],
      isEnabled: isAnyOfTheseFeaturesEnabled(featureCheck.setting.resetData),
    },
    {
      key: 'helper',
      sidebarMenu: {
        label: 'Bantuan',
        icon: <Icon.HelpInfo />,
      },
      path: ROUTE.MAIN_HELPER,
      component: 'views/modules/HelpDesk',
      middleware: [Authenticated],
      isEnabled: isAnyOfTheseFeaturesEnabled(
        featureCheck.miscellaneous.helpdesk
      ),
    },
  ]
  return filterOnlyEnabled(array)
}
