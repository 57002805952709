import React from 'react'
import { Route } from 'react-router-dom'
import { RouteItem, flattenRoutes } from 'domains/layouts/services/route.item'
import AsyncPage from 'views/components/AsyncPage'

export function routeFactory(routes: RouteItem[]) {
  const parsedRoutes = flattenRoutes(routes)
  return parsedRoutes.map((value: RouteItem, index: number) => {
    return (
      <Route
        key={index}
        exact
        component={AsyncPage(
          () => import('../../src/' + value.component),
          value.middleware
        )}
        path={value.path}
      />
    )
  })
}
