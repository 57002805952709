import local from 'env/local'
import prod from 'env/production'
import staging from 'env/staging'
import { Dict } from 'utilities/type'

import { EnvironmentConfig } from './def'

const config: Dict<Readonly<EnvironmentConfig>> = {
  prod,
  local,
  staging,
}

export function getCurrentEnvironment(): Readonly<EnvironmentConfig> {
  const env = process.env.REACT_APP_MODE || 'staging'
  return config[env]
}
