import React from 'react'
import { Button, Text, Break, Panel, Divider, Wrapper } from 'views/components'
import { ModalProps, Modal } from 'views/components/Modal'

interface ConfirmationResetDataModalProps extends ModalProps {
  resetDataType?: string
}

export const ConfirmationResetDataModal: React.FC<ConfirmationResetDataModalProps> =
  props => {
    return (
      <Modal
        width={'470px'}
        dismiss={props.dismiss}
        type='blank'
        canDismissOutside={false}
      >
        <Panel.Panel>
          <Panel.Header
            style={{ backgroundColor: '#AC2C24', border: '1px solid #AC2C24' }}
          >
            <Text.Heading h={6} color='white' weight='bold'>
              {props.resetDataType === 'management'
                ? 'Data Manajemen '
                : 'Data Transaksi '}
              akan di hapus!
            </Text.Heading>
          </Panel.Header>
          <Panel.Content>
            <Text.Paragraph size={14} noMargin>
              Apakah anda yakin ingin <b>me-reset</b> dan <b>menghapus</b> semua
              data-data{' '}
              {props.resetDataType === 'management'
                ? ' manajemen '
                : ' transaksi '}
              ?
              <br />
              <br />
              Data yang akan terhapus:
              {props.resetDataType === 'management' ? (
                <ul>
                  <li style={{ fontWeight: 'bold' }}>
                    Data Produk, Bahan Baku, Resep
                  </li>
                  <li style={{ fontWeight: 'bold' }}>Data Kelola Toko</li>
                  <li style={{ fontWeight: 'bold' }}>Data Toko Online</li>
                  <li style={{ fontWeight: 'bold' }}>Data Pelanggan</li>
                </ul>
              ) : (
                <ul>
                  <li style={{ fontWeight: 'bold' }}>
                    Data Transaksi (Awal - Sekarang)
                  </li>
                  <li style={{ fontWeight: 'bold' }}>Laporan</li>
                  <li style={{ fontWeight: 'bold' }}>Shift</li>
                </ul>
              )}
            </Text.Paragraph>
            <Divider />
            <Break height={12} />
            <Wrapper align='right'>
              <Button
                size='large'
                theme='primaryOutline'
                type='submit'
                label={`Batal`}
                onClick={() => props.dismiss()}
                style={{ width: '100px', marginRight: '15px' }}
              />
              <Button
                size='large'
                theme='danger'
                label={`Ya, Hapus`}
                onClick={() => props.close(true)}
                style={{ width: '100px' }}
              />
            </Wrapper>
          </Panel.Content>
        </Panel.Panel>
      </Modal>
    )
  }
