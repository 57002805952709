import React from 'react'
import { ModalProps, Modal } from 'views/components/Modal'
import {
  Panel,
  Text,
  Icon,
  Break,
  Button,
  Wrapper,
  Sided,
} from 'views/components'

type Props = ModalProps

const LogoutConfirmationModal: React.FC<Props> = props => {
  return (
    <Modal
      width={'470px'}
      dismiss={props.dismiss}
      type='blank'
      canDismissOutside={false}
    >
      <Panel.Panel>
        <Panel.Header>
          <Sided.Sided justifyContent='center' justify='center'>
            <Sided.Remaining>
              <Text.Heading h={6} color='black'>
                Keluar Akun
              </Text.Heading>
            </Sided.Remaining>
            <Sided.Fixed>
              <Icon.Close
                size={24}
                style={{ cursor: 'pointer' }}
                onClick={() => props.dismiss()}
              />
            </Sided.Fixed>
          </Sided.Sided>
        </Panel.Header>
        <Panel.Content>
          <Text.Paragraph size={14} color='black'>
            Anda yakin ingin keluar?
          </Text.Paragraph>
          <Break height={16} />
          <Wrapper padding='5px'>
            <Sided.Sided justify='center' gutter={16}>
              <Sided.Remaining>
                <Button
                  size='large'
                  theme='netral'
                  type='button'
                  label='Batal'
                  fullW={true}
                  onClick={() => props.close(false)}
                />
              </Sided.Remaining>
              <Sided.Remaining>
                <Button
                  size='large'
                  theme='primary'
                  type='button'
                  label='Keluar'
                  fullW={true}
                  onClick={() => props.close(true)}
                />
              </Sided.Remaining>
            </Sided.Sided>
          </Wrapper>
        </Panel.Content>
      </Panel.Panel>
    </Modal>
  )
}

export default LogoutConfirmationModal
