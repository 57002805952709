import { BaseApi } from 'api/base.api'
import {
  RoleModel,
  GetRoleListQueryParams,
  RolePayload,
} from 'domains/management/role/models'

export default class RoleManagementApi extends BaseApi {
  constructor() {
    super('/outlet/v4')
  }
  public async getRoleList(
    queryParams: GetRoleListQueryParams
  ): Promise<RoleModel[]> {
    return await this.makeForCamelCase('GET', '/permission-sets', queryParams)
  }
  public async createRole(rolePayload: RolePayload): Promise<RoleModel> {
    return await this.makeForCamelCase('POST', '/permission-sets', rolePayload)
  }
  public async updateRole(
    roleId: string,
    rolePayload: RolePayload
  ): Promise<RoleModel> {
    return await this.makeForCamelCase(
      'PUT',
      `/permission-sets/${roleId}`,
      rolePayload
    )
  }
  public async deleteRole(roleId: string): Promise<void> {
    return await this.makeForCamelCase('DELETE', `/permission-sets/${roleId}`)
  }
}
