import { HomeState, CardData } from 'domains/home/states'
import { Reducer, StateActions } from 'utilities/redux/reducer'
import { Dict } from 'utilities/type'
import {
  SET_CARD_SUMMARY,
  TOGGLE_CARD_VISIBILITY,
  SET_ALL_CARD_VISIBLE,
  SET_HOME_FILTER_DATE_RANGE,
  SET_HOME_FILTER_OUTLET_ID,
  SET_HOME_FILTER_CALENDAR_DROPDOWN,
} from './actions'
import { getLocalStorage } from 'utilities/localStorage'
import { CONFIG_LOCALSTORAGE } from 'constants/localStorage'
import { DateRange } from 'domains/commons/models'

export class HomeReducers extends Reducer<HomeState> {
  constructor() {
    super({
      cardData: {},
      cardVisibility: JSON.parse(
        getLocalStorage(CONFIG_LOCALSTORAGE.HOME_CARD_VISIBILITY)
      ),
      filter: {},
    })
  }

  public setInformation(state: HomeState, cardData: Dict<CardData>): HomeState {
    return {
      ...state,
      cardData,
    }
  }

  public toggleCardVisibility(state: HomeState, key: string): HomeState {
    return {
      ...state,
      cardVisibility: {
        ...state.cardVisibility,
        [key]: !state.cardVisibility[key],
      },
    }
  }
  public setAllVisible(state: HomeState): HomeState {
    return {
      ...state,
      cardVisibility: {
        TOTALTRANSACTIONS: true,
        GROSSSALES: true,
        NETSALES: true,
        NETINCOME: true,
        TOPPRODUCTS: true,
        EXPENSE: true,
      },
    }
  }

  public setFilterDateRange(state: HomeState, dateRange: DateRange): HomeState {
    return {
      ...state,
      filter: {
        ...state.filter,
        dateRange,
      },
    }
  }

  public setFilterOutletId(
    state: HomeState,
    selectedOutletId: string
  ): HomeState {
    return {
      ...state,
      filter: {
        ...state.filter,
        selectedOutletId,
      },
    }
  }

  public setFilterCalendarDropdown(
    state: HomeState,
    calendarType: string
  ): HomeState {
    return {
      ...state,
      filter: {
        ...state.filter,
        calendarType,
      },
    }
  }

  get actions(): StateActions<HomeState> {
    return {
      [SET_CARD_SUMMARY]: this.setInformation,
      [TOGGLE_CARD_VISIBILITY]: this.toggleCardVisibility,
      [SET_ALL_CARD_VISIBLE]: this.setAllVisible,
      [SET_HOME_FILTER_DATE_RANGE]: this.setFilterDateRange,
      [SET_HOME_FILTER_OUTLET_ID]: this.setFilterOutletId,
      [SET_HOME_FILTER_CALENDAR_DROPDOWN]: this.setFilterCalendarDropdown,
    }
  }
}
