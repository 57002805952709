const criteriaList = [
  {
    type: 'MOBILE',
    func: (w: number, h: number) => w <= h,
  },
  {
    type: 'DESKTOP',
    func: (w: number, h: number) => w > h,
  },
]

export const getScreenType = (w: number, h: number) => {
  let result = ''
  criteriaList.forEach(x => {
    if (x.func(w, h)) {
      result = x.type
    }
  })
  return result
}
