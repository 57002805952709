import React from 'react'
import { ModalProps, Modal } from 'views/components/Modal'
import { Panel, Text, Icon, Break, Button, Sided } from 'views/components'
import { t, Trans } from '@lingui/macro'
import { useLingui } from '@lingui/react'

interface Props extends ModalProps {
  name: string
}

const DeleteConfirmationModal: React.FC<Props> = props => {
  const { i18n } = useLingui()

  return (
    <Modal
      width={'470px'}
      dismiss={props.dismiss}
      type='blank'
      canDismissOutside={false}
    >
      <Panel.Panel>
        <Panel.Header>
          <Sided.Sided justifyContent='center' justify='center'>
            <Sided.Remaining>
              <Trans>
                <Text.Paragraph size={14} color='black' weight='bold'>
                  Hapus Kategori "{props.name}"
                </Text.Paragraph>
              </Trans>
            </Sided.Remaining>
            <Sided.Fixed>
              <Icon.Close
                style={{ cursor: 'pointer' }}
                onClick={() => props.close(false)}
                color='black'
              />
            </Sided.Fixed>
          </Sided.Sided>
        </Panel.Header>
        <Panel.Content>
          <Trans>
            <Text.Paragraph>
              Kategori yang sudah dihapus tidak dapat dikembalikan lagi.
            </Text.Paragraph>
          </Trans>
          <Break height={32} />
          <Sided.Sided gutter={16} justifyContent='center'>
            <Sided.Remaining>
              <Button
                size='large'
                theme='netral'
                type='button'
                label={i18n._(t`Batal`)}
                fullW={true}
                onClick={() => props.close(false)}
              />
            </Sided.Remaining>
            <Sided.Remaining>
              <Button
                size='large'
                theme='danger'
                type='submit'
                label={i18n._(t`Hapus`)}
                fullW={true}
                onClick={() => props.close(true)}
              />
            </Sided.Remaining>
          </Sided.Sided>
        </Panel.Content>
      </Panel.Panel>
    </Modal>
  )
}

export default DeleteConfirmationModal
