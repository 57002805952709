import React, { useEffect, useState } from 'react'
import './style.css'
import {
  Sidebar,
  SwitchRoute,
  Sided,
  FeatureFlag,
  Wrapper,
  Text,
  Button,
  Icon,
  Loader,
} from 'views/components'
import { layoutService, userService } from 'injectors'
import { generateDashboardRoutes } from 'routes/dashboard'
import { generateModalRoutes } from 'routes/modal'
import { filterTreeList } from 'utilities/converter/list'
import { RouteItem } from 'domains/layouts/services/route.item'
import { featureCheck, isAnyOfTheseFeaturesEnabled } from 'config'
import selector from './selector'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { DefaultModalService } from 'views/components/Modal'
import OnboardingBusinessInfo from 'views/modules/Home/OnboardModal/BusinessInfo/OnboardingBusinessInfo'
import { getLocalStorage, setLocalStorage } from 'utilities/localStorage'
import { CONFIG_LOCALSTORAGE } from 'constants/localStorage'
import { useHistory } from 'react-router'
import { RegisterBusinessInfoModal } from '../BusinessFormModal'

interface AuthorizedLayoutProps {}

const AuthorizedLayout: React.FC<AuthorizedLayoutProps> = props => {
  const [routes, setRoutes] = useState<RouteItem[]>([])
  const [isLoading, setIsLoading] = useState(null)
  const [hideBusinessInfoNotif, setHideBusinessInfoNotif] =
    React.useState(false)
  const isBusinessInfoNotifClosed = getLocalStorage(
    CONFIG_LOCALSTORAGE.IS_BUSINESS_INFO_NOTIF_CLOSED
  )
  const isOwner =
    getLocalStorage(CONFIG_LOCALSTORAGE.POST_TYPE)?.toLowerCase() === 'owner'
  const history = useHistory()

  const { userInfo } = useSelector(selector)

  useEffect(() => {
    getUserInfo()
    if (isAnyOfTheseFeaturesEnabled(featureCheck.home.routingV2)) {
      layoutService.checkActiveChildV2()
    } else {
      layoutService.checkActiveChild()
      loadRoutes()
    }
  }, [])

  const getUserInfo = async () => {
    const employeeId = getLocalStorage(CONFIG_LOCALSTORAGE.STAFF_ID)
    if (!employeeId) {
      try {
        setIsLoading(true)
        const user = await userService.getUser()
        await userService.getBusinessCategories()
        if (user.businessName.length === 0) {
          DefaultModalService.open(RegisterBusinessInfoModal, {})
        }
        setIsLoading(false)
      } catch (error) {
        setIsLoading(false)
        console.error(error)
      }
    }
  }

  const loadRoutes = async () => {
    const configuredRoutes = await generateDashboardRoutes()
    setRoutes(configuredRoutes)
  }

  const renderLayout = () => {
    generateModalRoutes()
    const fullscreenRoutes = filterTreeList(routes, x => x.isFullScreen)
    const nonFullscreenRoutes = filterTreeList(routes, x => !x.isFullScreen)

    return (
      <React.Fragment>
        <Sided.Sided gutter={0} className='layout'>
          <Sided.Fixed minWidth={240} className='left-side'>
            <Sidebar />
          </Sided.Fixed>
          <Sided.Remaining className='right-side'>
            <div className='layout-content'>
              {isAnyOfTheseFeaturesEnabled(featureCheck.home.routingV2) ? (
                props.children
              ) : (
                <SwitchRoute routes={nonFullscreenRoutes} />
              )}
            </div>
          </Sided.Remaining>
        </Sided.Sided>
        <FeatureFlag featureCheck={featureCheck.home.routingV2}>
          <SwitchRoute routes={fullscreenRoutes} />
        </FeatureFlag>
      </React.Fragment>
    )
  }

  const days = moment(userInfo.createdAt).add(7, 'days').diff(moment(), 'days')
  const hours = moment(userInfo.createdAt)
    .add(7, 'days')
    .diff(moment(), 'hours')
  const minutes = moment(userInfo.createdAt)
    .add(7, 'days')
    .diff(moment(), 'minutes')

  const renderFillBusinessInfoNotif = () => {
    if (
      !userInfo.areaId &&
      !userInfo.businessCategory &&
      moment(moment()).isBefore(moment(userInfo.createdAt).add(7, 'days')) &&
      isBusinessInfoNotifClosed !== 'true'
    ) {
      return (
        <Wrapper
          padding='12px 55px'
          width='100%'
          backgroundColor='#FEF6D9'
          style={{ borderRadius: '4px' }}
        >
          <Sided.Sided justify='center'>
            <Sided.Remaining align='left'>
              <Text.Paragraph size={16}>
                <Text.Span weight='bold'>
                  Segera lengkapi Informasi bisnis kamu yang akan berakhir{' '}
                  <Text.Span>
                    {days < 1
                      ? hours < 1
                        ? minutes + ' menit lagi.'
                        : hours + ' jam lagi.'
                      : days + ' hari lagi.'}
                  </Text.Span>
                </Text.Span>
              </Text.Paragraph>
            </Sided.Remaining>
            <Sided.Remaining align='right'>
              <Sided.Sided justify='center'>
                <Sided.Remaining align='right'>
                  <Button
                    theme='primary'
                    fullW={true}
                    onClick={() =>
                      DefaultModalService.open(OnboardingBusinessInfo, {})
                    }
                    label='LENGKAPI SEKARANG'
                    style={{ width: '200px' }}
                    size='small'
                  ></Button>
                </Sided.Remaining>
                <Sided.Fixed align='right'>
                  <Wrapper onClick={() => {}}>
                    <Icon.Close24
                      color='black'
                      onClick={() => {
                        setLocalStorage(
                          CONFIG_LOCALSTORAGE.IS_BUSINESS_INFO_NOTIF_CLOSED,
                          'true'
                        )
                        setHideBusinessInfoNotif(true)
                      }}
                    />
                  </Wrapper>
                </Sided.Fixed>
              </Sided.Sided>
            </Sided.Remaining>
          </Sided.Sided>
        </Wrapper>
      )
    } else {
      return null
    }
  }

  const renderLayoutV2 = () => {
    const productV2Path = '/kelola/produk/daftar-produk'
    const classnameLayoutContent =
      history.location.pathname === productV2Path
        ? 'layout-content no-padding'
        : 'layout-content'

    return (
      <React.Fragment>
        {isAnyOfTheseFeaturesEnabled(featureCheck.home.registerRevamp) ===
          false &&
          isOwner &&
          !hideBusinessInfoNotif &&
          !isLoading &&
          renderFillBusinessInfoNotif()}
        <Sided.Sided gutter={0} className='layout'>
          <Sided.Fixed minWidth={265} className='left-side'>
            <Sidebar />
          </Sided.Fixed>
          <Sided.Remaining className='right-side'>
            <div className={classnameLayoutContent}>{props.children}</div>
          </Sided.Remaining>
        </Sided.Sided>
      </React.Fragment>
    )
  }

  return isLoading ? (
    <Loader />
  ) : isAnyOfTheseFeaturesEnabled(featureCheck.home.routingV2) ? (
    renderLayoutV2()
  ) : (
    renderLayout()
  )
}

export default AuthorizedLayout
