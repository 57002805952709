import { Reducer, StateActions } from 'utilities/redux/reducer'
import { RoleState } from 'domains/management/role/states'
import {
  RoleModel,
  GetRoleListQueryParams,
} from 'domains/management/role/models'
import {
  SET_ROLES,
  APPEND_ROLES,
  SET_ROLE_LIST_REACH_BOTTOM,
  SET_LOADING_MORE_ROLE,
  SET_ROLE_LIST_REQUEST,
} from './actions'

export class RoleReducer extends Reducer<RoleState> {
  constructor() {
    super({
      roles: [],
      roleListManager: {
        isLoadingMoreRole: false,
        request: {},
        hasReachedBottom: false,
      },
    })
  }

  setRoles = (state: RoleState, roles: RoleModel[]): RoleState => {
    return {
      ...state,
      roles,
    }
  }

  appendRoles = (state: RoleState, roles: RoleModel[]): RoleState => {
    return {
      ...state,
      roles: [...state.roles, ...roles],
    }
  }

  setRoleListReachBottom = (
    state: RoleState,
    hasReachedBottom: boolean
  ): RoleState => {
    return {
      ...state,
      roleListManager: {
        ...state.roleListManager,
        hasReachedBottom,
      },
    }
  }

  setLoadingMoreRole = (
    state: RoleState,
    isLoadingMoreRole: boolean
  ): RoleState => {
    return {
      ...state,
      roleListManager: {
        ...state.roleListManager,
        isLoadingMoreRole,
      },
    }
  }

  setRoleListRequest = (
    state: RoleState,
    request: GetRoleListQueryParams
  ): RoleState => {
    return {
      ...state,
      roleListManager: {
        ...state.roleListManager,
        request,
      },
    }
  }

  get actions(): StateActions<RoleState> {
    return {
      [SET_ROLES]: this.setRoles,
      [APPEND_ROLES]: this.appendRoles,
      [SET_ROLE_LIST_REACH_BOTTOM]: this.setRoleListReachBottom,
      [SET_LOADING_MORE_ROLE]: this.setLoadingMoreRole,
      [SET_ROLE_LIST_REQUEST]: this.setRoleListRequest,
    }
  }
}
