import { BaseService } from 'domains/commons/services'
import { LocationModel, GetLocationParams } from '../models'
import { ILocationStorage } from '../contracts/storage'
import { Api } from 'domains/contracts/api'
import { ICommonStorage } from 'domains/commons/contracts/storage'
import { toDict } from 'utilities/converter/list'
import { LocationListQueryString } from '../states'
import { SET_LOCATION } from 'storages/location/actions'

export class LocationService extends BaseService implements ILocationService {
  locationStorage: ILocationStorage

  constructor(
    commonStorage: ICommonStorage,
    api: Api,
    locationStorage: ILocationStorage
  ) {
    super(commonStorage, api)
    this.locationStorage = locationStorage
  }

  public getAreas = async (
    queryParams: LocationListQueryString = {}
  ): Promise<LocationModel[]> => {
    this.setLoading(SET_LOCATION, true)
    queryParams.limit = queryParams.limit || 10
    const locations = await this.api.location.getAreas(queryParams)
    this.locationStorage.setLocation(
      toDict(locations, (x: LocationModel) => x.id)
    )
    this.setLoading(SET_LOCATION, false)
    const lastLocationId = this.getLastLocationId(locations)
    this.locationStorage.setLocationListRequest({
      ...queryParams,
      startingAfter: lastLocationId || queryParams.startingAfter,
    } as LocationListQueryString)
    this.locationStorage.setLocationListReachBottom(locations.length == 0)

    return locations
  }

  public getArea = async (
    areaId: string
  ): Promise<LocationModel | undefined> => {
    try {
      const area = await this.api.location.getArea(areaId)
      this.locationStorage.setLocation({
        [area.id]: area,
      })
      return area
    } catch (error) {
      throw error
    }

    return undefined
  }

  private getLastLocationId = (
    locations: LocationModel[]
  ): string | undefined => {
    return locations.length > 0 ? locations[locations.length - 1].id : undefined
  }

  public loadMore = async (): Promise<LocationModel[]> => {
    const locationListManager = this.getState().location.locationListManager
    if (locationListManager.hasReachedBottom) {
      return Promise.resolve([])
    }
    if (locationListManager.isLoadingMoreLocation) {
      return Promise.resolve([])
    }
    this.locationStorage.setLoadingMoreLocation(true)
    const queryParams = locationListManager.request
    const locations = await this.api.location.getAreas(queryParams)
    this.locationStorage.pushLocations(
      toDict(locations, (x: LocationModel) => x.id)
    )
    const lastLocationId = this.getLastLocationId(locations)
    this.locationStorage.setLoadingMoreLocation(false)
    this.locationStorage.setLocationListRequest({
      ...queryParams,
      startingAfter: lastLocationId || queryParams.startingAfter,
    } as LocationListQueryString)
    this.locationStorage.setLocationListReachBottom(locations.length == 0)

    return locations
  }
}

export interface ILocationService {
  getAreas: (queryParams?: GetLocationParams) => Promise<LocationModel[]>
  getArea: (areaId: string) => Promise<LocationModel | undefined>
  loadMore: () => Promise<LocationModel[]>
}
