import { BaseApi } from 'api/base.api'
import {
  Order,
  OrderQueryParam,
  DisbursementReport,
  GetDisbursementQueryParams,
  DisbursementTotalQueryParams,
  DisbursementTotal,
} from 'domains/reporting/models'

import { toIOrderReport } from './model'
import { toQueryString } from 'api/converter'
import { INewReportingApi } from 'domains/reporting/contracts/v3.api'

export default class NewReportingApi
  extends BaseApi
  implements INewReportingApi
{
  constructor() {
    super('/v3/reports')
  }

  public async getOrderReport(
    queryParams?: OrderQueryParam,
    headers: object = {}
  ): Promise<Order[]> {
    try {
      const querystring = toQueryString(queryParams)
      const response = (await this.makeForCamelCase(
        'GET',
        '/orders' + querystring,
        {},
        {},
        headers
      )) as any
      return response.data.map(toIOrderReport)
    } catch (error) {
      throw error
    }
  }

  public async getDisbursementReport(
    queryParams?: GetDisbursementQueryParams
  ): Promise<DisbursementReport[]> {
    try {
      const querystring = toQueryString(queryParams)
      const response = (await this.makeForCamelCase(
        'GET',
        '/disbursements' + querystring,
        {},
        {}
      )) as any
      return response.data
    } catch (error) {
      throw error
    }
  }

  public async getDisbursementTotal(
    queryParams?: DisbursementTotalQueryParams
  ): Promise<DisbursementTotal> {
    try {
      const querystring = toQueryString(queryParams)
      return (await this.makeForCamelCase(
        'GET',
        '/disbursements-total' + querystring,
        {},
        {}
      )) as any
    } catch (error) {
      throw error
    }
  }
}
