import React, { useState, useEffect, useContext } from 'react'
import {
  Text,
  Break,
  Field,
  Wrapper,
  Sided,
  SwitchButton,
  Divider,
  FeatureFlag,
} from 'views/components'
import './style.scss'
import Context, { ProductContext } from '../context'
import { ListingsStock } from './ListingsStock'
import { featureCheck } from 'config'
import netcore from 'utilities/netcore'
import { t, Trans } from '@lingui/macro'
import { useLingui } from '@lingui/react'

const StockForm = () => {
  const [isTrackingStock, setTrackingStock] = useState(false)
  const [isActionChoosed, setIsActionChoosed] = useState(0)
  const context = useContext<ProductContext>(Context)
  const { actionsProps, singleData, productId } = context
  const { getValues } = actionsProps
  const { i18n } = useLingui()
  const [isOpenStockHistoryModal, setIsOpenStockHistoryModal] =
    useState<boolean>(false)

  const handleSwitchTrackStock = (isTracking: boolean) => {
    if (isTracking) {
      netcore.dispatchEvent('inventory_track_on')
    } else {
      netcore.dispatchEvent('inventory_track_off')
    }

    actionsProps.setFieldValue(x => {
      x.trackStock = isTracking
    })
    actionsProps.validate()
    setTrackingStock(isTracking)
    if (getValues().trackStock === true) {
      actionsProps.setFieldValue(x => (x.stockAdjustment.quantity = 0))
    }
  }

  const currentStock = singleData ? singleData.stock : 0

  useEffect(() => {
    setTrackingStock(getValues().trackStock)
    netcore.dispatchEvent('inventory_open', {
      source: productId ? 'edit_product' : 'create_product',
    })
  }, [])

  const isHavingAction = () => {
    const values = getValues()
    return values.trackStock && !!values.stockAdjustment.type
  }

  const showAdjustmentInformation = (): string => {
    const values = getValues()
    if (!values.trackStock) {
      return ''
    }
    if (
      !values.stockAdjustment.quantity ||
      values.stockAdjustment.quantity < 0
    ) {
      return ''
    }

    switch (values.stockAdjustment.type) {
      case 'addition':
        return `${currentStock} + ${values.stockAdjustment.quantity} = ${
          currentStock + values.stockAdjustment.quantity
        }`
      case 'subtraction':
        return `${currentStock} - ${values.stockAdjustment.quantity} = ${
          currentStock - values.stockAdjustment.quantity
        }`
      case 'recalculation':
        return i18n._(
          t`${currentStock} diubah menjadi ${values.stockAdjustment.quantity}`
        )
      default:
        return ''
    }
    return ''
  }

  const showAdjustmentLabel = () => {
    const values = getValues()
    switch (values.stockAdjustment.type) {
      case 'addition':
        return i18n._(t`Jumlah Penambahan Stok`)
      case 'subtraction':
        return i18n._(t`Jumlah Pengurangan Stok`)
      case 'recalculation':
        return i18n._(t`Jumlah Stok Terbaru`)
    }
    return ''
  }

  const revalidateStockQuantity = () => {
    setIsActionChoosed(isActionChoosed + 1)
    if (
      getValues().stockAdjustment.type !== undefined &&
      isActionChoosed === 0
    ) {
      actionsProps.setFieldValue(x => (x.stockAdjustment.quantity = 0))
    } else if (
      getValues().stockAdjustment.type !== undefined &&
      getValues().stockAdjustment.quantity === 1
    ) {
      actionsProps.setFieldValue(x => x.stockAdjustment.quantity === 1)
    } else if (
      getValues().stockAdjustment.type !== undefined &&
      getValues().stockAdjustment.quantity !== 1
    ) {
      actionsProps.setFieldValue(x =>
        getValues().stockAdjustment.quantity !== 1
          ? (x.stockAdjustment.quantity = getValues().stockAdjustment.quantity)
          : (x.stockAdjustment.quantity = 0)
      )
    }
  }

  return (
    <Wrapper
      padding='16px'
      backgroundColor='white'
      border='1px solid #e0e0e0'
      style={{ borderBottomRightRadius: '4px', borderBottomLeftRadius: '4px' }}
    >
      <Sided.Sided justify='top'>
        <Sided.Remaining>
          <Trans>
            <Text.Heading h={6}>Stok Produk</Text.Heading>
          </Trans>
          <Trans>
            <Text.Paragraph size={14}>
              Lacak penambahan dan pengurangan stok produk ini
            </Text.Paragraph>
          </Trans>
        </Sided.Remaining>
        <Sided.Fixed>
          <SwitchButton
            isActive={isTrackingStock}
            onChange={handleSwitchTrackStock}
          />
        </Sided.Fixed>
      </Sided.Sided>

      <Divider />
      <Sided.Sided>
        <Sided.Remaining>
          <Trans>
            <Text.Heading h={6}>Stok saat ini: {currentStock}</Text.Heading>
          </Trans>
        </Sided.Remaining>
        <Sided.Remaining align='right'>
          {productId && (
            <FeatureFlag
              featureCheck={featureCheck.management.stockHistoryList}
            >
              <Trans>
                <Text.Paragraph
                  size={14}
                  color='blue'
                  noMargin
                  onClick={() => {
                    netcore.dispatchEvent('inventory_history')
                    setIsOpenStockHistoryModal(true)
                  }}
                >
                  Lihat Riwayat Stok
                </Text.Paragraph>
              </Trans>
            </FeatureFlag>
          )}
        </Sided.Remaining>
      </Sided.Sided>
      <Break height={16} />
      <Wrapper verticalGap={16}>
        <Field.Dropdown
          mapSubItem={x => x.stockAdjustment}
          name='type'
          prefixName='stockAdjustment'
          isDisabled={!isTrackingStock}
          onChange={() => revalidateStockQuantity()}
          list={[
            { value: undefined, label: i18n._(t`Pilih`) },
            { value: 'addition', label: i18n._(t`Tambahkan Stok`) },
            { value: 'subtraction', label: i18n._(t`Kurangkan Stok`) },
            { value: 'recalculation', label: i18n._(t`Hitung Ulang Stok`) },
          ]}
        />
        <Sided.Sided justify='bottom'>
          <Sided.Remaining>
            {isHavingAction() ? (
              <Field.Number
                prefixName='stockAdjustment'
                mapSubItem={x => x.stockAdjustment}
                label={showAdjustmentLabel()}
                name='quantity'
                isDisabled={!isHavingAction()}
              />
            ) : (
              <Field.Text name='quantity2' isDisabled={true} />
            )}
          </Sided.Remaining>
          <Sided.Fixed minWidth={200}>
            <Wrapper align='right' style={{ marginBottom: '10px' }}>
              {showAdjustmentInformation()}
            </Wrapper>
          </Sided.Fixed>
        </Sided.Sided>
        <Field.Number
          label={i18n._(t`Stok Minimum`)}
          name='minStock'
          isDisabled={!isTrackingStock}
        />
        <Trans>
          <Text.Paragraph size={14} color='gray'>
            Produk dengan stok menipis akan diberi peringatan
          </Text.Paragraph>
        </Trans>
        <Field.TextArea
          maxLength={160}
          prefixName='stockAdjustment'
          mapSubItem={x => x.stockAdjustment}
          label={i18n._(t`Catatan (Opsional)`)}
          name='note'
          isDisabled={!isHavingAction()}
          showCounter={true}
        />
      </Wrapper>
      <ListingsStock
        isOpenModal={isOpenStockHistoryModal}
        productId={productId}
        closeModal={() => setIsOpenStockHistoryModal(false)}
      />
    </Wrapper>
  )
}

export default StockForm
