import React from 'react'
import classNames from 'classnames'
import './style.css'

interface Props {
  onClick?: () => any
  isActive?: boolean
  color?: 'green'
}

const RadioButton = (props: Props) => {
  const className = classNames(
    'post-radiobutton',
    'post-radiobutton-' + (props.isActive ? 'active' : 'not'),
    props.color
  )

  return <div className={className} onClick={props.onClick} />
}

export default RadioButton
export { RadioButton }
