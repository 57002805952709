import axios, { AxiosInstance, AxiosRequestConfig, Method } from 'axios'
import { preRequestInterceptor } from './interceptor/preRequest'
import { errorInterceptor } from './interceptor/error'
import { toSnakeCase, toCamelCase } from './converter'
import { featureCheck, getAppConfig, isAnyOfTheseFeaturesEnabled } from 'config'
import { getLocalStorage } from 'utilities/localStorage'

export class BaseApi {
  private addtionalBaseUrl: string
  private axiosInstance: AxiosInstance

  constructor(addtionalBaseUrl = '') {
    this.addtionalBaseUrl = addtionalBaseUrl
    this.axiosInstance = axios.create()

    this.axiosInstance.interceptors.request.use(preRequestInterceptor, err =>
      Promise.reject(err)
    )

    this.axiosInstance.interceptors.response.use(
      response => response.data,
      errorInterceptor
    )
  }

  getBaseUrl(): string {
    const customApiUrl = getLocalStorage('_postCustomApiUrl')
    if (
      customApiUrl &&
      isAnyOfTheseFeaturesEnabled(featureCheck.miscellaneous.customApiUrl)
    ) {
      console.info('CUSTOM API URL => ', customApiUrl.toString())
      return customApiUrl.toString()
    }
    const config = getAppConfig()
    return config.apiUrl || ''
  }

  async make<T>(
    method: Method,
    url: string,
    data: any = {},
    additionalConfig = {},
    headers: object = {}
  ): Promise<T> {
    let header: object = {
      'Content-Type': 'application/json',
    }

    if (Object.keys(headers).length !== 0) {
      header = headers
    }

    const baseUrl = this.getBaseUrl()
    const config: AxiosRequestConfig = {
      baseURL: baseUrl,
      method: method,
      url: `${this.addtionalBaseUrl}${url}`,
      headers: header,
      ...additionalConfig,
    }

    if (method === 'GET') {
      Object.keys(data).forEach(key => {
        if (data[key] === null || data[key] === '') {
          delete data[key]
        }
      })
      config.params = data
    } else {
      config.data = data
    }

    return this.axiosInstance.request(config)
  }

  async makeForList<Item>(
    method: Method,
    url: string,
    data: any = {},
    additionalConfig = {},
    headers: object = {}
  ): Promise<Item[]> {
    if (method === 'GET') {
      data = toSnakeCase(data)
    }
    return await this.make(method, url, data, additionalConfig, headers)
  }

  async makeForCamelCase<T>(
    method: Method,
    url: string,
    data: any = {},
    additionalConfig = {},
    headers: object = {}
  ): Promise<T> {
    const obj = await this.make(
      method,
      url,
      toSnakeCase(data),
      additionalConfig,
      headers
    )
    return toCamelCase(obj)
  }

  async makeForCamelCaseForList<Item>(
    method: Method,
    url: string,
    data: any = {},
    additionalConfig = {},
    headers: object = {}
  ): Promise<Item[]> {
    const obj = await this.make(
      method,
      url,
      toSnakeCase(data),
      additionalConfig,
      headers
    )
    return toCamelCase<ListData<Item>>(obj).data
  }
}

export interface ListData<T> {
  data: T[]
}
