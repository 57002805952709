import { CONFIG_LOCALSTORAGE } from 'constants/localStorage'
import { getLocalStorage } from 'utilities/localStorage'
import { anyOf } from 'utilities/ops'
import { Dict } from 'utilities/type'

import staffConfig from '../config/staff'
import {
  FeatureFlags,
  ManagementFeatureChecks,
  SettingFeatureChecks,
  FeatureCheckFunc,
  ReportingFeatureChecks,
  AllFeatureCheck,
  ManagementFeatureFlags,
  ReportingFeatureFlags,
  SettingFeatureFlags,
  MiscellaneousFeatureFlags,
  MiscellaneousFeatureChecks,
  FeatureCheck,
  HomeFeatureFlags,
  HomeFeatureChecks,
  ContactlessFeatureFlags,
  ContactlessFeatureChecks,
  IngredientFeatureFlags,
  IngredientFeatureChecks,
} from './def'
import defaultConfig from './default'
import { getCurrentEnvironment } from './env'

export const PUBLIC_URL = process.env.REACT_APP_PUBLIC_PATH

export function isAnyOfTheseFeaturesEnabled(
  ...checks: FeatureCheckFunc[]
): boolean {
  const detail = getCurrentEnvironment()
  let featureFlags = {}
  const role = getLocalStorage(CONFIG_LOCALSTORAGE.POST_TYPE)
  if (role && role.toLowerCase() === 'staff') {
    featureFlags = {
      ...detail.featureFlags,
      ...staffConfig.featureFlags,
    }
  } else {
    featureFlags = detail.featureFlags
  }
  const isValidFeatureFlag = (c: FeatureCheckFunc) => c && c(featureFlags)
  return anyOf<FeatureCheckFunc>(...checks).validFor(isValidFeatureFlag)
}

function generateStateChange<T extends Dict<boolean>>(
  key: keyof FeatureCheck,
  subKey: keyof T & string
): FeatureCheckFunc {
  return (f: FeatureFlags) => {
    if (!f[key]) {
      return false
    }
    return (f[key] as T)[subKey] == true
  }
}

function generateStateChanges<
  T extends Dict<any>,
  TOut extends Dict<FeatureCheckFunc>
>(key: keyof FeatureCheck, ...subKeys: (keyof T & string)[]): TOut {
  const object: Dict<FeatureCheckFunc> = {}
  subKeys.forEach((subKey: string) => {
    object[subKey] = generateStateChange<T>(key, subKey)
  })
  return object as unknown as TOut
}

function generateStateChangeAllTrue<T extends Dict<boolean>>(
  key: keyof FeatureCheck
): FeatureCheckFunc {
  return (f: FeatureFlags) => {
    if (!f[key]) {
      return false
    }
    return Object.keys(f[key])
      .map(subKey => (f[key] as T)[subKey])
      .reduce((x, y) => x || y, false)
  }
}

export const featureCheck: FeatureCheck & AllFeatureCheck = {
  home: {
    ...generateStateChanges<HomeFeatureFlags, HomeFeatureChecks>(
      'home',
      'main',
      ...(Object.keys(
        defaultConfig.featureFlags.home
      ) as (keyof HomeFeatureFlags)[])
    ),
    any: generateStateChangeAllTrue('home'),
  },
  management: {
    ...generateStateChanges<ManagementFeatureFlags, ManagementFeatureChecks>(
      'management',
      'main',
      ...(Object.keys(
        defaultConfig.featureFlags.management
      ) as (keyof ManagementFeatureFlags)[])
    ),
    any: generateStateChangeAllTrue('management'),
  },
  setting: {
    ...generateStateChanges<SettingFeatureFlags, SettingFeatureChecks>(
      'setting',
      'main',
      ...(Object.keys(
        defaultConfig.featureFlags.setting
      ) as (keyof SettingFeatureFlags)[])
    ),
    any: generateStateChangeAllTrue('setting'),
  },
  contactless: {
    ...generateStateChanges<ContactlessFeatureFlags, ContactlessFeatureChecks>(
      'contactless',
      'main',
      ...(Object.keys(
        defaultConfig.featureFlags.contactless
      ) as (keyof ContactlessFeatureFlags)[])
    ),
    any: generateStateChangeAllTrue('contactless'),
  },
  reporting: {
    ...generateStateChanges<ReportingFeatureFlags, ReportingFeatureChecks>(
      'reporting',
      'main',
      ...(Object.keys(
        defaultConfig.featureFlags.reporting
      ) as (keyof ReportingFeatureFlags)[])
    ),
    any: generateStateChangeAllTrue('reporting'),
  },
  ingredients: {
    ...generateStateChanges<IngredientFeatureFlags, IngredientFeatureChecks>(
      'ingredients',
      ...(Object.keys(
        defaultConfig.featureFlags.ingredients
      ) as (keyof IngredientFeatureFlags)[])
    ),
    any: generateStateChangeAllTrue('ingredients'),
  },
  miscellaneous: {
    ...generateStateChanges<
      MiscellaneousFeatureFlags,
      MiscellaneousFeatureChecks
    >(
      'miscellaneous',
      ...(Object.keys(
        defaultConfig.featureFlags.miscellaneous
      ) as (keyof MiscellaneousFeatureFlags)[])
    ),
  },

  any: (f: FeatureFlags) =>
    generateStateChangeAllTrue('management')(f) ||
    generateStateChangeAllTrue('setting')(f) ||
    generateStateChangeAllTrue('contactless')(f) ||
    generateStateChangeAllTrue('reporting')(f) ||
    generateStateChangeAllTrue('miscellaneous')(f),
}
