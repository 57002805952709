import { BaseApi } from '../base.api'
import { AxiosRequestConfig } from 'axios'
import { IMediaApi } from 'domains/contracts/media.api'
import { ImageUploadResponse } from 'domains/media'

export default class MediaApi extends BaseApi implements IMediaApi {
  constructor() {
    super('/v2')
  }

  public async uploadImageFromBlob(
    blob: Blob,
    filename: string
  ): Promise<ImageUploadResponse> {
    const form = new FormData()
    form.append('file', blob)

    const config: AxiosRequestConfig = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    }

    return this.make('POST', '/files', form, config)
  }
}
