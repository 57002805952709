import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { cdnImageUrl } from 'constants/cdnUrl/imageUrl'
import { CONFIG_LOCALSTORAGE } from 'constants/localStorage'
import ROUTE from 'constants/routes'
import {
  employeeService,
  layoutService,
  locationService,
  subscriptionService,
  userService,
} from 'injectors'
import { RouteItemV2 } from 'route'
import getAuthorizedRoutes from 'route/authorizedRoute'
import { history } from 'storages'
import { getLocalStorage } from 'utilities/localStorage'
import netcore from 'utilities/netcore'
import { capitalizeFirstLetter } from 'utilities/strings/capitalizeFirstLetter'
import { firebaseFunc } from 'utilities/firebase'
import {
  isPremiumSelector,
  isTrialSelector,
} from 'views/modules/globalSelector/subscriptionTypeCheck'
import { SubscriptionComponent } from 'views/modules/Subscription'

import { Icon, VSided, Text, Wrapper, Break, Sided, Divider } from '..'
import { BadgeStatus } from '../Common'
import { DefaultModalService } from '../Modal'
import LogoutConfirmationModal from './LogoutConfirmation'
import selector from './selector'
import './style-v3.css'

const SidebarV3: React.FC = () => {
  const role = getLocalStorage(CONFIG_LOCALSTORAGE.POST_TYPE)
  const { user, employee, subscriptionAlert } = useSelector(selector)
  const employeeId = getLocalStorage(CONFIG_LOCALSTORAGE.STAFF_ID)
  let routes = getAuthorizedRoutes().filter(
    routeItem => !!routeItem.sidebarMenu
  )
  const [expandedMenu, setExpandedMenu] = useState<string[]>([])
  const [isAlmostExpired, setIsAlmostExpired] = useState<boolean>(false)
  const isPremium = useSelector(isPremiumSelector)
  const isTrial = useSelector(isTrialSelector)
  const isFnbBusinessCategory =
    user.isFnbBusinessCategory || employee.isFnbBusinessCategory

  if (!isFnbBusinessCategory) {
    routes = routes.filter(route => route.category !== 'fnb')
  } else {
    firebaseFunc.logEvent('ingredient_menu_shown')
  }

  const getData = async () => {
    try {
      if (employeeId) {
        const staffResponse = await employeeService.getStaff(employeeId)
        netcore.setIdentifier({
          userId: staffResponse.id,
          email: staffResponse.email,
          name: staffResponse.name,
          userType: role,
        })
        if (!subscriptionAlert?.status) {
          await subscriptionService.checkSubscriptionAlert()
        }
      } else {
        let userResponse = user
        let subscriptionAlertResponse = subscriptionAlert
        if (!userResponse.id) {
          userResponse = await userService.getUser()
        }
        if (!subscriptionAlertResponse.status) {
          subscriptionAlertResponse =
            await subscriptionService.checkSubscriptionAlert()
        }

        setIsAlmostExpired(
          moment(subscriptionAlertResponse.endDate).diff(moment(), 'day') < 7
        )

        const { village, district, regency, province } =
          await locationService.getArea(userResponse.areaId)
        netcore.setIdentifier({
          userId: userResponse.id,
          email: userResponse.email,
          mobile: userResponse.phone,
          name: userResponse.businessName,
          registerDate: userResponse.createdAt,
          userType: role,
          subscription: subscriptionAlertResponse.status,
          premiumStartDate: subscriptionAlertResponse.startDate,
          premiumEndDate: subscriptionAlertResponse.endDate,
          userLocation: `${village}, ${district}, ${regency}, ${province}`,
        })
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    routes.forEach(route => {
      if (history.location.pathname.includes(route.path)) {
        setExpandedMenu([route.path])
      }
    })
  }, [history.location.pathname])

  const handleClickLogoutButton = async () => {
    const confirmed = await DefaultModalService.open(
      LogoutConfirmationModal,
      {}
    )
    if (confirmed) {
      const employeeId = getLocalStorage(CONFIG_LOCALSTORAGE.STAFF_ID)
      if (employeeId) {
        layoutService.logOutEmployee()
      } else {
        layoutService.logout()
      }
    }
  }

  const renderOwnerProfile = () => {
    const handleOnClickBadge = function () {
      netcore.dispatchEvent('subscription_start', {
        source: 'drawer',
        user_type: subscriptionAlert.subscriptionType,
      })
      DefaultModalService.open(SubscriptionComponent, {})
    }

    let badge = <BadgeStatus status='lite' onClick={handleOnClickBadge} />

    if (isTrial || isPremium) {
      badge = (
        <BadgeStatus
          status='premium'
          onClick={handleOnClickBadge}
          isAlmostExpired={isAlmostExpired}
        />
      )
    }

    return (
      <VSided.Fixed>
        <Wrapper className='post-sidebar-profile'>
          <Sided.Sided onClick={() => history.push(ROUTE.ACCOUNT_SETTINGS)}>
            <Sided.Fixed verticalAlign='center'>
              <Icon.User
                size={24}
                color='white'
                style={{ cursor: 'pointer' }}
              />
            </Sided.Fixed>
            <Sided.Remaining verticalAlign='center'>
              <Wrapper className='post-sidebar-profile-info'>
                <Wrapper
                  className='post-sidebar-profile-info-name'
                  width='140px'
                >
                  <Text.Paragraph
                    className='post-sidebar-profile-info-name-overflow'
                    size={14}
                    color='white'
                    weight='bold'
                    noWrap
                  >
                    {user.businessName}
                  </Text.Paragraph>
                </Wrapper>
              </Wrapper>
            </Sided.Remaining>
            <Sided.Fixed verticalAlign='center'>
              <Icon.ChevronRight color='white' style={{ cursor: 'pointer' }} />
            </Sided.Fixed>
          </Sided.Sided>
          <Break height={8} />
          <Sided.Sided>{badge}</Sided.Sided>
        </Wrapper>
      </VSided.Fixed>
    )
  }

  const renderStaffProfile = () => (
    <VSided.Fixed>
      <Wrapper className='post-sidebar-profile'>
        <Sided.Sided gutter={16}>
          <Sided.Fixed verticalAlign='center'>
            <Icon.User size={24} />
          </Sided.Fixed>
          <Sided.Remaining verticalAlign='center'>
            <Text.Paragraph
              className='post-sidebar-profile-info-name-overflow'
              size={14}
              color='white'
              weight='bold'
              noWrap
              noMargin
            >
              {employee.currentStaff.name}
            </Text.Paragraph>
            <Text.Paragraph size={12} color='white' noMargin>
              {capitalizeFirstLetter(String(employee.currentStaff.roleId))}
            </Text.Paragraph>
          </Sided.Remaining>
        </Sided.Sided>
      </Wrapper>
    </VSided.Fixed>
  )

  const checkIsMatchRoute = (path: string) => {
    return path === history.location.pathname
  }

  const renderMenu = (routeItem: RouteItemV2, i: number) => {
    const getClassName = (path: string) => {
      const matchRoute = checkIsMatchRoute(path)
      return classNames('post-menu-item', { 'is-active': matchRoute })
    }

    const handleOnClick = (routeItem: RouteItemV2) => {
      if (routeItem.onClick) {
        routeItem.onClick()
      }
      history.push(routeItem.path)
    }

    const handleOnExpand = () => {
      if (routeItem.onClick) {
        routeItem.onClick()
      }
      if (!routeItem.children) {
        handleOnClick(routeItem)
        return
      }
      const menu = JSON.parse(JSON.stringify(expandedMenu))
      if (menu.includes(routeItem.path)) {
        menu.splice(menu.indexOf(routeItem.path), 1)
      } else {
        menu.push(routeItem.path)
      }
      setExpandedMenu(menu)
    }

    const routeItemChildren =
      routeItem.children &&
      routeItem.children.filter(child => {
        return child.isEnabled
      })

    return (
      <div key={i}>
        <Wrapper
          className={getClassName(routeItem.path)}
          key={routeItem.path}
          onClick={handleOnExpand}
        >
          <Sided.Sided gutter={16}>
            <Sided.Fixed verticalAlign='center'>
              {routeItem.sidebarMenu.icon}
            </Sided.Fixed>
            <Sided.Remaining verticalAlign='center'>
              <Text.Span size={14} align='left'>
                {routeItem.sidebarMenu.label}
              </Text.Span>
            </Sided.Remaining>
            {routeItem.isNew && (
              <Sided.Fixed verticalAlign='center'>
                <Text.Span
                  size={10}
                  style={{ fontStyle: 'italic' }}
                  color='white'
                  weight='bold'
                >
                  New!
                </Text.Span>
              </Sided.Fixed>
            )}
            <Sided.Fixed minWidth={24} verticalAlign='center'>
              {routeItem.children &&
                (expandedMenu.includes(routeItem.path) ? (
                  <Icon.ChevronUp style={{ cursor: 'pointer' }} />
                ) : (
                  <Icon.ChevronDown style={{ cursor: 'pointer' }} />
                ))}
            </Sided.Fixed>
          </Sided.Sided>
        </Wrapper>
        <Wrapper
          className={`panel ${
            expandedMenu.includes(routeItem.path) ? 'visible' : 'hidden'
          }`}
          style={{
            height: `${
              expandedMenu.includes(routeItem.path) && routeItemChildren
                ? routeItemChildren.length * 40
                : 0
            }px`,
          }}
        >
          {routeItemChildren &&
            routeItemChildren.map(route => (
              <Wrapper
                className={`${getClassName(route.path)} children`}
                onClick={() => handleOnClick(route)}
                key={route.path}
              >
                <Sided.Sided gutter={16}>
                  <Sided.Fixed verticalAlign='center' />
                  <Sided.Remaining verticalAlign='center'>
                    <Text.Span size={14} align='left'>
                      {route.sidebarMenu && route.sidebarMenu.label}
                    </Text.Span>
                  </Sided.Remaining>
                  {route.isNew && (
                    <Sided.Fixed verticalAlign='center'>
                      <Text.Span
                        size={10}
                        style={{ fontStyle: 'italic' }}
                        color='white'
                        weight='bold'
                      >
                        New!
                      </Text.Span>
                    </Sided.Fixed>
                  )}
                </Sided.Sided>
              </Wrapper>
            ))}
        </Wrapper>
      </div>
    )
  }

  return (
    <VSided.VSided gutter={0} className='sidebar-v3 main-side'>
      <VSided.Fixed>
        <Wrapper className='post-sidebar-logo'>
          <img
            src={cdnImageUrl + 'post-logo/post-light.png'}
            width='115px'
            alt='post logo white'
          />
        </Wrapper>
      </VSided.Fixed>
      {role === 'owner' ? renderOwnerProfile() : renderStaffProfile()}
      <Break height={20} />
      <Divider color='semigray' />
      <VSided.Remaining>
        <Wrapper className='post-menu list' height='100%'>
          {routes.filter(r => !r.parentKey).map(renderMenu)}
          <Break height={20} />
          <Text.Heading h={4} color='gray'>
            KELOLA PRODUK
          </Text.Heading>
          <Break height={6} />
          {routes.filter(r => r.parentKey === 'product').map(renderMenu)}
          {role === 'owner' && (
            <>
              <Break height={20} />
              <Text.Heading h={4} color='gray'>
                KELOLA TOKO
              </Text.Heading>
              <Break height={6} />
            </>
          )}
          {routes.filter(r => r.parentKey === 'manageOutlet').map(renderMenu)}
          <Break height={40} />
          <Wrapper
            className={classNames('post-menu-item', {
              'is-active': checkIsMatchRoute(ROUTE.MAIN_HELPER),
            })}
            key='Help'
            onClick={() => history.push(ROUTE.MAIN_HELPER)}
          >
            <Sided.Sided gutter={16}>
              <Sided.Fixed verticalAlign='center'>
                <Icon.HelpInfo />
              </Sided.Fixed>
              <Sided.Remaining verticalAlign='center'>
                <Text.Span size={14} align='left'>
                  Bantuan
                </Text.Span>
              </Sided.Remaining>
            </Sided.Sided>
          </Wrapper>
          <Wrapper
            className='post-menu-item'
            key='Logout'
            onClick={() => handleClickLogoutButton()}
          >
            <Sided.Sided gutter={16}>
              <Sided.Fixed verticalAlign='center'>
                <Icon.Logout />
              </Sided.Fixed>
              <Sided.Remaining verticalAlign='center'>
                <Text.Span size={14} align='left'>
                  Keluar
                </Text.Span>
              </Sided.Remaining>
            </Sided.Sided>
          </Wrapper>
        </Wrapper>
      </VSided.Remaining>
    </VSided.VSided>
  )
}

export default SidebarV3
