import { EnvironmentConfig } from 'config/def'

const config: EnvironmentConfig = {
  isTracking: true,
  featureFlags: {
    home: {
      totalQuantityCard: true,
      setupGuide: true,
      skipOnboarding: true,
      homeNewLiteLimitation: true,
      routingV2: true,
      netcore: true,
      appsflyer: true,
      sidebarV2: false,
      sidebarV3: true,
      registerRevamp: true,
      newCallCenterBanner: true,
    },
    management: {
      product: false,
      productVariant: true,
      massProductVariant: true,
      cropImageProduct: true,
      serviceType: true,
      category: true,
      tax: true,
      discount: true,
      maxDiscount: true,
      receipt: true,
      device: true,
      employee: true,
      shift: true,
      customer: true,
      paymentMethod: true,
      qrisPaymentMethod: true,
      attendance: false,
      serviceTypeWithServiceCharge: true,
      categoryCreationWithProduct: true,
      multiOutlet: true,
      outletForProduct: true,
      massUpload: true,
      massEdit: true,
      cashlezKYC: false,
      shopeepayKYC: true,
      stockTracking: true,
      stockHistoryList: true,
      hasBarcode: true,
      roleManagementStaff: true,
      deviceEmployeeNewFlow: true,
      receiptV2: true,
      receiptV3: false,
      inventory: true,
      bulkStockAdjustment: true,
      roleManagement: true,
      updateBankAccount: true,
    },
    reporting: {
      summary: true,
      productSale: true,
      cancellation: false,
      discount: true,
      shift: true,
      serviceType: true,
      paymentMethod: true,
      disbursement: true,
      expense: true,
      employee: true,
      productSalesCategoryName: true,
      serviceTypeFilterNetSales: false,
      salesGraphHourly: true,
      reportNewLiteLimitation: true,
      cogs: true,
    },
    setting: {
      subscriptionHistory: true,
      subscribing: true,
      businessProfile: true,
      generalInformation: true,
      resetData: true,
      account: true,
      subscriptionInvoice: true,
    },
    contactless: {
      contactlessDining: true,
      onlineOrder: true,
      paymentSetup: true,
      onlineDelivery: false,
      pushNotificationSettings: true,
    },
    miscellaneous: {
      helpdesk: true,
    },
    ingredients: {
      ingredient: true,
      ingredientCategory: true,
      recipe: true,
      ingredientSummaryReport: true,
    },
  },
}
export default config
