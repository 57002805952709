export const REGEX = {
  PHONE: /^((^08)|(^\+?628))(\d{3,4}){2}\d{2,5}$/,
  PHONE_EXTENDED: /^((^0)|(^\+?62))(\d{3,4}){2}\d{2,6}$/,
  EMAIL: /^([a-zA-Z0-9_\-\.\+]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/,
  URL: /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
  SLUG: /^[a-zA-Z0-9\-\_]{1,255}$/,
  URL_LINK:
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
  ALPHABET_ONLY: /^[A-Za-z ]+$/,
  DECIMAL_ONLY: /[0-9]+(\.[0-9]|\,[0-9])+?/,
  DECIMAL_NUMBER: /^\d{0,2}(\.|\,)?\d{1,2}$/,
  NUMBER: /^[0-9]*$/,
  DECIMAL_ALLOW: /^[0-9\,\.]*$/,
}
