import React, { useState } from 'react'
import moment from 'moment'

import { DateRange } from 'domains/commons/models'
import { Text, Sided, Icon } from 'views/components'
import Table from 'views/components/Table'
import Button from 'views/components/Button'

interface MonthlyCalendarProps {
  selectedDateRange: DateRange
  onChange: (dateRange: DateRange) => any
}

const MonthlyCalendar = (props: MonthlyCalendarProps) => {
  const [monthAndYear, setMonthAndYear] = useState({
    year: moment().year(),
    month: moment().month() + 1,
  })

  const chunckMonth = () => {
    const months = []

    for (let index = 0; index < 11; index += 3) {
      months.push([index + 1, index + 2, index + 3])
    }

    return months
  }

  const getMonthRange = (year: number, month: number) => {
    const from = new Date(year, month - 1, 1)
    const to = new Date(year, month, 0)
    return {
      from,
      to,
    }
  }

  const prevYear = () => () => {
    setMonthAndYear(prevState => ({
      ...prevState,
      year: prevState.year - 1,
    }))
  }

  const nextYear = () => () => {
    setMonthAndYear(prevState => ({
      ...prevState,
      year: prevState.year + 1,
    }))
  }
  const onClickMonthlyDate = (dateRange: DateRange) => {
    props.onChange(dateRange)
  }

  const renderYearSelector = () => (
    <Sided.Sided>
      <Sided.Fixed>
        <Button theme='transparent' onClick={prevYear()}>
          <Icon.ChevronLeft />
        </Button>
      </Sided.Fixed>
      <Sided.Remaining>
        <Text.Heading h={3}>{monthAndYear.year}</Text.Heading>
      </Sided.Remaining>
      <Sided.Fixed>
        <Button theme='transparent' onClick={nextYear()}>
          <Icon.ChevronLeft />
        </Button>
      </Sided.Fixed>
    </Sided.Sided>
  )

  return (
    <React.Fragment>
      {renderYearSelector()}
      <Table.Simple>
        {chunckMonth().map((chunkedMonths, i) => (
          <Table.Row key={i}>
            {chunkedMonths.map((month, j) => {
              const monthRange = getMonthRange(monthAndYear.year, month)
              const { from, to } = monthRange
              const className =
                moment(from).isSame(props.selectedDateRange.from, 'month') &&
                moment(to).isSame(props.selectedDateRange.to, 'month')
                  ? 'active'
                  : ''

              return (
                <Table.Column key={j}>
                  <Button
                    theme='transparent'
                    className={className}
                    onClick={() => onClickMonthlyDate(monthRange)}
                  >
                    <Text.Heading h={3}>
                      {moment()
                        .months(month - 1)
                        .format('MMMM')}
                    </Text.Heading>
                  </Button>
                </Table.Column>
              )
            })}
          </Table.Row>
        ))}
      </Table.Simple>
    </React.Fragment>
  )
}

export default MonthlyCalendar
