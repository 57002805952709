import { REGEX } from 'constants/regex'
import { SchemaValidation, ValidationContext } from 'utilities/validationCheck'

const checkData = (value: string, context: ValidationContext) => {
  const { name, email, phone } = context.parent
  if (!name && !email && !phone) {
    return false
  }
  return true
}

export const customerFormSchema = SchemaValidation.object().shape({
  name: SchemaValidation.string()
    .min(3, 'Nama pelanggan harus lebih dari 3 huruf')
    .test('Nama pelanggan tidak boleh kosong', checkData),
  email: SchemaValidation.string()
    .email('Format Email salah')
    .test('Email tidak boleh kosong', checkData),
  phone: SchemaValidation.string()
    .matches(REGEX.PHONE, 'Format No Handphone salah')
    .max(15, 'No Handphone tidak boleh lebih dari 15 digit')
    .test('No Handphone tidak boleh kosong', checkData),
  areaId: SchemaValidation.string(),
  address: SchemaValidation.string(),
})
