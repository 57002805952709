import { AppsflyerRequest } from 'domains/appsflyer/models'
import { BaseApi } from '../../api/base.api'
import { IAppsflyerApi } from '../../domains/appsflyer/contracts/api'

export default class AppsflyerApi extends BaseApi implements IAppsflyerApi {
  constructor() {
    super('v3/tracker/appsflyer')
  }

  public async trackEvent(request: AppsflyerRequest) {
    await this.makeForCamelCase('POST', `/event`, request)
  }
}
