import { BaseApi } from '../base.api'
import {
  RegisterOTPRequest,
  RegisterResendOTP,
} from 'domains/auth/register/models'
import {
  User,
  ChangePhoneToken,
  BusinessAccount,
  PasswordRequest,
  BusinessCategory,
  OnboardingSteps,
} from 'domains/users/models'
import { IUserApi } from 'domains/users/contracts/api'
import { Group } from 'utilities/type'
import { toGroupBy } from 'utilities/converter/list'

export default class UserApi extends BaseApi implements IUserApi {
  constructor() {
    super('/')
  }

  public async getUser(): Promise<User> {
    return await this.makeForCamelCase('GET', 'v2/users/me')
  }

  public async changePhoneToken(phone: BusinessAccount) {
    return this.makeForCamelCase(
      'POST',
      'v2/users/me/change-phone-tokens',
      phone
    )
  }

  public async createChangePasswordToken(
    query: RegisterOTPRequest
  ): Promise<ChangePhoneToken> {
    return this.makeForCamelCase(
      'POST',
      'v2/users/me/change-password-tokens',
      query
    )
  }

  public async checkChangePasswordToken(
    query: RegisterResendOTP,
    changePasswordTokenId: string
  ): Promise<void> {
    this.makeForCamelCase(
      'POST',
      `v2/users/me/change-password-tokens/${changePasswordTokenId}/check-otp`,
      query
    )
  }

  public async getBusinessCategories(): Promise<Group<BusinessCategory>> {
    const businessCategories: BusinessCategory[] = await this.makeForCamelCase(
      'GET',
      'v3/business-categories'
    )
    return toGroupBy<BusinessCategory>(businessCategories, x => x.group)
  }

  public async updateUser(query: BusinessAccount): Promise<User> {
    return this.makeForCamelCase('POST', 'v2/users/me', query)
  }

  public async resetManagements(): Promise<void> {
    return this.makeForCamelCase('POST', 'v2/reset-managements')
  }

  public async resetTransactions(): Promise<void> {
    return this.makeForCamelCase('POST', 'v2/reset-transactions')
  }

  public async changePhone(phone: BusinessAccount): Promise<User> {
    return this.makeForCamelCase('POST', 'v3/users/me/change-phone', phone)
  }

  public async changeEmail(phone: BusinessAccount): Promise<User> {
    return this.makeForCamelCase('POST', 'v3/users/me/change-email', phone)
  }

  public async checkPassword(password: PasswordRequest): Promise<void> {
    return this.makeForCamelCase('POST', 'v3/users/me/check-password', password)
  }

  public async getOnBoardingSteps(): Promise<OnboardingSteps[]> {
    return this.makeForCamelCaseForList('GET', 'v3/onboarding-steps')
  }
}
