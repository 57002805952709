import { BaseApi } from 'api/base.api'
import {
  GetIngredientCategoryQueryParams,
  IngredientCategoryModel,
} from 'domains/ingredients/models'

export default class IngredientCategoryApi extends BaseApi {
  constructor() {
    super('/v3/ingredient-categories')
  }

  public getIngredientCategories(
    queryParams?: GetIngredientCategoryQueryParams
  ): Promise<IngredientCategoryModel[]> {
    return this.makeForList('GET', '', queryParams)
  }

  public createIngredientCategory(
    category: IngredientCategoryModel
  ): Promise<IngredientCategoryModel> {
    return this.make('POST', '', category)
  }

  public updateIngredientCategory(
    category: IngredientCategoryModel
  ): Promise<IngredientCategoryModel> {
    return this.make('POST', `/${category.id}`, category)
  }

  public deleteIngredientCategory(
    categoryId: string
  ): Promise<IngredientCategoryModel> {
    return this.make('DELETE', `/${categoryId}`)
  }
}
