const PREFIX = 'REPORTING'

// Product Sales Reporting Actions
export const SET_PRODUCT_SALES_REPORTING = `${PREFIX}.SET_PRODUCT_SALES_REPORTING`

// Employee Reporting Actions
export const SET_EMPLOYEE_REPORTING = `${PREFIX}.SET_EMPLOYEES_REPORTING`

// Summary Reporting Actions
export const SET_SUMMARY_REPORTING = `${PREFIX}.SET_SUMMARY_REPORTING`
export const SET_DAILY_SUMMARY_REPORTING = `${PREFIX}.SET_DAILY_SUMMARY_REPORTING`
export const SET_HOURLY_SUMMARY_REPORTING = `${PREFIX}.SET_HOURLY_SUMMARY_REPORTING`

// Cancellation Reporting Actions
export const SET_CANCELLATION_REPORTING = `${PREFIX}.SET_CANCELLATION_REPORTING`

// Order Type Reporting Actions
export const SET_ORDER_TYPE_LIST_REPORTING = `${PREFIX}.SET_ORDER_TYPE_LIST_REPORTING`

// Discount Reporting Actions
export const SET_DISCOUNT_REPORTING = `${PREFIX}.SET_DISCOUNT_REPORTING`
export const SET_PAYMENT_METHOD_LIST_REPORT = `${PREFIX}.SET_PAYMENT_METHOD_LIST_REPORT`
export const SET_OUTLET_LIST_FOR_REPORTING = `${PREFIX}.SET_OUTLET_LIST_FOR_REPORTING`

// Shift Reporting Actions
export const SET_SHIFT_REPORTING = `${PREFIX}.SET_SHIFT_REPORTING`

// Filter Reporting Actions
export const SET_OUTLET_ID_REPORT_FILTER = `${PREFIX}.SELECT_OUTLET_FOR_REPORTING`
export const SET_DATE_RANGE_REPORT_FILTER = `${PREFIX}.SET_DATE_RANGE_FOR_REPORTING`
export const SET_SHOW_TYPE_REPORT_FILTER = `${PREFIX}.SET_SHOW_TYPE_REPOTR_FILTER`
export const SET_SALE_TYPE_REPORT_FILTER = `${PREFIX}.SET_SALE_TYPE_REPOTR_FILTER`
export const SET_EXPENSE_TYPE_REPORT_FILTER = `${PREFIX}.SET_EXPENSE_TYPE_REPORT_FILTER`
export const RESET_REPORTING_FILTER = `${PREFIX}.RESET_FILTER`
