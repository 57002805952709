import { BaseApi } from '../base.api'
import { INewAuthApi } from 'domains/auth/login/contracts/v3.api'
import {
  ExtendedRegisterTokenRequest,
  checkAccountRequest,
  checkAccountRequestV2,
} from 'domains/auth/register/models'
import { LoginResponse } from 'domains/auth/login/models'

export default class NewAuthApi extends BaseApi implements INewAuthApi {
  constructor() {
    super('/v3')
  }

  public async checkRegister(request: checkAccountRequest) {
    return this.makeForCamelCase('POST', '/check-register', request)
  }

  public async register(
    request: ExtendedRegisterTokenRequest
  ): Promise<LoginResponse> {
    return this.makeForCamelCase('POST', '/register', request)
  }

  //----------------------v2----------------------
  public async checkRegisterV2(request: checkAccountRequestV2) {
    return this.makeForCamelCase('POST', '/check-registered-user', request)
  }
}
