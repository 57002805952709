import { BaseApi } from '../base.api'
import { LoginRequest, LoginResponse } from 'domains/auth/login/models'
import {
  RegisterTokenRequest,
  RegisterResendOTP,
  RegisterOTPRequest,
} from 'domains/auth/register/models'
import { RegisterTempData } from 'domains/auth/register/states'
import {
  ResetPasswordOTPRequest,
  ResetPasswordTokenResponse,
  ResetPasswordTokenRequest,
  CheckResetPasswordOTPRequest,
} from 'domains/auth/reset-password/models'
import { IAuthApi } from 'domains/auth/login/contracts/api'

export default class AuthApi extends BaseApi implements IAuthApi {
  constructor() {
    super('/v2')
  }

  public async login(query: LoginRequest): Promise<LoginResponse> {
    return this.makeForCamelCase('POST', '/login', query)
  }

  public async logOut(authHeader: {}): Promise<void> {
    return this.makeForCamelCase('POST', '/logout', {}, authHeader)
  }

  public async checkPassword(password: string): Promise<LoginResponse> {
    return this.makeForCamelCase('POST', '/checkPassword', password)
  }

  public async registerToken(
    query: RegisterTokenRequest
  ): Promise<RegisterTempData> {
    return this.makeForCamelCase('POST', '/register-tokens', query)
  }

  public async register(query: RegisterOTPRequest): Promise<LoginResponse> {
    return this.makeForCamelCase('POST', '/register', query)
  }

  public async resendOTP(query: RegisterResendOTP): Promise<void> {
    this.makeForCamelCase('POST', '/register-tokens/resend-otp', query)
  }

  public async createResetPasswordToken(
    query: ResetPasswordTokenRequest
  ): Promise<ResetPasswordTokenResponse> {
    return this.makeForCamelCase('POST', '/reset-password-tokens', query)
  }

  public async checkResetPasswordToken(
    query: CheckResetPasswordOTPRequest,
    resetPasswordTokenId: string
  ) {
    return this.makeForCamelCase(
      'POST',
      `/reset-password-tokens/${resetPasswordTokenId}/check-otp`,
      query
    )
  }

  public async resetPassword(query: ResetPasswordOTPRequest) {
    return this.makeForCamelCase('POST', '/reset-password', query)
  }
}
