import { BaseService } from 'domains/commons/services'
import { ICommonStorage } from 'domains/commons/contracts/storage'
import { Api } from 'domains/contracts/api'
import { IRoleStorage } from '../contracts/storage'
import { RoleModel, GetRoleListQueryParams } from '../models'

const SET_ROLES = 'SET_ROLES'

export class RoleService extends BaseService implements IRoleService {
  roleStorage: IRoleStorage

  constructor(
    commonStorage: ICommonStorage,
    api: Api,
    roleStorage: IRoleStorage
  ) {
    super(commonStorage, api)
    this.roleStorage = roleStorage
  }

  private getLastId = (roles: RoleModel[]): string | undefined => {
    return roles.length > 0 ? roles[roles.length - 1].id : undefined
  }

  public loadRoles = async (queryParams: GetRoleListQueryParams = {}) => {
    try {
      this.setLoading(SET_ROLES, true)
      queryParams.limit = queryParams.limit || 10
      const roles = await this.api.management.role.getRoleList(queryParams)
      this.roleStorage.setRoles(roles)
      this.setLoading(SET_ROLES, false)
      const lastRoleId = this.getLastId(roles)
      this.roleStorage.setRoleListRequest({
        ...queryParams,
        startingAfter: lastRoleId || queryParams.startingAfter,
      } as GetRoleListQueryParams)
      this.roleStorage.setRoleListReachBottom(roles.length === 0)

      return roles
    } catch (error) {
      throw error
    }
  }

  public loadMore = async (): Promise<RoleModel[]> => {
    try {
      const roleListManager = this.getState().management.role.roleListManager
      if (roleListManager.hasReachedBottom) {
        return Promise.resolve([])
      }
      if (roleListManager.isLoadingMoreRole) {
        return Promise.resolve([])
      }
      this.roleStorage.setLoadingMoreRole(true)
      const queryParams = roleListManager.request
      const roles = await this.api.management.role.getRoleList(queryParams)
      this.roleStorage.appendRoles(roles)
      const lastProductId = this.getLastId(roles)
      this.roleStorage.setLoadingMoreRole(false)
      this.roleStorage.setRoleListRequest({
        ...queryParams,
        startingAfter: lastProductId || queryParams.startingAfter,
      } as GetRoleListQueryParams)
      this.roleStorage.setRoleListReachBottom(roles.length === 0)

      return roles
    } catch (error) {
      throw error
    }
  }
}

export interface IRoleService {
  loadRoles: (queryParams?: GetRoleListQueryParams) => Promise<RoleModel[]>
  loadMore: () => Promise<RoleModel[]>
}
