import { AppState } from 'domains/contracts/appState'
import { User, BusinessCategory } from 'domains/users/models'
import { DropdownItem } from 'views/components/Field/Dropdown'
import { Group } from 'utilities/type'
import { forEachDict } from 'utilities/converter/list'

export interface AuthorizedViewModel {
  userInfo: User
  businessCategoryDropdown: DropdownItem[]
}

const businessCategoryForDropdown = (
  businessCategories: Group<BusinessCategory>
): DropdownItem[] => {
  if (!businessCategories) {
    return []
  }
  let dropdownItems: DropdownItem[] = []
  forEachDict(businessCategories, (key, list) => {
    dropdownItems.push({
      label: key,
      value: null,
      asHeader: true,
      group: list.some(list => list.group === key) && key,
    })
    const newList = list.map((item, i) => ({
      value: item.id,
      label: item.name,
      indent: 1,
      group: item.group,
    }))
    dropdownItems = [...dropdownItems, ...newList]
  })

  return dropdownItems
}

export default (state: AppState): AuthorizedViewModel => ({
  userInfo: state.user,
  businessCategoryDropdown: businessCategoryForDropdown(
    state.user.businessCategories
  ),
})
