/* eslint-disable react/no-multi-comp */
import classNames from 'classnames'
import * as React from 'react'

import { BaseParentProps, BaseProps, BaseClickableProps } from '../props'
import './style.scss'

interface HeaderProps extends BaseParentProps, BaseProps {
  style?: React.CSSProperties
}
const Header = (props: HeaderProps) => {
  const className = classNames(
    props.className || '',
    'post-panel-segment',
    'post-panel-header'
  )
  const style: React.CSSProperties = { ...props.style }
  return (
    <header className={classNames(className)} style={style}>
      <React.Fragment>{props.children}</React.Fragment>
    </header>
  )
}

interface ContentProps extends BaseParentProps, BaseProps {
  padding?: 0 | 8 | 16 | 24 | 32
  style?: React.CSSProperties
}

const Content = (props: ContentProps) => {
  const basicClassNames = classNames(
    props.className || '',
    'post-panel-segment',
    'post-panel-content'
  )
  const style: React.CSSProperties = {
    padding: props.padding,
    ...props.style,
  }
  return (
    <section style={style} className={basicClassNames}>
      <React.Fragment>{props.children}</React.Fragment>
    </section>
  )
}

interface FooterProps extends BaseProps, BaseParentProps {
  bgColor?: 'gray'
  padding?: 8 | 16 | 24 | 32
}

const Footer = (props: FooterProps) => {
  const style: React.CSSProperties = {
    padding: props.padding || 16,
  }
  const footerClassname = classNames(
    props.className || '',
    'post-panel-segment',
    'post-panel-footer',
    props.bgColor ? 'post-panel-footer-bg-color-' + props.bgColor : ''
  )
  return (
    <footer className={footerClassname} style={style}>
      <React.Fragment>{props.children}</React.Fragment>
    </footer>
  )
}

interface PanelProps extends BaseParentProps, BaseProps {
  type?: 'rounded'
  noborder?: boolean
  fullScreen?: boolean
}

const Panel: React.FC<PanelProps> = (props: PanelProps) => {
  const type = props.type ? props.type : 'rounded'
  const className = classNames(
    'post-panel',
    'post-panel-' + type,
    props.noborder ? 'post-panel-noborder' : 'post-panel-border',
    props.fullScreen && 'fullscreen'
  )

  return (
    <div className={className} style={props.style}>
      <React.Fragment>{props.children}</React.Fragment>
    </div>
  )
}

interface CardProps extends BaseParentProps, BaseProps, BaseClickableProps {
  minHeight?: number
  padding?: 4 | 8 | 10 | 12 | 16 | 24 | 32
  style?: React.CSSProperties
  backgroundColor?:
    | 'white'
    | 'blue'
    | 'gray'
    | 'darkgray'
    | 'bluegray'
    | 'neutral'
  overflow?: string
  fitContent?: boolean
}
const Card: React.FC<CardProps> = (props: CardProps) => {
  const className = classNames(
    props.className || '',
    'post-panel',
    'post-panel-card',
    props.fitContent && 'fit-content',
    props.backgroundColor && `post-panel-background ${props.backgroundColor}`
  )
  let style: React.CSSProperties = {
    padding: props.padding || 0,
    overflow: props.overflow || 'unset',
    cursor: props.onClick && 'pointer',
    ...props.style,
  }
  if (props.minHeight) {
    style = {
      ...style,

      minHeight: props.minHeight,
    }
  }

  const handleOnClick = () => {
    if (props.onClick) {
      props.onClick()
    }
  }

  return (
    <div style={style} className={className} onClick={handleOnClick}>
      <React.Fragment>{props.children}</React.Fragment>
    </div>
  )
}

export default {
  Panel,
  Header,
  Content,
  Footer,
  Card,
}
