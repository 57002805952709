import { BaseReportingService } from '.'
import { toQueryISOString } from 'utilities/converter/date'
import { ReportingFilter } from '../models'

export class PaymentMethodReportService
  extends BaseReportingService
  implements IPaymentMethodReportService
{
  public async getPaymentMethodReports(filter?: ReportingFilter) {
    try {
      const queryParams = {
        outletId: filter.selectedOutletId || '',
        fromDate: filter.dateRange
          ? toQueryISOString(filter.dateRange.from, true)
          : '',
        toDate: filter.dateRange
          ? toQueryISOString(filter.dateRange.to, true)
          : '',
        viewIn: filter.showType,
      }
      const response = await this.api.reporting.getPaymentMethodReports(
        queryParams
      )
      this.reportingStorage.setPaymentMethodListReport(response)
    } catch (error) {
      console.log(error)
    }
  }
}

export interface IPaymentMethodReportService {
  getPaymentMethodReports: (filter?: ReportingFilter) => Promise<void>
}
