export const CONFIG_LOCALSTORAGE = {
  POST_TOKEN: '_postDashboardToken',
  POST_TYPE: '_postDashboardAccessType',
  STAFF_ID: '_postEmployeeId',
  STAFF_OUTLET_ID: '_postEmployeeOutletId',
  STAFF_BUSINESS_CATEGORY_ID: '_postEmployeeBusinessCategoryId',
  STAFF_OUTLET_NAME: '_postEmployeeOutletName',
  STAFF_PERMISSION_LIST: '_postEmployeePermissionList',
  ONBOARDING: '_postOnboarding',
  HOME_CARD_VISIBILITY: '_postHomeCardVisibility',
  IS_ONBOARDING_ACCOMPLISHED: '_postIsOnboardingAccomplished',
  IS_ONBOARDING_NOTIFIED: '_postIsOnboardingNotified',
  IS_ONBOARDING_STEP2_CLOSED: '_postIsOnboardingStep2Closed',
  IS_ONBOARDING_SKIPPED: '_postIsOnboardingSkipped',
  IS_FILL_BUSINESS_INFO: '_postIsFillBusinessInfo',
  IS_BUSINESS_INFO_NOTIF_CLOSED: '_postIsBusinessInfoNotifClosed',
  IS_SKIP_EASYORDER_ONBOARD: '_postIsSkipEasyOrderOnboard',
  CURRENT_DAY: '_postCurrentDay',
  USER_CREATED_DATE: '_postUserCreatedDate',
  USER_PREFERRED_LANGUAGE: '_postUserPreferredLanguage',
  IS_USER_FILLED_BUSINESS_INFO: '_postIsUserFilledBusinessInfo',
  IS_BANNER_PAYMENT_KYC_CLOSED: '_postIsBannerPaymentKycClosed',
  IS_DOWNLOAD_APP_ONBOARDING_CLOSED: '_postIsDownloadAppOnboardingClosed',
  IS_CALL_CENTER_BANNER_CLOSED: '_postIsCallCenterBannerClosed',
  QRIS_POPUP_LAST_SHOWN: '_postQRISPopupLastShown',
  IS_QRIS_POPUP_SHOW: '_postIsQRISPopupShow',
}
