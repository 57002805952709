import { Reducer, StateActions } from 'utilities/redux/reducer'
import { Dict } from 'utilities/type'
import {
  EmployeeState,
  EmployeeQueryParam,
} from 'domains/management/employee/states'
import { Employee, Staff, Cashier } from 'domains/management/employee/models'
import {
  SET_EMPLOYEES,
  APPEND_EMPLOYEES,
  SET_CURRENT_STAFF,
  SET_CURRENT_CASHIER,
  SET_EMPLOYEE_LIST_REACH_BOTTOM,
  SET_LOADING_MORE_EMPLOYEE,
  SET_EMPLOYEE_LIST_REQUEST,
  SET_IS_FNB_BUSINESS_CATEGORY,
} from './actions'

export class EmployeeReducer extends Reducer<EmployeeState> {
  constructor() {
    super({
      employees: {},
      currentStaff: {} as Staff,
      currentCashier: {} as Cashier,
      employeeListManager: {
        isLoadingMoreEmployee: false,
        request: {},
        hasReachedBottom: false,
      },
    })
  }

  setEmployees = (
    state: EmployeeState,
    employees: Dict<Employee>
  ): EmployeeState => {
    return {
      ...state,
      employees,
    }
  }

  setCurrentStaff = (
    state: EmployeeState,
    currentStaff: Staff
  ): EmployeeState => {
    return {
      ...state,
      currentStaff,
    }
  }

  setCurrentCashier = (
    state: EmployeeState,
    currentCashier: Cashier
  ): EmployeeState => {
    return {
      ...state,
      currentCashier,
    }
  }

  appendEmployees = (
    state: EmployeeState,
    employees: Dict<Employee>
  ): EmployeeState => {
    return {
      ...state,
      employees: {
        ...state.employees,
        ...employees,
      },
    }
  }

  setEmployeeListReachBottom = (
    state: EmployeeState,
    hasReachedBottom: boolean
  ): EmployeeState => {
    return {
      ...state,
      employeeListManager: {
        ...state.employeeListManager,
        hasReachedBottom,
      },
    }
  }

  setLoadingMoreEmployee = (
    state: EmployeeState,
    isLoadingMoreEmployee: boolean
  ): EmployeeState => {
    return {
      ...state,
      employeeListManager: {
        ...state.employeeListManager,
        isLoadingMoreEmployee,
      },
    }
  }

  setEmployeeListRequest = (
    state: EmployeeState,
    request: EmployeeQueryParam
  ): EmployeeState => {
    return {
      ...state,
      employeeListManager: {
        ...state.employeeListManager,
        request,
      },
    }
  }

  public setIsFnbBusinessCategory(
    state: EmployeeState,
    isFnbBusinessCategory: boolean
  ): EmployeeState {
    return {
      ...state,
      isFnbBusinessCategory,
    }
  }

  get actions(): StateActions<EmployeeState> {
    return {
      [SET_EMPLOYEES]: this.setEmployees,
      [SET_CURRENT_STAFF]: this.setCurrentStaff,
      [SET_CURRENT_CASHIER]: this.setCurrentCashier,
      [APPEND_EMPLOYEES]: this.appendEmployees,
      [SET_EMPLOYEE_LIST_REACH_BOTTOM]: this.setEmployeeListReachBottom,
      [SET_LOADING_MORE_EMPLOYEE]: this.setLoadingMoreEmployee,
      [SET_EMPLOYEE_LIST_REQUEST]: this.setEmployeeListRequest,
      [SET_IS_FNB_BUSINESS_CATEGORY]: this.setIsFnbBusinessCategory,
    }
  }
}
