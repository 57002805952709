import { BaseApi } from 'api/base.api'
import {
  Category,
  CreateCategoryRequest,
  CategoryRequestBody,
} from 'domains/management/category/models'
import { ICategoryApi } from 'domains/management/category/contracts/api'

export default class CategoryApi extends BaseApi implements ICategoryApi {
  constructor() {
    super('/v2/categories')
  }

  public async getCategories(queryParams?: any): Promise<Category[]> {
    return await this.makeForCamelCaseForList('GET', '', queryParams)
  }

  public async createSingleCategory(
    query: CreateCategoryRequest
  ): Promise<Category> {
    return await this.makeForCamelCase('POST', '', query)
  }

  public async updateSingleCategory(
    body: CategoryRequestBody,
    id: string
  ): Promise<Category> {
    return await this.makeForCamelCase('POST', `/${id}`, body)
  }

  public async deleteSingleCategory(id: string): Promise<void> {
    return await this.make('DELETE', id)
  }
}
