import { ReportingFilter } from '../models'
import { toQueryISOString } from 'utilities/converter/date'
import { BaseReportingService } from '.'

export interface IFilterParam {
  outletId: string
  fromDate: string
  toDate: string
  viewIn: 'summary' | 'daily' | 'weekly' | 'monthly'
}

export class ProductSaleReportingService
  extends BaseReportingService
  implements IProductSaleReportingService
{
  public async getProductSaleReporting(
    filter?: ReportingFilter
  ): Promise<void> {
    try {
      const queryParams: IFilterParam = {
        outletId: filter.selectedOutletId || '',
        fromDate: filter.dateRange
          ? toQueryISOString(filter.dateRange.from, true)
          : '',
        toDate: filter.dateRange
          ? toQueryISOString(filter.dateRange.to, true)
          : '',
        viewIn: filter.showType,
      }
      const response = await this.api.reporting.getProductSaleReports(
        queryParams
      )
      this.reportingStorage.setProductSalesReporting(response)
    } catch (error) {
      console.error(error)
    }
  }
}

export interface IProductSaleReportingService {
  getProductSaleReporting(filter?: ReportingFilter): Promise<void>
}
