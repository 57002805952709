import { DropdownItem } from 'views/components/Field/Dropdown'
import { Category } from 'domains/management/category/models'
import { Dict } from 'utilities/type'
import { toList } from 'utilities/converter/list'
import { AppState } from 'domains/contracts/appState'
import { ServiceType } from 'domains/management/serviceType/models'
import {
  ProductModel,
  ProductPricing,
} from 'domains/management/products/models'
import { OutletModel } from 'domains/management/outlets/models'
import { User } from 'domains/users/models'

export interface ProductViewModel {
  products: ProductModel[]
  categoryDropdownList: DropdownItem[]
  outletDropdownList: DropdownItem[]
  outletList: OutletModel[]
  serviceTypes: ServiceType[]
  isLoadingFirstLoad: boolean
  pricings: ProductPricing[]
  isLoadingLoadMore: boolean
  user: User
}

export const categoryForDropdown = (
  categories: Dict<Category>
): DropdownItem[] => {
  const newCategories = toList(categories)
  return newCategories
    ? newCategories.map((item, i) => ({
        value: item.id,
        label: item.name,
      }))
    : []
}

export const outletForDropdown = (
  outlets: Dict<OutletModel>
): DropdownItem[] => {
  const newOutlets = toList(outlets)
  return newOutlets
    ? newOutlets.map((item, i) => ({
        value: item.id,
        label: item.name,
      }))
    : []
}

export default (state: AppState): ProductViewModel => ({
  categoryDropdownList: categoryForDropdown(
    state.management.category.categories
  ),
  outletDropdownList: outletForDropdown(state.management.outlet.outlets),
  outletList: toList(state.management.outlet.outlets),
  serviceTypes: toList(state.management.serviceType.serviceTypes),
  pricings: toList(state.management.serviceType.serviceTypes).map(x => ({
    serviceTypeId: x.id,
    serviceType: x,
    price: 0,
  })),
  products: toList(state.management.product.products),
  isLoadingFirstLoad: state.common.loading['SET_PRODUCTS'],
  isLoadingLoadMore:
    state.management.product.productListManager.isLoadingMoreProduct,
  user: state.user,
})
