import { Reducer, StateActions } from 'utilities/redux/reducer'
import { Dict } from 'utilities/type'
import { PaymentMethodState } from 'domains/management/paymentMethod/states'
import {
  PaymentMethodModel,
  QRISPaymentMethod,
} from 'domains/management/paymentMethod/models'
import { SET_PAYMENT_METHODS, SET_QRIS_PAYMENT_METHODS } from './actions'

export class PaymentMethodReducer extends Reducer<PaymentMethodState> {
  constructor() {
    super({
      paymentMethods: {},
      QRISPaymentMethods: [],
    })
  }

  public setPaymentMethods(
    state: PaymentMethodState,
    paymentMethods: Dict<PaymentMethodModel>
  ): PaymentMethodState {
    return {
      ...state,
      paymentMethods,
    }
  }

  setQRISPaymentMethods = (
    state: PaymentMethodState,
    QRISPaymentMethods: QRISPaymentMethod
  ): PaymentMethodState => {
    return {
      ...state,
      QRISPaymentMethods: [...state.QRISPaymentMethods, QRISPaymentMethods],
    }
  }

  get actions(): StateActions<PaymentMethodState> {
    return {
      [SET_PAYMENT_METHODS]: this.setPaymentMethods,
      [SET_QRIS_PAYMENT_METHODS]: this.setQRISPaymentMethods,
    }
  }
}
