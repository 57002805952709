import React, { useState, useEffect } from 'react'
import MonthHeaderSelector, { MonthAndYear } from '../MonthHeaderSelector'
import './style.css'

import moment from 'moment'

import { getCalendarDates } from '../utils'
import { DateRange } from 'domains/commons/models'
import {
  isDateInBetween,
  isAtStartOrEnd,
  isAtStart,
  isAtEnd,
} from 'utilities/date'
import Text from 'views/components/Text'
import Flex from 'views/components/Flex'
import Button from 'views/components/Button'
import Panel from 'views/components/Panel'
import { toDateString } from 'utilities/converter/date'

interface DailyCalendarProps {
  dateVisibilityFunc?: (currentDay: Date, firstDateSelection?: Date) => boolean
  selectedDateRange: DateRange
  onChange: (dateRange: DateRange, datePlaceholder: string) => any
  isActive: boolean
  isSingle?: boolean
  onOptimisticConfirm?: (from: Date, to: Date) => void
}

const DailyCalendar = (props: DailyCalendarProps) => {
  const [hoveredDateRange, setHoveredDateRange] = useState(null)

  const [pickedStartDate, setPickedStartDate] = useState(moment().toDate())

  const [monthAndYear, setMonthAndYear] = useState({
    year: moment().year(),
    month: moment().month() + 1,
  })

  useEffect(() => {
    setPickedStartDate(null)
    setHoveredDateRange(null)
  }, [props.isActive])

  const [currentDailyCalendar, setCurrentDailyCalendar] = useState(
    getCalendarDates(monthAndYear.year, monthAndYear.month)
  )

  const reloadCalendar = (x: MonthAndYear) => {
    const calendar = getCalendarDates(x.year, x.month)
    setMonthAndYear(x)
    setCurrentDailyCalendar(calendar)
  }

  const handleDateMouseOver = (i: number, j: number) => {
    const day = currentDailyCalendar[i][j]
    if (pickedStartDate != null) {
      onHoveredDailyCalendar(pickedStartDate, day)
    }
  }

  const onHoveredDailyCalendar = (from: Date, to: Date) => {
    if (from > to) {
      ;[from, to] = [to, from]
    }

    setHoveredDateRange({
      from,
      to,
    })
  }

  const resolveDateStyle = (day: Date): string => {
    const ret: string[] = []

    if (day.getMonth() + 1 != monthAndYear.month) {
      ret.push('other-month')
    }

    if (!pickedStartDate) {
      const ranges: DateRange = props.selectedDateRange
      if (isDateInBetween(day, ranges)) {
        ret.push('ranged')
      }
      if (isAtStart(day, ranges)) {
        ret.push('start')
      }
      if (isAtEnd(day, ranges)) {
        ret.push('end')
      }
      if (isAtStartOrEnd(day, ranges)) {
        ret.push('active')
      }
    } else {
      const ranges: DateRange = hoveredDateRange

      if (isDateInBetween(day, ranges)) {
        ret.push('hovered-ranged')
      }

      if (moment(day).isSame(pickedStartDate)) {
        ret.push('active')
      }
    }
    if (moment(day).isSame(moment().startOf('day'))) {
      ret.push('today')
    }

    return ret.join(' ')
  }

  const onRangeSelectedFromDailyCalendar = (from: Date, to: Date) => {
    if (from > to) {
      ;[from, to] = [to, from]
    }

    if (
      moment(from).isSame(moment(), 'day') &&
      moment(to).isSame(moment(), 'day')
    ) {
      props.onChange(
        {
          from,
          to,
        },
        `(${toDateString(from, 'DD MMMM YYYY')})`
      )
    } else if (from === to) {
      props.onChange(
        {
          from,
          to,
        },
        `${toDateString(from, 'DD MMMM YYYY')}`
      )
    } else {
      props.onChange(
        {
          from,
          to,
        },
        `${
          toDateString(from, 'DD/MM/YYYY') +
          ' - ' +
          toDateString(to, 'DD/MM/YYYY')
        }`
      )
    }
  }

  const handleDateClick = (i: number, j: number) => {
    const day = currentDailyCalendar[i][j]

    if (props.isSingle) {
      onRangeSelectedFromDailyCalendar(day, day)
      setPickedStartDate(null)
      return
    }
    if (pickedStartDate != null) {
      onRangeSelectedFromDailyCalendar(pickedStartDate, day)
      setPickedStartDate(null)
      props?.onOptimisticConfirm?.(pickedStartDate, day)
    } else {
      onRangeSelectedFromDailyCalendar(day, day)
      setPickedStartDate(day)
      setHoveredDateRange(null)
    }
  }

  return (
    <React.Fragment>
      <Panel.Content className='calendar-daily-component'>
        <MonthHeaderSelector
          monthAndYear={monthAndYear}
          onChange={(x: { year: number; month: number }) => reloadCalendar(x)}
        />
      </Panel.Content>
      <Panel.Content className='calendar-daily-component'>
        <Flex.Row flexDirection='row' className='date-header'>
          <Flex.Column flex='1'>Min</Flex.Column>
          <Flex.Column flex='1'>Sen</Flex.Column>
          <Flex.Column flex='1'>Sel</Flex.Column>
          <Flex.Column flex='1'>Rab</Flex.Column>
          <Flex.Column flex='1'>Kam</Flex.Column>
          <Flex.Column flex='1'>Jum</Flex.Column>
          <Flex.Column flex='1'>Sab</Flex.Column>
        </Flex.Row>
        {currentDailyCalendar.map((week, i) => (
          <Flex.Row key={i} flexDirection='row' className='date-item'>
            {week.map((day, j) => {
              let content = <Text.Span>{toDateString(day, 'D')}</Text.Span>

              if (moment(day).isSame(moment(), 'day')) {
                content = (
                  <React.Fragment>
                    <Text.Span>{toDateString(day, 'D')}</Text.Span>
                    <Text.Paragraph size={8} className='mini' noMargin>
                      Hari Ini
                    </Text.Paragraph>
                  </React.Fragment>
                )
              }

              const defaultDateVisibilityFunc = (
                day: Date,
                pickedStartDate?: Date
              ) => moment(day) < moment()
              const visibilityFunc =
                props.dateVisibilityFunc || defaultDateVisibilityFunc
              return (
                <Flex.Column flex='1'>
                  <Button
                    key={j}
                    compact
                    fullW
                    theme='transparent'
                    disabled={!visibilityFunc(day, pickedStartDate)}
                    className={resolveDateStyle(day)}
                    onClick={() => handleDateClick(i, j)}
                    onMouseOver={() => handleDateMouseOver(i, j)}
                  >
                    {content}
                  </Button>
                </Flex.Column>
              )
            })}
          </Flex.Row>
        ))}
      </Panel.Content>
    </React.Fragment>
  )
}

export default DailyCalendar
