import { ReportingFilter } from '../models'
import { toQueryISOString } from 'utilities/converter/date'
import { BaseReportingService } from '.'

export interface IFilterParam {
  outletId: string
  fromDate: string
  toDate: string
  viewIn: 'summary' | 'daily' | 'weekly' | 'monthly'
}

export class EmployeeReportingService
  extends BaseReportingService
  implements IEmployeeReportingService
{
  public async getEmployeeReporting(filter?: ReportingFilter): Promise<void> {
    try {
      const queryParams: IFilterParam = {
        outletId: filter.selectedOutletId || '',
        fromDate: filter.dateRange
          ? toQueryISOString(filter.dateRange.from, true)
          : '',
        toDate: filter.dateRange
          ? toQueryISOString(filter.dateRange.to, true)
          : '',
        viewIn: filter.showType,
      }
      const response = await this.api.reporting.getEmployeeReports(queryParams)
      this.reportingStorage.setEmployeeReporting(response)
    } catch (error) {
      console.error(error)
    }
  }
}

export interface IEmployeeReportingService {
  getEmployeeReporting(filter?: ReportingFilter): Promise<void>
}
