import { BaseApi } from 'api/base.api'
import { IExpenseApi } from 'domains/management/expense/contracts/api'
import {
  ExpenseRequestBody,
  ExpenseCategory,
  Expense,
} from 'domains/management/expense/models'
import {
  ExpenseReportQueryString,
  ExpenseSummary,
} from 'domains/reporting/models'

export default class ExpenseApi extends BaseApi implements IExpenseApi {
  constructor() {
    super('/v3')
  }

  public async createExpense(req: ExpenseRequestBody): Promise<Expense> {
    return await this.makeForCamelCase('POST', '/expenses', req)
  }

  public async updateExpense(
    req: ExpenseRequestBody,
    id: string
  ): Promise<Expense> {
    return await this.makeForCamelCase('POST', `/expenses/${id}`, req)
  }

  public async deleteExpense(expenseId: string): Promise<void> {
    return await this.makeForCamelCase('DELETE', `/expenses/${expenseId}`, {
      expenseId,
    })
  }

  public async createCategory(name: string): Promise<ExpenseCategory> {
    return await this.makeForCamelCase('POST', '/expenses/categories', {
      name,
    })
  }

  public async updateCategory(
    category: ExpenseCategory
  ): Promise<ExpenseCategory> {
    return await this.makeForCamelCase(
      'POST',
      `/expenses/categories/${category.id}`,
      {
        name: category.name,
        categoryId: category.id,
      }
    )
  }

  public async deleteCategory(categoryId: string): Promise<void> {
    return await this.makeForCamelCase(
      'DELETE',
      `/expenses/categories/${categoryId}`,
      {
        categoryId,
      }
    )
  }

  public async getCategories(): Promise<ExpenseCategory[]> {
    return await this.makeForCamelCaseForList('GET', `/expenses/categories`, {})
  }

  public async getExpenseReports(
    queryParams?: ExpenseReportQueryString
  ): Promise<ExpenseSummary[]> {
    return await this.makeForCamelCaseForList(
      'GET',
      '/reports/expenses',
      queryParams
    )
  }
}
