import Xlsx, { WorkBook, WorkSheet } from 'xlsx'
import { toList } from 'utilities/converter/list'

export interface DataCells {
  list: any[]
  cells: any[][]
}

export const convertExcelBinaryTo2DArray = (binary: string): DataCells => {
  const wb: WorkBook = Xlsx.read(binary, {
    type: 'binary',
  })

  const sheet: WorkSheet = toList(wb.Sheets)[0]
  const range = Xlsx.utils.decode_range(sheet['!ref'])
  const cells: any[][] = []
  for (let R = range.s.r; R <= range.e.r; ++R) {
    for (let C = range.s.c; C <= range.e.c; ++C) {
      const cellref = Xlsx.utils.encode_cell({ c: C, r: R }) // construct A1 reference for cell
      if (!sheet[cellref]) continue // if cell doesn't exist, move on
      const cell = sheet[cellref]
      if (!cells[R]) {
        cells[R] = []
      }
      cells[R][C] = cell.v
    }
  }
  const rawList: any[] = Xlsx.utils.sheet_to_json(sheet)
  return {
    cells,
    list: rawList,
  }
}
