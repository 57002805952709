import React, { CSSProperties } from 'react'
import './style.css'
import classNames from 'classnames'
import { BaseParentProps, BaseProps } from '../props'
import { OfficialTextColor, AlignmentClass } from '../const'

interface ParagraphProps extends BaseParentProps, BaseProps {
  color?: OfficialTextColor
  size?: 8 | 10 | 12 | 14 | 16 | 18 | 20 | 24 | 32 | 40
  weight?: any
  dangerousContent?: string
  align?: AlignmentClass
  onClick?: () => any
  noMargin?: boolean
  noWrap?: boolean
}

interface HeadingProps extends BaseParentProps, BaseProps {
  color?: OfficialTextColor
  h: 1 | 2 | 3 | 4 | 5 | 6
  weight?: string
  dangerousContent?: string
  align?: AlignmentClass
  onClick?: () => any
  noMargin?: boolean
}

interface SpanProps extends BaseParentProps, BaseProps {
  color?: OfficialTextColor
  size?: 10 | 12 | 14 | 16 | 18 | 20 | 24 | 32 | 40
  weight?: any
  dangerousContent?: string
  align?: AlignmentClass
  onClick?: () => any
}

interface LinkProps extends BaseParentProps, BaseProps {
  href: string
  target?: string
  color?: OfficialTextColor
  size?: 10 | 12 | 14 | 16 | 18 | 20 | 24 | 32 | 40
  weight?: any
  dangerousContent?: string
  align?: AlignmentClass
  onClick?: () => any
}

const Paragraph = (props: ParagraphProps) => {
  const className = classNames(
    'post-paragraph',
    props.color,
    props.size ? 'post-text-size-' + props.size : '',
    props.align ? 'post-align-' + props.align : '',
    props.className || '',
    { 'cursor-pointer': props.onClick },
    props.noMargin ? 'post-paragraph-no-margin' : ''
  )
  const style: React.CSSProperties = {
    whiteSpace: props.noWrap ? 'nowrap' : 'normal',
    fontWeight: props.weight,
    ...props.style,
  }
  const dangerousContent = props.dangerousContent
    ? { __html: props.dangerousContent }
    : null
  return (
    <p
      data-testid='text-paragraph'
      className={className}
      style={style}
      onClick={props.onClick}
      // TODO: Sanitize dangerous HTML to prevent XSS
      dangerouslySetInnerHTML={dangerousContent}
    >
      {props.children}
    </p>
  )
}
const Heading = (props: HeadingProps) => {
  const className = classNames(
    'post-heading',
    props.color,
    props.align ? 'post-align-' + props.align : '',
    props.className || '',
    { 'cursor-pointer': props.onClick },
    props.noMargin ? 'post-heading-no-margin' : '',
    props.weight
  )
  const CustomTag = `h${props.h}` as any
  const dangerousContent = props.dangerousContent
    ? { __html: props.dangerousContent }
    : null

  return (
    <CustomTag
      data-testid='text-heading'
      className={className}
      onClick={props.onClick}
      style={props.style}
      // TODO: Sanitize dangerous HTML to prevent XSS
      dangerouslySetInnerHTML={dangerousContent}
    >
      {props.children}
    </CustomTag>
  )
}
const Span = (props: SpanProps) => {
  const className = classNames(
    'post-span',
    props.color,
    props.align ? 'post-align-' + props.align : '',
    props.size ? 'post-text-size-' + props.size : '',
    props.className || '',
    { 'cursor-pointer': props.onClick }
  )

  const style: CSSProperties = {
    ...props.style,
    fontWeight: props.weight,
  }

  const dangerousContent = props.dangerousContent
    ? { __html: props.dangerousContent }
    : null
  return (
    <span
      data-testid='text-span'
      className={className}
      style={style}
      onClick={props.onClick}
      // TODO: Sanitize dangerous HTML to prevent XSS
      dangerouslySetInnerHTML={dangerousContent}
    >
      {props.children}
    </span>
  )
}

const Link = (props: LinkProps) => {
  const className = classNames(
    'post-link',
    props.color,
    props.align ? 'post-align-' + props.align : '',
    props.size ? 'post-text-size-' + props.size : '',
    props.className || '',
    { 'cursor-pointer': props.onClick }
  )

  const style: CSSProperties = {
    ...props.style,
    fontWeight: props.weight,
  }

  const dangerousContent = props.dangerousContent
    ? { __html: props.dangerousContent }
    : null

  return (
    <a
      href={props.href}
      target={props.target}
      data-testid='text-link'
      className={className}
      style={style}
      onClick={props.onClick}
      // TODO: Sanitize dangerous HTML to prevent XSS
      dangerouslySetInnerHTML={dangerousContent}
    >
      {props.children}
    </a>
  )
}

const Text = {
  Paragraph,
  Heading,
  Span,
  Link,
}

export default Text
export { Paragraph, Heading, Span, Link }
