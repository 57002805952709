import classNames from 'classnames'
import React from 'react'
import { Column, useExpanded, useTable } from 'react-table'
import './style.css'
import { Panel } from 'views/components'

interface TableV2Props {
  columns: Array<Column<any>>
  data: any[]
  className?: string
  defaultColumn?: Partial<Column<any>>
  skipPageReset?: boolean
  updateMyData?: (rowIndex: number, columnId: string, value: any) => void
  useExpanded?: boolean
}

const TableV2 = (props: TableV2Props) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns: props.columns,
        data: props.data,
        defaultColumn: props.defaultColumn,
        autoResetPage: !props.skipPageReset,
        updateMyData: props.updateMyData,
      },
      props.useExpanded && useExpanded
    )

  const className = classNames(
    'post-table-v2',
    props.className || 'white-column'
  )

  return (
    <Panel.Card>
      <table
        {...getTableProps()}
        style={{ width: '100%' }}
        className={className}
      >
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </Panel.Card>
  )
}

export default TableV2
