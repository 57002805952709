import { BaseStorage } from 'storages/base'
import { IRoleStorage } from 'domains/management/role/contracts/storage'
import {
  RoleModel,
  GetRoleListQueryParams,
} from 'domains/management/role/models'
import { RoleListManager } from 'domains/management/role/states'
import {
  SET_ROLES,
  APPEND_ROLES,
  SET_ROLE_LIST_MANAGER,
  SET_ROLE_LIST_REQUEST,
  SET_ROLE_LIST_REACH_BOTTOM,
  SET_LOADING_MORE_ROLE,
} from './actions'

export class RoleStorage extends BaseStorage implements IRoleStorage {
  public setRoles(roles: RoleModel[]): void {
    this.dispatch(SET_ROLES, roles)
  }

  public appendRoles(roles: RoleModel[]): void {
    this.dispatch(APPEND_ROLES, roles)
  }

  public setRoleListManager(roleListManager: RoleListManager): void {
    this.dispatch(SET_ROLE_LIST_MANAGER, roleListManager)
  }

  public setRoleListRequest(request: GetRoleListQueryParams): void {
    this.dispatch(SET_ROLE_LIST_REQUEST, request)
  }

  public setRoleListReachBottom(hasReachedBottom: boolean): void {
    this.dispatch(SET_ROLE_LIST_REACH_BOTTOM, hasReachedBottom)
  }

  public setLoadingMoreRole(isLoadingMoreRole: boolean): void {
    this.dispatch(SET_LOADING_MORE_ROLE, isLoadingMoreRole)
  }
}
