import { BaseService } from '../../commons/services/index'
import { CardData } from '../states'
import {
  ReportingFilter,
  SummaryReportQueryString,
  ExpenseSummary,
} from 'domains/reporting/models'
import { toQueryISOString, toUTCISOString } from 'utilities/converter/date'
import { ICommonStorage } from 'domains/commons/contracts/storage'
import { IHomeStorage } from '../contracts/storage'
import { Api } from 'domains/contracts/api'
import { setLocalStorage, getLocalStorage } from 'utilities/localStorage'
import { CONFIG_LOCALSTORAGE } from 'constants/localStorage'
import { DateRange } from 'domains/commons/models'

const SET_CARD_SUMMARY = 'SET_CARD_SUMMARY'

export class HomeService extends BaseService implements IHomeService {
  homeStorage: IHomeStorage

  constructor(
    commonStorage: ICommonStorage,
    api: Api,
    homeStorage: IHomeStorage
  ) {
    super(commonStorage, api)
    this.homeStorage = homeStorage
  }

  public async loadCard(filter?: ReportingFilter): Promise<CardData> {
    this.setLoading(SET_CARD_SUMMARY, true)

    const queryParams: SummaryReportQueryString = !!filter && {
      outletId: filter.selectedOutletId || '',
      fromDate: filter.dateRange && toQueryISOString(filter.dateRange.from),
      toDate: filter.dateRange && toQueryISOString(filter.dateRange.to),
    }

    const expenseQueryParams = {
      outletId: filter.selectedOutletId || '',
      fromDate: filter.dateRange ? toUTCISOString(filter.dateRange.from) : '',
      toDate: filter.dateRange ? toUTCISOString(filter.dateRange.to) : '',
    }

    const summaryReports = await this.api.reporting.getSummaries(queryParams)
    const productSaleReports = await this.api.reporting.getProductSaleReports(
      queryParams
    )
    const expenseReports = await this.api.management.expense.getExpenseReports(
      expenseQueryParams
    )

    const calculateExpenses = (expenseReports: ExpenseSummary[]) => {
      let sum = 0
      for (let i = 0; i < expenseReports.length; i++) {
        for (let j = 0; j < expenseReports[i].expenses.length; j++) {
          sum += expenseReports[i].expenses[j].amount
        }
      }
      return sum
    }

    let cardData: CardData = {}
    if (summaryReports.length > 0 || expenseReports.length > 0) {
      cardData = {
        totalTransactions: summaryReports[0]
          ? summaryReports[0].transactionCount
          : 0,
        grossSales: summaryReports[0] ? summaryReports[0].grossSales : 0,
        netSales: summaryReports[0] ? summaryReports[0].netSales : 0,
        netIncome: summaryReports[0] ? summaryReports[0].netIncome : 0,
        topProducts: productSaleReports[0]
          ? productSaleReports[0].products.slice(0, 5)
          : null,
        expenseAmounts: calculateExpenses(expenseReports),
      }
    }
    this.homeStorage.setInformationSummary(cardData)
    this.setLoading(SET_CARD_SUMMARY, false)
    return cardData
  }

  public toggleVisibility(key: string) {
    let homeCardVisibility = JSON.parse(
      getLocalStorage(CONFIG_LOCALSTORAGE.HOME_CARD_VISIBILITY)
    )
    homeCardVisibility = {
      ...homeCardVisibility,
      [key]: homeCardVisibility ? !homeCardVisibility[key] : true,
    }
    setLocalStorage(
      CONFIG_LOCALSTORAGE.HOME_CARD_VISIBILITY,
      JSON.stringify(homeCardVisibility)
    )
    this.homeStorage.toggleCardVisibility(key)
  }

  public setAllVisibility = () => {
    const homeCardVisibility = {
      TOTALTRANSACTIONS: true,
      GROSSSALES: true,
      NETSALES: true,
      NETINCOME: true,
      TOPPRODUCTS: true,
      EXPENSE: true,
    }
    setLocalStorage(
      CONFIG_LOCALSTORAGE.HOME_CARD_VISIBILITY,
      JSON.stringify(homeCardVisibility)
    )
    this.homeStorage.setAllVisible()
  }

  public setFilterDateRange = async (dateRange: DateRange) => {
    this.homeStorage.setFilterDateRange(dateRange)
  }

  public setFilterOutletId = async (outletId?: string) => {
    this.homeStorage.setFilterOutletId(outletId)
  }

  public setFilterCalendarDropdown = async (calendarType: string) => {
    this.homeStorage.setFilterCalendarDropdown(calendarType)
  }
}

export interface IHomeService {
  loadCard: (filter?: ReportingFilter) => Promise<CardData>
  toggleVisibility: (key: string) => void
  setAllVisibility: () => void
  setFilterDateRange(dateRange: DateRange): void
  setFilterOutletId(outletId?: string): void
  setFilterCalendarDropdown: (calendarType: string) => Promise<void>
}
