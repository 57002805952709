import React from 'react'
import {
  Text,
  Break,
  Wrapper,
  Sided,
  Button,
  Field,
  Form,
  FormAction,
  VSided,
  Panel,
  Divider,
} from 'views/components'
import './style.scss'
import { newPasswordSchema } from '../../Auth/ResetPassword/NewPasswordForm/newPasswordSchema'
import { PasswordConfirmation } from 'domains/auth/reset-password/models'
import { useSelector } from 'react-redux'
import selector from './../selector'
import { resetPasswordService, commonService } from 'injectors'
import { DefaultModalService, Modal, ModalProps } from 'views/components/Modal'
import OTPConfirmationModal from './OTPConfirmation'

type PasswordSettingProps = ModalProps

const PasswordSetting: React.FC<PasswordSettingProps> = props => {
  const { userPhone } = useSelector(selector)

  const submit = async (actions: FormAction<PasswordConfirmation>) => {
    try {
      const resetPasswordTokenBody = { username: userPhone }
      const resetPasswordTokenId =
        await resetPasswordService.createResetPasswordToken(
          resetPasswordTokenBody
        )
      const otp = await DefaultModalService.open(OTPConfirmationModal, {
        resetPasswordTokenId: resetPasswordTokenId,
        phone: userPhone,
      })
      if (otp) {
        resetPasswordService.resetPassword({
          resetPasswordTokenId: resetPasswordTokenId,
          otp: otp,
          newPassword: actions.getValues().newPassword,
        })
      }
      commonService.setAlert({
        message: 'Password Anda berhasil diubah',
        type: 'success',
      })
      actions.reset()
      props.close('')
    } catch (error) {
      actions.reset()
    }
  }

  return (
    <Modal
      dismiss={() => props.dismiss()}
      type='full'
      canDismissOutside={false}
    >
      <Form.Form
        height='100%'
        initialValues={{
          newPassword: '',
          confirmation: '',
        }}
        validationSchema={newPasswordSchema}
        onSubmit={submit}
        render={(actions: FormAction<PasswordConfirmation>) => (
          <VSided.VSided>
            <VSided.Remaining>
              <Wrapper width='470px' isCenter>
                <Break height={32} />
                <Text.Heading h={3}>Atur Password</Text.Heading>
                <Break height={24} />
                <Wrapper>
                  <Text.Paragraph align='center' size={14}>
                    Anda dapat mengubah password Akun untuk alasan keamanan dan
                    menyetel ulang jika lupa.
                  </Text.Paragraph>
                  <Break height={24} />
                  <Panel.Card padding={16}>
                    <Text.Paragraph size={14} weight='bold'>
                      Password
                    </Text.Paragraph>
                    <Divider />
                    <Break height={14} />
                    <Field.Password label='Password' name='newPassword' />
                    <Break height={8} />
                    <Field.Password
                      label='Konfirmasi Password'
                      name='confirmation'
                    />
                  </Panel.Card>
                </Wrapper>
              </Wrapper>
            </VSided.Remaining>
            <VSided.Fixed>
              <Wrapper
                shadow='0 -2px 4px 0 rgba(0, 0, 0, 0.2)'
                width='100%'
                padding='16px'
                backgroundColor='white'
              >
                <Wrapper width='480px' isCenter>
                  <Sided.Sided justify='center' gutter={16}>
                    <Sided.Remaining>
                      <Button
                        size='large'
                        theme='netral'
                        type='button'
                        label='Batal'
                        fullW={true}
                        onClick={() => props.dismiss()}
                      />
                    </Sided.Remaining>
                    <Sided.Remaining>
                      <Button
                        size='large'
                        theme='primary'
                        disabled={!actions.isValid()}
                        type='submit'
                        label='Simpan'
                        fullW={true}
                      />
                    </Sided.Remaining>
                  </Sided.Sided>
                </Wrapper>
              </Wrapper>
            </VSided.Fixed>
          </VSided.VSided>
        )}
      />
    </Modal>
  )
}

export default PasswordSetting
