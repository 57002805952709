import { getAppConfig } from 'config'
import { User } from 'domains/users/models'

const initialize = (user: User): void => {
  const { crispWebsiteId } = getAppConfig()
  if (crispWebsiteId) {
    const w = window as any
    w.$crisp = []
    w.$crisp.push(['set', 'user:email', [user.email || '']])
    w.$crisp.push([
      'set',
      'user:phone',
      [user.phone || user.businessPhone || ''],
    ])
    w.$crisp.push([
      'set',
      'user:nickname',
      [user.ownerName || user.businessName || ''],
    ])
    w.$crisp.push(['set', 'session:data', [[['user_id', user.id || '']]]])

    w.CRISP_WEBSITE_ID = crispWebsiteId
    ;(function () {
      var d = document
      var s = d.createElement('script') as any
      s.src = 'https://client.crisp.chat/l.js'
      s.async = 1
      d.getElementsByTagName('head')[0].appendChild(s)
    })()
  }
}

const openLiveChat = () => {
  const { crispWebsiteId } = getAppConfig()
  if (crispWebsiteId) {
    const w = window as any
    w.$crisp.push(['do', 'chat:open'])
  }
}

const sendMessage = (message: string) => {
  const { crispWebsiteId } = getAppConfig()
  if (crispWebsiteId) {
    const w = window as any
    w.$crisp.push(['do', 'message:send', ['text', message]])
  }
}

const crisp = {
  initialize,
  openLiveChat,
  sendMessage,
}

export default crisp
