import {
  EmployeeReport,
  EmployeeReportModel,
  SummaryReport,
  ProductSaleReport,
  Order,
} from 'domains/reporting/models'
import { toCamelCase } from 'api/converter'

export function toISummaryReport(obj: any) {
  const newObj = toCamelCase<SummaryReport>(obj)
  return {
    ...newObj,
    grossSales: obj.salesGross,
    netSales: obj.salesNet,
  }
}

export function toIProductSaleReport(obj: any) {
  const newObj = toCamelCase<ProductSaleReport>(obj)
  return {
    ...newObj,
    products: obj.products.map((product: any) => {
      return {
        ...product,
        grossSales: product.salesGross,
        netSales: product.salesNet,
      }
    }),
  }
}

export function toEmployeeReport(obj: any) {
  const newObj = toCamelCase<Omit<EmployeeReport, 'employees'>>(obj)
  return {
    ...newObj,
    employees: obj.employees.map((employee: EmployeeReportModel) => {
      return {
        ...employee,
        salesGross: employee.salesGross,
        salesNet: employee.salesNet,
      }
    }),
  }
}

export function toIOrderReport(obj: any) {
  const newObj = toCamelCase<Order>(obj)
  return {
    ...newObj,
  }
}
