import LocationAutoComplete from './LocationAutoComplete'
import DropdownField from './Dropdown'
import DropdownCheckbox from './DropdownCheckbox'
import Email from './Email'
import Number from './Number'
import Password from './Password'
import Text from './Text'
import TextArea from './TextArea'

const Field = {
  Text,
  Password,
  Email,
  Number,
  Dropdown: DropdownField,
  DropdownCheckbox,
  LocationAutoComplete,
  TextArea,
}

export default Field
