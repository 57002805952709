const reportingRoute = {
  MAIN_REPORTING: '/laporan',
  SUMMARY_REPORTING: '/laporan/ringkasan',
  PRODUCT_SALES_REPORTING: '/laporan/penjualan',
  CANCELLATION_REPORTING: '/laporan/pembatalan',
  DISCOUNT_REPORTING: '/laporan/diskon',
  SHIFT_REPORTING: '/laporan/shift',
  ORDER_TYPE_REPORTING: '/laporan/tipe-pesanan',
  PAYMENT_METHOD_REPORTING: '/laporan/metode-pembayaran',
  OUTPUT_REPORTING: '/laporan/pengeluaran',
  EMPLOYEE_REPORTING: '/laporan/karyawan',
  DISBURSEMENT_REPORTING: '/laporan/pencairan-dana',
}

export default reportingRoute
