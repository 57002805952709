import {
  ReportingFilter,
  ExpenseReportingFilter,
  ExpenseSummary,
} from '../models'
import { toUTCISOString } from 'utilities/converter/date'
import { IExpenseStorage } from 'domains/management/expense/contracts/storage'
import { ICommonStorage } from 'domains/commons/contracts/storage'
import { Api } from 'domains/contracts/api'
import { BaseService } from 'domains/commons/services'
import { toDict } from 'utilities/converter/list'
import { expenseService } from 'injectors'

export class ExpenseReportingService
  extends BaseService
  implements IExpenseReportingService
{
  expenseStorage: IExpenseStorage

  constructor(
    commonStorage: ICommonStorage,
    api: Api,
    expenseStorage: IExpenseStorage
  ) {
    super(commonStorage, api)
    this.expenseStorage = expenseStorage
  }

  public getExpenseReportings = async (
    filter?: ExpenseReportingFilter
  ): Promise<ExpenseSummary[]> => {
    try {
      const queryParams = {
        outletId: filter.selectedOutletId || '',
        fromDate: filter.dateRange ? toUTCISOString(filter.dateRange.from) : '',
        toDate: filter.dateRange ? toUTCISOString(filter.dateRange.to) : '',
        categoryName: filter.categoryName || '',
      }
      const expenses = await this.api.management.expense.getExpenseReports(
        queryParams
      )
      this.expenseStorage.setExpenses(
        toDict(expenses, (p: ExpenseSummary) => p.category.id)
      )
      expenseService.showExpenseDetails(false)
      return expenses
    } catch (error) {
      console.error(error)
    }
    return []
  }
}

export interface IExpenseReportingService {
  getExpenseReportings(filter?: ReportingFilter): Promise<ExpenseSummary[]>
}
