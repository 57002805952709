import { BaseApi } from 'api/base.api'
import { Discount } from 'domains/management/discounts/models'
import {
  IDiscountApi,
  DiscountQueryParams,
} from 'domains/management/discounts/contracts/api'

export default class DiscountApi extends BaseApi implements IDiscountApi {
  constructor() {
    super('/v3/discounts')
  }

  public async getDiscounts(
    queryParams?: DiscountQueryParams
  ): Promise<Discount[]> {
    return await this.makeForCamelCaseForList('GET', '', queryParams)
  }

  public async createDiscount(query: Discount): Promise<Discount> {
    return await this.makeForCamelCase('POST', '', query)
  }

  public async updateDiscount(query: Discount, id: string): Promise<Discount> {
    return await this.makeForCamelCase('POST', `/${id}`, query)
  }

  public async deleteDiscount(id: string): Promise<void> {
    return await this.make('DELETE', id)
  }
}
