import { BlobProvider } from '@react-pdf/renderer'
import * as React from 'react'

import { Subscription, SubscriptionInvoice } from 'domains/subscriptions/model'
import { commonService, subscriptionService } from 'injectors'
import { Icon } from 'views/components'
import Loader from 'views/components/Loader'

import InvoiceDocument from './InvoiceDocument'

interface InvoicePdfProps {
  subscription: Subscription
}

const InvoicePdf: React.FC<InvoicePdfProps> = props => {
  const { useState, useEffect } = React
  const [ready, setReady] = useState(false)
  const [invoiceData, setInvoiceData] = useState<SubscriptionInvoice>()
  const [fileUrl, setFileUrl] = useState('')

  useEffect(() => {
    if (fileUrl) {
      const a = document.createElement('A') as HTMLAnchorElement
      a.href = fileUrl
      a.download = `SUB-${invoiceData.invoiceNo}.pdf`
      document.body.append(a)
      if (ready) {
        a.click()
        a.remove()
      }
      setReady(false)
      setFileUrl('')
    }
  }, [fileUrl])

  const getSubscriptionData = async () => {
    try {
      setReady(true)
      const invoiceResponse = await subscriptionService.getSubscriptionInvoice(
        props.subscription.id
      )
      setInvoiceData(invoiceResponse)
    } catch (error) {
      commonService.setAlert({
        type: 'error',
        message: error.message,
      })
    }
  }

  const loader = <Loader position='relative' size={24} />

  if (!ready) {
    return <Icon.Download onClick={getSubscriptionData} />
  } else {
    return !invoiceData ? (
      loader
    ) : (
      <BlobProvider document={<InvoiceDocument invoiceData={invoiceData} />}>
        {({ url, loading, error }) => {
          if (loading || !invoiceData) {
            return loader
          }
          if (!loading && url && !error) {
            setFileUrl(url)
            return null
          }
          if (error) {
            console.error(error)
            return <p>An error occurred</p>
          }
          return null
        }}
      </BlobProvider>
    )
  }
}

export default InvoicePdf
