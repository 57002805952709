import { Reducer, StateActions } from 'utilities/redux/reducer'
import { LayoutState } from 'domains/layouts/states'
import { SET_SUB_MENU, SET_MAIN_MENU, SET_SUB_MENU_V2 } from './actions'
import { RouteDescription } from 'domains/layouts/services/route.item'
import { RouteItemV2 } from 'route'

export class LayoutReducer extends Reducer<LayoutState> {
  constructor() {
    super({
      sidebarState: {
        currentRoute: null,
        currentRouteV2: null,
      },
    })
  }

  public setSubmenuView(
    state: LayoutState,
    currentRoute: RouteDescription
  ): LayoutState {
    return {
      ...state,
      sidebarState: {
        currentRoute,
      },
    }
  }

  public setSubmenuViewV2(
    state: LayoutState,
    currentRouteV2: RouteItemV2
  ): LayoutState {
    return {
      ...state,
      sidebarState: {
        currentRouteV2,
      },
    }
  }

  public setMainMenuView(state: LayoutState): LayoutState {
    return {
      ...state,
      sidebarState: {
        currentRoute: null,
      },
    }
  }

  get actions(): StateActions<LayoutState> {
    return {
      [SET_SUB_MENU]: this.setSubmenuView,
      [SET_SUB_MENU_V2]: this.setSubmenuViewV2,
      [SET_MAIN_MENU]: this.setMainMenuView,
    }
  }
}
