import { i18n } from '@lingui/core'
import { t } from '@lingui/macro'

import { APP_SETTINGS } from 'constants/appSettings'
import { SchemaValidation } from 'utilities/validationCheck'

export interface RegisterSuccessFormRequest {
  businessName: string
  businessCategoryId: string
}

export const RegisterSuccessFormSchema = SchemaValidation.object().shape({
  businessName: SchemaValidation.string()
    .min(
      APP_SETTINGS.MIN_BUSINESS_NAME_LENGTH,
      i18n._(
        t`Nama Bisnis tidak boleh kurang dari ${APP_SETTINGS.MIN_BUSINESS_NAME_LENGTH} karakter`
      )
    )
    .banlist(['<', '>'], i18n._(t`Ditemukan beberapa karakter terlarang`))
    .required(i18n._(t`Nama Bisnis tidak boleh kosong`)),
  businessCategoryId: SchemaValidation.string().required(
    'Kategori Bisnis tidak boleh kosong'
  ),
  email: SchemaValidation.string().email(i18n._(t`Format email tidak valid`)),
})
