import {
  RegisterTokenRequest,
  RegisterOTPRequest,
  ExtendedRegisterTokenRequest,
  checkAccountRequestV2,
} from '../models'
import { setLocalStorage } from 'utilities/localStorage'
import { CONFIG_LOCALSTORAGE } from 'constants/localStorage'
import { trimDuplicateSpace } from 'utilities/strings'
import { ICommonStorage } from 'domains/commons/contracts/storage'
import { BaseService } from 'domains/commons/services'
import { Api } from 'domains/contracts/api'

const satinizeRegisterTokenRequest = (
  request: RegisterTokenRequest
): RegisterTokenRequest => {
  return {
    ...request,
    businessName: trimDuplicateSpace(request.businessName),
  }
}

export interface IRegisterService {
  registerToken(quest: RegisterTokenRequest): Promise<void>
  registerOTP(request: RegisterOTPRequest): Promise<void>
  resendOTP(registerTokenId: string): Promise<void>
  registerAccount(request: ExtendedRegisterTokenRequest): Promise<void>
  checkAccount(request: ExtendedRegisterTokenRequest): Promise<void>
  checkAccountV2(request: checkAccountRequestV2): Promise<void>
}

export class RegisterService extends BaseService implements IRegisterService {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(commonStorage: ICommonStorage, api: Api) {
    super(commonStorage, api)
  }

  public async registerToken(request: RegisterTokenRequest): Promise<void> {
    try {
      request = satinizeRegisterTokenRequest(request)
      // const data = await this.api.auth.registerToken(request);
    } catch (error) {
      throw error
    }
  }

  public async registerOTP(request: RegisterOTPRequest): Promise<void> {
    try {
      const data = await this.api.auth.register(request)
      setLocalStorage(CONFIG_LOCALSTORAGE.POST_TOKEN, data.accessToken)
      setLocalStorage(CONFIG_LOCALSTORAGE.POST_TYPE, data.accessType)
    } catch (error) {
      throw error
    }
  }

  public async registerAccount(
    request: ExtendedRegisterTokenRequest
  ): Promise<void> {
    try {
      const data = await this.api.newAuth.register(request)
      setLocalStorage(CONFIG_LOCALSTORAGE.POST_TOKEN, data.accessToken)
      setLocalStorage(CONFIG_LOCALSTORAGE.POST_TYPE, data.accessType)
    } catch (error) {
      throw error
    }
  }

  public async checkAccount(
    request: ExtendedRegisterTokenRequest
  ): Promise<void> {
    try {
      await this.api.newAuth.checkRegister(request)
    } catch (error) {
      throw error
    }
  }

  public async resendOTP(registerTokenId: string): Promise<void> {
    try {
      this.setLoading('resent-otp', true)
      await this.api.auth.resendOTP({ registerTokenId })
      this.setLoading('resent-otp', false)
    } catch (error) {
      throw error
    }
  }

  public async checkAccountV2(request: checkAccountRequestV2): Promise<void> {
    try {
      await this.api.newAuth.checkRegisterV2(request)
    } catch (error) {
      throw error
    }
  }
}
