import { BaseService } from 'domains/commons/services'
import { AppsflyerRequest } from '../models'

export class AppsflyerService extends BaseService implements IAppsflyerService {
  public trackEvent = async (request: AppsflyerRequest) => {
    try {
      await this.api.appsflyer.trackEvent(request)
    } catch (error) {
      throw error
    }
  }
}

export interface IAppsflyerService {
  trackEvent: (request: AppsflyerRequest) => Promise<void>
}
