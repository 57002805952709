import React, { useContext } from 'react'
import { Table } from 'views/components'
import { generateTableConfig } from './tableConfig'
import Context from './../context'

export const ListingsStockTable: React.FC<{}> = props => {
  const { stockHistories } = useContext(Context)
  return (
    <Table
      list={stockHistories}
      type='striped'
      size='default'
      tableConfig={generateTableConfig()}
    />
  )
}
