import { getLocalStorage } from 'utilities/localStorage'
import { CONFIG_LOCALSTORAGE } from 'constants/localStorage'
import ROUTE from 'constants/routes'

export async function Guest(): Promise<boolean | string> {
  if (!getLocalStorage(CONFIG_LOCALSTORAGE.POST_TOKEN)) {
    return true
  }
  return ROUTE.MAINPAGE
}
