import { BaseStorage } from 'storages/base'
import { IOutletStorage } from 'domains/management/outlets/contracts/storage'
import { Dict } from 'utilities/type'
import { OutletModel } from 'domains/management/outlets/models'
import { SET_MUlTI_OUTLET } from './actions'

export class OutletStorage extends BaseStorage implements IOutletStorage {
  public setOutlets(outletDict: Dict<OutletModel>): void {
    this.dispatch(SET_MUlTI_OUTLET, outletDict)
  }
}
