import CategoryForm from 'views/modules/Management/Category/Form'
import ROUTE from 'constants/routes'
import { registerModalConfig } from 'views/components/Modal/config'
import CustomerForm from 'views/modules/Management/Customer/Form'
import DeviceFormModal from 'views/modules/Management/Device/DeviceFormModal'
import { AddDiscount } from 'views/modules/Management/Discount/AddDiscountModal'
import OutletForm from 'views/modules/Management/MultiOutlet/Form'
import MassImportForm from 'views/modules/Management/Product/MassImportForm'
import ProductFormModal from 'views/modules/Management/Product/ProductFormModal'
import AccountSetting from 'views/modules/Settings/AccountSettings'
import PasswordSetting from 'views/modules/Settings/Password'
import ResetDataSetting from 'views/modules/Settings/ResetData'

export function generateModalRoutes() {
  registerModalConfig(CategoryForm, {
    path: ROUTE.MANAGEMENT_PRODUCT_CATEGORY_FORM,
    backPath: ROUTE.MANAGEMENT_PRODUCT_CATEGORY,
  })

  registerModalConfig(CustomerForm, {
    path: ROUTE.MANAGEMENT_CUSTOMER_FORM,
    backPath: ROUTE.MANAGEMENT_CUSTOMER,
    defaultParams: {},
  })

  registerModalConfig(DeviceFormModal, {
    backPath: ROUTE.MANAGEMENT_OUTLET_DEVICE,
    path: ROUTE.MANAGEMENT_OUTLET_DEVICE_FORM,
  })

  registerModalConfig(AddDiscount, {
    backPath: ROUTE.MANAGEMENT_PAYMENT_DISCOUNT,
    path: ROUTE.MANAGEMENT_PAYMENT_DISCOUNT_FORM,
  })

  registerModalConfig(OutletForm, {
    backPath: ROUTE.MANAGEMENT_OUTLET_MULTIOUTLET,
    path: ROUTE.MANAGEMENT_OUTLET_MULTIOUTLET_FORM,
  })

  registerModalConfig(MassImportForm, {
    backPath: ROUTE.MANAGEMENT_PRODUCT_LIST,
    path: ROUTE.MANAGEMENT_MASS_IMPORT,
  })

  registerModalConfig(ProductFormModal, {
    path: ROUTE.MANAGEMENT_PRODUCT_FORM,
    backPath: ROUTE.MANAGEMENT_PRODUCT_LIST,
  })

  registerModalConfig(AccountSetting, {
    backPath: ROUTE.ACCOUNT_SETTINGS,
    path: ROUTE.ACCOUNT_BUSINESS_SETTINGS,
  })

  registerModalConfig(PasswordSetting, {
    backPath: ROUTE.ACCOUNT_SETTINGS,
    path: ROUTE.PASSWORD_SETTINGS,
  })

  registerModalConfig(ResetDataSetting, {
    backPath: ROUTE.ACCOUNT_SETTINGS,
    path: ROUTE.RESET_DATA_SETTINGS,
  })
}
