import { CONFIG_LOCALSTORAGE } from 'constants/localStorage'
import { getLocalStorage, setLocalStorage } from 'utilities/localStorage'
import { LoginRequest, LoginResponse } from '../models'
import { BaseService } from 'domains/commons/services'
export class AuthService extends BaseService implements IAuthService {
  public loginUser = async (request: LoginRequest): Promise<LoginResponse> => {
    try {
      try {
        const data = await this.api.auth.login(request)
        if (getLocalStorage(CONFIG_LOCALSTORAGE.STAFF_ID)) {
          localStorage.removeItem(CONFIG_LOCALSTORAGE.STAFF_ID)
        }
        setLocalStorage(CONFIG_LOCALSTORAGE.POST_TOKEN, data.accessToken)
        setLocalStorage(CONFIG_LOCALSTORAGE.POST_TYPE, data.accessType)
        return data
      } catch (e) {
        const staffRequest = {
          ...request,
          email: request.username,
        }
        const data = await this.api.management.employee.loginStaff(staffRequest)
        setLocalStorage(CONFIG_LOCALSTORAGE.STAFF_ID, data.employeeId)
        setLocalStorage(
          CONFIG_LOCALSTORAGE.STAFF_BUSINESS_CATEGORY_ID,
          data.businessCategoryId
        )
        setLocalStorage(CONFIG_LOCALSTORAGE.POST_TOKEN, data.accessToken)
        setLocalStorage(CONFIG_LOCALSTORAGE.POST_TYPE, data.accessType)
        setLocalStorage(
          CONFIG_LOCALSTORAGE.STAFF_PERMISSION_LIST,
          data.permissionList?.toString() || ''
        )
        return data
      }
    } catch (error) {
      throw error
    }
  }

  public logOutOwner = async (authHeader: {}) => {
    try {
      await this.api.auth.logOut(authHeader)
    } catch (error) {
      throw error
    }
  }
}

export interface IAuthService {
  loginUser: (request: LoginRequest) => Promise<LoginResponse>
  logOutOwner: (authHeader: {}) => Promise<void>
}
