import { BaseApi } from 'api/base.api'
import { PaylaterInfo } from 'domains/paylater/models'

export default class PaylaterApi extends BaseApi {
  constructor() {
    super('/paylater/v1/')
  }

  public async getPaylater(id: string): Promise<PaylaterInfo[]> {
    return await this.makeForCamelCaseForList('GET', `activation/${id}`)
  }
}
