import { REGEX } from 'constants/regex'
import { SchemaValidation } from 'utilities/validationCheck'

export const outletFormSchema = SchemaValidation.object().shape({
  name: SchemaValidation.string().required('Nama outlet tidak boleh kosong'),
  phone: SchemaValidation.string()
    .matches(REGEX.PHONE_EXTENDED, 'Format nomor telepon salah')
    .max(15, 'nomor telepon tidak boleh lebih dari 15 digit')
    .required('nomor telepon tidak boleh kosong'),
  address: SchemaValidation.string(),
  whatsappNo: SchemaValidation.string()
    .matches(REGEX.PHONE, 'Format No Whatsapp salah')
    .max(15, 'No Whatsapp tidak boleh lebih dari 15 digit'),
})
