import { toDict } from 'utilities/converter/list'
import { BaseService } from 'domains/commons/services'
import { ICategoryStorage } from '../contracts/storage'
import { ICommonStorage } from 'domains/commons/contracts/storage'
import { Api } from 'domains/contracts/api'
import {
  GettingCategoriesRequest,
  Category,
  CreateCategoryRequest,
  CategoryRequestBody,
} from '../models'

export class CategoryService extends BaseService implements ICategoryService {
  categoryStorage: ICategoryStorage

  constructor(
    commonStorage: ICommonStorage,
    api: Api,
    categoryStorage: ICategoryStorage
  ) {
    super(commonStorage, api)
    this.categoryStorage = categoryStorage
  }

  public getCategories = async (
    queryParams: GettingCategoriesRequest = {}
  ): Promise<Category[]> => {
    const data = await this.api.management.category.getCategories(queryParams)
    const filterCategoryStates = (categories: any) =>
      categories.map(
        (res: any) =>
          ({
            id: res.id,
            name: res.name,
            active: res.active,
            isDefault: res.isDefault,
          } as Category)
      )
    this.categoryStorage.setCategories(
      toDict(filterCategoryStates(data), (p: Category) => p.id)
    )
    const lastCategoryId = this.getLastCategoryId(data)
    this.categoryStorage.setGettingCategoriesRequest({
      ...queryParams,
      startingAfter: lastCategoryId || queryParams.startingAfter,
    } as GettingCategoriesRequest)
    this.categoryStorage.setCategoriesReachBottom(data.length == 0)

    return data
  }

  private getLastCategoryId = (categories: Category[]): string | undefined => {
    return categories.length > 0
      ? categories[categories.length - 1].id
      : undefined
  }

  public loadMore = async (): Promise<Category[]> => {
    const categoryListManager =
      this.getState().management.category.categoryListManager
    if (categoryListManager.hasReachedBottom) {
      return Promise.resolve([])
    }
    if (categoryListManager.isLoadingMoreCategory) {
      return Promise.resolve([])
    }
    this.categoryStorage.setLoadingMoreCategory(true)
    const queryParams = {
      ...categoryListManager.request,
      limit: 10,
    }
    const categories = await this.api.management.category.getCategories(
      queryParams
    )
    this.categoryStorage.pushCategories(
      toDict(categories, (p: Category) => p.id)
    )
    const lastCategoryId = this.getLastCategoryId(categories)
    this.categoryStorage.setLoadingMoreCategory(false)
    this.categoryStorage.setGettingCategoriesRequest({
      ...queryParams,
      startingAfter: lastCategoryId || queryParams.startingAfter,
    } as GettingCategoriesRequest)
    this.categoryStorage.setLoadingMoreCategory(categories.length === 0)
    this.categoryStorage.setCategoriesReachBottom(categories.length === 0)

    return categories
  }

  public createSingleCategory = async (request: CreateCategoryRequest) => {
    try {
      const categoryResponse =
        await this.api.management.category.createSingleCategory(request)
      const { management } = this.getState()
      const { category } = management
      const singleCategory = { [categoryResponse.id]: categoryResponse }
      const newCategories = { ...singleCategory, ...category.categories }
      this.categoryStorage.setCategories(newCategories)
      return categoryResponse
    } catch (error) {
      throw error
    }
  }

  public updateSingleCategory = async (
    request: CategoryRequestBody,
    id: string
  ) => {
    try {
      const categoryResponse =
        await this.api.management.category.updateSingleCategory(request, id)
      const { management } = this.getState()
      const { category } = management
      const newCategories = category.categories
      newCategories[categoryResponse.id] = categoryResponse
      this.categoryStorage.setCategories(newCategories)
    } catch (error) {
      throw error
    }
  }

  public deleteCategory = async (id = '') => {
    try {
      const queryParams = id && `/${id}`
      await this.api.management.category.deleteSingleCategory(queryParams)
      const { management } = this.getState()
      const { category } = management
      const newCategories = category.categories
      delete newCategories[id]
      this.categoryStorage.setCategories(newCategories)
    } catch (error) {
      throw error
    }
  }
}

export interface ICategoryService {
  getCategories: (queryParams?: GettingCategoriesRequest) => Promise<Category[]>
  createSingleCategory: (request: CreateCategoryRequest) => Promise<Category>
  updateSingleCategory: (request: CategoryRequestBody, id: string) => void
  deleteCategory: (id: string) => void
  loadMore: () => Promise<Category[]>
}
