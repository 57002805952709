import React, { useState } from 'react'
import DailyCalendar from './DailyCalendar'
import WeeklyCalendar from './WeeklyCalendar'
import MonthlyCalendar from './MonthlyCalendar'
import { Icon, Panel, Wrapper } from '../../components'

import './style.scss'
import { DateRange } from 'domains/commons/models'
import moment from 'moment'
import Button from '../Button'
import Card from '../Card'
import { Dropdown, Input } from 'antd'
import Sided from '../Sided'
import { toDateString } from 'utilities/converter/date'

interface Props {
  onChange: (selectedDateRange: DateRange) => any
  selectedDateRange: DateRange
  dateVisibilityFunc?: (currentDay: Date, firstDateSelection?: Date) => boolean
  mode: 'complex' | 'daily'
  disabled?: boolean
  selectType?: 'range' | 'single'
  value?: string
  customButtonLabel?: string
  style?: React.CSSProperties
}

const PostCalendar = (props: Props) => {
  const [visible, setVisible] = useState(false)
  const [calendarType, setCalendarType] = useState('daily')
  const [selectedDateRange, setSelectedDateRange] = useState(
    props.selectedDateRange
  )
  const [displayDateValue, setDisplayDateValue] = useState('')
  const confirmSelectedDate = (from?: Date, to?: Date) => {
    if (!selectedDateRange) {
      return
    }

    if (from) {
      if (from.getTime() < to.getTime()) {
        props.onChange({
          from: moment(from).startOf('day').toDate(),
          to: moment(to).endOf('day').toDate(),
        })
      } else {
        props.onChange({
          from: moment(to).startOf('day').toDate(),
          to: moment(from).endOf('day').toDate(),
        })
      }
    } else {
      props.onChange({
        from: selectedDateRange.from,
        to: selectedDateRange.to,
      })
    }
    setVisible(false)
  }
  const adjustDateRange = (dateRange: DateRange) => {
    let selectedDate: DateRange = {} as DateRange
    selectedDate = {
      from: moment(dateRange.from).startOf('day').toDate(),
      to: moment(dateRange.to).endOf('day').toDate(),
    }
    setSelectedDateRange(selectedDate)
    const { from, to } = selectedDate
    let placeholder = ''

    if (toDateString(from, 'DD/MM/YYYY') === toDateString(to, 'DD/MM/YYYY')) {
      placeholder = `${toDateString(from, 'DD/MM/YYYY')}`
    } else {
      placeholder = `${
        toDateString(from, 'DD/MM/YYYY') +
        ' - ' +
        toDateString(to, 'DD/MM/YYYY')
      }`
    }
    setDisplayDateValue(placeholder)
  }

  if (selectedDateRange && !displayDateValue) {
    adjustDateRange(selectedDateRange)
  }

  const handleVisibleChange = () => {
    !props.disabled && setVisible(prevState => !prevState)
  }

  const handleClickDaily = () => {
    setCalendarType('daily')
  }

  const handleClickWeekly = () => {
    setCalendarType('weekly')
  }

  const handleClickMonthly = () => {
    setCalendarType('monthly')
  }

  const renderCalendar = () => {
    if (calendarType === 'daily') {
      return (
        <DailyCalendar
          isActive={visible}
          dateVisibilityFunc={props.dateVisibilityFunc}
          selectedDateRange={selectedDateRange}
          onChange={(dateRange: DateRange) => adjustDateRange(dateRange)}
          onOptimisticConfirm={(from, to) => confirmSelectedDate(from, to)}
        />
      )
    } else if (calendarType === 'weekly') {
      return (
        <WeeklyCalendar
          selectedDateRange={selectedDateRange}
          onChange={(dateRange: DateRange) => adjustDateRange(dateRange)}
        />
      )
    } else {
      return (
        <MonthlyCalendar
          selectedDateRange={selectedDateRange}
          onChange={(dateRange: DateRange) => adjustDateRange(dateRange)}
        />
      )
    }
  }

  const confirmSelection = () => (
    <Wrapper>
      <Button
        theme='secondary'
        fullW={true}
        onClick={() => confirmSelectedDate()}
        label={props.customButtonLabel || 'Tampilkan'}
      />
    </Wrapper>
  )

  const renderDateSelector = () => (
    <Sided.Sided className='mode-selector'>
      <Sided.Remaining>
        <Button
          theme='primary'
          fullW={true}
          onClick={handleClickDaily}
          label='Harian'
        ></Button>
      </Sided.Remaining>
      <Sided.Remaining>
        <Button
          theme='primary'
          fullW={true}
          onClick={handleClickWeekly}
          label='Mingguan'
        ></Button>
      </Sided.Remaining>
      <Sided.Remaining>
        <Button
          theme='primary'
          fullW={true}
          onClick={handleClickMonthly}
          label='Bulanan'
        ></Button>
      </Sided.Remaining>
    </Sided.Sided>
  )

  const menu = (
    <Card type='shadow' className='calendar-inner'>
      <Panel.Panel type='rounded' className='panel'>
        {props.mode === 'complex' ? (
          <Panel.Header>{renderDateSelector()}</Panel.Header>
        ) : null}

        {renderCalendar()}
        <Panel.Footer>{confirmSelection()}</Panel.Footer>
      </Panel.Panel>
    </Card>
  )

  return (
    <Wrapper className='post-calendar' style={props.style}>
      <Dropdown
        overlay={menu}
        visible={visible}
        onVisibleChange={handleVisibleChange}
      >
        <Wrapper>
          <Wrapper className='onclick-layer' onClick={handleVisibleChange} />
          <Input
            prefix={<Icon.Calendar color='black' />}
            suffix={
              <Icon.Caretdown color='black' onClick={handleVisibleChange} />
            }
            value={
              props.value
                ? moment(props.value).format('DD/MM/YYYY')
                : displayDateValue
            }
            disabled={props.disabled}
          />
        </Wrapper>
      </Dropdown>
    </Wrapper>
  )
}

export default PostCalendar
