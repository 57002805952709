export function highlight(
  str: string,
  keyword: string,
  effect: (x: string) => string
): string {
  if (!str) {
    return str
  }
  return str.replace(new RegExp(keyword, 'g'), effect(keyword))
}

export function removeHighlight(str: string) {
  let newStr = str.replace(new RegExp('<b>', 'g'), '')
  return (newStr = newStr.replace(new RegExp('</b>', 'g'), ''))
}
