import { BaseApi } from 'api/base.api'
import { IPaymentMethodV3Api } from 'domains/management/paymentMethod/contracts/v3.api'
import {
  RegisterQRISPaymentPayload,
  QRISPaymentMethod,
  QRISBankAccountUpdateModel,
} from 'domains/management/paymentMethod/models'
import { BankOTPModel } from 'domains/bank/models'

export default class PaymentMethodV3Api
  extends BaseApi
  implements IPaymentMethodV3Api
{
  constructor() {
    super('/payment/v3')
  }

  public async registerQris(
    queryParams: RegisterQRISPaymentPayload
  ): Promise<void> {
    await this.makeForCamelCaseForList('POST', '/register-qris', queryParams)
  }

  public async getQRISPaymentMethod(
    outletId: string
  ): Promise<QRISPaymentMethod> {
    return await this.makeForCamelCase('GET', `/qris-payment-method`, {
      outletId,
    })
  }

  public async updateQRISPaymentMethod(
    queryParams: QRISPaymentMethod
  ): Promise<void> {
    return await this.makeForCamelCase(
      'POST',
      `/qris-payment-method`,
      queryParams
    )
  }

  public async createUpdateQRISBankAccountToken(
    outletId: string
  ): Promise<BankOTPModel> {
    return await this.makeForCamelCase(
      'POST',
      `/qris-bank-accounts/${outletId}/update-bank-account-token`
    )
  }

  public async updateQRISBankAccount(
    outletId: string,
    queryParams: QRISBankAccountUpdateModel
  ): Promise<void> {
    await this.makeForCamelCase(
      'POST',
      `/qris-bank-accounts/${outletId}/update-bank-account`,
      queryParams
    )
  }

  public async checkUpdateEligible(outletId: string): Promise<void> {
    await this.makeForCamelCase(
      'GET',
      `/qris-bank-accounts/${outletId}/check-update-eligible`
    )
  }
}
