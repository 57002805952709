import { DateRange } from 'domains/commons/models'

const getCalendarDates = (year: number, month: number): Date[][] => {
  const arr = []

  const d = new Date(year, month - 1, 1)
  const offset = d.getDay() - 1

  for (var i = 1; i <= 42; i++) {
    const d = new Date(year, month - 1, i)
    arr[offset + i] = d
  }
  for (var i = -offset; i <= offset; i++) {
    const d = new Date(year, month - 1, i)
    arr[offset + i] = d
  }

  arr.slice(0, 42)

  const twoDimensionalArray = []
  for (i = 0; i < 5; i++) {
    const clonedArray = [...arr].splice(i * 7, 7)
    twoDimensionalArray[i] = clonedArray
  }
  return twoDimensionalArray
}

const getWeekRanges = (year: number, month: number): DateRange[] => {
  const x = getCalendarDates(year, month)
  return x.map(weekList => ({
    from: weekList[0],
    to: weekList[6],
  }))
}

const getYearRange = (year: number): DateRange => {
  const from = new Date(year, 0, 1)
  const to = new Date(year + 1, 0, 0)
  return {
    from,
    to,
  }
}

export { getCalendarDates, getWeekRanges as getWeekRangeList, getYearRange }
