import React, { useState } from 'react'

import { REGEX } from 'constants/regex'
import { BaseProps } from 'views/components/props'
import { Icon } from 'views/components'

import Form, { RenderingFieldContext } from '../../Form'
import InputBase from '../base'

interface Props extends BaseProps {
  label?: string
  placeholder?: string
  name?: string
  maxLength?: number
  isDisabled?: boolean
  errorMessage?: string
  prefix?: React.ReactNode
  suffix?: React.ReactNode
  onChange?: (value: string) => any
  autoComplete?: boolean
  isFreezed?: boolean
  alphabetOnly?: boolean
  uppercase?: boolean
  secondaryLabel?: string
  tooltipMessage?: string
  noBorder?: boolean
  value?: string
  numberOnly?: boolean
  invisibleValue?: boolean
  required?: boolean
  autoFocus?: boolean
  clearable?: boolean
  onKeyPress?: (event_: React.KeyboardEvent) => void
  noMargin?: boolean
}

const InputText = (props: Props) => {
  const [inputValue, setInputValue] = useState<string>(props.value || '')

  const handleOnChange = (onChangeForm: any, value: string) => {
    const currentValue = value
    setInputValue(currentValue)
    if (currentValue && props.alphabetOnly) {
      const regex = new RegExp(REGEX.ALPHABET_ONLY)
      if (!regex.test(currentValue)) {
        return
      }
    }
    if (props.onChange && !props.invisibleValue) {
      props.onChange(currentValue)
    }
    onChangeForm(currentValue)
  }

  const handleKeyPress = (event_: React.KeyboardEvent) => {
    if (props.numberOnly) {
      if (!/^[0-9]{0,}$/.test(event_.key)) {
        event_.preventDefault()
      }
    }
    props.onKeyPress && props.onKeyPress(event_)
  }

  const clearText = (onChangeForm: any) => {
    setInputValue('')
    if (props.onChange && !props.invisibleValue) {
      props.onChange('')
    }
    onChangeForm('')
  }

  return (
    <Form.Field
      name={props.name}
      render={({
        field,
        value,
        onChange,
        onBlur,
      }: RenderingFieldContext<any>) => (
        <InputBase
          isDisabled={props.isDisabled}
          isFreezed={props.isFreezed}
          label={props.label}
          error={field.isDirty && (field.error || props.errorMessage)}
          suffix={
            props.clearable && inputValue ? (
              <Icon.Close
                color='gray'
                size={20}
                onClick={() => clearText(onChange)}
              />
            ) : (
              props.suffix
            )
          }
          prefix={props.prefix}
          secondaryLabel={props.secondaryLabel}
          tooltipMessage={props.tooltipMessage}
          noBorder={props.noBorder}
          required={props.required}
          noMargin={props.noMargin}
        >
          <input
            autoComplete={!props.autoComplete ? 'off' : 'on'}
            name={props.name}
            type='text'
            style={
              props.uppercase === true ? { textTransform: 'uppercase' } : {}
            }
            disabled={props.isDisabled || props.isFreezed}
            placeholder={props.placeholder}
            maxLength={props.maxLength}
            value={inputValue ? inputValue : props.value || value}
            onBlur={onBlur}
            onChange={event_ => handleOnChange(onChange, event_.target.value)}
            onKeyPress={handleKeyPress}
            data-testid='field-text'
            autoFocus={props.autoFocus}
          />
        </InputBase>
      )}
    />
  )
}

export default InputText
