import React, { useState, useRef, useEffect } from 'react'
import { Text, Flex, Button, Icon } from 'views/components'
import './style.scss'
import { resizeImage, getImageDimension } from 'utilities/image/resizeImage'
import {
  ExtractedFileData,
  extractFile,
  triggerFileUpload,
} from 'utilities/file/upload'
import { t, Trans } from '@lingui/macro'
import { useLingui } from '@lingui/react'

interface Props {
  initValue: string
  onChange: (values: string) => any
}

const UploadImageV2 = (props: Props) => {
  const { onChange, initValue } = props
  const { i18n } = useLingui()
  const [imageUrl, setImageUrl] = useState('')

  const renderUploadPhotoButton = () => (
    <div
      style={{
        position: 'absolute',
        left: '40%',
        top: '40%',
      }}
    >
      <Icon.Add />
    </div>
  )

  const handleUploadImage = async () => {
    const imageInfo = await triggerFileUpload(undefined, {
      acceptedFile: 'image/png, image/jpg, image/jpeg',
    })
    setToBeUploadedFile(imageInfo)
  }

  const setToBeUploadedFile = async (imageInfo: ExtractedFileData) => {
    const imageProperty = await getImageDimension(imageInfo.base64)
    let resizedImage
    if (imageProperty.width > 360) {
      const heightRatio = imageProperty.height / imageProperty.width
      resizedImage = await resizeImage(
        imageInfo.base64,
        360,
        Math.round(360 * heightRatio)
      )
    } else {
      resizedImage = await resizeImage(
        imageInfo.base64,
        imageProperty.width,
        imageProperty.height
      )
    }
    setImageUrl(resizedImage)
    onChange(resizedImage)
  }

  const handleFileDrop = async (e: DragEvent) => {
    const files = e.dataTransfer.files

    if (!files || files.length != 1) {
      return
    }
    const imageInfo = await extractFile(files[0])
    setToBeUploadedFile(imageInfo)
  }

  const dropAreaRef = useRef<HTMLDivElement>()
  useEffect(() => {
    if (!dropAreaRef || !dropAreaRef.current) {
      return
    }
    dropAreaRef.current.addEventListener('drop', handleFileDrop, false)
  }, [])

  const deleteImage = () => {
    setImageUrl(null)
    onChange('image_removed')
  }

  return (
    <div className='product-photo-container' ref={dropAreaRef}>
      <Flex.Row
        className='product-photo-row'
        flexDirection='row'
        style={{ marginTop: '8px' }}
      >
        <Flex.Column flex='2'>
          <div
            style={{
              width: '120px',
              height: '120px',
              border: 'dashed 2px #e6e6e6',
              position: 'relative',
            }}
          >
            <div>
              {imageUrl ? (
                <img
                  src={imageUrl}
                  alt='Product Image'
                  style={{
                    width: '120px',
                    height: '120px',
                    objectFit: 'cover',
                  }}
                />
              ) : initValue && initValue !== 'image_removed' ? (
                <img
                  src={initValue}
                  alt='Product Image'
                  style={{
                    width: '120px',
                    height: '120px',
                    objectFit: 'cover',
                  }}
                />
              ) : (
                renderUploadPhotoButton()
              )}
              {(imageUrl || (initValue && initValue !== 'image_removed')) && (
                <Button
                  theme='transparent'
                  className='delete-photo'
                  onClick={() => deleteImage()}
                >
                  <Icon.Cross></Icon.Cross>
                </Button>
              )}
            </div>
          </div>
        </Flex.Column>
        <Flex.Column flex='10'>
          <div
            style={{
              paddingLeft: '8px',
              textAlign: 'left',
            }}
          >
            <Trans>
              <Text.Paragraph>
                Unggah foto atau tarik produk kesini
              </Text.Paragraph>
            </Trans>
            <div className='select-photo-button'>
              <Button
                theme='netral'
                label={i18n._(t`Pilih Foto`)}
                size='large'
                onClick={handleUploadImage}
              ></Button>
            </div>
          </div>
        </Flex.Column>
      </Flex.Row>
    </div>
  )
}

export default UploadImageV2
