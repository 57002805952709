export function toUint8Array(characters: string): Uint8Array {
  const byteNumbers = new Array(characters.length)
  for (let i = 0; i < characters.length; i++) {
    byteNumbers[i] = characters.charCodeAt(i)
  }
  return new Uint8Array(byteNumbers)
}

export function convertBase64ToBlob(
  b64Data: string,
  contentType = '',
  sliceSize = 512
): Blob {
  const byteCharacters = atob(b64Data)
  const byteArrays = []
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)
    const byteArray = toUint8Array(slice)
    byteArrays.push(byteArray)
  }
  return new Blob(byteArrays, { type: contentType })
}
