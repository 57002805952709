import React, { useState, useEffect } from 'react'
import ReactModal from 'react-modal'
import { useSelector } from 'react-redux'
import {
  Wrapper,
  Panel,
  Sided,
  VSided,
  Image,
  Icon,
  Text,
  Button,
  Form,
  FormAction,
  Field,
  Break,
  Loader,
  Divider,
  Alert,
} from 'views/components'
import { DropdownItem } from 'views/components/Field/Dropdown'
import {
  userService,
  outletService,
  bankService,
  paymentMethodService,
  commonService,
} from 'injectors'
import {
  BankModel,
  BankAccountCheckModel,
  BankAccountCheckAttributes,
} from 'domains/bank/models'
import { PasswordRequest } from 'domains/users/models'
import crisp from 'utilities/crisp'
import { cdnImageUrl } from 'constants/cdnUrl/imageUrl'
import { REGEX } from 'constants/regex'
import { CONFIG_LOCALSTORAGE } from 'constants/localStorage'
import { toClassifiedNumber } from 'utilities/converter/classifiedNumber'
import { firebaseFunc } from 'utilities/firebase'
import { getLocalStorage } from 'utilities/localStorage'
import {
  PasswordConfimationSchema,
  EmailSchema,
  BankAccountSchema,
} from './validationSchema'
import SuccessModal from './SuccessModal'
import selector from './selector'
import { getScreenType } from 'utilities/screen/getScreentType'

const RegisterQRISModal = () => {
  const { user, outletList, qrisPaymentMethod, qrisModal } =
    useSelector(selector)
  const [outletDropdownList, setOutletDropdownList] = useState<
    DropdownItem<string>[]
  >([])

  const [isLoading, setIsLoading] = useState(false)
  const [step, setStep] = useState('1')
  const [accountNoStatus, setAccountNoStatus] = useState<
    'loading' | 'found' | 'not_found'
  >()
  const [errorMessage, setErrorMessage] = useState('')
  const [selectedOutletId, setSelectedOutletId] = useState('')
  const [successModal, setSuccessModal] = useState(false)

  const [bankAccountAttributes, setBankAccountAttributes] =
    useState<BankAccountCheckAttributes>()
  const [bankList, setBankList] = useState<BankModel[]>([])
  const [selectedBank, setSelectedBank] = useState<BankModel>()
  const [outletApiCalled, setOutletApiCalled] = useState(false)
  const [isQRISRegistered, setIsQRISRegistered] = useState(true)
  const isOwner =
    getLocalStorage(CONFIG_LOCALSTORAGE.POST_TYPE)?.toLowerCase() === 'owner'

  const isValidateBtnDisabled =
    !!errorMessage || accountNoStatus === 'not_found'

  useEffect(() => {
    if (
      (!outletApiCalled && outletDropdownList.length === 0) ||
      (outletDropdownList.length > 0 && outletDropdownList[0]?.label === '')
    ) {
      getOutlets()
    }
  }, [outletApiCalled, user])

  const getOutlets = async () => {
    await outletService.getOutlets()
    setOutletApiCalled(true)
  }

  useEffect(() => {
    if (qrisModal.visible === true) {
      getBanks()
    }
  }, [qrisModal.visible])

  const getBanks = async () => {
    const banks = await bankService.getBanks()
    setBankList(banks)
  }

  useEffect(() => {
    if (
      !qrisModal.selectedOutletId &&
      outletList.length > 0 &&
      qrisPaymentMethod.length === 0
    )
      getQRISRegister()
  }, [])

  useEffect(() => {
    if (outletList.length > 0 && qrisPaymentMethod.length > 0) {
      setOutletDropdownList(
        outletList.map(outlet => ({
          value: outlet.id,
          label: outlet.name,
          isDisabled: qrisPaymentMethod.find(
            item => item.outletId === outlet.id
          )?.isRegistered,
        }))
      )
      checkQRISRegister()
    }
  }, [outletList, qrisPaymentMethod])

  useEffect(() => {
    if (isOwner) {
      commonService.setQrisBannerVisible(!isQRISRegistered)
    }
  }, [isQRISRegistered])

  const getQRISRegister = () => {
    setIsLoading(true)
    let isAllRegistered = true

    outletList.map(async (outlet, idx) => {
      const qris = await paymentMethodService.getQris(outlet.id)
      outlet.isQRISRegistered = qris.isRegistered
      if (qris.isRegistered === false) {
        isAllRegistered = false
      }
      if (idx === outletList.length - 1) {
        setIsQRISRegistered(isAllRegistered)
        setIsLoading(false)
      }
    })
  }

  const checkQRISRegister = () => {
    let isAllRegistered = true

    qrisPaymentMethod.map((qris, idx) => {
      if (qris.isRegistered === false) {
        isAllRegistered = false
      }
      if (idx === qrisPaymentMethod.length - 1) {
        setIsQRISRegistered(isAllRegistered)
        setIsLoading(false)
      }
    })
  }

  const validateBankAccount = async (
    action: FormAction<BankAccountCheckModel>
  ) => {
    setAccountNoStatus('loading')
    try {
      const bankAccount = { ...action.getValues() }
      const data = await bankService.validateBankAccount(bankAccount)
      setBankAccountAttributes(data.data)
      setAccountNoStatus('found')
      setErrorMessage('')
    } catch (error) {
      setAccountNoStatus(error.code)
      if (error.code !== 'not_found') {
        setErrorMessage(error.message)
      }
    }
  }

  const submitForm = async (action: FormAction<BankAccountCheckModel>) => {
    const formData = action.getValues()
    try {
      await paymentMethodService.registerQris({
        userId: user.id,
        outletId: qrisModal.selectedOutletId || selectedOutletId,
        bankId: formData.bankShortCode,
        bankAccountNo: formData.accountNo,
        bankAccountName: bankAccountAttributes.attributes.accountName,
      })
      setSuccessModal(true)
    } catch (error) {
      action.setFieldError('accountNo', error.message)
    }
  }

  const closeModal = async () => {
    await setSelectedOutletId('')
    if (qrisModal.onClose) qrisModal.onClose()
    commonService.setQrisModal({ visible: false })
    setStep('1')
  }

  const bankDropdownItems: DropdownItem[] = bankList.map(bank => ({
    value: bank.id,
    label: bank.name,
    imageUrl: bank.imageUrl,
  }))

  const renderRegisterForm = () => {
    const AccountStatus = () => {
      switch (accountNoStatus) {
        case 'loading':
          return (
            <Wrapper>
              <Sided.Sided>
                <Sided.Fixed>
                  <Loader size={16} />
                </Sided.Fixed>
                <Sided.Remaining>
                  <Text.Span size={12} color='semiGray'>
                    Sedang Memverifikasi Rekening..
                  </Text.Span>
                </Sided.Remaining>
              </Sided.Sided>
            </Wrapper>
          )
        case 'found':
          return (
            <>
              <Wrapper
                backgroundColor='#F2F4F5'
                padding='18px 20px'
                position='absolute'
                style={{
                  width: '100%',
                  left: 0,
                }}
              >
                <Sided.Sided>
                  <Sided.Fixed>
                    <Icon.CheckCircled color='green' />
                  </Sided.Fixed>
                  <Sided.Remaining>
                    <Text.Span size={12} color='black'>
                      Rekening Berhasil Terverifikasi
                    </Text.Span>
                  </Sided.Remaining>
                </Sided.Sided>
                <Break height={8} />
                <Wrapper
                  backgroundColor='white'
                  padding='12px 16px'
                  style={{ borderRadius: '8px' }}
                >
                  <Sided.Sided>
                    <Sided.Fixed>
                      <Image
                        src={selectedBank.imageUrl}
                        width='60px'
                        height='40px'
                      />
                    </Sided.Fixed>
                    <Sided.Remaining>
                      <Text.Paragraph size={14} color='black' noMargin>
                        {bankAccountAttributes.attributes.accountName}
                      </Text.Paragraph>
                      <Text.Paragraph size={12} color='gray' noMargin>
                        {toClassifiedNumber(
                          bankAccountAttributes.attributes.accountNo,
                          Math.round(
                            bankAccountAttributes.attributes.accountNo.length *
                              0.25
                          )
                        )}
                      </Text.Paragraph>
                    </Sided.Remaining>
                  </Sided.Sided>
                </Wrapper>
              </Wrapper>
              <Break height={120} />
            </>
          )
        case 'not_found':
          return (
            <>
              <Wrapper
                backgroundColor='#F2F4F5'
                padding='18px 20px'
                position='absolute'
                style={{
                  width: '100%',
                  left: 0,
                }}
              >
                <Sided.Sided>
                  <Sided.Fixed>
                    <Icon.CautionSolid style={{ marginTop: '2px' }} />
                  </Sided.Fixed>
                  <Sided.Remaining>
                    <Text.Span size={12} color='black'>
                      Rekening Tidak Ditemukan
                    </Text.Span>
                  </Sided.Remaining>
                </Sided.Sided>
              </Wrapper>
              <Break height={40} />
              <Break height={40} />
            </>
          )
        default:
          return null
      }
    }

    const clearError = () => {
      if (accountNoStatus) {
        setAccountNoStatus(null)
      }
      if (errorMessage) {
        setErrorMessage('')
      }
    }

    return (
      <Form.Form
        initialValues={{ bankShortCode: '', accountNo: '' }}
        validationSchema={BankAccountSchema}
        onSubmit={submitForm}
        render={(action: FormAction<BankAccountCheckModel>) => (
          <Wrapper>
            <Field.Dropdown
              name='bankShortCode'
              label='Nama Bank'
              placeholder='Pilih Bank'
              list={bankDropdownItems}
              emptyMessage='Belum ada data bank'
              searchable={{
                enabled: true,
                customNotFound: (
                  <Wrapper padding='32px 30px 28px'>
                    <VSided.VSided gutter={24}>
                      <VSided.Fixed align='center'>
                        <Text.Heading h={6}>
                          Tidak menemukan Bank pilihan Kamu?..
                        </Text.Heading>
                        <Text.Paragraph size={14}>
                          Silahkan hubungi Call Center POST. (021) 5071 1700
                        </Text.Paragraph>
                      </VSided.Fixed>
                      <VSided.Remaining align='center'>
                        <Image
                          src={cdnImageUrl + 'images/confused-person.png'}
                          width='120px'
                          height='112px'
                        />
                      </VSided.Remaining>
                      <VSided.Fixed>
                        <Sided.Sided justifyContent='center'>
                          <Icon.Chat
                            color='blue'
                            onClick={crisp.openLiveChat}
                          />
                          <Text.Span
                            size={14}
                            color='blue'
                            onClick={crisp.openLiveChat}
                          >
                            Hubungi Tim POST.
                          </Text.Span>
                        </Sided.Sided>
                      </VSided.Fixed>
                    </VSided.VSided>
                  </Wrapper>
                ),
              }}
              onChange={value => {
                setSelectedBank(bankList.find(x => x.id === value))
                clearError()
              }}
            />
            <Break height={16} />
            <Field.Text
              name='accountNo'
              label='Nomor Rekening Bank'
              placeholder='Masukkan nomor rekening bank'
              suffix={
                action.getValues().accountNo && (
                  <Wrapper
                    backgroundColor='#F2F4F5'
                    padding='12px'
                    width='111px'
                  >
                    <Text.Span
                      color={
                        isValidateBtnDisabled || accountNoStatus === 'loading'
                          ? 'darkGrayBold'
                          : 'blue'
                      }
                      size={14}
                      onClick={() =>
                        !isValidateBtnDisabled &&
                        accountNoStatus !== 'loading' &&
                        validateBankAccount(action)
                      }
                      style={{
                        cursor:
                          isValidateBtnDisabled || accountNoStatus === 'loading'
                            ? 'not-allowed'
                            : 'pointer',
                      }}
                    >
                      Cek Rekening
                    </Text.Span>
                  </Wrapper>
                )
              }
              noMargin
              onChange={() => {
                clearError()
              }}
              onKeyPress={e => {
                if (!REGEX.NUMBER.test(e.key)) {
                  e.preventDefault()
                }
              }}
            />
            <Text.Span size={12} color='red' style={{ marginLeft: '2px' }}>
              {errorMessage}
            </Text.Span>
            <AccountStatus />
            <Break height={40} />
            <Sided.Sided justifyContent='right'>
              <Button
                type='submit'
                theme='primary'
                label='Aktifkan QRIS'
                size='medium'
                disabled={accountNoStatus !== 'found'}
                style={{ width: '136px' }}
              />
            </Sided.Sided>
          </Wrapper>
        )}
      />
    )
  }

  interface EmailForm {
    email: string
  }

  const renderRightSideContent = () => {
    let title = 'Aktifkan Pembayaran Via QRIS',
      content: JSX.Element

    const checkPassword = async (action: FormAction<PasswordRequest>) => {
      try {
        await userService.checkPassword(action.getValues())
        setStep('email')
      } catch (error) {
        action.setFieldError('password', error.message)
      }
    }

    const addEmail = async (action: FormAction<EmailForm>) => {
      try {
        await userService.changeEmail(action.getValues().email)
        await userService.getUser()
        setStep('2')
        commonService.setAlert({
          type: 'success',
          message: (
            <>
              <Icon.CheckCircled color='white' />
              <Text.Span
                color='white'
                size={14}
                align='center'
                style={{ marginLeft: '8px' }}
              >
                Email berhasil ditambahkan.
              </Text.Span>
            </>
          ),
        })
      } catch (error) {
        action.setFieldError('email', error.message)
      }
    }

    switch (step) {
      case 'password':
        title = 'Tambahkan Email'
        content = (
          <>
            <Wrapper>
              <Text.Heading h={6}>Tambah Email</Text.Heading>
              <Text.Span size={12} color='semiGray'>
                Alamat email kamu dibutuhkan untuk mengaktifkan pembayaran QRIS
              </Text.Span>
            </Wrapper>
            <Break height={16} />
            <Wrapper backgroundColor='#FDF6DC' padding='12px 16px'>
              <Text.Span size={14} color='black'>
                Silahkan masukkan <b>password akun</b> anda terlebih dahulu
              </Text.Span>
            </Wrapper>
            <Break height={16} />
            <Form.Form
              initialValues={{ password: '' }}
              validationSchema={PasswordConfimationSchema}
              onSubmit={checkPassword}
              render={() => (
                <>
                  <Field.Password
                    name='password'
                    label='Password'
                    placeholder='Password Akun Anda'
                  />
                  <Break height={40} />
                  <Sided.Sided justifyContent='right'>
                    <Button
                      type='submit'
                      theme='secondary'
                      label='Lanjut'
                      size='medium'
                      disabled={false}
                      style={{ width: '136px' }}
                    />
                  </Sided.Sided>
                </>
              )}
            />
          </>
        )
        break
      case 'email':
        title = 'Tambahkan Email'
        content = (
          <>
            <Wrapper>
              <Text.Heading h={6}>Tambah Email</Text.Heading>
              <Text.Span size={12} color='semiGray'>
                Alamat email kamu dibutuhkan untuk mengaktifkan pembayaran QRIS
              </Text.Span>
            </Wrapper>
            <Break height={24} />
            <Form.Form
              initialValues={{ email: '' }}
              validationSchema={EmailSchema}
              onSubmit={addEmail}
              render={() => (
                <>
                  <Field.Text
                    name='email'
                    label='Email'
                    placeholder='Contoh: email@mail.com'
                  />
                  <Break height={40} />
                  <Sided.Sided justifyContent='right'>
                    <Button
                      type='submit'
                      theme='secondary'
                      label='Simpan'
                      size='medium'
                      disabled={false}
                      style={{ width: '136px' }}
                    />
                  </Sided.Sided>
                </>
              )}
            />
          </>
        )
        break
      case '2':
        firebaseFunc.logEvent('qris_activate_open')
        content = (
          <>
            <Wrapper>
              <Text.Heading h={6}>Tambah Akun Bank Penerima</Text.Heading>
              <Text.Span size={12} color='semiGray'>
                Akun Bank Kamu dibutuhkan untuk proses pencairan dana tagihan
                pesanan Kamu.
              </Text.Span>
            </Wrapper>
            <Break height={24} />
            {renderRegisterForm()}
          </>
        )
        break
      default:
        content = null
        break
    }

    return (
      <VSided.VSided>
        <Alert />
        <VSided.Remaining style={{ overflowY: 'visible' }}>
          <Panel.Header>
            <Sided.Sided style={{ paddingTop: '12px' }}>
              <Sided.Remaining verticalAlign='center'>
                <Text.Heading h={6}>{title}</Text.Heading>
              </Sided.Remaining>
              <Sided.Fixed verticalAlign='center'>
                <Icon.Cross
                  style={{ display: 'block' }}
                  color='black'
                  size={16}
                  onClick={closeModal}
                />
              </Sided.Fixed>
            </Sided.Sided>
            <Divider margin={16} />
          </Panel.Header>
          <Panel.Content>{content}</Panel.Content>
        </VSided.Remaining>
      </VSided.VSided>
    )
  }

  const handleCloseSuccessModal = async () => {
    const outletId = qrisModal.selectedOutletId || selectedOutletId
    const qris = await paymentMethodService.getQris(outletId)
    outletList.find(outlet => outlet.id === outletId).isQRISRegistered =
      qris.isRegistered
    setSuccessModal(false)
    closeModal()
  }

  const screenType = getScreenType(window.screen.width, window.screen.height)
  return (
    <ReactModal
      isOpen={qrisModal.visible}
      style={{
        overlay: {
          zIndex: 99,
          backgroundColor: 'rgba(46, 63, 80, 0.8)',
          border: 'none',
          display: 'flex',
          placeContent: 'center',
          alignItems: 'center',
          padding: '1rem 2rem',
        },
        content: {
          inset: 0,
          backgroundColor: 'transparent',
          border: 'none',
          position: 'unset',
          minHeight: '393px',
          maxHeight: '600px',
          width: '100%',
          maxWidth: '570px',
          overflow: 'unset',
        },
      }}
      ariaHideApp={false}
    >
      <Wrapper height='100%' isCenter>
        <Panel.Panel fullScreen style={{ borderRadius: '8px' }}>
          {step === '1' ? (
            <Sided.Sided gutter={0} style={{ height: '100%' }}>
              <Sided.Remaining>
                <Panel.Header
                  style={{
                    background:
                      'linear-gradient(80.93deg, #4911BF 0%, #6B86E2 97.3%),linear-gradient(0deg, #001E3A, #001E3A)',
                    position: 'relative',
                    borderRadius: '8px 8px 0 0',
                    padding: '12px',
                  }}
                >
                  <Sided.Sided style={{ height: '140px' }}>
                    <Sided.Remaining>
                      <Wrapper
                        position='absolute'
                        style={{ left: '18px', bottom: '24px' }}
                      >
                        <Image
                          src={cdnImageUrl + 'images/QRIS-logo.png'}
                          width='70px'
                          height='25px'
                        />
                        <Break height={6} />
                        <Text.Paragraph size={14} color='white' noMargin>
                          Terima pembayaran <b>QRIS</b> tanpa daftar.
                        </Text.Paragraph>
                      </Wrapper>
                      <Image
                        src={cdnImageUrl + 'images/easy-transaction.png'}
                        width='175px'
                        height='150px'
                        style={{
                          position: 'absolute',
                          right: '-1px',
                          bottom: 0,
                        }}
                      />
                    </Sided.Remaining>
                    <Sided.Fixed>
                      <Icon.Cross
                        style={{ display: 'block' }}
                        color='white'
                        size={16}
                        onClick={closeModal}
                      />
                    </Sided.Fixed>
                  </Sided.Sided>
                </Panel.Header>
                <Panel.Content padding={16}>
                  {isLoading ? (
                    <Text.Paragraph color='white'>Loading...</Text.Paragraph>
                  ) : qrisModal.selectedOutletId ? (
                    <Field.Text
                      name='outletId'
                      label='Terapkan di Outlet'
                      value={
                        outletDropdownList.find(
                          x => x.value === qrisModal.selectedOutletId
                        )?.label
                      }
                      isDisabled
                    />
                  ) : (
                    <Field.Dropdown
                      name='outletId'
                      placeholder='Pilih Outlet'
                      label='Terapkan di Outlet'
                      list={outletDropdownList}
                      emptyMessage='Belum ada data outlet'
                      onChange={value => {
                        setSelectedOutletId(value)
                      }}
                    />
                  )}
                  <Break height={40} />
                  <Sided.Sided justifyContent='right'>
                    <Button
                      theme='secondary'
                      label='Aktifkan QRIS'
                      size='medium'
                      disabled={
                        !selectedOutletId && !qrisModal.selectedOutletId
                      }
                      style={{ width: '136px' }}
                      onClick={() => {
                        if (user.email) {
                          setStep('2')
                        } else {
                          setStep('password')
                        }
                      }}
                    />
                  </Sided.Sided>
                </Panel.Content>
              </Sided.Remaining>
            </Sided.Sided>
          ) : (
            <Sided.Sided gutter={0} style={{ height: '100%' }}>
              <Sided.Fixed
                minWidth={screenType === 'DESKTOP' ? 216 : 0}
                style={{
                  background:
                    'linear-gradient(80.93deg, #4911BF 0%, #6B86E2 97.3%),linear-gradient(0deg, #001E3A, #001E3A)',
                  position: 'relative',
                  borderRadius: '8px 0 0 8px',
                }}
              >
                <Wrapper
                  position='absolute'
                  style={{ top: '107px', left: '18px' }}
                >
                  <Image
                    src={cdnImageUrl + 'images/QRIS-logo.png'}
                    width='70px'
                    height='25px'
                  />
                  <Text.Paragraph size={14} color='white' noMargin>
                    Terima pembayaran <b>QRIS</b> tanpa daftar.
                  </Text.Paragraph>
                </Wrapper>
                {screenType === 'DESKTOP' && (
                  <Image
                    src={cdnImageUrl + 'images/easy-transaction.png'}
                    width='175px'
                    height='150px'
                    style={{ position: 'absolute', right: 0, bottom: 0 }}
                  />
                )}
              </Sided.Fixed>
              <Sided.Remaining
                style={{ position: 'relative', padding: '16px' }}
              >
                {renderRightSideContent()}
              </Sided.Remaining>
            </Sided.Sided>
          )}
        </Panel.Panel>
      </Wrapper>
      <SuccessModal
        isOpenModal={successModal}
        closeModal={() => {
          if (qrisModal.onSuccess) qrisModal.onSuccess()
          handleCloseSuccessModal()
        }}
      />
    </ReactModal>
  )
}

export default RegisterQRISModal
