import { Reducer, StateActions } from 'utilities/redux/reducer'
import { ServiceTypeState } from 'domains/management/serviceType/states'
import { ServiceType } from 'domains/management/serviceType/models'
import { SET_ORDERTYPE_PRODUCT } from './actions'
import { Dict } from 'utilities/type'
import { BaseStorage } from 'storages/base'
import { IServiceTypeStorage } from 'domains/management/serviceType/contracts/storage'

export class ServiceTypeStorage
  extends BaseStorage
  implements IServiceTypeStorage
{
  setServiceTypes(serviceTypeDict: Dict<ServiceType>): void {
    this.dispatch(SET_ORDERTYPE_PRODUCT, serviceTypeDict)
  }
}

export class ServiceTypeReducer extends Reducer<ServiceTypeState> {
  constructor() {
    super({
      serviceTypes: {},
    })
  }

  setServiceTypes(
    state: ServiceTypeState,
    serviceTypes: Dict<ServiceType>
  ): ServiceTypeState {
    return {
      ...state,
      serviceTypes: serviceTypes,
    }
  }

  get actions(): StateActions<ServiceTypeState> {
    return {
      [SET_ORDERTYPE_PRODUCT]: this.setServiceTypes,
    }
  }
}
