import { BaseApi } from 'api/base.api'
import { IOutletApi } from 'domains/management/outlets/contracts/api'
import { OutletModel, OutletRequest } from 'domains/management/outlets/models'

export default class OutletApi extends BaseApi implements IOutletApi {
  constructor() {
    super('/v2/outlets')
  }

  public async getOutlets(): Promise<OutletModel[]> {
    return await this.makeForCamelCaseForList<OutletModel>('GET', '')
  }

  public async createOutlet(requestBody: OutletRequest): Promise<OutletModel> {
    return await this.makeForCamelCase('POST', '', requestBody)
  }

  public async updateOutlet(
    requestBody: OutletRequest,
    outletId: string
  ): Promise<OutletModel> {
    return await this.makeForCamelCase('POST', `/${outletId}`, requestBody)
  }

  public async deleteOutlet(outletId: string): Promise<void> {
    return await this.makeForCamelCase('DELETE', `/${outletId}`)
  }

  public async deleteAllOutlet(): Promise<void> {
    return await this.makeForCamelCase('DELETE', '/')
  }
}
