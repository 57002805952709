import React from 'react'
import { Text, Break } from 'views/components'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'
import './style.css'
import { cdnImageUrl } from 'constants/cdnUrl/imageUrl'

const HeroImageCarousel = () => (
  <Carousel
    autoPlay
    infiniteLoop
    interval={2000}
    showStatus={false}
    showArrows={false}
    showThumbs={false}
    className='post-carousel'
  >
    <div>
      <Text.Heading h={2} noMargin color='black'>
        Kelola dan kembangkan
        <Break height={12} />
        usahamu dalam satu aplikasi
      </Text.Heading>
      <Break height={40} />
      <img
        src={cdnImageUrl + 'images/login-hero-1.png'}
        alt='POST. Login/Register Image'
        style={{ maxWidth: '522px' }}
      />
    </div>
    <div>
      <Text.Heading h={2} noMargin color='black'>
        <Break height={32} />
        <Break height={12} />
        Pantau penjualan dan pengeluaran
      </Text.Heading>
      <Break height={40} />
      <img
        src={cdnImageUrl + 'images/login-hero-2.png'}
        alt='POST. Login/Register Image'
        style={{ maxWidth: '522px' }}
      />
    </div>
    <div>
      <Text.Heading h={2} noMargin color='black'>
        Tingkatkan penjualan
        <Break height={12} />
        dengan Toko Online gratis
      </Text.Heading>
      <Break height={40} />
      <img
        src={cdnImageUrl + 'images/login-hero-3.png'}
        alt='POST. Login/Register Image'
        style={{ maxWidth: '522px' }}
      />
    </div>
    <div>
      <Text.Heading h={2} noMargin color='black'>
        Terima pembayaran
        <Break height={12} />
        e-wallet, kartu debit dan kredit
      </Text.Heading>
      <Break height={40} />
      <img
        src={cdnImageUrl + 'images/login-hero-4.png'}
        alt='POST. Login/Register Image'
        style={{ maxWidth: '522px' }}
      />
    </div>
  </Carousel>
)

export default HeroImageCarousel
