import { toDict } from 'utilities/converter/list'

import { BaseService } from 'domains/commons/services'
import { ICommonStorage } from 'domains/commons/contracts/storage'
import { Api } from 'domains/contracts/api'
import { IDiscountStorage } from '../contracts/storage'
import { Discount, GettingDiscountsRequest } from '../models'

const SET_DISCOUNT_PRODUCT = 'SET_DISCOUNT_PRODUCT'

export class DiscountService extends BaseService implements IDiscountService {
  discountStorage: IDiscountStorage

  constructor(
    commonStorage: ICommonStorage,
    api: Api,
    discountStorage: IDiscountStorage
  ) {
    super(commonStorage, api)
    this.discountStorage = discountStorage
  }

  public getDiscounts = async (
    queryParams: GettingDiscountsRequest = {}
  ): Promise<Discount[]> => {
    this.setLoading(SET_DISCOUNT_PRODUCT, true)
    queryParams.limit = queryParams.limit || 10
    const data = await this.api.management.discount.getDiscounts(queryParams)
    this.discountStorage.setDiscounts(toDict(data, (p: Discount) => p.id))
    const lastDiscountId = this.getLastDiscountId(data)
    this.discountStorage.setGettingDiscountsRequest({
      ...queryParams,
      startingAfter: lastDiscountId || queryParams.startingAfter,
    } as GettingDiscountsRequest)
    this.discountStorage.setDiscountsReachBottom(data.length === 0)
    this.setLoading(SET_DISCOUNT_PRODUCT, false)
    return data
  }

  private getLastDiscountId = (discounts: Discount[]): string | undefined => {
    return discounts.length > 0 ? discounts[discounts.length - 1].id : undefined
  }

  public loadMore = async (): Promise<Discount[]> => {
    const discountListManager =
      this.getState().management.discount.discountListManager
    if (discountListManager.hasReachedBottom) {
      return Promise.resolve([])
    }
    if (discountListManager.isLoadingMoreDiscount) {
      return Promise.resolve([])
    }
    this.discountStorage.setLoadingMoreDiscount(true)
    const queryParams = discountListManager.request
    const discounts = await this.api.management.discount.getDiscounts(
      queryParams
    )
    this.discountStorage.pushDiscounts(toDict(discounts, (p: Discount) => p.id))
    const lastDiscountId = this.getLastDiscountId(discounts)
    this.discountStorage.setLoadingMoreDiscount(false)
    this.discountStorage.setGettingDiscountsRequest({
      ...queryParams,
      startingAfter: lastDiscountId || queryParams.startingAfter,
    } as GettingDiscountsRequest)
    this.discountStorage.setLoadingMoreDiscount(discounts.length === 0)
    this.discountStorage.setDiscountsReachBottom(discounts.length === 0)
    return discounts
  }

  public createDiscount = async (request: Discount) => {
    try {
      const discountResponse =
        await this.api.management.discount.createDiscount(request)
      const { discounts } = this.getState().management.discount
      const newDiscounts = {
        [discountResponse.id]: discountResponse,
        ...discounts,
      }
      this.discountStorage.setDiscounts(newDiscounts)
    } catch (error) {
      throw error
    }
  }

  public updateDiscount = async (request: Discount, id: string) => {
    try {
      const discountResponse =
        await this.api.management.discount.updateDiscount(request, id)
      const { discounts } = this.getState().management.discount
      const newDiscounts = discounts
      newDiscounts[discountResponse.id] = discountResponse
      this.discountStorage.setDiscounts(newDiscounts)
    } catch (error) {
      throw error
    }
  }

  public deleteDiscount = async (id = '') => {
    try {
      this.setLoading('SET_CATEGORY_PRODUCT', true)
      const queryParams = id && `/${id}`
      await this.api.management.discount.deleteDiscount(queryParams)
      const { management } = this.getState()
      const { discount: discounts } = management
      const newDiscounts = discounts.discounts
      delete newDiscounts[id]
      this.discountStorage.setDiscounts(newDiscounts)
      this.setLoading('SET_CATEGORY_PRODUCT', false)
    } catch (error) {
      throw error
    }
  }
}

export interface IDiscountService {
  getDiscounts: (queryParams?: GettingDiscountsRequest) => Promise<Discount[]>
  createDiscount: (request: Discount) => void
  updateDiscount: (request: Discount, id: string) => void
  deleteDiscount: (id: string) => void
  loadMore: () => Promise<Discount[]>
}
