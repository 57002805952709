import { BaseApi } from 'api/base.api'
import { ShiftSetting } from 'domains/management/shift/models'
import { IShiftSettingApi } from 'domains/management/shift/contracts/api'

export default class ShiftSettingApi
  extends BaseApi
  implements IShiftSettingApi
{
  constructor() {
    super('/v2')
  }
  public async getShiftSetting(): Promise<ShiftSetting> {
    return await this.makeForCamelCase('GET', '/settings/shift')
  }
  public async setShiftSetting(
    shiftSetting: ShiftSetting
  ): Promise<ShiftSetting> {
    return await this.makeForCamelCase('POST', '/settings/shift', shiftSetting)
  }
}
