import { BaseStorage } from 'storages/base'
import { IDiscountStorage } from 'domains/management/discounts/contracts/storage'
import {
  Discount,
  GettingDiscountsRequest,
} from 'domains/management/discounts/models'
import {
  SET_DISCOUNT_PRODUCT,
  PUSH_DISCOUNT,
  SET_DISCOUNT_LIST_REACH_BOTTOM,
  SET_LOADING_MORE_DISCOUNT,
  SET_DISCOUNT_LIST_REQUEST,
} from './actions'
import { Dict } from 'utilities/type'

export class DiscountStorage extends BaseStorage implements IDiscountStorage {
  public setDiscounts(discountDict: Dict<Discount>): void {
    this.dispatch(SET_DISCOUNT_PRODUCT, discountDict)
  }

  public pushDiscounts(discountDict: Dict<Discount>): void {
    this.dispatch(PUSH_DISCOUNT, discountDict)
  }

  public setDiscountsReachBottom(hasReachedBottom: boolean): void {
    this.dispatch(SET_DISCOUNT_LIST_REACH_BOTTOM, hasReachedBottom)
  }

  public setLoadingMoreDiscount(isLoadingMoreDiscount: boolean): void {
    this.dispatch(SET_LOADING_MORE_DISCOUNT, isLoadingMoreDiscount)
  }
  public setGettingDiscountsRequest(request: GettingDiscountsRequest): void {
    this.dispatch(SET_DISCOUNT_LIST_REQUEST, request)
  }
}
