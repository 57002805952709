import React, { useState } from 'react'
import {
  Form,
  Text,
  Break,
  Button,
  Field,
  FormAction,
  Wrapper,
  Sided,
  Panel,
  Divider,
  VSided,
  Icon,
  // RadioButton,
} from 'views/components'
import { ModalProps, Modal, DefaultModalService } from 'views/components/Modal'
import {
  OutletGeolocation,
  OutletModel,
  OutletRequest,
} from 'domains/management/outlets/models'
import { UserState } from 'domains/users/states'
import { commonService, outletService, paymentMethodService } from 'injectors'
import DeleteConfirmationModal from '../../Modal/deleteConfirmation'
import { outletFormSchema } from './outletFormSchema'
import { trimDuplicateSpace } from 'utilities/strings'
import EditAddressModal from '../EditAddress'
import { featureCheck, isAnyOfTheseFeaturesEnabled } from 'config'

export interface OutletFormProps extends ModalProps {
  mode: 'update' | 'create'
  outletInfo: OutletModel
  user: UserState
}

const OutletForm: React.FC<OutletFormProps> = (props: OutletFormProps) => {
  const [outletGeo, setOutletGeo] = useState({} as OutletGeolocation)

  const { mode, outletInfo: multiOutletInfo /* , user */ } = props

  const id = multiOutletInfo && multiOutletInfo.id
  const name = multiOutletInfo && multiOutletInfo.name
  const phone = multiOutletInfo && multiOutletInfo.phone
  const address = multiOutletInfo && multiOutletInfo.address
  const whatsappNo = multiOutletInfo && multiOutletInfo.whatsappNo
  const latitude = multiOutletInfo && multiOutletInfo.latitude
  const longitude = multiOutletInfo && multiOutletInfo.longitude
  const areaId = multiOutletInfo && multiOutletInfo.areaId
  const mapAddress = multiOutletInfo && multiOutletInfo.mapAddress

  const addNewOutlet = async (
    request: OutletRequest,
    actions: FormAction<OutletRequest>
  ) => {
    try {
      const outlet = await outletService.createOutlet({
        ...request,
        ...outletGeo,
      })
      await paymentMethodService.getQris(outlet.id)
      commonService.setAlert({
        type: 'success',
        message: 'Outlet baru berhasil ditambahkan',
      })
      props.dismiss()
    } catch (error) {
      if (error.code === 'already_exists') {
        actions.setFieldError('name', 'Nama Outlet telah digunakan')
      }
    }
  }

  const updateOutlet = async (
    request: OutletRequest,
    id: string,
    actions: FormAction<OutletRequest>
  ) => {
    try {
      await outletService.updateOutlet({ ...request, ...outletGeo }, id)
      commonService.setAlert({
        type: 'success',
        message: 'Outlet berhasil diubah',
      })
      props.close('update')
    } catch (error) {
      if (error.code === 'already_exists') {
        actions.setFieldError('name', 'Nama Outlet telah digunakan')
      }
    }
  }

  const deleteOutlet = async (id: string) => {
    try {
      await outletService.deleteOutlet(id)
      paymentMethodService.deleteQris(id)
      commonService.setAlert({
        type: 'netral',
        message: 'Outlet berhasil dihapus',
      })
      props.close('delete')
    } catch (error) {
      commonService.setAlert({
        type: 'error',
        message: error.message,
      })
    }
  }

  const openDeleteConfirmationModal = async () => {
    const confirmed = await DefaultModalService.open(DeleteConfirmationModal, {
      title: 'Hapus Outlet?',
      body: 'Outlet yang sudah dihapus tidak dapat dikembalikan lagi.',
    })
    if (confirmed) {
      deleteOutlet(id)
    }
  }

  const renderDeleteOutletButton = () =>
    mode === 'update' && (
      <Text.Paragraph
        size={14}
        color='red'
        onClick={() => openDeleteConfirmationModal()}
        align='center'
      >
        Hapus Outlet
      </Text.Paragraph>
    )

  const submit = async (actions: FormAction<OutletRequest>) => {
    const request = actions.getValues()
    const trimmedRequest = {
      ...request,
      name: trimDuplicateSpace(request.name),
    }
    mode === 'update'
      ? updateOutlet(trimmedRequest, id, actions)
      : addNewOutlet(trimmedRequest, actions)
  }

  const openEditAddressModal = async () => {
    const outletGeoInfo = await DefaultModalService.open(EditAddressModal, {
      latitude: latitude,
      longitude: longitude,
      areaId: areaId,
      mapAddress: mapAddress,
    })

    setOutletGeo(outletGeoInfo)
  }

  const renderAddressField = () => {
    return (
      <Wrapper style={{ marginBottom: '16px' }}>
        <Sided.Sided gutter={0} style={{ marginBottom: '8px' }}>
          <Sided.Remaining>
            <Text.Span color='semiGray'>Alamat</Text.Span>
          </Sided.Remaining>
          <Sided.Fixed className='input-suffix'>
            <Text.Span color='darkGrayBold'>Opsional</Text.Span>
          </Sided.Fixed>
        </Sided.Sided>
        <Wrapper
          style={{
            background: 'none',
            padding: '0 8px',
            fontSize: '14px',
            width: '100%',
            height: '40px',
            lineHeight: '40px',
            borderRadius: '4px',
            outline: 'none',
            position: 'relative',
            boxSizing: 'border-box',
            border: '1px solid #e0e0e0',
            backgroundColor: '#ffffff',
            cursor: 'pointer',
          }}
          onClick={() => openEditAddressModal()}
        >
          <Sided.Sided>
            <Sided.Remaining>
              {outletGeo && outletGeo.mapAddress ? (
                <Text.Span>{outletGeo.mapAddress}</Text.Span>
              ) : mapAddress ? (
                <Text.Span>{mapAddress}</Text.Span>
              ) : (
                <Text.Span style={{ color: 'lightgrey' }}>
                  Cari Alamat Outlet
                </Text.Span>
              )}
            </Sided.Remaining>
            <Sided.Fixed minWidth={20}>
              <Icon.ChevronRight style={{ color: 'gray', marginTop: '6px' }} />
            </Sided.Fixed>
          </Sided.Sided>
        </Wrapper>
        {/* {props.error && !props.hideErrorMessage && (
            <Text.Span className='input-error-message' color='red' size={12}>
              {props.error}
            </Text.Span>
          )} */}
      </Wrapper>
    )
  }

  /* const renderEmailField = (actions: FormAction<OutletRequest>) => (
    <Wrapper border='1px solid #E0E0E0' margin='0 0 16px 0'>
      <Sided.Sided style={{ padding: '16px', backgroundColor: '#EEF9FD' }}>
        <Sided.Fixed>
          <Icon.Info size={16} color='black' style={{ marginTop: '4px' }} />
        </Sided.Fixed>
        <Sided.Remaining>
          <Text.Span size={14}>
            Laporan penjualan harian dan bukti pencairan dana pembayaran QRIS
            akan dikirimkan melalui email yang dipilih.
          </Text.Span>
        </Sided.Remaining>
      </Sided.Sided>
      {user.email && (
        <>
          <Sided.Sided justify='center' style={{ padding: '16px' }}>
            <Sided.Fixed>
              <RadioButton
                isActive={actions.getValues().isMerchantEmail}
                onClick={() =>
                  actions.setFieldValue(x => (x.isMerchantEmail = true))
                }
                color='green'
              />
            </Sided.Fixed>
            <Sided.Remaining>
              <Text.Span size={14}>{user.email}</Text.Span>
            </Sided.Remaining>
            <Sided.Fixed>
              <Text.Span size={14} color='gray'>
                Utama
              </Text.Span>
            </Sided.Fixed>
          </Sided.Sided>
          <Divider margin={1} />
        </>
      )}
      <Sided.Sided justify='center' style={{ padding: '16px' }}>
        <Sided.Fixed>
          <RadioButton
            isActive={!actions.getValues().isMerchantEmail}
            onClick={() =>
              actions.setFieldValue(x => (x.isMerchantEmail = false))
            }
            color='green'
          />
        </Sided.Fixed>
        <Sided.Remaining>
          <Text.Span size={14}>Gunakan Alamat Email Lain</Text.Span>
        </Sided.Remaining>
      </Sided.Sided>
      <Sided.Sided style={{ padding: '12px 16px 20px' }}>
        <Sided.Remaining>
          <Field.Text
            label='Alamat Email'
            name='email'
            placeholder='New_emailOutlet@email.com'
            isDisabled={actions.getValues().isMerchantEmail}
          />
        </Sided.Remaining>
      </Sided.Sided>
    </Wrapper>
  ) */

  const renderFormFields = (actions: FormAction<OutletRequest>) => (
    <VSided.VSided gutter={24}>
      <VSided.Remaining>
        <Wrapper isCenter width='480px'>
          <Break height={20} />
          <Text.Heading h={4}>
            {' '}
            {mode === 'update' ? 'Ubah Outlet' : 'Buat Outlet Baru'}{' '}
          </Text.Heading>
          <Break height={20} />
          <Panel.Card padding={16}>
            <Text.Heading h={5}>Detail Outlet</Text.Heading>
            <Divider />
            <Break height={10} />
            <Field.Text
              label='Nama Outlet'
              name='name'
              placeholder='Nama Lengkap Outlet'
            />
            {isAnyOfTheseFeaturesEnabled(
              featureCheck.contactless.onlineDelivery
            ) ? (
              renderAddressField()
            ) : (
              <Field.Text
                label='Alamat Outlet'
                name='address'
                placeholder='Alamat Lengkap Outlet'
              />
            )}
            {/* {renderAddressField()} */}
            {/* Will add Email field */}
            {/* {renderEmailField(actions)} */}
            <Field.Text
              label='Nomor Kontak Outlet'
              name='phone'
              placeholder='Nomor Kontak Outlet'
              tooltipMessage='Nomor Kontak Outlet diperlukan untuk informasi outlet pada struk'
            />
            <Field.Text
              label='Nomor Whatsapp Outlet'
              name='whatsappNo'
              placeholder='Nomor Whatsapp Outlet'
              secondaryLabel='Opsional'
              tooltipMessage='Nomor Whatsapp Outlet diperlukan untuk kebutuhan fitur Pesan Antar'
            />
          </Panel.Card>
          <Break height={24} />
          {renderDeleteOutletButton()}
        </Wrapper>
      </VSided.Remaining>
      <VSided.Fixed>
        <Wrapper
          shadow='0 -2px 4px 0 rgba(0, 0, 0, 0.2)'
          padding='16px'
          backgroundColor='white'
        >
          <Wrapper width='480px' isCenter>
            <Sided.Sided justify='center' gutter={16}>
              <Sided.Remaining>
                <Button
                  onClick={() => props.dismiss()}
                  size='large'
                  theme='netral'
                  type='button'
                  label='Batal'
                  fullW={true}
                />
              </Sided.Remaining>
              <Sided.Remaining>
                <Button
                  size='large'
                  theme='primary'
                  type='submit'
                  label='Simpan'
                  fullW={true}
                />
              </Sided.Remaining>
            </Sided.Sided>
          </Wrapper>
        </Wrapper>
      </VSided.Fixed>
    </VSided.VSided>
  )

  return (
    <Modal
      dismiss={() => props.dismiss()}
      type='full'
      canDismissOutside={false}
    >
      <Form.Form
        height='100%'
        initialValues={
          {
            name: name,
            phone: phone,
            address: address,
            whatsappNo: whatsappNo,
            latitude: latitude,
            longitude: longitude,
            mapAddress: mapAddress,
            areaId: areaId,
          } as OutletRequest
        }
        validationSchema={outletFormSchema}
        onSubmit={submit}
        render={(actions: FormAction<OutletRequest>) => {
          return renderFormFields(actions)
        }}
      />
    </Modal>
  )
}

export default OutletForm
