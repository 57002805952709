import { ServiceType } from 'domains/management/serviceType/models'
import { SET_ORDERTYPE_PRODUCT } from './actions'
import { Dict } from 'utilities/type'
import { BaseStorage } from 'storages/base'
import { IServiceTypeStorage } from 'domains/management/serviceType/contracts/storage'

export class ServiceTypeStorage
  extends BaseStorage
  implements IServiceTypeStorage
{
  setServiceTypes(serviceTypeDict: Dict<ServiceType>): void {
    this.dispatch(SET_ORDERTYPE_PRODUCT, serviceTypeDict)
  }
}
