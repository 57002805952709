import React from 'react'
import classNames from 'classnames'
import './style.css'
import { BaseClickableProps } from '../props'
import Text from '../Text'
import { Sided } from '..'

interface Props extends BaseClickableProps {
  theme:
    | 'primary'
    | 'secondary'
    | 'danger'
    | 'transparent'
    | 'netral'
    | 'inactive'
    | 'disable'
    | 'dangeroutline'
    | 'success'
    | 'normal'
    | 'primaryOutline'
    | 'secondaryOutline'
    | 'grayLight'
  label?: any
  size?: 'small' | 'medium' | 'large'
  fullW?: boolean
  type?: 'button' | 'submit' | 'reset'
  round?: boolean
  disabled?: boolean
  compact?: boolean
  iconLeft?: JSX.Element
  iconRight?: JSX.Element
  fontWeight?: string
  labelStyle?: React.CSSProperties
}

const Button: React.FC<Props> = props => {
  const className = classNames(
    props.compact ? '' : 'default',
    props.className || '',
    'post-button',
    props.theme,
    { 'full-width': props.fullW },
    { rounded: props.round },
    'post-button-' + (props.size || 'medium')
  )
  return (
    <button
      id={props.id}
      disabled={props.disabled}
      type={props.type || 'button'}
      className={className}
      onMouseOver={props.onMouseOver}
      onClick={props.onClick}
      data-testid='button'
      style={props.style}
    >
      {props.children ? (
        props.children
      ) : (
        <div className='post-button-inner'>
          <Sided.Sided>
            {props.iconLeft && (
              <Sided.Fixed verticalAlign='center'>{props.iconLeft}</Sided.Fixed>
            )}
            <Sided.Remaining>
              <Text.Heading
                h={6}
                align='center'
                style={props.labelStyle}
                noMargin
              >
                {props.label}
              </Text.Heading>
            </Sided.Remaining>
            {props.iconRight && (
              <Sided.Fixed verticalAlign='center'>
                {props.iconRight}
              </Sided.Fixed>
            )}
          </Sided.Sided>
        </div>
      )}
    </button>
  )
}

export default Button
