import React from 'react'
import { FormAction } from 'views/components'
import {
  CreateSingleProductRequestSchema,
  SingleProduct,
} from 'domains/management/products/models'

export interface ProductContext {
  base64ImageUrl: string
  setBase64ImageUrl: (imgUrl: string) => any
  actionsProps: FormAction<CreateSingleProductRequestSchema>
  singleData?: SingleProduct
  productId?: string
}

export default React.createContext<ProductContext>(null)
