import React, { useState, useEffect } from 'react'
import ReactModal from 'react-modal'
import {
  Panel,
  Sided,
  Text,
  Field,
  Icon,
  Wrapper,
  Scroll,
} from 'views/components'
import { ListingsStockTable } from './ListingsTable'
import './style.css'
import Context from './context'
import PostCalendar from 'views/components/PostCalendar'
import { DateRange } from 'domains/commons/models'
import { ProductStockHistory } from 'domains/management/products/models'
import { productService } from 'injectors'
import { toUTCISOString } from 'utilities/converter/date'
import moment from 'moment'
import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'

interface Props {
  productId: string
  isOpenModal: boolean
  closeModal: () => void
}

export const ListingsStock: React.FC<Props> = props => {
  const [dateRange, setDateRange] = useState<DateRange>({
    from: moment().startOf('day').toDate(),
    to: moment().endOf('day').toDate(),
  })
  const { i18n } = useLingui()

  const [stockHistories, setStockHistories] = useState<ProductStockHistory[]>(
    []
  )
  const [typeFilter, setTypeFilter] = useState<string | undefined>(undefined)

  const filterByType = (
    histories: ProductStockHistory[]
  ): ProductStockHistory[] => {
    if (!typeFilter) {
      return histories
    }
    return histories.filter(x => x.type == typeFilter)
  }

  const loadStockHistory = async () => {
    if (!dateRange) {
      return
    }
    const h = await productService.getProductStockHistories(props.productId, {
      fromDate: toUTCISOString(dateRange.from),
      toDate: toUTCISOString(dateRange.to),
    })
    setStockHistories(h)
  }

  useEffect(() => {
    loadStockHistory()
  }, [dateRange])

  const renderHeader = () => (
    <Sided.Sided>
      <Sided.Remaining verticalAlign='center'>
        <Text.Heading h={6}>{i18n._(t`Riwayat Stok`)}</Text.Heading>
      </Sided.Remaining>
      <Sided.Fixed verticalAlign='center'>
        <Icon.Cross
          style={{ display: 'block' }}
          color='black'
          size={16}
          onClick={() => props.closeModal()}
        />
      </Sided.Fixed>
    </Sided.Sided>
  )
  return (
    <ReactModal
      isOpen={props.isOpenModal}
      style={{
        overlay: {
          zIndex: 99,
          backgroundColor: 'rgba(46, 63, 80, 0.8)',
          border: 'none',
          display: 'flex',
          placeContent: 'center',
          alignItems: 'center',
          padding: '1rem 2rem',
        },
        content: {
          inset: 0,
          backgroundColor: 'transparent',
          alignItems: 'center',
          border: 'none',
          position: 'unset',
          padding: 0,
          height: '100%',
          maxHeight: '500px',
          width: '100%',
          maxWidth: '900px',
          overflow: 'unset',
        },
      }}
    >
      <Wrapper isCenter style={{ overflowY: 'auto', height: '100%' }}>
        <Panel.Panel fullScreen>
          <Panel.Header>{renderHeader()}</Panel.Header>
          <Panel.Content padding={0}>
            <Wrapper padding='0px 16px 16px 16px'>
              <Sided.Sided gutter={24} justify='center' justifyContent='center'>
                <Sided.Remaining>
                  <Field.Dropdown
                    name='stock'
                    value={typeFilter}
                    onChange={value => setTypeFilter(value)}
                    list={[
                      {
                        value: undefined,
                        label: i18n._(t`Semua jenis penyesuaian`),
                      },
                      {
                        value: 'perhitungan ulang',
                        label: i18n._(t`Perhitungan Ulang`),
                      },
                      { value: 'pengurangan', label: i18n._(t`Pengurangan`) },
                      { value: 'penambahan', label: i18n._(t`Penambahan`) },
                      { value: 'penjualan', label: i18n._(t`Penjualan`) },
                    ]}
                  />
                </Sided.Remaining>
                <Sided.Remaining>
                  <PostCalendar
                    selectedDateRange={dateRange}
                    mode='daily'
                    onChange={range => setDateRange(range)}
                  ></PostCalendar>
                </Sided.Remaining>
              </Sided.Sided>
              <Scroll className='scroll-listings'>
                <Context.Provider
                  value={{
                    stockHistories: filterByType(stockHistories),
                  }}
                >
                  <ListingsStockTable />
                </Context.Provider>
              </Scroll>
            </Wrapper>
          </Panel.Content>
        </Panel.Panel>
      </Wrapper>
    </ReactModal>
  )
}
