import React from 'react'
import { Panel, Sided, Text, Button, Break } from 'views/components'
import { ModalProps, Modal, DefaultModalService } from 'views/components/Modal'
import { DeviceModel } from 'domains/management/device/models'
import { deviceService, commonService } from 'injectors'
import { AppState } from 'domains/contracts/appState'
import { useSelector } from 'react-redux'

interface DeleteDeviceModalProps extends ModalProps {
  device: DeviceModel
}

export const DeleteDeviceModal: React.FC<DeleteDeviceModalProps> = props => {
  const modalComponentIds = useSelector((state: AppState) =>
    state.common.modalComponents.map(mc => mc.id)
  )
  const onDeleteDevice = async (device: DeviceModel) => {
    try {
      await deviceService.deleteDevice(device.id)
      commonService.setAlert({
        message: 'Kode Akses Kasir berhasil di hapus',
        type: 'netral',
      })
      modalComponentIds.forEach(id => {
        DefaultModalService.remove(id)
      })
    } catch (error) {
      commonService.setAlert({
        message: error.message,
        type: 'error',
      })
      props.dismiss()
    }
  }

  return (
    <Modal dismiss={props.dismiss} type='blank' width='400px'>
      <Panel.Panel>
        <Panel.Header>
          <Text.Heading h={5} noMargin>
            Hapus Perangkat?
          </Text.Heading>
        </Panel.Header>
        <Panel.Content>
          <Text.Paragraph size={14} color='brownGray'>
            Semua transaksi dari Kode Akses Kasir ini tetap tersimpan.
          </Text.Paragraph>
          <Break height={16} />
          <Sided.Sided gutter={16}>
            <Sided.Remaining>
              <Button
                theme='netral'
                size='large'
                label='Batal'
                onClick={() => props.dismiss()}
                fullW
              />
            </Sided.Remaining>
            <Sided.Remaining>
              <Button
                theme='danger'
                size='large'
                label='Hapus'
                onClick={() => onDeleteDevice(props.device)}
                fullW
              />
            </Sided.Remaining>
          </Sided.Sided>
        </Panel.Content>
      </Panel.Panel>
    </Modal>
  )
}
