import React from 'react'
import { useSelector } from 'react-redux'
import { commonService } from 'injectors'
import classNames from 'classnames'
import { AlertConfig } from 'domains/commons/states'
import { Icon, Text } from 'views/components'
import selector from './selector'
import './style.css'

interface Props {
  config?: AlertConfig
}

export const Alert = (props: Props) => {
  const config = useSelector(selector)
  if (config) {
    setTimeout(() => {
      commonService.setAlert(undefined)
    }, config.timeout || 3000)
  }

  const className = classNames(
    'post-alert',
    'post-alert-' + ((config && config.type) || 'success'),
    { hidden: !config },
    { visible: !!config }
  )
  return <div className={className}>{config && config.message}</div>
}

export const alertNoPermission = () => {
  commonService.setAlert({
    type: 'error',
    message: (
      <>
        <Icon.CautionTriangle color='white' />
        <Text.Span
          color='white'
          size={16}
          align='center'
          style={{ marginLeft: '8px' }}
        >
          Kamu tidak mempunyai izin untuk mengakses fitur ini.
        </Text.Span>
      </>
    ),
  })
}

export default Alert
