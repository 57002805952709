import React from 'react'
import { Provider } from 'react-redux'
import { Route, Router, Switch } from 'react-router-dom'

import { featureCheck, isAnyOfTheseFeaturesEnabled } from 'config'
import ROUTE_CONFIG, { RenderRoutes } from 'route'
import { AppStore, history } from 'storages'
import { LanguageProvider } from 'utilities/i18n'
import { Alert, OrientationBlockScreen } from 'views/components'
import LiveChatComponent from 'views/components/LiveChat'
import { ModalContainer } from 'views/components/Modal'
import AuthorizedLayout from 'views/layouts/Authorized'
import UnauthorizedLayout from 'views/layouts/Unauthorized'

export const App = () => {
  return (
    <LanguageProvider defaultLanguage='id'>
      <Provider store={AppStore}>
        <OrientationBlockScreen />
        <Router history={history}>
          {isAnyOfTheseFeaturesEnabled(featureCheck.home.routingV2) ? (
            <RenderRoutes routes={ROUTE_CONFIG} />
          ) : (
            <Switch>
              <Route path='/auth' component={UnauthorizedLayout} />
              <Route path='/' component={AuthorizedLayout} />
            </Switch>
          )}
        </Router>
        <ModalContainer></ModalContainer>
        <Alert />
        <LiveChatComponent />
      </Provider>
    </LanguageProvider>
  )
}
