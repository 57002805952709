import { BaseService } from 'domains/commons/services'
import { Api } from 'domains/contracts/api'
import { ICommonStorage } from 'domains/commons/contracts/storage'
import { ChargesDetails, ChargesRequestBody } from '../models'

export class ChargesService extends BaseService implements IChargesService {
  constructor(commonStorage: ICommonStorage, api: Api) {
    super(commonStorage, api)
  }

  public getCharges = async (): Promise<ChargesDetails> => {
    try {
      return await this.api.management.charges.getCharges()
    } catch (error) {
      throw error
    }
  }

  public updateCharges = async (request: ChargesRequestBody) => {
    try {
      return await this.api.management.charges.updateCharges(request)
    } catch (error) {
      throw error
    }
  }
}
export interface IChargesService {
  getCharges: () => Promise<ChargesDetails>
  updateCharges: (request: ChargesRequestBody) => Promise<ChargesDetails>
}
