const managementRoute = {
  MAIN_MANAGEMENT: '/kelola',
  MANAGEMENT_PRODUCT: '/kelola/produk',
  MANAGEMENT_PRODUCT_FORM: '/kelola/produk/form',
  MANAGEMENT_PRODUCT_LIST: '/kelola/produk/daftar-produk',
  MANAGEMENT_PRODUCT_CATEGORY: '/kelola/produk/kategori',
  MANAGEMENT_PRODUCT_CATEGORY_FORM: '/kelola/produk/kategori/form',
  MANAGEMENT_PRODUCT_ORDER_TYPE: '/kelola/produk/tipe-pesanan',
  MANAGEMENT_PRODUCT_ADD_PRODUCT: '/kelola/produk/tambahkan',
  MANAGEMENT_PRODUCT_ADD_CATEGORY: '/kelola/produk/kategory/tambah-kategori',
  MANAGEMENT_PAYMENT_DISCOUNT_PROMO: '/kelola/produk/diskon-dan-promo',
  MANAGEMENT_PAYMENT_DISCOUNT: '/kelola/produk/diskon-dan-promo',
  MANAGEMENT_PAYMENT_DISCOUNT_FORM: '/kelola/produk/diskon-dan-promo/form',
  MANAGEMENT_PAYMENT: '/kelola/toko/pembayaran',
  MANAGEMENT_PAYMENT_TAX_SERVICE_CHARGE:
    '/kelola/toko/pembayaran/pajak-dan-biaya-layanan',
  MANAGEMENT_PAYMENT_METHOD: '/kelola/toko/pembayaran/metode-pembayaran',
  MANAGEMENT_OUTLET: '/kelola/toko/outlet',
  MANAGEMENT_OUTLET_RECEIPT: '/kelola/toko/outlet/struk',
  MANAGEMENT_OUTLET_DEVICE: '/kelola/toko/outlet/perangkat',
  MANAGEMENT_OUTLET_DEVICE_FORM: '/kelola/toko/outlet/perangkat/form',
  MANAGEMENT_OUTLET_EMPLOYEE: '/kelola/toko/outlet/karyawan',
  MANAGEMENT_OUTLET_EMPLOYEE_FORM: '/kelola/toko/outlet/karyawan/form',
  MANAGEMENT_OUTLET_SHIFT: '/kelola/toko/outlet/shift',
  MANAGEMENT_OUTLET_MULTIOUTLET: '/kelola/toko/outlet/cabang',
  MANAGEMENT_OUTLET_MULTIOUTLET_FORM: '/kelola/toko/outlet/cabang/form',
  MANAGEMENT_OUTLET_ATTENDANCE: '/kelola/toko/outlet/kehadiran',
  MANAGEMENT_CUSTOMER: '/kelola/toko/pelanggan',
  MANAGEMENT_CUSTOMER_FORM: '/kelola/toko/pelanggan/form',
  MANAGEMENT_INTEGRATION: '/kelola/integrasi',
  MANAGEMENT_MASS_IMPORT: '/kelola/impor-massal',
  MANAGEMENT_ROLE_MANAGEMENT: '/kelola/toko/outlet/atur-peran-staff',
}

export default managementRoute
