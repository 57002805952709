import React from 'react'
import moment from 'moment'

import Sided from 'views/components/Sided'
import Button from 'views/components/Button'
import { Icon } from 'views/components'
import Text from 'views/components/Text'

export interface MonthAndYear {
  month: number
  year: number
}

interface MonthHeaderSelectorProps {
  onChange: (x: MonthAndYear) => any
  monthAndYear: MonthAndYear
}

const MonthHeaderSelector = (props: MonthHeaderSelectorProps) => {
  const nextMonth = () => () => {
    let m = props.monthAndYear.month + 1
    let y = props.monthAndYear.year
    if (m == 13) {
      m = 1
      y++
    }
    props.onChange({
      month: m,
      year: y,
    })
  }

  const prevMonth = () => () => {
    let m = props.monthAndYear.month - 1
    let y = props.monthAndYear.year
    if (m == 0) {
      m = 12
      y--
    }
    props.onChange({
      month: m,
      year: y,
    })
  }

  return (
    <Sided.Sided>
      <Sided.Fixed>
        <Button compact theme='transparent' onClick={prevMonth()}>
          <Icon.ChevronLeft />
        </Button>
      </Sided.Fixed>
      <Sided.Remaining>
        <Text.Heading h={4} align='center' noMargin>
          {`${moment()
            .months(props.monthAndYear.month)
            .subtract(1, 'month')
            .format('MMMM')} ${props.monthAndYear.year}`}
        </Text.Heading>
      </Sided.Remaining>
      <Sided.Fixed>
        <Button
          compact
          theme='transparent'
          className='month-header-component-header-next-month-button'
          onClick={nextMonth()}
        >
          <Icon.ChevronRight />
        </Button>
      </Sided.Fixed>
    </Sided.Sided>
  )
}

export default MonthHeaderSelector
