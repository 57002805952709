import { BaseStorage } from 'storages/base'
import {
  SET_EXPENSE,
  APPEND_EXPENSE,
  SET_EXPENSE_CATEGORIES,
  APPEND_EXPENSE_CATEGORIES,
  SET_SHOW_EXPENSE_DETAILS,
  SET_EXPENSE_DETAILS,
  SET_EXPENSE_FILTER_CATEGORY_NAME,
} from './actions'
import { Dict } from 'utilities/type'
import { ExpenseCategory, Expense } from 'domains/management/expense/models'
import { IExpenseStorage } from 'domains/management/expense/contracts/storage'
import { ExpenseSummary } from 'domains/reporting/models'

export class ExpenseStorage extends BaseStorage implements IExpenseStorage {
  setExpenses(expenses: Dict<ExpenseSummary>): void {
    this.dispatch(SET_EXPENSE, expenses)
  }

  appendExpenses(expenses: Dict<Expense>): void {
    this.dispatch(APPEND_EXPENSE, expenses)
  }

  setCategories(categories: Dict<ExpenseCategory>): void {
    this.dispatch(SET_EXPENSE_CATEGORIES, categories)
  }

  appendCategories(categories: Dict<ExpenseCategory>): void {
    this.dispatch(APPEND_EXPENSE_CATEGORIES, categories)
  }

  setShowExpenseDetails(isShown: boolean): void {
    this.dispatch(SET_SHOW_EXPENSE_DETAILS, isShown)
  }

  setExpenseDetails(details: Dict<Expense>): void {
    this.dispatch(SET_EXPENSE_DETAILS, details)
  }

  setExpenseFilterCategoryName(categoryName: string): void {
    this.dispatch(SET_EXPENSE_FILTER_CATEGORY_NAME, categoryName)
  }
}
