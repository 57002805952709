import React, { useRef, useState } from 'react'
import classNames from 'classnames'
import './style.css'
import { Icon, Sided, Text } from '..'

export interface ButtonDropdownItem {
  key: string
  label: string
  disabled?: boolean
}

interface Props {
  theme: 'primary'
  label: string
  size?: 'small' | 'medium' | 'large'
  fullW?: boolean
  onClick?: (key: string) => any
  disabled?: boolean
  list: ButtonDropdownItem[]
  iconLeft?: JSX.Element
  noCaret?: boolean
}

const ButtonDropdown: React.FC<Props> = props => {
  const [popupVisibility, setPopupVisibility] = useState('hidden')
  const containerRef = useRef<HTMLDivElement>(null)

  const handleClick = () => {
    if (props.disabled) {
      return
    }
    if (popupVisibility === 'hidden') {
      setPopupVisibility('visible')
      document.addEventListener('click', handleOutsideClick, false)
    } else {
      setPopupVisibility('hidden')
      document.removeEventListener('click', handleOutsideClick, false)
    }
  }

  const handleOutsideClick = (e: any) => {
    if (containerRef.current && !containerRef.current.contains(e.target)) {
      setPopupVisibility('hidden')
    }
  }

  const onItemClick = (key: string) => {
    if (!props.disabled && props.onClick) {
      props.onClick(key)
    }
  }

  const wrapperClassName = classNames('post-button-dropdown-wrapper', {
    'full-width': props.fullW,
  })

  const classNameButton = classNames(
    'post-button-dropdown',
    props.theme,
    { 'full-width': props.fullW },
    { disabled: props.disabled },
    'post-button-dropdown-' + (props.size || 'medium')
  )
  return (
    <div
      className={wrapperClassName}
      ref={containerRef}
      data-testid='button-dropdown-wrapper'
    >
      <button
        className={classNameButton}
        onClick={handleClick}
        data-testid='button-dropdown'
      >
        <Sided.Sided>
          {props.iconLeft && (
            <Sided.Fixed verticalAlign='center'>{props.iconLeft}</Sided.Fixed>
          )}
          <Sided.Remaining>
            <Text.Paragraph noWrap noMargin>
              {props.label}
            </Text.Paragraph>
          </Sided.Remaining>
          {!props?.noCaret && (
            <Sided.Fixed verticalAlign='center'>
              <Icon.Caretdown color='white' />
            </Sided.Fixed>
          )}
        </Sided.Sided>
      </button>
      <ul className={`post-button-dropdown-menu ${popupVisibility}`}>
        {props.list.map((item, i) => (
          <li
            className={classNames({ disabled: item.disabled })}
            key={i}
            onClick={() => !item.disabled && onItemClick(item.key)}
          >
            {item.label}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default ButtonDropdown
