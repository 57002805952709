import React from 'react'
import { Sided, Icon, Text } from 'views/components'

interface BackpageProps {
  onClick?: () => any
  namePage: string
  isIcon?: boolean
}

const BackPage = (props: BackpageProps) => {
  return (
    <Sided.Sided justifyContent='center' gutter={16} onClick={props.onClick}>
      {props.isIcon ? (
        <Sided.Fixed>
          <Icon.ArrowLeft color='black' />
        </Sided.Fixed>
      ) : (
        ' '
      )}
      <Sided.Remaining>
        <Text.Heading h={3} noMargin>
          {props.namePage}
        </Text.Heading>
      </Sided.Remaining>
    </Sided.Sided>
  )
}

export default BackPage
