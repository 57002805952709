import { featureCheck, isAnyOfTheseFeaturesEnabled } from 'config'
import ROUTE from 'constants/routes/index'
import {
  RouteItem,
  filterOnlyEnabled,
} from 'domains/layouts/services/route.item'
import { Guest } from 'middleware/guest'

export function generateAuthRoutes(): RouteItem[] {
  const arr: RouteItem[] = [
    {
      key: 'login',
      path: ROUTE.LOGIN,
      component: 'views/modules/Login',
      middleware: [Guest],
      isEnabled: true,
    },
    {
      key: 'register-token',
      path: ROUTE.REGISTER_TOKEN,
      component: 'views/modules/Registration/RegisterToken',
      middleware: [Guest],
      isEnabled: true,
    },
    {
      key: 'register-otp',
      path: ROUTE.REGISTER_OTP,
      component: 'views/modules/Registration/RegisterOTP',
      middleware: [Guest],
      isEnabled: true,
    },
    {
      key: 'forgot-password',
      path: ROUTE.FORGOT_PASSWORD,
      component: 'views/modules/Auth/ResetPassword/ResetPasswordToken',
      middleware: [Guest],
      isEnabled: true,
    },
    {
      key: 'confirmation-otp-forgot-password',
      path: ROUTE.FORGOT_PASSWORD_OTP,
      component: 'views/modules/Auth/ResetPassword/ResetPasswordOTP',
      middleware: [Guest],
      isEnabled: true,
    },
    {
      key: 'create-new-password',
      path: ROUTE.CREATE_NEW_PASSWORD,
      component: 'views/modules/Auth/ResetPassword/NewPasswordForm',
      middleware: [Guest],
      isEnabled: true,
    },
    {
      key: 'create-new-employee',
      path: ROUTE.CREATE_NEW_EMPLOYEE,
      component: 'views/modules/Management/Employee/ChangePassword',
      isEnabled: true,
    },
    {
      key: 'register-staff-success',
      path: ROUTE.CREATE_STAFF_PASSWORD_SUCCESS,
      component: 'views/modules/Management/Employee/ChangeStaffPasswordSuccess',
      isEnabled: true,
    },
    {
      key: 'register-success',
      path: ROUTE.REGISTER_SUCCESS,
      component: isAnyOfTheseFeaturesEnabled(featureCheck.home.registerRevamp)
        ? 'views/modules/Registration/RegisterSuccessV2'
        : 'views/modules/Registration/RegisterSuccess',
      isEnabled: true,
    },
  ]
  return filterOnlyEnabled(arr)
}
