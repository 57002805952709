import { BaseStorage } from 'storages/base'
import { ILocationStorage } from 'domains/location/contracts/storage'
import { LocationModel } from 'domains/location/models'
import {
  SET_LOCATION,
  PUSH_LOCATIONS,
  SET_LOADING_MORE_LOCATION,
  SET_LOCATION_LIST_REQUEST,
  SET_LOCATION_LIST_REACH_BOTTOM,
} from './actions'
import { Dict } from 'utilities/type'
import { LocationListQueryString } from 'domains/location/states'

export class LocationStorage extends BaseStorage implements ILocationStorage {
  setLocation(location: Dict<LocationModel>): void {
    this.dispatch(SET_LOCATION, location)
  }

  pushLocations(locations: Dict<LocationModel>): void {
    this.dispatch(PUSH_LOCATIONS, locations)
  }

  setLoadingMoreLocation(isLoadingMoreLocation: boolean): void {
    this.dispatch(SET_LOADING_MORE_LOCATION, isLoadingMoreLocation)
  }

  setLocationListRequest(request: LocationListQueryString): void {
    this.dispatch(SET_LOCATION_LIST_REQUEST, request)
  }

  setLocationListReachBottom(hasReachedBottom: boolean): void {
    this.dispatch(SET_LOCATION_LIST_REACH_BOTTOM, hasReachedBottom)
  }
}
