import React, { useEffect, useState } from 'react'
import Zendesk from 'react-zendesk'
import Wrapper from '../Wrapper'
import { firebaseFunc } from 'utilities/firebase'
import Sided from '../Sided'
import { Icon } from 'views/components'
import Text from '../Text'
import crisp from 'utilities/crisp'
import { useSelector } from 'react-redux'
import { AppState } from 'domains/contracts/appState'
import { User } from 'domains/users/models'

interface LiveChatConfig {
  zendesk: boolean
  whatsapp: boolean
  crisp: boolean
}

const LiveChatComponent = () => {
  const [liveChatConfig, setLiveChatConfig] = useState<LiveChatConfig>()
  const user = useSelector<AppState, User>(state => state.user)

  const getRemoteConfigValues = async () => {
    const liveChatConfig: firebase.remoteConfig.Value =
      await firebaseFunc.getRemoteConfigValue('live_chat_config')
    setLiveChatConfig(
      liveChatConfig ? JSON.parse(liveChatConfig.asString()) : {}
    )
  }

  useEffect(() => {
    getRemoteConfigValues()
  }, [])

  useEffect(() => {
    liveChatConfig && liveChatConfig.crisp && crisp.initialize(user)
  }, [liveChatConfig])

  return (
    <Wrapper onClick={() => console.error('live chat clicked')}>
      {liveChatConfig && liveChatConfig.whatsapp && (
        <Wrapper
          position='fixed'
          style={{ zIndex: 10, right: '32px', bottom: '32px' }}
        >
          <button
            style={{
              cursor: 'pointer',
              backgroundColor: '#25D366',
              border: 'none',
              borderRadius: '46px',
              padding: '8px 18px',
              outline: 'none',
            }}
            onClick={() => {
              firebaseFunc.logEvent('whatsapp_live_chat')
              window.open(
                'https://wa.me/6289527565949?text=Hai%20POST.%2C%20saya%20butuh%20bantuan',
                '_blank'
              )
            }}
          >
            <Sided.Sided>
              <Sided.Fixed verticalAlign='center'>
                <Icon.Whatsapp color='white' />
              </Sided.Fixed>
              <Sided.Remaining verticalAlign='center'>
                <Text.Span size={18} color='white'>
                  Whatsapp Chat
                </Text.Span>
              </Sided.Remaining>
            </Sided.Sided>
          </button>
        </Wrapper>
      )}
      {liveChatConfig && liveChatConfig.zendesk && (
        <Zendesk
          zendeskKey={'d25db634-457a-442a-b88d-c33b9caeccda'}
          onLoaded={() => {
            console.log('Zendesk is loaded!')
            ;(window as any).zE('webWidget:on', 'open', function () {
              firebaseFunc.logEvent('zendesk_live_chat')
            })
          }}
        />
      )}
    </Wrapper>
  )
}

export default LiveChatComponent
