import { AppState } from 'domains/contracts/appState'

export const isPremiumSelector = (state: AppState): boolean => {
  const subscriptionAlert = state.subscriptions.subscriptionAlert
  return (
    subscriptionAlert.subscriptionType &&
    subscriptionAlert.subscriptionType.toUpperCase() === 'PREMIUM'
  )
}

export const isTrialSelector = (state: AppState): boolean => {
  const subscriptionAlert = state.subscriptions.subscriptionAlert
  return (
    subscriptionAlert.subscriptionType &&
    subscriptionAlert.status.toUpperCase() === 'TRIAL'
  )
}
