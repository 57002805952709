import { ICommonStorage } from 'domains/commons/contracts/storage'
import { BaseService } from 'domains/commons/services'
import { IUserStorage } from '../contracts/storage'
import { Api } from 'domains/contracts/api'
import {
  BusinessAccount,
  PasswordRequest,
  OnboardingSteps,
  User,
  BusinessCategory,
} from '../models'
import { toDict } from 'utilities/converter/list'
import { Group } from 'utilities/type'

export class UserService extends BaseService implements IUserService {
  userStorage: IUserStorage

  constructor(
    commonStorage: ICommonStorage,
    api: Api,
    userStorage: IUserStorage
  ) {
    super(commonStorage, api)
    this.userStorage = userStorage
  }

  private setIsFnbBusinessCategory = (
    businessCategoryId: string,
    businessCategories: Group<BusinessCategory>
  ) => {
    const fnbBusinessCategories =
      businessCategories && businessCategories['Makanan & Minuman']
    if (fnbBusinessCategories) {
      this.userStorage.setIsFnbBusinessCategory(
        !!fnbBusinessCategories.find(
          category => category.id === businessCategoryId
        )
      )
    } else {
      this.userStorage.setIsFnbBusinessCategory(false)
    }
  }

  public getUser = async () => {
    try {
      const userResponse = await this.api.users.getUser()
      this.userStorage.setUser(userResponse)
      const { user } = this.getState()
      this.setIsFnbBusinessCategory(
        userResponse.businessCategoryId,
        user.businessCategories
      )
      return userResponse
    } catch (error) {
      throw error
    }
  }

  public updateUser = async (query: BusinessAccount) => {
    try {
      await this.api.users.updateUser(query)
      const user = await this.getUser()
      this.userStorage.setUser(user)
    } catch (error) {
      throw error
    }
  }

  public changePhoneToken = async (phone: any) => {
    try {
      await this.api.users.changePhoneToken(phone)
    } catch (error) {
      throw error
    }
  }

  public changePhone = async (phone: string) => {
    try {
      const phoneRequest: BusinessAccount = { phone: phone }
      await this.api.users.changePhone(phoneRequest)
    } catch (error) {
      throw error
    }
  }

  public changeEmail = async (email: string) => {
    try {
      const emailRequest: BusinessAccount = { email: email }
      await this.api.users.changeEmail(emailRequest)
      const { user } = this.getState()
      this.userStorage.setUser({ ...user, email: email })
    } catch (error) {
      throw error
    }
  }

  public checkPassword = async (password: PasswordRequest) => {
    try {
      await this.api.users.checkPassword(password)
    } catch (error) {
      throw error
    }
  }

  public resetManagements = async () => {
    try {
      await this.api.users.resetManagements()
    } catch (error) {
      throw error
    }
  }
  public resetTransactions = async () => {
    try {
      await this.api.users.resetTransactions()
    } catch (error) {
      throw error
    }
  }

  public getBusinessCategories = async () => {
    try {
      const businessCategories = await this.api.users.getBusinessCategories()
      this.userStorage.setBusinessCategories(businessCategories)
      const { user } = this.getState()
      this.setIsFnbBusinessCategory(user.businessCategoryId, businessCategories)
    } catch (error) {
      throw error
    }
  }

  public getOnboardingSteps = async () => {
    try {
      const onboardingSteps = await this.api.users.getOnBoardingSteps()
      this.userStorage.setOnboardingSteps(toDict(onboardingSteps, o => o.event))
      return onboardingSteps
    } catch (error) {
      throw error
    }
  }
}

export interface IUserService {
  getUser: () => Promise<User>
  updateUser: (query: User) => Promise<void>
  changePhoneToken: (phone: any) => Promise<void>
  getBusinessCategories: () => Promise<void>
  changePhone: (phone: string) => Promise<void>
  changeEmail: (email: string) => Promise<void>
  checkPassword: (password: PasswordRequest) => Promise<void>
  resetManagements: () => Promise<void>
  resetTransactions: () => Promise<void>
  getOnboardingSteps: () => Promise<OnboardingSteps[]>
}
