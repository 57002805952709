import { BaseStorage } from 'storages/base'
import { IUserStorage } from 'domains/users/contracts/storage'
import { User, BusinessCategory, OnboardingSteps } from 'domains/users/models'
import {
  SET_USER,
  LOGOUT_USER,
  SET_BUSINESS_CATEGORIES,
  SET_ONBOARDING_STEPS,
  SET_IS_FNB_BUSINESS_CATEGORY,
} from './actions'
import { Group, Dict } from 'utilities/type'

export class UserStorage extends BaseStorage implements IUserStorage {
  setUser(user: User): void {
    this.dispatch(SET_USER, user)
  }
  setBusinessCategories(businessCategories: Group<BusinessCategory>): void {
    this.dispatch(SET_BUSINESS_CATEGORIES, businessCategories)
  }
  logoutUser(): void {
    this.dispatch(LOGOUT_USER)
  }
  setOnboardingSteps(onboardingSteps: Dict<OnboardingSteps>) {
    this.dispatch(SET_ONBOARDING_STEPS, onboardingSteps)
  }
  setIsFnbBusinessCategory(isFnbBusinessCategory: boolean) {
    this.dispatch(SET_IS_FNB_BUSINESS_CATEGORY, isFnbBusinessCategory)
  }
}
