import React, { useState, useEffect } from 'react'

import { cdnImageUrl } from 'constants/cdnUrl/imageUrl'
import { MassProduct } from 'domains/management/products/models'
import { productService, commonService, categoryService } from 'injectors'
import { triggerFileUpload } from 'utilities/file/upload'
import {
  Wrapper,
  Sided,
  Button,
  Break,
  Text,
  Panel,
  Image,
  Icon,
  Divider,
} from 'views/components'
import { ModalProps, Modal, DefaultModalService } from 'views/components/Modal'

import MassImportLoadingModal from '../MassImportLoadingmodal'
import MassImportTable from '../MassImportTable'
import { useLingui } from '@lingui/react'
import { t, Trans } from '@lingui/macro'

type MassImportFormProps = ModalProps

const MassImportForm: React.FC<MassImportFormProps> = props => {
  const [isUploaded, setUpload] = useState(false)
  const [fileName, setFileName] = useState('')
  const [massProducts, setMassProducts] = useState([] as MassProduct[])
  const { i18n } = useLingui()

  useEffect(() => {
    categoryService.getCategories()
  }, [])

  const handleMassImport = async () => {
    try {
      const file = await triggerFileUpload(undefined, {
        acceptedFile: '.xlsx, .xls, .csv',
      })
      const data = await productService.uploadMassProducts(file)
      if (data.massProducts.length > 500) {
        throw new Error('Data melebihi batas maksimal 500 produk')
      }
      setTimeout(() => {
        setUpload(true)
        setMassProducts(data.massProducts)
        setFileName(data.fileInfo.file.name)
      }, 1000)
    } catch (error) {
      commonService.setAlert({
        type: 'error',
        message: error.message || i18n._(t`Format Excel Tidak Sesuai`),
      })
    }
  }

  const renderMassImportGuide = () => (
    <Wrapper width='480px' isCenter>
      <Break height={32} />
      <Trans>
        <Text.Heading h={4} align='left' noMargin>
          Upload Informasi Produk
        </Text.Heading>
      </Trans>
      <Break height={24} />
      <Wrapper padding='16px' isCorner style={{ backgroundColor: '#fef6d9' }}>
        <Trans>
          <Text.Paragraph size={14} color='brownGray' noMargin>
            <strong>Jangan mengubah format template.</strong> POST. hanya akan
            menyimpan data yang yang sesuai dengan format template.
          </Text.Paragraph>
        </Trans>
      </Wrapper>
      <Break height={24} />
      <Panel.Card padding={16}>
        <Sided.Sided justifyContent='center' justify='center' gutter={24}>
          <Sided.Fixed minWidth={70}>
            <Image
              width='70px'
              height='70px'
              src={cdnImageUrl + 'images/img-inventory-list-empty.svg'}
              mode='contain'
            />
          </Sided.Fixed>
          <Sided.Remaining>
            <Trans>
              <Text.Paragraph size={14} noMargin>
                <a
                  href='https://ddh86g38imawj.cloudfront.net/files/Template%20Daftar%20Produk.xlsx'
                  download='Template Daftar Produk'
                >
                  <Text.Span color='blue'>Download template</Text.Span>
                </a>{' '}
                dan isi informasi produk Anda. Anda dapat memasukkan hingga 500
                produk sekaligus ke daftar inventori Anda.
              </Text.Paragraph>
            </Trans>
          </Sided.Remaining>
        </Sided.Sided>
      </Panel.Card>
      <Break height={40} />
      <Break height={20} />
      {isUploaded ? renderDataIsLoaded() : renderUploadForm()}
    </Wrapper>
  )

  const renderDataIsLoaded = () => (
    <Panel.Card padding={24}>
      <Wrapper align='center'>
        <Icon.File color='black' />
        <Text.Paragraph>{fileName}</Text.Paragraph>
        <Divider margin={16} />
      </Wrapper>
      <Break height={10} />
      <Trans>
        <Text.Paragraph
          noMargin
          color='blue'
          size={14}
          weight='bold'
          align='center'
          style={{ cursor: 'pointer' }}
          onClick={() => setUpload(false)}
        >
          Upload ulang
        </Text.Paragraph>
      </Trans>
    </Panel.Card>
  )

  const renderUploadForm = () => (
    <Wrapper
      verticalGap={16}
      padding='24px'
      isCorner
      align='center'
      style={{
        backgroundColor: 'rgba(59, 142, 243, 0.08)',
        border: '1px dashed #3b8ef3',
      }}
    >
      <Trans>
        <Text.Paragraph size={14} noMargin align='center'>
          Drop file template yang sudah Anda isi ke sini, atau…
        </Text.Paragraph>
      </Trans>
      <Button
        theme='secondary'
        label={i18n._(t`Upload Template`)}
        iconLeft={<Icon.Add color='white' />}
        onClick={handleMassImport}
      />
      <Trans>
        <Text.Paragraph size={12} color='semiGray'>
          Pastikan format file .csv, .xls, atau .xlsx. (maks. 200 produk)
        </Text.Paragraph>
      </Trans>
    </Wrapper>
  )

  const convertMassProduct = (massProducts: MassProduct[]) => {
    const newMassProducts = massProducts
    newMassProducts.forEach(x => {
      Object.keys(x).forEach(() => {
        x.name = String(x.name)
      })
      return x
    })
    return newMassProducts
  }

  const openMassImportLoadingModal = async () => {
    const uploadedSuccess = await DefaultModalService.open(
      MassImportLoadingModal,
      {
        massProducts: convertMassProduct(massProducts),
      }
    )
    if (uploadedSuccess) {
      const isChecked = await DefaultModalService.open(MassImportTable, {
        massProducts: massProducts,
        type: 'create',
      })
      if (isChecked) {
        props.dismiss()
      }
    }
  }

  const renderConfirmationButton = () => (
    <Wrapper
      width='100%'
      padding='16px 0px'
      backgroundColor='white'
      shadow='0 -2px 4px 0 rgba(0, 0, 0, 0.2)'
      position='fixed'
      style={{ bottom: '0px', left: '0px' }}
    >
      <Wrapper width='480px' isCenter>
        <Sided.Sided gutter={16} justifyContent='center'>
          <Sided.Remaining>
            <Button
              theme='netral'
              fullW
              label={i18n._(t`Batal`)}
              onClick={() => props.dismiss()}
            />
          </Sided.Remaining>
          <Sided.Remaining>
            <Button
              theme='primary'
              fullW
              disabled={!isUploaded}
              label={i18n._(t`Lanjut`)}
              onClick={() => openMassImportLoadingModal()}
            />
          </Sided.Remaining>
        </Sided.Sided>
      </Wrapper>
    </Wrapper>
  )

  return (
    <Modal dismiss={props.dismiss} type='full' canDismissOutside={false}>
      {renderMassImportGuide()}
      {renderConfirmationButton()}
    </Modal>
  )
}

export default MassImportForm
