import { Dict } from 'utilities/type'

interface InterfaceDefinition {
  name: string
  keys: Dict<string>
}

function resolveArray(
  dict: Dict<InterfaceDefinition>,
  name: string,
  arr: any[]
) {
  ensureDefinitionAvailable(dict, name)
  arr.forEach((item: any) => {
    generateModel(dict, name, item)
  })
}
function resolveObject(dict: Dict<InterfaceDefinition>, name: string, x: any) {
  ensureDefinitionAvailable(dict, name)
  const keys = dict[name].keys
  Object.keys(x).map(key => {
    const obj = x[key]
    const type = getType(key, obj)
    keys[key] = type
    if (isObject(obj) || isArray(obj)) {
      generateModel(dict, toObjectName(key), x[key])
    }
  })
}

function ensureDefinitionAvailable(
  dict: Dict<InterfaceDefinition>,
  name: string
) {
  if (!dict[name]) {
    dict[name] = {
      name,
      keys: {},
    }
  }
}

function generateModel(dict: Dict<InterfaceDefinition>, name: string, x: any) {
  if (isArray(x)) {
    resolveArray(dict, name, x as any[])
  } else if (isObject(x)) {
    resolveObject(dict, name, x)
  }
}

function isArray(obj: any): boolean {
  return !!obj && Array.isArray(obj)
}
function isObject(obj: any): boolean {
  return !!obj && typeof obj == 'object' && !Array.isArray(obj)
}

function toObjectName(name: string): string {
  return name[0].toUpperCase() + name.slice(1)
}

function getType(key: string, obj: any): string {
  if (isArray(obj)) {
    return toObjectName(key) + '[]'
  } else if (isObject(obj)) {
    return toObjectName(key)
  } else if (obj == null || obj == undefined) {
    return 'any'
  }
  return typeof obj
}

export function printInterfaceDefinition(name: string, x: any) {
  const dict: Dict<InterfaceDefinition> = {}
  generateModel(dict, name, x)
  let str = ''
  Object.keys(dict).forEach((name: string) => {
    const obj: InterfaceDefinition = dict[name]
    str += 'interface ' + obj.name + '{\n'
    Object.keys(obj.keys).forEach((k: string) => {
      str += '\t' + k + ': ' + obj.keys[k] + '\n'
    })
    str += '}\n\n'
  })
  return str
}

export function withLeadingZero(n: number, digit: number): string {
  const numberString: string = n.toString()
  const leadingZeroes: string =
    numberString.length < digit
      ? new Array<string>(digit - numberString.length + 1).join('0')
      : ''
  return leadingZeroes + numberString
}
