import React, { Component } from 'react'
import { Map, Marker, GoogleApiWrapper, InfoWindow } from 'google-maps-react'
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete'
import { Icon } from '..'
import { Wrapper } from 'views/components'
import './style.css'

export class MapContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      address: '',

      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
      currentLat: null,
      currentLng: null,
    }
  }

  componentDidMount() {
    this.getLocation()
  }

  getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.showPosition)
    } else {
      alert('Geolocation is not supported by this browser.')
    }
  }

  showPosition = position => {
    if (this.props.initLatitude && this.props.initLongitude) {
      this.setState({ currentLat: this.props.initLatitude })
      this.setState({ currentLng: this.props.initLongitude })

      this.props.actions.setFieldValue(
        x => (x.latitude = this.props.initLatitude)
      )
      this.props.actions.setFieldValue(
        x => (x.longitude = this.props.initLongitude)
      )
    } else {
      var lat = position.coords.latitude
      var lng = position.coords.longitude
      console.log(lat, lng)

      this.setState({ currentLat: lat })
      this.setState({ currentLng: lng })

      this.props.actions.setFieldValue(x => (x.latitude = lat))
      this.props.actions.setFieldValue(x => (x.longitude = lng))
    }
  }

  handleChange = address => {
    this.setState({ address })
  }

  handleSelect = address => {
    this.setState({ address })
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        this.setState({ currentLat: latLng.lat })
        this.setState({ currentLng: latLng.lng })

        this.props.actions.setFieldValue(x => (x.latitude = latLng.lat))
        this.props.actions.setFieldValue(x => (x.longitude = latLng.lng))
      })
      .catch(error => console.error('Error', error))
  }

  onMarkerClick = (props, marker, e) =>
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true,
    })

  onMapClicked = (ref, map, ev) => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null,
      })
    }

    const location = ev.latLng

    this.setState({ currentLat: location.lat() })
    this.setState({ currentLng: location.lng() })

    this.props.actions.setFieldValue(x => (x.latitude = location.lat()))
    this.props.actions.setFieldValue(x => (x.longitude = location.lng()))

    map.panTo(location)
  }

  render() {
    return (
      <div id='googleMaps'>
        <PlacesAutocomplete
          value={this.state.address}
          onChange={this.handleChange}
          onSelect={this.handleSelect}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div
              style={{
                position: 'absolute',
                zIndex: '99',
                left: '55px',
                top: '100px',
              }}
            >
              <Wrapper center>
                <Icon.Search
                  color='black'
                  style={{ position: 'absolute', top: '10px', left: '5px' }}
                />
                <input
                  id='gmaps-search-input'
                  spellcheck='false'
                  style={{
                    paddingLeft: '35px',
                    width: '465px',
                    height: '45px',
                    background: '#FFFFFF',
                    border: '1px solid #E0E0E0',
                    boxSizing: 'border-box',
                    borderRadius: '4px',
                    outline: 'none',
                  }}
                  {...getInputProps({
                    placeholder: 'Cari Titik Lokasi',
                    className: 'location-search-input',
                  })}
                />
              </Wrapper>
              <div
                className='autocomplete-dropdown-container'
                style={{ marginTop: '5px' }}
              >
                {suggestions.map(suggestion => {
                  const className = suggestion.active
                    ? 'suggestion-item--active'
                    : 'suggestion-item'
                  const style = suggestion.active
                    ? {
                        backgroundColor: '#fafafa',
                        cursor: 'pointer',
                        padding: '12.5px',
                        width: '465px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        borderRadius: '1px',
                      }
                    : {
                        backgroundColor: '#ffffff',
                        cursor: 'pointer',
                        padding: '12.5px',
                        width: '465px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        borderRadius: '1px',
                      }
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  )
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>

        {this.state.currentLat && this.state.currentLng && (
          <Map
            style={{ width: '566px', height: '300px', zIndex: 1 }}
            google={this.props.google}
            mapTypeControl={false}
            streetViewControl={false}
            initialCenter={{
              lat: this.state.currentLat,
              lng: this.state.currentLng,
            }}
            center={{
              lat: this.state.currentLat,
              lng: this.state.currentLng,
            }}
            onClick={this.onMapClicked}
            zoom={18}
            disableDefaultUI={true}
          >
            <Marker
              onClick={this.onMarkerClick}
              position={{
                lat: this.state.currentLat,
                lng: this.state.currentLng,
              }}
              name={this.state.address}
            />
            <InfoWindow
              marker={this.state.activeMarker}
              visible={this.state.showingInfoWindow}
            >
              <div>
                <h1>{this.state.selectedPlace.name}</h1>
              </div>
            </InfoWindow>
          </Map>
        )}
      </div>
    )
  }
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GMAPS_API_KEY,
})(MapContainer)
