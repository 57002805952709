import React from 'react'
import { Spin } from 'antd'
import './style.css'

export const Loading: React.FC<{}> = () => {
  return (
    <div className='spin-container'>
      <Spin />
    </div>
  )
}
