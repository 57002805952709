import {} from 'global'

export interface EmailSender {
  to: string
  from?: string
  subject: string
  body: string
}

export function sendEmail(emailSender: EmailSender) {
  window.open(
    `mailto:${emailSender.to}
        ?subject=${emailSender.subject}&body=${emailSender.body}`,
    '_top'
  )
}
