import { forEachDict } from 'utilities/converter/list'
import { Dict } from 'utilities/type'
import { REGEX } from 'constants/regex'

export interface ValidationSchema<T> {
  validate: (obj: T, context?: ValidationContext) => ValidationError[]
}

export interface ValidationError {
  path: string
  message: string
}

function validate<T extends Dict<any>>(
  obj: T,
  structure: ObjectDefinitionSchema<T>,
  context: ValidationContext
): ValidationError[] {
  let results: ValidationError[] = []
  forEachDict(obj, (key: string, val: any) => {
    const checker = structure[key]
    if (!checker) {
      return
    }
    checker.checks.forEach(c => {
      const childContext: ValidationContext = createChildContext(
        context,
        obj,
        key,
        context.path != '' ? '.' + key : key
      )
      results = results.concat(c(val, childContext))
    })
  })
  return results
}

export interface ValidationContext<TRoot = any, TParent = TRoot> {
  parentContext?: ValidationContext<TRoot>
  parent?: TParent
  root: TRoot
  path: string
}

function createChildContext(
  context: ValidationContext,
  parent: any,
  key: string,
  extendedPath: string
): ValidationContext {
  return {
    parent,
    parentContext: context,
    root: context.root,
    path: context.path + '' + extendedPath,
  }
}

export type CheckFunc<T, Parent = ValidationContext> = (
  obj: T,
  parent?: Parent
) => boolean

interface IValidation<T> {
  checks: ((obj: T, context: ValidationContext) => ValidationError[])[]
}

class AnyValidation<T> implements IValidation<T> {
  checks: ((obj: T, context?: ValidationContext) => ValidationError[])[] = []
  generateError(message: string, context: ValidationContext): ValidationError {
    return {
      message,
      path: context.path,
    }
  }
  test(message: string, check: CheckFunc<T>) {
    this.checks.push((obj: T, context: ValidationContext) => {
      if (!check(obj, context)) {
        return [this.generateError(message, context)]
      }
      return []
    })
    return this
  }
  required(message: string) {
    this.checks.push((obj: T, context: ValidationContext) => {
      if (!obj) {
        return [this.generateError(message, context)]
      }
      return []
    })
    return this
  }
  requiredAllowZero(message: string) {
    this.checks.push((obj: T, context: ValidationContext) => {
      if (!obj && Number(obj) !== 0) {
        return [this.generateError(message, context)]
      }
      return []
    })
    return this
  }
}
class StringValidation extends AnyValidation<string> {
  max(max: number, message: string) {
    this.checks.push((obj: string, context: ValidationContext) => {
      if (obj === undefined) {
        return []
      }
      if (obj.trim().length > max) {
        return [this.generateError(message, context)]
      }
      return []
    })
    return this
  }
  min(min: number, message: string) {
    this.checks.push((obj: string, context: ValidationContext) => {
      if (obj === undefined || obj === '' || obj === null) {
        return []
      }
      if (obj.trim().length < min) {
        return [this.generateError(message, context)]
      }
      return []
    })
    return this
  }
  minNoTrim(min: number, message: string) {
    this.checks.push((obj: string, context: ValidationContext) => {
      if (obj === undefined || obj === '' || obj === null) {
        return []
      }
      if (obj.length < min) {
        return [this.generateError(message, context)]
      }
      return []
    })
    return this
  }
  matches(reg: RegExp, message: string) {
    this.checks.push((obj: string, context: ValidationContext) => {
      if (obj === undefined || obj === '' || obj === null) {
        return []
      }
      if (!reg.test(obj)) {
        return [this.generateError(message, context)]
      }
      return []
    })
    return this
  }
  email(message: string) {
    return this.matches(REGEX.EMAIL, message)
  }

  banlist(bannedCharacters: string[], message: string) {
    this.checks.push((value: string, context: ValidationContext) => {
      if (bannedCharacters.some(character => value.includes(character))) {
        return [
          this.generateError(
            `${message}: ${bannedCharacters.join(',')}`,
            context
          ),
        ]
      }
      return []
    })
    return this
  }
}
class NumberValidation extends AnyValidation<number> {
  max(max: number, message: string) {
    this.checks.push((obj: number, context: ValidationContext) => {
      if (obj === undefined) {
        return []
      }
      if (obj > max) {
        return [this.generateError(message, context)]
      }
      return []
    })
    return this
  }
  min(min: number, message: string) {
    this.checks.push((obj: number, context: ValidationContext) => {
      if (obj === undefined) {
        return []
      }
      if (obj < min) {
        return [this.generateError(message, context)]
      }
      return []
    })
    return this
  }
}

type ObjectDefinitionSchema<T> = {
  [K in keyof Partial<T>]: IValidation<T[K]> | undefined
}

type ObjectValidationShape<T> = IValidation<T> & ValidationSchema<T>

class ObjectDefinition<T extends Dict<any>> {
  shape(structure: ObjectDefinitionSchema<T>): ObjectValidationShape<T> {
    return {
      validate: (val: T, context?: ValidationContext) => {
        context = context || {
          path: '',
          root: val,
        }
        return validate(val, structure, context)
      },
      ...new ObjectValidation(structure),
    }
  }
}
class ObjectValidation<T extends Dict<any>> extends AnyValidation<T> {
  structure: ObjectDefinitionSchema<T>
  constructor(structure: ObjectDefinitionSchema<T>) {
    super()
    this.structure = structure
    this.checks.push((obj: T, context: ValidationContext) => {
      return validate(obj, structure, context)
    })
  }
}

class ArrayDefinition<T> {
  of(shape: ObjectValidationShape<T>) {
    return new ArrayValidation(shape)
  }
}

class ArrayValidation<T extends Dict<any>> extends AnyValidation<T[]> {
  shape: ObjectValidationShape<T>
  constructor(shape: ObjectValidationShape<T>) {
    super()
    this.shape = shape
    this.checks.push((list: T[], context: ValidationContext) => {
      let results: ValidationError[] = []
      list.forEach((x: T, index: number) => {
        const elementContext = createChildContext(
          context,
          list,
          index.toString(),
          `[${index}]`
        )
        const result = this.shape.validate(x, elementContext)
        results = results.concat(result)
      })
      return results
    })
  }
}

const SchemaValidation = {
  string: () => new StringValidation(),
  object: <T>() => new ObjectDefinition<T>(),
  array: <T>() => new ArrayDefinition<T>(),
  number: () => new NumberValidation(),
}

export { SchemaValidation }

/**
 *  SAMPLE
 * 
export const testSchema = SchemaValidation.object<{
    name: string,
    id: string,
    address: {
        city: string,
        district: string
    },
    history: { time: string }[]
}>().shape({
    name: Ashiap.string().max(25, "NAME Max 25 karakter"),
    id: Ashiap.string().max(25, "ID Max 25 karakter"),
    address: Ashiap.object().shape({
        city: Ashiap.string().min(2, "CITI Min 2 karakter"),
        district: Ashiap.string().min(3, "DISTRICT min 3 karakter"),
    }),
    history: Ashiap.array().of(
        Ashiap.object().shape({
            time: Ashiap.string().max(15, "TIME Max 15 karakter")
        })
    )
})

 * 
 */
