import { BaseReportingService } from '.'
import { ReportingFilter, Order, OrderQueryParam } from '../models'

import { toQueryISOString } from 'utilities/converter/date'

export interface IOrderReportingService {
  getOrderReport(filter?: ReportingFilter): Promise<Order[]>
}

export class OrderReportingService
  extends BaseReportingService
  implements IOrderReportingService
{
  public async getOrderReport(filter?: ReportingFilter) {
    try {
      const queryParams: OrderQueryParam = {
        outletId: filter.selectedOutletId || '',
        fromDate: filter.dateRange
          ? toQueryISOString(filter.dateRange.from, true)
          : '',
        toDate: filter.dateRange
          ? toQueryISOString(filter.dateRange.to, true)
          : '',
      }
      return await this.api.newReporting.getOrderReport(queryParams)
    } catch (error) {
      throw error
    }
  }
}
