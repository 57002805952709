import { ICommonStorage } from 'domains/commons/contracts/storage'
import { BaseService } from 'domains/commons/services'
import { Api } from 'domains/contracts/api'

import { ISubscriptionStorage } from '../contracts/storage'
import {
  SubscriptionPackagePricing,
  SubscriptionVoucher,
  SubscriptionPackage,
  SubscriptionMethod,
  SubscriptionRequest,
  Subscription,
  VoucherQueryParam,
  SubscriptionAlert,
  SubscriptionInvoice,
} from '../model'

export class SubscriptionService
  extends BaseService
  implements ISubscriptionService
{
  subscriptionStorage: ISubscriptionStorage

  constructor(
    commonStorage: ICommonStorage,
    api: Api,
    subscriptionStorage: ISubscriptionStorage
  ) {
    super(commonStorage, api)
    this.subscriptionStorage = subscriptionStorage
  }

  public checkSubscriptionAlert = async () => {
    try {
      const response = await this.api.subscriptions.checkSubscriptionAlert()
      this.subscriptionStorage.setSubscriptionAlert(response)
      return response
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  public getSubcriptionPackage = async () => {
    try {
      const response: SubscriptionPackage[] =
        await this.api.subscriptions.getSubscriptionPackages()
      const availablePackages: SubscriptionPackagePricing[] = response.find(
        x => x.name == 'Premium'
      ).packagePricings
      this.subscriptionStorage.setSubscriptionPackage(response)
      this.subscriptionStorage.setAvailablePackage(availablePackages)
    } catch (error) {
      throw error
    }
  }

  public getSubscriptionMethod = async () => {
    try {
      const response: SubscriptionMethod[] =
        await this.api.subscriptions.getSubscriptionMethod()
      this.subscriptionStorage.setSubscriptionMethod(response)
    } catch (error) {
      throw error
    }
  }

  public checkVoucher = async (
    code: string,
    packagePricingId: VoucherQueryParam
  ): Promise<SubscriptionVoucher> => {
    try {
      return await this.api.subscriptions.getVoucher(code, packagePricingId)
    } catch (error) {
      throw error
    }
  }

  public applySubscription = async (
    requestBody: SubscriptionRequest
  ): Promise<Subscription> => {
    try {
      const response = await this.api.subscriptions.applySubscription(
        requestBody
      )
      const subscription = await this.getSubscriptionDetail(response.id)
      this.subscriptionStorage.setSubscription(subscription)

      return subscription
    } catch (error) {
      throw error
    }
  }

  public getSubscriptionDetail = async (id: string): Promise<Subscription> => {
    const subscription = await this.api.subscriptions.getSubscriptionDetail(id)
    this.subscriptionStorage.setSubscription(subscription)
    return subscription
  }

  public getSubscriptions = async (): Promise<Subscription[]> => {
    const subscriptions = await this.api.subscriptions.getSubscriptions()
    this.subscriptionStorage.setSubscriptions(subscriptions)
    return subscriptions
  }

  public cancelSubscription = async (id: string) => {
    try {
      await this.api.subscriptions.cancelSubscription(id)
      this.subscriptionStorage.removeSubscription(id)
    } catch (error) {
      throw error
    }
  }

  public getSubscriptionInvoice = async (
    id: string
  ): Promise<SubscriptionInvoice> => {
    return await this.api.subscriptions.getSubscriptionInvoice(id)
  }
}

export interface ISubscriptionService {
  getSubscriptions: () => Promise<Subscription[]>
  getSubscriptionDetail: (id: string) => Promise<Subscription>
  getSubcriptionPackage: () => Promise<void>
  getSubscriptionMethod: () => Promise<void>
  checkSubscriptionAlert: () => Promise<SubscriptionAlert>
  checkVoucher: (
    code: string,
    packagePricingId: VoucherQueryParam
  ) => Promise<SubscriptionVoucher>
  applySubscription: (query: SubscriptionRequest) => Promise<Subscription>
  cancelSubscription: (id: string) => void
  getSubscriptionInvoice: (id: string) => Promise<SubscriptionInvoice>
}
