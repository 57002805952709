import { ManagementState } from 'domains/management/state'
import { combineReducers } from 'redux'
import { CategoryReducer } from './category/reducer'
import { ProductReducer } from './products/reducer'
import { ServiceTypeReducer } from './serviceType/reducer'
import { SingleReducer } from 'utilities/redux/reducer'
import { DiscountReducer } from './discounts/reducer'
import { DeviceReducer } from './device/reducer'
import { CustomerReducer } from './customer/reducer'
import { PaymentMethodReducer } from './paymentMethod/reducers'
import { EmployeeReducer } from './employee/reducer'
import { OutletReducer } from './outlet/reducer'
import { ShiftSettingReducer } from './shift/reducer'
import { ExpenseReducer } from './expense/reducer'
import { RoleReducer } from './role/reducer'

export class ManagementReducer {
  build(): SingleReducer<ManagementState> {
    return combineReducers<ManagementState>({
      shift: new ShiftSettingReducer().build(),
      category: new CategoryReducer().build(),
      product: new ProductReducer().build(),
      serviceType: new ServiceTypeReducer().build(),
      outlet: new OutletReducer().build(),
      discount: new DiscountReducer().build(),
      device: new DeviceReducer().build(),
      paymentMethod: new PaymentMethodReducer().build(),
      customer: new CustomerReducer().build(),
      employee: new EmployeeReducer().build(),
      expense: new ExpenseReducer().build(),
      role: new RoleReducer().build(),
    })
  }
}
