import { BaseStorage } from 'storages/base'
import { ICustomerStorage } from 'domains/management/customer/contracts/storage'
import { Dict } from 'utilities/type'
import {
  SET_CUSTOMER,
  SET_CUSTOMER_QUERY_PARAMS,
  SET_CUSTOMER_REACHED_BOTTOM,
  SET_LOADING_MORE_CUSTOMER,
  PUSH_CUSTOMER,
  UNSHIFT_CUSTOMER,
} from './actions'
import {
  Customer,
  CustomerQueryParams,
} from 'domains/management/customer/models'

export class CustomerStorage extends BaseStorage implements ICustomerStorage {
  setCustomers(categoryDict: Dict<Customer>): void {
    this.dispatch(SET_CUSTOMER, categoryDict)
  }

  setCustomerListRequest(request: CustomerQueryParams): void {
    this.dispatch(SET_CUSTOMER_QUERY_PARAMS, request)
  }

  setCustomerListReachBottom(hasReachedBottom: boolean): void {
    this.dispatch(SET_CUSTOMER_REACHED_BOTTOM, hasReachedBottom)
  }

  setLoadingMoreCustomer(isLoadingMoreCustomer: boolean): void {
    this.dispatch(SET_LOADING_MORE_CUSTOMER, isLoadingMoreCustomer)
  }

  pushCustomer(customers: Dict<Customer>): void {
    this.dispatch(PUSH_CUSTOMER, customers)
  }

  unshiftCustomer(customers: Dict<Customer>): void {
    this.dispatch(UNSHIFT_CUSTOMER, customers)
  }
}
