import {
  SET_CARD_SUMMARY,
  TOGGLE_CARD_VISIBILITY,
  SET_ALL_CARD_VISIBLE,
  SET_HOME_FILTER_DATE_RANGE,
  SET_HOME_FILTER_OUTLET_ID,
  SET_HOME_FILTER_CALENDAR_DROPDOWN,
} from './actions'
import { BaseStorage } from 'storages/base'
import { IHomeStorage } from 'domains/home/contracts/storage'
import { CardData } from 'domains/home/states'
import { Dict } from 'utilities/type'
import { DateRange } from 'domains/commons/models'

export class HomeStorage extends BaseStorage implements IHomeStorage {
  setInformationSummary(cardInformationSummary: Dict<CardData>): void {
    this.dispatch(SET_CARD_SUMMARY, cardInformationSummary)
  }
  toggleCardVisibility(key: string): void {
    this.dispatch(TOGGLE_CARD_VISIBILITY, key)
  }
  setAllVisible(): void {
    this.dispatch(SET_ALL_CARD_VISIBLE)
  }
  setFilterDateRange(dateRange: DateRange): void {
    this.dispatch(SET_HOME_FILTER_DATE_RANGE, dateRange)
  }
  setFilterOutletId(selectedOutletId: string): void {
    this.dispatch(SET_HOME_FILTER_OUTLET_ID, selectedOutletId)
  }
  setFilterCalendarDropdown(calendarType: string): void {
    this.dispatch(SET_HOME_FILTER_CALENDAR_DROPDOWN, calendarType)
  }
}
