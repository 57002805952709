import { DateRange } from 'domains/commons/models'
import { BaseReportingService } from '.'
import { ICommonStorage } from 'domains/commons/contracts/storage'
import { IReportingStorage } from '../contracts/storage'
import { IOutletStorage } from 'domains/management/outlets/contracts/storage'
import { toDict } from 'utilities/converter/list'
import { Api } from 'domains/contracts/api'

export class ReportingFilterService
  extends BaseReportingService
  implements IReportingFilterService
{
  outletStorage: IOutletStorage

  constructor(
    commonStorage: ICommonStorage,
    api: Api,
    reportingStorage: IReportingStorage,
    outletStorage: IOutletStorage
  ) {
    super(commonStorage, api, reportingStorage)
    this.outletStorage = outletStorage
  }

  public getOutletListForReporting = async () => {
    try {
      const data = await this.api.management.outlet.getOutlets()
      this.outletStorage.setOutlets(toDict(data, x => x.id))
    } catch (error) {
      console.error(error)
    }
  }

  public selectOutletReportFilter = async (outletId?: string) => {
    this.reportingStorage.setOutletIdReportFilter(outletId)
  }

  public setDateRangeReportFilter = async (dateRange: DateRange) => {
    this.reportingStorage.setDateRangeReportFilter(dateRange)
  }

  public setShowTypeReportFilter = async (
    showType: 'summary' | 'daily' | 'weekly' | 'monthly'
  ) => {
    this.reportingStorage.setShowTypeReportFilter(showType)
  }

  public setSalesTypeReportFilter = async (saleType: string) => {
    this.reportingStorage.setSaleTypeReportFilter(saleType)
  }

  public setExpenseTypeReportFilter = async (expenseType: string) => {
    this.reportingStorage.setExpenseTypeReportFilter(expenseType)
  }

  public resetFilter = async () => {
    this.reportingStorage.resetFilter()
  }
}

export interface IReportingFilterService {
  getOutletListForReporting: () => Promise<void>
  selectOutletReportFilter: (outletId?: string) => Promise<void>
  setDateRangeReportFilter: (dateRange: DateRange) => Promise<void>
  setShowTypeReportFilter: (
    showType: 'summary' | 'daily' | 'weekly' | 'monthly'
  ) => Promise<void>
  setSalesTypeReportFilter: (saleType: string) => Promise<void>
  setExpenseTypeReportFilter: (expenseType: string) => Promise<void>
  resetFilter: () => Promise<void>
}
