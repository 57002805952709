import { BaseReportingService } from '.'

export class CancellationReportingService
  extends BaseReportingService
  implements ICancellationReportingService
{
  public getCancellationListReporting = async () => {
    const cancellationReports =
      await this.api.reporting.getCancellationsReports()
    this.reportingStorage.setCancellationReporting(cancellationReports)
  }
}

export interface ICancellationReportingService {
  getCancellationListReporting: () => Promise<void>
}
