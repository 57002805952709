import RegisterState, { RegisterTempData } from 'domains/auth/register/states'
import { StateActions, Reducer } from 'utilities/redux/reducer'
import { SET_REGISTER_TEMP_DATA } from './action'

export class RegisterReducer extends Reducer<RegisterState> {
  constructor() {
    super({
      registerTempData: {} as RegisterTempData,
      registerErrorMsg: '',
    })
  }

  public setRegisterTempData(
    state: RegisterState,
    registerTempData: RegisterTempData
  ): RegisterState {
    return {
      ...state,
      registerTempData,
    }
  }

  get actions(): StateActions<RegisterState> {
    return {
      [SET_REGISTER_TEMP_DATA]: this.setRegisterTempData,
    }
  }
}
