export const toClassifiedNumber = (
  accountNumber: string,
  shownDigit: number
) => {
  let classifiedNumber = ''
  const hiddenNumberLength = accountNumber.length - shownDigit
  const shownNumber = accountNumber.slice(
    hiddenNumberLength,
    accountNumber.length
  )

  for (let i = 0; i < hiddenNumberLength; i++) {
    classifiedNumber += 'X'
  }

  return classifiedNumber + shownNumber
}
