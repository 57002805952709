import * as React from 'react'
import classNames from 'classnames'
import { BaseProps, BaseParentProps } from '../props'
import './style.scss'
import { AlignmentClass } from '../const'

interface FixProps extends BaseParentProps, BaseProps {
  minHeight?: number
  align?: 'left' | 'center' | 'right'
}
const Fixed = (props: FixProps) => {
  const className = classNames(
    props.className || '',
    'post-v-sided-fix',
    'post-v-sided-fix-text-align-' + (props.align || 'left')
  )
  const style: React.CSSProperties = {
    minHeight: props.minHeight,
  }
  return (
    <div style={style} className={classNames(className)}>
      <React.Fragment>{props.children}</React.Fragment>
    </div>
  )
}

interface RemainingProps extends BaseParentProps, BaseProps {
  align?: 'center' | 'left' | 'right'
  notScroll?: boolean
  style?: React.CSSProperties
}

const Remaining = (props: RemainingProps) => {
  const basicClassNames = classNames(
    props.className || '',
    'post-v-sided-remaining',
    'post-v-sided-remaining-text-align-' + (props.align || 'left'),
    props.notScroll ? 'post-v-sided-remaining-without-overflow' : ''
  )
  const style: React.CSSProperties = {
    textAlign: props.align,
    ...props.style,
  }

  return (
    <section style={style} className={basicClassNames}>
      <React.Fragment>{props.children}</React.Fragment>
    </section>
  )
}

interface SidedProps extends BaseParentProps, BaseProps {
  gutter?: 0 | 8 | 16 | 24 | 32 | 36 | 40 | 64
  justify?: 'center' | 'bottom' | 'top' | 'normal'
  justifyContent?: AlignmentClass
  onClick?: () => any
}

const VSided: React.FC<SidedProps> = (props: SidedProps) => {
  const className = classNames(
    props.className || '',
    'post-v-sided',
    'post-v-sided-gutter-' + (props.gutter != undefined ? props.gutter : 8),
    'post-v-sided-justify-' + (props.justify || 'normal'),
    'post-v-sided-justify-content-' + (props.justifyContent || 'left')
  )
  return (
    <div className={className} onClick={props.onClick}>
      <React.Fragment>{props.children}</React.Fragment>
    </div>
  )
}

export default {
  VSided,
  Fixed,
  Remaining,
}
