import React from 'react'
import { Modal, ModalProps } from 'views/components/Modal'
import { Panel, Sided, Text, Icon, Break, Button } from 'views/components'

interface Props extends ModalProps {
  title: string
  body: string
}

const DeleteConfirmationModal: React.FC<Props> = props => {
  return (
    <Modal
      width={'470px'}
      dismiss={props.dismiss}
      type='blank'
      canDismissOutside={false}
    >
      <Panel.Panel>
        <Panel.Header>
          <Sided.Sided justifyContent='center' justify='center'>
            <Sided.Remaining>
              <Text.Heading h={5} color='black'>
                {props.title}
              </Text.Heading>
            </Sided.Remaining>
            <Sided.Fixed>
              <Icon.Close
                size={24}
                style={{ cursor: 'pointer' }}
                onClick={() => props.dismiss()}
              />
            </Sided.Fixed>
          </Sided.Sided>
        </Panel.Header>
        <Panel.Content>
          {props.body}
          <Break height={32} />
          <Sided.Sided gutter={16}>
            <Sided.Remaining>
              <Button
                size='large'
                theme='netral'
                type='button'
                label='Batal'
                fullW={true}
                onClick={() => props.dismiss()}
              />
            </Sided.Remaining>
            <Sided.Remaining>
              <Button
                size='large'
                theme='danger'
                type='submit'
                label='Hapus'
                fullW={true}
                onClick={() => props.close(true)}
              />
            </Sided.Remaining>
          </Sided.Sided>
        </Panel.Content>
      </Panel.Panel>
    </Modal>
  )
}

export default DeleteConfirmationModal
