export function toCurrency(
  currencyType: string,
  value: number,
  formatType = 'id-ID'
) {
  return `${currencyType} ${new Intl.NumberFormat(formatType).format(value)}`
}

export function toNominal(value: number, formatType = 'de-DE') {
  return new Intl.NumberFormat(formatType).format(value)
}
