import React from 'react'
import { isAnyOfTheseFeaturesEnabled } from 'config'
import { FeatureCheckFunc } from 'config/def'

interface FeatureFlagProps {
  featureCheck: FeatureCheckFunc
}

const FeatureFlag: React.FC<FeatureFlagProps> = props => {
  const isEnabled = isAnyOfTheseFeaturesEnabled(props.featureCheck)

  return isEnabled && <React.Fragment>{props.children}</React.Fragment>
}

export default FeatureFlag
