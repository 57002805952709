import { Reducer, SingleReducer } from 'utilities/redux/reducer'
import { KeyedValue, Dict } from 'utilities/type'

import CommonState, {
  AlertConfig,
  QrisModalConfig,
} from 'domains/commons/states'
import {
  MODAL_LAYER_SET,
  MODAL_LAYER_REMOVE,
  MODAL_LAYER_PUSH,
  SET_LOADING,
  SET_ALERT_CONFIG,
  QRIS_BANNER_VISIBLE,
  QRIS_MODAL,
} from './actions'

export class CommonReducer extends Reducer<CommonState> {
  constructor() {
    super({
      loading: {},
      visible: {},
      modalComponents: [],
      qrisBannerVisible: false,
      qrisModal: { visible: false },
    })
  }

  public setLoading(
    state: CommonState,
    payload: KeyedValue<boolean>
  ): CommonState {
    return {
      ...state,
      loading: {
        ...state.loading,
        [payload.key]: payload.value,
      },
    }
  }

  public setAlertConfig(state: CommonState, alert?: AlertConfig): CommonState {
    return {
      ...state,
      alert,
    }
  }

  public setModalLayer(
    state: CommonState,
    modalComponents: any[]
  ): CommonState {
    return {
      ...state,
      modalComponents,
    }
  }

  public removeModalLayer(state: CommonState, id: string): CommonState {
    const modalComponents = state.modalComponents.filter(x => x.id != id)
    return {
      ...state,
      modalComponents,
    }
  }

  public pushModalLayer(state: CommonState, mc: { id: string }): CommonState {
    return {
      ...state,
      modalComponents: [...state.modalComponents, mc],
    }
  }

  public setQrisBannerVisible(
    state: CommonState,
    qrisBannerVisible: boolean
  ): CommonState {
    return {
      ...state,
      qrisBannerVisible,
    }
  }

  public setQrisModal(
    state: CommonState,
    qrisModal: QrisModalConfig
  ): CommonState {
    return {
      ...state,
      qrisModal,
    }
  }

  get actions(): Dict<SingleReducer<CommonState>> {
    return {
      [MODAL_LAYER_SET]: this.setModalLayer,
      [MODAL_LAYER_REMOVE]: this.removeModalLayer,
      [MODAL_LAYER_PUSH]: this.pushModalLayer,
      [SET_LOADING]: this.setLoading,
      [SET_ALERT_CONFIG]: this.setAlertConfig,
      [QRIS_BANNER_VISIBLE]: this.setQrisBannerVisible,
      [QRIS_MODAL]: this.setQrisModal,
    }
  }
}
