const authRoute = {
  LOGIN: '/auth/login',
  REGISTER_TOKEN: '/auth/register',
  REGISTER_OTP: '/auth/register/otp',
  REGISTER_SUCCESS: '/auth/register/success',
  REGISTER_SUCCESS_V2: '/auth/register/success-v2',
  FORGOT_PASSWORD: '/auth/forgot-password',
  FORGOT_PASSWORD_OTP: '/auth/forgot-password/confirm-otp',
  CREATE_NEW_PASSWORD: '/auth/create-new-password',
  CREATE_NEW_EMPLOYEE: '/auth/register/:role/:token',
  CREATE_STAFF_PASSWORD_SUCCESS: '/auth/register/employee/staff/success',
}

export default authRoute
