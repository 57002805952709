import React from 'react'
import ReactPDF, {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer'
import { SubscriptionInvoice } from 'domains/subscriptions/model'

import emailIcon from './assetsPdf/email.png'
import facebookIcon from './assetsPdf/facebook.png'
import instagramIcon from './assetsPdf/instagram.png'
import phoneIcon from './assetsPdf/phone.png'
import logo from './assetsPdf/post-black.png'
import youtubeIcon from './assetsPdf/youtube.png'
import fontLight from './assetsPdf/font/FazzNeue-Light.ttf'
import fontLightItalic from './assetsPdf/font/FazzNeue-LightItalic.ttf'
import fontRegular from './assetsPdf/font/FazzNeue-Regular.ttf'
import fontItalic from './assetsPdf/font/FazzNeue-Italic.ttf'
import fontMedium from './assetsPdf/font/FazzNeue-Medium.ttf'
import fontMediumItalic from './assetsPdf/font/FazzNeue-MediumItalic.ttf'
import fontBold from './assetsPdf/font/FazzNeue-Bold.ttf'
import fontBoldItalic from './assetsPdf/font/FazzNeue-BoldItalic.ttf'
import fontHeavy from './assetsPdf/font/FazzNeue-Heavy.ttf'
import fontHeavyItalic from './assetsPdf/font/FazzNeue-HeavyItalic.ttf'
import { toDateString } from 'utilities/converter/date'
import { toCurrency } from 'utilities/converter/currency'

interface InvoiceDocumentProps {
  invoiceData: SubscriptionInvoice
}

const InvoiceDocument = ({ invoiceData }: InvoiceDocumentProps) => (
  <Document>
    <Page style={styles.page} size='A4'>
      <View style={styles.flexVertical}>
        <View style={{ flex: 1, padding: '51px 44px 0 44px' }}>
          <View style={styles.flexHorizontal}>
            <View style={{ flex: 1 }}>
              <Image style={{ width: '100px', height: '28px' }} src={logo} />
            </View>
            <View>
              <Text style={{ fontSize: '24pt', fontWeight: 'bold' }}>
                Invoice
              </Text>
            </View>
          </View>
          <View style={{ height: '39px' }} />
          <View>
            <Text
              style={{
                fontSize: '14pt',
                fontWeight: 'bold',
                marginBottom: '4px',
              }}
            >
              {invoiceData.businessName}
            </Text>
            <Text style={{ fontSize: '10pt', marginBottom: '4px' }}>
              {toDateString(invoiceData.invoiceDate, 'dddd, DD MMMM YYYY')}
            </Text>
            <Text style={{ fontSize: '10pt', marginBottom: '4px' }}>
              No. Invoice {invoiceData.invoiceNo}
            </Text>
          </View>
          <View style={{ height: '39px' }} />
          <View>
            <Text style={{ marginBottom: '12px', fontSize: '12pt' }}>
              Transaksi
            </Text>
            <View style={{ ...styles.flexHorizontal, marginBottom: '12px' }}>
              <View
                style={{ ...styles.flexHorizontal, flex: 1, flexWrap: 'wrap' }}
              >
                <Text style={{ fontWeight: 'bold', fontSize: '14pt' }}>
                  {invoiceData.packagePricingLabel}{' '}
                </Text>
                <Text style={{ fontSize: '14pt' }}>
                  (Masa Berlaku{' '}
                  {toDateString(invoiceData.startDate, 'DD MMMM YYYY')} -{' '}
                  {toDateString(invoiceData.endDate, 'DD MMMM YYYY')})
                </Text>
              </View>
              <View>
                <Text style={{ fontSize: '14pt' }}>
                  {toCurrency('Rp', invoiceData.packagePrice)}
                </Text>
              </View>
            </View>
            {invoiceData.voucherCode && (
              <View style={{ ...styles.flexHorizontal, marginBottom: '12px' }}>
                <View style={{ flex: 1 }}>
                  <Text
                    style={{
                      paddingLeft: '24px',
                      fontSize: '14pt',
                      fontStyle: 'italic',
                    }}
                  >
                    {invoiceData.voucherCode}
                  </Text>
                </View>
                <View>
                  <Text style={{ fontSize: '14pt', color: 'red' }}>
                    -{toCurrency('Rp', invoiceData.voucherAmount)}
                  </Text>
                </View>
              </View>
            )}
          </View>
          <View style={styles.divider} />
          <View>
            <View style={{ ...styles.flexHorizontal, marginBottom: '12px' }}>
              <View style={{ flex: 1 }}>
                <Text
                  style={{
                    fontSize: '14pt',
                    fontWeight: 'bold',
                    marginBottom: '2px',
                  }}
                >
                  TOTAL
                </Text>
                <Text style={{ fontSize: '10pt' }}>
                  *(DPP {toCurrency('Rp', invoiceData.dpp)}, PPN 10%{' '}
                  {toCurrency('Rp', invoiceData.ppn)})
                </Text>
              </View>
              <View>
                <Text style={{ fontSize: '14pt', fontWeight: 'bold' }}>
                  {toCurrency('Rp', invoiceData.paymentAmount)}
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.divider} />
        </View>
        <View
          style={{
            backgroundColor: '#1d1d1f',
            padding: '24px 44px',
            color: '#fff',
          }}
        >
          <View style={styles.flexHorizontal}>
            <View style={{ flex: 1 }}>
              <Text
                style={{
                  fontSize: '14pt',
                  fontWeight: 'bold',
                  marginBottom: '4px',
                }}
              >
                PT. Fazzmart Teknologi Nusantara
              </Text>
              <Text style={{ fontSize: '10pt', marginBottom: '2px' }}>
                Jl. DR. Ide Anak Agung Gde Agung RT 05 / RW 02, Kuningan Timur,
              </Text>
              <Text style={{ fontSize: '10pt', marginBottom: '14px' }}>
                Kecamatan Setiabudi, Daerah Khusus Ibukota – Jakarta 12950
              </Text>
              <View style={styles.flexHorizontal}>
                <View style={{ ...styles.flexHorizontal, marginRight: '28px' }}>
                  <Image
                    style={{
                      width: '16px',
                      height: 'auto',
                      marginRight: '10px',
                    }}
                    src={phoneIcon}
                  />
                  <Text style={{ fontSize: '10pt' }}>+62 21-5071-1700</Text>
                </View>
                <View style={styles.flexHorizontal}>
                  <Image
                    style={{
                      width: '16px',
                      height: 'auto',
                      marginRight: '10px',
                    }}
                    src={emailIcon}
                  />
                  <Text style={{ fontSize: '10pt' }}>cs@post.app</Text>
                </View>
              </View>
            </View>
            <View
              style={{
                ...styles.flexHorizontal,

                alignItems: 'flex-end',
                height: '100%',
              }}
            >
              <Image
                style={{ width: '25px', height: 'auto', marginRight: '12px' }}
                src={facebookIcon}
              />
              <Image
                style={{ width: '25px', height: 'auto', marginRight: '12px' }}
                src={instagramIcon}
              />
              <Image
                style={{ width: '25px', height: 'auto', marginRight: '12px' }}
                src={youtubeIcon}
              />
            </View>
          </View>
        </View>
      </View>
    </Page>
  </Document>
)

Font.register({
  family: 'FazzNeue',
  fonts: [
    { src: fontLight, fontWeight: 'light' },
    { src: fontLightItalic, fontWeight: 'light', fontStyle: 'italic' },
    { src: fontRegular, fontWeight: 'normal' },
    { src: fontItalic, fontStyle: 'italic' },
    { src: fontMedium, fontWeight: 'medium' },
    { src: fontMediumItalic, fontWeight: 'medium', fontStyle: 'italic' },
    { src: fontBold, fontWeight: 'bold' },
    { src: fontBoldItalic, fontWeight: 'bold', fontStyle: 'italic' },
    { src: fontHeavy, fontWeight: 'heavy' },
    { src: fontHeavyItalic, fontWeight: 'heavy', fontStyle: 'italic' },
  ],
})

const styles = StyleSheet.create({
  page: { fontFamily: 'FazzNeue', fontWeight: 'normal' },
  flexVertical: { display: 'flex', flexDirection: 'column', height: '100%' },
  flexHorizontal: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  divider: {
    borderTop: '2pt solid #000',
    width: '100%',
    marginBottom: '12px',
  },
} as ReactPDF.Styles)

export default InvoiceDocument
