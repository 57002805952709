import React from 'react'
import './style.css'
import classNames from 'classnames'

interface Props {
  content?: JSX.Element
  onChange?: (isChecked: boolean) => any
  checked?: boolean
  noMargin?: boolean
  style?: React.CSSProperties
  isDisabled?: boolean
  align?: 'left' | 'right'
}

const Checkbox = (props: Props) => {
  const onChange = (isChecked: boolean) => {
    props.onChange && props.onChange(isChecked)
  }

  const className = classNames(
    'post-checkbox',
    props.noMargin ? 'post-checkbox-no-margin' : ''
  )

  const checkmarkClassName = classNames('checkmark', props.align)

  return (
    <label className={className} data-testid='checkbox' style={props.style}>
      {props.content}
      <input
        type='checkbox'
        onChange={evt => onChange(evt.target.checked)}
        checked={props.checked}
        disabled={props.isDisabled}
      />
      <span className={checkmarkClassName}></span>
    </label>
  )
}

export default Checkbox
export { Checkbox }
