import { featureCheck, getAppConfig, isAnyOfTheseFeaturesEnabled } from 'config'
import { toDateString } from 'utilities/converter/date'

const smartech = (window as any).smartech

const isNotLocalMode = getAppConfig().mode !== 'local'
const isNetcoreActive =
  isNotLocalMode &&
  isAnyOfTheseFeaturesEnabled(featureCheck.home.netcore) &&
  smartech

const initialize = (): void => {
  if (isNetcoreActive) {
    smartech('create', 'ADGMOT35CHFLVDHBJNIG50K969FH3FQ2M0RCID7OPDOU5KNEA1JG')
    smartech('register', '09b5243b12aa0a687dce3d42e7f5f64d')
  }
}

export interface NetcoreContact {
  userId: string
  email: string
  mobile?: string
  name: string
  registerDate?: string
  premiumStartDate?: string
  premiumEndDate?: string
  userType?: string
  userLocation?: string
  subscription?: string
}

const setIdentifier = (netcoreData: NetcoreContact): void => {
  if (isNetcoreActive) {
    smartech('identify', netcoreData.userId.toLowerCase())
    smartech('contact', '2', {
      'pk^userid': netcoreData.userId.toLowerCase(),
      email: netcoreData.email.toLowerCase(),
      mobile: netcoreData.mobile.toLowerCase(),
      NAME: netcoreData.name.toLowerCase(),
      REGISTER_DATE: netcoreData.registerDate
        ? toDateString(netcoreData.registerDate, 'DD-MM-YYYY')
        : '',
      PREMIUM_DATE: netcoreData.premiumStartDate
        ? toDateString(netcoreData.premiumStartDate, 'DD-MM-YYYY')
        : '',
      PREMIUM_END_DATE: netcoreData.premiumEndDate
        ? toDateString(netcoreData.premiumEndDate, 'DD-MM-YYYY')
        : '',
      USER_TYPE: netcoreData.userType ? netcoreData.userType.toLowerCase() : '',
      USER_LOCATION: netcoreData.userLocation,
      SUBSCRIPTION: netcoreData.subscription
        ? netcoreData.subscription.toLowerCase()
        : '',
    })
  }
}

const dispatchEvent = (eventName: string, value?: any): void => {
  if (isNetcoreActive) {
    smartech('dispatch', eventName, value || '')
  }
}

const netcore = {
  initialize,
  dispatchEvent,
  setIdentifier,
}

export default netcore
