import { SchemaValidation } from 'utilities/validationCheck'

export interface OnboardingBusinessInfoModel {
  businessCategoryId: string
  areaId: string
}

export const OnboardingBusinessInfoSchema = SchemaValidation.object().shape({
  businessCategoryId: SchemaValidation.string().required(
    'Kategori bisnis tidak boleh kosong'
  ),
  areaId: SchemaValidation.string().required(
    'Lokasi bisnis tidak boleh kosong'
  ),
})
