import * as React from 'react'
import { BaseProps } from '../props'
import { Sided, CircleText } from 'views/components'
import './style.css'

interface StepListProps extends BaseProps {
  step: number
  children: React.ReactNode
  justify?: 'center' | 'top' | 'bottom'
}

const List = (props: StepListProps) => (
  <Sided.Sided justify={props.justify || 'center'}>
    <Sided.Fixed>
      <CircleText text={props.step.toString()} color='blue' />
    </Sided.Fixed>
    <Sided.Remaining>{props.children}</Sided.Remaining>
  </Sided.Sided>
)

interface StepProps extends BaseProps {
  children?: React.ReactNode
}
const Steps: React.FC<StepProps> = (props: StepProps) => (
  <div className='post-vertical-steps'>
    <React.Fragment>{props.children}</React.Fragment>
  </div>
)

export default {
  Steps,
  List,
}
