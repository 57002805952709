import {
  SET_EMPLOYEE_REPORTING,
  SET_SUMMARY_REPORTING,
  SET_DAILY_SUMMARY_REPORTING,
  SET_HOURLY_SUMMARY_REPORTING,
  SET_PRODUCT_SALES_REPORTING,
  SET_CANCELLATION_REPORTING,
  SET_ORDER_TYPE_LIST_REPORTING,
  SET_DISCOUNT_REPORTING,
  SET_SHIFT_REPORTING,
  SET_PAYMENT_METHOD_LIST_REPORT,
  SET_OUTLET_ID_REPORT_FILTER,
  SET_DATE_RANGE_REPORT_FILTER,
  SET_SHOW_TYPE_REPORT_FILTER,
  SET_SALE_TYPE_REPORT_FILTER,
  SET_EXPENSE_TYPE_REPORT_FILTER,
  RESET_REPORTING_FILTER,
} from './actions'
import { Reducer } from 'utilities/redux/reducer'
import {
  ProductSaleReport,
  SummaryReport,
  ServiceTypeReportData,
  DiscountReport,
  ShiftReport,
  PaymentMethodReport,
  CancellationReport,
  SummaryHourlyReport,
  EmployeeReport,
} from 'domains/reporting/models'
import { ReportingState } from 'domains/reporting/states'
import { DateRange } from 'domains/commons/models'
import moment from 'moment'

export class ReportingReducer extends Reducer<ReportingState> {
  constructor() {
    super({
      summaryReports: [],
      dailySummaryReports: [],
      hourlySummaryReports: [],
      productSaleReports: [],
      employeeReports: [],
      cancellationReports: [],
      serviceTypeReports: [],
      discountReports: [],
      shiftReports: [],
      paymentMethods: [],
      filter: {
        showType: 'summary',
        categoryName: '',
        calendarType: '',
        dateRange: {
          from: moment().subtract(6, 'day').startOf('day').toDate(),
          to: moment().endOf('day').toDate(),
        },
      },
    })
  }

  public setSummaryReporting(
    state: ReportingState,
    summaryReports: SummaryReport[]
  ): ReportingState {
    return {
      ...state,
      summaryReports,
    }
  }

  public setDailySummaryReporting(
    state: ReportingState,
    dailySummaryReports: SummaryReport[]
  ): ReportingState {
    return {
      ...state,
      dailySummaryReports,
    }
  }

  public setHourlySummaryReporting(
    state: ReportingState,
    hourlySummaryReports: SummaryHourlyReport[]
  ): ReportingState {
    return {
      ...state,
      hourlySummaryReports,
    }
  }

  public setProductSalesReporting(
    state: ReportingState,
    productSales: ProductSaleReport[]
  ): ReportingState {
    return {
      ...state,
      productSaleReports: productSales,
    }
  }

  public setEmployeeReporting(
    state: ReportingState,
    employeeReports: EmployeeReport[]
  ): ReportingState {
    return {
      ...state,
      employeeReports: employeeReports,
    }
  }

  public setCancellationReporting(
    state: ReportingState,
    cancellations: CancellationReport[]
  ): ReportingState {
    return {
      ...state,
      cancellationReports: cancellations,
    }
  }

  public setServiceTypeListReporting(
    state: ReportingState,
    serviceTypes: ServiceTypeReportData[]
  ): ReportingState {
    return {
      ...state,
      serviceTypeReports: serviceTypes,
    }
  }

  public setDiscountReporting(
    state: ReportingState,
    discounts: DiscountReport[]
  ): ReportingState {
    return {
      ...state,
      discountReports: discounts,
    }
  }

  public setShiftReporting(
    state: ReportingState,
    shifts: ShiftReport[]
  ): ReportingState {
    return {
      ...state,
      shiftReports: shifts,
    }
  }

  public setPaymentMethodListReport(
    state: ReportingState,
    paymentMethod: PaymentMethodReport[]
  ): ReportingState {
    return {
      ...state,
      paymentMethods: paymentMethod,
    }
  }

  public setOutletIdReportFilter(
    state: ReportingState,
    selectedOutletId?: string
  ): ReportingState {
    return {
      ...state,
      filter: {
        ...state.filter,
        selectedOutletId,
      },
    }
  }

  public setDateRangeReportFilter(
    state: ReportingState,
    dateRange: DateRange
  ): ReportingState {
    return {
      ...state,
      filter: {
        ...state.filter,
        dateRange,
      },
    }
  }

  public setShowTypeReportFilter(
    state: ReportingState,
    showType: 'summary' | 'daily' | 'weekly' | 'monthly'
  ): ReportingState {
    return {
      ...state,
      filter: {
        ...state.filter,
        showType,
      },
    }
  }

  public setSaleTypeReportFilter(
    state: ReportingState,
    saleType: string
  ): ReportingState {
    return {
      ...state,
      filter: {
        ...state.filter,
        saleType,
      },
    }
  }

  public setExpenseTypeReportFilter(
    state: ReportingState,
    expenseType: string
  ): ReportingState {
    return {
      ...state,
      filter: {
        ...state.filter,
        categoryName: expenseType,
      },
    }
  }

  public resetFilter(state: ReportingState): ReportingState {
    return {
      ...state,
      filter: {
        showType: 'summary',
        categoryName: '',
        calendarType: '',
        dateRange: {
          from: moment().subtract(6, 'day').startOf('day').toDate(),
          to: moment().endOf('day').toDate(),
        },
      },
    }
  }

  get actions(): {
    [p: string]: (state: ReportingState, payload?: any) => any
  } {
    return {
      [SET_SUMMARY_REPORTING]: this.setSummaryReporting,
      [SET_DAILY_SUMMARY_REPORTING]: this.setDailySummaryReporting,
      [SET_HOURLY_SUMMARY_REPORTING]: this.setHourlySummaryReporting,
      [SET_PRODUCT_SALES_REPORTING]: this.setProductSalesReporting,
      [SET_EMPLOYEE_REPORTING]: this.setEmployeeReporting,
      [SET_CANCELLATION_REPORTING]: this.setCancellationReporting,
      [SET_ORDER_TYPE_LIST_REPORTING]: this.setServiceTypeListReporting,
      [SET_DISCOUNT_REPORTING]: this.setDiscountReporting,
      [SET_SHIFT_REPORTING]: this.setShiftReporting,
      [SET_PAYMENT_METHOD_LIST_REPORT]: this.setPaymentMethodListReport,
      [SET_OUTLET_ID_REPORT_FILTER]: this.setOutletIdReportFilter,
      [SET_DATE_RANGE_REPORT_FILTER]: this.setDateRangeReportFilter,
      [SET_SHOW_TYPE_REPORT_FILTER]: this.setShowTypeReportFilter,
      [SET_SALE_TYPE_REPORT_FILTER]: this.setSaleTypeReportFilter,
      [SET_EXPENSE_TYPE_REPORT_FILTER]: this.setExpenseTypeReportFilter,
      [RESET_REPORTING_FILTER]: this.resetFilter,
    }
  }
}
