import { BaseStorage } from 'storages/base'
import { IEmployeeStorage } from 'domains/management/employee/contracts/storage'
import { Dict } from 'utilities/type'
import { Employee, Staff, Cashier } from 'domains/management/employee/models'
import {
  SET_EMPLOYEES,
  APPEND_EMPLOYEES,
  SET_EMPLOYEE_LIST_MANAGER,
  SET_CURRENT_STAFF,
  SET_CURRENT_CASHIER,
  SET_EMPLOYEE_LIST_REQUEST,
  SET_EMPLOYEE_LIST_REACH_BOTTOM,
  SET_LOADING_MORE_EMPLOYEE,
  SET_IS_FNB_BUSINESS_CATEGORY,
} from './actions'
import {
  EmployeeListManager,
  EmployeeQueryParam,
} from 'domains/management/employee/states'

export class EmployeeStorage extends BaseStorage implements IEmployeeStorage {
  public setEmployees(employees: Dict<Employee>): void {
    this.dispatch(SET_EMPLOYEES, employees)
  }

  public setCurrentStaff(staff: Staff): void {
    this.dispatch(SET_CURRENT_STAFF, staff)
  }

  public setCurrentCashier(cashier: Cashier): void {
    this.dispatch(SET_CURRENT_CASHIER, cashier)
  }

  public appendEmployees(employees: Dict<Employee>): void {
    this.dispatch(APPEND_EMPLOYEES, employees)
  }

  public setEmployeeListManager(
    employeeListManager: EmployeeListManager
  ): void {
    this.dispatch(SET_EMPLOYEE_LIST_MANAGER, employeeListManager)
  }

  public setEmployeeListRequest(request: EmployeeQueryParam): void {
    this.dispatch(SET_EMPLOYEE_LIST_REQUEST, request)
  }

  public setEmployeeListReachBottom(hasReachedBottom: boolean): void {
    this.dispatch(SET_EMPLOYEE_LIST_REACH_BOTTOM, hasReachedBottom)
  }

  public setLoadingMoreEmployee(isLoadingMoreEmployee: boolean): void {
    this.dispatch(SET_LOADING_MORE_EMPLOYEE, isLoadingMoreEmployee)
  }

  setIsFnbBusinessCategory(isFnbBusinessCategory: boolean) {
    this.dispatch(SET_IS_FNB_BUSINESS_CATEGORY, isFnbBusinessCategory)
  }
}
