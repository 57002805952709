import classNames from 'classnames'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { featureCheck } from 'config'
import { cdnImageUrl } from 'constants/cdnUrl/imageUrl'
import { CONFIG_LOCALSTORAGE } from 'constants/localStorage'
import ROUTE from 'constants/routes'
import { AppState } from 'domains/contracts/appState'
import {
  employeeService,
  layoutService,
  locationService,
  subscriptionService,
  userService,
} from 'injectors'
import { RouteItemV2 } from 'route'
import getAuthorizedRoutes from 'route/authorizedRoute'
import { history } from 'storages'
import { getLocalStorage } from 'utilities/localStorage'
import netcore from 'utilities/netcore'
import { capitalizeFirstLetter } from 'utilities/strings/capitalizeFirstLetter'
import {
  isPremiumSelector,
  isTrialSelector,
} from 'views/modules/globalSelector/subscriptionTypeCheck'
import { SubscriptionComponent } from 'views/modules/Subscription'

import {
  Icon,
  VSided,
  Text,
  Wrapper,
  FeatureFlag,
  Break,
  Popup,
  Button,
  Panel,
  Sided,
  Divider,
} from '..'
import { BadgeStatus } from '../Common'
import { DefaultModalService } from '../Modal'
import { PopupContentEvent } from '../Popup'
import LogoutConfirmationModal from './LogoutConfirmation'
import selector from './selector'
import { firebaseFunc } from 'utilities/firebase'
import './style-v2.css'

const SidebarV2: React.FC = () => {
  const role = getLocalStorage(CONFIG_LOCALSTORAGE.POST_TYPE)
  const onboardingSteps = useSelector(
    (state: AppState) => state.user.onboardingSteps
  )
  const { user, employee, subscriptionAlert } = useSelector(selector)
  const employeeId = getLocalStorage(CONFIG_LOCALSTORAGE.STAFF_ID)
  const routes = getAuthorizedRoutes().filter(
    routeItem => !!routeItem.sidebarMenu
  )
  const [expandedMenu, setExpandedMenu] = useState<string[]>([])
  const isPremium = useSelector(isPremiumSelector)
  const isTrial = useSelector(isTrialSelector)
  const isUserFillBusinessInfo =
    (!user.areaId &&
      !user.businessCategoryId &&
      moment(moment()).isBefore(moment(user.createdAt).add(7, 'days'))) ||
    !user.businessCategoryId

  const getData = async () => {
    try {
      if (employeeId) {
        const staffResponse = await employeeService.getStaff(employeeId)
        netcore.setIdentifier({
          userId: staffResponse.id,
          email: staffResponse.email,
          name: staffResponse.name,
          userType: role,
        })
      } else {
        let userResponse = user
        let subscriptionAlertResponse = subscriptionAlert
        if (!userResponse.id) {
          userResponse = await userService.getUser()
        }
        if (!subscriptionAlertResponse.status) {
          subscriptionAlertResponse =
            await subscriptionService.checkSubscriptionAlert()
        }
        const { village, district, regency, province } =
          await locationService.getArea(userResponse.areaId)
        netcore.setIdentifier({
          userId: userResponse.id,
          email: userResponse.email,
          mobile: userResponse.phone,
          name: userResponse.businessName,
          registerDate: userResponse.createdAt,
          userType: role,
          subscription: subscriptionAlertResponse.status,
          premiumStartDate: subscriptionAlertResponse.startDate,
          premiumEndDate: subscriptionAlertResponse.endDate,
          userLocation: `${village}, ${district}, ${regency}, ${province}`,
        })
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    routes.forEach(route => {
      if (history.location.pathname.includes(route.path)) {
        setExpandedMenu([route.path])
      }
    })
  }, [history.location.pathname])

  const handleClickLogoutButton = async () => {
    const confirmed = await DefaultModalService.open(
      LogoutConfirmationModal,
      {}
    )
    if (confirmed) {
      const employeeId = getLocalStorage(CONFIG_LOCALSTORAGE.STAFF_ID)
      if (employeeId) {
        layoutService.logOutEmployee()
      } else {
        layoutService.logout()
      }
    }
  }

  const renderOwnerProfile = () => {
    const handleOnClickBadge = function () {
      netcore.dispatchEvent('subscription_start', {
        source: 'drawer',
        user_type: subscriptionAlert.subscriptionType,
      })
      DefaultModalService.open(SubscriptionComponent, {})
    }

    let badge = <BadgeStatus status='lite' onClick={handleOnClickBadge} />

    if (isTrial) {
      badge = <BadgeStatus status='trial' onClick={handleOnClickBadge} />
    }

    if (isTrial === false && isPremium) {
      badge = <BadgeStatus status='premium' onClick={handleOnClickBadge} />
    }
    return (
      <VSided.Fixed>
        <Wrapper className='post-sidebar-profile'>
          <Sided.Sided>
            <Sided.Fixed verticalAlign='center'>
              <Icon.User size={24} />
            </Sided.Fixed>
            <Sided.Remaining verticalAlign='center'>
              <Wrapper className='post-sidebar-profile-info'>
                <Wrapper
                  className='post-sidebar-profile-info-name'
                  width='140px'
                >
                  <Text.Paragraph
                    className='post-sidebar-profile-info-name-overflow'
                    size={14}
                    color='white'
                    weight='bold'
                    noWrap
                  >
                    {user.businessName}
                  </Text.Paragraph>
                </Wrapper>
                {badge}
              </Wrapper>
            </Sided.Remaining>
            <Sided.Fixed verticalAlign='center'>
              <Icon.ChevronRight
                onClick={() => history.push(ROUTE.ACCOUNT_SETTINGS)}
              />
            </Sided.Fixed>
          </Sided.Sided>
        </Wrapper>
      </VSided.Fixed>
    )
  }

  const renderStaffProfile = () => (
    <VSided.Fixed>
      <Wrapper className='post-sidebar-profile'>
        <Sided.Sided gutter={16}>
          <Sided.Fixed verticalAlign='center'>
            <Icon.User size={24} />
          </Sided.Fixed>
          <Sided.Remaining verticalAlign='center'>
            <Text.Paragraph
              className='post-sidebar-profile-info-name-overflow'
              size={14}
              color='white'
              weight='bold'
              noWrap
              noMargin
            >
              {employee.currentStaff.name}
            </Text.Paragraph>
            <Text.Paragraph size={12} color='white' noMargin>
              {capitalizeFirstLetter(String(employee.currentStaff.roleId))}
            </Text.Paragraph>
          </Sided.Remaining>
        </Sided.Sided>
      </Wrapper>
    </VSided.Fixed>
  )

  const renderSetupGuide = () => {
    const isOnBoardingAccomplished = getLocalStorage(
      user.id + CONFIG_LOCALSTORAGE.IS_ONBOARDING_ACCOMPLISHED
    )
    return (
      <FeatureFlag featureCheck={featureCheck.home.setupGuide}>
        <VSided.Fixed>
          <Wrapper className='post-sidebar-setup-guide'>
            <Popup
              renderCenterContent={() => (
                <Button
                  theme='secondary'
                  label='Setup Guide'
                  fullW
                  size='small'
                  fontWeight='normal'
                  onClick={() => firebaseFunc.logEvent('setup_guide_open')}
                />
              )}
              renderContent={(popupEvent: PopupContentEvent) => (
                <Wrapper
                  width='475px'
                  style={
                    isUserFillBusinessInfo
                      ? { marginTop: '200px' }
                      : { marginTop: '100px' }
                  }
                >
                  <Panel.Panel>
                    <Panel.Header>
                      <Wrapper padding='0 8px'>
                        <Text.Heading h={6}>Setup Guide</Text.Heading>
                      </Wrapper>
                    </Panel.Header>
                    <Panel.Content padding={24}>
                      {isUserFillBusinessInfo && (
                        <>
                          <Text.Paragraph size={20} weight='bold' color='black'>
                            Informasi Bisnis
                          </Text.Paragraph>
                          <Wrapper verticalGap={8}>
                            <Sided.Sided>
                              <Sided.Remaining>
                                <Text.Paragraph>
                                  Lengkapi informasi bisnismu agar kami dapat
                                  memberikan informasi terbaik
                                </Text.Paragraph>
                              </Sided.Remaining>
                              <Sided.Fixed>
                                <Button
                                  type='submit'
                                  size='large'
                                  theme='secondary'
                                  label='Lengkapi Sekarang'
                                  fullW
                                  onClick={() => {
                                    popupEvent.close()
                                    history.push(ROUTE.BUSINESS_PROFILE)
                                  }}
                                />
                              </Sided.Fixed>
                            </Sided.Sided>
                          </Wrapper>
                          <Divider />
                        </>
                      )}
                      {!isOnBoardingAccomplished && (
                        <>
                          <Text.Paragraph size={20} weight='bold' color='black'>
                            Langkah - langkah menggunakan POST
                          </Text.Paragraph>
                          <Wrapper
                            style={{ paddingLeft: '24px' }}
                            verticalGap={8}
                          >
                            <Sided.Sided>
                              <Sided.Remaining>
                                <Text.Paragraph>
                                  1. Tambah Produk
                                </Text.Paragraph>
                              </Sided.Remaining>
                              <Sided.Fixed>
                                <Wrapper
                                  className='circle-checklist'
                                  backgroundColor={
                                    onboardingSteps &&
                                    onboardingSteps.add_product
                                      ? '#3B8EF3'
                                      : '#E0E0E0'
                                  }
                                >
                                  <Icon.Checklist color='white' />
                                </Wrapper>
                              </Sided.Fixed>
                            </Sided.Sided>
                            <Sided.Sided>
                              <Sided.Remaining>
                                <Text.Paragraph>
                                  2. Transaksi Di Aplikasi
                                </Text.Paragraph>
                              </Sided.Remaining>
                              <Sided.Fixed>
                                <Wrapper
                                  className='circle-checklist'
                                  backgroundColor={
                                    onboardingSteps && onboardingSteps.add_order
                                      ? '#3B8EF3'
                                      : '#E0E0E0'
                                  }
                                >
                                  <Icon.Checklist color='white' />
                                </Wrapper>
                              </Sided.Fixed>
                            </Sided.Sided>
                            <Sided.Sided>
                              <Sided.Remaining>
                                <Text.Paragraph>
                                  3. Lihat Laporan
                                </Text.Paragraph>
                              </Sided.Remaining>
                              <Sided.Fixed>
                                <Wrapper
                                  className='circle-checklist'
                                  backgroundColor={
                                    onboardingSteps &&
                                    onboardingSteps.see_report
                                      ? '#3B8EF3'
                                      : '#E0E0E0'
                                  }
                                >
                                  <Icon.Checklist color='white' />
                                </Wrapper>
                              </Sided.Fixed>
                            </Sided.Sided>
                          </Wrapper>
                          <Divider />
                        </>
                      )}
                      <Text.Paragraph size={20} weight='bold' color='black'>
                        Aktifkan Pembayaran Non-Tunai
                      </Text.Paragraph>
                      <Sided.Sided>
                        <Sided.Fixed minWidth={260}>
                          <Text.Paragraph size={14}>
                            Nikmati kemudahan dalam menerima pembayaran nontunai
                            pada aplikasi POST.
                          </Text.Paragraph>
                        </Sided.Fixed>
                        <Sided.Remaining align='right' verticalAlign='center'>
                          <Button
                            theme='secondary'
                            label='Aktifkan Sekarang'
                            fontWeight='normal'
                            size='small'
                            onClick={() => {
                              firebaseFunc.logEvent('setup_guide_payment')
                              history.push(ROUTE.MANAGEMENT_PAYMENT_METHOD)
                              popupEvent.close()
                            }}
                          />
                        </Sided.Remaining>
                      </Sided.Sided>
                      <Divider />
                      <Text.Paragraph size={20} weight='bold' color='black'>
                        EasyOrder
                      </Text.Paragraph>
                      <Sided.Sided>
                        <Sided.Fixed minWidth={260}>
                          <Text.Paragraph size={14}>
                            Mulai berjualan dan terima pesanan secara online
                            dengan EasyOrder.
                          </Text.Paragraph>
                        </Sided.Fixed>
                        <Sided.Remaining align='right' verticalAlign='center'>
                          <Button
                            theme='secondary'
                            label='Buat Toko Online'
                            fontWeight='normal'
                            size='small'
                            onClick={() => {
                              firebaseFunc.logEvent('setup_guide_easyorder')
                              history.push(ROUTE.MAIN_CONTACTLESS)
                              popupEvent.close()
                            }}
                          />
                        </Sided.Remaining>
                      </Sided.Sided>
                    </Panel.Content>
                  </Panel.Panel>
                </Wrapper>
              )}
            />
          </Wrapper>
        </VSided.Fixed>
      </FeatureFlag>
    )
  }

  const checkIsMatchRoute = (path: string) => {
    return path === history.location.pathname
  }

  const renderMenu = (routeItem: RouteItemV2, i: number) => {
    const getClassName = (path: string) => {
      const matchRoute = checkIsMatchRoute(path)
      return classNames('post-menu-item', { 'is-active': matchRoute })
    }

    const handleOnClick = (path: string) => {
      history.push(path)
    }

    const handleOnExpand = () => {
      if (!routeItem.children) {
        handleOnClick(routeItem.path)
        return
      }
      const menu = JSON.parse(JSON.stringify(expandedMenu))
      if (menu.includes(routeItem.path)) {
        menu.splice(menu.indexOf(routeItem.path), 1)
      } else {
        menu.push(routeItem.path)
      }
      setExpandedMenu(menu)
    }

    const routeItemChildren =
      routeItem.children && routeItem.children.filter(child => child.isEnabled)

    return (
      <>
        <Wrapper
          className={getClassName(routeItem.path)}
          key={routeItem.path}
          onClick={handleOnExpand}
        >
          <Sided.Sided gutter={16}>
            <Sided.Fixed verticalAlign='center'>
              {routeItem.sidebarMenu.icon}
            </Sided.Fixed>
            <Sided.Remaining verticalAlign='center'>
              <Text.Span size={14} align='left'>
                {routeItem.sidebarMenu.label}
              </Text.Span>
            </Sided.Remaining>
            {routeItem.isNew && (
              <Sided.Fixed verticalAlign='center'>
                <Text.Span
                  size={10}
                  style={{ fontStyle: 'italic' }}
                  color='white'
                  weight='bold'
                >
                  New!
                </Text.Span>
              </Sided.Fixed>
            )}
            <Sided.Fixed minWidth={24} verticalAlign='center'>
              {routeItem.children &&
                (expandedMenu.includes(routeItem.path) ? (
                  <Icon.ChevronUp style={{ cursor: 'pointer' }} />
                ) : (
                  <Icon.ChevronDown style={{ cursor: 'pointer' }} />
                ))}
            </Sided.Fixed>
          </Sided.Sided>
        </Wrapper>
        <Wrapper
          className={`panel ${
            expandedMenu.includes(routeItem.path) ? 'visible' : 'hidden'
          }`}
          style={{
            height: `${
              expandedMenu.includes(routeItem.path) && routeItemChildren
                ? routeItemChildren.length * 56
                : 0
            }px`,
          }}
        >
          {routeItemChildren &&
            routeItemChildren.map(route => (
              <Wrapper
                className={`${getClassName(route.path)} children`}
                onClick={() => handleOnClick(route.path)}
                key={route.path}
              >
                <Sided.Sided gutter={16}>
                  <Sided.Fixed verticalAlign='center' />
                  <Sided.Remaining verticalAlign='center'>
                    <Text.Span size={14} align='left'>
                      {route.sidebarMenu && route.sidebarMenu.label}
                    </Text.Span>
                  </Sided.Remaining>
                  {route.isNew && (
                    <Sided.Fixed verticalAlign='center'>
                      <Text.Span
                        size={10}
                        style={{ fontStyle: 'italic' }}
                        color='white'
                        weight='bold'
                      >
                        New!
                      </Text.Span>
                    </Sided.Fixed>
                  )}
                </Sided.Sided>
              </Wrapper>
            ))}
        </Wrapper>
      </>
    )
  }

  return (
    <VSided.VSided gutter={0} className='sidebar-v2 main-side'>
      <VSided.Fixed>
        <Wrapper className='post-sidebar-logo'>
          <img
            src={cdnImageUrl + 'post-logo/post-light.png'}
            width='115px'
            alt='post logo white'
          />
        </Wrapper>
      </VSided.Fixed>
      {role === 'owner' ? renderOwnerProfile() : renderStaffProfile()}
      {renderSetupGuide()}
      <VSided.Remaining>
        <Wrapper className='post-menu list' height='100%'>
          {routes.filter(r => !r.parentKey).map(renderMenu)}
          <Break height={20} />
          <Text.Heading h={4} color='gray'>
            PRODUK
          </Text.Heading>
          <Break height={6} />
          {routes.filter(r => r.parentKey === 'product').map(renderMenu)}
          <Break height={20} />
          <Text.Heading h={4} color='gray'>
            KELOLA TOKO
          </Text.Heading>
          <Break height={6} />
          {routes.filter(r => r.parentKey === 'manageOutlet').map(renderMenu)}
        </Wrapper>
      </VSided.Remaining>
      <VSided.Fixed>
        <Wrapper className='post-menu outside' height='100%'>
          <Wrapper
            className={classNames('post-menu-item', {
              'is-active': checkIsMatchRoute(ROUTE.MAIN_HELPER),
            })}
            key='Help'
            onClick={() => history.push(ROUTE.MAIN_HELPER)}
          >
            <Sided.Sided gutter={16}>
              <Sided.Fixed verticalAlign='center'>
                <Icon.HelpInfo />
              </Sided.Fixed>
              <Sided.Remaining verticalAlign='center'>
                <Text.Span size={14} align='left' color='semiGray'>
                  Bantuan
                </Text.Span>
              </Sided.Remaining>
            </Sided.Sided>
          </Wrapper>
          <Wrapper
            className='post-menu-item'
            key='Logout'
            onClick={() => handleClickLogoutButton()}
          >
            <Sided.Sided gutter={16}>
              <Sided.Fixed verticalAlign='center'>
                <Icon.Logout />
              </Sided.Fixed>
              <Sided.Remaining verticalAlign='center'>
                <Text.Span size={14} align='left' color='semiGray'>
                  Keluar
                </Text.Span>
              </Sided.Remaining>
            </Sided.Sided>
          </Wrapper>
        </Wrapper>
      </VSided.Fixed>
    </VSided.VSided>
  )
}

export default SidebarV2
