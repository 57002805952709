import React from 'react'
import { withRouter } from 'react-router'
import Wrapper from 'views/components/Wrapper'
import SwitchRoute from 'views/components/SwitchRoute'
import { generateAuthRoutes } from 'routes/auth'
import { featureCheck, isAnyOfTheseFeaturesEnabled } from 'config'

const UnauthorizedLayout: React.FC = props => {
  const routeItem = generateAuthRoutes()
  return (
    <Wrapper className='layout'>
      {isAnyOfTheseFeaturesEnabled(featureCheck.home.routingV2) ? (
        props.children
      ) : (
        <SwitchRoute routes={routeItem} />
      )}
    </Wrapper>
  )
}

export default withRouter(UnauthorizedLayout)
