export const logChecker = () => {
  if (!process.env.REACT_APP_MODE) {
    console.log(
      '%cWelcome To POST Dashboard',
      'color: #3B8EF3; left: 0; font-weight: bold; font-size: 30px; -webkit-text-stroke: 0.5px #000;'
    )
    console = {
      log: () => {},
      warn: () => {},
      error: () => {},
    } as Console
  }
}
