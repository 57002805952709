import { ProductListQueryString } from 'domains/management/products/states'
import {
  SET_PRODUCTS,
  SET_COMBO_PRODUCTS,
  SET_VARIATION_PRODUCTS,
  SET_PRODUCT_LIST_REQUEST,
  PUSH_PRODUCTS,
  SET_PRODUCT_LIST_REACH_BOTTOM,
  SET_LOADING_MORE_PRODUCT,
  UNSHIFT_PRODUCTS,
  SET_PRODUCTS_V2,
  PUSH_PRODUCTS_V2,
  SET_PRODUCT_LIST_REQUEST_V2,
  SET_PRODUCT_LIST_REACH_BOTTOM_V2,
  SET_LOADING_MORE_PRODUCT_V2,
} from './actions'
import { Dict } from 'utilities/type'
import {
  ProductListV2QueryParams,
  ProductModel,
  ProductV2Model,
} from 'domains/management/products/models'
import { BaseStorage } from 'storages/base'
import { IProductStorage } from 'domains/management/products/contracts/storage'

export class ProductStorage extends BaseStorage implements IProductStorage {
  setProducts(products: Dict<ProductModel>): void {
    this.dispatch(SET_PRODUCTS, products)
  }

  pushProducts(products: Dict<ProductModel>): void {
    this.dispatch(PUSH_PRODUCTS, products)
  }

  setProductsV2(products: Dict<ProductV2Model>): void {
    this.dispatch(SET_PRODUCTS_V2, products)
  }

  pushProductsV2(products: Dict<ProductV2Model>): void {
    this.dispatch(PUSH_PRODUCTS_V2, products)
  }

  unshiftProducts(products: Dict<ProductModel>): void {
    this.dispatch(UNSHIFT_PRODUCTS, products)
  }

  setComboProducts(comboProducts: Dict<ProductModel>): void {
    this.dispatch(SET_COMBO_PRODUCTS, comboProducts)
  }

  setVariationProducts(variationProductDict: Dict<ProductModel>): void {
    this.dispatch(SET_VARIATION_PRODUCTS, variationProductDict)
  }

  setProductListReachBottom(hasReachedBottom: boolean): void {
    this.dispatch(SET_PRODUCT_LIST_REACH_BOTTOM, hasReachedBottom)
  }

  setLoadingMoreProduct(isLoadingMoreProduct: boolean): void {
    this.dispatch(SET_LOADING_MORE_PRODUCT, isLoadingMoreProduct)
  }

  setProductListRequest(request: ProductListQueryString): void {
    this.dispatch(SET_PRODUCT_LIST_REQUEST, request)
  }

  setProductListReachBottomV2(hasReachedBottom: boolean): void {
    this.dispatch(SET_PRODUCT_LIST_REACH_BOTTOM_V2, hasReachedBottom)
  }

  setLoadingMoreProductV2(isLoadingMoreProduct: boolean): void {
    this.dispatch(SET_LOADING_MORE_PRODUCT_V2, isLoadingMoreProduct)
  }

  setProductListRequestV2(request: ProductListV2QueryParams): void {
    this.dispatch(SET_PRODUCT_LIST_REQUEST_V2, request)
  }
}
