import React from 'react'
import InputBase from '../base'
import Form, { RenderingFieldContext } from '../../Form'

interface Props {
  label?: string
  placeholder?: string
  name?: string
}

const InputEmail = (props: Props) => {
  return (
    <Form.Field
      name={props.name}
      render={({ field, value, onChange, onBlur }: RenderingFieldContext) => (
        <InputBase label={props.label} error={field.isDirty && field.error}>
          <input
            value={value}
            onBlur={onBlur}
            onChange={evt => onChange(evt.target.value)}
            name={props.name}
            type='text'
            placeholder={props.placeholder}
            data-testid='field-email'
          />
        </InputBase>
      )}
    />
  )
}

export default InputEmail
