import React from 'react'
import { ModalProps, Modal } from 'views/components/Modal'
import { Icon, Text, Button, Panel, Wrapper, Break } from 'views/components'

interface ResetDataSuccessModalProps extends ModalProps {
  resetDataType: string
}

const ResetDataSuccessModal: React.FC<ResetDataSuccessModalProps> = props => {
  return (
    <Modal
      width='470px'
      dismiss={props.dismiss}
      type='blank'
      canDismissOutside={false}
    >
      <Panel.Card padding={16}>
        <Wrapper align='center'>
          <Icon.SuccessSign style={{ width: '240px', height: '240px' }} />
          <Text.Heading h={3}>
            Anda Berhasil Reset Data{' '}
            {props.resetDataType === 'management' ? 'Manajemen' : 'Transaksi'}
          </Text.Heading>
          <Break height={12} />
          {props.resetDataType === 'management' ? (
            <Text.Paragraph size={14}>
              Silahkan periksa di halaman Kelola Toko dan Kelola Produk <br />
              untuk melihat perubahan hasil dari reset data.
            </Text.Paragraph>
          ) : (
            <Text.Paragraph size={14}>
              Silahkan periksa di halaman Riwayat Transaksi untuk <br />
              melihat perubahan. dari hasil reset data
            </Text.Paragraph>
          )}
          <Break height={24} />
          <Button
            theme='primary'
            fullW
            label='Kembali'
            onClick={() => props.close('')}
          />
        </Wrapper>
      </Panel.Card>
    </Modal>
  )
}

export default ResetDataSuccessModal
