const staffConfig = {
  featureFlags: {
    home: {
      routingV2: true,
      sidebarV2: false,
      sidebarV3: true,
      totalQuantityCard: true,
    },
    management: {
      receipt: false,
      paymentMethod: false,
      tax: false,
      discount: true,
      category: true,
      product: false,
      productVariant: true,
      massProductVariant: true,
      device: false,
      serviceType: false,
      employee: false,
      shift: false,
      customer: false,
      outerCustomer: true,
      attendance: false,
      serviceTypeWithServiceCharge: true,
      categoryCreationWithProduct: true,
      multiOutlet: false,
      outletForProduct: true,
      massUpload: true,
      kycIntegration: false,
      stockTracking: true,
      stockHistoryList: true,
      hasBarcode: true,
      inventory: true,
      bulkStockAdjustment: true,
      roleManagement: true,
    },
    reporting: {
      summary: true,
      productSale: true,
      cancellation: false,
      discount: true,
      shift: true,
      serviceType: true,
      paymentMethod: true,
      expense: true,
      employee: true,
      productSalesCategoryName: true,
      cogs: false,
    },
    ingredients: {
      ingredient: true,
      ingredientCategory: true,
      recipe: true,
      ingredientSummaryReport: true,
    },
    setting: {
      subscriptionHistory: false,
      subscribing: false,
      businessProfile: false,
      generalInformation: false,
      resetData: false,
      account: false,
    },
    contactless: {
      contactlessDining: false,
      onlineOrder: false,
      paymentSetup: false,
    },
    miscellaneous: {
      helpdesk: true,
    },
  },
}

export default staffConfig
