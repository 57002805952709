import React, { useState, useEffect, useCallback } from 'react'
import Modal from 'react-modal'
import Cropper from 'react-easy-crop'
import { Slider } from 'antd'
import {
  Text,
  Sided,
  VSided,
  Divider,
  Wrapper,
  Button,
  Icon,
  FormAction,
} from 'views/components'
import Loader from 'views/components/Loader'
import { getCroppedImg, resizeImage, getImageDimension } from 'utilities/image'
import { CreateSingleProductRequestSchema } from 'domains/management/products/models'
import { ConfirmationModal, ConfirmationModalProps } from './confirmationModal'
import { t, Trans } from '@lingui/macro'
import { useLingui } from '@lingui/react'

interface cropImageModalProps {
  image: string
  actionsProps: FormAction<CreateSingleProductRequestSchema>
  showModal: boolean
  setShowModal: (values: boolean) => void
}

const CropImageModal = (props: cropImageModalProps) => {
  const { image, actionsProps, showModal, setShowModal } = props

  const { i18n } = useLingui()

  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [rotation, setRotation] = useState(0)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [confirmationModalProps, setConfirmationModalProps] =
    useState<ConfirmationModalProps>({} as ConfirmationModalProps)

  // Set to initial state when image is changed
  useEffect(() => {
    setCrop({ x: 0, y: 0 })
    setZoom(1)
    setRotation(0)
    setCroppedAreaPixels(null)
  }, [props.image])

  const setCroppedImage = useCallback(async () => {
    setIsLoading(true)
    try {
      const croppedImage = await getCroppedImg(
        image,
        croppedAreaPixels,
        rotation
      )
      const imageProperty = await getImageDimension(croppedImage)

      let resizedImage = ''
      if (imageProperty.width > 360) {
        resizedImage = await resizeImage(croppedImage, 360, 360)
      }
      actionsProps.setFieldValue(
        x => (x.pictureUrl = resizedImage !== '' ? resizedImage : croppedImage)
      )
      setIsLoading(false)
    } catch (e) {
      console.error(e)
      setIsLoading(false)
    }
  }, [croppedAreaPixels, rotation])

  const openCancelConfirmationModal = () => {
    setShowConfirmationModal(true)
    setConfirmationModalProps({
      title: 'Hapus Perubahan',
      content: 'Apakah anda yakin ingin menghapus Perubahan ?',
      submitTheme: 'danger',
      onSubmit: () => {
        setShowConfirmationModal(false)
        setShowModal(false)
      },
    } as ConfirmationModalProps)
  }

  const openSubmitConfirmationModal = () => {
    setShowConfirmationModal(true)
    setConfirmationModalProps({
      title: 'Gunakan Foto Produk',
      content: 'Apakah anda yakin ingin menggunakan Foto ini ?',
      submitTheme: 'primary',
      onSubmit: async () => {
        await setCroppedImage()
        setShowConfirmationModal(false)
        setShowModal(false)
      },
    } as ConfirmationModalProps)
  }

  return (
    <Modal
      isOpen={showModal}
      onRequestClose={openCancelConfirmationModal}
      contentLabel='Unggah Foto Produk'
      ariaHideApp={false}
      style={{
        overlay: {
          zIndex: 1001,
          backgroundColor: 'rgba(51,51,51,0.8)',
          display: 'flex',
          placeContent: 'center',
          alignItems: 'center',
          padding: '1rem 2rem',
        },
        content: {
          position: 'unset',
          height: '100%',
          maxHeight: '716px',
          width: '100%',
          maxWidth: '820px',
          padding: '20px 0',
        },
      }}
    >
      <VSided.VSided>
        <VSided.Fixed>
          <Sided.Sided justifyContent='center' style={{ padding: '0 16px' }}>
            <Sided.Remaining align='left'>
              <Trans>
                <Text.Heading h={5}>Unggah Foto Produk</Text.Heading>
              </Trans>
            </Sided.Remaining>
            <Sided.Fixed>
              <Icon.Close
                size={24}
                color='black'
                onClick={openCancelConfirmationModal}
              />
            </Sided.Fixed>
          </Sided.Sided>
          <Divider />
        </VSided.Fixed>
        {isLoading ? (
          <VSided.Remaining>
            <Loader />
          </VSided.Remaining>
        ) : (
          <>
            <VSided.Remaining>
              <Sided.Sided
                justifyContent='center'
                gutter={24}
                className='card-section'
                style={{
                  position: 'relative',
                  width: '100%',
                  height: '100%',
                  padding: '0 16px',
                }}
              >
                <Sided.Remaining style={{ position: 'relative' }}>
                  <Cropper
                    image={image}
                    aspect={1 / 1}
                    crop={crop}
                    onCropChange={setCrop}
                    onCropComplete={(croppedArea, croppedAreaPixels) => {
                      setCroppedAreaPixels(croppedAreaPixels)
                    }}
                    zoom={zoom}
                    onZoomChange={setZoom}
                    rotation={rotation}
                    onRotationChange={setRotation}
                  />
                </Sided.Remaining>
              </Sided.Sided>
            </VSided.Remaining>
            <VSided.Fixed>
              <div style={{ padding: '0 16px' }}>
                <Sided.Sided
                  justifyContent='center'
                  justify='center'
                  gutter={8}
                  style={{ height: '40px' }}
                >
                  <Sided.Fixed>
                    <Icon.Minus
                      size={16}
                      color='black'
                      onClick={() => {
                        if (zoom > 1) setZoom(zoom - 0.1)
                      }}
                    />
                  </Sided.Fixed>
                  <Sided.Remaining>
                    <Slider
                      value={zoom}
                      min={1}
                      max={3}
                      step={0.1}
                      onChange={(value: any) => setZoom(value)}
                    />
                  </Sided.Remaining>
                  <Sided.Fixed>
                    <Icon.Add
                      size={16}
                      color='black'
                      onClick={() => {
                        if (zoom < 3) setZoom(zoom + 0.1)
                      }}
                    />
                  </Sided.Fixed>
                </Sided.Sided>
                <Sided.Sided
                  justifyContent='center'
                  justify='center'
                  gutter={8}
                  style={{ height: '40px' }}
                >
                  <Icon.RotateLeft
                    size={24}
                    color='black'
                    onClick={() => setRotation(rotation - 90)}
                  />
                  <Divider position='vertical' color='gray' />
                  <Icon.RotateRight
                    size={24}
                    color='black'
                    onClick={() => setRotation(rotation + 90)}
                  />
                </Sided.Sided>
              </div>
            </VSided.Fixed>
          </>
        )}
        <VSided.Fixed minHeight={76}>
          <Wrapper
            shadow='0 -2px 4px 0 rgba(0, 0, 0, 0.2)'
            width='100%'
            padding='16px'
            backgroundColor='white'
          >
            <Wrapper width='340px' isCenter>
              <Sided.Sided justify='center' gutter={16}>
                <Sided.Remaining>
                  <Button
                    onClick={openCancelConfirmationModal}
                    size='large'
                    theme='netral'
                    type='button'
                    label={i18n._(t`Pilih Foto Lain`)}
                    fullW={true}
                    disabled={isLoading}
                  />
                </Sided.Remaining>
                <Sided.Remaining>
                  <Button
                    onClick={openSubmitConfirmationModal}
                    size='large'
                    theme='primary'
                    type='submit'
                    label={i18n._(t`Gunakan`)}
                    fullW={true}
                    disabled={isLoading}
                  />
                </Sided.Remaining>
              </Sided.Sided>
            </Wrapper>
          </Wrapper>
        </VSided.Fixed>
      </VSided.VSided>
      <ConfirmationModal
        showModal={showConfirmationModal}
        close={() => setShowConfirmationModal(false)}
        {...confirmationModalProps}
      />
    </Modal>
  )
}

export default CropImageModal
