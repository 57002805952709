import { i18n } from '@lingui/core'
import { t } from '@lingui/macro'

import { APP_SETTINGS } from 'constants/appSettings'
import { REGEX } from 'constants/regex'
import { SchemaValidation } from 'utilities/validationCheck'

export const accountSettingsSchema = SchemaValidation.object().shape({
  businessName: SchemaValidation.string()
    .min(
      APP_SETTINGS.MIN_BUSINESS_NAME_LENGTH,
      i18n._(
        t`Nama Bisnis tidak boleh kurang dari ${APP_SETTINGS.MIN_BUSINESS_NAME_LENGTH} karakter`
      )
    )
    .required(i18n._(t`Nama Bisnis tidak boleh kosong`)),
  phone: SchemaValidation.string()
    .matches(REGEX.PHONE, i18n._(t`Format No Handphone salah`))
    .required(i18n._(t`No Handphone tidak boleh kosong`)),
  email: SchemaValidation.string()
    .email(i18n._(t`Format email tidak valid`))
    .required(i18n._(t`Email tidak boleh kosong`)),
})
