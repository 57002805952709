import { AppState } from 'domains/contracts/appState'
import { UserState } from 'domains/users/states'
import { OutletModel } from 'domains/management/outlets/models'
import { QRISPaymentMethod } from 'domains/management/paymentMethod/models'
import { QrisModalConfig } from 'domains/commons/states'
import { toList } from 'utilities/converter/list'

interface QRISModalViewModel {
  user: UserState
  outletList: OutletModel[]
  qrisPaymentMethod: QRISPaymentMethod[]
  qrisModal: QrisModalConfig
}

export default (state: AppState): QRISModalViewModel => ({
  user: state.user,
  outletList: toList(state.management.outlet.outlets),
  qrisPaymentMethod: state.management.paymentMethod.QRISPaymentMethods,
  qrisModal: state.common.qrisModal,
})
