import { Reducer, StateActions } from 'utilities/redux/reducer'
import { CategoryState } from 'domains/management/category/states'
import {
  SET_CATEGORY_PRODUCT,
  PUSH_CATEGORY,
  SET_CATEGORY_LIST_REACH_BOTTOM,
  SET_LOADING_MORE_CATEGORY,
  SET_CATEGORY_LIST_REQUEST,
  UNSHIFT_CATEGORY,
} from './actions'
import {
  Category,
  GettingCategoriesRequest,
} from 'domains/management/category/models'
import { Dict } from 'utilities/type'

export class CategoryReducer extends Reducer<CategoryState> {
  constructor() {
    super({
      categories: {},
      categoryListManager: {
        isLoadingMoreCategory: false,
        request: {},
        hasReachedBottom: false,
      },
    })
  }

  setCategoryProduct = (
    state: CategoryState,
    categories: Dict<Category>
  ): CategoryState => {
    return {
      ...state,
      categories: categories,
    }
  }

  pushCategory = (
    state: CategoryState,
    categories: Dict<Category>
  ): CategoryState => {
    return {
      ...state,
      categories: {
        ...state.categories,
        ...categories,
      },
    }
  }

  unshiftCategory = (
    state: CategoryState,
    categories: Dict<Category>
  ): CategoryState => {
    return {
      ...state,
      categories: {
        ...categories,
        ...state.categories,
      },
    }
  }

  setCategoryListReachBottom = (
    state: CategoryState,
    hasReachedBottom: boolean
  ): CategoryState => {
    return {
      ...state,
      categoryListManager: {
        ...state.categoryListManager,
        hasReachedBottom,
      },
    }
  }

  setLoadingMoreCategory = (
    state: CategoryState,
    isLoadingMoreCategory: boolean
  ): CategoryState => {
    return {
      ...state,
      categoryListManager: {
        ...state.categoryListManager,
        isLoadingMoreCategory,
      },
    }
  }

  setCategoryListRequest = (
    state: CategoryState,
    request: GettingCategoriesRequest
  ): CategoryState => {
    return {
      ...state,
      categoryListManager: {
        ...state.categoryListManager,
        request,
      },
    }
  }

  get actions(): StateActions<CategoryState> {
    return {
      [SET_CATEGORY_PRODUCT]: this.setCategoryProduct,
      [PUSH_CATEGORY]: this.pushCategory,
      [UNSHIFT_CATEGORY]: this.unshiftCategory,
      [SET_CATEGORY_LIST_REACH_BOTTOM]: this.setCategoryListReachBottom,
      [SET_LOADING_MORE_CATEGORY]: this.setLoadingMoreCategory,
      [SET_CATEGORY_LIST_REQUEST]: this.setCategoryListRequest,
    }
  }
}
