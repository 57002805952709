import {
  SET_CATEGORY_PRODUCT,
  PUSH_CATEGORY,
  SET_CATEGORY_LIST_REACH_BOTTOM,
  SET_LOADING_MORE_CATEGORY,
  SET_CATEGORY_LIST_REQUEST,
} from './actions'
import {
  Category,
  GettingCategoriesRequest,
} from 'domains/management/category/models'
import { Dict } from 'utilities/type'
import { BaseStorage } from 'storages/base'
import { ICategoryStorage } from 'domains/management/category/contracts/storage'

export class CategoryStorage extends BaseStorage implements ICategoryStorage {
  public setCategories(categoryDict: Dict<Category>): void {
    this.dispatch(SET_CATEGORY_PRODUCT, categoryDict)
  }

  public pushCategories(categoryDict: Dict<Category>): void {
    this.dispatch(PUSH_CATEGORY, categoryDict)
  }

  public unshiftCategories(categoryDict: Dict<Category>): void {
    this.dispatch(PUSH_CATEGORY, categoryDict)
  }

  public setCategoriesReachBottom(hasReachedBottom: boolean): void {
    this.dispatch(SET_CATEGORY_LIST_REACH_BOTTOM, hasReachedBottom)
  }

  public setLoadingMoreCategory(isLoadingMoreCategory: boolean): void {
    this.dispatch(SET_LOADING_MORE_CATEGORY, isLoadingMoreCategory)
  }
  public setGettingCategoriesRequest(request: GettingCategoriesRequest): void {
    this.dispatch(SET_CATEGORY_LIST_REQUEST, request)
  }
}
