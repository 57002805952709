import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import './style.css'
import { BaseParentProps } from '../props'

interface Props extends BaseParentProps {
  isActive?: boolean
  onChange?: (isActive: boolean) => any
  isDisabled?: boolean
  onClick?: () => any
  style?: React.CSSProperties
  color?: 'green'
}

const SwitchButton = (props: Props) => {
  const [isActive, setIsActive] = useState(props.isActive)

  const handleOnClick = () => {
    if (props.onChange) {
      props.onChange(!isActive)
    }
    setIsActive(!isActive)
  }

  useEffect(() => {
    setIsActive(props.isActive)
  }, [props])

  const className = classNames(
    'post-switch',
    isActive ? 'post-switch-active' : '',
    props.isDisabled ? 'post-switch-disable' : '',
    props.color || ''
  )

  const style: React.CSSProperties = {
    cursor: props.onChange ? 'pointer' : 'default',
    ...props.style,
  }

  return (
    <div
      style={style}
      className={className}
      onClick={handleOnClick}
      data-testid='switch-button'
    ></div>
  )
}

export default SwitchButton
