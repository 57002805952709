import { BaseApi } from 'api/base.api'
import {
  GetRecipesQueryParams,
  RecipeBodyModel,
  RecipeModel,
} from 'domains/ingredients/models'

export default class RecipeApi extends BaseApi {
  constructor() {
    super('/v3')
  }

  public getRecipes(
    queryParams?: GetRecipesQueryParams
  ): Promise<RecipeModel[]> {
    return this.makeForList('GET', '/recipes', queryParams)
  }

  public createRecipe(recipe: RecipeBodyModel): Promise<RecipeBodyModel> {
    return this.make('POST', '/recipes', recipe)
  }

  public updateRecipe(recipe: RecipeBodyModel): Promise<RecipeBodyModel> {
    return this.make('POST', `/recipes/${recipe.id}`, recipe)
  }

  public deleteRecipe(recipeId: string): Promise<void> {
    return this.make('DELETE', `/recipes/${recipeId}`)
  }
}
