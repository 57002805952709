import { BaseStorage } from 'storages/base'
import { AlertConfig, QrisModalConfig } from 'domains/commons/states'
import { ICommonStorage } from 'domains/commons/contracts/storage'
import { AppStore } from 'storages'
import { AppState } from 'domains/contracts/appState'
import {
  SET_LOADING,
  SET_ALERT_CONFIG,
  MODAL_LAYER_SET,
  MODAL_LAYER_PUSH,
  MODAL_LAYER_REMOVE,
  QRIS_BANNER_VISIBLE,
  QRIS_MODAL,
} from './actions'

export class CommonStorage extends BaseStorage implements ICommonStorage {
  setLoading(key: string, value: boolean): void {
    this.dispatch(SET_LOADING, {
      key,
      value,
    })
  }

  getState(): AppState {
    return AppStore.getState()
  }

  setAlertConfig(alert?: AlertConfig): void {
    this.dispatch(SET_ALERT_CONFIG, alert)
  }

  setModalLayers(modalComponents: { id: string }[]) {
    this.dispatch(MODAL_LAYER_SET, modalComponents)
  }
  pushModalLayer(modalComponent: { id: string }) {
    this.dispatch(MODAL_LAYER_PUSH, modalComponent)
  }
  removeModalLayer(id: string) {
    this.dispatch(MODAL_LAYER_REMOVE, id)
  }
  setQrisBannerVisible(visible: boolean) {
    this.dispatch(QRIS_BANNER_VISIBLE, visible)
  }
  setQrisModal(qrisModal: QrisModalConfig) {
    this.dispatch(QRIS_MODAL, qrisModal)
  }
}
