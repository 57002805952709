import React, { useEffect } from 'react'
import { ModalProps, Modal, DefaultModalService } from 'views/components/Modal'
import { DeviceModel } from 'domains/management/device/models'
import {
  FormAction,
  Form,
  Wrapper,
  Text,
  Break,
  Panel,
  Field,
  Sided,
  Button,
  Divider,
  VSided,
} from 'views/components'
import { outletService, deviceService, commonService } from 'injectors'
import { useSelector } from 'react-redux'
import { AppState } from 'domains/contracts/appState'
import { toList } from 'utilities/converter/list'
import { DropdownItem } from 'views/components/Field/Dropdown'
import DeviceFormSchema, { DeviceFormField } from './deviceFormSchema'
import { toDateString } from '../../../../../utilities/converter/date'
import { DeleteDeviceModal } from '../DeleteDeviceModal'
import copyToClipboard from 'utilities/strings/copytoClipboard'
import { trimDuplicateSpace } from 'utilities/strings'

interface DeviceFormModalProps extends ModalProps {
  device?: DeviceModel
}

const DeviceFormModal: React.FC<DeviceFormModalProps> = props => {
  const outletDropdownItem = useSelector<AppState, DropdownItem<string>[]>(
    state => {
      const outletList = toList(state.management.outlet.outlets)
      return outletList.map(outlet => ({
        value: outlet.id,
        label: outlet.name,
      }))
    }
  )

  useEffect(() => {
    outletService.getOutlets()
  }, [])

  const initialValue: DeviceFormField | any = props.device
    ? {
        name: props.device.name,
        outletId: props.device.outlet.id,
      }
    : {}

  const handleSubmit = async (actions: FormAction<DeviceFormField>) => {
    try {
      let res = {}
      const values = actions.getValues()
      const trimmedValues = {
        ...values,
        name: trimDuplicateSpace(values.name),
      }
      if (props.device) {
        await deviceService.updateDevice(props.device.id, trimmedValues)
        commonService.setAlert({
          type: 'success',
          message: 'Kode Akses Kasir berhasil diubah',
        })
      } else {
        res = await deviceService.createDevice(trimmedValues)
        commonService.setAlert({
          type: 'success',
          message: 'Kode Akses Kasir baru berhasil disimpan',
        })
      }
      props.close(res)
    } catch (error) {
      commonService.setAlert({
        type: 'error',
        message: error.message,
      })
    }
  }

  const textTitle = props.device
    ? 'Ubah Kode Akses Kasir'
    : 'Tambah Kode Akses Kasir'

  return (
    <Modal dismiss={props.dismiss} type='full' canDismissOutside={false}>
      <Form.Form
        height='100%'
        initialValues={initialValue}
        validationSchema={DeviceFormSchema}
        onSubmit={handleSubmit}
        render={(actions: FormAction<DeviceFormField>) => (
          <VSided.VSided>
            <VSided.Remaining>
              <Break height={24} />
              <Wrapper width='480px' isCenter verticalGap={24}>
                <Text.Heading h={4} align='left' noMargin>
                  {' '}
                  {textTitle}{' '}
                </Text.Heading>
                <Panel.Card padding={16}>
                  <Text.Heading h={6}>Detail Kode Akses</Text.Heading>
                  <Divider />
                  <Break height={10} />
                  <Field.Text
                    placeholder='Contoh: Perangkat 1'
                    name='name'
                    label='Nama Perangkat'
                  />
                  <Field.Dropdown
                    list={outletDropdownItem}
                    placeholder='Ayam Geprek'
                    name='outletId'
                    label='Beroperasi di Outlet'
                    onChange={val => console.error(val)}
                  />
                  {props.device && (
                    <Wrapper>
                      <Panel.Card
                        backgroundColor='darkgray'
                        padding={16}
                        style={{ border: 'none' }}
                      >
                        <Text.Paragraph size={14} color='black'>
                          Kode Akses Kasir
                        </Text.Paragraph>
                        <Text.Paragraph size={20} weight='bold' color='black'>
                          {props.device.activationCode}
                        </Text.Paragraph>
                        <Text.Paragraph size={10} color='black'>
                          Sinkronisasi terakhir
                        </Text.Paragraph>
                        <Text.Paragraph size={14} weight='bold' color='black'>
                          {props.device.synchronizedAt
                            ? toDateString(props.device.synchronizedAt, 'LLLL')
                            : '-'}
                        </Text.Paragraph>
                      </Panel.Card>
                      <Break height={24} />
                      <Text.Paragraph
                        size={14}
                        color='blue'
                        align='center'
                        onClick={() =>
                          copyToClipboard(props.device.activationCode)
                        }
                      >
                        Salin Kode Akses Kasir
                      </Text.Paragraph>
                    </Wrapper>
                  )}
                </Panel.Card>
                {props.device && (
                  <React.Fragment>
                    <Text.Paragraph
                      size={14}
                      color='red'
                      align='center'
                      onClick={() =>
                        DefaultModalService.open(DeleteDeviceModal, {
                          device: props.device,
                        })
                      }
                    >
                      Hapus
                    </Text.Paragraph>
                  </React.Fragment>
                )}
              </Wrapper>
            </VSided.Remaining>
            <VSided.Fixed>
              <Wrapper
                padding='16px'
                backgroundColor='#ffffff'
                shadow='0 -2px 4px 0 rgba(0, 0, 0, 0.2)'
              >
                <Wrapper width='480px' isCenter>
                  <Sided.Sided justify='center' gutter={16}>
                    <Sided.Remaining>
                      <Button
                        theme='netral'
                        label='Batal'
                        size='large'
                        fullW
                        onClick={() => props.close({})}
                      />
                    </Sided.Remaining>
                    <Sided.Remaining>
                      <Button
                        disabled={!actions.isValid()}
                        type='submit'
                        theme='primary'
                        label='Simpan'
                        size='large'
                        fullW
                      />
                    </Sided.Remaining>
                  </Sided.Sided>
                </Wrapper>
              </Wrapper>
            </VSided.Fixed>
          </VSided.VSided>
        )}
      />
    </Modal>
  )
}

export default DeviceFormModal
