import { Reducer, StateActions } from 'utilities/redux/reducer'
import { User, BusinessCategory, OnboardingSteps } from 'domains/users/models'
import { UserState } from 'domains/users/states'
import {
  SET_USER,
  SET_BUSINESS_CATEGORIES,
  SET_ONBOARDING_STEPS,
  SET_IS_FNB_BUSINESS_CATEGORY,
} from './actions'
import { Group, Dict } from 'utilities/type'

export class UserReducer extends Reducer<UserState> {
  constructor() {
    super({} as User)
  }

  public setUser(state: UserState, user: User): UserState {
    return {
      ...state,
      ...user,
    }
  }

  public setBusinessCategories(
    state: UserState,
    businessCategories: Group<BusinessCategory>
  ): UserState {
    return {
      ...state,
      businessCategories: businessCategories,
    }
  }

  public setOnboardingSteps(
    state: UserState,
    onboardingSteps: Dict<OnboardingSteps>
  ): UserState {
    return {
      ...state,
      onboardingSteps,
    }
  }

  public setIsFnbBusinessCategory(
    state: UserState,
    isFnbBusinessCategory: boolean
  ): UserState {
    return {
      ...state,
      isFnbBusinessCategory,
    }
  }

  get actions(): StateActions<UserState> {
    return {
      [SET_USER]: this.setUser,
      [SET_BUSINESS_CATEGORIES]: this.setBusinessCategories,
      [SET_ONBOARDING_STEPS]: this.setOnboardingSteps,
      [SET_IS_FNB_BUSINESS_CATEGORY]: this.setIsFnbBusinessCategory,
    }
  }
}
