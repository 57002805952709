import { i18n } from '@lingui/core'
import { t } from '@lingui/macro'
import { SchemaValidation } from 'utilities/validationCheck'

export const PasswordConfimationSchema = SchemaValidation.object().shape({
  password: SchemaValidation.string()
    .minNoTrim(8, i18n._(t`Minimal 8 karakter`))
    .required(i18n._(t`Password harus diisi`)),
})

export const EmailSchema = SchemaValidation.object().shape({
  email: SchemaValidation.string()
    .email(i18n._(t`Format email salah`))
    .required(i18n._(t`Email harus diisi`)),
})

export const BankAccountSchema = SchemaValidation.object().shape({
  bankShortCode: SchemaValidation.string().required(i18n._(t`Pilih Bank`)),
  accountNo: SchemaValidation.string()
    .min(3, 'Nomor Rekening tidak boleh kurang dari 3 digit')
    .required(i18n._(t`Nomor Rekening Bank harus diisi`)),
})
