import { t, Trans } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import React from 'react'

import { PasswordRequest } from 'domains/users/models'
import { userService } from 'injectors'
import {
  Panel,
  Text,
  Icon,
  Break,
  Button,
  Field,
  FormAction,
  Form,
  Wrapper,
  Sided,
} from 'views/components'
import { ModalProps, Modal } from 'views/components/Modal'

import { PasswordConfimationSchema } from '../schema'

type Props = ModalProps

const PasswordConfirmationModal: React.FC<Props> = props => {
  const { i18n } = useLingui()

  const handleCheckPassword = async (action: FormAction<PasswordRequest>) => {
    try {
      const passwordRequest: PasswordRequest = {
        password: action.getValues().password,
      }
      await userService.checkPassword(passwordRequest)
      props.close(true)
    } catch {
      action.setFieldError('password', i18n._(t`Password anda salah`))
    }
  }

  const passwordForm = () => (
    <Form.Form
      height='100%'
      initialValues={
        {
          password: undefined,
        } as PasswordRequest
      }
      validationSchema={PasswordConfimationSchema}
      render={(action: FormAction<PasswordRequest>) => {
        return (
          <>
            <Field.Password label={i18n._(t`Password`)} name='password' />
            <Break height={16} />
            <Wrapper padding='5px'>
              <Button
                size='large'
                theme='primary'
                type='button'
                label={i18n._(t`Lanjut`)}
                fullW={true}
                onClick={() => handleCheckPassword(action)}
              />
            </Wrapper>
          </>
        )
      }}
    />
  )

  return (
    <Modal
      width={'470px'}
      dismiss={props.dismiss}
      type='blank'
      canDismissOutside={false}
    >
      <Panel.Panel>
        <Panel.Header>
          <Sided.Sided justifyContent='center' justify='center'>
            <Sided.Remaining>
              <Trans>
                <Text.Heading h={6} color='black'>
                  Konfirmasi Password
                </Text.Heading>
              </Trans>
            </Sided.Remaining>
            <Sided.Fixed>
              <Icon.Close
                size={24}
                style={{ cursor: 'pointer' }}
                onClick={() => props.dismiss()}
              />
            </Sided.Fixed>
          </Sided.Sided>
        </Panel.Header>
        <Panel.Content>
          <Trans>
            <Text.Paragraph size={14} color='black'>
              Masukkan password akun Anda untuk mengubah Akun Bisnis.
            </Text.Paragraph>
          </Trans>
          <Break height={16} />
          {passwordForm()}
        </Panel.Content>
      </Panel.Panel>
    </Modal>
  )
}

export default PasswordConfirmationModal
