import React from 'react'
import { Text } from 'views/components'
import {
  RTableConfig,
  RColumnConfig,
  convertToReactTableConfig,
} from 'views/components/Table'
import { ProductStockHistory } from 'domains/management/products/models'
import { toDateString } from 'utilities/converter/date'
import { i18n } from '@lingui/core'
import { t } from '@lingui/macro'

function formatDateRange(item: ProductStockHistory) {
  if (item.fromDate === item.toDate) {
    return toDateString(item.toDate, 'DD/MM/YY')
  }
  if (item.fromDate && item.toDate) {
    return (
      toDateString(item.fromDate, 'DD/MM/YY') +
      '-' +
      toDateString(item.toDate, 'DD/MM/YY')
    )
  }
  let singleDate = ''
  if (item.toDate) {
    singleDate = item.toDate
  }
  if (item.fromDate) {
    singleDate = item.fromDate
  }
  if (singleDate) {
    return toDateString(singleDate, 'DD/MM/YY')
  }
  return '-'
}

export const generateTableConfig = (): RTableConfig<ProductStockHistory> => {
  const columns: RColumnConfig<ProductStockHistory>[] = [
    {
      title: i18n._(t`Tanggal`),
      align: 'left',
      body: (item: ProductStockHistory) => (
        <Text.Paragraph size={14} weight='regular' color='black' align='left'>
          {formatDateRange(item)}
        </Text.Paragraph>
      ),
    },
    {
      title: i18n._(t`Penyesuaian`),
      align: 'left',
      body: (item: ProductStockHistory) => (
        <Text.Paragraph size={14} weight='regular' color='black' align='left'>
          {item.type}
        </Text.Paragraph>
      ),
    },
    {
      title: i18n._(t`Jumlah`),
      align: 'right',
      body: (item: ProductStockHistory) => (
        <Text.Paragraph size={14} weight='regular' color='black' align='right'>
          {item.value}
        </Text.Paragraph>
      ),
    },
    {
      title: i18n._(t`Catatan`),
      align: 'left',
      body: (item: ProductStockHistory) => (
        <Text.Paragraph size={14} weight='regular' color='black' align='left'>
          {item.note}
        </Text.Paragraph>
      ),
    },
  ]
  return convertToReactTableConfig(columns)
}
