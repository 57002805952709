import { BaseReportingService } from '.'
import { SalesFact, ReportingFilter, SalesFactQueryString } from '../models'
import { toQueryISOString } from 'utilities/converter/date'
import FileSaver from 'file-saver'

export interface ISalesFactReportingService {
  getSalesFacts(filter?: ReportingFilter): Promise<SalesFact[]>
  downloadSalesFactsCsv(filter?: ReportingFilter): Promise<void>
}

export class SalesFactReportingService
  extends BaseReportingService
  implements ISalesFactReportingService
{
  public async getSalesFacts(filter?: ReportingFilter) {
    try {
      const queryParams: SalesFactQueryString = {
        outletId: filter.selectedOutletId || '',
        fromDate: filter.dateRange
          ? toQueryISOString(filter.dateRange.from, true)
          : '',
        toDate: filter.dateRange
          ? toQueryISOString(filter.dateRange.to, true)
          : '',
      }
      return await this.api.reporting.getSalesFacts(queryParams)
    } catch (error) {
      throw error
    }
  }

  public async downloadSalesFactsCsv(filter?: ReportingFilter) {
    try {
      const queryParams: SalesFactQueryString = {
        outletId: filter.selectedOutletId || '',
        fromDate: filter.dateRange
          ? toQueryISOString(filter.dateRange.from, true)
          : '',
        toDate: filter.dateRange
          ? toQueryISOString(filter.dateRange.to, true)
          : '',
      }
      const response = await this.api.reporting.downloadSalesFactsCsv(
        queryParams
      )
      const data = new Blob([response], {
        type: 'application/csv;charset=UTF-8',
      })
      FileSaver.saveAs(data, 'product-sale-detail.csv')
    } catch (error) {
      throw error
    }
  }
}
