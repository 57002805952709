import { BaseService } from 'domains/commons/services'

import {
  BankAccountCreateModel,
  BankAccountModel,
  BankModel,
  BankOTPModel,
  BankOTPRequest,
  BankAccountCheckModel,
  BankAccountCheckResponse,
} from '../models'

export class BankService extends BaseService implements IBankService {
  public getBanks = async () => {
    try {
      return await this.api.bank.getBanks()
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  public getBankAccounts = () => {
    return this.api.bank.getBankAccounts()
  }

  public createBankAccount = (bankAccount: BankAccountCreateModel) => {
    return this.api.bank.createBankAccount(bankAccount)
  }

  public validateBankAccount = (bankAccount: BankAccountCheckModel) => {
    return this.api.bank.validateBankAccount(bankAccount)
  }

  public createOTP = () => {
    return this.api.bank.createOTP()
  }

  public validateOTP = (token: string, request: BankOTPRequest) => {
    return this.api.bank.validateOTP(token, request)
  }

  public resendOTP = (token: string) => {
    return this.api.bank.resendOTP(token)
  }
}

export interface IBankService {
  getBanks: () => Promise<BankModel[]>
  getBankAccounts: () => Promise<BankAccountModel[]>
  createBankAccount: (
    bankAccount: BankAccountCreateModel
  ) => Promise<BankAccountModel>
  validateBankAccount: (
    bankAccount: BankAccountCheckModel
  ) => Promise<BankAccountCheckResponse>
  createOTP: () => Promise<BankOTPModel>
  validateOTP: (token: string, request: BankOTPRequest) => Promise<void>
  resendOTP: (token: string) => Promise<void>
}
