import { BaseService } from 'domains/commons/services'
import { Api } from 'domains/contracts/api'
import { ICommonStorage } from 'domains/commons/contracts/storage'
import { ICustomerStorage } from 'domains/management/customer/contracts/storage'
import {
  CustomerRequest,
  Customer,
  CustomerQueryParams,
  CustomerModelForMassUpload,
  MassCustomer,
} from '../models'
import { toDict } from 'utilities/converter/list'
import { ExtractedFileData } from 'utilities/file/upload'
import { convertExcelBinaryTo2DArray } from 'utilities/csv/fileTo2DArray'

const SET_CUSTOMER = 'SET_CUSTOMER'

export class CustomerService extends BaseService implements ICustomerService {
  customerStorage: ICustomerStorage

  constructor(
    commonStorage: ICommonStorage,
    api: Api,
    customerStorage: ICustomerStorage
  ) {
    super(commonStorage, api)
    this.customerStorage = customerStorage
  }

  public getCustomers = async (
    queryParams: CustomerQueryParams = {}
  ): Promise<Customer[]> => {
    try {
      this.setLoading(SET_CUSTOMER, false)
      const newCustomers = await this.api.management.customer.getCustomers(
        queryParams
      )
      if (newCustomers !== null) {
        this.customerStorage.setCustomers(toDict(newCustomers, x => x.id))
        this.setLoading(SET_CUSTOMER, true)
        const lastCustomerId = this.getLastCustomersId(newCustomers)
        this.customerStorage.setCustomerListRequest({
          ...queryParams,
          startingAfter: lastCustomerId || queryParams.startingAfter,
        } as CustomerQueryParams)
        this.customerStorage.setCustomerListReachBottom(
          newCustomers.length == 0
        )
      } else {
        this.setLoading(SET_CUSTOMER, true)
      }
      return newCustomers
    } catch (error) {
      throw error
    }
  }

  private getLastCustomersId = (customers: Customer[]): string | undefined => {
    return customers.length > 0 ? customers[customers.length - 1].id : undefined
  }

  public loadMore = async (): Promise<Customer[]> => {
    const customerListManager =
      this.getState().management.customer.customerListManager
    if (customerListManager.hasReachedBottom) {
      return Promise.resolve([])
    }
    if (customerListManager.isLoadingMoreCustomer) {
      return Promise.resolve([])
    }
    this.customerStorage.setLoadingMoreCustomer(true)
    const queryParams = customerListManager.request
    const customers = await this.api.management.customer.getCustomers(
      queryParams
    )
    if (customers !== null) {
      this.customerStorage.pushCustomer(
        toDict(customers, (p: Customer) => p.id)
      )
      const lastCustomerId = this.getLastCustomersId(customers)
      this.customerStorage.setLoadingMoreCustomer(false)
      this.customerStorage.setCustomerListRequest({
        ...queryParams,
        startingAfter: lastCustomerId || queryParams.startingAfter,
      } as CustomerQueryParams)
      this.customerStorage.setCustomerListReachBottom(customers.length == 0)
    } else {
      this.customerStorage.setCustomerListReachBottom(true)
      this.customerStorage.setLoadingMoreCustomer(false)
    }
    return customers
  }

  public createCustomer = async (request: CustomerRequest) => {
    try {
      const response = await this.api.management.customer.createCustomer(
        request
      )
      const customersState = this.getState().management.customer.customers
      customersState[response.id] = response
      this.customerStorage.setCustomers(customersState)
    } catch (error) {
      throw error
    }
  }

  public updateCustomer = async (request: CustomerRequest, id: string) => {
    try {
      const response = await this.api.management.customer.updateCustomer(
        request,
        id
      )
      const customersState = this.getState().management.customer.customers
      customersState[response.id] = response
      this.customerStorage.setCustomers(customersState)
    } catch (error) {
      throw error
    }
  }

  public deleteCustomer = async (id: string) => {
    try {
      await this.api.management.customer.deleteCustomer(id)
      const customersState = this.getState().management.customer.customers
      delete customersState[id]
      this.customerStorage.setCustomers(customersState)
    } catch (error) {
      throw error
    }
  }

  public convertMassCustomers = async (
    file: ExtractedFileData
  ): Promise<CustomerModelForMassUpload> => {
    const jsonData = convertExcelBinaryTo2DArray(file.binary)
    const requiredCells = ['Nama', 'Nomor Kontak', 'Email', 'Alamat']
    const isValidData = requiredCells.reduce(
      (final, current) => jsonData.cells[0].includes(current),
      true
    )
    if (!isValidData || jsonData.list.length > 200) {
      throw new Error('Data tabel tidak valid.')
    }
    const massCustomers = jsonData.list.map(x => ({
      name: x['Nama'] || '',
      phone: x['Nomor Kontak'] || '',
      email: x['Email'] || '',
      address: x['Alamat'] || '',
    })) as MassCustomer[]

    return {
      massCustomers,
      fileInfo: file,
    }
  }

  public bulkImportCustomers = async (
    customers: MassCustomer[]
  ): Promise<void> => {
    try {
      await this.api.management.customer.bulkImportCustomers(customers)
    } catch (error) {
      throw error
    }
  }
}

export interface ICustomerService {
  getCustomers: (queryParams?: CustomerQueryParams) => Promise<Customer[]>
  createCustomer: (request: CustomerRequest) => Promise<void>
  updateCustomer: (request: CustomerRequest, id: string) => Promise<void>
  deleteCustomer: (id: string) => Promise<void>
  loadMore: () => Promise<Customer[]>
  convertMassCustomers: (
    file: ExtractedFileData
  ) => Promise<CustomerModelForMassUpload>
  bulkImportCustomers: (customers: MassCustomer[]) => Promise<void>
}
