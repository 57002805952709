import { getLocalStorage } from 'utilities/localStorage'
import { CONFIG_LOCALSTORAGE } from 'constants/localStorage'
import { AxiosRequestConfig } from 'axios'

export const preRequestInterceptor = (
  config: AxiosRequestConfig
): AxiosRequestConfig => {
  const token = getLocalStorage(CONFIG_LOCALSTORAGE.POST_TOKEN)
  if (token) {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${token}`,
    }
  }
  return config
}
