const PREFIX = 'PRODUCT.'

export const SET_PRODUCTS = 'SET_PRODUCTS'
export const SET_PRODUCTS_V2 = 'SET_PRODUCTS_V2'
export const SET_LOADING_MORE_PRODUCT = 'IS_LOADING_MORE_PRODUCT'
export const PUSH_PRODUCTS = 'PUSH_PRODUCTS'
export const PUSH_PRODUCTS_V2 = 'PUSH_PRODUCTS_V2'
export const UNSHIFT_PRODUCTS = 'UNSHIFT_PRODUCTS'
export const SET_PRODUCT_LIST_REACH_BOTTOM = 'SET_PRODUCT_LIST_REACH_BOTTOM'
export const SET_PRODUCT_LIST_REQUEST = 'SET_PRODUCT_LIST_REQUEST'
export const SET_LOADING_MORE_PRODUCT_V2 = 'IS_LOADING_MORE_PRODUCT_V2'
export const SET_PRODUCT_LIST_REACH_BOTTOM_V2 =
  'SET_PRODUCT_LIST_REACH_BOTTOM_V2'
export const SET_PRODUCT_LIST_REQUEST_V2 = 'SET_PRODUCT_LIST_REQUEST_V2'
export const SET_COMBO_PRODUCTS = 'SET_COMBO_PRODUCTS'
export const SET_VARIATION_PRODUCTS = 'SET_VARIATION_PRODUCTS'
export const SET_PRODUCT_LIST = PREFIX + 'SET_PRODUCT_LIST'
