import React, { useEffect } from 'react'

import { cdnImageUrl } from 'constants/cdnUrl/imageUrl'
import { MassProduct } from 'domains/management/products/models'
import { Wrapper, Text, Panel, Image } from 'views/components'
import Loader from 'views/components/Loader'
import { ModalProps, Modal } from 'views/components/Modal'
import { Trans } from '@lingui/macro'

interface MassImportLoadingModalProps extends ModalProps {
  massProducts: MassProduct[]
}

const MassImportLoadingModal: React.FC<MassImportLoadingModalProps> = props => {
  useEffect(() => {
    setTimeout(() => {
      props.close(true)
    }, 2000)
  })

  const renderContent = () => (
    <Panel.Card padding={24}>
      <Wrapper align='center' verticalGap={8}>
        <Image
          width='160px'
          height='160px'
          src={cdnImageUrl + 'images/img-inventory-list-empty.svg'}
          mode='contain'
        />
        <Trans>
          <Text.Heading h={5} noMargin>
            Mengimpor produk…
          </Text.Heading>
        </Trans>
        <Wrapper position='relative' height='70px'>
          <Loader position='relative' />
        </Wrapper>
        <Trans>
          <Text.Paragraph
            color='red'
            noMargin
            onClick={() => props.close(false)}
          >
            Batalkan
          </Text.Paragraph>
        </Trans>
      </Wrapper>
    </Panel.Card>
  )
  return (
    <Modal
      dismiss={props.dismiss}
      type='blank'
      canDismissOutside={false}
      width='400px'
    >
      {renderContent()}
    </Modal>
  )
}

export default MassImportLoadingModal
