import { Reducer, StateActions } from 'utilities/redux/reducer'
import { SET_SHIFT_SETTING } from './actions'
import { ShiftSettingState } from 'domains/management/shift/states/state'
import { ShiftSetting } from 'domains/management/shift/models'

export class ShiftSettingReducer extends Reducer<ShiftSettingState> {
  constructor() {
    super({
      shiftSetting: {
        isAuto: false,
        initialCash: 0,
      },
    })
  }

  setShiftSetting = (
    state: ShiftSettingState,
    shiftSetting: ShiftSetting
  ): ShiftSettingState => {
    return {
      shiftSetting,
    }
  }

  get actions(): StateActions<ShiftSettingState> {
    return {
      [SET_SHIFT_SETTING]: this.setShiftSetting,
    }
  }
}
