import classnames from 'classnames'
import React, { useRef, useEffect } from 'react'

import Wrapper from '../Wrapper'
import './style.css'

export interface PopupContentEvent {
  close: () => void
}

interface PopupProps {
  renderContent: (event_: PopupContentEvent) => React.ReactElement
  renderCenterContent: () => React.ReactElement
  onVisibilityChanged?: (isVisibility: boolean) => any
  onClose?: () => any
  className?: string
  position?: 'left' | 'right'
}

const Popup: React.FC<PopupProps> = props => {
  const [isVisible, setVisibility] = React.useState<boolean>(false)
  const containerRef = useRef<HTMLDivElement>(null)
  const isVisibleRef = useRef(isVisible)

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick, false)
    return () => {
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [])

  const handleOutsideClick = (event: any) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      if (isVisibleRef.current) {
        close()
      }
    }
  }

  const open = () => {
    isVisibleRef.current = true
    setVisibility(true)
    props.onVisibilityChanged && props.onVisibilityChanged(true)
  }
  const close = () => {
    isVisibleRef.current = false
    setVisibility(false)
    props.onClose && props.onClose()
    props.onVisibilityChanged && props.onVisibilityChanged(false)
  }

  const toggle = () => {
    isVisible ? close() : open()
  }

  const popupClassName = classnames('post-popup', props.className)

  const popupContentClassName = classnames(
    'post-popup-content',
    props.position || '',
    {
      visible: isVisible,
    }
  )
  return (
    <div ref={containerRef} className={popupClassName}>
      <Wrapper className={popupContentClassName}>
        <Wrapper className='triangle' />
        <Wrapper className='content'>{props.renderContent({ close })}</Wrapper>
      </Wrapper>
      <Wrapper onClick={toggle}>{props.renderCenterContent()}</Wrapper>
    </div>
  )
}

export default Popup
