import { BaseStorage } from 'storages/base'
import { IDeviceStorage } from 'domains/management/device/contracts/storage'
import { Dict } from 'utilities/type'
import { DeviceModel } from 'domains/management/device/models'
import { SET_DEVICES, APPEND_DEVICES } from './actions'

export class DeviceStorage extends BaseStorage implements IDeviceStorage {
  public setDevices(devices: Dict<DeviceModel>): void {
    this.dispatch(SET_DEVICES, devices)
  }

  public appendDevices(devices: Dict<DeviceModel>): void {
    this.dispatch(APPEND_DEVICES, devices)
  }
}
