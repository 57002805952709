import { BaseService } from 'domains/commons/services'

import { PaylaterInfo } from '../models'

export class PaylaterService extends BaseService implements IPaylaterService {
  public getPaylaterInfo = async (id: string) => {
    try {
      return await this.api.paylater.getPaylater(id)
    } catch (error) {
      console.error(error)
      throw error
    }
  }
}

export interface IPaylaterService {
  getPaylaterInfo: (id: string) => Promise<PaylaterInfo[]>
}
