import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { history } from 'storages'
import { matchPath } from 'react-router-dom'
import { getAppConfig } from 'config'

// Array of Route Config Objects
const routes = [{ path: '/auth' }, { path: '/' }]

const initializeSentry = () => {
  const appConfig = getAppConfig()
  if (appConfig.sentryDsn) {
    Sentry.init({
      dsn: appConfig.sentryDsn,
      autoSessionTracking: true,
      environment: appConfig.mode,
      release: 'post-web-dashboard@' + appConfig.mode,
      integrations: [
        new Integrations.BrowserTracing({
          routingInstrumentation: Sentry.reactRouterV5Instrumentation(
            history,
            routes,
            matchPath
          ),
        }),
      ],
      debug: appConfig.mode === ('local' || 'staging'),
      tracesSampleRate: 1.0,
      normalizeDepth: 10,
    })
  }
}

export const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  actionTransformer: action => {
    if (action.type === 'USER.SET_USER') {
      // Return null to not log the action to Sentry
      return null
    }
    if (action.type === 'SET_REGISTER_TEMP_DATA') {
      // Return a transformed action to remove sensitive information
      return {
        ...action,
        id: null,
      }
    }

    return action
  },
})

export const sentryFunc = {
  initializeSentry,
}
