import { Reducer, StateActions } from 'utilities/redux/reducer'
import { Dict } from 'utilities/type'
import { DeviceState } from 'domains/management/device/states/state'
import { DeviceModel } from 'domains/management/device/models'
import { SET_DEVICES, APPEND_DEVICES } from './actions'

export class DeviceReducer extends Reducer<DeviceState> {
  constructor() {
    super({
      devices: {},
    })
  }

  setDevices = (
    state: DeviceState,
    devices: Dict<DeviceModel>
  ): DeviceState => {
    return {
      ...state,
      devices,
    }
  }

  appendDevices = (
    state: DeviceState,
    devices: Dict<DeviceModel>
  ): DeviceState => {
    return {
      ...state,
      devices: {
        ...state.devices,
        ...devices,
      },
    }
  }

  get actions(): StateActions<DeviceState> {
    return {
      [SET_DEVICES]: this.setDevices,
      [APPEND_DEVICES]: this.appendDevices,
    }
  }
}
