import { BaseApi } from 'api/base.api'
import {
  SummaryReport,
  ProductSaleReport,
  ServiceTypeReportData,
  CancellationReport,
  DiscountReport,
  PaymentMethodReport,
  SummaryReportQueryString,
  ExpenseReportQueryString,
  ExpenseSummary,
  SalesFactQueryString,
  SalesFact,
  ServiceTypeReportQueryParams,
  DiscountReportQueryString,
  ProductSaleReportQueryString,
  SummaryHourlyReport,
  EmployeeReport,
  EmployeeReportQueryString,
  IngredientReport,
  GetIngredientReportsQueryParams,
} from 'domains/reporting/models'

import {
  toISummaryReport,
  toIProductSaleReport,
  toEmployeeReport,
} from './model'
import { toQueryString } from 'api/converter'
export default class ReportingApi extends BaseApi {
  constructor() {
    super('/v2/reports')
  }

  public async getSummaries(
    queryParams?: SummaryReportQueryString,
    headers: object = {}
  ): Promise<SummaryReport[]> {
    try {
      const querystring = toQueryString(queryParams)
      const response = (await this.makeForCamelCase(
        'GET',
        '/summaries' + querystring,
        {},
        {},
        headers
      )) as any
      return response.data.map(toISummaryReport)
    } catch (error) {
      throw error
    }
  }

  public async getSummariesHourly(
    queryParams?: SummaryReportQueryString,
    headers: object = {}
  ): Promise<SummaryHourlyReport[]> {
    try {
      const querystring = toQueryString(queryParams)
      const response = (await this.makeForCamelCase(
        'GET',
        '/summaries-hourly' + querystring,
        {},
        {},
        headers
      )) as any
      return response.data.map(toISummaryReport)
    } catch (error) {
      throw error
    }
  }

  public async getProductSaleReports(
    queryParams?: ProductSaleReportQueryString
  ): Promise<ProductSaleReport[]> {
    try {
      const querystring = toQueryString(queryParams)
      const { data } = (await this.makeForCamelCase(
        'GET',
        '/products' + querystring
      )) as any
      return data.map(toIProductSaleReport)
    } catch (error) {
      throw error
    }
  }

  public async getEmployeeReports(
    queryParams?: EmployeeReportQueryString
  ): Promise<EmployeeReport[]> {
    try {
      const querystring = toQueryString(queryParams)
      const { data } = (await this.makeForCamelCase(
        'GET',
        '/employees' + querystring
      )) as any
      return data.map(toEmployeeReport)
    } catch (error) {
      throw error
    }
  }

  public downloadSalesFactsCsv(queryParams?: SalesFactQueryString): any {
    try {
      const querystring = toQueryString(queryParams)
      return this.make('GET', '/sales-facts-csv' + querystring) as any
    } catch (error) {
      throw error
    }
  }

  public async getCancellationsReports(): Promise<CancellationReport[]> {
    return [
      {
        productName: 'Nasi Goreng',
        quantity: 5,
        totalCancellation: 100000,
      },
      {
        productName: 'Bihun Goreng Seafood',
        quantity: 2,
        totalCancellation: 40000,
      },
      {
        productName: 'Teh Manis Dingin',
        quantity: 3,
        totalCancellation: 12000,
      },
    ]
  }

  public async getServiceTypeReports(
    queryParams?: ServiceTypeReportQueryParams
  ): Promise<ServiceTypeReportData[]> {
    try {
      const querystring = toQueryString(queryParams)
      const response = (await this.makeForCamelCase<ServiceTypeReportData[]>(
        'GET',
        '/service-types' + querystring
      )) as any
      return response.data
    } catch (error) {
      throw error
    }
  }

  public async getDiscountReports(
    queryParams?: DiscountReportQueryString
  ): Promise<DiscountReport[]> {
    try {
      const querystring = toQueryString(queryParams)
      return this.makeForCamelCaseForList(
        'GET',
        '/discounts' + querystring
      ) as any
    } catch (error) {
      throw error
    }
  }

  public async getPaymentMethodReports(
    queryParams?: SummaryReportQueryString
  ): Promise<PaymentMethodReport[]> {
    try {
      const querystring = toQueryString(queryParams)
      const response = (await this.makeForCamelCase(
        'GET',
        '/payment-methods' + querystring
      )) as any
      return response.data.map(toISummaryReport)
    } catch (error) {
      throw error
    }
  }

  public async getExpenseReports(
    queryParams?: ExpenseReportQueryString
  ): Promise<ExpenseSummary[]> {
    try {
      const querystring = toQueryString(queryParams)
      return await this.makeForCamelCaseForList<ExpenseSummary>(
        'GET',
        '/expenses',
        querystring
      )
    } catch (error) {
      throw error
    }
  }

  public async getSalesFacts(
    queryParams?: SalesFactQueryString
  ): Promise<SalesFact[]> {
    try {
      const querystring = toQueryString(queryParams)
      return await this.makeForCamelCaseForList<SalesFact>(
        'GET',
        '/sales-facts' + querystring
      )
    } catch (error) {
      throw error
    }
  }

  public async getIngredientReports(
    queryParams: GetIngredientReportsQueryParams
  ): Promise<IngredientReport[]> {
    try {
      return (
        (await this.makeForList('GET', '/ingredient', queryParams)) as any
      ).data
    } catch (error) {
      throw error
    }
  }
}
