import { BaseService } from 'domains/commons/services'
import { IDeviceStorage } from '../contracts/storage'
import { ICommonStorage } from 'domains/commons/contracts/storage'
import { Api } from 'domains/contracts/api'
import { toDict } from 'utilities/converter/list'
import { DeviceBody, DeviceModel } from '../models'

const SET_DEVICES = 'SET_DEVICES'

export class DeviceService extends BaseService implements IDeviceService {
  deviceStorage: IDeviceStorage

  constructor(
    commonStorage: ICommonStorage,
    api: Api,
    deviceStorage: IDeviceStorage
  ) {
    super(commonStorage, api)
    this.deviceStorage = deviceStorage
  }

  public loadDevices = async () => {
    try {
      this.commonStorage.setLoading(SET_DEVICES, true)
      const deviceListResponse = await this.api.management.device.getDevices()
      this.deviceStorage.setDevices(
        toDict(deviceListResponse, device => device.id)
      )
      this.commonStorage.setLoading(SET_DEVICES, false)
      return deviceListResponse
    } catch (error) {
      throw error
    }
  }

  public loadDevice = async (deviceId: string) => {
    try {
      const deviceResponse = await this.api.management.device.getDevice(
        deviceId
      )
      this.deviceStorage.setDevices({ [deviceResponse.id]: deviceResponse })
    } catch (error) {
      throw error
    }
  }

  public createDevice = async (deviceBody: DeviceBody) => {
    try {
      const deviceResponse = await this.api.management.device.createDevice(
        deviceBody
      )
      this.deviceStorage.appendDevices({ [deviceResponse.id]: deviceResponse })
      return deviceResponse
    } catch (error) {
      throw error
    }
  }

  public updateDevice = async (deviceId: string, deviceBody: DeviceBody) => {
    try {
      const deviceResponse = await this.api.management.device.updateDevice(
        deviceId,
        deviceBody
      )
      this.deviceStorage.appendDevices({ [deviceResponse.id]: deviceResponse })
      return deviceResponse
    } catch (error) {
      throw error
    }
  }

  public deleteDevice = async (deviceId: string) => {
    try {
      await this.api.management.device.deleteDevice(deviceId)
      const { devices } = this.getState().management.device
      delete devices[deviceId]
      this.deviceStorage.setDevices(devices)
    } catch (error) {
      throw error
    }
  }
}

export interface IDeviceService {
  loadDevices: () => Promise<DeviceModel[]>
  loadDevice: (deviceId: string) => Promise<void>
  createDevice: (deviceBody: DeviceBody) => Promise<DeviceModel>
  updateDevice: (
    deviceId: string,
    deviceBody: DeviceBody
  ) => Promise<DeviceModel>
  deleteDevice: (id: string) => Promise<void>
}
