import React from 'react'
import { Panel, Sided, Text, Button, Break } from 'views/components'
import { ModalProps, Modal } from 'views/components/Modal'
import { Discount } from 'domains/management/discounts/models'
import { useLingui } from '@lingui/react'
import { t, Trans } from '@lingui/macro'

interface DeleteDiscountModalProps extends ModalProps {
  discount: Discount
}

export const DeleteDiscountModal: React.FC<DeleteDiscountModalProps> =
  props => {
    const onDeleteDiscount = () => {
      try {
        props.close(true)
      } catch (error) {
        props.close(false)
      }
    }
    const { i18n } = useLingui()

    return (
      <Modal
        dismiss={() => props.close(false)}
        canDismissOutside={false}
        type='blank'
        width='400px'
      >
        <Panel.Panel>
          <Panel.Header>
            <Trans>
              <Text.Heading h={5} noMargin>
                Hapus Diskon “{props.discount.name}”?
              </Text.Heading>
            </Trans>
          </Panel.Header>
          <Panel.Content>
            <Trans>
              <Text.Paragraph size={14} color='brownGray'>
                Diskon yang sudah dihapus tidak dapat dikembalikan lagi.
              </Text.Paragraph>
            </Trans>
            <Break height={16} />
            <Sided.Sided gutter={16}>
              <Sided.Remaining>
                <Button
                  theme='netral'
                  size='large'
                  label={i18n._(t`Batal`)}
                  onClick={() => props.close(false)}
                  fullW
                />
              </Sided.Remaining>
              <Sided.Remaining>
                <Button
                  theme='danger'
                  size='large'
                  label={i18n._(t`Hapus`)}
                  onClick={() => onDeleteDiscount()}
                  fullW
                />
              </Sided.Remaining>
            </Sided.Sided>
          </Panel.Content>
        </Panel.Panel>
      </Modal>
    )
  }
