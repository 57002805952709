import React, { useState } from 'react'
import MonthHeaderSelector, { MonthAndYear } from '../MonthHeaderSelector'
import { Text, Sided } from 'views/components'
import moment from 'moment'
import { List } from 'antd'
import { getWeekRangeList } from '../utils'
import { DateRange } from 'domains/commons/models'
import Button from 'views/components/Button'
import { toDateString } from 'utilities/converter/date'

interface WeeklyCalendarProps {
  selectedDateRange: DateRange
  onChange: (dateRange: DateRange) => any
}

const WeeklyCalendar = (props: WeeklyCalendarProps) => {
  const reloadCalendar = (x: MonthAndYear) => {
    const calendar = getWeekRangeList(x.year, x.month)
    setMonthAndYear(x)
    setWeeklyCalendar(calendar)
  }

  const [monthAndYear, setMonthAndYear] = useState({
    year: moment().year(),
    month: moment().month() + 1,
  })

  const [weeklyCalendar, setWeeklyCalendar] = useState(
    getWeekRangeList(monthAndYear.year, monthAndYear.month)
  )

  const onClickWeeklyDate = (dateRange: DateRange) => {
    props.onChange(dateRange)
  }

  return (
    <div>
      <MonthHeaderSelector
        monthAndYear={monthAndYear}
        onChange={x => reloadCalendar(x)}
      />
      <div>
        <List>
          {weeklyCalendar.map((item, i) => {
            const className =
              moment(item.from).isSame(props.selectedDateRange.from, 'day') &&
              moment(item.to).isSame(props.selectedDateRange.to, 'day')
                ? 'active'
                : ''

            const clickWeeklyDate = () => onClickWeeklyDate(item)

            const textContent = (
              <Text.Paragraph size={12} align='left'>
                Minggu ke-{i + 1}
              </Text.Paragraph>
            )

            return (
              <List.Item key={i}>
                <Button
                  fullW={true}
                  theme='transparent'
                  className={className}
                  onClick={clickWeeklyDate}
                >
                  <Sided.Sided>
                    <Sided.Remaining>{textContent}</Sided.Remaining>
                    <Sided.Remaining>
                      <Text.Paragraph size={12} align='right'>
                        {`${toDateString(
                          item.from,
                          'DD/MM/YYYY'
                        )} - ${toDateString(item.to, 'DD/MM/YYYY')}`}
                      </Text.Paragraph>
                    </Sided.Remaining>
                  </Sided.Sided>
                </Button>
              </List.Item>
            )
          })}
        </List>
      </div>
    </div>
  )
}

export default WeeklyCalendar
