import ReportingApi from 'api/reporting/v2'
import NewReportingApi from 'api/reporting/v3'
import AuthApi from 'api/auth/v2'
import UserApi from 'api/users'
import SubscriptionApi from 'api/subscriptions'
import OutletApi from 'api/management/outlet'
import CategoryApi from 'api/management/category'
import ProductApi from 'api/management/product'
import ServiceTypeApi from 'api/management/serviceType'
import SettingsApi from 'api/settings'
import MediaApi from 'api/media'
import NewAuthApi from 'api/auth/v3'
import LocationApi from 'api/location'
import DiscountApi from 'api/management/discounts'
import ChargesApi from 'api/management/charges'
import CustomerApi from 'api/management/customer'
import PaymentMethodApi from 'api/management/paymentMethod'
import PaymentMethodV3Api from 'api/management/paymentMethod/v3'
import DeviceApi from 'api/management/device'
import ShiftSettingApi from 'api/management/shift'
import EmployeeApi from 'api/management/employee'
import RoleManagementApi from 'api/management/role'

import {
  IEmployeeReportingService,
  EmployeeReportingService,
} from './domains/reporting/services/employee'
import {
  OutletService,
  IOutletService,
} from 'domains/management/outlets/services'
import { IOutletStorage } from 'domains/management/outlets/contracts/storage'
import { ICommonStorage } from 'domains/commons/contracts/storage'
import { CommonStorage } from 'storages/common/storage'
import { AppState } from 'domains/contracts/appState'
import { Store } from 'redux'
import { AppStore, history } from 'storages'
import {
  ICategoryService,
  CategoryService,
} from 'domains/management/category/services'
import { CategoryStorage } from 'storages/management/category/storage'
import { ICategoryStorage } from 'domains/management/category/contracts/storage'
import { IProductStorage } from 'domains/management/products/contracts/storage'
import { ProductStorage } from 'storages/management/products/storage'
import {
  IProductService,
  ProductService,
} from 'domains/management/products/services'
import { IHomeService, HomeService } from 'domains/home/services'
import { IHomeStorage } from 'domains/home/contracts/storage'
import { HomeStorage } from 'storages/home/storage'
import { ILayoutStorage } from 'domains/layouts/contracts/storage'
import { LayoutStorage } from 'storages/layouts/storage'
import { ILayoutService, LayoutService } from 'domains/layouts/services'
import { IUserStorage } from 'domains/users/contracts/storage'
import { UserStorage } from 'storages/users/storage'
import { ICommonService, CommonService } from 'domains/commons/services'
import { IServiceTypeStorage } from 'domains/management/serviceType/contracts/storage'
import { ServiceTypeStorage } from 'storages/management/serviceType/storage'
import {
  IServiceTypeService,
  ServiceTypeService,
} from 'domains/management/serviceType/services'
import {
  IRegisterService,
  RegisterService,
} from 'domains/auth/register/services'
import { ISubscriptionStorage } from 'domains/subscriptions/contracts/storage'
import { SubscriptionStorage } from 'storages/subscriptions/storage'
import {
  SubscriptionService,
  ISubscriptionService,
} from 'domains/subscriptions/services'
import {
  IResetPasswordService,
  ResetPasswordService,
} from 'domains/auth/reset-password/services'
import { IReportingService } from 'domains/reporting/services'
import {
  ISummaryReportingService,
  SummaryReportingService,
} from 'domains/reporting/services/summary'
import {
  IProductSaleReportingService,
  ProductSaleReportingService,
} from 'domains/reporting/services/productSale'
import {
  IServiceTypeReportingService,
  ServiceTypeReportingService,
} from 'domains/reporting/services/serviceType'
import {
  CancellationReportingService,
  ICancellationReportingService,
} from 'domains/reporting/services/cancellation'
import {
  IDiscountReportingService,
  DiscountReportingService,
} from 'domains/reporting/services/discount'
import {
  IShiftReportingService,
  ShiftReportingService,
} from 'domains/reporting/services/shift'
import {
  IPaymentMethodReportService,
  PaymentMethodReportService,
} from 'domains/reporting/services/paymentMethod'
import {
  IReportingFilterService,
  ReportingFilterService,
} from 'domains/reporting/services/filter'
import { IReportingStorage } from 'domains/reporting/contracts/storage'
import { ReportingStorage } from 'storages/reporting/storage'
import { IUserService, UserService } from 'domains/users/services'
import { IAuthService, AuthService } from 'domains/auth/login/services'
import { IHelpdeskService, HelpdeskService } from 'domains/helpdesk/services'
import { ILocationService, LocationService } from 'domains/location/services'
import { IDiscountStorage } from 'domains/management/discounts/contracts/storage'
import {
  IDiscountService,
  DiscountService,
} from 'domains/management/discounts/services'
import { ILocationStorage } from 'domains/location/contracts/storage'
import { LocationStorage } from 'storages/location/storage'
import {
  ICustomerService,
  CustomerService,
} from 'domains/management/customer/services'
import { ICustomerStorage } from 'domains/management/customer/contracts/storage'
import { CustomerStorage } from 'storages/management/customer/storage'
import { IPaymentMethodStorage } from 'domains/management/paymentMethod/contracts/storage'
import { PaymentMethodStorage } from 'storages/management/paymentMethod/storages'
import {
  IPaymentMethodService,
  PaymentMethodService,
} from 'domains/management/paymentMethod/services'
import { IMediaService, MediaService } from 'domains/media'
import { IDeviceStorage } from 'domains/management/device/contracts/storage'
import { DiscountStorage } from 'storages/management/discounts/storage'
import { DeviceStorage } from 'storages/management/device/storage'
import {
  IDeviceService,
  DeviceService,
} from 'domains/management/device/services'
import {
  IChargesService,
  ChargesService,
} from 'domains/management/charges/services'
import { IEmployeeStorage } from 'domains/management/employee/contracts/storage'
import { EmployeeStorage } from 'storages/management/employee/storage'
import {
  IEmployeeService,
  EmployeeService,
} from 'domains/management/employee/services'
import { OutletStorage } from 'storages/management/outlet/storage'
import {
  IShiftSettingService,
  ShiftSettingService,
} from 'domains/management/shift/services'
import { IShiftSettingStorage } from 'domains/management/shift/contracts/storage'
import { ShiftSettingStorage } from 'storages/management/shift/storage'
import {
  ReceiptService,
  IReceiptService,
} from 'domains/management/receipt/services'
import ReceiptApi from 'api/management/receipt'
import { Api } from 'domains/contracts/api'
import {
  IExpenseReportingService,
  ExpenseReportingService,
} from 'domains/reporting/services/expense'
import {
  IExpenseService,
  ExpenseService,
} from 'domains/management/expense/services'
import { ExpenseStorage } from 'storages/management/expense/storage'
import { IExpenseStorage } from 'domains/management/expense/contracts/storage'
// import MockExpenseApi from "api/management/expense/index.mock";
import { IRoleService, RoleService } from 'domains/management/role/services'
import { RoleStorage } from 'storages/management/role/storage'
import { IRoleStorage } from 'domains/management/role/contracts/storage'
import ExpenseApi from 'api/management/expense'
import PaymentMethodKYCApi from 'api/management/paymentMethodKYC'
import {
  IPaymentMethodKYCService,
  PaymentMethodKYCService,
} from 'domains/management/paymentMethodKYC/services'
import {
  ISalesFactReportingService,
  SalesFactReportingService,
} from 'domains/reporting/services/salesFact'
import {
  IOrderReportingService,
  OrderReportingService,
} from 'domains/reporting/services/order'
import DiningApi from 'api/contactless'
import { IDiningStorage } from 'domains/contactless/contracts/storage'
import { DiningStorage } from 'storages/contactless/dining/storage'
import { IDiningService, DiningService } from 'domains/contactless/services'
import BankApi from 'api/bank'
import { IBankService, BankService } from 'domains/bank/services'
import AppsflyerApi from 'api/appsflyer'
import { AppsflyerService, IAppsflyerService } from 'domains/appsflyer/services'
import IngredientApi from 'api/ingredients'
import IngredientCategoryApi from 'api/ingredientCategories'
import RecipeApi from 'api/recipes'
import ProductApiV2 from 'api/management/product/v2'
import { ProductServiceV2 } from 'domains/management/products/services/v2'
import PaylaterApi from 'api/paylater'
import { IPaylaterService, PaylaterService } from 'domains/paylater/services'
import InventoryApi from 'api/inventory'

const reporting = new ReportingApi()
// const mockExpense = new MockExpenseApi()
// reporting.getExpenseReports = mockExpense.getExpenseReports

export const api: Api = {
  appsflyer: new AppsflyerApi(),
  auth: new AuthApi(),
  bank: new BankApi(),
  newAuth: new NewAuthApi(),
  reporting,
  newReporting: new NewReportingApi(),
  users: new UserApi(),
  subscriptions: new SubscriptionApi(),
  location: new LocationApi(),
  inventory: new InventoryApi(),
  management: {
    shift: new ShiftSettingApi(),
    outlet: new OutletApi(),
    customer: new CustomerApi(),
    category: new CategoryApi(),
    product: new ProductApi(),
    productV2: new ProductApiV2(),
    discounts: new DiscountApi(),
    serviceType: new ServiceTypeApi(),
    discount: new DiscountApi(),
    device: new DeviceApi(),
    paymentMethod: new PaymentMethodApi(),
    paymentMethodV3: new PaymentMethodV3Api(),
    charges: new ChargesApi(),
    employee: new EmployeeApi(),
    receipt: new ReceiptApi(),
    expense: new ExpenseApi(),
    paymentMethodKYC: new PaymentMethodKYCApi(),
    role: new RoleManagementApi(),
  },
  setting: new SettingsApi(),
  media: new MediaApi(),
  contactless: {
    dining: new DiningApi(),
  },
  ingredients: new IngredientApi(),
  ingredientCategories: new IngredientCategoryApi(),
  recipes: new RecipeApi(),
  paylater: new PaylaterApi(),
}

export const store: Store<AppState> = AppStore

export interface Storages {
  customerStorage: ICustomerStorage
  outletStorage: IOutletStorage
  commonStorage: ICommonStorage
  categoryStorage: ICategoryStorage
  productStorage: IProductStorage
  homeStorage: IHomeStorage
  layoutStorage: ILayoutStorage
  shiftSettingStorage: IShiftSettingStorage
  userStorage: IUserStorage
  serviceTypeStorage: IServiceTypeStorage
  subscriptionStorage: ISubscriptionStorage
  locationStorage: ILocationStorage
  reportingStorage: IReportingStorage
  discountStorage: IDiscountStorage
  deviceStorage: IDeviceStorage
  paymentMethodStorage: IPaymentMethodStorage
  employeeStorage: IEmployeeStorage
  roleStorage: IRoleStorage
}

const customerStorage: ICustomerStorage = new CustomerStorage(store)
const outletStorage: IOutletStorage = new OutletStorage(store)
const commonStorage: ICommonStorage = new CommonStorage(store)
const categoryStorage: ICategoryStorage = new CategoryStorage(store)
const productStorage: IProductStorage = new ProductStorage(store)
const homeStorage: IHomeStorage = new HomeStorage(store)
const layoutStorage: ILayoutStorage = new LayoutStorage(store)
const shiftSettingStorage: IShiftSettingStorage = new ShiftSettingStorage(store)
const userStorage: IUserStorage = new UserStorage(store)
const serviceTypeStorage: IServiceTypeStorage = new ServiceTypeStorage(store)
const subscriptionStorage: ISubscriptionStorage = new SubscriptionStorage(store)
const locationStorage: ILocationStorage = new LocationStorage(store)
const reportingStorage: IReportingStorage = new ReportingStorage(store)
const discountStorage: IDiscountStorage = new DiscountStorage(store)
const deviceStorage: IDeviceStorage = new DeviceStorage(store)
const paymentMethodStorage: IPaymentMethodStorage = new PaymentMethodStorage(
  store
)
const employeeStorage: IEmployeeStorage = new EmployeeStorage(store)
const expenseStorage: IExpenseStorage = new ExpenseStorage(store)
const roleStorage: IRoleStorage = new RoleStorage(store)
const diningStorage: IDiningStorage = new DiningStorage(store)

export const storages: Storages = {
  customerStorage,
  outletStorage,
  commonStorage,
  categoryStorage,
  productStorage,
  homeStorage,
  layoutStorage,
  shiftSettingStorage,
  userStorage,
  serviceTypeStorage,
  subscriptionStorage,
  locationStorage,
  reportingStorage,
  discountStorage,
  deviceStorage,
  paymentMethodStorage,
  employeeStorage,
  roleStorage,
}

export const appsflyerService: IAppsflyerService = new AppsflyerService(
  commonStorage,
  api
)
export const customerService: ICustomerService = new CustomerService(
  commonStorage,
  api,
  customerStorage
)
export const outletService: IOutletService = new OutletService(
  commonStorage,
  api,
  outletStorage
)
export const commonService: ICommonService = new CommonService(
  commonStorage,
  api
)
export const chargesService: IChargesService = new ChargesService(
  commonStorage,
  api
)
export const categoryService: ICategoryService = new CategoryService(
  commonStorage,
  api,
  categoryStorage
)
export const productService: IProductService = new ProductService(
  commonStorage,
  api,
  productStorage,
  history
)
export const productServiceV2 = new ProductServiceV2(
  commonStorage,
  api,
  productStorage,
  history
)
export const homeService: IHomeService = new HomeService(
  commonStorage,
  api,
  homeStorage
)
export const layoutService: ILayoutService = new LayoutService(
  commonStorage,
  api,
  layoutStorage,
  userStorage,
  history
)
export const serviceTypeService: IServiceTypeService = new ServiceTypeService(
  commonStorage,
  api,
  serviceTypeStorage
)
export const registerService: IRegisterService = new RegisterService(
  commonStorage,
  api
)
export const locationService: ILocationService = new LocationService(
  commonStorage,
  api,
  locationStorage
)
export const subscriptionService: ISubscriptionService =
  new SubscriptionService(commonStorage, api, subscriptionStorage)
export const resetPasswordService: IResetPasswordService =
  new ResetPasswordService(commonStorage, api)
export const summaryReportingService: ISummaryReportingService =
  new SummaryReportingService(commonStorage, api, reportingStorage)
export const productSaleReportingService: IProductSaleReportingService =
  new ProductSaleReportingService(commonStorage, api, reportingStorage)
export const employeeReportingService: IEmployeeReportingService =
  new EmployeeReportingService(commonStorage, api, reportingStorage)
export const serviceTypeReportingService: IServiceTypeReportingService =
  new ServiceTypeReportingService(commonStorage, api, reportingStorage)
export const cancellationReportingService: ICancellationReportingService =
  new CancellationReportingService(commonStorage, api, reportingStorage)
export const discountReportingService: IDiscountReportingService =
  new DiscountReportingService(commonStorage, api, reportingStorage)
export const shiftReportingService: IShiftReportingService =
  new ShiftReportingService(commonStorage, api, reportingStorage)
export const paymentMethodReportService: IPaymentMethodReportService =
  new PaymentMethodReportService(commonStorage, api, reportingStorage)
export const reportingFilterService: IReportingFilterService =
  new ReportingFilterService(
    commonStorage,
    api,
    reportingStorage,
    outletStorage
  )
export const userService: IUserService = new UserService(
  commonStorage,
  api,
  userStorage
)
export const authService: IAuthService = new AuthService(commonStorage, api)
export const receiptService: IReceiptService = new ReceiptService(
  commonStorage,
  api
)
export const mediaService: IMediaService = new MediaService(commonStorage, api)
export const helpdeskService: IHelpdeskService = new HelpdeskService(
  commonStorage,
  api
)
export const expenseReportingService: IExpenseReportingService =
  new ExpenseReportingService(commonStorage, api, expenseStorage)
export const salesFactReportingServise: ISalesFactReportingService =
  new SalesFactReportingService(commonStorage, api, reportingStorage)
export const orderReportingService: IOrderReportingService =
  new OrderReportingService(commonStorage, api, reportingStorage)
export const reportingService: IReportingService = {
  summary: summaryReportingService,
  expense: expenseReportingService,
  productSale: productSaleReportingService,
  employee: employeeReportingService,
  serviceType: serviceTypeReportingService,
  cancellation: cancellationReportingService,
  discount: discountReportingService,
  shift: shiftReportingService,
  paymentMethod: paymentMethodReportService,
  filter: reportingFilterService,
  salesFact: salesFactReportingServise,
  order: orderReportingService,
}
export const discountService: IDiscountService = new DiscountService(
  commonStorage,
  api,
  discountStorage
)
export const shiftSettingService: IShiftSettingService =
  new ShiftSettingService(commonStorage, api, shiftSettingStorage)
export const deviceService: IDeviceService = new DeviceService(
  commonStorage,
  api,
  deviceStorage
)
export const paymentMethodService: IPaymentMethodService =
  new PaymentMethodService(commonStorage, api, paymentMethodStorage)
export const employeeService: IEmployeeService = new EmployeeService(
  commonStorage,
  api,
  employeeStorage
)
export const expenseService: IExpenseService = new ExpenseService(
  commonStorage,
  api,
  expenseStorage
)
export const roleService: IRoleService = new RoleService(
  commonStorage,
  api,
  roleStorage
)
export const paymentMethodKYCService: IPaymentMethodKYCService =
  new PaymentMethodKYCService(commonStorage, api)
export const diningService: IDiningService = new DiningService(
  commonStorage,
  api,
  diningStorage
)
export const bankService: IBankService = new BankService(commonStorage, api)
export const paylaterService: IPaylaterService = new PaylaterService(
  commonStorage,
  api
)
