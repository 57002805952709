import { i18n } from '@lingui/core'
import { t } from '@lingui/macro'
import React from 'react'

import { isAnyOfTheseFeaturesEnabled, featureCheck } from 'config'
import { AppState } from 'domains/contracts/appState'
import { SubscriptionAlert } from 'domains/subscriptions/model'
import { User } from 'domains/users/models'
import { Icon } from 'views/components'

interface BusinessAccountSelector {
  businessName: string
  email: string
  phone: string
}

export interface CardRenderData {
  id: string
  label: string
  description: string
  icon: React.ReactElement
  isActive: boolean
}

export interface SettingViewModel {
  userInfo: User
  userPhone: string
  businessAccountInfo: BusinessAccountSelector
  subscriptionAlert: SubscriptionAlert
  cardRenderData: CardRenderData[]
}

const cardRenderData = [
  {
    id: 'accountSetting',
    label: i18n._(t`Atur Akun`),
    description: i18n._(t`Atur nomor handphone, dan email Akun Anda`),
    icon: <Icon.UserSession />,
    isActive: isAnyOfTheseFeaturesEnabled(featureCheck.setting.account),
  },
  {
    id: 'passwordSetting',
    label: i18n._(t`Atur Password`),
    description: i18n._(t`Atur password Akun Anda`),
    icon: <Icon.Lock />,
    isActive: isAnyOfTheseFeaturesEnabled(featureCheck.setting.account),
  },
  {
    id: 'resetData',
    label: i18n._(t`Reset Data`),
    description: i18n._(t`Hapus data di akun kamu`),
    icon: <Icon.Reload color='black' />,
    isActive: isAnyOfTheseFeaturesEnabled(featureCheck.setting.resetData),
  },
]

export default (state: AppState): SettingViewModel => ({
  userInfo: state.user,
  userPhone: state.user.phone,
  businessAccountInfo: {
    businessName: state.user.businessName,
    email: state.user.email,
    phone: state.user.phone,
  },
  subscriptionAlert: state.subscriptions.subscriptionAlert,
  cardRenderData: cardRenderData.filter(data => data.isActive),
})
