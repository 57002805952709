import { Dict, KeyedValue, Group, Tree } from 'utilities/type'

export function toDict<T>(list: T[], func: (o: T) => string): Dict<T> {
  const dict: Dict<T> = {}
  list.forEach(o => {
    const key = func(o)
    dict[key] = o
  })
  return dict
}

export function toGroupBy<T>(list: T[], func: (o: T) => string): Group<T> {
  const dict: Dict<T[]> = {}
  list.forEach(o => {
    const key = func(o)
    if (!dict[key]) {
      dict[key] = []
    }
    dict[key].push(o)
  })
  return dict
}

export function toKeyedValue<T>(dict: Dict<T>): KeyedValue<T>[] {
  return Object.keys(dict).map(key => ({
    key,
    value: dict[key],
  }))
}

export function toList<T>(map: Dict<T>): T[] {
  return Object.keys(map).map(key => map[key])
}

export function mapDict<T>(
  map: Dict<T>,
  mapFunc: (obj: T, key?: string) => T
): Dict<T> {
  const newMap: Dict<T> = {}
  Object.keys(map).forEach(key => {
    const obj = mapFunc(map[key], key)
    newMap[key] = obj
  })
  return newMap
}

export function filterDict<T>(
  map: Dict<T>,
  checkFunc: (obj: T) => boolean
): Dict<T> {
  const newMap: Dict<T> = {}
  Object.keys(map).forEach(key => {
    const obj = map[key]
    if (checkFunc(obj)) {
      newMap[key] = obj
    }
  })
  return newMap
}

export function filterTreeList<T>(
  treeList: Tree<T>[],
  checkFunc: (obj: T) => boolean
): Tree<T>[] {
  return treeList.filter(checkFunc).map(x => {
    const obj = {
      ...x,
    }
    if (x.children) {
      obj.children = filterTreeList(x.children, checkFunc)
    }
    return obj
  })
}

export function mapTree<T, TOut>(
  tree: Tree<T>,
  mapper: (obj: Tree<T>) => Tree<TOut>
): Tree<TOut> {
  const newTree = mapper(tree)
  if (tree.children) {
    newTree.children = tree.children.map(t => mapTree(t, mapper))
  }
  return newTree
}

export function forEachDict<T>(
  map: Dict<T>,
  forEachFunc: (key: string, obj: T) => any
): void {
  Object.keys(map).forEach(key => {
    forEachFunc(key, map[key])
  })
}

export function mapObject<O, X = any>(
  map: Record<keyof O, any>,
  mapFunc: (key: keyof O, obj: any) => X
): Record<keyof O, X> {
  const newMap: Record<any, X> = {}
  Object.keys(map).forEach(key => {
    const k = key as keyof O
    const obj = mapFunc(k, map[k])
    newMap[k] = obj
  })
  return newMap as Record<keyof O, X>
}

export function toGrid<T>(list: T[], col: number): T[][] {
  const newList = [...list]
  const grid: T[][] = []
  for (let idx = 0; idx < list.length; idx += col) {
    const segment = newList.slice(idx, idx + col)
    grid.push(segment)
  }
  return grid
}

export function mergeObject<T extends Dict<any>>(obj: T, newObj: T): T {
  if (typeof obj == 'object' && typeof newObj == 'object') {
    const mergedObj: Dict<any> = {
      ...obj,
    }
    forEachDict(newObj, key => {
      mergedObj[key] = mergeObject(mergedObj[key], newObj[key])
    })
    return mergedObj as T
  } else if (newObj != undefined) {
    return newObj
  } else {
    return obj
  }
}
