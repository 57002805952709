import 'antd/dist/antd.css'
import React from 'react'
import ReactDOM from 'react-dom'

import { App } from './App'
import * as serviceWorker from './serviceWorker'
import './style.css'
import { disableDragAndDrop } from './utilities/eventListeners'
import { firebaseFunc } from './utilities/firebase'
import './utilities/i18n'
import { logChecker } from './utilities/logChecker'
import netcore from './utilities/netcore'
import { activateScreenChecker } from './utilities/screen/screenChecker'
import { sentryFunc } from './utilities/sentry'
import { initializeTagManager } from './utilities/tagManager'
import './wdyr'

disableDragAndDrop()
activateScreenChecker()
firebaseFunc.initializeFirebase()
logChecker()
sentryFunc.initializeSentry()
serviceWorker.register()
netcore.initialize()
initializeTagManager()

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.querySelector('#root')
)
