import { CONFIG_LOCALSTORAGE } from 'constants/localStorage'
import { User } from 'domains/users/models'
import { locationService, userService } from 'injectors'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Debouncer } from 'utilities/debounce'
import { setLocalStorage } from 'utilities/localStorage'
import { highlight } from 'utilities/strings/highlighter'
import {
  Wrapper,
  Form,
  Text,
  Break,
  Panel,
  FloatingBox,
  FormAction,
  Field,
  Button,
} from 'views/components'
import { DropdownItem } from 'views/components/Field/Dropdown'
import { ModalProps, Modal } from 'views/components/Modal'
import { convertAreasToDropdownItems } from 'views/modules/Home/selector'
import { RegisterSuccessFormSchema } from 'views/modules/Registration/RegisterSuccessV2/registerSuccessFormSchema'
import { BusinessProfileForm } from 'views/modules/Settings/BusinessProfile/businessProfileFormSchema'
import selector from '../Authorized/selector'

import { cdnImageUrl } from 'constants/cdnUrl/imageUrl'

interface RegisterFlowModalProps extends ModalProps {}

export const RegisterBusinessInfoModal: React.FC<RegisterFlowModalProps> =
  props => {
    const [isNextStep, setNextStep] = useState(false)
    const [userId, setUserId] = useState('')
    const { businessCategoryDropdown } = useSelector(selector)

    const getUser = async () => {
      try {
        const userResponse = await userService.getUser()
        setUserId(userResponse.id)
      } catch (error) {
        console.error(error)
      }
    }

    useEffect(() => {
      userService.getBusinessCategories()
      getUser()
    }, [])

    const handleFillBusinessInfoV2 = async (actions: FormAction<User>) => {
      setNextStep(true)
      const values = actions.getValues()
      await userService.updateUser(values)
      if (values.email?.length > 0) {
        await userService.changeEmail(values.email)
      }
      setLocalStorage(
        userId + CONFIG_LOCALSTORAGE.IS_FILL_BUSINESS_INFO,
        'done'
      )
    }

    const handleAreaSearch = async (
      keyword: string
    ): Promise<DropdownItem<string>[]> => {
      if (keyword.length < 3) {
        return []
      }
      const debouncer = new Debouncer()
      return debouncer.debounce(async () => {
        const queryParams = {
          query: keyword,
        }
        const areas = await locationService.getAreas(queryParams)
        return convertAreasToDropdownItems(areas).map(x => ({
          ...x,
          label: highlight(x.label, keyword.toUpperCase(), y => `<b>${y}</b>`),
        }))
      }, 500)
    }

    const resolveInitValue = async (areaId: string): Promise<DropdownItem> => {
      const location = await locationService.getArea(areaId)
      if (!location) {
        return undefined
      }
      return convertAreasToDropdownItems([location])[0]
    }

    const handleOnChangeAreaId = async (
      areaId: string,
      action: FormAction<BusinessProfileForm>
    ) => {
      try {
        const response = await locationService.getArea(areaId)
        action.setFieldValue(x => (x.postalCode = response.postalCode))
      } catch (error) {
        console.error(error)
      }
    }

    const handleFillBusinessInfoV2Step2 = async (actions: FormAction<User>) => {
      const values = actions.getValues()
      await userService.updateUser(values)
      if (values.email?.length > 0) {
        await userService.changeEmail(values.email)
      }
      props.close({})
    }

    const step2 = () => {
      return (
        <Wrapper
          position='relative'
          width='100%'
          height='100%'
          style={{ backgroundColor: '#f2f4f5', padding: '24px' }}
        >
          <img
            src={cdnImageUrl + 'post-logo/post-dark.png'}
            width='111px'
            height='56px'
            alt='logo post'
          />
          <FloatingBox>
            <Break height={12} />
            <Panel.Card style={{ padding: '12px 24px', width: '520px' }}>
              <Break height={16} />
              <Text.Heading h={2} align='center'>
                Informasi Bisnis
              </Text.Heading>
              <Break height={32} />
              <Form.Form
                height='100%'
                initialValues={
                  {
                    areaId: '',
                    email: '',
                  } as User
                }
                validationSchema={RegisterSuccessFormSchema}
                render={(actions: FormAction<User>) => (
                  <>
                    <Field.LocationAutoComplete
                      label='Lokasi'
                      name='areaId'
                      searchFunc={handleAreaSearch}
                      placeholder='Pilih kota / kecamatan'
                      resolveInitValueFunc={resolveInitValue}
                      onChange={val => handleOnChangeAreaId(val, actions)}
                    />
                    <Field.Text
                      name='email'
                      placeholder={`Alamat Email`}
                      label={`Email (Optional)`}
                    />
                    <Text.Paragraph size={12} color='semiGray'>
                      Laporan bisnis berkala, notifikasi keamanan, dan bukti
                      langgananan akan dikirim melalui email
                    </Text.Paragraph>
                    <Break height={20} />
                    <Button
                      disabled={!actions.isValid()}
                      type='submit'
                      theme='primary'
                      label={`Mulai Bisnis`}
                      fullW
                      id='registration-step-2'
                      round={false}
                      onClick={() => handleFillBusinessInfoV2Step2(actions)}
                    />
                    <Break height={20} />
                    <Text.Heading
                      h={6}
                      color='black'
                      align='center'
                      onClick={() => props.close({})}
                    >
                      Lewati
                    </Text.Heading>
                    <Break height={20} />
                  </>
                )}
              />
            </Panel.Card>
          </FloatingBox>
        </Wrapper>
      )
    }

    const step1 = () => {
      return (
        <Wrapper
          position='relative'
          width='100%'
          height='100%'
          style={{ backgroundColor: '#f2f4f5', padding: '24px' }}
        >
          <img
            src={cdnImageUrl + 'post-logo/post-dark.png'}
            width='111px'
            height='56px'
            alt='logo post'
          />
          <FloatingBox>
            <Wrapper width='520px' isCenter align='center' verticalGap={8}>
              <Break height={12} />
              <Panel.Card padding={24}>
                <Break height={16} />
                <Text.Heading h={2} align='center'>
                  Daftarkan Bisnis Kamu
                </Text.Heading>
                <Break height={32} />
                <Form.Form
                  height='100%'
                  initialValues={
                    {
                      businessName: '',
                      businessCategoryId: '',
                    } as User
                  }
                  validationSchema={RegisterSuccessFormSchema}
                  render={(actions: FormAction<User>) => (
                    <Wrapper align='left'>
                      <Field.Text
                        name='businessName'
                        placeholder={`Nama Bisnis`}
                        label={`Nama Bisnis`}
                      />
                      <Field.Dropdown
                        label='Kategori Bisnis'
                        multilevel={true}
                        list={businessCategoryDropdown}
                        name='businessCategoryId'
                        placeholder='Pilih Kategori Bisnis'
                      />
                      <Break height={20} />
                      <Button
                        disabled={!actions.isValid()}
                        type='submit'
                        theme='primary'
                        label={`Lanjut`}
                        fullW
                        id='registration-step-1'
                        round={false}
                        onClick={() => handleFillBusinessInfoV2(actions)}
                      />
                      <Break height={16} />
                    </Wrapper>
                  )}
                />
              </Panel.Card>
            </Wrapper>
          </FloatingBox>
        </Wrapper>
      )
    }

    const newSuccessPage = () => {
      if (isNextStep) {
        return step2()
      } else {
        return step1()
      }
    }

    return (
      <Modal dismiss={() => {}} type='full' canDismissOutside={false}>
        {newSuccessPage()}
      </Modal>
    )
  }
