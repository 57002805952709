import React, {
  useContext,
  useRef,
  useEffect,
  useState,
  CSSProperties,
} from 'react'

import Context, { FormContext } from './context'
import { forEachDict } from 'utilities/converter/list'
import { Fields, FieldData } from './field'
import SubItemContext, { SubItemFormContext } from './subItemContext'
import { ValidationSchema, ValidationError } from 'utilities/validationCheck'

interface FormProps<T extends object = any> {
  validationSchema?: ValidationSchema<T>
  onChange?: (values: T) => any
  onChangeAtValid?: (values: T) => any
  initialValues: T
  height?: string
  render: (actions: FormAction<T>) => any
  onSubmit?: (action: FormAction<T>) => any
}

export interface FormAction<T> {
  submit: () => any
  setValues: (values: T) => any
  validate: () => boolean
  isValid: () => any
  reset: () => any
  getValues: () => T
  validateAndGetValue: () => T
  setFieldValue: <V>(mutate: (x: T) => any) => any
  disable: () => any
  enable: () => any
  setFieldError: (key: string, error: string) => any
  getError: (key: string) => string
}

const Form = <T extends object>(props: FormProps<T>): any => {
  const [valuesState, setValueState] = useState<T>(props.initialValues)
  const [validityState, setValidityState] = useState<boolean>(false)
  const [submittingState, setSubmittingState] = useState<boolean>(false)
  const [isDisabled, setIsDisabled] = useState<boolean>(false)
  const [fieldsState, setFieldsState] = useState<Fields<T>>({})

  const valuesRef = useRef<T>(valuesState)
  const fieldsRef = useRef<Fields<T>>(fieldsState)
  const validityRef = useRef<boolean>(validityState)

  function applyFields() {
    setFieldsState({ ...fieldsRef.current })
  }
  function applyValues() {
    setValueState({ ...valuesRef.current })
    if (props.onChange) {
      props.onChange(valuesRef.current)
    }

    if (props.onChangeAtValid && validityRef.current) {
      props.onChangeAtValid(valuesRef.current)
    }
  }
  function applyValidity() {
    setValidityState(validityRef.current)
  }

  const onFieldTouched = (key: string) => {
    const fields = fieldsRef.current

    if (!fields[key]) {
      return
    }
    validateAll()
    fields[key] = {
      ...fields[key],
      isDirty: true,
    }
    applyFields()
  }

  const validateAll = () => {
    validityRef.current = true
    if (!props.validationSchema) {
      applyValidity()
      return
    }

    const fields = fieldsRef.current
    const values = valuesRef.current

    forEachDict(fields, key => {
      fields[key] = {
        ...fields[key],
        error: undefined,
      }
    })

    try {
      const validationErrors: ValidationError[] =
        props.validationSchema.validate(values)
      if (validationErrors.length > 0) {
        validityRef.current = false
        validationErrors.forEach((e: ValidationError) => {
          const { path, message } = e
          fields[path] = {
            ...fields[path],
            error: message,
          }
        })
      }
    } catch (err) {}
    applyValidity()
  }

  useEffect(() => {
    if (props.initialValues) {
      validateAll()
    }
  }, [props.initialValues, props.validationSchema])

  const setFieldValue = function <V>(key: string, value: V) {
    const fields = fieldsRef.current
    const values = valuesRef.current

    fields[key] = {
      ...fields[key],
      isDirty: true,
    }
    const field: FieldData<T, V> = fields[key] as FieldData<T, V>
    field.setValueFunc(values, value)

    validateAll()
    applyValues()
    applyFields()
  }

  const submit = async () => {
    if (props.validationSchema) {
      validateAll()
      applyFields()
    }
  }

  const getField = function <V>(key: string): FieldData<T> {
    return fieldsState[key]
  }

  const registerField = function <V extends any>(
    key: string,
    name: string,
    mapSubItem: (item: T) => V | any
  ): FieldData<T, V> {
    const fields = fieldsRef.current

    let f = fields[key]

    if (!f || !f.setValueFunc || !f.name) {
      f = {
        ...f,
        setValueFunc: (values: T, val: any) => {
          mapSubItem(values)[name] = val
        },
        name,
      }
      fields[key] = f
      applyFields()
    }

    return f
  }
  const setFieldError = (key: string, error: string) => {
    const fields = fieldsRef.current
    if (!fields[key]) {
      return
    }
    fields[key].error = error
    applyFields()
  }

  const reset = () => {
    const fields = fieldsRef.current
    forEachDict(fields, key => {
      fields[key] = {
        ...fields[key],
        isDirty: false,
        error: undefined,
      }
    })
    valuesRef.current = props.initialValues
    validityRef.current = false
    applyValidity()
    applyValues()
    applyFields()
  }

  const setAllDirty = () => {
    const fields = fieldsRef.current

    forEachDict(fields, key => {
      fields[key] = {
        ...fields[key],
        isDirty: true,
      }
    })
  }

  const executeSubmitFunc = async (
    action: FormAction<T>,
    innerFunc: (action: FormAction<T>) => any
  ) => {
    try {
      setAllDirty()
      action.validateAndGetValue()
      if (!action.isValid()) {
        throw 'not valid'
      }
      action.disable()
      await innerFunc(action)
    } catch {}
    action.enable()
  }

  const action: FormAction<T> = {
    submit,
    setFieldError,
    setValues: (values: T) => {
      valuesRef.current = values
      applyValues()
    },
    isValid: () => validityState && !submittingState,
    validate: (): boolean => {
      validateAll()
      applyFields()
      return validityRef.current
    },
    setFieldValue: function <V>(mutate: (x: T) => any) {
      const v = valuesRef.current
      mutate(v)
      applyValues()
    },
    reset,
    validateAndGetValue: () => {
      validateAll()
      applyFields()
      return valuesState
    },
    getValues: () => valuesState,
    getError: (key: string) => fieldsRef.current[key]?.error,
    disable: () => setIsDisabled(true),
    enable: () => setIsDisabled(false),
  }

  const handleOnSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault()
    setSubmittingState(true)
    await executeSubmitFunc(action, props.onSubmit)
    setSubmittingState(false)
  }

  const style: CSSProperties = {}
  if (props.height) {
    style.height = props.height
  }

  return (
    <Context.Provider
      value={{
        values: valuesState,
        isDisabled,
        getField,
        onFieldTouched,
        registerField,
        setFieldValue,
      }}
    >
      <form
        autoComplete='off'
        style={style}
        onSubmit={handleOnSubmit}
        noValidate
      >
        {props.render(action)}
      </form>
    </Context.Provider>
  )
}

export interface FieldProps {
  name: string
  prefixName?: string
  mapSubItem?: (x: any) => any
  render: (renderingFieldContext: RenderingFieldContext) => React.ReactNode
}

export interface RenderingFieldContext<T = any> {
  field: FieldData<T>
  value: any
  onBlur: () => any
  onChange: (value: any) => any
}

function Field<T>(props: FieldProps): any {
  const ctx: FormContext = useContext(Context)
  const subCtx: SubItemFormContext = useContext(SubItemContext) || {
    prefixName: '',
    mapSubItem: x => x,
  }

  let mapSubItem = subCtx.mapSubItem

  if (props.mapSubItem) {
    mapSubItem = x => props.mapSubItem(subCtx.mapSubItem(x))
  }

  const key = [subCtx.prefixName, props.prefixName, props.name]
    .filter(x => !!x)
    .join('.')
  const field = ctx.registerField(key, props.name, mapSubItem)
  return props.render({
    field,
    //isDisabled: ctx.isDisabled,
    value: ctx.values[key],
    onBlur: () => ctx.onFieldTouched(key),
    onChange: (value: any) => ctx.setFieldValue(key, value),
  })
}

export interface ArrayHelperProps<T, Item extends Object> {
  name: string
  forEach: (values: T) => Item[]
  render: (x: Item, index: number) => React.ReactNode
}

function ArrayHelper<T, Item extends Object>(
  props: ArrayHelperProps<T, Item>
): any {
  const ctx: FormContext = useContext(Context)
  const list = props.forEach(ctx.values)
  return (
    <React.Fragment>
      {list.map((x, index) => (
        <SubItemContext.Provider
          value={{
            mapSubItem: x => props.forEach(x)[index],
            prefixName: props.name + '[' + index + ']',
          }}
        >
          {props.render(x, index)}
        </SubItemContext.Provider>
      ))}
    </React.Fragment>
  )
}

export default {
  ArrayHelper,
  Form,
  Field,
}
