import { BaseApi } from 'api/base.api'
import { IBankApi } from 'domains/bank/contracts/api'
import {
  BankAccountCreateModel,
  BankAccountModel,
  BankModel,
  BankOTPModel,
  BankOTPRequest,
  BankAccountCheckModel,
  BankAccountCheckResponse,
  BankAccountUpdateModel,
} from 'domains/bank/models'

export default class BankApi extends BaseApi implements IBankApi {
  constructor() {
    super('/banks/v1')
  }

  public async getBanks(): Promise<BankModel[]> {
    return await this.makeForCamelCaseForList('GET', '/banks')
  }

  public async getBankAccounts(): Promise<BankAccountModel[]> {
    return await this.makeForCamelCaseForList('GET', '/bank-accounts')
  }

  public async createBankAccount(
    bankAccount: BankAccountCreateModel
  ): Promise<BankAccountModel> {
    return await this.makeForCamelCase('POST', '/bank-accounts', bankAccount)
  }

  public async getBankAccount(
    bankAccountId: string
  ): Promise<BankAccountModel> {
    return await this.makeForCamelCase('GET', `/bank-accounts/${bankAccountId}`)
  }

  public async validateBankAccount(
    bankAccount: BankAccountCheckModel
  ): Promise<BankAccountCheckResponse> {
    return await this.makeForCamelCase(
      'POST',
      `/bank-account/validation`,
      bankAccount
    )
  }

  public async createOTP(): Promise<BankOTPModel> {
    return await this.makeForCamelCase('POST', '/otp')
  }

  public async validateOTP(
    token: string,
    request: BankOTPRequest
  ): Promise<void> {
    return await this.makeForCamelCase(
      'POST',
      `/otp/${token}/validate`,
      request
    )
  }

  public async resendOTP(token: string): Promise<void> {
    return await this.makeForCamelCase('POST', `/otp/${token}/resend`)
  }

  public async createUpdateBankAccountToken(
    userId: string
  ): Promise<BankOTPModel> {
    return await this.makeForCamelCase(
      'POST',
      `/bank-accounts/${userId}/update-account-token`
    )
  }

  public async updateBankAccount(
    userId: string,
    queryParams: BankAccountUpdateModel
  ): Promise<void> {
    await this.makeForCamelCase(
      'POST',
      `/bank-accounts/${userId}/update-account`,
      queryParams
    )
  }

  public async checkUpdateEligible(bankAccountId: string): Promise<void> {
    await this.makeForCamelCase(
      'GET',
      `/bank-accounts/${bankAccountId}/check-update-eligible`
    )
  }
}
