import { t } from '@lingui/macro'
import { SchemaValidation, ValidationContext } from 'utilities/validationCheck'
import { i18n } from '@lingui/core'
import {
  CreateSingleProductRequest,
  CreateSingleProductRequestSchema,
  StockAdjustment,
} from 'domains/management/products/models'

const validateDimension = (
  value: number,
  context: ValidationContext<CreateSingleProductRequestSchema>
) => {
  const { length, width, height } = context.parent
  if (!length && !width && !height) {
    return true
  }
  if (!length || !width || !height) {
    return false
  }
  return true
}

export const productFormSchema =
  SchemaValidation.object<CreateSingleProductRequestSchema>().shape({
    name: SchemaValidation.string()
      .min(3, i18n._(t`Nama Produk harus lebih dari 3 huruf`))
      .required(i18n._(t`Nama tidak boleh kosong`)),
    price: SchemaValidation.number()
      .requiredAllowZero(i18n._(t`Harga jual tidak boleh kosong`))
      .test(
        i18n._(t`Harga jual tidak boleh lebih kecil dari harga modal`),
        function (
          value: number,
          context: ValidationContext<CreateSingleProductRequest>
        ) {
          if (context.parent.unitCost) {
            return context.parent.unitCost <= value
          }
          return true
        }
      ),
    barcode: SchemaValidation.string().matches(
      /^[0-9]*$/,
      i18n._(t`Barcode hanya diisi oleh angka`)
    ),
    sku: SchemaValidation.string().min(
      3,
      i18n._(t`SKU harus lebih dari 3 huruf`)
    ),
    unitCost: SchemaValidation.number().test(
      i18n._(t`Harga modal tidak boleh lebih besar dari harga jual`),
      function (
        value: number,
        context: ValidationContext<CreateSingleProductRequest>
      ) {
        if (value === undefined) {
          return true
        }
        if (context.parent.price > 0) {
          return context.parent.price >= value
        } else {
          return true
        }
      }
    ),
    pricing: SchemaValidation.array().of(
      SchemaValidation.object().shape({
        price: SchemaValidation.number()
          .min(1, i18n._(t`Harga harus diatas 0`))
          .required(i18n._(t`Harga jual tipe pesanan tidak boleh kosong`)),
        // .test("Harga kurang dari harga beli", function (value: number, context:  ValidationContext<CreateSingleProductRequest, ProductPricing>) {
        //   if (value === undefined) {
        //     return true;
        //   }
        //   if (context.root.unitCost > 0) {
        //     return context.root.unitCost > value;
        //   } else {
        //     return true;
        //   }
        // })
      })
    ),
    stockAdjustment: SchemaValidation.object().shape({
      quantity: SchemaValidation.number()
        .min(1, i18n._(t`Stok harus lebih dari 0`))
        .test(
          i18n._(t`Stok tidak boleh kosong`),
          function (
            value: number,
            context: ValidationContext<
              CreateSingleProductRequest,
              StockAdjustment
            >
          ) {
            if (!context.root.trackStock) {
              return true
            }
            if (!context.parent.type) {
              return true
            }
            if (value === undefined || value === null) {
              return false
            }
            return true
          }
        ),
      note: SchemaValidation.string().matches(
        /^.{0,160}$/,
        i18n._(t`Maksimal catatan adalah 160 karakter`)
      ),
    }),
    minStock: SchemaValidation.number().test(
      i18n._(t`Min Stok tidak boleh negatif`),
      function (
        value: number,
        context: ValidationContext<CreateSingleProductRequest, StockAdjustment>
      ) {
        if (!context.root.trackStock) {
          return true
        }
        if (value == undefined) {
          return true
        }
        return value >= 0
      }
    ),
    weight: SchemaValidation.number().min(
      1,
      i18n._(t`Berat harus lebih dari 0`)
    ),
    length: SchemaValidation.number()
      .min(1, i18n._(t`Panjang harus lebih dari 0`))
      .test(i18n._(t`Berat dan dimensi harus diisi semua`), validateDimension),
    width: SchemaValidation.number()
      .min(1, i18n._(t`Lebar harus lebih dari 0`))
      .test('-', validateDimension),
    height: SchemaValidation.number()
      .min(1, i18n._(t`Tinggi harus lebih dari 0`))
      .test('-', validateDimension),
  })
