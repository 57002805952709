import { BaseStorage } from 'storages/base'
import { SET_SHIFT_SETTING } from './actions'
import { IShiftSettingStorage } from 'domains/management/shift/contracts/storage'
import { ShiftSetting } from 'domains/management/shift/models'

export class ShiftSettingStorage
  extends BaseStorage
  implements IShiftSettingStorage
{
  public setShiftSetting(shiftSetting: ShiftSetting): void {
    this.dispatch(SET_SHIFT_SETTING, shiftSetting)
  }
}
