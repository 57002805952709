import { AppState } from 'domains/contracts/appState'
import { Subscription, SubscriptionAlert } from 'domains/subscriptions/model'
import { toList } from 'utilities/converter/list'
import { BadgeColor } from 'views/components/const'

export interface SubscriptionViewModel {
  currentSubscription?: Subscription
  subscriptions: Subscription[]
  subscriptionAlert: SubscriptionAlert
}

export default function (state: AppState): SubscriptionViewModel {
  const list = toList(state.subscriptions.subscriptions)
  return {
    subscriptions: list,
    currentSubscription: list.filter(
      x => x.status.toUpperCase() === 'WAITING_PAYMENT'
    )[0],
    subscriptionAlert: state.subscriptions.subscriptionAlert,
  }
}

export function getSubscriptionBadge(subscription: Subscription): {
  status: string
  color: BadgeColor
} {
  switch (subscription.status.toUpperCase()) {
    case 'WAITING_PAYMENT':
      return {
        status: 'MENUNGGU PEMBAYARAN',
        color: 'yellow',
      }
    case 'ENDED':
      return {
        status: 'SELESAI',
        color: 'darkGray',
      }
    case 'CANCELLED':
      return {
        status: 'BATAL',
        color: 'red',
      }
  }
  return {
    status: 'LUNAS',
    color: 'green',
  }
}
