import React, { useState, useEffect } from 'react'
import InputBase from '../base'
import { Icon, Text, Form } from 'views/components'
import './style.css'
import { RenderingFieldContext } from 'views/components/Form'
import { Dropdown, DropdownAction } from 'views/components/Dropdown'
import Checkbox from 'views/components/Checkbox'
import { Break } from 'views/components/Common'
import Button from 'views/components/Button'

export interface DropdownItem<T = string> {
  value: T
  label: string
  isDisabled?: boolean
}

interface Props<T> {
  label?: string
  placeholder?: string
  list: DropdownItem<T>[]
  name: string
  onChange?: (values: any[]) => any
  value?: string[]
  prefix?: React.ReactNode
  className?: string
  title: string
  style?: React.CSSProperties
}

export default <T extends any>(props: Props<T>) => {
  const valueState = useState<string[]>(props.value)
  const [checkedList, setCheckedList] = useState([])
  const [shownValue, setShownValue] = useState('')
  const allList = Object.values(props.list.map(x => x.value))

  useEffect(() => {
    if (props.value) {
      handlePropsOnChange(props.value)
    }
  }, [])

  const renderDropdownAndOverlay = (
    { field, value, onChange, onBlur }: RenderingFieldContext,
    { setVisibility, isVisible, toggle }: DropdownAction
  ): [React.ReactNode, React.ReactNode] => {
    if (value && value != valueState[0]) {
      updateSelection(value)
      handlePropsOnChange(value)
      setCheckedList(value)
    }

    const onSelect = (value: any) => {
      onChange(value)
      updateSelection(value)
      handlePropsOnChange(value)
      setVisibility(false)
    }

    const handleCheckButton = (value: string) => {
      let newArr = checkedList
      if (newArr.includes(value)) {
        newArr = newArr.filter(e => e !== value)
      } else {
        newArr = [value, ...newArr]
      }
      setCheckedList(newArr)
    }

    const handleCheckAll = () => {
      if (checkedList.length !== allList.length) {
        setCheckedList(allList)
      } else {
        setCheckedList([])
      }
    }

    const renderList = () =>
      props.list.map((item, i) => {
        if (item.value !== undefined && item.label !== undefined) {
          return (
            <>
              <div style={{ display: 'flex' }}>
                <Checkbox
                  checked={
                    checkedList &&
                    !item.isDisabled &&
                    checkedList.includes(item.value)
                  }
                  onChange={() => handleCheckButton(String(item.value))}
                  isDisabled={item.isDisabled}
                />
                <Text.Span color={item.isDisabled ? 'gray' : 'black'}>
                  {item.label}
                </Text.Span>
              </div>
              <Break height={12} />
            </>
          )
        }
        return null
      })

    const renderCheckboxList = () => {
      return (
        <div
          style={{
            width: '100%',
            backgroundColor: '#fff',
            height: '100%',
            border: '1px solid #e0e0e0',
            borderRadius: '4px',
            marginTop: '3px',
            padding: '16px',
            position: 'relative',
            zIndex: 20,
            ...props.style,
          }}
        >
          {props.prefix}
          <Break height={12} />
          <div
            style={{
              maxHeight: '135px',
              overflowY: 'scroll',
            }}
          >
            <div style={{ display: 'flex' }}>
              <Checkbox
                checked={checkedList && checkedList.length === allList.length}
                onChange={() => handleCheckAll()}
              />
              <span>Semua {props.title}</span>
            </div>
            <Break height={12} />
            {renderList()}
          </div>
          <hr style={{ borderTop: '1px solid #e0e0e0' }} />
          <Break height={6} />
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Text.Heading h={6}>
              {checkedList && checkedList.length} {props.title} terpilih
            </Text.Heading>
            <Button theme='primary' onClick={() => onSelect(checkedList)}>
              Simpan
            </Button>
          </div>
        </div>
      )
    }

    const selectedItem = shownValue
    return [
      <div className='inner' onClick={toggle}>
        {selectedItem ? (
          <Text.Span dangerousContent={selectedItem}></Text.Span>
        ) : (
          <Text.Paragraph color='gray'>{props.placeholder}</Text.Paragraph>
        )}
        <div
          onClick={toggle}
          style={{
            width: '25px',
            height: '35px',
            position: 'absolute',
            backgroundColor: 'transparent',
            right: '-25px',
            top: '0',
            zIndex: 999,
          }}
        />
      </div>,
      <div
        className={`post-input-select-option ${
          isVisible ? 'visible' : 'hidden'
        }`}
        style={isVisible ? { marginBottom: '100px' } : {}}
      >
        {props.list && renderCheckboxList()}
      </div>,
    ]
  }

  const handlePropsOnChange = (value?: any) => {
    if (props.onChange) {
      updateSelection(value)
      setCheckedList(value)
      props.onChange(value)
    }
  }

  const updateSelection = (value: string[]) => {
    valueState[1](value)
    setShownValue(
      value && value.length === allList.length
        ? `Semua ${props.title}`
        : `${value.length} ${props.title} terpilih`
    )
  }

  const renderInner = (fieldContext: RenderingFieldContext) => (
    <InputBase
      label={props.label}
      className={props.className}
      suffix={<Icon.Caretdown color='black' />}
      error={fieldContext.field.isDirty && fieldContext.field.error}
    >
      <Dropdown
        className='post-input-select dropdown-checkbox'
        onClose={() => fieldContext.onBlur()}
        alwaysRenderOverlay={true}
        render={dropdownAction =>
          renderDropdownAndOverlay(fieldContext, dropdownAction)
        }
      ></Dropdown>
    </InputBase>
  )
  return <Form.Field name={props.name} render={renderInner} />
}
