import { LocationListQueryString, LocationState } from 'domains/location/states'
import { Reducer, StateActions } from 'utilities/redux/reducer'
import { LocationModel } from 'domains/location/models'
import {
  PUSH_LOCATIONS,
  SET_LOADING_MORE_LOCATION,
  SET_LOCATION,
  SET_LOCATION_LIST_REACH_BOTTOM,
  SET_LOCATION_LIST_REQUEST,
} from './actions'
import { Dict } from 'utilities/type'

export class LocationReducer extends Reducer<LocationState> {
  constructor() {
    super({
      areas: {} as Dict<LocationModel>,
      locationListManager: {
        isLoadingMoreLocation: false,
        request: {},
        hasReachedBottom: false,
      },
    })
  }

  setLocation = (
    state: LocationState,
    areas: Dict<LocationModel>
  ): LocationState => {
    return {
      ...state,
      areas: areas,
    }
  }

  pushLocation = (
    state: LocationState,
    areas: Dict<LocationModel>
  ): LocationState => {
    return {
      ...state,
      areas: {
        ...state.areas,
        ...areas,
      },
    }
  }

  setLocationListReachBottom = (
    state: LocationState,
    hasReachedBottom: boolean
  ): LocationState => {
    return {
      ...state,
      locationListManager: {
        ...state.locationListManager,
        hasReachedBottom,
      },
    }
  }

  setLoadingMoreLocation = (
    state: LocationState,
    isLoadingMoreLocation: boolean
  ): LocationState => {
    return {
      ...state,
      locationListManager: {
        ...state.locationListManager,
        isLoadingMoreLocation,
      },
    }
  }

  setLocationListRequest = (
    state: LocationState,
    request: LocationListQueryString
  ): LocationState => {
    return {
      ...state,
      locationListManager: {
        ...state.locationListManager,
        request,
      },
    }
  }

  get actions(): StateActions<LocationState> {
    return {
      [SET_LOCATION]: this.setLocation,
      [PUSH_LOCATIONS]: this.pushLocation,
      [SET_LOCATION_LIST_REACH_BOTTOM]: this.setLocationListReachBottom,
      [SET_LOADING_MORE_LOCATION]: this.setLoadingMoreLocation,
      [SET_LOCATION_LIST_REQUEST]: this.setLocationListRequest,
    }
  }
}
