import React, { useState, useEffect } from 'react'
import ReactModal from 'react-modal'
import { useSelector } from 'react-redux'
import moment from 'moment'

import { commonService } from 'injectors'
import { AppState } from 'domains/contracts/appState'
import { User } from 'domains/users/models'
import { BankOTPRequest } from 'domains/bank/models'
import { capitalizeFirstLetter } from 'utilities/strings/capitalizeFirstLetter'
import { withLeadingZero } from 'utilities/converter/modeller'
import { toDateString } from 'utilities/converter/date'
import { CountdownCounter, TimerCountdown } from 'utilities/date'
import {
  Text,
  Sided,
  Icon,
  Break,
  Field,
  Button,
  Form,
  FormAction,
  SuccessModal,
} from 'views/components'
import Loader from 'views/components/Loader'
import Wrapper from 'views/components/Wrapper'
import './style.scss'
import { otpFormSchema } from './otpFormSchema'

interface OtpFormModalProps {
  isOpenModal: boolean
  closeModal: () => void
  selectedOutletId?: string
  onSubmit: (otp: string) => any
  resendOtp: () => void
}

const OtpFormModal = (props: OtpFormModalProps) => {
  const user = useSelector<AppState, User>(state => state.user)
  const [timeLeft, setTimeLeft] = useState<TimerCountdown | null>()
  const [isLoading, setIsloading] = useState<boolean>(false)
  const [successModal, setSuccessModal] = useState(false)
  const oneMonthLater = toDateString(
    moment().add(30, 'day').toDate(),
    'DD MMMM YYYY'
  )

  const countdownCounter = new CountdownCounter(t => {
    setTimeLeft(t)
  })

  const startCountdown = () => {
    const t = moment().add(60, 'second').toDate()
    countdownCounter.start(t)
  }

  useEffect(() => {
    startCountdown()
  }, [])

  const resendOTP = async () => {
    try {
      props.resendOtp()
      startCountdown()
    } catch (error) {
      commonService.setAlert({
        message: error.message,
        type: 'error',
      })
    }
  }

  const submitOTP = async (actions: FormAction<BankOTPRequest>) => {
    setIsloading(true)
    try {
      await props.onSubmit(actions.getValues().otp)
      setSuccessModal(true)
      setIsloading(false)
    } catch (error) {
      setIsloading(false)
      commonService.setAlert({
        message: capitalizeFirstLetter(error.message),
        type: 'error',
      })
    }
  }

  const renderSendOTPLink = () => (
    <Text.Heading
      className='cursor-pointer'
      h={6}
      align='center'
      noMargin
      color='blue'
      onClick={() => resendOTP()}
    >
      Kirim Ulang Kode OTP
    </Text.Heading>
  )

  const renderCountdown = () => (
    <Text.Heading
      className='cursor-pointer'
      h={6}
      align='center'
      noMargin
      color='semiGray'
    >
      Kirim ulang Kode OTP ({withLeadingZero(timeLeft.minutes, 2)}:
      {withLeadingZero(timeLeft.seconds, 2)})
    </Text.Heading>
  )

  const handleCloseModal = () => {
    setSuccessModal(false)
    props.closeModal()
  }

  return (
    <ReactModal
      isOpen={props.isOpenModal}
      style={{
        overlay: {
          zIndex: 99,
        },
        content: {
          inset: 0,
          borderRadius: 0,
          backgroundColor: '#F7F9FA',
          padding: 0,
        },
      }}
      ariaHideApp={false}
    >
      <Wrapper height='100%' width='100%'>
        <Wrapper padding='40px 36px'>
          <Sided.Sided gutter={16}>
            <Sided.Fixed>
              <Icon.ArrowLeft
                color='black'
                onClick={() => props.closeModal()}
              />
            </Sided.Fixed>
            <Sided.Remaining>
              <Text.Heading h={3} noMargin align='left' color='black'>
                Konfirmasi OTP
              </Text.Heading>
            </Sided.Remaining>
          </Sided.Sided>
          <Break height={40} />
          <Break height={16} />
          {isLoading ? (
            <Wrapper align='center'>
              <Break height={40} />
              <Loader position='relative' />
            </Wrapper>
          ) : (
            <Wrapper width='320px' className='forgot-password-middle'>
              <Text.Paragraph size={14} color='brownGray' align='center'>
                Masukkan kode OTP yang dikirimkan
                <br />
                ke <strong>{user.phone}</strong>
                <br />
                Untuk konfirmasi penambahan akun bank
              </Text.Paragraph>
              <Break height={40} />
              <Form.Form
                height='100%'
                initialValues={{
                  otp: '',
                }}
                validationSchema={otpFormSchema}
                onSubmit={submitOTP}
                render={(action: FormAction<BankOTPRequest>) => {
                  return (
                    <React.Fragment>
                      <Field.Text
                        numberOnly
                        label='KODE OTP'
                        name='otp'
                        placeholder='Tulis Kode OTP'
                        onKeyPress={e => {
                          const target: any = e.target
                          if (target.value.length === 6) {
                            e.preventDefault()
                          }
                        }}
                      />
                      <Button
                        fullW
                        size='large'
                        label='Lanjut'
                        theme='primary'
                        type='submit'
                        disabled={!action.isValid()}
                      />
                    </React.Fragment>
                  )
                }}
              />
              <Break height={20} />
              {timeLeft ? renderCountdown() : renderSendOTPLink()}
            </Wrapper>
          )}
        </Wrapper>
      </Wrapper>
      {successModal && (
        <SuccessModal
          isOpenModal={successModal}
          closeModal={handleCloseModal}
          title='Akun Bank berhasil diubah'
          content={`Kamu dapat merubah akun bank kembali setelah tanggal ${oneMonthLater}.`}
        />
      )}
    </ReactModal>
  )
}

export default OtpFormModal
