import { SchemaValidation } from 'utilities/validationCheck'

export interface DeviceFormField {
  name: string
  outletId: string
}

const DeviceFormSchema = SchemaValidation.object<DeviceFormField>().shape({
  name: SchemaValidation.string()
    .min(3, 'Nama Perangkat tidak boleh kurang dari 3 karakter')
    .required('Nama Perangkat tidak boleh kosong'),
  outletId: SchemaValidation.string().required('Outlet tidak boleh kosong'),
})

export default DeviceFormSchema
