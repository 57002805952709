import React from 'react'
import './style.css'
import classNames from 'classnames'
import { BaseParentProps, BaseProps } from '../props'

interface WrapperProps extends BaseParentProps, BaseProps {
  width?: string
  height?: string
  minHeight?: string
  backgroundColor?: string
  padding?: string
  margin?: string
  position?:
    | 'inherit'
    | 'initial'
    | 'absolute'
    | 'fixed'
    | 'relative'
    | 'static'
    | 'sticky'
  isCenter?: boolean
  align?: 'left' | 'center' | 'right'
  shadow?: string
  border?: string
  isCorner?: boolean
  display?: string
  onClick?: any
  verticalGap?: 0 | 8 | 16 | 24 | 32 | 40
  isSelected?: boolean
}

const Wrapper = (props: WrapperProps) => {
  const style: React.CSSProperties = {
    width: props.width,
    height: props.height,
    minHeight: props.minHeight,
    backgroundColor: props.backgroundColor,
    padding: props.padding,
    position: props.position,
    textAlign: props.align,
    margin: props.margin,
    border: props.border,
    boxShadow: props.shadow,
    display: props.display,
    ...props.style,
  }

  const className = classNames(
    'post-wrapper',
    props.isCenter ? 'post-wrapper-center-width' : '',
    props.isCorner ? 'post-wrapper-border-corner' : '',
    props.isSelected ? 'post-wrapper-selected' : '',
    'post-wrapper-verticalgap-' + (props.verticalGap || 0),
    { 'cursor-pointer': props.onClick },
    props.className || ''
  )

  return (
    <div
      className={className}
      style={style}
      onClick={props.onClick}
      id={props.id}
    >
      {props.children}
    </div>
  )
}

export default Wrapper
