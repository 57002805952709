import { BaseReportingService } from '.'
import { ShiftReport } from 'domains/reporting/models'

export class ShiftReportingService
  extends BaseReportingService
  implements IShiftReportingService
{
  public getShiftListReporting = async () => {
    console.warn('deprecated, use from src/client instead')
    const shiftReports: ShiftReport[] = []
    this.reportingStorage.setShiftReporting(shiftReports)
  }
}

export interface IShiftReportingService {
  getShiftListReporting(): Promise<void>
}
