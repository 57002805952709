import {
  SubscriptionAlert,
  SubscriptionPackagePricing,
  SubscriptionPackage,
  SubscriptionMethod,
  Subscription,
} from 'domains/subscriptions/model'
import {
  SET_SUBSCRIPTION_ALERT,
  SET_SUBSCRIPTIONS,
  SET_SUBSCRIPTION,
  SET_AVAILABLE_PACKAGE,
  SET_SUBSCRIPTION_PACKAGE,
  SET_SUBSCRIPTION_METHOD,
  REMOVE_SUBSCRIPTION,
} from './actions'
import { BaseStorage } from 'storages/base'
import { ISubscriptionStorage } from 'domains/subscriptions/contracts/storage'
import { toList } from 'utilities/converter/list'

export class SubscriptionStorage
  extends BaseStorage
  implements ISubscriptionStorage
{
  setSubscriptionAlert(alert: SubscriptionAlert): void {
    this.dispatch(SET_SUBSCRIPTION_ALERT, alert)
  }
  setAvailablePackage(availablePackages: SubscriptionPackagePricing[]): void {
    this.dispatch(SET_AVAILABLE_PACKAGE, availablePackages)
  }
  getAvailablePackages(): SubscriptionPackagePricing[] {
    return toList(this.getState().subscriptions.availablePackages)
  }
  setSubscriptionPackage(subscriptionPackage: SubscriptionPackage[]): void {
    this.dispatch(SET_SUBSCRIPTION_PACKAGE, subscriptionPackage)
  }
  setSubscriptionMethod(subscriptionMethod: SubscriptionMethod[]): void {
    this.dispatch(SET_SUBSCRIPTION_METHOD, subscriptionMethod)
  }
  setSubscriptions(subscriptions: Subscription[]): void {
    this.dispatch(SET_SUBSCRIPTIONS, subscriptions)
  }
  setSubscription(subscription: Subscription): void {
    this.dispatch(SET_SUBSCRIPTION, subscription)
  }

  removeSubscription(id: string): void {
    this.dispatch(REMOVE_SUBSCRIPTION, id)
  }
}
