import { i18n } from '@lingui/core'
import {
  detect,
  fromNavigator,
  fromStorage,
  fromUrl,
} from '@lingui/detect-locale'
import { I18nProvider } from '@lingui/react'
import React, { createContext, ReactNode, useEffect, useState } from 'react'

import { featureCheck, getAppConfig, isAnyOfTheseFeaturesEnabled } from 'config'
import { CONFIG_LOCALSTORAGE } from 'constants/localStorage'
import { setLocalStorage } from 'utilities/localStorage'

export const languageList: Record<string, string> = {
  en: 'English',
  id: 'Bahasa Indonesia',
  'pseudo-LOCALE': 'pseudo-LOCALE (testing)',
}

const { mode } = getAppConfig()
const isLocal = mode === 'local'
if (!isLocal) {
  delete languageList['pseudo-LOCALE']
}

export async function dynamicActivate(locale: string): Promise<void> {
  const { messages } = isLocal
    ? await import(`@lingui/loader!../../locales/${locale}/messages.po`)
    : await import(`../../locales/${locale}/messages`)
  i18n.load(locale, messages)
  i18n.activate(locale)
}

export const useLanguageDetection = (defaultLang = 'id') => {
  useEffect(() => {
    const DEFAULT_FALLBACK = () => defaultLang

    const result = detect(
      fromUrl('lang'),
      fromStorage(CONFIG_LOCALSTORAGE.USER_PREFERRED_LANGUAGE),
      fromNavigator(),
      DEFAULT_FALLBACK
    )
    let lang = 'id'
    if (
      isAnyOfTheseFeaturesEnabled(
        featureCheck.miscellaneous.i18nLanguageTranslation
      )
    ) {
      lang = result === 'pseudo-LOCALE' ? result : result.split('-')[0]
    }
    dynamicActivate(lang)
  }, [defaultLang])
}

export function useLanguage(defaultLanguage = 'id') {
  const [language, _setLanguage] = useState(defaultLanguage)
  const supportedLocales = Object.keys(languageList)
  const setLanguage = (locale: string) => {
    if (supportedLocales.includes(locale)) {
      _setLanguage(locale)
      dynamicActivate(locale)
      setLocalStorage(CONFIG_LOCALSTORAGE.USER_PREFERRED_LANGUAGE, locale)
    }
  }
  return { language, setLanguage, supportedLocales }
}

export const Language = createContext<{
  language?: string
  setLanguage: (locale: string) => void
  supportedLocales: string[]
}>(null)

interface LanguageProviderProps {
  defaultLanguage: string
  children?: ReactNode
}

export const LanguageProvider = ({
  defaultLanguage,
  children,
}: LanguageProviderProps) => {
  const value = useLanguage(defaultLanguage)
  useLanguageDetection(defaultLanguage)
  return (
    <Language.Provider value={value}>
      <I18nProvider i18n={i18n}>{children}</I18nProvider>
    </Language.Provider>
  )
}
