import { BaseService } from 'domains/commons/services'
import { ICommonStorage } from 'domains/commons/contracts/storage'
import { Api } from 'domains/contracts/api'
import { IExpenseStorage } from '../contracts/storage'
import { Expense, ExpenseCategory, ExpenseRequestBody } from '../models'
import { toDict } from 'utilities/converter/list'
import { ExpenseSummary } from 'domains/reporting/models'
import { AppStore } from 'storages'
import { expenseService, reportingService } from 'injectors'

const SET_EXPENSES = 'SET_EXPENSES'

export class ExpenseService extends BaseService implements IExpenseService {
  expenseStorage: IExpenseStorage

  constructor(
    commonStorage: ICommonStorage,
    api: Api,
    expenseStorage: IExpenseStorage
  ) {
    super(commonStorage, api)
    this.expenseStorage = expenseStorage
  }

  public createExpense = async (req: ExpenseRequestBody): Promise<Expense> => {
    try {
      this.setLoading(SET_EXPENSES, true)
      const expense = await this.api.management.expense.createExpense(req)
      const expenseSummary: ExpenseSummary = {
        amount: expense.amount,
        category: expense.category,
        expenses: [expense],
        fromDate: expense.recordedDate,
        toDate: expense.recordedDate,
        userId: expense.userId,
        name: expense.name,
        outletId: expense.outletId,
        recordedDate: expense.recordedDate,
      }
      if (
        Object.keys(AppStore.getState().management.expense.expenses).length ===
        0
      ) {
        this.expenseStorage.appendExpenses({ [expense.id]: expenseSummary })
      }
      this.setLoading(SET_EXPENSES, false)
      return expense
    } catch (error) {
      console.error(error)
    }
    return null
  }

  public updateExpense = async (
    req: ExpenseRequestBody,
    expenseId: string
  ): Promise<Expense> => {
    try {
      this.setLoading(SET_EXPENSES, true)
      const data = await this.api.management.expense.updateExpense(
        req,
        expenseId
      )
      const { management } = this.getState()
      const { expense, outlet } = management
      const newExpenses = expense.expenseDetails
      if (
        data.recordedDate !== newExpenses[data.id].recordedDate ||
        data.category.id !== newExpenses[data.id].category.id ||
        data.outletId !== newExpenses[data.id].outletId
      ) {
        if (Object.keys(expense.expenseDetails).length === 1) {
          expenseService.showExpenseDetails(false)
          reportingService.expense.getExpenseReportings(
            AppStore.getState().reporting.filter
          )
        }
        delete newExpenses[data.id]
        this.expenseStorage.setExpenseDetails(newExpenses)
      } else {
        newExpenses[data.id] = data
        newExpenses[data.id].outletName =
          outlet.outlets[String(newExpenses[data.id].outletId)].name
        this.expenseStorage.setExpenseDetails(newExpenses)
      }
      this.setLoading(SET_EXPENSES, false)
      return data
    } catch (error) {
      console.error(error)
    }
    return null
  }

  public showExpenseDetails = (isShown: boolean) => {
    this.expenseStorage.setShowExpenseDetails(isShown)
  }

  public setExpenseDetails = (expense: Expense[]) => {
    const expenseDict = toDict(expense, e => e.id)
    this.expenseStorage.setExpenseDetails(expenseDict)
  }

  public setExpenseFilterCategoryName = (categoryName: string) => {
    this.expenseStorage.setExpenseFilterCategoryName(categoryName)
  }

  public deleteExpense = async (expenseId: string): Promise<void> => {
    try {
      await this.api.management.expense.deleteExpense(expenseId)
      const { expense } = this.getState().management
      const currentCategoryId = expense.expenseDetails[expenseId].category.id
      delete expense.expenseDetails[expenseId]
      this.expenseStorage.setExpenseDetails(expense.expenseDetails)
      if (Object.keys(expense.expenseDetails).length === 0) {
        expenseService.showExpenseDetails(false)
        delete expense.expenses[currentCategoryId]
        this.expenseStorage.setExpenses(expense.expenses)
      }
      if (Object.keys(expense.expenses).length === 0) {
        delete expense.expenses[Object.keys(expense.expenses)[0]]
        this.expenseStorage.setExpenses(expense.expenses)
      }
      return
    } catch (error) {
      console.error(error)
    }
    return null
  }

  public createCategory = async (name: string): Promise<ExpenseCategory> => {
    try {
      const category = await this.api.management.expense.createCategory(name)
      this.expenseStorage.appendCategories({ [category.id]: category })
      return category
    } catch (error) {
      console.error(error)
    }
    return null
  }

  public updateCategory = async (
    category: ExpenseCategory
  ): Promise<ExpenseCategory> => {
    try {
      const savedCategory = await this.api.management.expense.updateCategory(
        category
      )
      this.expenseStorage.appendCategories({
        [savedCategory.id]: savedCategory,
      })
      return savedCategory
    } catch (error) {
      console.error(error)
    }
    return null
  }

  public deleteCategory = async (categoryId: string): Promise<void> => {
    try {
      await this.api.management.expense.deleteCategory(categoryId)
      const { categories } = this.getState().management.expense
      delete categories[categoryId]
      this.expenseStorage.setCategories(categories)
      return
    } catch (error) {
      console.error(error)
    }
    return null
  }

  public getCategories = async (): Promise<ExpenseCategory[]> => {
    try {
      const categories = await this.api.management.expense.getCategories()
      this.expenseStorage.setCategories(toDict(categories, c => c.id))
      return categories
    } catch (error) {
      console.error(error)
    }
    return []
  }
}

export interface IExpenseService {
  createExpense: (req: ExpenseRequestBody) => Promise<Expense>
  updateExpense: (
    req: ExpenseRequestBody,
    expenseId: string
  ) => Promise<Expense>
  deleteExpense: (expenseId: string) => Promise<void>
  getCategories: () => Promise<ExpenseCategory[]>
  createCategory: (name: string) => Promise<ExpenseCategory>
  updateCategory: (category: ExpenseCategory) => Promise<ExpenseCategory>
  deleteCategory: (categoryId: string) => Promise<void>
  showExpenseDetails: (isShown: boolean) => void
  setExpenseDetails: (details: Expense[]) => void
  setExpenseFilterCategoryName: (categoryName: string) => void
}
