import { SET_SUB_MENU, SET_MAIN_MENU, SET_SUB_MENU_V2 } from './actions'
import { RouteDescription } from 'domains/layouts/services/route.item'
import { BaseStorage } from 'storages/base'
import { ILayoutStorage } from 'domains/layouts/contracts/storage'
import { RouteItemV2 } from 'route'

export class LayoutStorage extends BaseStorage implements ILayoutStorage {
  setMainMenuView(): void {
    this.dispatch(SET_MAIN_MENU)
  }

  setSubmenuView(routeItem: RouteDescription): void {
    this.dispatch(SET_SUB_MENU, routeItem)
  }

  setSubmenuViewV2(routeItem: RouteItemV2): void {
    this.dispatch(SET_SUB_MENU_V2, routeItem)
  }
}
