import { Reducer, StateActions } from 'utilities/redux/reducer'
import { DiscountState } from 'domains/management/discounts/states'
import {
  Discount,
  GettingDiscountsRequest,
} from 'domains/management/discounts/models'
import { Dict } from 'utilities/type'
import {
  SET_DISCOUNT_PRODUCT,
  PUSH_DISCOUNT,
  SET_DISCOUNT_LIST_REACH_BOTTOM,
  SET_LOADING_MORE_DISCOUNT,
  SET_DISCOUNT_LIST_REQUEST,
} from './actions'

export class DiscountReducer extends Reducer<DiscountState> {
  constructor() {
    super({
      discounts: {},
      discountListManager: {
        isLoadingMoreDiscount: false,
        request: {},
        hasReachedBottom: false,
      },
    })
  }

  setDiscountProduct = (
    state: DiscountState,
    discounts: Dict<Discount>
  ): DiscountState => {
    return {
      ...state,
      discounts: discounts,
    }
  }

  pushDiscount = (
    state: DiscountState,
    discounts: Dict<Discount>
  ): DiscountState => {
    return {
      ...state,
      discounts: {
        ...state.discounts,
        ...discounts,
      },
    }
  }

  setDiscountListReachBottom = (
    state: DiscountState,
    hasReachedBottom: boolean
  ): DiscountState => {
    return {
      ...state,
      discountListManager: {
        ...state.discountListManager,
        hasReachedBottom,
      },
    }
  }

  setLoadingMoreDiscount = (
    state: DiscountState,
    isLoadingMoreDiscount: boolean
  ): DiscountState => {
    return {
      ...state,
      discountListManager: {
        ...state.discountListManager,
        isLoadingMoreDiscount,
      },
    }
  }

  setDiscountListRequest = (
    state: DiscountState,
    request: GettingDiscountsRequest
  ): DiscountState => {
    return {
      ...state,
      discountListManager: {
        ...state.discountListManager,
        request,
      },
    }
  }

  get actions(): StateActions<DiscountState> {
    return {
      [SET_DISCOUNT_PRODUCT]: this.setDiscountProduct,
      [PUSH_DISCOUNT]: this.pushDiscount,
      [SET_DISCOUNT_LIST_REACH_BOTTOM]: this.setDiscountListReachBottom,
      [SET_LOADING_MORE_DISCOUNT]: this.setLoadingMoreDiscount,
      [SET_DISCOUNT_LIST_REQUEST]: this.setDiscountListRequest,
    }
  }
}
