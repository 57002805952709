import { i18n } from '@lingui/core'
import { t } from '@lingui/macro'

import { APP_SETTINGS } from 'constants/appSettings'
import { SchemaValidation } from 'utilities/validationCheck'

export const PasswordConfimationSchema = SchemaValidation.object().shape({
  password: SchemaValidation.string()
    .minNoTrim(
      APP_SETTINGS.MIN_PASSWORD_LENGTH,
      i18n._(
        t`Panjang minimal password adalah ${APP_SETTINGS.MIN_PASSWORD_LENGTH} karakter`
      )
    )
    .required(i18n._(t`Password harus diisi`)),
})
