import { toDict, toList } from 'utilities/converter/list'
import { BaseService } from 'domains/commons/services'
import { ICommonStorage } from 'domains/commons/contracts/storage'
import { Api } from 'domains/contracts/api'
import { IPaymentMethodStorage } from '../contracts/storage'
import {
  PaymentMethodModel,
  PaymentMethodUpdateBody,
  PaymentMethodOption,
  RegisterQRISPaymentPayload,
  QRISPaymentMethod,
} from '../models'

const SET_PAYMENT_METHODS = 'SET_PAYMENT_METHODS'

export class PaymentMethodService
  extends BaseService
  implements IPaymentMethodService
{
  paymentMethodStorage: IPaymentMethodStorage

  constructor(
    commonStorage: ICommonStorage,
    api: Api,
    paymentMethodStorage: IPaymentMethodStorage
  ) {
    super(commonStorage, api)
    this.paymentMethodStorage = paymentMethodStorage
  }

  public getPaymentMethods = async (
    outletId: string
  ): Promise<PaymentMethodModel[]> => {
    this.setLoading(SET_PAYMENT_METHODS, true)
    const data = await this.api.management.paymentMethod.getPaymentMethods({
      outletId,
    })
    this.paymentMethodStorage.setPaymentMethods(
      toDict(data, (p: PaymentMethodModel) => p.id)
    )
    this.setLoading(SET_PAYMENT_METHODS, false)
    return data
  }

  public batchUpdatePaymentMethod = async (
    toBeUpdatedPaymentMethods: PaymentMethodUpdateBody
  ) => {
    try {
      const savedPaymentMethods =
        await this.api.management.paymentMethod.batchUpdatePaymentMethods(
          toBeUpdatedPaymentMethods
        )
      const { paymentMethods } = this.getState().management.paymentMethod
      const currentPaymentMethodList = toList(paymentMethods)
      const paymentMethodDict = toDict(
        currentPaymentMethodList.map(pm => {
          const options = pm.options
          const newPaymentMethod = savedPaymentMethods.filter(
            (p: PaymentMethodModel) => p.id === pm.id
          )[0]
          if (newPaymentMethod) {
            newPaymentMethod.options.forEach((newOpt: PaymentMethodOption) => {
              const idx = options.map(opt => opt.id).indexOf(newOpt.id)
              if (idx >= 0) {
                options[idx] = newOpt
              }
            })
          }
          return {
            ...pm,
            options,
          }
        }),
        pm => pm.id
      )
      this.paymentMethodStorage.setPaymentMethods(paymentMethodDict)
      return savedPaymentMethods
    } catch (error) {
      throw error
    }
  }

  public registerQris = async (
    queryParams: RegisterQRISPaymentPayload
  ): Promise<void> => {
    await this.api.management.paymentMethodV3.registerQris(queryParams)
  }

  public getQris = async (outletId: string): Promise<QRISPaymentMethod> => {
    const QRISPaymentMethod =
      await this.api.management.paymentMethodV3.getQRISPaymentMethod(outletId)
    const qrisState =
      this.commonStorage.getState().management.paymentMethod.QRISPaymentMethods

    const updatedQris = qrisState.find(qris => qris.outletId === outletId)

    if (updatedQris) {
      this.deleteQris(outletId)
    }

    this.paymentMethodStorage.setQRISPaymentMethods({
      ...QRISPaymentMethod,
      outletId: outletId,
    })

    return QRISPaymentMethod
  }

  public updateQris = async (queryParams: QRISPaymentMethod): Promise<void> => {
    await this.api.management.paymentMethodV3.updateQRISPaymentMethod(
      queryParams
    )
  }

  public deleteQris = (outletId: string) => {
    try {
      const { QRISPaymentMethods } =
        this.commonStorage.getState().management.paymentMethod
      const deletedIdx = QRISPaymentMethods.findIndex(
        qris => qris.outletId === outletId
      )
      QRISPaymentMethods.splice(deletedIdx, 1)
    } catch (error) {
      throw error
    }
  }
}

export interface IPaymentMethodService {
  getPaymentMethods: (outletId?: string) => Promise<PaymentMethodModel[]>
  batchUpdatePaymentMethod: (
    paymentMethodUpdateBody: PaymentMethodUpdateBody
  ) => Promise<PaymentMethodModel[]>
  registerQris: (queryParams: RegisterQRISPaymentPayload) => Promise<void>
  getQris: (outletId: string) => Promise<QRISPaymentMethod>
  updateQris: (queryParams: QRISPaymentMethod) => Promise<void>
  deleteQris: (outletId: string) => void
}
