import { BaseApi } from 'api/base.api'
import { IPaymentMethodApi } from 'domains/management/paymentMethod/contracts/api'
import {
  PaymentMethodModel,
  PaymentMethodUpdateBody,
} from 'domains/management/paymentMethod/models'

import { PaymentMethodQueryParams } from 'domains/management/paymentMethod/states'

export default class PaymentMethodApi
  extends BaseApi
  implements IPaymentMethodApi
{
  constructor() {
    super('/v2/payment-methods')
  }

  public async getPaymentMethods(
    queryParams: PaymentMethodQueryParams
  ): Promise<PaymentMethodModel[]> {
    return await this.makeForCamelCaseForList('GET', '', queryParams)
  }

  public async batchUpdatePaymentMethods(
    paymentMethodUpdateBody: PaymentMethodUpdateBody
  ): Promise<PaymentMethodModel[]> {
    return await this.makeForCamelCaseForList(
      'POST',
      '/batch-update',
      paymentMethodUpdateBody
    )
  }
}
