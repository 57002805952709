import { Tooltip } from 'antd'
import classNames from 'classnames'
import React, { useState } from 'react'

import Text from 'views/components/Text'

import './style.css'

import { Icon, Sided } from '..'

interface Props {
  label?: string
  secondaryLabel?: string
  children?: React.ReactChild
  isDisabled?: boolean
  className?: string
  error?: string
  prefix?: React.ReactNode
  suffix?: React.ReactNode
  onToggle?: () => void
  dataTestID?: string
  isFreezed?: boolean
  tooltipMessage?: string
  noBorder?: boolean
  hideErrorMessage?: boolean
  required?: boolean
  noMargin?: boolean
}

const InputBase = (props: Props) => {
  const [isFocus, setIsFocus] = useState(false)

  const label = props.label && (
    <Text.Paragraph color='semiGray'>{props.label}</Text.Paragraph>
  )

  const secondaryLabel = props.secondaryLabel && (
    <Text.Paragraph style={{ color: '#979899' }}>
      {props.secondaryLabel}
    </Text.Paragraph>
  )

  const className = classNames(
    'post-input',
    props.className,
    props.isDisabled && 'disabled',
    { focus: isFocus },
    { 'no-margin': props.noMargin }
  )

  const classNameBase = classNames('post-input-base', {
    focus: isFocus,
    frezzed: props.isFreezed,
    'input-error': props.error,
    'no-border': props.noBorder,
  })

  const tooltip = () =>
    props.tooltipMessage && (
      <Tooltip
        trigger='hover'
        placement='bottom'
        title={props.tooltipMessage}
        className='tooltip-placement'
      >
        <span>
          {' '}
          <Icon.Help color='black' />{' '}
        </span>
      </Tooltip>
    )

  return (
    <div
      className={className}
      onFocus={() => setIsFocus(true)}
      onBlur={() => setIsFocus(false)}
      data-testid='field-base'
    >
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Text.Span style={{ display: 'flex' }}>
          {label}
          {props.required && <Text.Span color='red'>*</Text.Span>} {tooltip()}
        </Text.Span>
        <span>{secondaryLabel}</span>
      </div>
      <Sided.Sided gutter={0} className={classNameBase}>
        {props.prefix && (
          <Sided.Fixed className='input-prefix'>{props.prefix}</Sided.Fixed>
        )}
        <Sided.Remaining>{props.children}</Sided.Remaining>
        {props.suffix && (
          <Sided.Fixed className='input-suffix'>{props.suffix}</Sided.Fixed>
        )}
      </Sided.Sided>
      {props.error !== undefined && !props.hideErrorMessage && (
        <Text.Span className='input-error-message' color='red' size={12}>
          {props.error}
        </Text.Span>
      )}
    </div>
  )
}

export default InputBase
