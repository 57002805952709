import React, { useEffect, useState, ReactNode, useContext } from 'react'
import {
  Field,
  Text,
  Break,
  UploadImage,
  Form,
  SwitchButton,
  Sided,
  Divider,
  Wrapper,
  Checkbox,
  Button,
  FeatureFlag,
  Panel,
} from 'views/components'
import UploadImageV2 from 'views/components/UploadImage/UploadImageV2'
import './style.css'
import { useSelector } from 'react-redux'
import selector, { ProductViewModel } from '../../selector'
import {
  CreateSingleProductRequest,
  ProductPricing,
} from 'domains/management/products/models'
import Context, { ProductContext } from '../context'
import { featureCheck, isAnyOfTheseFeaturesEnabled } from 'config'
import { CONFIG_LOCALSTORAGE } from 'constants/localStorage'
import { getLocalStorage } from 'utilities/localStorage'
import { DefaultModalService } from 'views/components/Modal'
import AddProductCategoryExtraModal from './addProductCategoryExtraModal'
import netcore from 'utilities/netcore'
import { useLingui } from '@lingui/react'
import { t, Trans } from '@lingui/macro'
import CropImageModal from './CropImageModal'

const ProductInfoForm = () => {
  const {
    pricings,
    categoryDropdownList,
    outletDropdownList,
  }: ProductViewModel = useSelector(selector)
  const [showServiceType, switchServiceType] = useState<boolean>(false)
  const employeeId = getLocalStorage(CONFIG_LOCALSTORAGE.STAFF_ID)
  const context = useContext<ProductContext>(Context)
  const { actionsProps } = context
  const { getValues } = actionsProps
  const { i18n } = useLingui()

  const [showModal, setShowModal] = useState(false)
  const [selectedImage, setSelectedImage] = useState('')

  const onChangeSwitch = () => {
    netcore.dispatchEvent('create_product_price_ordertype')
    if (showServiceType === false) {
      switchServiceType(!showServiceType)
      actionsProps.setFieldValue(x => {
        x.pricing = pricings.map(p => ({ ...p, price: getValues().price }))
      })
      actionsProps.validateAndGetValue()
    } else {
      switchServiceType(!showServiceType)
      actionsProps.setFieldValue(x => {
        x.pricing = []
      })
      actionsProps.validate()
    }
  }

  const serviceTypeAdjustment = () => {
    const { pricing } = getValues()
    let min = pricing[0].price
    for (let i = 0; i < pricing.length; i++) {
      if (pricing[i].price < min) {
        min = pricing[i].price
      }
    }
    actionsProps.validate()
  }

  const renderServiceTypesPricing = (): ReactNode => (
    <React.Fragment>
      <Break height={16} />
      <Form.ArrayHelper
        name='pricing'
        forEach={(x: CreateSingleProductRequest) => x.pricing}
        render={(pricing: ProductPricing, index: number) => {
          return (
            <React.Fragment>
              <Field.Number
                label={pricing.serviceType.name}
                name={`price`}
                placeholder={i18n._(t`Contoh: Rp. 15.000`)}
                value={pricing.price}
                onChange={() => serviceTypeAdjustment()}
              />
            </React.Fragment>
          )
        }}
      />
    </React.Fragment>
  )

  useEffect(() => {
    if (getValues().pricing.length > 0) {
      switchServiceType(true)
    }
  }, [])

  const renderProductDetail = () => {
    return (
      <Wrapper
        padding='16px'
        backgroundColor='white'
        style={{
          borderWidth: '0px 1px 1px 1px',
          borderStyle: 'solid',
          borderBottomLeftRadius: '4px',
          borderBottomRightRadius: '4px',
          borderColor: '#e0e0e0',
        }}
      >
        <Trans>
          <Text.Heading h={6} noMargin>
            Detail Produk
          </Text.Heading>
        </Trans>
        <Divider />
        <Break height={10} />
        <Field.Text
          label={i18n._(t`Nama Produk`)}
          name='name'
          placeholder={i18n._(t`Contoh: Ayam Goreng`)}
        />
        {!showServiceType && (
          <Field.Number
            label={i18n._(t`Harga Jual`)}
            name='price'
            placeholder={i18n._(t`Contoh: Rp. 15.000`)}
          />
        )}
        <Sided.Sided justify='center'>
          <Sided.Remaining>
            <Trans>
              <Text.Heading h={6}>
                {' '}
                Terapkan harga jual per tipe pesanan
              </Text.Heading>
            </Trans>
          </Sided.Remaining>
          <Sided.Fixed>
            <SwitchButton
              isActive={showServiceType}
              onChange={onChangeSwitch}
            />
          </Sided.Fixed>
        </Sided.Sided>
        <Trans>
          <Text.Paragraph>
            {' '}
            Terapkan harga berbeda untuk setiap tipe pesanan (contoh: Makan Di
            Tempat, Go-Food, Tokopedia) <br />
            {showServiceType === true &&
              getValues().price < getValues().unitCost && (
                <span style={{ color: '#ba1818', fontSize: '12px' }}>
                  Harga jual tidak boleh lebih kecil daripada modal
                </span>
              )}
          </Text.Paragraph>
        </Trans>
        <React.Fragment>
          {showServiceType &&
            getValues().price !== undefined &&
            renderServiceTypesPricing()}
          {showServiceType && getValues().price === undefined && (
            <Trans>
              <Text.Span color='red'>
                Silahkan isi harga jual terlebih dahulu.
              </Text.Span>
            </Trans>
          )}
          <br />
          {showServiceType && getValues().pricing.length === 0 && (
            <Trans>
              <Text.Span color='red'>
                Tipe pesanan tidak tersedia, harga sementara akan mengikuti
                harga jual
              </Text.Span>
            </Trans>
          )}
        </React.Fragment>
      </Wrapper>
    )
  }

  const handleApplyOnNewOutlet = () => {
    return (
      <Wrapper style={{ display: 'flex' }}>
        <Checkbox
          checked={actionsProps.getValues().applyOnNewOutlet}
          onChange={() =>
            actionsProps.setFieldValue(
              x =>
                (x.applyOnNewOutlet =
                  !actionsProps.getValues().applyOnNewOutlet)
            )
          }
        />
        <Trans>
          <span>Terapkan ke Outlet baru</span>
        </Trans>
      </Wrapper>
    )
  }

  const renderCategoryDropdownExtraItem = () => {
    return (
      <Wrapper padding='8px' style={{ borderTop: 'solid 1px #e0e0e0' }}>
        <Button
          fullW
          theme='netral'
          label={i18n._(t`Tambah`)}
          onClick={async () => {
            try {
              const categoryId: string = await DefaultModalService.open(
                AddProductCategoryExtraModal,
                {}
              )
              if (String(categoryId) === 'false') {
                actionsProps.setFieldValue(x => (x.categoryId = undefined))
              } else {
                actionsProps.setFieldValue(x => (x.categoryId = categoryId))
              }
            } catch (error) {
              console.error('modal closed')
            }
          }}
        />
      </Wrapper>
    )
  }

  const renderAdditionalDetail = () => {
    return (
      <Wrapper
        padding='16px'
        backgroundColor='white'
        style={{
          borderWidth: '0px 1px 1px 1px',
          borderStyle: 'solid',
          borderBottomLeftRadius: '4px',
          borderBottomRightRadius: '4px',
          borderColor: '#e0e0e0',
        }}
      >
        <Trans>
          <Text.Heading h={6} noMargin>
            Detail Tambahan (Opsional)
          </Text.Heading>
        </Trans>
        <Divider />
        <Break height={10} />
        <Trans>
          <Text.Paragraph>Foto Produk</Text.Paragraph>
        </Trans>
        <Break height={10} />
        {isAnyOfTheseFeaturesEnabled(
          featureCheck.management.cropImageProduct
        ) ? (
          <UploadImage
            initValue={getValues().pictureUrl}
            onChange={value => {
              if (value === 'image_removed') {
                actionsProps.setFieldValue(x => (x.pictureUrl = value))
              } else {
                setSelectedImage(value)
                setShowModal(true)
              }
            }}
            imageUrl={getValues().pictureUrl}
          />
        ) : (
          <UploadImageV2
            initValue={getValues().pictureUrl}
            onChange={value =>
              actionsProps.setFieldValue(x => (x.pictureUrl = value))
            }
          />
        )}
        <Break height={16} />
        <Field.TextArea
          maxLength={160}
          label={i18n._(t`Deskripsi Produk (Opsional)`)}
          name='description'
          placeholder={i18n._(t`Tulis Deskripsi Produk`)}
          showCounter={true}
        />
        <Field.Dropdown
          label={i18n._(t`Kategori`)}
          name='categoryId'
          placeholder={i18n._(t`Pilih Kategori`)}
          list={categoryDropdownList}
          extraItem={() => renderCategoryDropdownExtraItem()}
        />
        <Field.Number
          label={i18n._(t`Harga Modal`)}
          name='unitCost'
          placeholder='Rp. 0'
        />
        {
          // hide for now (not tested)
          <FeatureFlag featureCheck={featureCheck.management.hasBarcode}>
            <Field.Text
              label={i18n._(t`Barcode`)}
              name='barcode'
              placeholder='2300000101010101'
            />
          </FeatureFlag>
        }
        <Field.Text
          label={i18n._(t`SKU`)}
          name='sku'
          placeholder={i18n._(t`Contoh: 12345`)}
        />
        <FeatureFlag featureCheck={featureCheck.management.outletForProduct}>
          {!employeeId && (
            <Field.DropdownCheckbox
              label={i18n._(t`Pilih Outlet`)}
              name='outletIds'
              placeholder={i18n._(t`Pilih Outlet`)}
              list={outletDropdownList}
              title={i18n._(t`Outlet`)}
              prefix={handleApplyOnNewOutlet()}
            />
          )}
        </FeatureFlag>
        <Sided.Sided>
          <Sided.Fixed minWidth={180}>
            <Field.Number
              label={i18n._(t`Berat (gram)`)}
              name='weight'
              placeholder={i18n._(t`Contoh: 500`)}
              className='error-message-nowrap'
            />
          </Sided.Fixed>
          <Sided.Remaining>
            <Text.Paragraph>Dimensi (cm)</Text.Paragraph>
            <Sided.Sided>
              <Sided.Remaining>
                <Field.Number
                  name='length'
                  placeholder={i18n._(t`Panjang`)}
                  hideErrorMessage
                />
              </Sided.Remaining>
              <Sided.Fixed verticalAlign='center'>X</Sided.Fixed>
              <Sided.Remaining>
                <Field.Number
                  name='width'
                  placeholder={i18n._(t`Lebar`)}
                  hideErrorMessage
                />
              </Sided.Remaining>
              <Sided.Fixed verticalAlign='center'>X</Sided.Fixed>
              <Sided.Remaining>
                <Field.Number
                  name='height'
                  placeholder={i18n._(t`Tinggi`)}
                  hideErrorMessage
                />
              </Sided.Remaining>
            </Sided.Sided>
            <Text.Span className='input-error-message' color='red' size={12}>
              {actionsProps.getError('length')}
            </Text.Span>
          </Sided.Remaining>
        </Sided.Sided>
        <Break height={16} />
        <Panel.Card
          style={{ backgroundColor: '#FEF6D9', border: 'none' }}
          padding={12}
        >
          <Text.Paragraph noMargin size={12} color='semiGray'>
            <i>Berat dan dimensi diperlukan untuk kurir Pesan Antar.</i>
          </Text.Paragraph>
        </Panel.Card>
      </Wrapper>
    )
  }

  return (
    <React.Fragment>
      {renderProductDetail()}
      <Break height={24} />
      {renderAdditionalDetail()}
      {isAnyOfTheseFeaturesEnabled(
        featureCheck.management.cropImageProduct
      ) && (
        <CropImageModal
          image={selectedImage}
          actionsProps={actionsProps}
          showModal={showModal}
          setShowModal={setShowModal}
        />
      )}
    </React.Fragment>
  )
}

export default ProductInfoForm
