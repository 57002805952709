import React, { Component } from 'react'
import Nprogress from 'nprogress'
import Loader from '../Loader'
import { MiddleWareFunction } from 'domains/layouts/services/route.item'
import Wrapper from '../Wrapper'

export default function AsyncPage<P>(
  pageComponent: () => Promise<{
    default: React.FC<P>
  }>,
  middlewares: Array<MiddleWareFunction> = []
) {
  class AsyncFunc extends Component<
    P,
    {
      component: React.ReactElement<P>
    }
  > {
    private mounted: boolean
    constructor(props: P) {
      super(props)
      this.state = {
        component: null,
      }
    }

    async componentWillMount() {
      Nprogress.start()
      const middlewareResults = await Promise.all(
        middlewares.map(
          middleware =>
            new Promise(async resolve => {
              resolve(await middleware())
            })
        )
      )
      middlewareResults.forEach(fallback => {
        if (fallback !== true && typeof fallback === 'string') {
          window.location.href = fallback
        }
      })
    }

    componentWillUnmount() {
      this.mounted = false
    }

    UNSAFE_componentWillUnmount() {
      this.mounted = false
    }

    async componentDidMount() {
      try {
        this.mounted = true
        const { default: Component } = await pageComponent()
        Nprogress.done()

        if (this.mounted) {
          this.setState({
            component: <Component {...this.props} />,
          })
        }
      } catch (error) {
        console.error(error)
      }
    }

    render() {
      return (
        this.state.component || (
          <Wrapper height='100%' width='100%' position='relative'>
            <Loader />
          </Wrapper>
        )
      )
    }
  }

  return AsyncFunc
}
