import React from 'react'
import classNames from 'classnames'
import './style.css'
import { BaseClickableProps, BaseProps, BaseParentProps } from '../props'

interface RowProps extends BaseProps, BaseParentProps, BaseClickableProps {
  flex?: string
  width?: string
  height?: string
  flexDirection?: 'column' | 'row'
  hGap?: number | 4 | 8 | 12 | 16
  vGap?: number | 4 | 8 | 12 | 16
}

interface ColumnProps extends BaseProps, BaseParentProps {
  flex?: string
  width?: string
  height?: string
  minWidth?: string
  position?:
    | 'inherit'
    | 'initial'
    | 'revert'
    | 'unset'
    | 'absolute'
    | 'fixed'
    | 'relative'
    | 'static'
    | 'sticky'
}

const Row = (props: RowProps) => {
  const className = classNames(
    'post-flex-row',
    props.className || '',
    props.hGap ? `h-gap-${props.hGap}` : '',
    props.vGap ? `v-gap-${props.vGap}` : ''
  )
  const style: React.CSSProperties = {
    ...props.style,
    flex: props.flex,
    width: props.width,
    height: props.height,
    flexDirection: props.flexDirection || 'column',
  }
  return (
    <div className={className} style={style}>
      {props.children}
    </div>
  )
}

const Column = (props: ColumnProps) => {
  const style: React.CSSProperties = {
    ...props.style,
    position: props.position ? props.position : 'unset',
    flex: props.flex,
    minWidth: props.minWidth,
    height: props.height,
    width: props.width,
  }
  const className = classNames('post-flex-column', props.className || '')
  return (
    <div className={className} style={style}>
      {props.children}
    </div>
  )
}

const Flex = {
  Column,
  Row,
}

export default Flex
